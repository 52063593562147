import React from 'react';
import { withAuthUser } from 'lib/Session';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { fetchBrand } from 'actions/brands';

const CampaignPolicy = Component => {
  class Ability extends React.Component {
    state = {
      brand: null
    };

    componentDidMount() {
      const { authUser, dispatchFetchBrand } = this.props;

      dispatchFetchBrand(authUser.uid, brand => {
        this.setState(() => ({ brand }));
      });
    }

    render() {
      const { brand } = this.state;
      const { match } = this.props;

      let authorized = true;

      if (brand && brand.products) {
        authorized = Object.keys(brand.products).includes(match.params.campaignId);
      }

      return <div>{authorized ? <Component {...this.props} /> : <Redirect to="/posts" />}</div>;
    }
  }

  const mapStateToProps = () => ({});

  const mapDispatchToProps = dispatch => ({
    dispatchFetchBrand: (brandUID, callback) => dispatch(fetchBrand(brandUID, callback))
  });

  return compose(
    withAuthUser,
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
  )(Ability);
};

export { CampaignPolicy as default };
