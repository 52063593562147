/* eslint no-console: ["error", { allow: ["log"] }] */
import { firebase } from 'lib/Firebase';
import * as Promise from 'bluebird';
import lodash from 'lodash';
import { CAMPAIGN_APPLIED_UID_STATUS } from 'constants/campaigns';
import { POST_DETAIL_STATUS, POST_STATUS } from 'constants/posts';
import { addCampaign, updateCampaign } from 'actions/campaigns';
import {
  CURRENT_CAMPAIGN,
  SET_NEW_MESSAGES_COUNT,
  NEEDS_SHIPPING_COUNT,
  POST_TO_APPROVE_COUNT,
  APPLIED_INFLUENCER_COUNT,
  SHIPPED_COUNT,
  SET_CAMPAIGN_INFLUENCER_LIST,
  SET_CAMPAIGN_APPLICATION_LIST,
  REMOVE_CAMPAIGN_APPLICANT,
  SET_CAMPAIGN_DASHBOARD_READY,
  SET_CAMPAIGN_LISTENING
} from '../../reducers/ui/campaignDashboard';
import { setInfluencers, updateInfluencerCampaignPosts } from '../influencers';
import { appliedInfluencerByStatus } from '../../selectors/campaignList';
import { fetchInfluencerByUIDs } from '../../api/influencers';
import { fetchCampaignActiveOrComplete } from '../../api/campaigns';
import { listenCampaignInfluencersPosts } from '../../listeners/campaignDashboard';

export const setNeedsShippingCount = (campaignUID, needsShippingCount) => ({
  type: NEEDS_SHIPPING_COUNT,
  campaignUID,
  needsShippingCount
});

export const setShippedCount = (campaignUID, shippedCount) => ({
  type: SHIPPED_COUNT,
  campaignUID,
  shippedCount
});

export const setPostToApproveCount = (campaignUID, postToApproveCount) => ({
  type: POST_TO_APPROVE_COUNT,
  campaignUID,
  postToApproveCount
});

export const setAppliedInfluencerCount = (campaignUID, appliedInfluencerCount) => ({
  type: APPLIED_INFLUENCER_COUNT,
  campaignUID,
  appliedInfluencerCount
});

export const setCurrentCampaign = campaign => ({
  type: CURRENT_CAMPAIGN,
  campaign
});

export const setNewMessagesCount = (campaignUID, newMessageCount) => ({
  type: SET_NEW_MESSAGES_COUNT,
  campaignUID,
  newMessageCount
});

export const addCurrentCampaign = (campaignUID, callback = null) => async dispatch => {
  const snap = await firebase.activeProduct(campaignUID).once('value');
  let campaign = {};

  if (snap.exists()) {
    campaign = {
      campaignUID,
      ...snap.val()
    };

    dispatch(setCurrentCampaign(campaign));
    dispatch(addCampaign({ [campaignUID]: snap.val() }));

    if (callback) {
      callback(campaign);
    }
  } else {
    const completedSnapshot = await firebase.completedProduct(campaignUID).once('value');
    campaign = {
      campaignUID,
      ...completedSnapshot.val()
    };

    dispatch(setCurrentCampaign(campaign));
    dispatch(addCampaign({ [campaignUID]: completedSnapshot.val() }));

    if (callback) {
      callback(campaign);
    }
  }
};

const isNeedsShippingPost = status => status === POST_STATUS.needsShipping;

const isSubmittedPostDetail = status => status === POST_DETAIL_STATUS.submitted;

const isApprovedAppliedUID = status => status === CAMPAIGN_APPLIED_UID_STATUS.approved;

export const fetchCampaignNavLinkStatusCounts = campaignUID => dispatch => {
  fetchCampaignActiveOrComplete(campaignUID, (campaign, error) => {
    if (!campaign || error) {
      console.log(error);
      return;
    }

    const { appliedUID = {} } = campaign;
    let needsShippingCount = 0;
    let shippedCount = 0;
    let postToApproveCount = 0;

    const influencerUIDs = Object.keys(appliedUID);
    const approvedUIDs = influencerUIDs.filter(x => isApprovedAppliedUID(appliedUID[x].status));

    Promise.map(
      approvedUIDs,
      influencerUID => {
        return firebase
          .influencer(influencerUID)
          .child(`partnerships/products/${campaignUID}`)
          .once('value', snapshot => {
            const campaignPosts = snapshot.val();

            if (campaignPosts && isNeedsShippingPost(campaignPosts.status)) {
              needsShippingCount += 1;
            } else {
              // base on code
              // src/components/CampaignDashboard/Influencers/ProductShipments.js:L28
              // if not equal to needsShipping then considier as shipped
              shippedCount += 1;
            }

            const posts = campaignPosts ? campaignPosts.posts || {} : {};

            Object.keys(posts).forEach(postUID => {
              const post = posts[postUID];

              if (isSubmittedPostDetail(post.details.status)) {
                postToApproveCount += 1;
              }
            });
          });
      },
      {
        concurrency: 1
      }
    )
      .then(() => {
        dispatch(setPostToApproveCount(campaignUID, postToApproveCount));
        dispatch(setNeedsShippingCount(campaignUID, needsShippingCount));
        dispatch(setShippedCount(campaignUID, shippedCount));
      })
      .catch(e => {
        console.log('Error on fetchCampaignNavLinkStatusCounts promising: ', e);
      });
  });
};

export const setCampaignDashboardReady = (campaignUID, ready = false) => ({
  type: SET_CAMPAIGN_DASHBOARD_READY,
  campaignUID,
  ready
});

export const setCampaignInfluencerList = (campaignUID, influencerUIDs) => ({
  type: SET_CAMPAIGN_INFLUENCER_LIST,
  campaignUID,
  influencerUIDs
});

export const setCampaignApplicationList = (campaignUID, influencerUIDs) => ({
  type: SET_CAMPAIGN_APPLICATION_LIST,
  campaignUID,
  influencerUIDs
});

export const removeCampaignApplicant = (campaignUID, influencerUIDs) => ({
  type: REMOVE_CAMPAIGN_APPLICANT,
  campaignUID,
  influencerUIDs
});

export const setCampaignListen = (campaignUID, listening = false) => ({
  type: SET_CAMPAIGN_LISTENING,
  campaignUID,
  listening
});

export const loadCampaignDashboard = (campaignId, campaign) => async (dispatch, getState) => {
  const {
    ui: { campaignDashboard }
  } = getState();
  if (campaign.appliedUID) {
    const dashboard = campaignDashboard.byCampaignId[campaignId] || {};
    const applicantUIDs = dashboard.applicantUIDs || [];
    const influencerUIDs = dashboard.influencerUIDs || [];
    const loadedInfluencerUIDs = lodash.concat(applicantUIDs, influencerUIDs);
    const sumbittedApplication = appliedInfluencerByStatus(campaign, 'submitted');
    const approvedAppliedInfluencers = appliedInfluencerByStatus(campaign, 'approved');
    const appliedInfluencerIds = lodash.concat(sumbittedApplication, approvedAppliedInfluencers);
    const newInfluencers = lodash.xor(appliedInfluencerIds.sort(), loadedInfluencerUIDs.sort());

    const unloadedInfluencer = await fetchInfluencerByUIDs(newInfluencers);
    dispatch(setInfluencers(unloadedInfluencer));
    dispatch(setAppliedInfluencerCount(campaignId, sumbittedApplication.length));

    const notlistenedInfluencersPost = lodash.xor(
      approvedAppliedInfluencers.sort(),
      influencerUIDs.sort()
    );

    listenCampaignInfluencersPosts(campaignId, notlistenedInfluencersPost, (influencerUID, val) => {
      dispatch(updateInfluencerCampaignPosts(influencerUID, campaignId, val));
    });

    dispatch(fetchCampaignNavLinkStatusCounts(campaignId));
    dispatch(setCampaignInfluencerList(campaignId, approvedAppliedInfluencers));
    dispatch(setCampaignApplicationList(campaignId, sumbittedApplication));
    dispatch(updateCampaign({ [campaignId]: campaign }));
    dispatch(setCampaignDashboardReady(campaignId, true));
    dispatch(setCampaignListen(campaignId, true));
  } else {
    dispatch(updateCampaign({ [campaignId]: campaign }));
    dispatch(setCampaignDashboardReady(campaignId, true));
  }
};
