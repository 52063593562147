import React, { Component } from 'react';
import {
  Modal, Form, Input, Select, Button,
  Row, Col, Icon, Checkbox,
} from 'antd';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import {
  hideShippingFormModal,
  resetShippingDetails,
} from 'actions/ui/shippingFormModal';
import ShippingHistory from 'components/CampaignDashboard/Influencers/ShippingHistory';

const { Item } = Form;
const { Option } = Select;

const carrierCodes = {
  usps: 'USPS',
  stamps_com: 'Stamps.com',
  ups: 'UPS',
  fedex: 'FedEx',
  canada_post: 'Canada Post',
  australia_post: 'Australia Post',
};

const carrierOptions = () => (
  Object.entries(carrierCodes).map(([id, value]) => (
    <Option key={id} value={id}>{ value }</Option>
  ))
);

class ShippingFormModal extends Component {
  state = {
    nonShippable: false,
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      influencerId, productId,
      updateShippingDetails: update,
      requestDeliveryDetails: request,
      resetShippingDetails: reset,
      form: { validateFieldsAndScroll },
      form,
    } = this.props;

    const { nonShippable } = this.state;

    validateFieldsAndScroll((err, params) => {
      if (!err || nonShippable) {
        let trackingNumber = params.trackingNumber || '';
        trackingNumber = trackingNumber.replace(/\s/g, '');
        const parameters = {
          ...params,
          trackingNumber,
        };

        update(influencerId, productId, parameters)
          .then(() => {
            if (nonShippable) {
              form.resetFields();
              reset();
            } else {
              request(params.carrierCode, params.trackingNumber);
              form.setFieldsValue({ trackingNumber });
              this.setState({ showForm: false });
            }
            this.close();
          });
      }
    });
  }

  handleCheckboxChange = () => {
    this.setState(state => ({ nonShippable: !state.nonShippable }));
  }

  toggleForm = () => {
    this.setState(prevState => ({ showForm: !prevState.showForm }));
  }

  close = () => {
    const { hideShippingFormModal: hideModal } = this.props;
    hideModal();
  }

  form() {
    const { form: { getFieldDecorator }, influencerId } = this.props;
    const { nonShippable } = this.state;
    let showForm = false;
    if (influencerId === 'ft-987654321') {
      showForm = true;
    }

    return (
      <Form style={{ display: showForm ? 'block' : 'none' }}>
        <Item style={{ background: '#ddd', paddingLeft: '20px', borderRadius: '3px' }}>
          {getFieldDecorator('nonShippable')(<Checkbox checked={nonShippable} onChange={this.handleCheckboxChange}>Product does not need shipping</Checkbox>)}
        </Item>
        <div style={{ display: nonShippable ? 'none' : 'block' }}>
          <Item label="Carrier Code">
            {getFieldDecorator('carrierCode', {
              rules: [{ required: !nonShippable, message: 'Please input Shipping Carrier' }],
            })(<Select disabled={true} placeholder="Shipping Carrier">{ carrierOptions() }</Select>)}
          </Item>
          <Item label="Tracking Number">
            {getFieldDecorator('trackingNumber', {
              rules: [{ required: !nonShippable, message: 'Please input Tracking Number' }],
            })(<Input disabled={true} placeholder="0000000" />)}
          </Item>
        </div>
      </Form>
    );
  }

  details() {
    const { influencerId } = this.props;
    let influencer = null;

    if (influencerId !== 'ft-987654321') {
      influencer = {
        details: {
          name: 'Demo Influencer',
          username: 'demo-influencer2',
          address: 'Demo Address',
        },
      };
    }
    if (!influencer) return '';
    const { details } = influencer;
    const today = moment();

    const deliveryDetails = {
      shipDate: moment(today).subtract(1, 'days'),
      actualDeliveryDate: today,
      estimatedDeliveryDate: today,
      events: [
        {
          description: 'Delivered',
          occurred_at: today,
        },
        {
          description: 'Out For Delivery Today',
          occurred_at: moment(today).subtract(1, 'days'),
        },
      ],
    };

    const loading = (
      <div style={{ textAlign: 'center', fontSize: '16pt' }}>
        <Icon type="loading" />
      </div>
    );

    const shipDate = deliveryDetails.shipDate || 'N/A';
    const deliveryDate = deliveryDetails.actualDeliveryDate || deliveryDetails.estimatedDeliveryDate || 'N/A';
    const { showForm } = this.state;
    return (
      <div style={{ display: showForm ? 'none' : 'block' }}>
        <div style={{ textAlign: 'center' }}>
          {details.address}
        </div>
        { deliveryDetails.fetching ? loading : (
          <div>
            <Row style={{ marginTop: '20px' }}>
              <Col span={12} style={{ textAlign: 'center' }}>
                <strong>Date Shipped</strong>
                <br />
                <span>{ shipDate !== 'N/A' ? moment(shipDate).format('ddd, MMM D YYYY') : shipDate }</span>
              </Col>
              <Col span={12} style={{ textAlign: 'center' }}>
                <strong>{ deliveryDetails.actualDeliveryDate ? 'Delivered On' : 'Expected Delivery' }</strong>
                <br />
                <span>{ deliveryDate !== 'N/A' ? moment(deliveryDate).format('ddd, MMM D YYYY') : deliveryDate }</span>
              </Col>
            </Row>
            <ShippingHistory events={deliveryDetails.events} />
          </div>
        )}
      </div>
    );
  }


  render() {
    const { show, hideShippingFormModal: hideModal, submitting } = this.props;
    const { showForm } = this.state;
    return (
      <Modal
        title="Shipping Details"
        visible={show}
        onOk={this.handleSubmit}
        onCancel={() => hideModal()}
        footer={[
          showForm
            ? <Button key="submit" type="primary" loading={submitting} onClick={this.handleSubmit} disabled>Update</Button>
            : <Button key="close" onClick={this.close}>Close</Button>,
        ]}
      >
        <div style={{ textAlign: 'center' }}>
          <Button style={{ marginBottom: '20px' }} onClick={() => this.toggleForm()} disabled>
            <Icon type={showForm ? 'solution' : 'setting'} />
            { showForm ? 'Show Tracking Details' : 'Edit Tracking Number' }
          </Button>
        </div>
        { this.details() }
        { this.form() }
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  show: state.ui.shippingFormModal.show,
  productId: state.ui.shippingFormModal.productId,
  submitting: state.ui.shippingFormModal.submitting,
  influencerId: state.ui.shippingFormModal.influencerId,
  influencers: state.entities.influencers,
  deliveryDetails: state.ui.shippingFormModal.delivery,
});

const mapDispatchToProps = dispatch => ({
  hideShippingFormModal: () => dispatch(hideShippingFormModal()),
  resetShippingDetails: () => dispatch(resetShippingDetails()),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  Form.create({ name: 'shippingForm' }),
)(ShippingFormModal);
