import React from 'react';
import { connect } from 'react-redux';
import MoreApplications from 'images/more-applications@3x.png';
import { FREE_INFLUENCERS_DISPLAY } from 'constants/common';

const FreeTrialBanner = ({
  totalAppliedUIDs,
  hasInfluencers,
}) => {
  const remainingTotalAppliedUIDs = totalAppliedUIDs - FREE_INFLUENCERS_DISPLAY;

  return (
    remainingTotalAppliedUIDs > 0 && hasInfluencers ? (
      <div
        style={{
          marginTop: '56px',
          marginBottom: '109px',
          textAlign: 'center',
        }}
      >
        <div>
          <img src={MoreApplications} alt="More Applicaitons" width="200px" height="204.9px" />
        </div>
        <div
          style={{
            fontFamily: 'TruenoBold',
            fontSize: '25px',
            lineHeight: '1.24',
            color: '#000000',
            marginTop: '25.1px',
          }}
        >
          Plus&nbsp;
          {remainingTotalAppliedUIDs}
          &nbsp;more&nbsp;
          {remainingTotalAppliedUIDs > 1 ? 'applications' : 'application'}
        </div>
        <div
          style={{
            fontSize: '13px',
            lineHeight: '1.77',
            color: '#000000',
            marginTop: '7px',
          }}
        >
          New influencers are constantly discovering your campign. To unlock
          <br />
          the full list of influencers you can work with, select a plan and get
          <br />
          your campaign underway!
        </div>
      </div>
    ) : (
      null
    )
  );
};

const mapStateToProps = state => ({
  hasInfluencers: state.ui.influencerList.length > 0,
  totalAppliedUIDs: state.ui.applicationsAction.totalAppliedUIDs,
});

export default connect(mapStateToProps)(FreeTrialBanner);
