import { useContext } from 'react';
import AuthUserContext from './context';

const useSession = () => {
  const authUser = useContext(AuthUserContext);

  return authUser;
};

export default useSession;
