// brand.(id).products.(id)
export const BRAND_PRODUCT_STATUS = {
  active: 'active',
  completed: 'completed'
};

// brand.(id).details.status
export const BRAND_DETAILS_STATUS = {
  pending: 'pending',
  approved: 'approved'
};
