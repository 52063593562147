export const SET_LOGGED_USER = 'SET_LOGGED_USER';

export const REMOVE_LOGGED_USER = 'REMOVE_LOGGED_USER';
export const UPDATE_LOGGED_USER = 'UPDATE_LOGGED_USER';

const defaultState = {
  authUserData: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_LOGGED_USER:
      return {
        ...state,
        authUserData: action.authUserData
      };

    case REMOVE_LOGGED_USER:
      return {
        ...state,
        authUserData: null
      };
    case UPDATE_LOGGED_USER:
      return {
        ...state,
        authUserData: {
          ...state.authUserData,
          details: {
            ...state.authUserData.details,
            ...action.authUserData.details
          }
        }
      };
    default:
      return state;
  }
};
