import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popover, Button, Row, Col } from 'antd';
import moment from 'moment';
import {
  adminPushInfluencer,
  adminUpdateLastInfluencerDoc,
  adminRemoveInfluencer
} from '../../../stores/actions/ui/admin/influencers';
import { listenInfluencers } from '../../../listeners/influencers';
import { setInfluencers } from '../../../actions/influencers';
import formatSocialData, { formatSocialDataMin } from '../../../components/Helpers/numeral';
import Container from '../../../components/Common/Container';
import {
  InfluencerAvatar,
  AdminInfluencerFilters,
  InfluencerTable,
  Badge,
  InfluencerActiveCampaigns,
  InfluencerAdminDrawer,
  useInfluencerDrawer,
  SuspendButton,
  InfluencerCampaignProvider,
  InfluencersStatistics
} from '../../../components/Influencers';
import { influencersSearch } from '../../../lib/Firebase';
import InfluencerWrapper from '../../../wrappers/influencerWrapper';

const columns = [
  {
    title: 'Influencer',
    dataIndex: 'username',
    className: 'username',
    render: (val, attr) => <InfluencerAvatar influencer={attr} showName />
  },
  {
    title: 'Last login',
    dataIndex: 'lastLoginDate',
    className: 'last-login-date',
    render: val => {
      if (val) {
        const date = moment(val * 1000);
        return (
          <div>
            {date.format('M/D/YY')}
            <br />
            {date.format('h:mma')}
          </div>
        );
      }
      return '';
    }
  },
  {
    title: 'Joined',
    dataIndex: 'createdAt',
    className: 'joined',
    render: val => moment(val, 'YYYY-MM-DD HH:mm:SS ZZ').format('M/D/YY')
  },
  {
    title: 'Rating',
    dataIndex: 'engagementRate',
    className: 'rating',
    render: (val, { avgAttitudeProfessionalism, avgContentQuality }) =>
      formatSocialDataMin((avgContentQuality + avgAttitudeProfessionalism) / 2)
  },
  {
    title: 'Followers',
    dataIndex: 'followedByCount',
    className: 'followers',
    render: formatSocialData
  },
  { title: 'Apps', dataIndex: 'applicationCount', className: 'apps' },
  {
    title: 'Last App Date',
    dataIndex: 'lastAppliedDate',
    className: 'last-applied-date',
    render: val => (val === 0 ? '--' : moment(val * 1000).format('M/D/YY'))
  },
  {
    title: 'Active',
    dataIndex: 'activePartnershipCount',
    className: 'active',
    render: (val, { key }) => {
      return (
        <Popover
          arrowPointAtCenter
          trigger="hover"
          placement="bottomRight"
          content={<InfluencerActiveCampaigns influencerUID={key} />}
        >
          <div className="active-partnerships">{val}</div>
        </Popover>
      );
    }
  },
  {
    title: 'Complete',
    dataIndex: 'completedPartnershipCount',
    className: 'complete',
    render: val => {
      return <div className="completed-partnerships">{val}</div>;
    }
  },
  { title: 'Level', dataIndex: 'star', className: 'level', render: val => <Badge level={val} /> }
];

const initialPagination = { page: 1, limit: 10 };

const subscribed = [];

const Page = () => {
  const dispatch = useDispatch();
  const [influencerDrawer, handleShow, handleHide] = useInfluencerDrawer();
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState(initialPagination);
  const { influencers: influencerUIDs = [], lastInfluencerDoc } = useSelector(
    state => state.ui.admin.influencers
  );

  function retrieveInfluencers(newFilters = {}, reload) {
    const listener = listenInfluencers(
      newFilters,
      reload ? null : lastInfluencerDoc,
      pagination.limit,
      influencerDocs => {
        let influencers = {};
        let lastDocID;
        influencerDocs.forEach(doc => {
          const influencer = doc.data();

          influencers = {
            ...influencers,
            [doc.id]: { details: { ...influencer } }
          };
          dispatch(setInfluencers(influencers));
          dispatch(adminPushInfluencer(doc.id));
          lastDocID = doc;
        });
        dispatch(adminUpdateLastInfluencerDoc(lastDocID));
      }
    );

    subscribed.push(listener);
  }

  function removeAllSubscriber() {
    while (subscribed.length > 0) {
      subscribed.pop()();
    }
    console.log('UNSUBSCRIBE');
  }

  useEffect(() => {
    retrieveInfluencers(filters, true);

    return removeAllSubscriber;
  }, []);

  function loadMore() {
    setPagination({
      ...pagination,
      page: pagination.page + 1
    });

    retrieveInfluencers(filters);
  }

  function handleChange(newFilters) {
    removeAllSubscriber();
    dispatch({ type: 'ADMIN_RESET_INFLUENCERS' });
    retrieveInfluencers(newFilters, true);
    setFilters(newFilters);
    setPagination(initialPagination);
  }

  async function handleSuspend(influencerUID) {
    dispatch(adminRemoveInfluencer(influencerUID));
    handleHide();
  }

  async function handleDownload() {
    const influencers = await influencersSearch(filters).get();
    let csvContent = '';

    influencers.docs.forEach(doc => {
      const {
        details: { authEmail = '', completeAddress, name, username }
      } = new InfluencerWrapper({ details: doc.data() });
      const row = [`"${name}"`, `"${username}"`, `"${authEmail}"`, `"${completeAddress}"`].join(
        ','
      );
      csvContent += `${row}\r\n`;
    });
    const csv = new Blob([csvContent], { type: 'text/CSV' });
    const url = URL.createObjectURL(csv);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'influencer-info.csv');
    link.click();
  }

  const showLimit = pagination.limit * pagination.page - pagination.page;

  return (
    <div id="admin-influencer-page">
      <Container width={940}>
        <InfluencersStatistics />
      </Container>
      <Container width={940}>
        <Row type="flex" justify="space-between" className="search-filters">
          <AdminInfluencerFilters onChange={handleChange} onDownload={handleDownload} />
        </Row>
        {influencerUIDs.length > 0 && (
          <>
            <Row>
              <Col>
                <InfluencerTable
                  influencerUIDs={influencerUIDs}
                  columns={columns}
                  onRowClick={({ key }) => handleShow(key)}
                />
              </Col>
            </Row>
            <div className="text-center">
              {influencerUIDs.length > showLimit && influencerUIDs.length !== 0 && (
                <Button className="load-more" onClick={loadMore}>
                  LOAD MORE
                </Button>
              )}
            </div>
            <InfluencerCampaignProvider>
              <InfluencerAdminDrawer
                {...influencerDrawer}
                onHide={handleHide}
                actionButtons={influencerUID => [
                  <SuspendButton influencerUID={influencerUID} onSuspend={handleSuspend} />
                ]}
              />
            </InfluencerCampaignProvider>
          </>
        )}
      </Container>
    </div>
  );
};

export default Page;
