import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, Table, Button, message, Select } from 'antd';
import moment from 'moment';
import { businessSubs, businessListener } from '../../../listeners/businesses';
import { firebase } from '../../../lib/Firebase';
import Container from '../../../components/Common/Container';

const columns = [
  {
    key: 'name',
    title: 'Name',
    render: business => {
      return business && business.name;
    }
  },
  {
    key: 'website',
    title: 'Website',
    render: business => {
      return business && business.website;
    }
  },
  {
    key: 'email',
    title: 'Email',
    render: business => {
      return business && business.email;
    }
  },
  {
    key: 'applicationDate',
    dataIndex: 'applicationDate',
    title: 'Application Date'
  },
  {
    key: 'approvalData',
    dataIndex: 'approvalDate',
    title: 'Approval Date'
  },
  {
    key: 'registrationDate',
    dataIndex: 'registrationDate',
    title: 'Registration Date'
  },
  {
    key: 'firstCampaign',
    dataIndex: 'firstCampaign',
    title: 'First Campaign',
    render: firstCampaign => (firstCampaign ? <span>&#10003;</span> : null)
  },
  {
    key: 'firstCredit',
    dataIndex: 'firstCredit',
    title: 'First Credit',
    render: firstCredit => (firstCredit ? <span>&#10003;</span> : null)
  },
  {
    title: 'Action',
    dataIndex: 'actions',
    align: 'center',
    key: 'actions',
    className: 'actions',
    render: ({ status, onApprove, onReject }) => {
      const displayRecord = () => {
        let display = null;
        if (status === 'approved') {
          display = 'Approved';
        } else if (status === 'rejected') {
          display = 'Rejected';
        } else {
          display = (
            <div style={{ width: '162px' }}>
              <Button type="primary" onClick={onApprove}>
                Approve
              </Button>
              <Button onClick={onReject}>Reject</Button>
            </div>
          );
        }
        return display;
      };
      return <>{displayRecord()}</>;
    }
  }
];

const initialPagination = { page: 1, limit: 50 };
const modalInitial = { show: false, businessId: null, index: null, email: null };
const BusinessPage = () => {
  const [pagination, setPagination] = useState(initialPagination);
  const [approveModal, setApproveModal] = useState(modalInitial);
  const [rejectModal, setRejectModal] = useState(modalInitial);
  const [businessesList, setBusinessesList] = useState([]);
  const [lastBusinessDoc, setLastBusinessDoc] = useState(0);
  const [filtersState, setFiltersState] = useState({});
  const [newFilters, setNewFilters] = useState({});

  async function retrieveBusinesses(lastDocBusiness = null, filters = {}) {
    const listener = await businessListener(
      lastDocBusiness,
      filters || {},
      pagination.limit,
      (businesses, businessLastDoc, newlyFilter) => {
        if (lastDocBusiness) {
          setBusinessesList(prevState => {
            return [...prevState, ...businesses];
          });
        } else {
          setBusinessesList(businesses);
        }

        if (Object.keys(newlyFilter).length > 0) {
          setNewFilters(newlyFilter);
        }

        setLastBusinessDoc(businessLastDoc);
      }
    );

    businessSubs.addListener(listener);
  }

  useEffect(() => {
    async function requestBusiness() {
      retrieveBusinesses();
    }

    requestBusiness();
  }, []);

  const updateState = (status, isApprove) => {
    const updateBusinessList = businessesList;
    if (isApprove) {
      const { index } = approveModal;
      updateBusinessList[index].status = status;
      setApproveModal(modalInitial);
    } else {
      const { index } = rejectModal;
      updateBusinessList[index].status = status;
      setRejectModal(modalInitial);
    }
    setBusinessesList(updateBusinessList);
  };

  async function updateBrandStatus(email, status, field) {
    let brandUID = null;
    await firebase.firestore
      .collection('brands')
      .where('email', '==', email)
      .get()
      .then(doc => {
        doc.docs.map(data => {
          brandUID = data.id;
          return data.id;
        });
      });
    if (brandUID) {
      firebase
        .brand(brandUID)
        .child('details')
        .update(
          {
            status,
            [field]: new Date().getTime() / 1000
          },
          err => {
            if (err) {
              // eslint-disable-next-line no-console
              console.log(err);
            }
          }
        );
    }
  }

  async function handleApprove() {
    try {
      await firebase.firestore
        .collection('businesses')
        .where('email', '==', approveModal.email)
        .get()
        .then(doc => {
          doc.docs.map(data => {
            data.ref.update({
              status: 'approved',
              approvedAt: new Date().getTime() / 1000
            });

            return data;
          });
        });

      updateBrandStatus(approveModal.email, 'approved', 'approvedAt');

      // check if already exist brand table
      const brandInfo = await firebase.firestore
        .collection('brands')
        .where('email', '==', approveModal.email)
        .get();
      await firebase.newOnboardingApproveBusiness(approveModal.email, !brandInfo.empty);

      updateState('approved', true);
      message.success('Successfully Approved!');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('error', e);
      message.error('Something went wrong');
    }
  }

  async function handleReject() {
    try {
      await firebase.firestore
        .collection('businesses')
        .where('email', '==', rejectModal.email)
        .get()
        .then(doc => {
          doc.docs.map(data => {
            data.ref.update({
              status: 'rejected',
              rejectedAt: new Date().getTime() / 1000
            });

            return data;
          });
        });

      updateBrandStatus(rejectModal.email, 'rejected', 'rejectedAt');

      updateState('rejected');
      message.success('Successfully Rejected!');
    } catch (e) {
      message.error('Something went wrong');
    }
  }

  async function loadMore(filters = {}) {
    setPagination({
      ...pagination,
      page: pagination.page + 1
    });

    await retrieveBusinesses(lastBusinessDoc, filters);
  }

  async function handleChange(filters) {
    setLastBusinessDoc(null);
    setNewFilters({});
    setFiltersState(filters);
    setPagination(initialPagination);

    await retrieveBusinesses(null, filters);
  }

  const showLimit = pagination.limit * pagination.page;

  const source = businessesList
    .filter(data => Object.keys(data).length > 0)
    .map((data, index) => {
      let registrationDate = null;

      if (data.registeredAt && data.status === 'approved') {
        registrationDate = moment.unix(data.registeredAt).format('YY/MM/DD');
      }

      return {
        key: data.uid,
        name: data.name,
        website: data.website ? data.website : '',
        email: data.email,
        applicationDate: data.appliedAt ? moment.unix(data.appliedAt).format('YY/MM/DD') : null,
        approvalDate: data.approvedAt ? moment.unix(data.approvedAt).format('YY/MM/DD') : null,
        registrationDate,
        photo: data.logo,
        firstCampaign: data.firstCampaign,
        firstCredit: data.firstCredit,
        actions: {
          status: data.status,
          onApprove: () =>
            setApproveModal({ show: true, businessId: data.uid, index, email: data.email }),
          onReject: () =>
            setRejectModal({ show: true, businessId: data.uid, index, email: data.email })
        }
      };
    })
    .slice(0, showLimit);

  return (
    <Container width={1300} id="admin-business-approval">
      <Row type="flex" justify="space-between" className="search-filters">
        <Col md={10} xs={24} />
        <Col md={10} xs={24}>
          <Row type="flex" justify="end" gutter={8}>
            <Col md={6}>
              <Select
                allowClear
                showArrow={false}
                className="trend-select"
                placeholder="Status: All"
                value={filtersState.status}
                onChange={val => {
                  handleChange({
                    hasRecord: filtersState.hasRecord,
                    status: val || undefined
                  });
                }}
              >
                <Select.Option value="pending">Pending</Select.Option>
                <Select.Option value="approved">Approved</Select.Option>
                <Select.Option value="rejected">Rejected</Select.Option>
              </Select>
            </Col>
            <Col md={6}>
              <Select
                allowClear
                showArrow={false}
                className="trend-select"
                placeholder="Has: All"
                value={filtersState.hasRecord}
                onChange={val => {
                  handleChange({
                    hasRecord: val || undefined,
                    status: filtersState.status
                  });
                }}
              >
                <Select.Option value="has_registered">Has Registered</Select.Option>
                <Select.Option value="has_not_registered">Has Not Registered</Select.Option>
                <Select.Option value="has_campaign">Has Campaign</Select.Option>
                <Select.Option value="has_no_campaign">Has No Campaign</Select.Option>
                <Select.Option value="has_credits">Has Credits</Select.Option>
                <Select.Option value="has_no_credits">Has No Credits</Select.Option>
              </Select>
            </Col>
          </Row>
        </Col>
      </Row>

      <Table dataSource={source} columns={columns} pagination={false} showHeader />
      <Modal
        visible={approveModal.show}
        closable={false}
        footer={false}
        className="admin-approve-modal"
      >
        <p className="text-center">Are you sure you want to approve this Business?</p>
        <Row type="flex" justify="center">
          <Col className="button-options">
            <Button type="primary" block onClick={() => handleApprove()}>
              Confirm
            </Button>
            <Button block onClick={() => setApproveModal({ ...approveModal, show: false })}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        visible={rejectModal.show}
        closable={false}
        footer={false}
        className="admin-approve-modal"
      >
        <p className="text-center">Are you sure you want to reject this Business?</p>
        <Row type="flex" justify="center">
          <Col className="button-options">
            <Button type="primary" block onClick={() => handleReject()}>
              Confirm
            </Button>
            <Button block onClick={() => setRejectModal({ ...rejectModal, show: false })}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal>
      <div className="text-center loadmore">
        {businessesList.length > showLimit - 1 && (
          <Button
            className="load-more"
            onClick={() => loadMore({ ...filtersState, ...newFilters })}
          >
            LOAD MORE
          </Button>
        )}
      </div>
    </Container>
  );
};

export default BusinessPage;
