import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Container from 'components/Common/Container';
import Header from './Header';
import activeCampaigns from './Brands/activeCampaigns';
import { listenAllBrands } from '../../listeners/brands';
import { updateBrand } from '../../actions/brands';
import { getCategories } from '../../actions/categories';
import ApprovalPage from './ApprovalsPage';
import InfluencersPage from './InfluencersPage';
import ReviewsPage from './ReviewsPage';
import ReferralsPage from './ReferralsPage';
import YotPoMetric from './YotPoMetric';
import PayoutsPage from './PayoutsPage';
import BusinessesPage from './BusinessPage';
import OnboardingGuides from './OnboardingGuides';
import BusinessesForUpdate from './BusinessesForUpdate';
import { getAllBrandSearches } from '../../actions/brandSearch';

const main = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllBrandSearches(null));
  }, [dispatch, getAllBrandSearches]);

  useEffect(() => {
    const unsubscribeBrands = listenAllBrands({
      onUpdate: (id, brand) => {
        dispatch(updateBrand(id, brand));
      }
    });
    dispatch(getCategories());
    return () => {
      unsubscribeBrands.off();
    };
  }, []);

  const preview = window.location.href.includes('/adminv2/onboardingGuides/preview');

  return (
    <Container id="admin-layout" width="100%">
      {!preview && <Header />}
      <Switch>
        <Route exact path="/adminv2/activeCampaigns" component={activeCampaigns} />
        <Route exact path="/adminv2/influencers" component={InfluencersPage} />
        <Route exact path="/adminv2/approvals" component={ApprovalPage} />
        <Route exact path="/adminv2/reviews" component={ReviewsPage} />
        <Route exact path="/adminv2/referrals" component={ReferralsPage} />
        <Route exact path="/adminv2/businesses" component={BusinessesPage} />
        <Route exact path="/adminv2/yotpoMetric" component={YotPoMetric} />
        <Route path="/adminv2/payouts" component={PayoutsPage} />
        <Route exact path="/adminv2/onboardingGuides" component={OnboardingGuides} />
        <Route exact path="/adminv2/businesses/forUpdate" component={BusinessesForUpdate} />
        <Redirect from="/adminv2/" to="/adminv2/activeCampaigns" />
      </Switch>
    </Container>
  );
};

export default main;
