/* eslint-disable import/prefer-default-export */
export const PARTNERSHIP_STATUS = {
  SUBMITTED: 'submitted', // Influencer Submitted and application to the campaign
  NEEDS_SHIPPING: 'needsShipping', // Influencer Approved in the Campaign
  SHIPPED: 'shipped', // Influencer products is shipped by the campaign
  ACTIVE: 'active', // Influencer Receives the product from the brand
  POST_SUBMITTED: 'postSubmitted', // Influencer Posted all of the required posts from a campagin
  REJECTED: 'rejected', // Influencer is rejected from the campaign
  COMPLETED: 'completed' // Influencer completed his partnership with the campaign
};

export const PARTNERSHIP_PAYOUT_STATUS = {
  PAID: 'paid',
  PROCESSING: 'processing',
  DENIED: 'denied'
};
