import { useState } from 'react';

const useInfluencerDrawer = () => {
  const [influencerDrawer, setInfluencerDrawer] = useState({
    show: false,
    influencerUID: null
  });

  function show(influencerUID) {
    setInfluencerDrawer({ show: true, influencerUID });
  }

  function hide() {
    setInfluencerDrawer(state => ({ ...state, show: false }));
  }

  return [influencerDrawer, show, hide];
};

export default useInfluencerDrawer;
