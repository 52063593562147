/* eslint no-console: ["error", { allow: ["log"] }] */
import axios from 'axios';
import { UserToken } from 'lib/Firebase';
import Routes from 'api/routes';

export default async (influencerUID, campaignUID, callback) => {
  const { headers } = await UserToken();
  const data = {
    influencerUID,
    campaignUID,
  };

  axios.post(Routes.paypalPayout, data, { headers }).then((response) => {
    callback({ payoutBatchId: response.data.payoutBatchId });
  }).catch((error) => {
    const errMsg = error.response.data ? error.response.data.msg : error;
    callback({}, errMsg);
  });
};
