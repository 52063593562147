export const SET_APPLICANT_LIST = "SET_APPLICANT_LIST";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_APPLICANT_LIST:
      return [...new Set(action.influencerUIDs)];
    default:
      return state;
  }
};
