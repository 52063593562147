import { ADD_POST_STATISTICS } from 'reducers/entities/postStatistics';
import { SET_POST_GRAPH_FILTER_TYPE } from 'reducers/ui/postGraph';
import { firebase } from 'lib/Firebase';

export const addPostStats = (brandUID, stats) => ({
  type: ADD_POST_STATISTICS,
  payload: {
    [brandUID]: stats,
  },
});

export const fetchPostStatistics = (brandUID, productUID) => (dispatch) => {
  let query = firebase.postStatistics(brandUID);
  query = query.child(productUID);

  return query.once('value')
    .then((snap) => {
      let stats = {};

      if (!snap.exists()) {
        return stats;
      }

      stats = snap.val();

      dispatch(addPostStats(brandUID, { [productUID]: stats }));

      return stats;
    })
    .catch((err) => {
      console.error('Error on retrieving Post Statistics', err);

      return {};
    });
};

export const setPostGraphFilter = filterBy => ({
  type: SET_POST_GRAPH_FILTER_TYPE,
  payload: filterBy,
});
