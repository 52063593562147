import React, { useState, useEffect } from 'react';
import TrendLogo from 'images/trend-black@3x.png';
import useReactRouter from 'use-react-router';
import { Modal, Row, Col, Button } from 'antd';

const FreeTrialModal = props => {
  const { show } = props;
  const { history } = useReactRouter();
  const [showFreeTrialModal, setShowFreeTrialModal] = useState(show);

  useEffect(() => {
    setShowFreeTrialModal(show);
  }, [props]);

  return (
    <Modal
      visible={showFreeTrialModal}
      closable={false}
      className="trend-modal"
      footer={null}
      width={423}
    >
      <div
        style={{
          marginTop: '20px',
          marginBottom: '40px',
          textAlign: 'center'
        }}
      >
        <img src={TrendLogo} alt="trend-logo" width="95" height="18" />
      </div>
      <div
        style={{
          fontFamily: 'truenoextrabold',
          fontSize: '18px',
          lineHeight: '1.33',
          textAlign: 'center',
          color: '#000000',
          marginBottom: '8px'
        }}
      >
        To approve or reject influencers
        <br />
        you must select your plan
      </div>
      <div
        style={{
          fontSize: '13px',
          lineHeight: '1.77',
          textAlign: 'center',
          color: '#000000',
          marginBottom: '50px'
        }}
      >
        New influencers are constantly discovering
        <br />
        your campign. To unlock the full list of
        <br />
        influencers you can work with, select a plan
        <br />
        and get your campaign underway!
      </div>
      <Row gutter={20} key="row-multi-action">
        <Col span={12}>
          <Button
            key="cancel"
            className="trend-btn medium-btn secondary"
            onClick={() => setShowFreeTrialModal(false)}
            block
          >
            Cancel
          </Button>
        </Col>
        <Col span={12}>
          <Button
            key="approve"
            type="primary"
            className="trend-btn medium-btn"
            onClick={() => {
              history.push('/account/subscription');
            }}
            block
          >
            Select Plan
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default FreeTrialModal;
