import React from 'react';
import {
  Empty,
  Collapse,
  Icon,
  Steps,
} from 'antd';
import moment from 'moment';
import { DATE_TIMEZONE_FORMAT } from 'constants/common';

const { Panel } = Collapse;
const { Step } = Steps;

const customPanelStyle = {
  background: '#f7f7f7',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden',
};

const ShippingHistory = ({ events: e }) => {
  const events = e || [];
  const eventList = events.map(event => (
    <Step
      key={event.occurred_at}
      title={event.description}
      description={moment(event.occurred_at, DATE_TIMEZONE_FORMAT).format('h:mm A ddd, MMM D YYYY')}
      style={{ width: '100%' }}
    />
  ));

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
      style={{ marginTop: '22px' }}
    >
      <Panel header="Tracking Details" style={customPanelStyle}>
        {
          events.length > 0 ? (
            <Steps direction="vertical" status="success" size="small" current={events.length}>
              { eventList }
            </Steps>
          ) : (
            <div>
              <Empty description={<span style={{ color: '#ddd' }}>No History yet.</span>} style={{ marginTop: '20px' }} />
            </div>
          )
        }
      </Panel>
    </Collapse>
  );
};

export default ShippingHistory;
