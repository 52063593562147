import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import 'styles/base/antd-custom.less';
import { InlineWidget } from 'react-calendly';
import { firebase } from '../../lib/Firebase';
import trendLogo from '../../images/trend@2x.png';
import Container from '../../components/Common/Container';
import useLoading from '../../components/Common/useLoading';

const Onboarding = () => {
  const [LoadingScreen, setLoading] = useLoading();
  const history = useHistory();
  useEffect(() => {
    setLoading(true);
    const listener = firebase.auth.onAuthStateChanged(authUser => {
      setLoading(false);
      if (!authUser) {
        history.push('/login');
      }

      window.scrollTo(0, 0);
    });
    return () => {
      listener();
    };
  }, []);

  return (
    <LoadingScreen>
      <div id="onboarding-page" className="bg-black">
        <Container width={940}>
          <Row className="header" type="flex" justify="center">
            <Col span="8">
              <div style={{ textAlign: 'center' }}>
                <img
                  alt="Trend"
                  src={trendLogo}
                  width="70"
                  height="13"
                  style={{ display: 'inline-block' }}
                />
              </div>
            </Col>
          </Row>
          <Row type="flex" justify="center" style={{ marginBottom: '40px' }}>
            <Col className="setup-steps">
              <div className="step">
                <div className="step-symbol">1</div>
                <div className="step-label">Confirm Payment</div>
              </div>
              <div className="step active">
                <div className="step-symbol">2</div>
                <div className="step-label">Schedule Onboarding</div>
              </div>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <h1>
              Welcome to Trend!
              <br /> Schedule Onboarding Call below
            </h1>
          </Row>
          <Row type="flex" justify="center" align="top">
            <Col className="calendar">
              <InlineWidget
                url="https://calendly.com/jamie-catanach/onboarding?background_color=000000&text_color=ffffff&primary_color=fbb900"
                styles={{ width: '100%', height: '950px' }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </LoadingScreen>
  );
};

export default Onboarding;
