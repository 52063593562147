import React, { Component } from 'react';
import { Row, Col, message } from 'antd';
import { withRouter } from 'react-router-dom';
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement
} from 'react-stripe-elements';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { updateLoggedUser } from 'actions/session';
import { addCredits } from '../../actions/ui/brandAccount';
import { createCard } from '../../api/subscriptions';
import { purchasedTag } from '../../lib/Analytics';

const inputStyles = {
  fontWeight: 'normal',
  fontSize: '15px',
  lineHeight: '18px',
  color: '#FFB917',
  '::placeholder': {
    color: '#757575',
    marginBottom: '19px',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '18px'
  }
};

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    const { onRef } = props;
    if (onRef) {
      onRef(this);
    }
  }

  componentWillUnmount() {
    const { onRef } = this.props;
    onRef(undefined);
  }

  validateCheckoutForm = async email => {
    const { stripe } = this.props;
    const tokenResponse = await stripe.createToken({ name: email });
    return tokenResponse;
  };

  handleSubmit = async (currentUser, onComplete, onError) => {
    const { standardCredits = 0, vipCredits = 0, stripe, payCredits } = this.props;

    const tokenResponse = await stripe.createToken({ name: currentUser.email });
    let createResponse = null;
    if (tokenResponse.error) {
      message.error(tokenResponse.error.message);
      onError();
    } else {
      const { token } = tokenResponse;
      createResponse = await createCard(token);
      if (standardCredits !== 0 || vipCredits !== 0) {
        payCredits(currentUser.uid, standardCredits, vipCredits, async (response, error) => {
          if (error) {
            console.log(error);
            message.error(error.response.data.message);
            onError();
          } else {
            await purchasedTag(currentUser.uid);
            onComplete();
          }
        });
      } else if (createResponse) {
        onComplete();
      } else {
        onError();
      }
    }
  };

  render() {
    return (
      <div className="text-white checkout dark-theme white" style={{ fontSize: '18px' }}>
        <Row>
          <label className="text-gray-darkest">
            <span style={{ color: '#fff', fontWeight: 'bold' }} className="type-sfpro-medium">
              Card number (you will not be charge until you accept creators)
            </span>
            <CardNumberElement
              placeholder="XXXX-XXXX-XXXX-XXXX"
              style={{
                base: inputStyles
              }}
              className="stripe-element-input"
            />
          </label>
        </Row>
        <Row gutter={24} style={{ marginTop: '20px' }}>
          <Col md={10} xs={24}>
            <label className="text-gray-darkest">
              <span style={{ color: '#fff', fontWeight: 'bold' }} className="type-sfpro-medium">
                CVC Number
              </span>
              <CardCVCElement
                style={{ base: inputStyles }}
                className="stripe-element-input"
                placeholder="Enter CVC Number"
              />
            </label>
          </Col>
          <Col md={7} xs={12}>
            <label className="text-gray-darkest">
              <span style={{ color: '#fff', fontWeight: 'bold' }} className="type-sfpro-medium">
                Expiration Date
              </span>
              <CardExpiryElement style={{ base: inputStyles }} className="stripe-element-input" />
            </label>
          </Col>
          <Col md={7} xs={12}>
            <label className="text-gray-darkest">
              <span style={{ color: '#fff', fontWeight: 'bold' }} className="type-sfpro-medium">
                Postal Code
              </span>
              <PostalCodeElement
                style={{
                  base: inputStyles
                }}
                className="stripe-element-input"
              />
            </label>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.entities.session.authUserData
});

const mapDispatchToProps = dispatch => ({
  payCredits: (uid, selectedStandardCredits, selectedVipCredits, onComplete) => {
    dispatch(addCredits(uid, selectedStandardCredits, selectedVipCredits, onComplete));
  },
  updateSession: authUserData => {
    dispatch(updateLoggedUser(authUserData));
  }
});
export default compose(
  withRouter,
  injectStripe,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CheckoutForm);
