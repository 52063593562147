/* eslint-disable no-restricted-syntax */
import React from 'react';
import { Form, Button, Input, Row, Col, InputNumber, Radio, Tabs } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import TrendSelect from '../Form/TrendSelect';
import TagSelect from '../Form/TagSelect';
import ImageUpload from '../Form/ImageUploader';
import ImageUploaderV2 from '../Form/ImageUploaderV2';
import { firebase } from '../../lib/Firebase';
import { withAuthUser } from '../../lib/Session';
import { UpCaseFirstLetter } from '../../lib/Common';

const { TextArea } = Input;

const Content = ({ children, extra }) => (
  <Row type="flex" style={{ minHeight: 'calc(100vh - 45px)' }}>
    <Col
      style={{
        width: 'calc(100% - 320px)',
        padding: '44px 74px 0',
        borderRight: '1px solid #3C3C3C'
      }}
    >
      <Row type="flex" justify="center">
        <Col style={{ width: 596 }}>{children}</Col>
      </Row>
    </Col>
    <Col className="page-other-details" style={{ width: '320px', padding: '30px 14px 0' }}>
      {extra}
    </Col>
  </Row>
);

const tabsFields = [
  ['companyName', 'storeImage', 'companyDescription'],
  ['name', 'image', 'value', 'url'],
  ['gender'],
  ['brandtags', 'hashtags'],
  [
    'category',
    'numberOfPosts',
    'postTypes[0]',
    'postTypes[1]',
    'isStoryRequired',
    'postGuidelines[0]',
    'postGuidelines[1]',
    'postGuidelines[2]',
    'styleguide'
  ]
];

const cappedAmount = 175;

class CampaignForm extends React.Component {
  state = {
    submitted: false,
    submitting: false,
    categories: [],
    tab: '0',
    list: []
  };

  componentDidMount() {
    const {
      form: { setFieldsValue },
      campaign,
      brandUID
    } = this.props;

    firebase.adminCategories().once('value', snap => {
      const categories = snap.val();

      this.setState({
        categories: Object.keys(categories).map(x => ({
          key: categories[x],
          value: UpCaseFirstLetter(categories[x])
        }))
      });
    });
    this.setState({ tab: brandUID ? '0' : '1' });
    setFieldsValue({ ...campaign });

    this.onboardingGuide(brandUID ? 'brand' : 'product');
  }

  validateMaximumPost = productValue => (rule, val, callback) => {
    if (productValue < 175 && val > 1) {
      callback(false);
    } else {
      callback();
    }
  };

  handleSubmit = () => {
    const { form, onSubmit } = this.props;
    const { validateFieldsAndScroll } = form;

    this.setState({ submitting: true, submitted: true });

    validateFieldsAndScroll(async (error, fields) => {
      if (!error) {
        const payload = {
          ...fields,
          states: { All: 'All' }
        };

        Object.keys(payload).forEach(keys => {
          if (payload[keys] === undefined) {
            delete payload[keys];
          }
        });

        this.setState({ submitting: false });
        await onSubmit(payload);
      } else {
        const faildFlds = Object.keys(error);
        let failedTab = 0;

        for (const flds of tabsFields) {
          const index = tabsFields.indexOf(flds);
          const matchfld = flds.filter(fld => faildFlds.includes(fld));
          if (matchfld.length > 0) {
            failedTab = index;
            break;
          }
        }
        onSubmit(fields, true);
        this.setState({ submitting: false, tab: failedTab.toString() });
      }
    });
  };

  handleTabChange = tab => {
    const options = {
      0: 'brand',
      1: 'product',
      2: 'creators',
      3: 'social',
      4: 'action'
    };

    this.setState({ tab });
    this.onboardingGuide(options[tab]);
  };

  checkHasError = (fields = []) => {
    const { form } = this.props;
    const { submitted } = this.state;
    if (!submitted) {
      return false;
    }

    let hasError = false;
    fields.forEach(x => {
      if (form.getFieldError(x)) {
        hasError = true;
      }
    });
    return hasError;
  };

  handleNextSubmit = () => {
    const {
      form: { validateFieldsAndScroll },
      onSubmit
    } = this.props;
    const { tab } = this.state;
    const intTab = parseInt(tab, 10);
    const fields = tabsFields[intTab];
    validateFieldsAndScroll(fields, {}, (error, values) => {
      if (!error) {
        onSubmit(values, true);
        this.setState({ tab: (intTab + 1).toString() });
      }
    });
  };

  onboardingGuide = async (option = 'product') => {
    const data = await firebase.firestore
      .collection('onboardingGuides')
      .where('selectedOption', '==', option)
      .orderBy('key', 'asc')
      .get();
    if (!data.empty) {
      const tmpList = [];
      data.docs.map(infoData => {
        const info = infoData.data();
        tmpList.push(
          <div key={infoData.id} style={{ marginBottom: '21px' }}>
            {info.formType === 'title' && <h3>{info.title}</h3>}
            {info.formType === 'image' &&
              info.storeImage.map(image => {
                return <img src={image} alt={image} style={{ marginBottom: '23px' }} />;
              })}
            {info.formType === 'content' && (
              <p style={{ whiteSpace: 'pre-line' }}>{info.content}</p>
            )}
            {info.formType === 'youtube' && (
              <>
                <iframe
                  title="Onboarding"
                  src={info.youtube}
                  width="290"
                  height="163"
                  frameBorder="0"
                  allowFullScreen
                />
                <h3 style={{ marginTop: '40px' }}>Need Help Setting Up Your Brief?</h3>
                <div style={{ width: 292, marginBottom: '39px' }}>
                  <a
                    href="https://calendly.com/jamie-catanach/onboarding"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ant-btn ant-btn-primary ant-btn-block"
                  >
                    Schedule A Call
                  </a>
                </div>
              </>
            )}
          </div>
        );
        return info;
      });

      this.setState({ list: tmpList });
    } else {
      this.setState({ list: [] });
    }
  };

  renderBrandForm = () => {
    const {
      form: { getFieldDecorator },
      brandUID
    } = this.props;
    const { list } = this.state;

    return (
      <Tabs.TabPane
        tab={
          <span className={`${this.checkHasError(tabsFields[0]) ? 'hasError' : ''} `}>Brand</span>
        }
        key="0"
      >
        <Content extra={list}>
          <h1 className="page-header">Show Your Brand to Creators</h1>
          <div className="page-description">
            We’ll need an image for your brand and a quick description of your company. This will
            help attract the right creators.
          </div>
          <Form.Item style={{ marginBottom: '40px' }}>
            {getFieldDecorator('storeImage', { rules: [{ required: true, message: 'Required' }] })(
              <ImageUpload filename={brandUID}>
                <div className="label">Add any image that represents your brand</div>
              </ImageUpload>
            )}
          </Form.Item>
          <Form.Item label="Company Name">
            {getFieldDecorator('companyName', { rules: [{ required: true, message: 'Required' }] })(
              <Input
                placeholder="Company Name"
                className="trend-textarea dark"
                style={{ height: '51px' }}
              />
            )}
          </Form.Item>
          <Form.Item label="Company Description" style={{ marginBottom: '56px' }}>
            {getFieldDecorator('companyDescription', {
              rules: [{ required: true, message: 'Required' }]
            })(
              <Input.TextArea
                className="trend-textarea dark"
                placeholder="2-3 sentences that decribe your company"
                style={{ minHeight: '155px' }}
              />
            )}
          </Form.Item>
          <div className="text-center submit">
            <Button type="primary" onClick={this.handleNextSubmit}>
              Next Step - Campaign Basics
            </Button>
          </div>
        </Content>
      </Tabs.TabPane>
    );
  };

  render() {
    const {
      form: { getFieldDecorator, getFieldValue },
      campaignUID,
      campaign,
      brandUID
    } = this.props;
    const { tab, submitting, categories, list } = this.state;
    const productValue = getFieldValue('value') || 0;
    const numberOfPosts = getFieldValue('numberOfPosts') || 0;
    const { postTypes = [], gender = null } = campaign;

    return (
      <Form
        layout="vertical"
        className="product-form"
        hideRequiredMark
        onSubmit={this.handleSubmit}
      >
        <Tabs
          defaultActiveKey={brandUID ? '0' : '1'}
          activeKey={tab}
          tabPosition="left"
          onTabClick={this.handleTabChange}
        >
          {brandUID ? this.renderBrandForm() : ''}
          <Tabs.TabPane
            tab={(
              <span className={`${this.checkHasError(tabsFields[1]) ? 'hasError' : ''} `}>
                Product
              </span>
)}
            key="1"
          >
            <Content extra={list}>
              <h1 className="page-header">Your Product Info</h1>
              <div className="page-description">
                Add an attractive image of your product to catch the creators attention, and set the
                stage for the results you’re looking for.
              </div>

              <Form.Item label="Name your campaign" style={{ marginBottom: '39px' }}>
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: 'Add a campaign name' }]
                })(
                  <Input
                    type="text"
                    placeholder="Add a campaign name"
                    size="large"
                    className="trend-input dark large type-light"
                  />
                )}
              </Form.Item>
              <Form.Item style={{ textAlign: 'center' }}>
                {getFieldDecorator('image', {
                  rules: [{ required: true, message: 'Campaign Image is required.' }]
                })(
                  <ImageUpload
                    filename={campaignUID}
                    ref={ref => {
                      this.uploaderRef = ref;
                    }}
                  >
                    <div className="label">Add any image of your product</div>
                  </ImageUpload>
                )}
              </Form.Item>
              <Row gutter={{ sm: 30 }}>
                <Col sm={12} xs={24}>
                  <Form.Item label="Product Website">
                    {getFieldDecorator('url', {
                      rules: [
                        {
                          transform: urlLink => {
                            if (!/^https?:\/\//i.test(urlLink)) {
                              return `http://${urlLink}`;
                            }

                            return urlLink;
                          }
                        },
                        {
                          required: true,
                          message: 'Please enter website'
                        },
                        {
                          type: 'url',
                          message: 'Please provide a valid website URL.'
                        }
                      ]
                    })(
                      <Input
                        type="text"
                        size="large"
                        className="trend-input dark"
                        placeholder="http://www.website.com"
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col sm={12} xs={24}>
                  <Form.Item label="Product value in dollars">
                    <div>
                      {getFieldDecorator('value', {
                        rules: [
                          {
                            required: true,
                            message: 'Please provide a numeric value.'
                          }
                        ]
                      })(
                        <InputNumber
                          className="trend-input dark"
                          placeholder="$50.00"
                          formatter={v => v && `$${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={v => v.replace(/\$\s?|(,*)/g, '')}
                        />
                      )}
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <div align="center">
                <Button
                  size="large"
                  type="submit"
                  className="trend-btn product-btn"
                  loading={submitting}
                  onClick={this.handleNextSubmit}
                >
                  Next Step - Creators
                </Button>
              </div>
            </Content>
          </Tabs.TabPane>

          <Tabs.TabPane
            tab={(
              <span className={`${this.checkHasError(tabsFields[2]) ? 'hasError' : ''}`}>
                Creators
              </span>
)}
            key="2"
          >
            <Content extra={list}>
              <h1 className="page-header">Target Creators</h1>
              <div className="page-description">
                What kind of creator do you need for your campaign? Do you have a specific gender
                you&apos;re targeting? Enter it below.
              </div>
              <Form.Item label="Target Gender">
                {getFieldDecorator('gender', { initialValue: gender })(
                  <Radio.Group buttonStyle="solid" className="trend-radio-buttons">
                    <Radio.Button value={null}>Any</Radio.Button>
                    <Radio.Button value="female">Female</Radio.Button>
                    <Radio.Button value="male">Male</Radio.Button>
                  </Radio.Group>
                )}
              </Form.Item>
              <div align="center">
                <Button
                  size="large"
                  type="submit"
                  className="trend-btn product-btn"
                  loading={submitting}
                  onClick={this.handleNextSubmit}
                >
                  Next Step - Social Media Details
                </Button>
              </div>
            </Content>
          </Tabs.TabPane>

          <Tabs.TabPane
            tab={(
              <span className={`${this.checkHasError(tabsFields[3]) ? 'hasError' : ''}`}>
                Social
              </span>
)}
            key="3"
          >
            <Content extra={list}>
              <h1 className="page-header">Social Media Details</h1>
              <div className="page-description">
                Make Influencers include your brand’s Instagram handle with their posts. This gives
                audience members will have a clear path to visit your page. Add required hashtags to
                drive people help your posts be discovered by people searching for specific themes.
              </div>
              <Form.Item
                label={(
                  <div className="with-sub-label">
                    Required Instagram Handles
                    <div className="sub-label">Press “Enter” after each handle</div>
                  </div>
)}
              >
                {getFieldDecorator('brandtags', {
                  rules: [
                    {
                      required: true,
                      message: 'Please provide at least one instagram handle'
                    }
                  ]
                })(<TagSelect tagPrefix="@" placeholder="YourHandle" />)}
              </Form.Item>
              <Form.Item
                label={(
                  <div className="with-sub-label">
                    Required Hashtags (Max 5)
                    <div className="sub-label">Press Enter after each tag</div>
                  </div>
)}
              >
                {getFieldDecorator('hashtags', {
                  rules: [{ required: true, message: 'Please provide at least one hashtag' }]
                })(<TagSelect tagPrefix="#" max={5} placeholder="YourHashtag" />)}
              </Form.Item>
              <div align="center">
                <Button
                  size="large"
                  type="submit"
                  className="trend-btn product-btn"
                  onClick={this.handleNextSubmit}
                >
                  Next Step - The Fun Part!
                </Button>
              </div>
            </Content>
          </Tabs.TabPane>

          <Tabs.TabPane
            tab={(
              <span className={`${this.checkHasError(tabsFields[4]) ? 'hasError' : ''}`}>
                Action!
              </span>
)}
            key="4"
          >
            <Content extra={list}>
              <h1 className="page-header">Lights, Camera, Action!</h1>
              <div className="page-description">
                Put on your creative director hat and get specific about the kind of content you
                want the creators to produce.
              </div>
              <Row gutter={{ sm: 30 }}>
                <Col sm={12} xs={24}>
                  <Form.Item
                    label={(
                      <div className="with-sub-label">
                        <span>Product Category</span>
                        <div className="sub-label">Select One</div>
                      </div>
)}
                  >
                    {getFieldDecorator('category', {
                      rules: [{ required: true, message: 'Please choose a category.' }]
                    })(
                      <TrendSelect type="dark" options={categories} placeholder="Select category" />
                    )}
                  </Form.Item>
                </Col>
                <Col sm={12} xs={24}>
                  <Form.Item
                    label={(
                      <div className="with-sub-label">
                        <span>Post Per Creator</span>
                        <div className="sub-label">
                          Brands must provide over $175 to warrant 2 posts.
                        </div>
                      </div>
)}
                  >
                    <div>
                      {getFieldDecorator('numberOfPosts', {
                        rules: [
                          {
                            required: true,
                            message: 'Please choose number of posts.'
                          },
                          {
                            validator: this.validateMaximumPost(productValue),
                            message: 'Only $175 and above can warrant 2 posts'
                          }
                        ]
                      })(
                        <Radio.Group
                          buttonStyle="solid"
                          className="trend-radio-buttons"
                          style={{ width: '123px' }}
                        >
                          <Radio.Button value={1}>1</Radio.Button>
                          {productValue >= cappedAmount ? (
                            <Radio.Button value={2}>2</Radio.Button>
                          ) : (
                            ''
                          )}
                        </Radio.Group>
                      )}
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              {numberOfPosts > 0 && (
                <Form.Item label="What are you looking for in your first post?">
                  {getFieldDecorator('postTypes[0]', {
                    initialValue: postTypes[0],
                    rules: [{ required: true, message: 'Please select a post type' }]
                  })(
                    <Radio.Group buttonStyle="solid" className="trend-radio-buttons">
                      <Radio.Button value="image">Image Post</Radio.Button>
                      <Radio.Button value="video">Video Post</Radio.Button>
                    </Radio.Group>
                  )}
                </Form.Item>
              )}
              {numberOfPosts > 1 && (
                <Form.Item label="What are you looking for in your second post?">
                  {getFieldDecorator('postTypes[1]', {
                    initialValue: postTypes[1],
                    rules: [{ required: true, message: 'Please select a post type' }]
                  })(
                    <Radio.Group buttonStyle="solid" className="trend-radio-buttons">
                      <Radio.Button value="image">Image Post</Radio.Button>
                      <Radio.Button value="video">Video Post</Radio.Button>
                    </Radio.Group>
                  )}
                </Form.Item>
              )}
              <Form.Item label="Instagram Story Required?">
                {getFieldDecorator('isStoryRequired', {
                  rules: [{ required: true, message: 'Please select from the options' }]
                })(
                  <Radio.Group buttonStyle="solid" className="trend-radio-buttons">
                    <Radio.Button id="campaign-is_story_required_no" value={false}>
                      No
                    </Radio.Button>
                    <Radio.Button id="campaign-is_story_required_yes" value>
                      Yes
                    </Radio.Button>
                  </Radio.Group>
                )}
              </Form.Item>

              <Form.Item label="Which products will you send the influencers?">
                {getFieldDecorator('postGuidelines[0]', {
                  rules: [
                    {
                      required: true,
                      message: 'Please provide guidelines.'
                    }
                  ]
                })(
                  <Input
                    className="trend-textarea dark"
                    placeholder="Start Typing..."
                    style={{ height: '51px' }}
                  />
                )}
              </Form.Item>
              <Form.Item label="What would you like the influencers to mention in their caption?">
                {getFieldDecorator('postGuidelines[1]', {
                  rules: [
                    {
                      required: true,
                      message: 'Please provide guidelines.'
                    }
                  ]
                })(
                  <TextArea
                    rows="6"
                    className="trend-textarea dark"
                    placeholder="Start Typing..."
                  />
                )}
              </Form.Item>
              <Form.Item label="What photo / video style are you looking for?">
                {getFieldDecorator('postGuidelines[2]', {
                  rules: [
                    {
                      required: true,
                      message: 'Please provide guidelines.'
                    }
                  ]
                })(
                  <TextArea
                    rows="6"
                    className="trend-textarea dark"
                    placeholder="Start Typing..."
                  />
                )}
              </Form.Item>
              <Form.Item
                label={(
                  <div className="with-sub-label">
                    Style Guide
                    <div className="sub-label">
                      Add images so the creators know what kind of content to create.
                    </div>
                  </div>
)}
              >
                {getFieldDecorator('styleGuide', {})(<ImageUploaderV2 col={6} />)}
              </Form.Item>
              <div align="center">
                <Button
                  size="large"
                  type="submit"
                  className="trend-btn product-btn"
                  loading={submitting}
                  onClick={this.handleSubmit}
                >
                  All Done - Launch Your Campaign!
                </Button>
              </div>
            </Content>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  session: state.entities.session.authUserData
});

export default compose(
  withRouter,
  withAuthUser,
  Form.create({ name: 'campaign-form' }),
  connect(mapStateToProps)
)(CampaignForm);
