import React from 'react';
import { Button, Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import * as routes from '../../../constants/routes';

const ContentCredit = ({ standardCredits, brand }) => {
  // const { ownedCampaigns } = allOwnedCampaigns;
  const history = useHistory();
  const { status, lastPurchaseDate } = brand;
  const onboardingCompleted = status === 'approved' && lastPurchaseDate;
  const onboardingProgress = status === 'approved' && !lastPurchaseDate;
  // const notDisplayAddCreditsBtn = status === 'pending' ||

  function goTo() {
    history.push(routes.MANAGE_CREDITS_PATH);
  }

  return (
    <div className="content-credit-text">
      <>
        <Row>
          <Col
            lg={24}
            className="credit-title-block size-12px text-white text-center"
            style={{ paddingTop: '1px' }}
          >
            <span className="credit-value vip">{standardCredits || 0}</span>
            <br />
            <div className="credit-title">Creator Credits</div>
            <p className="credit-desc">
              {(!status || onboardingCompleted) &&
                'These credits do not expire, when you run out you can add more at any time'}
              {onboardingProgress &&
                "To accept any applications you receive you'll need to add creator credits"}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button className="credit-add" type="primary" block onClick={goTo}>
              Add Creator Credits
            </Button>
          </Col>
        </Row>
      </>
    </div>
  );
};

export default ContentCredit;
