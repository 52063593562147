import React, { Component } from 'react';
import { Row, Col, Spin } from 'antd';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withFirebase } from 'lib/Firebase';
import { AuthUserContext } from 'lib/Session';
import { resetPostsAnalytics } from 'actions/ui/postsAnalytics';
import { updatePostDetails } from 'actions/posts';
import { totalPostSummary } from 'selectors/postsAnalytics';
import MinLayout from 'components/Common/MinLayout';
import CurrencyFormat from 'react-currency-format';
import EmptyPosts from 'components/Common/EmptyPosts';
import PostGraph from 'components/PostGraph';
import PostGraphFilter from 'components/PostGraph/Filter';
import { setInfluencerList } from 'actions/ui/influencerList';
import CampaignPolicy from 'policies/CampaignPolicy';
import { setPostGraphFilter } from 'actions/ui/postGraph';
import ViewOption from './ViewOption';
import GridLayout from './GridLayout';
import ListLayout from './ListLayout';
import PostTypeFilter from './PostTypeFilter';

class Posts extends Component {
  state = {
    hasFetched: false
  };

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;

    const { dispatchSetFilter } = this.props;

    dispatchSetFilter('1month');
    this.setState({ hasFetched: true });
  }

  componentWillUnmount() {
    this._isMounted = false;

    const { resetPosts: reset, dispatchResetInfluencers, firebase } = this.props;

    reset();
    dispatchResetInfluencers();

    firebase.activeProducts().off();
    firebase.influencers().off();
  }

  render() {
    const {
      totalPostSummary: { posts, likes, comments, impressions },
      view,
      match: { params }
    } = this.props;
    const { hasFetched } = this.state;

    return (
      <Spin spinning={!hasFetched} style={{ minHeight: '300px' }}>
        {hasFetched && posts === 0 && (
          <div style={{ background: '#ffffff' }}>
            <EmptyPosts />
          </div>
        )}
        {hasFetched && posts > 0 && (
          <div id="campaign-posts-page">
            <Row type="flex" justify="space-around" align="middle" style={{ margin: '30px 0' }}>
              <MinLayout>
                <Row gutter={8}>
                  <Col sm={12} xs={24}>
                    <Row className="mb-5">
                      <Col sm={6} xs={12}>
                        <div
                          style={{
                            fontFamily: 'truenoextrabold',
                            fontSize: '17px',
                            fontStyle: 'italic',
                            color: '#000',
                            lineHeight: '1.4'
                          }}
                        >
                          <CurrencyFormat value={posts} displayType="text" thousandSeparator />
                        </div>
                        <div
                          style={{
                            fontSize: '8px',
                            color: '#5b6572',
                            letterSpacing: '0.5px'
                          }}
                        >
                          LIVE POSTS
                        </div>
                      </Col>
                      <Col sm={6} xs={12}>
                        <div
                          style={{
                            fontFamily: 'truenoextrabold',
                            fontSize: '17px',
                            fontStyle: 'italic',
                            color: '#000',
                            lineHeight: '1.4'
                          }}
                        >
                          <CurrencyFormat value={likes} displayType="text" thousandSeparator />
                        </div>
                        <div
                          style={{
                            fontSize: '8px',
                            color: '#5b6572',
                            letterSpacing: '0.5px'
                          }}
                        >
                          LIKES
                        </div>
                      </Col>
                      <Col sm={6} xs={12}>
                        <div
                          style={{
                            fontFamily: 'truenoextrabold',
                            fontSize: '17px',
                            fontStyle: 'italic',
                            color: '#000',
                            lineHeight: '1.4'
                          }}
                        >
                          <CurrencyFormat value={comments} displayType="text" thousandSeparator />
                        </div>
                        <div
                          style={{
                            fontSize: '8px',
                            color: '#5b6572',
                            letterSpacing: '0.5px'
                          }}
                        >
                          COMMENTS
                        </div>
                      </Col>
                      <Col sm={6} xs={12}>
                        <div
                          style={{
                            fontFamily: 'truenoextrabold',
                            fontSize: '17px',
                            fontStyle: 'italic',
                            color: '#000',
                            lineHeight: '1.4'
                          }}
                        >
                          <CurrencyFormat
                            value={impressions}
                            displayType="text"
                            thousandSeparator
                          />
                        </div>
                        <div
                          style={{
                            fontSize: '8px',
                            color: '#5b6572',
                            letterSpacing: '0.5px'
                          }}
                        >
                          IMPRESSIONS
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={12} xs={24}>
                    <PostGraphFilter />
                  </Col>
                </Row>
                <Row>
                  <PostGraph productUID={params.campaignId} />
                </Row>
              </MinLayout>
            </Row>
            <Row type="flex" justify="space-around" className="post-filter-types">
              <MinLayout>
                <PostTypeFilter />
              </MinLayout>
            </Row>
            <Row type="flex" justify="space-around">
              <MinLayout>
                <div style={{ marginTop: '10px' }}>
                  <AuthUserContext.Consumer>
                    {authUser => authUser && <ViewOption authUser={authUser} />}
                  </AuthUserContext.Consumer>
                  <div style={{ marginTop: '10px' }}>
                    {view === 'grid' ? <GridLayout /> : <ListLayout />}
                  </div>
                </div>
              </MinLayout>
            </Row>
          </div>
        )}
      </Spin>
    );
  }
}

const mapStateToProps = (
  state,
  {
    match: {
      params: { campaignId }
    }
  }
) => ({
  view: state.ui.modeView.view,
  totalPostSummary: totalPostSummary(state.entities.posts.byId, state.ui.campaignPosts[campaignId])
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updatePostDetails: (influencerUID, postUID, post) =>
    dispatch(updatePostDetails(influencerUID, ownProps.match.params.campaignId, postUID, post)),
  resetPosts: () => dispatch(resetPostsAnalytics()),
  dispatchResetInfluencers: () => dispatch(setInfluencerList([])),
  dispatchSetFilter: filteredBy => dispatch(setPostGraphFilter(filteredBy))
});

export default compose(
  CampaignPolicy,
  withRouter,
  withFirebase,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Posts);
