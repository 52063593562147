import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';

const InfluencerTable = ({ influencerUIDs = [], columns = [], ...rest }) => {
  const { byId: influencerById } = useSelector(state => state.entities.influencers);
  const source = influencerUIDs.map(influencerUID => {
    const {
      details: {
        createdAt,
        engagementRate = 0,
        avgLikeCount = 0,
        avgCommentCount = 0,
        followedByCount = 0,
        applicationCount = 0,
        completedPartnershipCount = 0,
        activePartnershipCount = 0,
        lastAppliedDate,
        isVIP,
        username,
        parsedState,
        name,
        image,
        star = 0,
        lastLoginDate,
        avgAttitudeProfessionalism = 0,
        avgContentQuality = 0
      } = {}
    } = influencerById[influencerUID];

    return {
      key: influencerUID,
      createdAt,
      engagementRate,
      avgLikeCount,
      avgCommentCount,
      followedByCount,
      applicationCount,
      activePartnershipCount,
      completedPartnershipCount,
      lastAppliedDate,
      star,
      username,
      parsedState,
      name,
      isVIP,
      image,
      lastLoginDate,
      avgAttitudeProfessionalism,
      avgContentQuality
    };
  });

  return <Table dataSource={source} pagination={false} columns={columns} {...rest} />;
};

export default InfluencerTable;
