import React, { useEffect, useState } from 'react';
import { Row, Col, Layout } from 'antd';
import CustomEmpty from 'components/Common/CustomEmpty';
import Promise from 'bluebird';
import PostCard from './PostCard';
import { firebase } from '../../../lib/Firebase';

const { Content } = Layout;

const GridLayout = props => {
  const isFetching = true;
  const { postIds } = props;
  const [grids, setGrids] = useState([]);

  const setGridInfo = async () => {
    const gridsTmp = await Promise.map(postIds, async post => {
      const { influencerUID, uid } = post;
      const influencerDetails = await firebase.firestore
        .collection('influencers')
        .doc(influencerUID)
        .get();
      return (
        <Col md={8} sm={12} xs={24} style={{ marginBottom: '10px' }}>
          <div>
            <PostCard
              influencerUID={influencerUID}
              postUID={uid}
              post={post}
              influencer={influencerDetails.data() || {}}
            />
          </div>
        </Col>
      );
    });

    setGrids(gridsTmp);
  };

  useEffect(() => {
    setGridInfo();
  }, [postIds]);

  return (
    <Content id="campaign-posts-list" style={{ background: '#fff', minHeight: 600 }}>
      <Row type="flex" justify="space-around">
        <Col span={24}>
          {grids && grids.length ? <Row gutter={16}>{grids}</Row> : null}
          {!isFetching && grids.length === 0 && (
            <CustomEmpty
              style={{ marginTop: '10px', textAlign: 'center' }}
              description="No post yet."
            />
          )}
        </Col>
      </Row>
    </Content>
  );
};

export default GridLayout;
