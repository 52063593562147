import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from 'lib/Firebase';
import { withCookies } from 'react-cookie';
import { message } from 'antd';
import { setUserData } from '../Analytics';
import * as ROUTES from '../../constants/routes';

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    state = {
      authUser: null,
      brand: null
    };

    componentDidMount() {
      const { firebase, history, cookies } = this.props;
      const isAdmin = cookies.get('isAdmin');
      const isContentAdmin = cookies.get('isContentAdmin');

      this.listener = firebase.auth.onAuthStateChanged(authUser => {
        if (!authUser) {
          history.push('/login');
          return;
        }

        firebase.brand(authUser.uid).once('value', snapshot => {
          let brand = null;
          let brandUID = null;

          if (snapshot.exists()) {
            brand = snapshot.val();
            brandUID = snapshot.key;

            if (process.env.REACT_APP_ENV === 'production' && window.dataLayer) {
              const {
                details: { ownerName, authEmail }
              } = brand;

              window.dataLayer.push({
                userId: authUser.uid
              });

              setUserData({
                email: authEmail,
                ownerName
              });
            }
            this.setState({ authUser, brand, isAdmin, isContentAdmin });

            if (authUser && brand) {
              if (brand.details.isFreeTrial) {
                this.handleFreeTrial(authUser, brand, brandUID);
              } else if (!condition(authUser, brand, isAdmin, isContentAdmin)) {
                this.handleFailRedirect(authUser, brand, brandUID);
              } else if (
                brand.details.firstCampaign === false &&
                !isAdmin &&
                !ROUTES.PRE_ONBOARDING_PATH.includes(window.location.pathname)
              ) {
                history.push(ROUTES.PRE_ONBOARDING_PATH);
              }
            }
          } else {
            history.push('/signup');
          }
        });
      });
    }

    componentWillUnmount() {
      this.listener();
    }

    handleFreeTrial = (authUser, brand, brandUID) => {
      const { history, firebase } = this.props;
      firebase
        .trials()
        .orderByChild('email')
        .equalTo(authUser.email)
        .once('child_added', snapshot => {
          if (!snapshot.val()) {
            history.push('/login');
          } else if (brand.details.name) {
            firebase
              .activeProducts()
              .orderByChild('brandUID')
              .equalTo(brandUID)
              .once('value', brandSnapshot => {
                if (!brandSnapshot.val()) {
                  history.push(`/trial/${snapshot.val().hash}/campaign`);
                }
              });
          } else {
            history.push(`/trial/${snapshot.val().hash}/company`);
          }
        });
    };

    handleFailRedirect = (authUser, brand) => {
      const { history, firebase } = this.props;
      if (!brand.details.name) {
        history.push(ROUTES.PRE_ONBOARDING_PATH);
      } else {
        message.error(
          'There seems to be an issue with your account. Please contact Trend support.'
        );
        firebase.auth.signOut();
        history.push('/login');
      }
    };

    render() {
      const { authUser, brand, isAdmin, isContentAdmin } = this.state;

      return condition(authUser, brand, isAdmin, isContentAdmin) ? (
        <Component {...this.props} authUser={authUser} brand={brand} />
      ) : null;
    }
  }

  return compose(
    withRouter,
    withFirebase,
    withCookies
  )(WithAuthorization);
};

export default withAuthorization;
