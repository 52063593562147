import React from 'react';
import { Avatar, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { ReactComponent as TrendLogoWeb } from 'images/trend-logo-web.svg';

// for brand and campaign profile images
const ProfileImage = ({ src }) => {
  if (!src) {
    return (
      <div className="brand-image">
        <Row type="flex" align="middle" justify="center" style={{ height: '100%' }}>
          <Col>
            <TrendLogoWeb />
          </Col>
        </Row>
      </div>
    );
  }
  return (
    <div className="brand-image">
      <Avatar src={src} />
    </div>
  );
};

ProfileImage.defaultProps = {
  src: ''
};

ProfileImage.propTypes = {
  src: PropTypes.string
};

export default ProfileImage;
