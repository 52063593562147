import React, { Component } from 'react';
import { Row } from 'antd';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Overview from 'components/ExampleDashboard/Overviews';
import DemoMessages from 'components/Demo/Messages';
import DemoInfluencersTabLink from 'components/Demo/InfluencersTabLink';
import DemoPosts from 'components/Demo/Posts';
import CampaignBanner from 'components/ExampleDashboard/Banner/CampaignBanner';
import { withFirebase } from 'lib/Firebase';
import { withAuthUser } from 'lib/Session';
import * as routes from 'constants/routes';
import MinLayout from 'components/Common/MinLayout';
import WithBrand from 'components/Helpers/WithBrand';

class CampaignContent extends Component {
  defaultSelectedKeys = () => {
    const { location } = this.props;
    const defaultKey = 'summary';
    const arrPathname = location.pathname.split('/');
    const pathIndex = location.pathname.includes('demo') ? 4 : 3;

    return [arrPathname[pathIndex] || defaultKey];
  };

  render() {
    const { match, currentCampaign } = this.props;

    let campaign = currentCampaign || {};
    if (match.params.campaignId === 'example') {
      campaign = { name: 'Welcome to Trend', isListed: true };
    }
    return (
      <div className="campaign-content">
        <div className="nav-header">
          <Row type="flex" justify="space-around">
            <MinLayout>
              <CampaignBanner campaignName={campaign.name} currentCampaign={campaign} />
            </MinLayout>
          </Row>
          <div style={{ borderBottom: '1px solid #dbdfe8' }} />
        </div>
        <Row>
          {/* demo path, should be priority */}
          <Route path={routes.DEMO_MY_INFLUENCERS_PATH} component={DemoInfluencersTabLink} />
          <Route path={routes.DEMO_POSTS_PATH} component={DemoPosts} />
          <Route path={routes.DEMO_MESSAGES_PATH} component={DemoMessages} />

          <Route path="/exampleCampaigns/:campaignId/summary" component={Overview} />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, { match: { params } }) => ({
  currentCampaign: state.ui.campaignDashboard.currentCampaign,
  campaignDashboard: state.ui.campaignDashboard.byCampaignId[params.campaignId],
  influencers: state.entities.influencers.byId,
  campaigns: state.entities.campaigns.byId
});

export default compose(
  withRouter,
  WithBrand,
  withFirebase,
  withAuthUser,
  connect(mapStateToProps)
)(CampaignContent);
