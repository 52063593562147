import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import moment from 'moment';
import { saveAs } from 'file-saver';
import validUrl from 'valid-url';

class PostImages {
  getImageLink = ({
    fullResImage,
    standardResolutionImage,
    lowResolutionImage,
  }) => {
    let link = fullResImage || standardResolutionImage || lowResolutionImage;

    if (link) {
      if (!link.includes('imgp.socapi.icu') && !link.includes('localhost')) {
        link = link.replace(/http:/, 'https:');
      }

      if (!validUrl.isUri(link)) {
        link = null;
      }
    }

    return link;
  }

  click = (node) => {
    try {
      node.dispatchEvent(new MouseEvent('click'));
    } catch (e) {
      const evt = document.createEvent('MouseEvents');
      evt.initMouseEvent('click', true, true, window, 0, 0, 0, 80, 20, false, false, false, false, 0, null);

      node.dispatchEvent(evt);
    }
  }

  openImageNewTab = (link, fileName) => {
    const a = document.createElement('a');

    a.download = `${fileName}.jpeg`;
    a.rel = 'noopener';
    a.href = link;
    a.target = '_blank';

    this.click(a);
  }

  downloadImage = (post, callback) => {
    const fileName = post.code;
    const link = this.getImageLink(post);

    if (!link) {
      callback('This Influencer did not upload an HD photo!');
      return;
    }

    const xhr = new XMLHttpRequest();
    xhr.open('GET', link);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      saveAs(xhr.response, `${fileName}.jpeg`, { type: 'blob' });
    };
    xhr.onerror = () => {
      this.openImageNewTab(link, fileName);
    };
    xhr.send();
  }

  getFullResImages = (posts) => {
    const fullResImages = [];
    Object.values(posts).forEach(({ details: postDetails }) => {
      const link = this.getImageLink(postDetails);
      if (link) {
        fullResImages.push({
          link,
          fileName: postDetails.code,
        });
      }
    });

    return fullResImages;
  }

  downloadAllHD = (folderName, posts, callback) => {
    const jszip = new JSZip();
    const fullResImages = this.getFullResImages(posts);

    let count = 0;

    fullResImages.forEach((post) => {
      JSZipUtils.getBinaryContent(post.link, (error, data) => {
        if (!error) {
          jszip.file(`${post.fileName}.jpg`, data, { binary: true });

          if (count === (fullResImages.length - 1)) {
            jszip.generateAsync({ type: 'blob' }).then((content) => {
              saveAs(content, `${folderName} - ${moment().format()}.zip`);
            });

            callback();
          }
        }
        count += 1;
      });
    });
  }
}

export default PostImages;
