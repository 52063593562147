import { POST_DETAIL_STATUS } from 'constants/posts';
import _ from 'lodash';

export const filteredByApproved = (ids = [], posts) =>
  _.remove([...ids], id => {
    const post = posts.byId[id];
    const details = post.details || post;
    return details.status === POST_DETAIL_STATUS.approved;
  });

export const sortPosts = (ids, posts) => {
  return ids.sort((a, b) => {
    const postA = new Date(posts[a].createdTime).getTime();
    const postB = new Date(posts[b].createdTime).getTime();
    if (postA < postB) {
      return 1;
    }
    if (postA > postB) {
      return -1;
    }
    return 0;
  });
};

export const filterPosts = (posts, { approvedPosts: postUIDs, params: { category, brandUID } }) =>
  postUIDs.filter(postUID => {
    const post = posts[postUID];

    const categoryFlag = category ? post.category === category : true;
    const myCampaignFlag = brandUID ? post.brandUID === brandUID : true;

    return categoryFlag && myCampaignFlag;
  });
