import React from 'react';
import * as routes from 'constants/routes';
import { Redirect, Switch, Route } from 'react-router-dom';
import { ReactComponent as IconBack } from 'images/icon-back-arrow-white.svg';
// import SignOutButton from 'components/Signout';
// import AddCredits from 'components/ManageCredits/AddCredits';
import NewCreditPage from 'components/ManageCredits/NewCreditPage';
import Container from '../Common/Container';
import 'styles/pages/manageCredits.less';

const logo =
  'https://static.wixstatic.com/media/2ba213_71d75f77807444218e8b44e2e827c78e~mv2.png/v1/fill/w_218,h_40,al_c,q_80,usm_1.20_1.00_0.01/2ba213_71d75f77807444218e8b44e2e827c78e~mv2.webp';

const ManageCredits = props => {
  const { authUser: user, history } = props;

  const goBack = () => {
    if (history.location.key) {
      history.goBack();
    } else {
      history.push('/producs');
    }
  };

  return (
    <div id="manage_credits">
      <div className="pt-7" style={{ background: '#0f0f0f !important' }}>
        <div className="action-link clickable" align="center">
          <div className="back">
            <div
              onClick={goBack}
              style={{ cursor: 'pointer', color: '#ffb917' }}
              role="presentation"
            >
              <IconBack />
              <span className="white d-block type-sbold bsize-4">Back</span>
            </div>
          </div>
        </div>
        <div align="center">
          <img src={logo} alt="logo" className="logo d-iblock" width="70px" />
          <p className="type-sfpro-regular ht-6 text-white title size-34px">
            Add Credits to Accept Creators
          </p>
          <p className="type-sfpro-regular text-white size-18px">
            1 credit = 1 custom photo or video & 1 post on Instagram
          </p>
          <p
            className="type-sfpro-regular text-white descriptions size-18px"
            style={{ width: '885px', fontWeight: '400px', lineHeight: '28px' }}
          >
            No software fees. Buy credits, get high-quality content and posts from the country’s top
            creators. We pay the creators ourselves so you don’t have to worry about that. These
            credits will stay in your account for up to 12 months.
          </p>
        </div>
      </div>
      <Container width={940} style={{ marginTop: '79px' }}>
        <Switch>
          <Route
            path={routes.MANAGE_ADD_CREDITS_PATH}
            render={() => <NewCreditPage authUser={user} />}
          />
          <Redirect to={routes.MANAGE_ADD_CREDITS_PATH} />
        </Switch>
      </Container>
    </div>
  );
};

export default ManageCredits;
