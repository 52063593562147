import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Button, Col } from 'antd';
import Search from './Search';
import {
  CampaignFilter,
  CampaignCardFullDetails,
  AdminCampaignModal
} from '../../../components/Campaigns';
import './activeCampaign.less';
import {
  adminPushActiveCampaign,
  adminUpdateLastCampaignDoc
} from '../../../stores/actions/ui/admin/brands';
import { listenBrand } from '../../../stores/listeners/brands';
import { addCampaign } from '../../../actions/campaigns';
import { listenActiveCampaigns } from '../../../listeners/campaigns';
import Container from '../../../components/Common/Container';

const initialPagination = { page: 1, limit: 10 };
const initialCampaignModal = { show: false, campaignUID: null };

const activeCampaigns = () => {
  const dispatch = useDispatch();
  const [subscribed, setSubscribeState] = useState([]);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState(initialPagination);
  const [campaignModal, setCampaignModal] = useState(initialCampaignModal);

  const { campaigns: campaignUIDs = [], lastCampaignDoc } = useSelector(
    state => state.ui.admin.activeCampaigns
  );

  function retrieveCampaigns(newFilters = {}, reload) {
    const listener = listenActiveCampaigns(
      newFilters,
      reload ? null : lastCampaignDoc,
      pagination.limit,
      campaignDocs => {
        console.log('aasdasd', campaignDocs);
        let campaigns = {};
        let lastDocID;
        campaignDocs.forEach(doc => {
          const campaign = doc.data();
          campaigns = { ...campaigns, [doc.id]: { ...campaign } };

          dispatch(listenBrand(campaign.brandUID));
          dispatch(adminPushActiveCampaign(doc.id));
          lastDocID = doc;
        });
        dispatch(adminUpdateLastCampaignDoc(lastDocID));

        dispatch(addCampaign(campaigns));
      }
    );

    setSubscribeState([...subscribed, listener]);
  }

  useEffect(() => {
    retrieveCampaigns(filters, true);

    return () => {
      subscribed.forEach(sub => {
        sub();
      });
    };
  }, []);

  function loadMore() {
    setPagination({
      ...pagination,
      page: pagination.page + 1
    });

    retrieveCampaigns(filters);
  }

  function handleChange(val = {}) {
    const newFilters = { ...filters, ...val };

    if (
      filters.brand !== val.brand ||
      filters.search !== newFilters.search ||
      filters.gender !== newFilters.gender ||
      (filters.category && filters.health && (!newFilters.category || !newFilters.health)) ||
      (newFilters.category && newFilters.health)
    ) {
      subscribed.forEach(sub => {
        sub();
      });

      dispatch({ type: 'ADMIN_RESET_CAMPAIGNS' });
      retrieveCampaigns(newFilters, true);
      setFilters(newFilters);
      setPagination(initialPagination);
    }
  }

  function handleClick(campaignUID) {
    setCampaignModal({ show: true, campaignUID });
  }

  function handleClose() {
    setCampaignModal({ show: false, campaignUID: '' });
  }

  const showLimit = pagination.limit * pagination.page - pagination.page;
  const list = campaignUIDs
    .map(campaignUID => {
      return (
        <CampaignCardFullDetails
          key={campaignUID}
          md={8}
          xs={12}
          campaignUID={campaignUID}
          onClick={() => handleClick(campaignUID)}
        />
      );
    })
    .slice(0, showLimit)
    .filter(x => !!x);

  return (
    <Container width={940} id="admin-brands-active-campaigns">
      <Row type="flex" justify="space-between" className="search-filters">
        <Col md={6} xs={24}>
          <Search onChange={handleChange} />
        </Col>
        <Col md={16} xs={24}>
          <Row type="flex" justify="end" gutter={8}>
            <CampaignFilter onChange={handleChange} adminOrigin />
          </Row>
        </Col>
      </Row>
      <AdminCampaignModal
        visible={campaignModal.show}
        campaignUID={campaignModal.campaignUID}
        onClose={handleClose}
      />
      <Row gutter={16}>{list}</Row>
      <div className="text-center">
        {campaignUIDs.length > showLimit && campaignUIDs !== 0 && (
          <Button className="load-more" onClick={loadMore}>
            LOAD MORE
          </Button>
        )}
      </div>
    </Container>
  );
};

export default activeCampaigns;
