export const SET_STORE_ANALYTICS = 'SET_STORE_ANALYTICS'

const defaultState = [];

export default(state = defaultState, action) => {
  switch(action.type){
    case SET_STORE_ANALYTICS:
      return [...action.payload];

    default:
      return state;
  }
}
