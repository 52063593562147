import React, { useState, useEffect } from 'react';
import { Table, Row, Button } from 'antd';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import { POST_STATUS } from 'constants/posts';
import MinLayout from 'components/Common/MinLayout';
import CustomEmpty from 'components/Common/CustomEmpty';
import InfluencerAvatar from 'components/Common/InfluencerAvatar';
import InfluencerImg1 from 'images/demo/influencer-1.png';
import InfluencerImg2 from 'images/demo/influencer-2.png';
import InfluencerImg3 from 'images/demo/influencer-3.png';
import { showShippingFormModal } from 'actions/ui/shippingFormModal';
import DemoInfluencersBanner from './InfluencersBanner';
import ShippingFormModal from './ShippingFormModal';
import { InfluencerDrawer } from '../Influencers';
import { setInfluencers } from '../../actions/influencers';

const Action = ({ showModal, influencerUID, campaignUID, status }) => {
  let shippedBtn = (
    <Button
      onClick={() => showModal(influencerUID, campaignUID)}
      className="trend-btn"
      style={{
        height: '33px',
        width: '97px',
        wordWrap: 'break-word',
        color: '#000000',
        fontSize: '10px',
        fontWeight: '900'
      }}
    >
      MARK AS
      <br />
      SHIPPED
    </Button>
  );

  if (status !== POST_STATUS.needsShipping) {
    shippedBtn = (
      <Button
        onClick={() => showModal(influencerUID, campaignUID)}
        className="trend-btn secondary type-firme-iblack bg-white"
        style={{ height: '32px' }}
      >
        Shipping Details
      </Button>
    );
  }

  return <div className="cell-action">{shippedBtn}</div>;
};

const Address = influencer => (
  <div
    style={{
      lineHeight: '1.64'
    }}
    className="size-11px text-black"
  >
    <div className="type-sbold">{influencer.name}</div>
    <div>{influencer.address}</div>
  </div>
);

const columns = [
  {
    title: 'INFLUENCERS',
    dataIndex: 'influencer',
    key: 'influencer',
    width: '220px',
    className: 'influencer-width-shipment',
    render: influencer => <InfluencerAvatar influencer={influencer} />
  },
  {
    title: 'ADDRESS',
    dataIndex: 'influencer',
    key: 'address',
    render: Address
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    align: 'right',
    className: 'col-action',
    render: Action
  }
];

const ProductShipments = props => {
  const dispatch = useDispatch();
  const [influencerDetailsModal, setInfluencerDetailsModal] = useState({
    show: false,
    influencerUID: null
  });

  const { showShippingFormModal: showModal } = props;
  const mapNeedsShipping = [
    {
      key: 'ft-987654321',
      influencer: {
        image: InfluencerImg2,
        isVIP: false,
        name: 'Demo Influencer2',
        username: 'demo-influencer2',
        state: 'New York, NY',
        address: 'Demo Address'
      },
      action: {
        influencerUID: 'ft-987654321',
        status: 'needsShipping',
        showModal
      }
    }
  ];

  const mapShipped = [
    {
      key: '123',
      influencer: {
        name: 'Demo Influencer1',
        username: 'demo-influencer1',
        image: InfluencerImg1,
        address: 'Demo Address',
        state: 'TX'
      },
      action: {
        showModal,
        influencerUID: '123',
        campaignUID: '321',
        status: 'submitted'
      }
    },
    {
      key: 'ft-987654321123',
      influencer: {
        image: InfluencerImg3,
        isVIP: false,
        username: 'demo-influencer3',
        name: 'Demo Influencer3',
        state: 'New York, NY',
        address: 'Demo Address'
      },
      action: {
        influencerUID: 'ft-987654321123',
        showModal
      }
    }
  ];

  function handleHideInfluencerDetails() {
    setInfluencerDetailsModal(state => ({ ...state, show: false }));
  }

  const handleShowInfluencerDetails = influencerUID => event => {
    if (event.target.type === 'button') {
      return;
    }

    setInfluencerDetailsModal({
      show: true,
      influencerUID
    });
  };

  useEffect(() => {
    [...mapShipped, ...mapNeedsShipping].forEach((data, index) => {
      const { influencer } = data;
      const lastWeek = moment()
        .subtract(1, 'week')
        .format('YYYY-w');
      const times = index + 1;
      dispatch(
        setInfluencers({
          [data.key]: {
            details: {
              state: influencer.state,
              username: influencer.username,
              image: influencer.image,
              isVIP: influencer.isVIP,
              ageRange: data.ageRange
            },
            instagram: {
              avgCommentCount: 1900 * times,
              avgLikeCount: 32700 * times,
              biography: 'Enjoy Your Demo and \nhave a great day',
              followedByCount: 13800 * times,
              stats: {
                [lastWeek]: {
                  avgCommentCount: 1857 * times,
                  avgLikeCount: 32300 * times
                }
              }
            }
          }
        })
      );
    });
  }, []);

  return (
    <Row type="flex" justify="space-around">
      <MinLayout>
        {mapNeedsShipping.length > 0 && (
          <div style={{ marginTop: '23px', marginBottom: '44px' }}>
            <div className="type-ebold size-13px text-black mb-2" style={{ marginBottom: '15px' }}>
              Needs to be shipped -&nbsp;
              {mapNeedsShipping.length}
            </div>
            <Table
              locale={{ emptyText: <CustomEmpty description="No shipments to show" /> }}
              className="trend-table"
              columns={columns}
              dataSource={mapNeedsShipping}
              pagination={false}
              cellpadding="0"
              cellspacing="0"
              onRow={record => ({
                onClick: handleShowInfluencerDetails(record.key)
              })}
            />
          </div>
        )}

        {mapShipped.length > 0 && (
          <div style={{ marginTop: '23px' }}>
            <div className="type-ebold size-13px text-black mb-2" style={{ marginBottom: '15px' }}>
              Shipped -&nbsp;
              {mapShipped.length}
            </div>
            <Table
              locale={{ emptyText: <CustomEmpty description="No shipments to show" /> }}
              className="trend-table"
              columns={columns}
              dataSource={mapShipped}
              pagination={false}
              cellpadding="0"
              cellspacing="0"
              onRow={record => ({
                onClick: handleShowInfluencerDetails(record.key)
              })}
            />
          </div>
        )}

        <ShippingFormModal />
        <DemoInfluencersBanner />
        <InfluencerDrawer {...influencerDetailsModal} onHide={handleHideInfluencerDetails} />
      </MinLayout>
    </Row>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  showShippingFormModal
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductShipments);
