import FirebaseContext, { withFirebase } from './context';
import Firebase from './firebase';
import UserToken from './userToken';
import useFirebase from './useFirebase';
import { influencersSearch } from './influencers';
import { postSearch } from './posts';

const firebase = new Firebase();

export {
  Firebase as default,
  FirebaseContext,
  UserToken,
  withFirebase,
  firebase,
  useFirebase,
  influencersSearch,
  postSearch
};
