import { firebase } from '..';

export default function(filters = {}) {
  let query = firebase.firestore.collection('posts');

  if (filters.category) {
    query = query.where('category', '==', filters.category);
  }

  if (filters.brandUID) {
    query = query.where('brandUID', '==', filters.brandUID);
  }

  query = query.orderBy('createdAt', 'desc');

  return query;
}
