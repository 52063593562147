import React, { Component } from 'react';
import { Icon, Button, Spin } from 'antd';
import { connect } from 'react-redux';
import { ReactComponent as RightIcon } from '../../images/arrow-right.svg';
import { ReactComponent as LeftIcon } from '../../images/arrow-left.svg';

class Image extends Component {
  constructor(props) {
    super(props);
    const { post } = this.props;
    let { images = [] } = post;
    const { standardResolutionImage, fullResImage } = post;

    let defaultImg = standardResolutionImage;

    if (!standardResolutionImage || standardResolutionImage === 'none') {
      defaultImg = fullResImage;
    }

    images = images.slice(1).map(image => image.standardResFilePath);

    this.state = {
      images: [defaultImg, ...images],
      page: 0,
      loading: true
    };
  }

  handleNextPage = () => {
    this.setState(({ page }) => ({ page: page + 1, loading: true }));
  };

  handlePrevPage = () => {
    this.setState(({ page }) => ({ page: page - 1, loading: true }));
  };

  handleErrorImage = () => {
    const { post } = this.props;
    const { fullResImage } = post;

    if (fullResImage) {
      this.setState(state => ({
        ...state,
        images: [fullResImage, ...state.images.slice(1)]
      }));
    }
  };

  handleImageLoaded = () => {
    this.setState({
      loading: false
    });
  };

  actions = () => {
    const { page, images } = this.state;

    if (images.length < 2) {
      return <div />;
    }
    return (
      <div className="post-gallery-actions">
        {page > 0 && (
          <Button className="action-previous" type="link" onClick={this.handlePrevPage}>
            <Icon component={LeftIcon} />
          </Button>
        )}

        {page < images.length - 1 && (
          <Button className="action-next" type="link" onClick={this.handleNextPage}>
            <Icon component={RightIcon} />
          </Button>
        )}
      </div>
    );
  };

  render() {
    const { post, withLabel } = this.props;
    const { images, page, loading } = this.state;
    const type = post.type || 'image';

    return (
      <div className="post-card-image">
        <Spin spinning={loading}>
          <a
            href={`https://instagram.com/p/${post.code}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={images[page]}
              alt="im-1"
              onError={this.handleErrorImage}
              onLoad={this.handleImageLoaded}
            />
          </a>
          {this.actions()}
          {withLabel && (
            <div className="post-type-wrapper">
              <div className={`post-type-label type-${type}`}>{type} Post</div>
            </div>
          )}
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  post: ownProps.post || state.entities.posts.byId[ownProps.postUID]
});

export default connect(mapStateToProps)(Image);
