import React from 'react';
import { Empty } from 'antd';
import NoMessagesImg from 'images/DefaultEmpty/no-messages.png';

const MessagesBanner = () => (
  <Empty
    className="empty-no-messages"
    style={{
      marginTop: '66px',
      marginBottom: '109px',
    }}
    image={
      <img src={NoMessagesImg} alt="free trial" style={{ width: '504px', height: 'auto' }} />
    }
    description={(
      <span className="empty-desc-header type-bold mb-2 mt-6">
        Open communication with your influencers
      </span>
    )}
  >
    <div className="mt-1">
      <div className="empty-desc-body">
        The Trend app allows you to iron out any details you need to with
        <br className="hidden-mobile" />
        the influencers you work with. Communication is key to ensuring
        <br className="hidden-mobile" />
        your campaigns go off without a hitch.
      </div>
    </div>
  </Empty>
);

export default MessagesBanner;
