import React from 'react';
import { Row, Col } from 'antd';
import 'styles/base/antd-custom.less';
import { InlineWidget } from 'react-calendly';
import trendLogo from '../../images/trend@2x.png';
import Container from '../Common/Container';

const Calendly = () => {
  return (
    <Row type="flex" justify="center" align="middle" style={{ height: '300px' }}>
      <Col span={8} className="text-center">
        <div id="onboarding-page" className="bg-black">
          <Container width={940}>
            <Row className="header" type="flex" justify="center">
              <Col span="8">
                <div style={{ textAlign: 'center' }}>
                  <img
                    alt="Trend"
                    src={trendLogo}
                    width="70"
                    height="13"
                    style={{ display: 'inline-block' }}
                  />
                </div>
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <h1>Schedule Call below</h1>
            </Row>
            <Row type="flex" justify="center" align="top">
              <Col className="calendar">
                <InlineWidget
                  url="https://calendly.com/jamie-catanach/onboarding?background_color=000000&text_color=ffffff&primary_color=fbb900"
                  styles={{ width: '100%', height: '950px' }}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </Col>
    </Row>
  );
};

export default Calendly;
