import React, { useState } from 'react';
import axios from 'axios';
import { Icon, message, Button, Modal, Form, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import YotPoImage from '../../images/yotpo-circle-large@2x.png';
import TrendLogo from '../../images/trend-black@3x.png';
import { firebase } from '../../lib/Firebase';
import { setLoggedUser } from '../../actions/session';
import { ReactComponent as CloseIcon } from '../../images/close-x.svg';

const initialFields = {
  apiKey: '',
  secretKey: ''
};
const LoginModal = ({ show, handleShow }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [fields, setField] = useState(initialFields);
  const [hasError, setHasError] = useState(false);

  const session = useSelector(state => state.entities.session.authUserData);

  const handleChange = type => event => {
    setField({ ...fields, [type]: event.target.value });
  };

  function handleClose() {
    setField(initialFields);
    setHasError(false);
    handleShow(false);
  }

  async function handleSubmit() {
    const {
      uid: brandUID,
      details: { name }
    } = session;
    const { apiKey, secretKey } = fields;

    setLoading(true);
    setHasError(false);
    try {
      await axios.post('https://api.yotpo.com/oauth/token', {
        client_id: apiKey,
        client_secret: secretKey,
        grant_type: 'client_credentials'
      });

      const brandDoc = firebase.firestore.collection('brands').doc(brandUID);
      await brandDoc
        .collection('brandIntegrations')
        .doc('yotpo')
        .set(
          {
            apiKey,
            secretKey,
            createdAt: moment().unix()
          },
          { merge: true }
        );

      await brandDoc.set(
        {
          name
        },
        { merge: true }
      );

      dispatch(setLoggedUser({ ...session, integrations: { yotpo: true } }));
      message.success('Succesfully connected to Yotpo');
      setLoading(false);
      handleClose();
    } catch (e) {
      setLoading(false);
      setHasError(true);
    }
  }

  const { apiKey, secretKey } = fields;
  return (
    <Modal className="yotpo-modal" closable={false} visible={show} footer={false}>
      <Button type="link" className="close" onClick={handleClose}>
        <Icon component={CloseIcon} />
      </Button>
      <div className="text-center logos">
        <img className="trend-image" src={TrendLogo} alt="trend logo" />
        <span>&amp;</span>
        <img className="yotpo-image" src={YotPoImage} alt="yotpo logo" />
      </div>
      <h1>Sign in to your Yotpo account to send content from Trend to Yotpo.</h1>
      <div style={{ position: 'relative' }}>
        <Form.Item>
          <div className="label">
            <label className="" title="API Key">
              API Key
            </label>
          </div>
          <Input placeholder="Enter Key" onChange={handleChange('apiKey')} value={apiKey} />
        </Form.Item>
        <Form.Item>
          <div className="label">
            <label className="" title="Secret Key">
              Secret Key
            </label>
          </div>
          <Input placeholder="Enter Key" onChange={handleChange('secretKey')} value={secretKey} />
        </Form.Item>
        <Form.Item className="submit">
          <Button loading={loading} block onClick={handleSubmit}>
            {loading ? 'Verifying' : 'Connect your Account'}
          </Button>
        </Form.Item>
        {hasError && <div className="error">Credentials not found</div>}
        <p className="text-center help">
          <a
            href="https://support.yotpo.com/en/article/finding-your-yotpo-app-key-and-api-secret"
            target="_blank"
            rel="noopener noreferrer"
          >
            Need help finding this info?
          </a>
        </p>
      </div>
    </Modal>
  );
};

export default LoginModal;
