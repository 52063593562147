import React, { Component } from 'react';
import moment from 'moment';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import TextTruncate from 'react-text-truncate';
import { Col, Row, Avatar, Spin, Table, Skeleton } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { withAuthUser } from 'lib/Session';
import { DATE_TIMEZONE_FORMAT } from 'constants/common';
import EmptyMessages from 'components/Common/EmptyMessages';
import MinLayout from 'components/Common/MinLayout';
import WithBrand from 'components/Helpers/WithBrand';
import CampaignPolicy from 'policies/CampaignPolicy';
import filterSortMessages from '../../../selectors/messages';
import { fetchMessages } from '../../../actions/messages';
import MultipleMessageModal from './MultipleMessageModal';
import { appliedInfluencerByStatus } from '../../../selectors/campaignList';
import {
  addElementEventListener,
  handleScrollEvent,
  removeElementEventListener
} from '../../../helpers/autoScrollUpdate';

const columns = [
  {
    title: '',
    dataIndex: 'influencerAvatar',
    key: 'influencerAvatar',
    render: text => {
      return <Avatar src={text} size={50} />;
    },
    className: 'avatar'
  },
  {
    title: '',
    dataIndex: 'influencer',
    key: 'influencer',
    className: 'author',
    render: (text, record) => (
      <div className="ml-2">
        <Skeleton loading={!text} title paragraph={false}>
          <p>
            <Link
              to={`/campaigns/${record.campaignUID}/messages/${record.key}`}
              style={{ color: '#000' }}
              className="type-bold"
            >
              @{text}
            </Link>
          </p>
          <p>{record.influencerState}</p>
        </Skeleton>
      </div>
    )
  },
  {
    title: '',
    dataIndex: 'message',
    key: 'message',
    className: 'message-col',
    align: 'center',
    render: message => (
      <div style={{ height: '30px' }} align="center">
        <div style={{ width: '260px', textAlign: 'left' }}>
          <TextTruncate text={message} line={2} />
        </div>
      </div>
    ),
    onCell: record => ({
      onClick: () => {
        record.history.push(`/campaigns/${record.campaignUID}/messages/${record.key}`);
      }
    })
  },
  {
    title: '',
    dataIndex: 'time',
    key: 'time',
    className: 'time'
  },
  {
    title: '',
    dataIndex: 'count',
    key: 'count',
    className: 'count',
    render: hasUnread =>
      hasUnread && (
        <Avatar
          style={{
            backgroundColor: '#ffb917',
            verticalAlign: 'middle',
            marginLeft: '17px',
            lineHeight: '28px'
          }}
        >
          1
        </Avatar>
      )
  }
];

class Messages extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    const { campaign } = this.props;

    let approvedInfluencerIds = [];
    if (campaign) {
      approvedInfluencerIds = appliedInfluencerByStatus(campaign, 'approved');
    }

    this.state = {
      hasFetched: false,
      checkedMessageUIDs: [],
      indeterminate: false,
      checkAll: false,
      approvedInfluencerIds,
      showCheckbox: false,
      limit: 10
    };
  }

  componentDidMount() {
    addElementEventListener('trend-content-layout', this.handleScroll);
  }

  componentWillUnmount() {
    removeElementEventListener('trend-content-layout', this.handleScroll);
  }

  handleScroll = () => {
    handleScrollEvent(() => {
      const { limit } = this.state;
      const { messageList } = this.props;
      if (limit < messageList.length) {
        this.setState(state => ({ ...state, limit: state.limit + 10 }));
      }
    });
  };

  handleCheck = selectedRowKeys => {
    this.setState({
      checkedMessageUIDs: selectedRowKeys
    });
  };

  handleGroupMessage = enabled => {
    this.setState({ showCheckbox: enabled });
  };

  checkboxActions = () => {
    const { checkedMessageUIDs } = this.state;

    return (
      <MinLayout>
        <MultipleMessageModal
          messageUIDs={checkedMessageUIDs}
          onSelection={this.handleGroupMessage}
        />
      </MinLayout>
    );
  };

  render() {
    let dataSource = [];
    const {
      messages,
      messageList,
      history,
      isFetching,
      authUser,
      influencers,
      match: { params }
    } = this.props;
    const { limit, showCheckbox } = this.state;
    const { checkedMessageUIDs } = this.state;

    messageList.slice(0, limit).forEach(messageUID => {
      const { users } = messages[messageUID];

      const { lastMessageTimestamp, lastMessageText, influencerUID } = messages[messageUID];
      const { hasUnread } = users[authUser.uid];
      const influencer = influencers[influencerUID];

      dataSource = [
        ...dataSource,
        {
          key: messageUID,
          campaignUID: params.campaignId,
          influencerAvatar: influencer.details.parsedAvatar,
          influencer: influencer.details.username,
          influencerState: influencer.details.parsedState,
          message: lastMessageText,
          history,
          time: moment(lastMessageTimestamp, DATE_TIMEZONE_FORMAT).fromNow(),
          count: hasUnread,
          check: {
            messageUID,
            checkedMessageUIDs,
            onCheck: this.handleCheck
          }
        }
      ];
    });

    let rowSelection;
    if (showCheckbox) {
      rowSelection = {
        onChange: this.handleCheck,
        getCheckboxProps: record => ({ uid: record.key })
      };
    }

    return (
      <Spin spinning={isFetching && !dataSource.length} style={{ minHeight: '300px' }}>
        <div
          ref={r => {
            this.tableRef = r;
          }}
        />
        <div className="messages-container">
          <Row
            gutter={64}
            style={{
              height: '100%',
              marginBottom: '40px',
              background: '#ffffff'
            }}
          >
            <Col>
              {!isFetching && messageList && !messageList.length && <EmptyMessages />}
              {messageList && !!messageList.length && (
                <>
                  <Row type="flex" justify="space-around" className="trend-table-actions">
                    {this.checkboxActions()}
                  </Row>
                  <Row type="flex" justify="space-around">
                    <MinLayout>
                      <Table
                        className="trend-table"
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        showHeader={false}
                        rowSelection={rowSelection}
                      />
                    </MinLayout>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </div>
      </Spin>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  influencers: state.entities.influencers.byId,
  isFetching: state.entities.messages.isFetching,
  messages: state.entities.messages.byId,
  messageList: filterSortMessages(
    state.entities.messages.byCampaign[ownProps.match.params.campaignId],
    state.entities.messages.byId,
    ownProps.authUser.uid
  ),
  campaign: state.entities.campaigns.byId[ownProps.match.params.campaignId]
});

const mapDispatchToProps = dispatch => ({
  dispatchFetchMessages: (campaignId, type, onComplete) =>
    dispatch(fetchMessages(campaignId, type, onComplete))
});

export default compose(
  CampaignPolicy,
  WithBrand,
  withRouter,
  withAuthUser,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Messages);
