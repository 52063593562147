import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Spin } from 'antd';
import { getCategories } from 'actions/categories';
import { Link } from 'react-router-dom';
import CampaignListItem from './CampaignListItem';
import * as routes from '../../constants/routes';
import { fetchCampaignsV2 } from '../../actions/ui/campaignList';

class CampaignList extends Component {
  constructor(props) {
    super(props);

    const { getCategories: categories, fetchCampaigns: campaigns } = this.props;

    categories();
    campaigns();
  }

  render() {
    const { campaignList, campaigns, brands, isFetching } = this.props;

    const ListItems = campaignList.map(campaignUID => {
      const campaign = campaigns[campaignUID];
      const brand = brands[campaign.brandUID];

      return (
        <div key={campaignUID}>
          <Link to={routes.toCampaignExample(campaignUID)}>
            <CampaignListItem key={campaignUID} campaign={campaign} brand={brand} />
          </Link>
        </div>
      );
    });

    return (
      <Spin spinning={isFetching}>
        <Row>
          <div className="mt-8">{ListItems}</div>
        </Row>
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  campaignList: state.ui.campaignList.list,
  campaigns: state.entities.campaigns.byId,
  isFetching: state.entities.campaigns.isFetching,
  brands: state.entities.brands.byId
});

const mapDispatchToProps = dispatch => ({
  fetchCampaigns: () => dispatch(fetchCampaignsV2()),
  getCategories: () => dispatch(getCategories())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignList);
