export const ADD_REVIEWS = 'ADD_REVIEWS';

const initialState = {
  byId: {},
  byBrandUID: {},
  byInfluencerUID: {}
};

const addById = (byIdState = {}, reviewUID, review = {}) => ({
  ...byIdState,
  [reviewUID]: review
});

const addByBrandUID = (byBrandUIDState = [], reviewUID) => [
  ...new Set([...byBrandUIDState, reviewUID])
];

const addByInfluencerUID = (byInfluencerUIDState = [], reviewUID) => [
  ...new Set([...byInfluencerUIDState, reviewUID])
];

const addReviews = (state, reviews = {}) => {
  let newState = state;
  Object.keys(reviews).forEach(reviewUID => {
    const review = reviews[reviewUID];
    const { brandUID, influencerUID } = review;

    newState = {
      ...newState,
      byId: addById(newState.byId, reviewUID, review),
      byBrandUID: {
        ...newState.byBrandUID,
        [brandUID]: addByBrandUID(newState.byBrandUID[brandUID], reviewUID)
      },
      byInfluencerUID: {
        ...newState.byInfluencerUID,
        [influencerUID]: addByInfluencerUID(newState.byInfluencerUID[influencerUID], reviewUID)
      }
    };
  });

  return newState;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_REVIEWS:
      return addReviews(state, action.payload);

    default:
      return state;
  }
};
