import { firebase } from '../Firebase';
import dataLayer from './index';

const purchasedTag = async brandUID => {
  // Normally analytics should only be
  // executed in production env
  if (process.env.REACT_APP_ENV !== 'production') {
    return;
  }

  const purchasedHistory = await firebase
    .purchasedHistory()
    .where('brandUID', '==', brandUID)
    .get();

  const purchasedDocs = purchasedHistory.docs;

  if (purchasedDocs.length === 0) {
    return;
  }

  const [purchased] = purchasedDocs
    .map(snap => snap.data())
    .sort((a, b) => {
      return b.purchasedDate - a.purchasedDate;
    });

  if (window.fbq !== null) {
    const purchasedArgs = {
      value: (purchased.totalAmount / 100).toFixed(2),
      currency: 'USD',
      contents: [
        {
          id: 'standardCredits',
          quantity: purchased.standardCredits
        }
      ],
      content_type: 'product'
    };

    window.fbq('track', 'Purchase', purchasedArgs);
  }

  let productName = 'Standard Credits (Initial Purchase)';

  if (purchasedDocs.length > 1) {
    productName = 'Standard Credits (Repurchase)';
  }

  dataLayer({
    event: 'site.purchase',
    ecommerce: {
      purchase: {
        actionField: {
          id: purchased.purchasedId,
          revenue: purchased.totalAmount / 100
        },
        products: [
          {
            id: 'standard_credits',
            name: productName,
            quantity: purchased.standardCredits,
            price: purchased.standardAmount / purchased.standardCredits / 100
          }
        ]
      }
    }
  });
};

export { purchasedTag as default };
