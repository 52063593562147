import { POST_STATUS } from 'constants/posts';

export default (influencers, influencerUIDs, campaignUID, status = null) =>
  influencerUIDs.filter(influencerUID => {
    const influencer = influencers[influencerUID];
    if (influencer.partnerships && influencer.partnerships.products) {
      const { status: postStatus } = influencer.partnerships.products[campaignUID] || {};

      if (status) {
        return postStatus === status;
      }

      return postStatus !== POST_STATUS.needsShipping;
    }
    return false;
  });
