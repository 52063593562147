import React, { useState } from 'react';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import LoginModal from './LoginModal';
import { importToYotPo } from '../../lib/Firebase/callables';
import SentModal from './SentModal';
import AttachProductModal from './AttachProductModal';

const Btn = ({ children, postUIDs = [], ...rest }) => {
  const session = useSelector(state => state.entities.session.authUserData);
  const [modal, setModal] = useState({
    show: false
  });

  const [sentModal, setSentModal] = useState({ show: false });
  const [attachModal, setAttachModal] = useState({ show: false });
  const [sending, setSending] = useState(false);

  async function handleClick() {
    if (!session.integrations.yotpo) {
      setModal({ ...modal, show: true });
    } else {
      setAttachModal({ ...setAttachModal, show: true });
    }
  }

  async function handleSubmit(tag) {
    setSending(true);
    setAttachModal({ ...setAttachModal, show: false });
    await importToYotPo(postUIDs, tag);
    setSentModal({ ...sentModal, show: true });
    setSending(false);
  }

  return (
    <>
      <Button loading={sending} className="yotpo-btn" size="large" onClick={handleClick} {...rest}>
        {children}
      </Button>
      <SentModal
        show={sentModal.show}
        handleShow={val => setSentModal({ ...sentModal, show: val })}
      />
      <AttachProductModal
        show={attachModal.show}
        handleShow={val => setAttachModal({ ...attachModal, show: val })}
        onSubmit={handleSubmit}
      />
      <LoginModal show={modal.show} handleShow={val => setModal({ ...modal, show: val })} />
    </>
  );
};

export default Btn;
