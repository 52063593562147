import moment from 'moment';
import { firebase } from 'lib/Firebase';
import { DATE_TIMEZONE_FORMAT } from 'constants/common';
import {
  fetchRequest,
  receiveRequest,
} from 'actions/messages';
import { SET_MESSAGE_LIST } from 'reducers/ui/messageList';

export const setMessageList = messages => ({
  type: SET_MESSAGE_LIST,
  payload: messages,
});

export const sendNewMessage = (
  text,
  sender,
  recipients,
  partnership,
  shouldAppearInMessage,
  isRejection = false,
) => (dispatch) => {
  // TODO[not priority]: same on mobile issue, need to check current thread
  //  this would happen if approved influence is being rejected
  //  and that influencer was reapplied then approved [though rare case]
  const baseMessagesRef = firebase.messages().push({});

  baseMessagesRef.child('messages').push({
    sender: sender.uid,
    text,
    timestamp: moment().format(DATE_TIMEZONE_FORMAT),
  });

  const messageDetails = {
    partnershipType: partnership.type,
    partnershipUID: partnership.uid,
  };

  if (isRejection) {
    messageDetails.isRejection = true;
  }

  baseMessagesRef.child('details').update(messageDetails);

  const usersRef = baseMessagesRef.child('users');
  usersRef.child(sender.uid).update({
    name: sender.name,
    type: sender.type,
    hasUnread: false,
  });

  firebase.db.ref(`${sender.type}s/${sender.uid}/messages`).update({
    [baseMessagesRef.key]: shouldAppearInMessage,
  });

  recipients.forEach((recipient) => {
    usersRef.child(recipient.uid).update({
      name: recipient.name,
      type: recipient.type,
      hasUnread: true,
    });

    firebase.db.ref(`${recipient.type}s/${recipient.uid}/messages`).update({
      [baseMessagesRef.key]: true,
    });
  });
};

export const getInfluencerMessageUID = (brandUID, influencerUID, callback) => {
  return (dispatch) => {
    dispatch(fetchRequest());

    firebase.influencer(influencerUID).child(`messages`).orderByValue().equalTo(true).once('value').then((snapshot) => {
      if (!snapshot.val()) {
        callback(false);
        return;
      }

      // set to reverse rare scenario, duplicate thread occur.
      // get latest thread conversation
      const influencerMessageUIDs = Object.keys(snapshot.val()).reverse();

      firebase.brand(brandUID).child(`messages`).orderByValue().equalTo(true).once('value').then((childSnapshot) => {
        if (!childSnapshot.val()) {
          callback(false);
          return;
        }

        // set to reverse if rare scenario, duplicate thread occur.
        // get latest thread conversation
        const brandMessageUIDs = Object.keys(childSnapshot.val()).reverse();

        for(let messageUID of brandMessageUIDs) {
          if (influencerMessageUIDs.includes(messageUID)) {
            callback(true, messageUID);
            return;
          }
        }
      }).catch((e) => {
        callback(false);
        console.log('Error on getting brand messages UID: ', e);
      });
    }).catch((e) => {
      callback(false);
      console.log('Error on getting influencer messages UID: ', e);
    }).finally(() => {
      dispatch(receiveRequest());
    });
  }
};
