import React, { useState, useEffect } from 'react';
import { Table, Button, Row, Icon, Dropdown, Menu } from 'antd';
import moment from 'moment';
import MinLayout from 'components/Common/MinLayout';
import { useDispatch } from 'react-redux';
import { ReactComponent as IconMessages } from 'images/icon-messages.svg';
import { UpCaseFirstLetter } from 'lib/Common';
import InfluencerImg1 from 'images/demo/influencer-1.png';
import InfluencerImg2 from 'images/demo/influencer-2.png';
import InfluencerImg3 from 'images/demo/influencer-3.png';
import DemoInfluencerAvatar from './InfluencerAvatar';
import DemoInfluencersBanner from './InfluencersBanner';
import { InfluencerDrawer } from '../Influencers';
import { setInfluencers } from '../../actions/influencers';

const { Item: MenuItem } = Menu;

const ActionButton = ({ influencerUID }, record) => {
  const moreOptions = (
    <Menu className="remove-dropdown">
      <MenuItem>
        <span role="presentation">REMOVE FROM CAMPAIGN</span>
      </MenuItem>
    </Menu>
  );

  return (
    <div className="cell-action">
      <Button
        onClick={() => {
          record.history.push(`/demo/messages/${influencerUID}`);
        }}
        shape="circle"
        style={{
          background: '#ffb917',
          border: '0',
          marginRight: '5px',
          width: '35px',
          height: '35px'
        }}
      >
        <IconMessages
          style={{
            position: 'relative',
            top: '2px',
            width: '15px',
            height: '14px'
          }}
        />
      </Button>

      <Dropdown
        overlay={moreOptions}
        placement="bottomRight"
        trigger={['click']}
        className="more-options-btn"
      >
        <Button shape="circle">
          <Icon type="ellipsis" />
        </Button>
      </Dropdown>
    </div>
  );
};

const Categories = categories =>
  categories.map(category => <div key={category}>{UpCaseFirstLetter(category)}</div>);

const columns = [
  {
    title: 'INFLUENCER',
    dataIndex: 'influencer',
    key: 'influencer',
    className: 'pl-0 influencer-width',
    render: influencer => <DemoInfluencerAvatar influencer={influencer} />
  },
  {
    title: 'LOCATION',
    dataIndex: 'location',
    key: 'location',
    className: 'text-right location'
  },
  {
    title: 'AGE RANGE',
    dataIndex: 'ageRange',
    key: 'ageRange',
    className: 'text-right'
  },
  {
    title: 'GENDER',
    dataIndex: 'gender',
    key: 'gender',
    className: 'text-right'
  },
  {
    title: 'CATEGORY',
    dataIndex: 'category',
    key: 'category',
    className: 'text-right',
    render: Categories
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    align: 'right',
    render: ActionButton
  }
];

const dataSource = [
  {
    key: 'ft-123456789',
    influencer: {
      avatar: InfluencerImg1,
      isVIP: true,
      username: 'demo-influencer1',
      state: 'New York, NY'
    },
    location: 'Demo Address',
    gender: 'Male',
    ageRange: '18-21',
    category: ['Adventure', 'Fashion'],
    action: {
      influencerUID: 'ft-123456789'
    }
  },
  {
    key: 'ft-987654321',
    influencer: {
      avatar: InfluencerImg2,
      isVIP: false,
      username: 'demo-influencer2',
      state: 'New York, NY'
    },
    location: 'Demo Address',
    gender: 'Female',
    ageRange: '28-30',
    category: ['Adventure'],
    action: {
      influencerUID: 'ft-987654321'
    }
  },
  {
    key: 'ft-987654321123',
    influencer: {
      avatar: InfluencerImg3,
      isVIP: false,
      username: 'demo-influencer3',
      state: 'New York, NY'
    },
    location: 'Demo Address',
    gender: 'Female',
    ageRange: '23-25',
    category: ['Fashion'],
    action: {
      influencerUID: 'ft-987654321123'
    }
  }
];

const Influencers = ({ history }) => {
  const dispatch = useDispatch();
  const [influencerDetailsModal, setInfluencerDetailsModal] = useState({
    show: false,
    influencerUID: null
  });

  dataSource.forEach((data, i) => {
    dataSource[i].history = history;
  });

  function handleHideInfluencerDetails() {
    setInfluencerDetailsModal(state => ({ ...state, show: false }));
  }

  const handleShowInfluencerDetails = influencerUID => event => {
    if (
      (event.target.tagName !== 'TD' &&
        event.target.closest('td') &&
        event.target.closest('td').className.includes('actions')) ||
      (event.target.closest('li') &&
        event.target.closest('li').className.includes('ant-dropdown-menu-item'))
    ) {
      return;
    }

    setInfluencerDetailsModal({
      show: true,
      influencerUID
    });
  };

  useEffect(() => {
    dataSource.forEach((data, index) => {
      const { influencer } = data;
      const lastWeek = moment()
        .subtract(1, 'week')
        .format('YYYY-w');
      const times = index + 1;
      dispatch(
        setInfluencers({
          [data.key]: {
            details: {
              state: influencer.state,
              username: influencer.username,
              image: influencer.avatar,
              isVIP: influencer.isVIP,
              ageRange: data.ageRange
            },
            instagram: {
              avgCommentCount: 1900 * times,
              avgLikeCount: 32700 * times,
              biography: 'Enjoy Your Demo and \nhave a great day',
              followedByCount: 13800 * times,
              stats: {
                [lastWeek]: {
                  avgCommentCount: 1857 * times,
                  avgLikeCount: 32300 * times
                }
              }
            }
          }
        })
      );
    });
  }, []);

  return (
    <Row type="flex" justify="space-around">
      <MinLayout>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          className="trend-table"
          onRow={record => ({
            onClick: handleShowInfluencerDetails(record.key)
          })}
        />
        <DemoInfluencersBanner />
        <InfluencerDrawer {...influencerDetailsModal} onHide={handleHideInfluencerDetails} />
      </MinLayout>
    </Row>
  );
};

export default Influencers;
