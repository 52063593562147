import React, { Component } from 'react';
// import { Row, Col, Checkbox, Spin } from 'antd';
import { Row, Col, Spin } from 'antd';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { firebase } from 'lib/Firebase';
import MinLayout from 'components/Common/MinLayout';
import { setMultipleSelect } from 'actions/ui/applicationsFilter';
import CampaignPolicy from 'policies/CampaignPolicy';
import ApplicationList from './ApplicationList';
import ApplicationsFilter from './ApplicationsFilter';
import FreeTrialBanner from './FreeTrialBanner';

class Applications extends Component {
  componentWillUnmount() {
    firebase.activeProducts().off();
    firebase.influencers().off();
  }

  render() {
    const {
      multipleSelect,
      hasInfluencers,
      // setMultipleSelect: showMultipleSelect,
      isFetching
    } = this.props;

    return (
      <div id="application-page">
        {hasInfluencers && !multipleSelect && (
          <div
            style={{
              background: '#fcfcfc',
              borderBottom: '1px solid #e8e8e8',
              padding: '15px 0'
            }}
          >
            <Row type="flex" justify="space-around">
              <MinLayout>
                <Col span={9}>
                  <ApplicationsFilter />
                </Col>
                <Col span={15} className="pt-1">
                  <span
                    className="type-sfpro-regular size-12px"
                    style={{
                      fontStyle: 'italic',
                      fontWeight: '700',
                      color: '#5B6572',
                      float: 'right',
                      lineHeight: '18px'
                    }}
                  >
                    Applications will be automatically rejected after 45 days
                  </span>
                  {/* <label htmlFor="multipleSelect" className="d-block clickable leading-18px">
                    <Checkbox
                      id="multipleSelect"
                      checked={multipleSelect}
                      onChange={showMultipleSelect}
                      className="custom-checkbox"
                      style={{ float: 'left', marginRight: '6px' }}
                    />
                    <span
                      className="size-10px type-firme-iblack spacing-1px text-black pl-1"
                      style={{ fontSize: '0.8em' }}
                    >
                      SELECT MULTIPLE
                    </span>
                  </label> */}
                </Col>
              </MinLayout>
            </Row>
          </div>
        )}
        {isFetching && (
          <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <Spin />
          </div>
        )}
        <div style={{ background: '#ffffff' }}>
          <ApplicationList />
          <FreeTrialBanner />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, { match: { params } }) => ({
  multipleSelect: state.ui.applicationsFilter.multipleSelect,
  hasInfluencers:
    state.ui.campaignDashboard.byCampaignId[params.campaignId] &&
    state.ui.campaignDashboard.byCampaignId[params.campaignId].applicantUIDs &&
    state.ui.campaignDashboard.byCampaignId[params.campaignId].applicantUIDs.length > 0,
  isFetching: state.entities.influencers.isFetching,
  brands: state.entities.brands.byId
});

const mapDispatchToProps = dispatch => ({
  setMultipleSelect: () => dispatch(setMultipleSelect(true))
});

export default compose(
  CampaignPolicy,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Applications);
