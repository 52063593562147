import React from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { firebase } from '../../lib/Firebase';
import JobScheduler from '../../lib/Firebase/JobScheduler';

const RestartTrialButton = ({ brandUID }) => {
  async function handleClick() {
    const products = await firebase
      .activeProducts()
      .orderByChild('brandUID')
      .equalTo(brandUID)
      .once('value');

    if (products.exists() && Object.keys(products.val()).length === 1) {
      const [firstProductUID] = Object.keys(products.val());

      const today = moment().unix();
      await firebase.activeProduct(firstProductUID).update({
        isListed: true,
        extendedTrialDate: today
      });

      const job = new JobScheduler('autoUnlistProduct', {
        productUID: firstProductUID,
        brandUID
      });

      job.performAt(moment(today * 1000).add(24, 'hours'));
    }
  }

  return <Button onClick={handleClick}>Restart Trial</Button>;
};

export default RestartTrialButton;
