import {
  LIST_YOTPO_METRICS,
  YOTPO_BRANDS,
  ADD_LIST_YOTPO_METRICS,
  ADD_YOTPO_API_DATA
} from 'reducers/ui/admin/yotpoMetrics';

export const listYotPoMetrics = yotpos => ({
  type: LIST_YOTPO_METRICS,
  yotpos
});

export const yotpoBrands = brands => ({
  type: YOTPO_BRANDS,
  brands
});

export const addListYotPoMetrics = yotpo => ({
  type: ADD_LIST_YOTPO_METRICS,
  yotpo
});

export const addYotPoApiData = (yotpoId, yotpoData) => ({
  type: ADD_YOTPO_API_DATA,
  yotpoId,
  yotpoData
});
