import React from 'react';
import { Modal, Row, Col, Button } from 'antd';

const ConfirmApproveModal = props => {
  const {
    show,
    hide,
    selectedInfluencerUIDs,
    approveInfluencers,
    creditsTally,
    submitApprove
  } = props;

  return (
    <Modal visible={show} closable={false} className="trend-modal" footer={null} width={423}>
      <div>
        <h2 className="black mb-2 ht-6 lh-2">
          Are you sure you want to
          <br />
          <span style={{ color: '#ffb917', fontStyle: 'italic' }}>approve</span>
          &nbsp;
          {selectedInfluencerUIDs.length === 1 ? 'this influencer?' : 'this influencers?'}
        </h2>
        <div className="text-black size-12px lh-20px" style={{ marginTop: '10px' }}>
          Approving an influencer will cost you either 1 or 2 campaign credits per influencer.
          Review the summary below before confirming your approval.
        </div>
      </div>
      <div
        style={{
          marginTop: '25px',
          marginBottom: '30px',
          width: '371px',
          objectFit: 'contain',
          borderRadius: '2px',
          border: 'solid 1px #d6d6d6',
          backgroundColor: '#ffffff'
        }}
      >
        <Row style={{ marginTop: '29px' }}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <div
              style={{
                fontSize: '60px',
                fontFamily: 'truenoextrabold',
                color: '#3c3c3c'
              }}
            >
              {creditsTally.standard + creditsTally.vip * 2}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div
              style={{
                fontFamily: 'truenoextrabold',
                fontSize: '20px',
                color: '#3c3c3c',
                textAlign: 'center',
                marginBottom: '41px'
              }}
            >
              TOTAL CREATOR CREDITS
            </div>
          </Col>
        </Row>
      </div>
      <Row gutter={20} key="row-multi-action">
        <Col span={12}>
          <Button
            key="cancel"
            disabled={submitApprove}
            className="trend-btn medium-btn secondary"
            onClick={() => hide('visibleConfirmApprove')}
            block
          >
            Cancel
          </Button>
        </Col>
        <Col span={12}>
          <Button
            key="approve"
            type="primary"
            className="trend-btn medium-btn"
            loading={submitApprove}
            onClick={approveInfluencers}
            block
          >
            Approve
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default ConfirmApproveModal;
