import React, { useEffect, useState } from 'react';
import Promise from 'bluebird';
import { Row, Col, Modal, Table, Button, message } from 'antd';
import moment from 'moment';
import {
  businessForUpdateSubs,
  businessForUpdateListener
} from '../../../listeners/businessesForUpdate';
import { firebase } from '../../../lib/Firebase';
import Container from '../../../components/Common/Container';

const initialPagination = { page: 1, limit: 250 };
const modalInitial = { show: false, businessId: null, index: null, email: null };
const BusinessesForUpdate = () => {
  const [pagination, setPagination] = useState(initialPagination);
  const [approveModal, setApproveModal] = useState(modalInitial);
  const [rejectModal, setRejectModal] = useState(modalInitial);
  const [approvedAndRejected, setApprovedAndRejected] = useState(false);
  const [businessesList, setBusinessesList] = useState([]);
  const [lastBusinessDoc, setLastBusinessDoc] = useState(0);
  const [lastBusinessDocApprovedRejected, setLastBusinessDocApprovedRejected] = useState(0);
  const [source, setSource] = useState([]);

  const columns = [
    {
      key: 'name',
      title: 'Name',
      render: business => {
        return business && business.name;
      }
    },
    {
      key: 'email',
      title: 'Email',
      render: business => {
        return business && business.email;
      }
    },
    {
      dataIndex: 'registrationDate',
      key: 'registrationDate',
      title: 'Registration Date',
      render: registrationDate =>
        registrationDate ? moment.unix(registrationDate).format('YY/MM/DD') : null,
      sorter: () => {}
    },
    {
      key: 'firstCampaign',
      title: 'First Campaign',
      dataIndex: 'firstCampaign',
      render: firstCampaign => (firstCampaign ? <span>&#10003;</span> : null)
    },
    {
      key: 'approvalDate',
      dataIndex: 'approvalDate',
      title: 'Approval Date',
      render: approvalDate => (approvalDate ? moment.unix(approvalDate).format('YY/MM/DD') : null),
      sorter: () => {}
    },
    {
      key: 'firstCredit',
      dataIndex: 'firstCredit',
      title: 'First Credit',
      render: firstCredit => (firstCredit ? <span>&#10003;</span> : null)
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      align: 'center',
      key: 'actions',
      className: 'actions',
      render: ({ firstCampaign, status, onApprove, onReject }) => {
        const displayRecord = () => {
          let display = null;
          if (status === 'approved') {
            display = 'Approved';
          } else if (status === 'rejected') {
            display = 'Rejected';
          } else if (firstCampaign) {
            display = (
              <div style={{ width: '162px' }}>
                <Button type="primary" onClick={onApprove}>
                  Approve
                </Button>
                <Button onClick={onReject}>Reject</Button>
              </div>
            );
          }
          return display;
        };
        return <>{displayRecord()}</>;
      }
    }
  ];

  async function retrieveBusinesses(lastDocBusiness = null) {
    const listener = await businessForUpdateListener(
      lastDocBusiness,
      pagination.limit,
      approvedAndRejected,
      lastBusinessDocApprovedRejected,
      (businesses, businessLastDoc, approveAndRejectStatus, lastDocApprovedRejected) => {
        setBusinessesList(prevState => {
          return [...prevState, ...businesses];
        });
        setLastBusinessDoc(businessLastDoc);
        setApprovedAndRejected(approveAndRejectStatus);
        setLastBusinessDocApprovedRejected(lastDocApprovedRejected);
      }
    );

    businessForUpdateSubs.addListener(listener);
  }

  useEffect(() => {
    async function requestBusiness() {
      retrieveBusinesses();
    }

    requestBusiness();
  }, []);

  const updateState = (status, isApprove) => {
    const updateBusinessList = businessesList;

    if (isApprove) {
      const { index } = approveModal;
      updateBusinessList[index].status = status;
      setApproveModal(modalInitial);
    } else {
      const { index } = rejectModal;
      updateBusinessList[index].status = status;
      setRejectModal(modalInitial);
    }
    setBusinessesList(updateBusinessList);
  };

  async function updateBrandStatus(status, field) {
    const brandUID = status === 'approved' ? approveModal.businessId : rejectModal.businessId;
    await firebase.firestore
      .collection('brands')
      .doc(brandUID)
      .update({
        status,
        [field]: moment().unix()
      });

    await firebase
      .brand(brandUID)
      .child('details')
      .update({ status, [field]: moment().unix() });
  }

  async function handleApprove() {
    try {
      updateBrandStatus('approved', 'approvedAt');
      updateState('approved', true);

      // making product/campaign listed true upon approval
      const productsSnap = await firebase
        .activeProducts()
        .orderByChild('brandUID')
        .equalTo(approveModal.businessId)
        .once('value');
      const products = productsSnap.val();

      await Promise.map(Object.keys(products), async productId => {
        // update firestore products
        await firebase.firestore
          .collection('products')
          .doc(productId)
          .update({ isListed: true });

        // update products active (RDB)
        await firebase.activeProduct(productId).update({ isListed: true });
      });

      await firebase.setACTag(approveModal.email, 'brand-application-approved');

      message.success('Successfully Approved!');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('error', e);
      message.error('Something went wrong');
    }
  }

  async function handleReject() {
    try {
      updateBrandStatus('rejected', 'rejectedAt');
      updateState('rejected');

      message.success('Successfully Rejected!');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('error', e);
      message.error('Something went wrong');
    }
  }

  async function loadMore() {
    setPagination({
      ...pagination,
      page: pagination.page + 1
    });

    await retrieveBusinesses(lastBusinessDoc);
  }

  const showLimit = pagination.limit * pagination.page;

  useEffect(() => {
    setSource(
      businessesList
        .filter(data => Object.keys(data).length > 0)
        .map((data, index) => {
          return {
            key: data.uid,
            name: data.name,
            email: data.email,
            registrationDate: data.createdAtUnix ? data.createdAtUnix : 0,
            firstCampaign: data.firstCampaign,
            approvalDate: data.approvedAt ? data.approvedAt : 0,
            firstCredit: data.firstCredit,
            actions: {
              status: data.status,
              firstCampaign: data.firstCampaign,
              onApprove: () =>
                setApproveModal({ show: true, businessId: data.uid, index, email: data.email }),
              onReject: () =>
                setRejectModal({ show: true, businessId: data.uid, index, email: data.email })
            }
          };
        })
        .slice(0, showLimit)
    );
  }, [businessesList]);

  // let source = ;

  const sortData = (key, order = 'ascend') => {
    return function innerSort(a, b) {
      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'descend' ? comparison * -1 : comparison;
    };
  };

  const handleChange = (tablePagination, filters, sorter) => {
    if (sorter.order) {
      setSource(source.sort(sortData(sorter.columnKey, sorter.order)));
    } else {
      setSource(
        businessesList
          .filter(data => Object.keys(data).length > 0)
          .map((data, index) => {
            return {
              key: data.uid,
              name: data.name,
              email: data.email,
              registrationDate: data.createdAtUnix ? data.createdAtUnix : 0,
              firstCampaign: data.firstCampaign,
              approvalDate: data.approvedAt ? data.approvedAt : 0,
              firstCredit: data.firstCredit,
              actions: {
                status: data.status,
                firstCampaign: data.firstCampaign,
                onApprove: () =>
                  setApproveModal({ show: true, businessId: data.uid, index, email: data.email }),
                onReject: () =>
                  setRejectModal({ show: true, businessId: data.uid, index, email: data.email })
              }
            };
          })
          .slice(0, showLimit)
      );
    }
  };

  return (
    <Container width={1300} id="admin-business-approval">
      <Table
        dataSource={source}
        columns={columns}
        pagination={false}
        onChange={handleChange}
        showHeader
      />
      <Modal
        visible={approveModal.show}
        closable={false}
        footer={false}
        className="admin-approve-modal"
      >
        <p className="text-center">Are you sure you want to approve this Business?</p>
        <Row type="flex" justify="center">
          <Col className="button-options">
            <Button type="primary" block onClick={() => handleApprove()}>
              Confirm
            </Button>
            <Button block onClick={() => setApproveModal({ ...approveModal, show: false })}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        visible={rejectModal.show}
        closable={false}
        footer={false}
        className="admin-approve-modal"
      >
        <p className="text-center">Are you sure you want to reject this Business?</p>
        <Row type="flex" justify="center">
          <Col className="button-options">
            <Button type="primary" block onClick={() => handleReject()}>
              Confirm
            </Button>
            <Button block onClick={() => setRejectModal({ ...rejectModal, show: false })}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal>
      <div className="text-center loadmore">
        {businessesList.length > showLimit - 1 && (
          <Button className="load-more" onClick={() => loadMore()}>
            LOAD MORE
          </Button>
        )}
      </div>
    </Container>
  );
};

export default BusinessesForUpdate;
