import { SET_LOGGED_USER, REMOVE_LOGGED_USER, UPDATE_LOGGED_USER } from 'reducers/entities/session';
import { firebase } from '../lib/Firebase';

export const setLoggedUser = authUserData => ({
  type: SET_LOGGED_USER,
  authUserData
});

export const updateSession = authUserData => ({
  type: UPDATE_LOGGED_USER,
  authUserData
});

export const removeLoggedUser = () => ({
  type: REMOVE_LOGGED_USER
});

export const fetchLoggedUserData = (authUser, callback = null) => dispatch => {
  firebase.brand(authUser.uid).once('value', async snapshot => {
    const brand = snapshot.val();

    const integrationsCollection = await firebase.firestore
      .collection('brands')
      .doc(authUser.uid)
      .collection('brandIntegrations')
      .get();

    const integrations = {};
    integrationsCollection.docs.forEach(doc => {
      integrations[doc.id] = true;
    });

    dispatch(
      setLoggedUser({
        ...authUser,
        ...brand,
        integrations
      })
    );

    if (callback) {
      callback(brand);
    }
  });
};

export const clearSession = () => dispatch => {
  dispatch(removeLoggedUser());
};

export const updateLoggedUser = authUser => dispatch => {
  dispatch(updateSession(authUser));
};
