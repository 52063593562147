import React from 'react';
import { Form, Button } from 'antd';
import TagSelect from '../../Form/TagSelect';

class Social extends React.Component {
  handleSubmit = () => {
    const {
      onSubmit,
      onError,
      form: { validateFieldsAndScroll }
    } = this.props;

    validateFieldsAndScroll(async (error, fields) => {
      if (!error) {
        onError(false);
        await onSubmit(fields);
      } else {
        onError(true);
      }
    });
  };

  render() {
    const { form, campaign } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form
        layout="vertical"
        className="product-form pb-8"
        hideRequiredMark
        onSubmit={this.handleSubmit}
      >
        <h1 className="page-header">Social Media Details</h1>
        <div className="page-description">
          Make Influencers include your brand’s Instagram handle with their posts. This gives
          audience members will have a clear path to visit your page. Add required hashtags to drive
          people help your posts be discovered by people searching for specific themes.
        </div>
        <Form.Item
          label={(
            <div className="with-sub-label">
              Required Instagram Handles
              <div className="sub-label">Press “Enter” after each handle</div>
            </div>
)}
        >
          {getFieldDecorator('brandtags', {
            initialValue: campaign.brandtags,
            rules: [
              {
                required: true,
                message: 'Please provide at least one brand tag'
              }
            ]
          })(<TagSelect tagPrefix="@" placeholder="YourHandle" />)}
        </Form.Item>
        <Form.Item
          label={(
            <div className="with-sub-label">
              Required Hashtags (Max 5)
              <div className="sub-label">Press Enter after each tag</div>
            </div>
)}
        >
          {getFieldDecorator('hashtags', {
            initialValue: campaign.hashtags,
            rules: [{ required: true, message: 'Please provide at least one hashtag' }]
          })(<TagSelect tagPrefix="#" max={5} placeholder="yourhashtag" />)}
        </Form.Item>
        <div align="center">
          <Button
            size="large"
            type="submit"
            className="trend-btn product-btn"
            onClick={this.handleSubmit}
          >
            Next Step - Actions
          </Button>
        </div>
      </Form>
    );
  }
}

export default Form.create({ name: 'social-pane' })(Social);
