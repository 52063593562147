import React from 'react';
import { Row, Col } from 'antd';

const Container = ({ width, children, ...rest }) => {
  return (
    <Row type="flex" justify="center">
      <Col style={{ width }} {...rest}>
        {children}
      </Col>
    </Row>
  );
};

export default Container;
