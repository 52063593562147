import React, { useState } from 'react';
import { Button } from 'antd';
import { postResourceDownloader } from './PostDownloader';

const DownloadButton = ({ post, filename }) => {
  const [downloading, setDownloadingState] = useState(false);

  async function downloadResource(e) {
    e.preventDefault();

    setDownloadingState(true);

    const name = filename.replace(/[.,]/g, '');
    await postResourceDownloader(post, name, name);
    setDownloadingState(false);
  }

  return (
    <Button onClick={downloadResource} type="default" block loading={downloading}>
      DOWNLOAD CREATIVE
    </Button>
  );
};

export default DownloadButton;
