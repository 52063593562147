import numeral from 'numeral';

const formatSocialData = number => {
  if (!number) {
    return 0;
  }

  const num = Math.round(number);

  if (num > 9999) {
    const abbr = numeral(num).format('0.0a');

    return abbr.split('.0').join('');
  }

  return numeral(num).format('0,0');
};

export const formatSocialDataMin = number => numeral(number).format('0.00');

export const formatCurrency = (number, sign, decimal = '00') =>
  numeral(number).format(`${sign}0,0.${decimal}`);

export default formatSocialData;
