import React, { Component } from 'react';
import moment from 'moment';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeModeView } from 'actions/ui/creativeAssets';
import { Row, Col, Radio, Button } from 'antd';
import { fetchCampaignById } from 'actions/campaigns';
import { addPosts } from 'actions/posts';
import { ReactComponent as IconGrid } from 'images/icon-grid.svg';
import IconList from 'images/icon-list@3x.png';
import { downloadAllPostResources } from '../../Post/PostDownloader';
import { firebase } from '../../../lib/Firebase';
import { YotPoBtn } from '../../YotPo';
import { DATE_TIMEZONE_FORMAT } from '../../../constants/common';
import YotPoImage from '../../../images/yotpo-circle@2x.png';

const { Button: RadioButton, Group: RadioGroup } = Radio;

class ViewOption extends Component {
  state = {
    downloadAllHD: false
  };

  constructor(props) {
    super(props);

    const { match, fetchCampaignById: fetchCampaign } = props;

    const { campaignId } = match.params;
    fetchCampaign(campaignId);
  }

  downloadHDImages = async () => {
    const {
      posts,
      postUIDs,
      campaign,
      match: {
        params: { campaignId }
      }
    } = this.props;
    const folderName = (campaign.name || 'defaultHDFilename').replace(/[.,]/g, '');

    this.setState({ downloadAllHD: true });
    let allPosts = {};
    postUIDs.forEach(uid => {
      const post = posts[uid];

      if (post.status === 'approved') {
        allPosts = { ...allPosts, [uid]: posts[uid] };
      }
    });

    const snap = await firebase.additionalMedia(campaignId).once('value');
    const additionalMedia = snap.val() || {};

    try {
      await downloadAllPostResources(allPosts, additionalMedia, folderName);
      this.setState({ downloadAllHD: false });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  yotpoImages = () => {
    const { posts, influencers, postUIDs } = this.props;

    const images = postUIDs.map(postUID => {
      const post = posts[postUID];
      const { influencerUID, details: postDetails } = post;
      const { fullResImage, standardResolutionImage, captionText, createdTime } = postDetails;
      const {
        details: { username }
      } = influencers[influencerUID];

      return {
        user_name: username,
        image_url: fullResImage || standardResolutionImage,
        caption: captionText,
        creation_date: moment(createdTime, DATE_TIMEZONE_FORMAT).format('YYYY-MM-DD')
      };
    });

    return images;
  };

  onChangeView = e => {
    const { changeModeView: modeView } = this.props;
    modeView(e.target.value);
  };

  render() {
    const { view, postUIDs } = this.props;
    const { downloadAllHD } = this.state;

    return (
      <Row type="flex" align="middle" style={{ marginTop: '20px' }}>
        <Col span={12} style={{ paddingTop: 10 }}>
          <RadioGroup defaultValue={view} onChange={this.onChangeView} className="plain-radio">
            <RadioButton value="grid">
              <span style={{ display: 'block', lineHeight: '11px' }}>
                <IconGrid
                  style={{
                    width: '15px',
                    height: '15px',
                    marginRight: '10px',
                    verticalAlign: 'middle'
                  }}
                />
                <span className="size-11px">Grid</span>
              </span>
            </RadioButton>
            <RadioButton value="list">
              <span style={{ display: 'block', lineHeight: '11px' }}>
                <img
                  src={IconList}
                  style={{
                    width: '19px',
                    height: '15px',
                    marginRight: '10px',
                    verticalAlign: 'middle'
                  }}
                  alt="Icon List"
                />
                <span className="size-11px">List</span>
              </span>
            </RadioButton>
          </RadioGroup>
        </Col>
        <Col span={12} className="text-right bulk-actions">
          <YotPoBtn postUIDs={postUIDs}>
            Send content to <img src={YotPoImage} alt="yotpo logo" />
          </YotPoBtn>{' '}
          <Button
            loading={downloadAllHD}
            onClick={this.downloadHDImages}
            size="large"
            className="trend-btn type-firme-iblack size-10px text-black spacing-05px"
          >
            Download HD Creative
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (
  state,
  {
    match: {
      params: { campaignId }
    }
  }
) => ({
  view: state.ui.modeView.view,
  posts: state.entities.posts.byId,
  influencers: state.entities.influencers.byId,
  campaign: state.entities.campaigns.byId[campaignId],
  postUIDs: state.ui.campaignPosts[campaignId]
});

const mapDispatchToProps = dispatch => ({
  dispatchAddPosts: posts => dispatch(addPosts(posts)),
  changeModeView: modeView => dispatch(changeModeView(modeView)),
  fetchCampaignById: campaignUID => dispatch(fetchCampaignById(campaignUID))
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ViewOption);
