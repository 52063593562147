import Promise from 'bluebird';
import {
  CAMPAIGN_LIST_CATEGORY,
  CAMPAIGN_LIST_MY_CAMPAIGN,
  CAMPAIGN_LIST_BRAND_UID,
  CLEAR_CAMPAIGN_LIST,
  SET_CAMPAIGN_LIST
} from 'reducers/ui/campaignList';
import { receiveCampaigns, fetchRequest } from 'actions/campaigns';
import { receiveBrands } from 'actions/brands';
import { firebase } from '../../lib/Firebase';

export const setCampaignList = campaignUIDs => ({
  type: SET_CAMPAIGN_LIST,
  payload: campaignUIDs
});

export const clearCampaignList = () => ({
  type: CLEAR_CAMPAIGN_LIST
});

const retrieveBrands = dispatch => campaigns => {
  let brands = {};

  return Promise.map(Object.keys(campaigns), campaignUID => {
    const campaign = campaigns[campaignUID];

    return firebase
      .brand(campaign.brandUID)
      .once('value')
      .then(brandSnap => {
        brands = {
          ...brands,
          [brandSnap.key]: brandSnap.val()
        };
      });
  }).then(() => {
    dispatch(receiveBrands(brands));
    return campaigns;
  });
};

// const insertOwnedCampaigns = (newIds, getState) => {
//   const {
//     entities: {
//       campaigns: { byId: campaigns }
//     },
//     ui: {
//       sideNav: { ownedCampaigns: ownedIds },
//       campaignList: { list: existingIds }
//     }
//   } = getState();

//   const insertableIds = _.difference(ownedIds, existingIds);
//   if (_.isEmpty(insertableIds) && !_.difference(newIds, ownedIds)) {
//     return newIds;
//   }

//   if (_.isEmpty(newIds) && !_.isEmpty(insertableIds)) {
//     return ownedIds;
//   }

//   const finalyIds = [];

//   newIds.forEach(id => {
//     insertableIds.forEach(iid => {
//       const date1 = new Date(campaigns[id].createdAt).getTime();
//       const date2 = new Date(campaigns[iid].createdAt).getTime();
//       if (date1 < date2) {
//         finalyIds.push(insertableIds.shift());
//       }
//     });
//     finalyIds.push(id);
//   });

//   return finalyIds;
// };

export const fetchCampaignsV2 = () => async dispatch => {
  dispatch(fetchRequest());

  const exampleCampaigns = (await firebase.db.ref('admin/exampleCampaigns').once('value')).val();
  console.log(exampleCampaigns);

  let campaigns = {};
  await Promise.map(exampleCampaigns, async campaignUID => {
    const productSnap = await firebase.firestore
      .collection('products')
      .doc(campaignUID)
      .get();

    campaigns = {
      ...campaigns,
      [campaignUID]: productSnap.data()
    };
  });

  dispatch(retrieveBrands)(campaigns);
  dispatch(receiveCampaigns(campaigns));
  dispatch(setCampaignList(Object.keys(campaigns)));
};

export const setCampaignListCategory = category => (dispatch, getState) => {
  dispatch(clearCampaignList());

  dispatch({
    type: CAMPAIGN_LIST_CATEGORY,
    category
  });

  const parameters = {
    ...getState().ui.campaignList.params,
    category
  };

  dispatch(fetchCampaignsV2(parameters));
};

export const setCampaignListBrand = brandUID => (dispatch, getState) => {
  dispatch(clearCampaignList());

  dispatch({
    type: CAMPAIGN_LIST_BRAND_UID,
    brandUID
  });

  const parameters = {
    ...getState().ui.campaignList.params,
    brandUID
  };

  dispatch(fetchCampaignsV2(parameters));
};

export const setCampaignListMyCampaign = myCampaign => ({
  type: CAMPAIGN_LIST_MY_CAMPAIGN,
  myCampaign
});

export const setCampaignListBrandUID = brandUID => ({
  type: CAMPAIGN_LIST_BRAND_UID,
  brandUID
});
