export const CHANGE_MODE_VIEW = 'CHANGE_MODE_VIEW';

const defaultState = {
  view: 'grid'
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_MODE_VIEW:
      return {
        ...state,
        view: action.view
      };

    default:
      return state;
  }
};
