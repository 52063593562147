import axios from "axios";
import JSZip from "jszip";
import Promise from "bluebird";
import { saveAs } from "file-saver";

const fileTypes = {
  "image/jpg": "jpg",
  "image/jpeg": "jpg",
  "image/png": "png",
  "video/mp4": "mp4",
  "video/quicktime": "mov"
};

const requestResource = url => {
  return axios({
    method: "get",
    url,
    responseType: "blob"
  });
};

export const retrieveAndCompressFiles = async (urls = [], filename = "") => {
  const zip = new JSZip();

  return Promise.map(urls, ({ filename, url, fallbackUrl }) => {
    return requestResource(url)
      .then(response => {
        const type = fileTypes[response.headers["content-type"]];
        if (!type) {
          console.log(
            "File type not supported: ",
            response.headers["content-type"]
          );
          throw new Error("Some of the files attached is not supported");
        }

        zip.file(`${filename}.${type}`, response.data, { binary: true });
      })
      .catch(() => {
        return requestResource(fallbackUrl).then(response => {
          const type = fileTypes[response.headers["content-type"]];
          if (!type) {
            console.log(
              "File type not supported: ",
              response.headers["content-type"]
            );
            throw new Error("Some of the files attached is not supported");
          }

          zip.file(`${filename}.${type}`, response.data, { binary: true });
        });
      })
      .catch(e => {
        console.log("Data resources was not retrieve:", url);
        return;
      });
  })
    .then(() => {
      zip.generateAsync({ type: "blob" }).then(content => {
        saveAs(content, filename);
      });
    })
    .catch(e => {
      console.error("Cant retrieve image", e);
    });
};

export const retrieveFile = async (url, filename = "", fallbackUrl) =>
  requestResource(url)
    .then(response => {
      const type = fileTypes[response.headers["content-type"]];
      saveAs(response.data, `${filename}.${type}`, { type: "blob" });
    })
    .catch(() => {
      return requestResource(fallbackUrl).then(response => {
        const type = fileTypes[response.headers["content-type"]];
        saveAs(response.data, `${filename}.${type}`, { type: "blob" });
      });
    })
    .catch(() => {
      console.error("Data resources was not retrieve: ", url);
    });
