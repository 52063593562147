import { firebase } from 'lib/Firebase';

const doUpdateEmail = (email, currentPassword, callback) => {
  const user = firebase.auth.currentUser;
  const creds = firebase.app.auth.EmailAuthProvider.credential(
    user.email,
    currentPassword,
  );

  user.reauthenticateWithCredential(creds).then(() => {
    user.updateEmail(email).then(() => {
      callback(true);
    }).catch((error) => {
      callback(false, { error });
    });
  }).catch((error) => {
    callback(false, { error });
  });
};

const doUpdatePassword = (email, params, callback) => {
  const { currentPassword, newPassword, confirmPassword } = params;

  const user = firebase.auth.currentUser;
  const creds = firebase.app.auth.EmailAuthProvider.credential(
    user.email,
    currentPassword,
  );
  if (newPassword !== confirmPassword) {
    return callback(false, { error: { message: 'Confirmation password does not match with the new password' } });
  }
  user.reauthenticateWithCredential(creds).then(() => {
    user.updatePassword(newPassword).then(() => {
      callback(true);
    }).catch((error) => {
      callback(false, { error });
    });
  }).catch((error) => {
    callback(false, { error });
  });
}

export {
  doUpdatePassword,
  doUpdateEmail as default,
};
