/* eslint no-console: ["error", { allow: ["log"] }] */
/* eslint newline-per-chained-call: ["error", { "ignoreChainWithDepth": 5 }] */
import { firebase } from 'lib/Firebase';
import {
  fetchRequest,
  receiveRequest,
  addPosts,
} from 'actions/posts';
import { SET_POSTS_ANALYTICS, RESET_POSTS_ANALYTICS } from 'reducers/ui/postsAnalytics';
import { getInfluencersFromPosts } from 'actions/ui/postList';

export const setPostsAnalytics = posts => ({
  type: SET_POSTS_ANALYTICS,
  posts,
});

export const resetPostsAnalytics = () => ({
  type: RESET_POSTS_ANALYTICS,
});

export const getPerCampaignPosts = campaignId => (dispatch) => {
  dispatch(fetchRequest());
  dispatch(resetPostsAnalytics());

  return firebase.posts().orderByChild('productUID').equalTo(campaignId).once('value')
    .then(snap => (snap.exists() ? snap.val() : {}))
    .then(dispatch(getInfluencersFromPosts))
    .then((posts) => {
      dispatch(addPosts(posts));
      dispatch(setPostsAnalytics(posts));
      dispatch(receiveRequest());
      return posts;
    });
};
