import React from 'react';
import _ from 'lodash';
import CircularParticle from './CircularParticle';
import SquiggleParticle from './SquiggleParticle';
import { generateWholeNumber } from './index';

const Particles = ({ count }) => {
  const types = [SquiggleParticle, CircularParticle, CircularParticle];

  const particles = _.times(count, i => {
    const Particle = types[generateWholeNumber(0, 2)];
    return <Particle key={`particle-${i}`} />;
  });

  return <div className="confetti-particles">{particles}</div>;
};

export default Particles;
