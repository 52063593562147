import { combineReducers } from 'redux';
import campaignList from './campaignList';
import modeView from './creativeAssets';
import postList from './postList';
import influencerList from './influencerList';
import postsAnalytics from './postsAnalytics';
import sideNav from './sideNav';
import recentPosts from './recentPosts';
import campaignSummary from './campaignSummary';
import applicationsAction from './applicationsAction';
import applicationsFilter from './applicationsFilter';
import shippingFormModal from './shippingFormModal';
import campaignHistory from './campaignHistory';
import campaignDashboard from './campaignDashboard';
import campaignPosts from './campaignPosts';
import storeAnalytics from './storeAnalytics';
import postGraph from './postGraph';
import overview from './overview';
import adminBrandsPage from './adminBrandsPage';
import applicantList from './applicantList';
import admin from './admin';

export default combineReducers({
  applicantList,
  campaignList,
  modeView,
  postList,
  influencerList,
  postsAnalytics,
  sideNav,
  recentPosts,
  campaignSummary,
  campaignHistory,
  applicationsAction,
  applicationsFilter,
  shippingFormModal,
  campaignDashboard,
  campaignPosts,
  storeAnalytics,
  postGraph,
  overview,
  adminBrandsPage,
  admin
});
