import React, { Component } from 'react';
import { Modal, Form, Input, Select, Button, Row, Col, Icon, message, Checkbox } from 'antd';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import {
  requestDeliveryDetails,
  hideShippingFormModal,
  updateShippingDetails,
  resetShippingDetails
} from 'actions/ui/shippingFormModal';
import { POST_STATUS } from 'constants/posts';
import ShippingHistory from './ShippingHistory';

import { firebase } from '../../../lib/Firebase';

const { Item } = Form;
const { Option } = Select;

const carrierCodes = {
  usps: 'USPS',
  stamps_com: 'Stamps.com',
  ups: 'UPS',
  fedex: 'FedEx',
  canada_post: 'Canada Post',
  dhl_express: 'DHL'
};

const carrierOptions = () =>
  Object.entries(carrierCodes).map(([id, value]) => (
    <Option key={id} value={id}>
      {value}
    </Option>
  ));

class ShippingFormModal extends Component {
  state = {
    showForm: false,
    nonShippable: false
  };

  componentWillReceiveProps({ submitting: nextSubmitting, influencerId, productId }) {
    const {
      influencers,
      form,
      requestDeliveryDetails: request,
      influencerId: previousInfluencer,
      resetShippingDetails: reset
    } = this.props;

    if (influencerId !== previousInfluencer) {
      console.log(influencerId);
      const influencer = influencers.byId[influencerId];
      const product = influencer.partnerships.products[productId];
      const shippingDetails = {
        carrierCode: product.carrierCode,
        trackingNumber: product.trackingNumber
      };

      form.setFieldsValue(shippingDetails);

      let newState = {};
      if (product.carrierCode && product.trackingNumber) {
        request(product.carrierCode, product.trackingNumber);
        newState = { showForm: false, nonShippable: false };
      } else {
        reset();
        newState = { nonShippable: false, showForm: true };
        if (product.status !== POST_STATUS.needsShipping) {
          newState.nonShippable = true;
        }
      }

      this.setState(newState);
    }

    const { submitting } = this.props;
    if (submitting && !nextSubmitting) {
      message.success('Shipping Details Updated');
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const {
      influencerId,
      productId,
      updateShippingDetails: update,
      requestDeliveryDetails: request,
      resetShippingDetails: reset,
      form: { validateFieldsAndScroll },
      form
    } = this.props;

    const { nonShippable } = this.state;

    validateFieldsAndScroll((err, params) => {
      if (!err || nonShippable) {
        let trackingNumber = params.trackingNumber || '';
        trackingNumber = trackingNumber.replace(/\s/g, '');
        const parameters = {
          ...params,
          trackingNumber
        };

        update(influencerId, productId, parameters).then(() => {
          if (nonShippable) {
            form.resetFields();
            reset();
          } else {
            request(params.carrierCode, params.trackingNumber);
            form.setFieldsValue({ trackingNumber });
            this.setState({ showForm: false });
          }
          this.close();
        });
      }
    });
  };

  handleCheckboxChange = () => {
    this.setState(state => ({ nonShippable: !state.nonShippable }));
  };

  toggleForm = () => {
    this.setState(prevState => ({ showForm: !prevState.showForm }));
  };

  close = () => {
    const { hideShippingFormModal: hideModal } = this.props;
    hideModal();
  };

  form() {
    const {
      form: { getFieldDecorator }
    } = this.props;
    const { showForm, nonShippable } = this.state;

    return (
      <Form style={{ display: showForm ? 'block' : 'none' }}>
        <Item
          style={{
            background: '#ddd',
            paddingLeft: '20px',
            borderRadius: '3px'
          }}
        >
          {getFieldDecorator('nonShippable')(
            <Checkbox checked={nonShippable} onChange={this.handleCheckboxChange}>
              Product does not need shipping (promo/coupon code).
            </Checkbox>
          )}
        </Item>
        <p>
          If you are shipping this product, add the tracking information below for best results.
          This will let influencers know when to expect their package and will help them plan their
          post date.
        </p>
        <div style={{ display: nonShippable ? 'none' : 'block' }}>
          <Item label="Carrier Code">
            {getFieldDecorator('carrierCode', {
              rules: [
                {
                  required: !nonShippable,
                  message: 'Please input Shipping Carrier'
                }
              ]
            })(
              <Select disabled={nonShippable} placeholder="Shipping Carrier">
                {carrierOptions()}
              </Select>
            )}
          </Item>
          <Item label="Tracking Number">
            {getFieldDecorator('trackingNumber', {
              rules: [
                {
                  required: !nonShippable,
                  message: 'Please input Tracking Number'
                }
              ]
            })(<Input disabled={nonShippable} placeholder="0000000" />)}
          </Item>
        </div>
      </Form>
    );
  }

  details() {
    const { influencers, influencerId } = this.props;
    const influencer = influencers.byId[influencerId];
    if (!influencer) return '';
    const { details } = influencer;

    const { deliveryDetails, productId } = this.props;

    const loading = (
      <div style={{ textAlign: 'center', fontSize: '16pt' }}>
        <Icon type="loading" />
      </div>
    );

    if (deliveryDetails.actualDeliveryDate) {
      firebase.updateProductActiveByDeliveryDate({
        influencerUID: influencerId,
        productUID: productId,
        deliveryDate: deliveryDetails.actualDeliveryDate
      });
    }

    const shipDate = deliveryDetails.shipDate || 'N/A';
    const deliveryDate =
      deliveryDetails.actualDeliveryDate || deliveryDetails.estimatedDeliveryDate || 'N/A';
    const { showForm } = this.state;
    return (
      <div style={{ display: showForm ? 'none' : 'block' }}>
        <div style={{ textAlign: 'center' }}>
          {`${details.address} ${details.secondAddress}, ${details.city}`}
          <br />
          {`${details.state}, ${details.zip}`}
        </div>
        {deliveryDetails.fetching ? (
          loading
        ) : (
          <div>
            <Row style={{ marginTop: '20px' }}>
              <Col span={12} style={{ textAlign: 'center' }}>
                <strong>Date Shipped</strong>
                <br />
                <span>
                  {shipDate !== 'N/A' ? moment(shipDate).format('ddd, MMM D YYYY') : shipDate}
                </span>
              </Col>
              <Col span={12} style={{ textAlign: 'center' }}>
                <strong>
                  {deliveryDetails.actualDeliveryDate ? 'Delivered On' : 'Expected Delivery'}
                </strong>
                <br />
                <span>
                  {deliveryDate !== 'N/A'
                    ? moment(deliveryDate).format('ddd, MMM D YYYY')
                    : deliveryDate}
                </span>
              </Col>
            </Row>
            <ShippingHistory events={deliveryDetails.events} />
          </div>
        )}
      </div>
    );
  }

  render() {
    const { show, hideShippingFormModal: hideModal, submitting } = this.props;
    const { showForm } = this.state;
    return (
      <Modal
        title="Shipping Details"
        visible={show}
        onOk={this.handleSubmit}
        onCancel={() => hideModal()}
        footer={[
          showForm ? (
            <Button key="submit" type="primary" loading={submitting} onClick={this.handleSubmit}>
              Update
            </Button>
          ) : (
            <Button key="close" onClick={this.close}>
              Close
            </Button>
          )
        ]}
      >
        <div style={{ textAlign: 'center' }}>
          <Button style={{ marginBottom: '20px' }} onClick={() => this.toggleForm()}>
            <Icon type={showForm ? 'solution' : 'setting'} />
            {showForm ? 'Show Tracking Details' : 'Edit Tracking Number'}
          </Button>
        </div>
        {this.form()}
        {this.details()}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  show: state.ui.shippingFormModal.show,
  productId: state.ui.shippingFormModal.productId,
  submitting: state.ui.shippingFormModal.submitting,
  influencerId: state.ui.shippingFormModal.influencerId,
  influencers: state.entities.influencers,
  deliveryDetails: state.ui.shippingFormModal.delivery
});

const mapDispatchToProps = dispatch => ({
  hideShippingFormModal: () => dispatch(hideShippingFormModal()),
  updateShippingDetails: (influencerId, productId, parameters) =>
    dispatch(updateShippingDetails(influencerId, productId, parameters)),
  requestDeliveryDetails: (carrierCode, trackingNumber) =>
    dispatch(requestDeliveryDetails(carrierCode, trackingNumber)),
  resetShippingDetails: () => dispatch(resetShippingDetails())
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  Form.create({ name: 'shippingForm' })
)(ShippingFormModal);
