/* eslint-disable import/prefer-default-export */
export const AC_FIELD_IDS = {
  Website: 37,
  'Campaign Title for Current Campaign': 36,
  'Number of Applications for Current Campaign': 35,
  'UTM Subscription Medium': 24,
  'UTM Subscription Source': 25,
  'UTM Subscription Campaign': 26,
  'UTM Subscription Content': 27,
  'UTM Subscription Term': 28
};
