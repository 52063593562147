/* eslint import/prefer-default-export: 0 */
import { firebase } from '../lib/Firebase';

class BusinessForUpdateListener {
  constructor() {
    this.subscriptions = [];
  }

  addListener(subs) {
    this.subscriptions.push(subs);
  }
}

export const businessForUpdateListener = async (
  lastBusinessDoc = null,
  limit = 250,
  onlyApprovedAndRejected = false,
  lastApprovedAndRejectedDoc = null,
  callback
) => {
  let business = [];
  let lastDocBusiness = null;
  let lastDocApprovedRejected = null;
  let approvedAndRejected = onlyApprovedAndRejected;
  let pendingLength = 0;

  const refQuery = () => {
    const query = firebase.firestore
      .collection('brands')
      .where('status', '==', 'pending')
      .orderBy('createdAtUnix', 'desc');

    return query;
  };

  const iterateBusiness = businessesDocs => {
    businessesDocs.forEach(businessSnap => {
      const businessData = businessSnap.data();

      const appendData = () => {
        business = [...business, { uid: businessSnap.id, ...businessData }];
        lastDocBusiness = businessSnap;
      };

      appendData();
    });
  };

  if (!onlyApprovedAndRejected) {
    // pending
    let firstQuery = refQuery();

    if (lastBusinessDoc) {
      firstQuery = firstQuery.startAfter(lastBusinessDoc);
    }

    const businessesSnap = await firstQuery.limit(limit).get();
    pendingLength = businessesSnap.docs.length;
    iterateBusiness(businessesSnap.docs);
  }

  if (pendingLength < limit) {
    const newLimit = limit - pendingLength;
    // get approved and rejected
    let approvedRejectQuery = firebase.firestore
      .collection('brands')
      .where('status', 'in', ['approved', 'rejected'])
      .where('firstCampaign', '==', true)
      .orderBy('createdAtUnix', 'desc');

    if (lastApprovedAndRejectedDoc) {
      approvedRejectQuery = approvedRejectQuery.startAfter(lastApprovedAndRejectedDoc);
    }

    const businessesSnapApprovedReject = await approvedRejectQuery.limit(newLimit).get();
    businessesSnapApprovedReject.docs.forEach(businessSnap => {
      const businessData = businessSnap.data();

      const appendData = () => {
        business = [...business, { uid: businessSnap.id, ...businessData }];
        lastDocApprovedRejected = businessSnap;
      };

      appendData();
    });
    approvedAndRejected = true;
  }

  callback(business, lastDocBusiness, approvedAndRejected, lastDocApprovedRejected);
};

export const businessForUpdateSubs = new BusinessForUpdateListener();
