import React from 'react';
import { Route } from 'react-router-dom';

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const renderComponent = props => (
    <Layout>
      <Component {...props} />
    </Layout>
  );

  return <Route {...rest} render={renderComponent} />;
};

export default AppRoute;
