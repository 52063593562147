/* eslint class-methods-use-this: ["error", { "exceptMethods": ["stringPresence"] }] */
class InfluencerWrapper {
  constructor(body) {
    Object.keys(body).forEach(key => {
      this[key] = body[key];
    });

    this.details = {
      ...this.details,
      ...this.extedDetails()
    };
  }

  extedDetails() {
    return {
      parsedState: this.parsedState(),
      parsedAvatar: this.parsedAvatar(),
      parsedZip: this.parsedZip(),
      parsedCity: this.parsedCity(),
      completeAddress: this.completeAddress()
    };
  }

  parsedState() {
    return this.stringPresence(this.details.state);
  }

  parsedAvatar() {
    const avatar = this.details ? this.details.image : '';
    return avatar;
  }

  parsedCity() {
    return this.stringPresence(this.details.city);
  }

  parsedZip() {
    return this.stringPresence(this.details.zip);
  }

  completeAddress() {
    const address = this.stringPresence(this.details.address);

    return `${address} ${
      this.details.secondAddress
    } ${this.parsedCity()}, ${this.parsedState()} ${this.parsedZip()}`;
  }

  stringPresence(value) {
    const validString = value && value.toLowerCase() !== 'error' ? value : '';
    return validString;
  }
}

export default InfluencerWrapper;
