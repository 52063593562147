import React from 'react';
import { Row } from 'antd';

const PrivacyPolicy = () => (
  <Row type="flex" justify="space-around">
    <div className="min-layout-content" style={{ textAlign: 'justify' }}>
      <h1 className="white mb-2 text-white" style={{ textAlign: 'center', marginBottom: '40px !important' }}>
        ONLINE PRIVACY POLICY AGREEMENT
      </h1>
      <p className="type-light" style={{ color: '#a6a6a6' }}>
        Trend.io, (Trend), is committed to keeping any and all personal information
        collected of those individuals that visit our website and make use of our
        online facilities and services accurate, confidential, secure and private.
        Our privacy policy has been designed and created to ensure those affiliated
        with Trend.io of our commitment and realization of our obligation not only to
        meet but to exceed most existing privacy standards.
      </p>

      <p className="type-light" style={{ color: '#a6a6a6' }}>
        <span className="mb-2" style={{ fontFamily: 'TruenoBold', fontStyle: 'italic' }}>THEREFORE,</span>
        &nbsp;this Privacy Policy Agreement shall apply to Trend.io, and thus it shall
        govern any and all data collection and usage thereof. Through the use of&nbsp;
        <a href="//www.trend.io" target="_blank" rel="noopener noreferrer">www.trend.io</a>
        &nbsp;you are herein consenting to the following data procedures expressed
        within this agreement.
      </p>

      <div style={{ marginLeft: '30px' }}>
        <h3 className="white mb-2 text-white">
          Collection of Information
        </h3>

        <p className="type-light" style={{ color: '#a6a6a6' }}>
          This website collects various types of information, such as:
        </p>

        <ul className="type-light" style={{ color: '#a6a6a6' }}>
          <li>
            Voluntarily provided information which may include your name, address,
            email address, billing and/or credit card information etc., which may be
            used when you purchase products and/or services and to deliver the
            services you have requested.
          </li>
          <li>
            Information automatically collected when visiting our website, which may
            include cookies, third party tracking technologies and server logs.
          </li>
        </ul>

        <p className="type-light" style={{ color: '#a6a6a6' }}>
          Please rest assured that this site shall only collect personal information
          that you knowingly and willingly provide by way of surveys, completed
          membership forms, and emails. It is the intent of this site to use personal
          information only for the purpose for which it was requested and any additional
          uses specifically provided on this site.
        </p>

        <p className="type-light" style={{ color: '#a6a6a6' }}>
          Trend.io may have the occasion to collect anonymous demographic information,
          such as age, gender, household income, political affiliation, race and religion
          at a later time.We may also gather information about the type of browser you
          are using, IP address or type of operating system to assist us in providing
          and maintaining superior quality service.
        </p>

        <p className="type-light" style={{ color: '#a6a6a6' }}>
          It is highly recommended and suggested that you review the privacy policies
          and statements of any website you choose to use or frequent as a means to
          better understand the way in which other websites garner, make use of and
          share information collected.
        </p>

        <h3 className="white mb-2 text-white">
          Use of Information Collected
        </h3>

        <p className="type-light" style={{ color: '#a6a6a6' }}>
          Trend.io may collect and may make use of personal information to assist
          in the operation of our website and to ensure delivery of the services
          you need and request. At times, we may find it necessary to use personally
          identifiable information as a means to keep you informed of other possible
          products and/or services that may be available to you from www.trend.io.
          Trend.io may also be in contact with you with regards to completing surveys
          and/or research questionnaires related to your opinion of current or potential
          future services that may be offered.
        </p>

        <p className="type-light" style={{ color: '#a6a6a6' }}>
          Trend.io does not now, nor will it in the future, sell, rent or lease any
          of our customer lists and/or names to any third parties.
        </p>

        <p className="type-light" style={{ color: '#a6a6a6' }}>
          Trend.io may deem it necessary to follow websites and/or pages that our
          users may frequent in an effort to gleam what types of services and/or
          products may be the most popular to customers or the general public.
        </p>

        <p className="type-light" style={{ color: '#a6a6a6' }}>
          Trend.io may disclose your personal information, without prior notice to
          you, only if required to do so in accordance with applicable laws and/or
          in a good faith belief that such action is deemed necessary or is required
          in an effort to:
        </p>

        <ul className="type-light" style={{ color: '#a6a6a6' }}>
          <li>
            Remain in conformance with any decrees, laws and/or statutes or in an
            effort to comply with any process which may be served upon Trend.io
            and/or our website;
          </li>
          <li>
          Maintain, safeguard and/or preserve all the rights and/or property of Trend.io; and
          </li>
          <li>
            Perform under demanding conditions in an effort to safeguard the personal
            safety of users of www.trend.io and/or the general public.
          </li>
        </ul>

        <h3 className="white mb-2 text-white">
          Children Under Age of 13
        </h3>

        <p className="type-light" style={{ color: '#a6a6a6' }}>
          Trend.io does not knowingly collect personal identifiable information from
          children under the age of thirteen (13) without verifiable parental consent.
          If it is determined that such information has been inadvertently collected
          on anyone under the age of thirteen (13), we shall immediately take the
          necessary steps to ensure that such information is deleted from our system&apos;s
          database. Anyone under the age of thirteen (13) must seek and obtain parent
          or guardian permission to use this website.
        </p>

        <h3 className="white mb-2 text-white">
          Unsubscribe or Opt-Out
        </h3>

        <p className="type-light" style={{ color: '#a6a6a6' }}>
          All users and/or visitors to our website have the option to discontinue receiving
          communication from us and/or reserve the right to discontinue receiving
          communications by way of email or newsletters. To discontinue or unsubscribe
          to our website please send an email that you wish to unsubscribe to Support@trend.io.
          If you wish to unsubscribe or opt-out from any third party websites, you must go to
          that specific website to unsubscribe and/or opt-out.
        </p>

        <h3 className="white mb-2 text-white">
          Links to Other Web Sites
        </h3>

        <p className="type-light" style={{ color: '#a6a6a6' }}>
          Our website does contain links to affiliate and other websites. Trend.io does
          not claim nor accept responsibility for any privacy policies, practices and/or
          procedures of other such websites. Therefore, we encourage all users and visitors
          to be aware when they leave our website and to read the privacy statements of
          each and every website that collects personally identifiable information. The
          aforementioned Privacy Policy Agreement applies only and solely to the
          information collected by our website.
        </p>

        <h3 className="white mb-2 text-white">
          Security
        </h3>

        <p className="type-light" style={{ color: '#a6a6a6' }}>
          Trend.io shall endeavor and shall take every precaution to maintain adequate
          physical, procedural and technical security with respect to our offices and
          information storage facilities so as to prevent any loss, misuse, unauthorized
          access, disclosure or modification of the user&apos;s personal information
          under our control.
        </p>

        <p className="type-light" style={{ color: '#a6a6a6' }}>
          The company also uses Secure Socket Layer (SSL) for authentication and private
          communications in an effort to build users&apos; trust and confidence in the
          internet and website use by providing simple and secure access and communication
          of credit card and personal information.
        </p>

        <h3 className="white mb-2 text-white">
          Changes to Privacy Policy Agreement
        </h3>

        <p className="type-light" style={{ color: '#a6a6a6' }}>
          Trend.io reserves the right to update and/or change the terms of our privacy policy,
          and as such we will post those changes to our website homepage at www.trend.io,
          so that our users and/or visitors are always aware of the type of information we
          collect, how it will be used, and under what circumstances, if any, we may disclose
          such information. If at any point in time Trend.io decides to make use of any
          personally identifiable information on file, in a manner vastly different from
          that which was stated when this information was initially collected, the user or
          users shall be promptly notified by email. Users at that time shall have the option
          as to whether or not to permit the use of their information in this separate manner.
        </p>

        <h3 className="white mb-2 text-white">
          Acceptance of Terms
        </h3>

        <p className="type-light" style={{ color: '#a6a6a6' }}>
          Through the use of this website, you are hereby accepting the terms and conditions
          stipulated within the aforementioned Privacy Policy Agreement. If you are not
          in agreement with our terms and conditions, then you should refrain from further
          use of our sites. In addition, your continued use of our website following the
          posting of any updates or changes to our terms and conditions shall mean that you
          are in agreement and acceptance of such changes.
        </p>

        <h3 className="white mb-2 text-white">
          How to Contact Us
        </h3>

        <p className="type-light" style={{ color: '#a6a6a6' }}>
          If you have any questions or concerns regarding the Privacy Policy Agreement
          related to our website, please feel free to contact us at the following email,
          telephone number or mailing address.
        </p>

        <p className="type-light" style={{ color: '#a6a6a6' }}>
          <span
            className="white mb-2 text-white"
            style={{
              fontSize: '14px',
              fontFamily: 'TruenoBold',
            }}
          >
            Email:
          </span>
          &nbsp;support@trend.io
        </p>

        <p className="type-light" style={{ color: '#a6a6a6' }}>
          <span className="white mb-2 text-white" style={{ fontFamily: 'TruenoBold', fontSize: '14px' }}>
              Telephone Number:
          </span>
          &nbsp;(512) 790-6764
        </p>

        <p className="white text-white" style={{ fontFamily: 'TruenoBold', fontSize: '14px' }}>
          Mailing Address:
        </p>

        <p className="type-light" style={{ color: '#a6a6a6', marginLeft: '25px' }}>
          Trend
          <br />
          701 Brazos St
          <br />
          Austin, Texas 78701
        </p>
      </div>
    </div>
  </Row>
);

export default PrivacyPolicy;
