import React from 'react';
import { Row, Col, Spin, Layout } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import CustomEmpty from 'components/Common/CustomEmpty';
import { usePostTypeFilter } from './PostTypeFilter';
import PostCard from './PostCard';

const { Content } = Layout;

const GridLayout = props => {
  const types = usePostTypeFilter();
  const { posts, influencers, postIds, isFetching } = props;

  const filteredPost = postIds.filter(postUID => types.includes(posts[postUID].type));
  const grids = filteredPost.map(postUID => {
    const { influencerUID, ...post } = posts[postUID];

    const influencerDetails = influencers[influencerUID] && influencers[influencerUID].details;

    return (
      <Col md={8} sm={12} xs={24} key={postUID} style={{ marginBottom: '10px' }}>
        <div>
          <PostCard
            influencerUID={influencerUID}
            postUID={postUID}
            post={post}
            influencer={influencerDetails || {}}
          />
        </div>
      </Col>
    );
  });

  return (
    <Content id="campaign-posts-list" style={{ background: '#fff', minHeight: 600 }}>
      <Row type="flex" justify="space-around">
        <Col span={24}>
          {isFetching ? (
            <div style={{ textAlign: 'center' }}>
              <Spin style={{ marginTop: '10px' }} />
            </div>
          ) : (
            <Row gutter={16}>{grids}</Row>
          )}
          {!isFetching && grids.length === 0 && (
            <CustomEmpty
              style={{ marginTop: '10px', textAlign: 'center' }}
              description="No post yet."
            />
          )}
        </Col>
      </Row>
    </Content>
  );
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { campaignId }
    }
  }
) => ({
  posts: state.entities.posts.byId,
  postIds: state.ui.campaignPosts[campaignId],
  influencers: state.entities.influencers.byId,
  isFetching: state.entities.posts.isFetching
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(GridLayout);
