import React from 'react';
import { connect } from 'react-redux';

const PostCardImage = ({ post, withLabel = true }) => {
  const { type = 'image' } = post;
  const { standardResolutionImage, fullResImage } = post;
  let postImg = standardResolutionImage;

  if (!standardResolutionImage || standardResolutionImage === 'none') {
    postImg = fullResImage;
  }

  if (type === 'story') {
    postImg = post.thumbnail;
  }

  return (
    <div className="post-card-image">
      {withLabel && (
        <div className="post-type-wrapper">
          <div className={`post-type-label type-${type}`}>{type} Post</div>
        </div>
      )}
      <a href={post.link} target="_blank" rel="noopener noreferrer">
        <img
          key="image"
          src={postImg}
          alt=""
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </a>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  post: state.entities.posts.byId[ownProps.postUID]
});

export default connect(mapStateToProps)(PostCardImage);
