import axios from 'axios';
import Routes from 'api/routes';
import UserToken from 'lib/Firebase/userToken';
import { firebase } from 'lib/Firebase';

export const addCredits = async (brandUID, standardCredits, vipCredits, onComplete = null) => {
  const data = { brandUID, standardCredits, vipCredits };
  const tokenData = await UserToken();

  axios
    .post(Routes.addCredits, data, { headers: tokenData.headers })
    .then(response => {
      onComplete(response);
    })
    .catch(error => {
      onComplete(null, error.response.data);
    });
};

export const fetchBrands = (onComplete = null) => {
  firebase
    .brands()
    .once('value')
    .then(snap => {
      let brands = [];
      if (snap.exists()) {
        brands = snap.val();
      }

      onComplete(brands);
    })
    .catch(err => {
      onComplete(null, err);
    });
};
