import React from 'react';
import { Row, Menu, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import ExampleImage from '../../../images/example-campaign@2x.png';

const ExampleItem = props => {
  const { campaignId, image, title, ...others } = props;

  return (
    <Menu.Item {...others}>
      <Link to="/campaigns/example/summary" className="campaign-link">
        <Row type="flex" align="middle">
          <Avatar src={ExampleImage} shape="square" size={36} className="campaign-avatar" />
          <div className="campaign-item hidden-mobile">Welcome to Trend</div>
        </Row>
      </Link>
    </Menu.Item>
  );
};

export default ExampleItem;
