import React, { useReducer } from 'react';

const InfluencerCampaignContext = React.createContext({});

const reducer = (state, { influencerUID, campaigns }) => {
  if (!influencerUID) return state;
  return {
    ...state,
    [influencerUID]: { ...campaigns, loaded: true }
  };
};

const initialState = {};
const InfluencerCampaignProvider = ({ children }) => {
  const [campaigns, setCampaigns] = useReducer(reducer, initialState);

  return (
    <InfluencerCampaignContext.Provider value={{ campaigns, setCampaigns }}>
      {children}
    </InfluencerCampaignContext.Provider>
  );
};

export { InfluencerCampaignContext, InfluencerCampaignProvider };
