export const RECEIVE_NOTIFICATION = 'RECEIVE_NOTIFICATION';

export const RECEIVE_NOTIFICATIONS = 'RECEIVE_NOTIFICATIONS';

export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';

const initialState = {
  byId: {
    // 1: {
    //   ...atr
    // }
  },
  all: [
    // ids
    // 1,2,3
  ]
};

const addNotifications = (state, notifications) => ({
  ...state,
  byId: {
    ...state.byId,
    ...notifications
  },
  all: [...new Set(state.all.concat(Object.keys(notifications)))]
});

const addNotification = (state, notification) => ({
  ...state,
  byId: {
    ...state.byId,
    ...notification
  },
  all: [...new Set(state.all.concat(Object.keys(notification)))]
});

const updateNotification = (state, notificationId, notification) => ({
  ...state,
  byId: {
    ...state.byId,
    [notificationId]: {
      ...state.byId[notificationId],
      ...notification
    }
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_NOTIFICATION:
      return addNotification(state, action.notification);

    case RECEIVE_NOTIFICATIONS:
      return addNotifications(state, action.notifications);

    case UPDATE_NOTIFICATION:
      return updateNotification(state, action.notificationId, action.notification);

    default:
      return state;
  }
};
