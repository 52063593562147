const { firebase } = require('..');

const getYotPoMedia = async ({ page, source }) => {
  try {
    const func = firebase.callable('getYotPoMedia');
    const response = await func({ page, source });

    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export default getYotPoMedia;
