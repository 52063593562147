import React from 'react';
import { compose } from 'recompose';
import moment from 'moment';
import {
  Row,
  Layout,
  Col,
  Button,
  Avatar,
} from 'antd';
import { withRouter } from 'react-router-dom';
import HeaderText from 'components/Common/HeaderText';
import InfluencerImg1 from 'images/demo/influencer-1.png';
import InfluencerImg2 from 'images/demo/influencer-2.png';
import InfluencerImg3 from 'images/demo/influencer-3.png';
import WithBrand from 'components/Helpers/WithBrand';
import { DATE_TIMEZONE_FORMAT } from 'constants/common';
import MinLayout from 'components/Common/MinLayout';
import { ReactComponent as IconBackMessage } from 'images/icon-back-arrow.svg';
import DemoHeaderNote from './DemoHeaderNote';

const {
  Header,
  Content,
} = Layout;

const influencers = {
  'ft-123456789': {
    name: 'Demo Influencer1',
    username: 'demo-influencer1',
    message: 'Is there any way to check the shipping status? I’m traveling quite frequently the next couple of months and want to make sure I can bring the product for my shoot! Thanks!',
    avatar: InfluencerImg1,
  },
  'ft-987654321': {
    name: 'Demo Influencer2',
    username: 'demo-influencer2',
    message: 'Sounds great! I’m looking forward to collaborating',
    avatar: InfluencerImg2,
  },
  'ft-987654321123': {
    name: 'Demo Influencer3',
    username: 'demo-influencer3',
    message: '',
    avatar: InfluencerImg3,
  },
};

const ChatBoxItem = ({ message }) => (
  message.own ? (
    <Row
      style={{
        backgroundColor: '#ffffff',
        marginTop: 40,
        marginBottom: 40,
      }}
      type="flex"
      justify="space-around"
    >
      <Col align="center" span={4} />
      <Col
        span={14}
        offset={3}
        style={{
          border: '1px solid #eee',
          padding: 25,
          borderRadius: 10,
          backgroundColor: '#f8f9fb',
        }}
      >
        <p
          style={{
            marginBottom: '0',
            fontSize: '12px',
            color: '#000',
            lineHeight: '2.08',
          }}
        >
          {message.text}
        </p>
      </Col>
      <Col align="center" span={2}>
        <Avatar src={message.avatar} size={50} className="mt-1">User</Avatar>
        <p
          style={{
            color: '#5B6572',
            textAlign: 'center',
            lineHeight: '11px',
          }}
          className="mt-2 type-regular size-9px"
        >
          {moment(message.timestamp, DATE_TIMEZONE_FORMAT).fromNow()}
        </p>
      </Col>
    </Row>
  ) : (
    <Row
      style={{
        backgroundColor: '#ffffff',
        marginTop: 40,
        marginBottom: 40,
      }}
      type="flex"
      justify="space-around"
    >
      <Col align="center" span={2}>
        <Avatar src={message.avatar} size={50}>Me</Avatar>
        <p
          style={{
            marginTop: '10',
            fontFamily: 'TruenoRegular',
            fontSize: '9px',
            color: '#5B6572',
            textAlign: 'right',
            letterSpacing: 0,
            lineHeight: '11px',
          }}
        >
          {moment(message.timestamp, DATE_TIMEZONE_FORMAT).fromNow()}
        </p>
      </Col>
      <Col span={14} style={{ border: '1px solid #eee', padding: 25, borderRadius: 10 }}>
        <p
          style={{
            marginBottom: '0',
            fontSize: '12px',
            color: '#000',
            lineHeight: '2.08',
          }}
        >
          {message.text}
        </p>
      </Col>
      <Col align="center" span={4} />
    </Row>
  )
);

const MessageChatBox = ({
  brand,
  match,
  history,
}) => {
  const influencer = influencers[match.params.messageId];

  if (!influencer) {
    history.goBack();
  }

  const dataSource = [
    {
      key: 'x123er',
      own: true,
      text: 'Congratulations! You have been chosen to receive ProductX for BrandX in exchange for a creative post about the product. We\'ll ship the product soon. Please remember to follow the guidelines of the product post and respond here with any questions!',
      timestamp: moment().subtract(1, 'day').format(DATE_TIMEZONE_FORMAT),
      avatar: brand.details.storeImage,
    },
  ];

  if (influencer.message !== '') {
    dataSource.push({
      key: match.params.messageId,
      own: false,
      text: influencer.message,
      timestamp: moment().format(DATE_TIMEZONE_FORMAT),
      avatar: influencer.avatar,
    });
  }

  return (
    <div>
      <Header
        style={{
          backgroundColor: '#fff',
          borderBottom: '1px solid #d6d6d6',
          padding: '30px 0',
          height: '90px',
          position: 'fixed',
          top: '0',
          width: 'calc(100% - 210px)',
          zIndex: 1,
        }}
      >
        <Row type="flex" justify="space-around">
          <MinLayout>
            <HeaderText bold style={{ marginBottom: 48, paddingBottom: 48 }}>
              <Button
                style={{
                  float: 'left',
                  marginRight: 24,
                  paddingRight: 22,
                  color: '#000',
                  boxShadow: 'none',
                  border: '0',
                  borderRight: '1px solid #d6d6d6',
                  borderRadius: '0',
                  fontSize: '12px',
                  textAlign: 'center',
                }}
                onClick={history.goBack}
              >
                <IconBackMessage style={{ display: 'inlineBlock' }} />
                <span style={{ display: 'block' }}>Back</span>
              </Button>
              <div style={{ float: 'left', marginTop: '15px', lineHeight: '0' }}>
                <div style={{ lineHeight: '0' }}>{influencer.name}</div>
              </div>
            </HeaderText>
          </MinLayout>
        </Row>
      </Header>
      <Content style={{ marginTop: 48, marginBottom: 64, paddingTop: 24 }}>
        <Row style={{ marginTop: '18px' }}>
          <DemoHeaderNote />
        </Row>
        <Row type="flex" justify="space-around">
          <MinLayout>
            {
              dataSource.map(message => (
                <ChatBoxItem key={message.key} message={message} />
              ))
            }
          </MinLayout>
        </Row>
      </Content>
    </div>
  );
};

export default compose(
  WithBrand,
  withRouter,
)(MessageChatBox);
