import React from 'react';
import { Form, Button, Switch, Input, Row, Col, InputNumber, Radio, Select } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as routes from 'constants/routes';
import { compose } from 'recompose';
import TrendSelect from '../Form/TrendSelect';
import { createCampaign, editCampaign } from '../../actions/campaigns';
import TagSelect from '../Form/TagSelect';
import ImageUpload from '../Form/ImageUploader';
import ImageUploaderV2 from '../Form/ImageUploaderV2';
import { firebase } from '../../lib/Firebase';
import { withAuthUser } from '../../lib/Session';
import { UpCaseFirstLetter } from '../../lib/Common';
import { YotPoBtn } from '../YotPo';
import YotPoImage from '../../images/yotpo-circle@3x.png';
import { getYotPoProducts } from '../../lib/Firebase/callables';

const { Option } = Select;
const { TextArea } = Input;

const validFields = [
  'name',
  'image',
  'url',
  'value',
  'isOnLikeToKnowIt',
  'isStoryRequired',
  'category',
  'numberOfPosts',
  'hashtags',
  'brandtags',
  'isVIP',
  'guidelines',
  'gender',
  'postTypes',
  'isListed',
  'postGuidelines',
  'styleGuide',
  'yotPoProductID'
];

const cappedAmount = 175;

class CampaignForm extends React.Component {
  state = {
    submitting: false,
    categories: [],
    form: {},
    tempCampaignUID: null,
    products: []
  };

  componentDidMount() {
    const {
      authUser,
      form: { setFieldsValue },
      campaignUID
    } = this.props;
    const { uid: brandUID } = authUser;

    firebase.adminCategories().on('value', snap => {
      const categories = snap.val();

      this.setState({
        categories: Object.keys(categories).map(x => ({
          key: categories[x],
          value: UpCaseFirstLetter(categories[x])
        }))
      });
    });

    if (campaignUID) {
      firebase.activeProduct(campaignUID).once('value', snap => {
        if (snap.exists()) {
          const campaign = snap.val();
          Object.keys(campaign).forEach(field => {
            if (!validFields.includes(field)) {
              delete campaign[field];
            }
          });
          campaign.hashtags = Object.values(campaign.hashtags);
          campaign.brandtags = Object.values(campaign.brandtags);
          this.setState({ form: { ...campaign } });
          setFieldsValue(campaign);
        }
      });
      firebase.completedProduct(campaignUID).once('value', snap => {
        if (snap.exists()) {
          const campaign = snap.val();
          Object.keys(campaign).forEach(field => {
            if (!validFields.includes(field)) {
              delete campaign[field];
            }
          });
          campaign.hashtags = Object.values(campaign.hashtags);
          campaign.brandtags = Object.values(campaign.brandtags);
          this.setState({ form: { ...campaign } });
          setFieldsValue(campaign);
        }
      });
    } else {
      const tempCampaignUID = firebase.activeProducts().push().key;
      this.setState({
        tempCampaignUID
      });
    }

    firebase.firestore
      .collection('brands')
      .doc(brandUID)
      .collection('brandIntegrations')
      .doc('yotpo')
      .get()
      .then(snap => {
        if (snap.exists) {
          getYotPoProducts().then(res => {
            this.setState({ products: res });
          });
        }
      });
  }

  componentWillUnmount() {
    const { authUser } = this.props;
    const { uid: brandUID } = authUser;
    firebase.adminCategories().off();
    firebase.brand(brandUID).off();
  }

  validateMaximumPost = productValue => (rule, val, callback) => {
    if (productValue < 175 && val > 1) {
      callback(false);
    } else {
      callback();
    }
  };

  handleSubmit = () => {
    const { form, authUser, dispatch, history, onFinished, listed = true } = this.props;
    let { campaignUID } = this.props;
    const { uid: brandUID } = authUser;
    const { tempCampaignUID, form: attributes } = this.state;
    const { validateFieldsAndScroll } = form;

    this.setState({ submitting: true });

    validateFieldsAndScroll(async (error, fields) => {
      if (!error) {
        let payload = {
          ...fields,
          isListed: !attributes.isListed ? true : attributes.isListed,
          brandUID,
          states: { All: 'All' }
        };

        if (!listed) {
          payload = {
            ...payload,
            isListed: false
          };
        }

        Object.keys(payload).forEach(keys => {
          if (payload[keys] === undefined) delete payload[keys];
        });

        if (campaignUID) {
          await dispatch(editCampaign(payload, campaignUID, brandUID));
        } else {
          campaignUID = await dispatch(createCampaign(payload, tempCampaignUID, brandUID));
        }

        this.setState({ submitting: false });
        if (onFinished) {
          onFinished(campaignUID);
        } else {
          history.push(routes.toCampaignSummaryPath(campaignUID));
        }
      } else {
        this.setState({ submitting: false });
      }
    });
  };

  albumOptions = () => {
    const { products } = this.state;
    return products.map(product => {
      return (
        <Select.Option key={product.id} value={product.domain_key}>
          {product.name}
        </Select.Option>
      );
    });
  };

  render() {
    const {
      form: { getFieldDecorator, getFieldValue },
      campaignUID,
      session
    } = this.props;
    const { integrations = {} } = session;
    const { yotpo } = integrations;
    const { tempCampaignUID, submitting, categories, form } = this.state;
    const productValue = getFieldValue('value') || 0;
    const numberOfPosts = getFieldValue('numberOfPosts') || 0;

    return (
      <Form
        layout="vertical"
        className="product-form pb-8"
        hideRequiredMark
        onSubmit={this.handleSubmit}
      >
        <Form.Item label="Name your campaign" style={{ marginBottom: '39px' }}>
          {getFieldDecorator('name', {
            rules: [{ required: true, message: 'Add a campaign name' }]
          })(
            <Input
              type="text"
              placeholder="Add a campaign name"
              size="large"
              className="trend-input dark large type-light"
            />
          )}
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          {getFieldDecorator('image', {
            rules: [{ required: true, message: 'Campaign Image is required.' }]
          })(
            <ImageUpload
              filename={campaignUID || tempCampaignUID}
              ref={ref => {
                this.uploaderRef = ref;
              }}
            >
              <div className="label">Add any image of your product</div>
            </ImageUpload>
          )}
        </Form.Item>
        <Row gutter={{ sm: 30 }}>
          <Col sm={12} xs={24}>
            <Form.Item label="Product Website">
              {getFieldDecorator('url', {
                rules: [
                  {
                    transform: urlLink => {
                      if (!/^https?:\/\//i.test(urlLink)) {
                        return `http://${urlLink}`;
                      }

                      return urlLink;
                    }
                  },
                  {
                    required: true,
                    message: 'Please enter website'
                  },
                  {
                    type: 'url',
                    message: 'Please provide a valid website URL.'
                  }
                ]
              })(
                <Input
                  type="text"
                  size="large"
                  className="trend-input dark"
                  placeholder="http://www.website.com"
                />
              )}
            </Form.Item>
          </Col>
          <Col sm={12} xs={24}>
            <Form.Item label="Product value in dollars">
              <div>
                {getFieldDecorator('value', {
                  rules: [
                    {
                      required: true,
                      message: 'Please provide a numeric value.'
                    }
                  ]
                })(
                  <InputNumber
                    className="trend-input dark"
                    placeholder="$50.00"
                    formatter={v => v && `$${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={v => v.replace(/\$\s?|(,*)/g, '')}
                  />
                )}
              </div>
            </Form.Item>
          </Col>
          <Col sm={12} xs={24}>
            <Form.Item
              label={(
                <div className="with-sub-label">
                  <span>Product Category</span>
                  <div className="sub-label">Select One</div>
                </div>
)}
            >
              {getFieldDecorator('category', {
                rules: [{ required: true, message: 'Please choose a category.' }]
              })(<TrendSelect type="dark" options={categories} placeholder="Select category" />)}
            </Form.Item>
          </Col>
          <Col sm={12} xs={24}>
            <Form.Item
              label={(
                <div className="with-sub-label">
                  <span>Product Category</span>
                  <div className="sub-label">Brand must provide over $175 to warrant 2 posts.</div>
                </div>
)}
            >
              <div>
                {getFieldDecorator('numberOfPosts', {
                  rules: [
                    {
                      required: true,
                      message: 'Please choose number of posts.'
                    },
                    {
                      validator: this.validateMaximumPost(productValue),
                      message: 'Only $175 and above can warrant 2 posts'
                    }
                  ]
                })(
                  <TrendSelect
                    type="dark"
                    options={productValue >= cappedAmount ? [1, 2] : [1]}
                    placeholder="Select Number of Posts"
                  />
                )}
              </div>
            </Form.Item>
          </Col>
        </Row>
        <h2 style={{ marginBottom: '45px' }}>
          General Post Guidelines
          <div className="sub-header">
            Give them a general understanding of what you’re looking for and have faith in their
            creative minds to come up with content that connects with their audience. After all,
            there followers follow them for a<br /> reason!
          </div>
        </h2>
        <Form.Item label="Which products will you send the influencers?">
          {getFieldDecorator('postGuidelines[0]', {
            rules: [
              {
                required: true,
                message: 'Please provide guidelines.'
              }
            ]
          })(
            <Input
              className="trend-textarea dark"
              placeholder="Start Typing..."
              style={{ height: '51px' }}
            />
          )}
        </Form.Item>
        <Form.Item label="What would you like the influencers to mention in their caption?">
          {getFieldDecorator('postGuidelines[1]', {
            rules: [
              {
                required: true,
                message: 'Please provide guidelines.'
              }
            ]
          })(<TextArea rows="6" className="trend-textarea dark" placeholder="Start Typing..." />)}
        </Form.Item>
        <Form.Item label="What photo / video style are you looking for?">
          {getFieldDecorator('postGuidelines[2]', {
            rules: [
              {
                required: true,
                message: 'Please provide guidelines.'
              }
            ]
          })(<TextArea rows="6" className="trend-textarea dark" placeholder="Start Typing..." />)}
        </Form.Item>
        <Form.Item
          label={(
            <div className="with-sub-label">
              Style Guide
              <div className="sub-label">
                Add images so the creators know what kind of content to create.
              </div>
            </div>
)}
        >
          {getFieldDecorator('styleGuide')(<ImageUploaderV2 col={6} />)}
        </Form.Item>
        {numberOfPosts > 0 && (
          <Form.Item label="What are you looking for in your first post?">
            {getFieldDecorator('postTypes[0]', {
              initialValue: (form.postTypes && form.postTypes[0]) || 'image',
              rules: [{ required: true, message: 'Please select a post type' }]
            })(
              <Radio.Group buttonStyle="solid" className="trend-radio-buttons">
                <Radio.Button value="image">Image Post</Radio.Button>
                <Radio.Button value="video">Video Post</Radio.Button>
              </Radio.Group>
            )}
          </Form.Item>
        )}
        {numberOfPosts > 1 && (
          <Form.Item label="What are you looking for in your second post?">
            {getFieldDecorator('postTypes[1]', {
              initialValue: (form.postTypes && form.postTypes[1]) || 'image',
              rules: [{ required: true, message: 'Please select a post type' }]
            })(
              <Radio.Group buttonStyle="solid" className="trend-radio-buttons">
                <Radio.Button value="image">Image Post</Radio.Button>
                <Radio.Button value="video">Video Post</Radio.Button>
              </Radio.Group>
            )}
          </Form.Item>
        )}
        <Form.Item label="Instagram Story Required?">
          {getFieldDecorator('isStoryRequired', {
            initialValue: form.isStoryRequired,
            rules: [{ required: true, message: 'Please select from the options' }]
          })(
            <Radio.Group buttonStyle="solid" className="trend-radio-buttons">
              <Radio.Button id="campaign-is_story_required_no" value={false}>
                No
              </Radio.Button>
              <Radio.Button id="campaign-is_story_required_yes" value>
                Yes
              </Radio.Button>
            </Radio.Group>
          )}
        </Form.Item>
        <Form.Item label="Gender">
          {getFieldDecorator('gender')(
            <TrendSelect type="dark" placeholder="Select Gender">
              <Option value={null}>Any</Option>
              <Option value="male">Male</Option>
              <Option value="female">Female</Option>
            </TrendSelect>
          )}
        </Form.Item>
        <h2>
          Brand Tags and Hash Tags
          <div className="sub-header">
            Make Influencers include your brands Instagram handle with their posts. This way
            audience members will have a clear path to visit your page. add required hashtags to
            drive people help your posts be discovered by people searching for specific themes (ex.
            #healthysnack, #feelinggood, #newyearnewyou)
          </div>
        </h2>
        <Form.Item
          label={(
            <div className="with-sub-label">
              Required Instagram Handles
              <div className="sub-label">Press “Enter” after each handle</div>
            </div>
)}
        >
          {getFieldDecorator('brandtags', {
            rules: [
              {
                required: true,
                message: 'Please provide at least one brand tag'
              }
            ]
          })(<TagSelect tagPrefix="@" placeholder="YourHandle" />)}
        </Form.Item>
        <Form.Item
          label={(
            <div className="with-sub-label">
              Required Hashtags (Max 5)
              <div className="sub-label">Press Enter after each tag</div>
            </div>
)}
        >
          {getFieldDecorator('hashtags', {
            rules: [{ required: true, message: 'Please provide at least one hashtag' }]
          })(<TagSelect tagPrefix="#" max={5} placeholder="yourhashtag" />)}
        </Form.Item>
        <Form.Item>
          <Row>
            <Col
              span={18}
              style={{
                paddingRight: 6
              }}
            >
              <h2 style={{ lineHeight: '23px' }}>
                Show this listing to VIP Influencers?
                <br />
                (VIP creators cost 2 campaign credits)
              </h2>
            </Col>
            <Col span={6} style={{ position: 'relative' }}>
              <span
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0
                }}
              >
                {getFieldDecorator('isVIP', {
                  valuePropName: 'checked',
                  initialValue: form.isVIP
                })(<Switch className="show-campaigns-to-vip" />)}
              </span>
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '470px' }}>
              <h2>
                <div className="sub-header" style={{ marginBottom: 0 }}>
                  VIP Influencers are our best of the best influencers. They have a combination of
                  amazing content creation skills, a very engaged audience, and a sizeable
                  following. You can always add more VIP influencer credits to your plan from the
                  dashboard.
                </div>
              </h2>
            </Col>
          </Row>
        </Form.Item>
        <div className="yotpo-field">
          <Row style={{ marginBottom: '27px' }}>
            <Col span="18">
              <label className="d-block type-sbold text-white size-18px">
                Connect to a Yotpo Product ID
              </label>
              <p
                className="mt-2 ht-1 size-14px text-white sub-label"
                style={{
                  lineHeight: '1.71'
                }}
              >
                Enter a yotpo product ID to be able to automatically send your content over to a
                specific Folder in your Yotpo account.
              </p>
            </Col>
            <Col span="6" className="text-right">
              <img src={YotPoImage} alt="YotPo" />
            </Col>
          </Row>
          {!yotpo && (
            <Form.Item>
              <YotPoBtn>CONNECT YOTPO ACCOUNT</YotPoBtn>
            </Form.Item>
          )}
          {yotpo && (
            <Row>
              <Col span={12}>
                <Form.Item label="Product Folder (optional)">
                  {getFieldDecorator('yotPoProductID')(
                    <TrendSelect type="dark" placeholder="Select a Product">
                      {this.albumOptions()}
                    </TrendSelect>
                  )}
                </Form.Item>
              </Col>
            </Row>
          )}
        </div>
        <div align="center">
          <Button
            size="large"
            type="submit"
            className="trend-btn product-btn"
            loading={submitting}
            onClick={this.handleSubmit}
          >
            {campaignUID ? 'Save Campaign Edits' : 'Launch Campaign!'}
          </Button>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  session: state.entities.session.authUserData
});

export default compose(
  withRouter,
  withAuthUser,
  Form.create({ name: 'campaign-form' }),
  connect(mapStateToProps)
)(CampaignForm);
