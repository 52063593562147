import React from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withAuthUser } from 'lib/Session';
import UpperCaseLabel from 'components/Common/UpperCaseLabel';
import { filteredByApproved, sortPosts } from 'selectors/posts';
import PostItem from './PostItem';
import EmptyRecentPosts from './EmptyRecentPosts';
import FreeEmptyRecentPosts from './FreeEmptyRecentPosts';

const RecentPost = ({ postIds, posts, influencers, authUser, brands }) => {
  const { isFreeTrial } = (brands[authUser.uid] || {}).details || {};

  const lists = postIds
    .filter(id => posts.byId[id].type !== 'story')
    .slice(0, 3)
    .map(id => {
      const post = posts.byId[id];
      const influencer = influencers.byId[post.influencerUID];
      const username = influencer && influencer.details && influencer.details.username;
      return <PostItem key={id} post={post} postUID={id} username={username} />;
    });

  return (
    <div id="recent-post">
      <div>
        <Row gutter={0}>
          <Col span={13}>
            <h3 className="size-13px text-black" style={{ marginBottom: '16px' }}>
              Latest Posts
            </h3>
          </Col>
          <Col span={5} className="pt-1 text-right">
            <UpperCaseLabel>COMMENTS</UpperCaseLabel>
          </Col>
          <Col span={6} className="pt-1 text-right">
            <UpperCaseLabel>LIKES</UpperCaseLabel>
          </Col>
        </Row>
        {!isFreeTrial && lists.length < 1 ? <EmptyRecentPosts /> : lists}
        {isFreeTrial && <FreeEmptyRecentPosts />}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  posts: state.entities.posts,
  postIds: sortPosts(
    filteredByApproved(state.ui.campaignPosts[ownProps.campaignId], state.entities.posts),
    state.entities.posts.byId
  ),
  influencers: state.entities.influencers,
  brands: state.entities.brands.byId
});

export default compose(
  withAuthUser,
  connect(mapStateToProps)
)(RecentPost);
