// appliedUID status
// > products/active/../appliedUID/status
const CAMPAIGN_APPLIED_UID_STATUS = {
  submitted: 'submitted',
  approved: 'approved',
  rejected: 'rejected'
};

const CAMPAIGN_STATS = {
  Applications: 'applicantHealth',
  Influencers: 'influencerHealth',
  Shipments: 'shippedHealth',
  'Post Overdue': 'postOverdueHealth',
  Downloads: 'downloadHealth',
  'Last Login': 'lastLoginHealth'
};

export { CAMPAIGN_APPLIED_UID_STATUS as default, CAMPAIGN_APPLIED_UID_STATUS, CAMPAIGN_STATS };
