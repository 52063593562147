import React from 'react';
import { Tag } from 'antd';

const SubscriptionType = ({ isVIP }) => (
  <div style={{ textAlign: 'center' }}>
    {isVIP ? <Tag color="#ebba0e">VIP</Tag> : ''}
  </div>
);

export default SubscriptionType;
