import React from 'react';
import moment from 'moment';
import { message, Row, Col, Input, Form, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { firebase } from '../../lib/Firebase';
import { DATE_TIMEZONE_FORMAT } from '../../constants/common';
import Container from '../../components/Common/Container';
import TrendLogo from '../../images/trend-logo-web.svg';

import { UTMFieldNames } from '../../constants/utmFieldNames';

class SignUpForm extends React.Component {
  state = {
    submitting: false
  };

  handleSubmit = () => {
    const {
      form: { validateFieldsAndScroll },
      onSubmit
    } = this.props;

    this.setState({ submitting: true });
    validateFieldsAndScroll(async (error, values) => {
      if (!error) {
        try {
          await onSubmit(values);
        } catch (e) {
          message.error(e.message);
          this.setState({ submitting: false });
        }
      } else {
        this.setState({ submitting: false });
      }
    });
  };

  checkPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Password inconsistent');
    } else {
      callback();
    }
  };

  render() {
    const { submitting } = this.state;
    const {
      form: { getFieldDecorator }
    } = this.props;

    return (
      <Form hideRequiredMark colon={false}>
        <Row type="flex" justify="center">
          <Col md={12} xs={20}>
            <Form.Item label="Name">
              {getFieldDecorator('name', { rules: [{ required: true, message: 'Required' }] })(
                <Input className="trend-input" placeholder="Your Name" />
              )}
            </Form.Item>
          </Col>
          <Col md={12} xs={20}>
            <Form.Item label="Business Email">
              {getFieldDecorator('email', { rules: [{ required: true, message: 'Required' }] })(
                <Input className="trend-input" placeholder="yourname@email.com" />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col md={12} xs={20}>
            <Form.Item label="Business Name">
              {getFieldDecorator('businessName', {
                rules: [{ required: true, message: 'Required' }]
              })(<Input className="trend-input" placeholder="Your Business" />)}
            </Form.Item>
          </Col>
          <Col md={12} xs={20}>
            <Form.Item label="Business Website">
              {getFieldDecorator('website', {
                rules: [{ required: true, message: 'Required' }]
              })(<Input className="trend-input" placeholder="http://website.com" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col md={12} xs={20}>
            <Form.Item label="Password">
              {getFieldDecorator('password', { rules: [{ required: true, message: 'Required' }] })(
                <Input.Password className="trend-input" placeholder="********" />
              )}
            </Form.Item>
          </Col>
          <Col md={12} xs={20}>
            <Form.Item label="Confirm Password">
              {getFieldDecorator('confirmPassword', {
                rules: [
                  { required: true, message: 'Required' },
                  { validator: this.checkPassword, message: 'Not the same' }
                ]
              })(<Input.Password className="trend-input" placeholder="********" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col md={24} className="text-center">
            <Button type="primary" onClick={this.handleSubmit} loading={submitting}>
              Get Started
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

const WrappedForm = Form.create({ name: 'sign-up-form' })(SignUpForm);

const SignUpV2Page = () => {
  const history = useHistory();

  const UTMParams = async () => {
    const params = queryString.parse(window.location.search);

    const value = [];
    if (Object.keys(params).length) {
      Object.keys(UTMFieldNames).forEach(key => {
        value.push({
          fieldName: UTMFieldNames[key],
          value: params[key]
        });
      });
    }

    return value;
  };

  async function handleSubmit(values) {
    const { email, password, businessName, name, website } = values;
    const auth = await firebase.auth.createUserWithEmailAndPassword(email, password);
    const today = moment();
    await firebase
      .brand(auth.user.uid)
      .child('details')
      .set({
        email,
        name: businessName,
        ownerName: name,
        status: 'pending',
        website,
        isActive: true,
        isDisabled: false,
        isPushNotificationsEnabled: true,
        createdAt: today.format(DATE_TIMEZONE_FORMAT),
        createdAtUnix: today.unix()
      });

    const utmParams = await UTMParams();
    await firebase.createActiveCampaignContact({ email, name, businessName, website, utmParams });

    history.push('/campaigns/example/summary');
  }

  return (
    <Container id="sign-up-v2-page" width={640}>
      <div className="text-center" style={{ marginTop: '38px', marginBottom: '60px' }}>
        <img src={TrendLogo} alt="trend" />
      </div>
      <Row type="flex" justify="center">
        <Col md={24} xs={20} className="header">
          <h1>Apply to Join Trend for Free</h1>
        </Col>
      </Row>

      <WrappedForm onSubmit={handleSubmit} />
    </Container>
  );
};

export default SignUpV2Page;
