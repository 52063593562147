import React from 'react';
import { Input, Tag, Tooltip } from 'antd';

class TagSelect extends React.Component {
  static getDerivedStateFromProps(nextProps) {
    if ('value' in nextProps) {
      return { tags: nextProps.value || [] };
    }
    return null;
  }

  state = {
    tags: [],
    inputValue: '',
    touch: false,
    focus: false
  };

  handleInputChange = e => {
    const { value } = e.target;
    this.setState({ inputValue: e.target.value, touch: value.length > 0 });
  };

  handleInputConfirm = () => {
    const { onChange, max } = this.props;
    let { tags } = this.state;
    const { inputValue } = this.state;
    if (onChange) {
      if (max && tags.length >= max) return;

      if (inputValue) {
        const trimmedTag = inputValue.replace(/\s/g, '');

        if (!!trimmedTag && !tags.includes(trimmedTag)) {
          tags = [...tags, trimmedTag];
        }
      }

      this.setState({
        tags,
        inputValue: '',
        touch: false,
        focus: false
      });

      onChange(tags);
    }
  };

  handleTagClose = tag => {
    const { tags } = this.state;
    const { onChange } = this.props;
    const newTags = tags.filter(x => x !== tag);

    onChange(newTags);
  };

  handleOnFocus = () => {
    this.setState({ focus: true });
  };

  render() {
    const { inputValue = '', tags, touch, focus } = this.state;
    const { tagPrefix = '', placeholder = '' } = this.props;
    const placeholderEl = (
      <span className={touch || focus ? 'ant-input-prefix-touch' : ''}>{tagPrefix}</span>
    );

    return (
      <>
        <Input
          className="trend-input dark brandtag-input"
          value={inputValue}
          onChange={this.handleInputChange}
          onBlur={this.handleInputConfirm}
          onPressEnter={this.handleInputConfirm}
          onFocus={this.handleOnFocus}
          placeholder={placeholder}
          prefix={placeholderEl}
        />
        <div style={{ marginTop: '15px' }} className="trend-selected-tags">
          {Object.entries(tags).map(([index, tag]) => {
            const btag = `${tagPrefix}${tag}`;
            const isLongTag = tag.length > 20;
            const tagElem = (
              <Tag
                key={`${tag}-${index}`}
                className="trend-tag"
                closable
                style={{ marginTop: '3px' }}
                onClose={() => this.handleTagClose(tag)}
              >
                {isLongTag ? `${btag.slice(0, 20)}...` : btag}
              </Tag>
            );

            return isLongTag ? (
              <Tooltip title={btag} key={tag}>
                {tagElem}
              </Tooltip>
            ) : (
              tagElem
            );
          })}
        </div>
      </>
    );
  }
}

export default TagSelect;
