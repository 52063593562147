import React, { Component } from "react";
import { notification, Icon, Row } from "antd";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { withFirebase } from "../../lib/Firebase";
import { DATE_TIMEZONE_FORMAT } from "../../constants/common";
import { AuthUserContext, withAuthorization } from "../../lib/Session";
import SignOutButton from "../Signout";
import { CampaignForm } from "../Campaigns";
import ThinContainer from "../Common/ThinContainer";

const defaultState = {};

class TrialCampaignSetup extends Component {
  state = defaultState;

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;

    const { firebase, authUser } = this.props;

    firebase.brand(authUser.uid).once("value", snapshot => {
      if (this._isMounted) {
        const { details: brandDetails } = snapshot.val();

        this.setState({
          name: brandDetails.name,
          storeImage: brandDetails.storeImage,
          description: brandDetails.description
        });
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

  handleProgress = progress => this.setState({ progress });

  handleUploadError = () => {
    this.setState({ isUploading: false });
  };

  handleUploadSuccess = filename => {
    this.setState({ progress: 100, isUploading: false });

    const { firebase } = this.props;

    firebase.storage
      .ref("brands")
      .child(filename)
      .getDownloadURL()
      .then(url => {
        this.setState({ storeImage: url });
      });
  };

  onSuccess = () => {
    notification.success({
      message: "Company",
      description: "Profile Saved",
      duration: 2
    });
  };

  onChange = e => {
    const { target } = e;
    this.setState({ [target.name]: target.value });
  };

  onSubmit = event => {
    const {
      company_name,
      description,
      storeImage,
      email,
      password
    } = this.state;

    const { firebase, history } = this.props;

    const initBrandData = {
      name: company_name,
      description,
      storeImage,
      logo: storeImage,
      isPushNotificationsEnabled: true,
      isActive: true,
      isAdmin: false,
      isDisabled: false,
      isFreeTrial: true,
      createdAt: moment().format(DATE_TIMEZONE_FORMAT),
      credits: 0,
      standardCredits: 0,
      email
    };

    let userId;
    firebase
      .doCreateUserWithEmailAndPassword(email, password)
      .then(authUser => {
        userId = authUser.user.uid;
        initBrandData.uid = userId;
        firebase.brandInfo(userId).set(initBrandData);
      })
      .then(() => {
        firebase.doSignInWithEmailAndPassword(email, password).then(() => {
          history.push("/products");
        });
      })
      .catch(err => {
        this.setState({ error: err });
      });

    event.preventDefault();
  };

  render() {
    return (
      <div style={{ background: "#000 !important", paddingTop: 30 }}>
        <div className="action-link clickable" align="center">
          <div className="sign-out">
            <AuthUserContext.Consumer>
              {authUser => authUser && <SignOutButton />}
            </AuthUserContext.Consumer>
          </div>
        </div>
        <Row type="flex" justify="space-around">
          <ThinContainer style={{ paddingTop: 30 }}>
            <div className="pt-7">
              <span>
                <div align="center">
                  <div
                    style={{
                      height: 57,
                      width: 353,
                      backgroundColor: "#0B0B0B",
                      borderRadius: 57,
                      padding: "13px 0 13px 0",
                      fontSize: 12,
                      textAlign: "left"
                    }}
                  >
                    <ul
                      style={{
                        color: "#DFDFDF",
                        listStyle: "none",
                        margin: 0,
                        padding: 0
                      }}
                    >
                      <li style={{ width: "50%", float: "left" }}>
                        <div style={{ position: "relative" }}>
                          <span
                            style={{
                              position: "absolute",
                              left: 16,
                              margin: 0,
                              padding: "0",
                              height: 28,
                              backgroundColor: "#2E2E2E",
                              width: 28,
                              color: "#000000",
                              borderRadius: 28,
                              border: "1px solid #2E2E2E"
                            }}
                          >
                            <span
                              style={{
                                position: "absolute",
                                top: 4,
                                left: 7
                              }}
                            >
                              <Icon type="check" />
                            </span>
                          </span>
                          <span
                            style={{
                              position: "relative",
                              top: 4,
                              left: 54,
                              color: "#333333"
                            }}
                          >
                            Company Profile
                          </span>
                        </div>
                      </li>
                      <li style={{ width: "50%", float: "left" }}>
                        <div style={{ position: "relative" }}>
                          <span
                            style={{
                              position: "absolute",
                              left: 16,
                              margin: 0,
                              padding: "0",
                              height: 28,
                              backgroundColor: "#FDAB14",
                              width: 28,
                              borderRadius: 28,
                              border: "1px solid #ffffff"
                            }}
                          >
                            <span
                              style={{
                                position: "absolute",
                                top: 4,
                                left: 9
                              }}
                            >
                              2
                            </span>
                          </span>
                          <span
                            style={{ position: "relative", top: 4, left: 54 }}
                          >
                            Campaign Set up
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="pt-7"
                  align="center"
                  style={{ color: "#DBDBDB" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      marginBottom: 65
                    }}
                  >
                    <h4 style={{ color: "#DBDBDB", fontSize: 18 }}>
                      Let’s set up your first campaign.
                    </h4>
                    <p
                      style={{
                        fontSize: 14,
                        fontWeight: "normal",
                        lineHeight: 1.8
                      }}
                    >
                      Which product are you looking to promote? Each campaign
                      created on Trend will be used to promote a specific
                      product or service. The information here is what the
                      influencers will see and read to decide if they’re
                      interested in a partnership with your brand.
                    </p>
                  </div>
                </div>
              </span>
              <CampaignForm />
            </div>
          </ThinContainer>
        </Row>
      </div>
    );
  }
}
const condition = authUser => !!authUser;

export default compose(
  withRouter,
  withFirebase,
  withAuthorization(condition)
)(TrialCampaignSetup);
