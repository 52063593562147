import _ from 'lodash';

export const SET_POSTS_FEED = 'SET_POSTS_FEED';
export const CLEAR_POSTS_FEED = 'CLEAR_POSTS_FEED';
export const SET_POST_LIST_CATEGORY = 'SET_POST_LIST_CATEGORY';
export const SET_POST_LIST_BRAND = 'SET_POST_LIST_BRAND';
export const SET_POST_LIST_FIRST_ITEM = 'SET_POST_LIST_FIRST_ITEM';

const initialState = {
  approvedPosts: [],
  params: {
    category: '',
    brandUID: null,
  },
  firstItem: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_POST_LIST_FIRST_ITEM:
      return {
        ...state,
        firstItem: action.payload,
      };
    case SET_POST_LIST_BRAND:
      return {
        ...state,
        params: {
          ...state.params,
          brandUID: action.brandUID,
        },
      };
    case SET_POST_LIST_CATEGORY:
      return {
        ...state,
        params: {
          ...state.params,
          category: action.category,
        },
      };
    case SET_POSTS_FEED:
      return {
        ...state,
        approvedPosts: _.uniq([
          ...state.approvedPosts,
          ...action.payload,
        ]),
      };
    case CLEAR_POSTS_FEED:
      return {
        ...state,
        approvedPosts: [],
      };
    default:
      return state;
  }
}
