import React, { Component } from 'react';
import { Modal, notification, Button, Row, Col } from 'antd';
import { connect } from 'react-redux';
import * as routes from 'constants/routes';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import { setOwnedCampaigns } from 'actions/ui/sideNav';
import { checkIfAllApprovedAreCompleted } from 'api/campaigns';
import {
  removeCampaignFromStore,
  addCampaignBackToStore,
  archiveCampaign,
  revertArchiveCampaign
} from 'actions/campaigns';
import MinLayout from 'components/Common/MinLayout';
import { withAuthUser } from 'lib/Session';

const styles = StyleSheet.create({
  container: {
    position: 'fixed',
    background: '#f5f9fe',
    padding: '14px 0px 15px 210px',
    borderTop: '1px solid #dbdfe8',
    width: '100%',
    left: 0,
    '@media (min-width: 990px)': {
      padding: '14px 0px 15px 210px'
    },
    '@media (max-width: 990px)': {
      padding: '14px 0px 15px 80px'
    }
  },
  isFreeTrial: {
    bottom: '65px'
  },
  notFree: {
    bottom: '0px'
  },
  button: {
    marginRight: '3px'
  }
});

class CampaignSettings extends Component {
  state = {
    removeModalVisible: false,
    addModalVisible: false,
    archiveModalVisible: false
  };

  revertArchive = () => {
    const { revertArchiveCampaign: revert, campaignUID, authUser, updateSideNav } = this.props;

    revert(campaignUID, authUser.uid, () => {
      updateSideNav(authUser.uid);

      notification.success({
        message: 'Campaign',
        description: 'Removed Campaign from archive',
        duration: 2
      });
    });
  };

  showRemoveModal = () => {
    this.setState({ removeModalVisible: true });
  };

  showArchiveModal = async () => {
    const { campaignUID } = this.props;
    try {
      await checkIfAllApprovedAreCompleted(campaignUID);
      this.setState({ archiveModalVisible: true });
    } catch (e) {
      console.log(e);
      this.archiveWarningNotice();
    }
  };

  showAddModal = () => {
    this.setState({ addModalVisible: true });
  };

  handleCancel = () => {
    this.setState({
      removeModalVisible: false,
      addModalVisible: false,
      archiveModalVisible: false,
      loadingSubmit: false
    });
  };

  handleRemoveOk = () => {
    const {
      campaignUID,
      authUser,
      updateSideNav,
      removeCampaignFromStore: removeCampaign
    } = this.props;

    this.setState(() => ({ loadingSubmit: true }));
    removeCampaign(campaignUID, () => {
      // Update sidenav MY CAMPAIGNS
      updateSideNav(authUser.uid);
      this.handleCancel();

      notification.success({
        message: 'Campaign',
        description: 'Campaign removed to store.',
        duration: 2
      });
    });
  };

  handleAddOk = () => {
    const { campaignUID, updateSideNav, addCampaignBackToStore: addBack, authUser } = this.props;

    this.setState({ loadingSubmit: true });
    addBack(campaignUID, () => {
      updateSideNav(authUser.uid);
      this.handleCancel();

      notification.success({
        message: 'Campaign',
        description: 'Campaign added back to store.',
        duration: 2
      });
    });
  };

  handleArchiveOk = async () => {
    const { campaignUID, authUser, archiveCampaign: archive, updateSideNav, history } = this.props;
    this.setState({ loadingSubmit: true });
    try {
      await checkIfAllApprovedAreCompleted(campaignUID);
      archive(campaignUID, authUser.uid, () => {
        updateSideNav(authUser.uid);
        history.push(routes.CAMPAIGN_HISTORY_PATH);
      });
    } catch (e) {
      console.log(e);
      this.archiveWarningNotice();
      this.handleCancel();
    }
  };

  archiveWarningNotice = () => {
    const errMsg =
      'All applications must be resolved and all pending posts must be completed before you can archive this campaign. You may remove the product from the store anytime, however, to prevent new influencers from applying.';

    notification.warning({
      message: 'Campaign cannot be archived yet',
      description: errMsg,
      duration: 10
    });
  };

  render() {
    let actionItems = [];
    const { isCompleted, isListed, brandUID, history, campaignUID, authUser, brands } = this.props;
    const brandDetails = (brands[brandUID] || {}).details || {};

    const { status, lastPurchaseDate } = brandDetails;
    const onBoardingCompleted = status === 'approved' && lastPurchaseDate;
    const showMarqueNotif = status === 'approved' && !lastPurchaseDate;

    if (!authUser || authUser.uid !== brandUID) {
      return '';
    }

    if (!status || onBoardingCompleted) {
      if (!isCompleted) {
        if (isListed) {
          actionItems = [
            <Button
              key="remove-listing"
              onClick={this.showRemoveModal}
              className="trend-btn secondary mr-2 type-firme-italic bg-white"
              style={{ borderColor: '#a9b0b9' }}
            >
              Stop&nbsp;
              <span className="hidden-xmobile">Accepting Applications</span>
            </Button>
          ];
        } else {
          actionItems = [
            <Button
              key="add-listing"
              onClick={this.showAddModal}
              className="trend-btn secondary mr-2 type-firme-italic bg-white"
              style={{ borderColor: '#a9b0b9' }}
            >
              Add Campaign Back to Store
            </Button>
          ];
        }

        if (!brandDetails.isFreeTrial) {
          actionItems = [
            ...actionItems,
            <Button
              key="archive-listing"
              onClick={this.showArchiveModal}
              className="trend-btn secondary mr-2 type-firme-italic bg-white"
              style={{ borderColor: '#a9b0b9' }}
            >
              Archive&nbsp;
              <span className="hidden-xmobile">Campaign</span>
            </Button>
          ];
        }
      } else {
        actionItems = [
          ...actionItems,
          <Button
            key="revert"
            onClick={this.revertArchive}
            className="trend-btn secondary mr-2 type-firme-italic bg-white"
            style={{ borderColor: '#a9b0b9' }}
          >
            <span className="hidden-xmobile">Reactivate Campaign</span>
          </Button>
        ];
      }
    }

    const { removeModalVisible, loadingSubmit, addModalVisible, archiveModalVisible } = this.state;
    const bottomStyle = brandDetails.isFreeTrial ? styles.isFreeTrial : styles.notFree;

    return (
      <div
        className={`${css(styles.container, bottomStyle)} ${showMarqueNotif &&
          'with-notification'}`}
      >
        <div className="ant-row-flex ant-row-flex-space-around">
          <MinLayout>
            <Button
              onClick={() => history.push(`/campaigns/${campaignUID}/edit`)}
              className="trend-btn secondary mr-2 type-firme-italic bg-white"
              style={{ borderColor: '#a9b0b9' }}
            >
              Edit&nbsp;
              <span className="hidden-xmobile">Campaign</span>
            </Button>
            <span>
              {actionItems}
              <Modal
                visible={removeModalVisible}
                onCancel={this.handleCancel}
                className="trend-modal"
                closable={false}
                footer={null}
                width={350}
              >
                <div className="label">
                  Are you sure you want to
                  <div className="highlighted">stop accepting applications?</div>
                </div>

                <Row style={{ marginTop: '20px' }} gutter={20} key="reject-modal">
                  <Col span={12}>
                    <Button
                      key="cancel"
                      disabled={loadingSubmit}
                      onClick={this.handleCancel}
                      className="trend-btn medium-btn secondary"
                      block
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      key="submit"
                      loading={loadingSubmit}
                      onClick={this.handleRemoveOk}
                      className="trend-btn medium-btn"
                      block
                    >
                      Yes
                    </Button>
                  </Col>
                </Row>
              </Modal>

              <Modal
                visible={addModalVisible}
                onCancel={this.handleCancel}
                className="trend-modal"
                closable={false}
                footer={null}
                width={350}
              >
                <div className="label">
                  Are you sure you want to
                  <div className="highlighted">add this campaign back to store?</div>
                </div>
                <Row style={{ marginTop: '20px' }} gutter={20} key="reject-modal">
                  <Col span={12}>
                    <Button
                      key="cancel"
                      disabled={loadingSubmit}
                      onClick={this.handleCancel}
                      className="trend-btn medium-btn secondary"
                      block
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      key="submit"
                      loading={loadingSubmit}
                      onClick={this.handleAddOk}
                      className="trend-btn medium-btn"
                      block
                    >
                      Yes
                    </Button>
                  </Col>
                </Row>
              </Modal>

              <Modal
                visible={archiveModalVisible}
                onOk={this.handleArchiveOk}
                onCancel={this.handleCancel}
                className="trend-modal"
                width={350}
                closable={false}
                footer={null}
              >
                <div className="label">
                  Are you sure you want to
                  <div className="highlighted">archive this campaign?</div>
                </div>

                <Row style={{ marginTop: '20px' }} gutter={20} key="reject-modal">
                  <Col span={12}>
                    <Button
                      key="cancel"
                      disabled={loadingSubmit}
                      onClick={this.handleCancel}
                      className="trend-btn medium-btn secondary"
                      block
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      key="submit"
                      loading={loadingSubmit}
                      onClick={this.handleArchiveOk}
                      className="trend-btn medium-btn"
                      block
                    >
                      Yes
                    </Button>
                  </Col>
                </Row>
              </Modal>
            </span>
          </MinLayout>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  brands: state.entities.brands.byId
});

const mapDispatchToProps = dispatch => ({
  removeCampaignFromStore: (id, onComplete) => dispatch(removeCampaignFromStore(id, onComplete)),
  addCampaignBackToStore: (id, onComplete) => dispatch(addCampaignBackToStore(id, onComplete)),
  archiveCampaign: (campaignUID, brandUID, onComplete) =>
    dispatch(archiveCampaign(campaignUID, brandUID, onComplete)),
  updateSideNav: brandUID => dispatch(setOwnedCampaigns(brandUID)),
  revertArchiveCampaign: (campaignUID, brandUID, onComplete) =>
    dispatch(revertArchiveCampaign(campaignUID, brandUID, onComplete))
});

export default compose(
  withAuthUser,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CampaignSettings);
