import axios from 'axios';
import Routes from 'api/routes';
import { UserToken } from 'lib/Firebase';

export default async (campaignUID, email, callback) => {
  const { headers } = await UserToken();
  const data = {
    campaignUID,
    email,
  };

  axios.post(Routes.sendAnalyticsEmail, data, { headers }).then((response) => {
    callback(true, { response });
  }).catch((error) => {
    callback(false, { error });
  });
};
