import React from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import UpperCaseLabel from 'components/Common/UpperCaseLabel';
import { totalPostSummary } from 'selectors/postsAnalytics';
import { approvedInfluencers } from 'selectors/campaigns';
import CurrencyFormat from 'react-currency-format';

const CampaignSummary = ({ postStats, totalInfluencers, campaign = { numberOfPosts: 0 } }) => (
  <div style={{ borderBottom: '1px solid #e8e8e8', paddingBottom: '30px' }}>
    <Row gutter={8} style={{ marginTop: '30px' }}>
      <Col md={5} xs={8} sm={5}>
        <h2 className="stats-heading">
          <CurrencyFormat value={totalInfluencers} displayType="text" thousandSeparator />
        </h2>
        <UpperCaseLabel>Influencers</UpperCaseLabel>
      </Col>
      <Col md={5} xs={8} sm={5}>
        <h2 className="stats-heading">
          <CurrencyFormat value={postStats.posts} displayType="text" thousandSeparator />
          /
          <CurrencyFormat value={campaign.numberOfPosts * totalInfluencers} displayType="text" thousandSeparator />
        </h2>
        <UpperCaseLabel>Live Posts</UpperCaseLabel>
      </Col>
      <Col md={5} xs={8} sm={5}>
        <h2 className="stats-heading">
          <CurrencyFormat value={postStats.likes} displayType="text" thousandSeparator />
        </h2>
        <UpperCaseLabel>Likes</UpperCaseLabel>
      </Col>
      <Col md={5} xs={8} sm={5}>
        <h2 className="stats-heading">
          <CurrencyFormat value={postStats.comments} displayType="text" thousandSeparator />
        </h2>
        <UpperCaseLabel>Comments</UpperCaseLabel>
      </Col>
      <Col md={4} xs={8} sm={4}>
        <h2 className="stats-heading">
          <CurrencyFormat value={postStats.impressions} displayType="text" thousandSeparator />
        </h2>
        <UpperCaseLabel>Impression</UpperCaseLabel>
      </Col>
    </Row>
  </div>
);

const mapStateToProps = (state, props) => ({
  postStats: totalPostSummary(state.entities.posts.byId, state.ui.campaignPosts[props.campaignId]),
  totalInfluencers: approvedInfluencers(state.entities.campaigns.byId[props.campaignId]).length,
  campaign: state.entities.campaigns.byId[props.campaignId],
});

export default connect(mapStateToProps)(CampaignSummary);
