import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Icon } from 'antd';
import Promise from 'bluebird';
import { ReactComponent as ImageIcon } from '../../../images/photographs.svg';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const ImageUploader = props => {
  const { onChange, col, defaultImages, k } = props;
  const [files, setFiles] = useState([]);
  const [fileList, setFileList] = useState([]);

  const handleRemove = index => {
    const updateFiles = [...files.keys()].filter(key => key !== index);
    const tmpFiles = updateFiles.map(key => {
      return files[key];
    });
    setFiles(tmpFiles);
  };

  useEffect(() => {
    onChange(files);
    const tmpFileList = files.map((file, index) => {
      return (
        <Col key={file.url} span={col} className="image" style={{ display: 'inline-block' }}>
          <img src={file.url} alt="style guide" style={{ width: '100px', height: '100px' }} />
          <div className="actions" style={{ width: '100px', marginBottom: '10px' }}>
            <Button className="remove" type="link" onClick={() => handleRemove(index)} block>
              Remove
            </Button>
          </div>
        </Col>
      );
    });

    setFileList(tmpFileList);
  }, [files]);

  const setImages = async () => {
    const tmpFiles = [];
    if (defaultImages[k]) {
      await Promise.map(defaultImages[k], async image => {
        const response = await fetch(image);
        const blob = await response.blob();
        const metadata = {
          type: blob.type
        };
        const file = new File([blob], 'image.png', metadata);
        tmpFiles.push({
          file,
          url: image
        });
      });
      setFiles(tmpFiles);
    }
  };

  useEffect(() => {
    setImages();
  }, [defaultImages]);

  const handleAdd = async e => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      e.target.value = null;
      const newState = [
        ...files,
        {
          file,
          url: await getBase64(file)
        }
      ];
      setFiles(newState);
    }
  };

  return (
    <Row className="image-uploader" gutter={16}>
      {fileList && fileList.map(file => file)}
      <Col span={col}>
        <div className="add">
          <div className="button">
            <Icon component={ImageIcon} style={{ fontSize: '50px' }} />
            <div className="label">Add Image+</div>
          </div>
          <input type="file" onChange={handleAdd} className="upload-files" />
        </div>
      </Col>
    </Row>
  );
};

export default ImageUploader;
