import React, { useEffect, useState } from 'react';
import { Table, Row, Button, Avatar, notification } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import MinLayout from 'components/Common/MinLayout';
import { ReactComponent as IconMessages } from 'images/icon-messages.svg';
import { ReactComponent as IconDownload } from 'images/icon-download.svg';
import CurrencyFormat from 'react-currency-format';
import InfluencerImg1 from 'images/demo/influencer-1.png';
import InfluencerImg2 from 'images/demo/influencer-2.png';
import InfluencerImg3 from 'images/demo/influencer-3.png';
import PostImg1 from 'images/demo/post-1.png';
import PostImg2 from 'images/demo/post-2.png';
import PostImg3 from 'images/demo/post-3.png';
import PostImg4 from 'images/demo/post-4.png';
import { POST_DETAIL_STATUS } from 'constants/posts';
import PostImages from 'lib/Download';
import DemoInfluencerAvatar from './InfluencerAvatar';
import DemoInfluencersBanner from './InfluencersBanner';
import { InfluencerDrawer } from '../Influencers';
import { setInfluencers } from '../../actions/influencers';

const postAction = (post, record) => {
  const messageAction = (
    <Button
      onClick={() => {
        record.history.push(`/demo/messages/${post.influencerUID}`);
      }}
      shape="circle"
      style={{
        background: '#ffb917',
        border: '0',
        marginRight: '5px',
        width: '35px',
        height: '35px'
      }}
    >
      <IconMessages
        style={{
          position: 'relative',
          top: '2px',
          width: '15px',
          height: '14px'
        }}
      />
    </Button>
  );

  const approvedAction = (
    <div>
      {messageAction}
      <Button
        onClick={() =>
          PostImages.downloadImage(post, alert => {
            notification.warning({
              message: 'Image',
              description: alert,
              duration: 2
            });
          })
        }
        style={{
          width: '35px',
          height: '35px',
          backgroundColor: '#fcfcfc',
          border: 'solid 1px #e9e9e9',
          objectFit: 'contain'
        }}
        shape="circle"
      >
        <IconDownload
          style={{
            position: 'relative',
            top: '2px',
            width: '15px',
            height: '15px'
          }}
        />
      </Button>
    </div>
  );

  const pendingAction = (
    <div>
      <Button
        style={{ height: '33px', marginRight: '9px' }}
        className="trend-btn ant-btn type-firme-italic size-10px"
      >
        APPROVE
      </Button>
      <Button
        className="trend-btn ant-btn secondary type-firme-italic bg-white size-10px"
        style={{ backgroundColor: '#fcfcfc', height: '33px' }}
      >
        REJECT
      </Button>
    </div>
  );

  const isAwaiting = ![POST_DETAIL_STATUS.approved, POST_DETAIL_STATUS.submitted].includes(
    post.status
  );

  return (
    <div className="cell-action">
      {post.status === POST_DETAIL_STATUS.approved && approvedAction}
      {post.status === POST_DETAIL_STATUS.submitted && pendingAction}
      {isAwaiting && messageAction}
    </div>
  );
};

const ThousandSeparator = num => {
  if (num) {
    return <CurrencyFormat value={num} displayType="text" thousandSeparator />;
  }

  return '--';
};

const PostAvatar = postAvatar => <Avatar src={postAvatar} size={50} shape="square" />;

const columns = [
  {
    title: 'INFLUENCER',
    dataIndex: 'influencer',
    key: 'influencer',
    className: 'influencer-width',
    render: influencer => <DemoInfluencerAvatar influencer={influencer} />
  },
  {
    title: 'POST',
    dataIndex: 'post',
    key: 'post',
    className: 'post-width pl-0',
    render: PostAvatar
  },
  {
    title: 'COMMENTS',
    dataIndex: 'comments',
    className: 'comments pl-0',
    key: 'comments',
    render: ThousandSeparator
  },
  {
    title: 'LIKES',
    dataIndex: 'likes',
    key: 'likes',
    className: 'likes pl-0',
    render: ThousandSeparator
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    align: 'right',
    className: 'pl-0',
    render: postAction
  }
];

const PostApproval = ({ history }) => {
  const dispatch = useDispatch();
  const [influencerDetailsModal, setInfluencerDetailsModal] = useState({
    show: false,
    influencerUID: null
  });
  const { host, protocol } = window.location;

  const dataSourceNeedsApproval = [
    {
      key: '1-needsApproval',
      influencer: {
        isVIP: false,
        username: 'demo-influencer3',
        avatar: InfluencerImg3,
        state: 'New York, NY'
      },
      history,
      post: PostImg4,
      likes: 1200,
      comments: 3550,
      action: {
        status: 'submitted',
        influencerUID: 'ft-987654321123'
      }
    }
  ];

  const dataSourceAwaiting = [
    {
      key: '1-awaiting',
      influencer: {
        isVIP: false,
        username: 'demo-influencer2',
        avatar: InfluencerImg2,
        state: 'New York, NY'
      },
      history,
      likes: null,
      comments: null,
      action: {
        status: null,
        influencerUID: 'ft-987654321'
      }
    },
    {
      key: '2-needsApproval',
      influencer: {
        isVIP: true,
        username: 'demo-influencer1',
        avatar: InfluencerImg1,
        state: 'New York, NY'
      },
      history,
      likes: null,
      comments: null,
      action: {
        status: null,
        influencerUID: 'ft-123456789'
      }
    }
  ];

  const dataSourceApproved = [
    {
      key: '1-approved',
      influencer: {
        isVIP: true,
        username: 'demo-influencer1',
        avatar: InfluencerImg1,
        state: 'New York, NY'
      },
      history,
      post: PostImg1,
      likes: 4050,
      comments: 4329,
      action: {
        status: 'approved',
        influencerUID: 'ft-123456789',
        code: 'post-123456789',
        fullResImage: `${protocol}//${host}${PostImg1}`
      }
    },
    {
      key: '2-approved',
      influencer: {
        isVIP: false,
        username: 'demo-influencer2',
        avatar: InfluencerImg2,
        state: 'New York, NY'
      },
      history,
      post: PostImg2,
      likes: 350,
      comments: 400,
      action: {
        status: 'approved',
        influencerUID: 'ft-987654321',
        code: 'post-987654321',
        fullResImage: `${protocol}//${host}${PostImg2}`
      }
    },
    {
      key: '3-approved',
      influencer: {
        isVIP: false,
        username: 'demo-influencer3',
        avatar: InfluencerImg3,
        state: 'New York, NY'
      },
      history,
      post: PostImg3,
      likes: 1523,
      comments: 5000,
      action: {
        status: 'approved',
        influencerUID: 'ft-987654321123',
        code: 'post-123456789',
        fullResImage: `${protocol}//${host}${PostImg3}`
      }
    }
  ];

  function handleHideInfluencerDetails() {
    setInfluencerDetailsModal(state => ({ ...state, show: false }));
  }

  const handleShowInfluencerDetails = influencerUID => event => {
    const parentClass = event.target.closest('td').className;
    const tagname = event.target.tagName.toLowerCase();

    if (
      (tagname !== 'td' && event.target.closest('td').className.includes('actions')) ||
      parentClass.includes('post')
    ) {
      return;
    }

    setInfluencerDetailsModal({
      show: true,
      influencerUID
    });
  };

  useEffect(() => {
    [...dataSourceApproved, ...dataSourceAwaiting, ...dataSourceNeedsApproval].forEach(
      (data, index) => {
        const { influencer } = data;
        const lastWeek = moment()
          .subtract(1, 'week')
          .format('YYYY-w');
        const times = index + 1;
        dispatch(
          setInfluencers({
            [data.key]: {
              details: {
                state: influencer.state,
                username: influencer.username,
                image: influencer.avatar,
                isVIP: influencer.isVIP,
                ageRange: data.ageRange
              },
              instagram: {
                avgCommentCount: 1900 * times,
                avgLikeCount: 32700 * times,
                biography: 'Enjoy Your Demo and \nhave a great day',
                followedByCount: 13800 * times,
                stats: {
                  [lastWeek]: {
                    avgCommentCount: 1857 * times,
                    avgLikeCount: 32300 * times
                  }
                }
              }
            }
          })
        );
      }
    );
  }, []);

  return (
    <div>
      <Row type="flex" justify="space-around">
        <MinLayout>
          <div style={{ marginTop: '23px' }}>
            <div style={{ marginBottom: '15px' }}>
              <div className="type-ebold size-13px text-black mb-2">Needs Approval - 1</div>
              <div style={{ fontSize: '10px', color: '#000', lineHeight: '1.8' }}>
                Influencers work hard to create unique work for your brand. Make sure to
                <br />
                only reject a post if they&apos;ve missed something from the post requirements.
              </div>
            </div>
            <Table
              columns={columns}
              dataSource={dataSourceNeedsApproval}
              className="trend-table"
              pagination={false}
              onRow={record => ({
                onClick: handleShowInfluencerDetails(record.key)
              })}
            />
          </div>
          <div style={{ marginTop: '40px' }}>
            <div style={{ marginBottom: '15px' }}>
              <div className="type-ebold size-13px text-black mb-2">Awaiting Post - 2</div>
              <div style={{ fontSize: '10px', color: '#000', lineHeight: '1.8' }}>
                These influencers have yet to post. If you are looking for status on when
                <br />
                they will post, feel free to message them at any point.
              </div>
            </div>
            <Table
              columns={columns}
              dataSource={dataSourceAwaiting}
              className="trend-table table-awaiting"
              pagination={false}
              onRow={record => ({
                onClick: handleShowInfluencerDetails(record.key)
              })}
            />
          </div>
          <div style={{ marginTop: '40px', marginBottom: '44px' }}>
            <div className="type-ebold size-13px text-black mb-2">Approved Posts - 3</div>
            <Table
              columns={columns}
              dataSource={dataSourceApproved}
              className="trend-table table-approved"
              pagination={false}
              onRow={record => ({
                onClick: handleShowInfluencerDetails(record.key)
              })}
            />
          </div>
          <DemoInfluencersBanner />

          <InfluencerDrawer {...influencerDetailsModal} onHide={handleHideInfluencerDetails} />
        </MinLayout>
      </Row>
    </div>
  );
};

export default PostApproval;
