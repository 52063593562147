import React from 'react';
import { Col, Button, Checkbox } from 'antd';
import { ReactComponent as IconCheck } from 'images/icon-accept-checkbox.svg';
import { ReactComponent as IconClose } from 'images/icon-reject-checkbox.svg';

import MinLayout from 'components/Common/MinLayout';

const ListActions = props => {
  const { multipleApprove, multipleReject, cancelMultipleSelect } = props;

  return (
    <MinLayout>
      <Col span={3}>
        <label htmlFor="multipleApprove" className="d-block clickable leading-18px">
          <Button
            id="multipleApprove"
            onClick={() => multipleApprove()}
            shape="circle"
            style={{
              float: 'left',
              width: '20px',
              height: '20px',
              backgroundColor: '#ffb917',
              fontSize: '12px',
              border: '0'
            }}
          >
            <IconCheck style={{ width: '10px', height: '8px' }} />
          </Button>
          <span
            className="size-10px type-firme-iblack spacing-1px text-black"
            style={{ paddingLeft: '8px' }}
          >
            APPROVE
          </span>
        </label>
      </Col>
      <Col span={3}>
        <label htmlFor="multipleReject" className="d-block clickable leading-18px">
          <Button
            id="multipleReject"
            onClick={() => multipleReject()}
            shape="circle"
            style={{
              float: 'left',
              backgroundColor: '#000000',
              width: '20px',
              height: '20px',
              color: '#ffffff',
              fontSize: '12px',
              border: '0'
            }}
          >
            <IconClose
              style={{
                width: '10px',
                height: '8px',
                position: 'relative',
                top: '-1px'
              }}
            />
          </Button>
          <span
            className="size-10px type-firme-iblack spacing-1px text-black"
            style={{ paddingLeft: '8px' }}
          >
            PASS
          </span>
        </label>
      </Col>
      <Col span={4} offset={13}>
        <label htmlFor="cancelAll" className="d-block clickable leading-18px">
          <Checkbox
            id="cancelAll"
            checked={false}
            onChange={cancelMultipleSelect}
            className="custom-checkbox"
            style={{ float: 'left' }}
          />
          <span
            className="size-10px type-firme-iblack spacing-1px text-black"
            style={{ fontSize: '0.8em', paddingLeft: '8px' }}
          >
            CANCEL
          </span>
        </label>
      </Col>
    </MinLayout>
  );
};
export default ListActions;
