const { firebase } = require('..');

const importToYotPo = async (postUIDs = [], tag) => {
  try {
    const func = firebase.callable('importImageToYotPo');

    const response = await func({ postUIDs, tag });

    console.log(response);
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};

export default importToYotPo;
