export default (influencerUIDs = [], campaign) =>
  influencerUIDs.sort((a, b) => {
    if (Object.keys(campaign).length === 0) {
      return 0;
    }

    const appliedTimestampA = new Date(campaign.appliedUID[a].timestamp);
    const appliedTimestampB = new Date(campaign.appliedUID[b].timestamp);

    return appliedTimestampA <= appliedTimestampB ? 1 : -1;
  });
