import React, { useEffect, useState } from 'react';
// import { Spin, Collapse, Table } from 'antd';
import { Spin, Table } from 'antd';
import * as Promise from 'bluebird';
import { useSelector, useDispatch } from 'react-redux';
import { receiveBrand } from 'actions/brands';
import { receiveYotPoMetrics, receiveYotPoMetric, updateYotPoMetric } from 'actions/yotpoMetric';
import { listenAllYotpoMetric } from 'listeners/yotpoMetrics';
// import { getYotPoMedia } from 'lib/Firebase/callables';
import { firebase } from 'lib/Firebase';
// import { getMediaTally } from 'selectors/admin/yotpo';
import {
  listYotPoMetrics,
  yotpoBrands,
  addListYotPoMetrics
  // addYotPoApiData
} from 'actions/ui/yotpoMetrics';
import Container from 'components/Common/Container';
import './yotpoMetric.less';

// const { Panel } = Collapse;

// const columns = [
//   {
//     key: 'name',
//     title: 'Product Name',
//     dataIndex: 'name'
//   },
//   {
//     key: 'images',
//     title: 'images',
//     dataIndex: 'images'
//   },
//   {
//     key: 'videos',
//     title: 'videos',
//     dataIndex: 'videos'
//   }
// ];

const columnsBrand = [
  {
    key: 'brand',
    title: 'Brand Name',
    dataIndex: 'brand',
    render: brand => {
      return brand ? brand.name : <Spin />;
    }
  }
];

const Page = () => {
  const dispatch = useDispatch();

  const [isFetchingYotPos, setIsFetchingYotPos] = useState(false);
  // const [isFetching, setIsFetching] = useState({});

  const brandById = useSelector(state => state.ui.admin.yotpoMetrics.byBrandId);
  const yotpos = useSelector(state => state.ui.admin.yotpoMetrics.listYotPos);
  // const yotpoApiData = useSelector(state => state.ui.admin.yotpoMetrics.yotpoData);
  // const yotpoTally = useSelector(getMediaTally);

  useEffect(() => {
    const unsubYotPo = listenAllYotpoMetric({
      onAdded: (yotpoId, yotpoData) => {
        dispatch(receiveYotPoMetric({ [yotpoId]: yotpoData }));
        dispatch(addListYotPoMetrics({ [yotpoId]: yotpoData }));
      }
    });

    return () => unsubYotPo();
  }, [dispatch, listenAllYotpoMetric, receiveYotPoMetric, updateYotPoMetric, addListYotPoMetrics]);

  useEffect(() => {
    setIsFetchingYotPos(true);

    async function fetch() {
      const yotpoSnap = await firebase
        .yotpoMetric()
        .orderBy('createdAt', 'desc')
        .get();

      if (!yotpoSnap.empty) {
        let list = {};

        yotpoSnap.docs.forEach(yotpoDoc => {
          list = { ...list, [yotpoDoc.id]: yotpoDoc.data() };
        });

        dispatch(receiveYotPoMetrics(list));
        dispatch(listYotPoMetrics(list));
      }
      setIsFetchingYotPos(false);
    }

    fetch();
  }, [fetch, dispatch, receiveYotPoMetrics, listYotPoMetrics]);

  useEffect(() => {
    function fetchBrands() {
      Promise.map(
        yotpos,
        brandUID => {
          firebase.firestore
            .collection('brands')
            .doc(brandUID)
            .get()
            .then(brandSnap => {
              dispatch(
                yotpoBrands({
                  [brandSnap.id]: brandSnap.data()
                })
              );
            });
        },
        { concurrency: 5 }
      );
    }

    fetchBrands();
  }, [yotpos, dispatch, receiveBrand]);

  // const yotposCollapse = yotpos.map(yotpoId => {
  //   const brand = brandById[yotpoId];
  //   const yotpo = yotpoTally[yotpoId] || {};

  //   const dataSource = Object.keys(yotpo).map(name => {
  //     const newName = name === 'undefined' ? 'Unspecified' : name;

  //     return {
  //       key: `${yotpoId}-${newName}`,
  //       name: newName,
  //       ...yotpo[name]
  //     };
  //   });

  //   return (
  //     <Panel header={brand ? brand.name : <Spin />} key={yotpoId}>
  //       <Table
  //         dataSource={dataSource}
  //         columns={columns}
  //         pagination={false}
  //         loading={{
  //           spinning: !yotpoTally[yotpoId],
  //           tip: 'Fetching live data. It may take a while.'
  //         }}
  //       />
  //     </Panel>
  //   );
  // });

  const yotposDataSource = yotpos.map(yotpoId => {
    const brand = brandById[yotpoId];

    return {
      key: yotpoId,
      brand
    };
  });

  // const fetchMedia = async (page, source, yotpoId) => {
  //   try {
  //     const { pagination, images } = await getYotPoMedia({ page, source });
  //     let yotpoData = [];

  //     images.forEach(image => {
  //       const getMedia = (name, mediaType) => {
  //         if (image.media_type === mediaType) {
  //           yotpoData = [
  //             ...yotpoData,
  //             {
  //               name,
  //               mediaType
  //             }
  //           ];
  //         }
  //       };

  //       if (image.tagged_products) {
  //         image.tagged_products.forEach(product => {
  //           getMedia(product.product_name, 'video');
  //           getMedia(product.product_name, 'image');
  //         });
  //       } else {
  //         getMedia(undefined, 'video');
  //         getMedia(undefined, 'image');
  //       }
  //     });

  //     dispatch(addYotPoApiData(yotpoId, yotpoData));

  //     const { total, per_page: perPage } = pagination;
  //     const isDone = page * perPage >= total;

  //     if (!isDone) {
  //       await fetchMedia(page + 1, source, yotpoId);
  //     }
  //   } catch (e) {
  //     // eslint-disable-next-line no-console
  //     console.log('Error on fetchMedia: ', e);
  //   }
  // };

  // const viewSummary = async keys => {
  //   if (keys.length === 0) {
  //     return;
  //   }

  //   const yotpoId = keys[keys.length - 1];

  //   if (!isFetching[yotpoId] && !yotpoApiData[yotpoId]) {
  //     setIsFetching({ ...isFetching, [yotpoId]: true });

  //     await Promise.map(['import', 'onsite_upload'], async source => {
  //       await fetchMedia(1, source, yotpoId);
  //     }).finally(() => {
  //       setIsFetching({ ...isFetching, [yotpoId]: false });
  //     });
  //   }
  // };

  return (
    <Container width={940}>
      <Table
        dataSource={yotposDataSource}
        columns={columnsBrand}
        loading={isFetchingYotPos}
        pagination={{ defaultPageSize: 20 }}
      />
    </Container>
  );
};

export default Page;
