import {
  RECEIVE_NOTIFICATION,
  RECEIVE_NOTIFICATIONS,
  UPDATE_NOTIFICATION
} from 'reducers/entities/notifications';

export const receivenotifications = notifications => ({
  type: RECEIVE_NOTIFICATIONS,
  notifications
});

export const receivenotification = notification => ({
  type: RECEIVE_NOTIFICATION,
  notification
});

export const updatenotification = (notificationId, notification) => ({
  type: UPDATE_NOTIFICATION,
  notificationId,
  notification
});
