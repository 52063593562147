import React, { useEffect, useState } from 'react';
import { Row, Col, Icon, Button } from 'antd';
import { ReactComponent as TrendLogoWeb } from 'images/trend-logo-web.svg';
import { useHistory, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import * as routes from 'constants/routes';
import { CampaignFormV2 } from '../../components/Campaigns';
import { firebase } from '../../lib/Firebase';
import { editCampaign } from '../../actions/campaigns';
import useSession from '../../lib/Session/useSession';
import { ReactComponent as BackIcon } from '../../images/back-arrow-white.svg';

const EditPage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState({});
  const [cookies] = useCookies(['isAdmin']);
  const authUser = useSession();
  const history = useHistory();
  const { campaignId: campaignUID } = useParams();

  async function setData() {
    const campaignSnap = await firebase.activeProduct(campaignUID).once('value');
    const newCampaign = campaignSnap.val();

    setCampaign({ ...newCampaign });
    setLoading(false);
  }

  useEffect(() => {
    const { uid } = firebase.auth.currentUser;

    if (!cookies.isAdmin) {
      firebase
        .brand(uid)
        .child('details')
        .update({ lastLoginDate: new Date().getTime() / 1000 });
    }

    setData();
  }, []);

  async function handleSubmit(values = {}, hasError = false) {
    const brandUID = authUser.uid;

    let payload = {
      ...campaign,
      ...values,
      brandUID
    };

    Object.keys(payload).forEach(keys => {
      if (payload[keys] === undefined) delete payload[keys];
    });

    setCampaign(payload);

    if (values.category && !hasError) {
      payload = {
        ...payload,
        states: { All: 'All' }
      };

      await dispatch(editCampaign(payload, campaignUID, brandUID));
      history.push(routes.toCampaignSummaryPath(campaignUID));
    }
  }

  if (loading) {
    return <div />;
  }

  return (
    <div style={{ background: '#000' }} id="new-campaign-page">
      <Row
        type="flex"
        justify="space-around"
        style={{ borderBottom: '1px solid #3C3C3C', padding: '15px 15px 15px 0px' }}
      >
        <Col sm={12} style={{ lineHeight: '13px' }}>
          <Button
            type="link"
            style={{
              position: 'absolute',
              fontSize: '18px',
              lineHeight: '13px',
              display: 'inline-block',
              top: '-9px'
            }}
            onClick={() => {
              history.goBack();
            }}
          >
            <Icon component={BackIcon} />
          </Button>
          <TrendLogoWeb className="logo d-iblock" width="70px" style={{ marginLeft: '53px' }} />
        </Col>
        <Col sm={12} style={{ textAlign: 'right', lineHeight: '13px' }}>
          <span
            style={{
              color: '#fff',
              fontFamily: 'SFProDisplay',
              fontSize: '14px',
              fontWeight: 'bold'
            }}
          >
            Need Help Setting Up Your Campaign?{' '}
            <a
              href="https://calendly.com/jamie-catanach/onboarding"
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontStyle: 'italic' }}
            >
              Schedule A Call
            </a>
          </span>
        </Col>
      </Row>
      <Row>
        <Col>
          <CampaignFormV2 campaignUID={campaignUID} campaign={campaign} onSubmit={handleSubmit} />
        </Col>
      </Row>
    </div>
  );
};

export default EditPage;
