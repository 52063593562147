import { firebase } from '../lib/Firebase';
import { receivenotification } from '../actions/notification';

const listenNotification = ({ brandUID, dispatch }) => {
  const unsubscribe = firebase
    .notifications()
    .doc(brandUID)
    .onSnapshot(snapshot => {
      if (snapshot.exists) {
        const data = snapshot.data();
        dispatch(receivenotification({ [snapshot.id]: data }));
      }
    });

  return unsubscribe;
};

export { listenNotification as default, listenNotification };
