import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message } from 'antd';
import moment from 'moment';
import Container from '../../components/Common/Container';
import GiantCheckImg from '../../images/Giant_Check@2x.png';
import { firebase } from '../../lib/Firebase';
import ReferImg from '../../images/Referred_by_friend@2x.png';

const initialState = { companyName: '', contactName: '' };

const REFERRAL_STATUS_MESSAGES = {
  approved: 'Referral Confirmed!',
  pending: 'Pending Trend Approval...',
  rejected: 'Rejected'
};

const ReferPage = () => {
  const [form, setForm] = useState(initialState);
  const [referrals, setReferrals] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    async function fetch() {
      const { currentUser } = firebase.auth;
      const referralsSnap = await firebase.firestore
        .collection('referrals')
        .where('brandUID', '==', currentUser.uid)
        .orderBy('submittedAt', 'desc')
        .get();
      let list = [];
      referralsSnap.docs.forEach(doc => {
        list = [...list, { uid: doc.id, ...doc.data() }];
      });

      setReferrals(list);
    }

    fetch();
  }, [fetch]);

  async function handleSubmit() {
    setSubmitting(true);
    const { currentUser } = firebase.auth;

    if (form.companyName && form.contactName) {
      const referral = {
        ...form,
        brandUID: currentUser.uid,
        status: 'pending',
        submittedAt: moment().unix()
      };

      await firebase.firestore.collection('referrals').add(referral);

      setForm(initialState);
      setReferrals([referral, ...referrals]);
    } else {
      message.error('All fields are requred');
    }

    setSubmitting(false);
  }

  const handleChange = field => e => {
    setForm({ ...form, [field]: e.target.value });
  };

  const referrralList = referrals.map(referral => {
    return (
      <div key={referral.uid} className="referral">
        {referral.status === 'approved' && (
          <div className="referral-icon">
            <img src={GiantCheckImg} alt="approved" />
          </div>
        )}
        <div className="referral-details">
          <div className="referral-details-brand">{referral.companyName}</div>
          <div className={`referral-details-status ${referral.status}`}>
            {REFERRAL_STATUS_MESSAGES[referral.status]}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div id="refer-brand-page">
      <div className="tab-header">
        <Container width={733}>
          <h1>
            Earn 5 Credits ($500 Value)
            <br />
            <small>Refer a brand</small>
            <img src={ReferImg} alt="refer" />
          </h1>
        </Container>
      </div>
      <div className="tab-content">
        <Container width={733}>
          <h2>Earn 5 credits for every brand you refer</h2>
          <p>
            Do you have any friends that work for brands who could use Trend? If you refer them and
            they become a customer, we’ll give you 5 credits for your effort. Thank you in advance.
          </p>
          <Form.Item label="Company Name you Referred" colon={false}>
            <Input
              placeholder="Company name"
              value={form.companyName}
              onChange={handleChange('companyName')}
            />
          </Form.Item>
          <Form.Item label="Contact at this Company" colon={false}>
            <Input
              placeholder="Contact name"
              value={form.contactName}
              onChange={handleChange('contactName')}
            />
          </Form.Item>

          <Button type="primary" onClick={handleSubmit} loading={submitting}>
            Submit Referral
          </Button>

          <div className="referrals">{referrralList}</div>
        </Container>
      </div>
    </div>
  );
};

export default ReferPage;
