import _ from "lodash";
export const SET_ADMIN_BRAND_IDS = "SET_ADMIN_BRAND_IDS";
export const SET_ADMIN_BRAND_SEARCHING = "SET_ADMIN_BRAND_SEARCHING";

const initialState = {
  list: [],
  searching: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ADMIN_BRAND_IDS:
      return {
        ...state,
        list: _.uniq(payload.brandIds)
      };
    case SET_ADMIN_BRAND_SEARCHING:
      return {
        ...state,
        searching: payload
      };
    default:
      return state;
  }
};
