/* eslint no-console: ["error", { allow: ["log"] }] */
/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Layout, Alert } from 'antd';
import ProfileImage from 'components/Common/ProfileImage';
import Logo from 'components/Common/Logo';
import { onFetchBrand } from 'actions/brands';
import { fetchCurrentSubscription, fetchPlans } from 'api/subscriptions';
import { withCookies } from 'react-cookie';
import { isMobile } from 'react-device-detect';
import { fetchLoggedUserData } from 'actions/session';
import SideMenus from './SideNav/SideMenus';
import SubscriptionType from './SideNav/SubscriptionType';
import ContentCredit from './SideNav/ContentCredit';
import FreeTrialBanner from './SideNav/FreeTrialBanner';
import FreeTrialBadge from './SideNav/FreeTrialBadge';
import { listenBrandPosts } from '../../listeners/brands';
import { addPosts } from '../../actions/posts';
import { PendingReviewModal } from '../Brands';
import { getAllInfluencersInBrand } from '../../actions/ui/applicationsAction';
import { setOwnedCampaigns } from '../../actions/ui/sideNav';
import AddCreditNotification from './SideNav/AddCreditNotification';
import MobileView from './MobileView';
import { firebase } from '../../lib/Firebase';

const { Sider, Content } = Layout;

class SiderLayout extends Component {
  constructor(props) {
    super(props);

    const {
      authUser: { uid: userUID }
    } = this.props;

    this.state = {
      brandUID: userUID,
      planName: null,
      squishedNav: false,
      brandReady: false,
      campaignReady: false,
      tmpApprovedAt: null
    };

    this.listeners = [];
  }

  componentWillMount() {
    const {
      authUser: { uid: userUID }
    } = this.props;

    fetchCurrentSubscription(userUID, (subscription, error) => {
      if (error) {
        console.log(error);
      } else if (subscription.object === 'subscription') {
        fetchPlans(plans => {
          const plan = plans.body[subscription.items.data[0].plan.id];

          let planName = plan.nickname;

          if (plan.legacy) {
            planName = 'Custom';
          }

          this.setState({ planName });
        });
      } else if (subscription.object === 'old_subscription') {
        this.setState({ planName: subscription.planName });
      }
    });
  }

  componentDidMount() {
    const {
      onFetchBrand: brand,
      authUser: { uid: userUID },
      dispatch,
      ownedCampaigns
    } = this.props;

    this.listeners.push(
      listenBrandPosts(userUID, posts => {
        dispatch(addPosts(posts));
      })
    );
    const ref = brand(userUID, () => {
      this.setState({ brandReady: true });
    });
    this.listeners.push(ref);

    ownedCampaigns(userUID).then(() => {
      this.setState({ campaignReady: true });
    });
    dispatch(getAllInfluencersInBrand(userUID));

    firebase.firestore
      .collection('brands')
      .doc(userUID)
      .get()
      .then(snap => {
        if (snap.exists) {
          this.setState({ tmpApprovedAt: snap.data().approvedAt });
        }
      });
  }

  componentWillUnmount() {
    this.listeners.forEach(listener => {
      try {
        listener.off();
      } catch (e) {
        listener();
      }
    });
  }

  mobileCheck = () => {
    let check = false;
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)|plucker|pocket|psp|series(4|6)0|symbian|treo|up.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        userAgent
      )
    ) {
      check = true;
    }

    return check;
  };

  adjustSideNav = broken => {
    this.setState({ squishedNav: broken });
  };

  render() {
    const {
      brandUID,
      planName,
      squishedNav,
      brandReady,
      campaignReady,
      tmpApprovedAt
    } = this.state;

    if (isMobile) {
      return <MobileView />;
    }
    if (!brandReady || !campaignReady) {
      return <div />;
    }

    const { brands, children, cookies, history, allOwnedCampaigns, campaignById } = this.props;
    const { ownedCampaigns } = allOwnedCampaigns;

    const userBrand = brands[brandUID];
    const brand = userBrand.details;
    const isAdmin = cookies.get('isAdmin');
    const {
      credits = 0,
      standardCredits = 0,
      addedStandardCredits = 0,
      addedVipCredits = 0,
      status,
      lastPurchaseDate,
      approvedAt
    } = brand;

    const firstCampaign = campaignById[ownedCampaigns[0]];
    const firstCampaignCompleted = firstCampaign;
    const onBoarding = status && !lastPurchaseDate;
    const onBoardingApproved = onBoarding && status === 'approved';
    const onBoardingApprovedComplete = onBoardingApproved && firstCampaignCompleted;
    const showMarqueNotif = status === 'approved' && !lastPurchaseDate;

    let approvedAtDisplay =
      firstCampaign && firstCampaign.extendedTrialDate
        ? firstCampaign.extendedTrialDate
        : approvedAt;

    if (!approvedAtDisplay) {
      approvedAtDisplay =
        firstCampaign && firstCampaign.extendedTrialDate
          ? firstCampaign.extendedTrialDate
          : tmpApprovedAt;
    }

    const expiredOnBoardingCampaign =
      onBoardingApprovedComplete && moment.unix(approvedAtDisplay).add(24, 'hours') < moment();

    const extendedOnBoardingCampaign =
      onBoardingApprovedComplete &&
      firstCampaign &&
      firstCampaign.extendedTrialDate &&
      moment(firstCampaign.extendedTrialDate * 1000).add(24, 'hours') > moment();

    const adminAlert = user => (
      <span>
        You are in admin mode! Logged in as &nbsp;
        <strong>{user.details.name}</strong>
        .&nbsp;Be extra carefull.
      </span>
    );

    return (
      <div>
        <Layout>
          <Sider
            width={210}
            style={{
              overflow: 'hidden',
              height: '100vh',
              position: 'fixed',
              zIndex: '99',
              left: '0',
              minHeight: '100vh'
            }}
            breakpoint="lg"
            onBreakpoint={broken => {
              this.adjustSideNav(broken);
            }}
          >
            {!squishedNav && brand.storeImage && <Logo />}
            <ProfileImage src={brand.storeImage} />
            <SubscriptionType isVIP={brand.isVIP} />
            {userBrand && userBrand.details && userBrand.details.isFreeTrial ? (
              <FreeTrialBadge />
            ) : (
              <ContentCredit
                brand={brand}
                credits={credits + addedVipCredits}
                standardCredits={standardCredits + addedStandardCredits}
                planName={planName}
                squishedNav={squishedNav}
                allOwnedCampaigns={allOwnedCampaigns}
              />
            )}
            <SideMenus
              squishedNav={squishedNav}
              userBrand={userBrand}
              buttonText={
                ownedCampaigns && ownedCampaigns.length
                  ? 'Create New Campaign'
                  : 'Start New Campaign'
              }
            />
          </Sider>

          <Layout id="trend-content-layout" style={{ marginLeft: squishedNav ? '80px' : '210px' }}>
            <Content>
              {isAdmin && userBrand && (
                <Alert
                  className="admin-warning"
                  description={adminAlert(userBrand)}
                  type="warning"
                  style={{ paddingLeft: squishedNav ? '20px' : '110px' }}
                />
              )}
              {children}
            </Content>
            {userBrand && userBrand.details && (
              <FreeTrialBanner brand={userBrand} history={history} />
            )}
          </Layout>
          {!isAdmin && <PendingReviewModal brandUID={brandUID} />}

          {showMarqueNotif && (
            <AddCreditNotification
              unixDate={approvedAtDisplay}
              expired={expiredOnBoardingCampaign && !extendedOnBoardingCampaign}
            />
          )}
        </Layout>
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  brands: state.entities.brands.byId,
  allOwnedCampaigns: state.ui.sideNav,
  campaignById: state.entities.campaigns.byId
});

export const mapDispatchToProps = dispatch => ({
  onFetchBrand: (brandUID, callback) => dispatch(onFetchBrand(brandUID, callback)),
  ownedCampaigns: brandUID => dispatch(setOwnedCampaigns(brandUID)),
  fetchLoggedUserData: (authUser, callback) => dispatch(fetchLoggedUserData(authUser, callback)),
  dispatch
});

export default compose(
  withRouter,
  withCookies,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SiderLayout);
