const { firebase } = require('..');

const crawleraInvite = async ({ inviteUID }, callback) => {
  try {
    const func = firebase.callable('crawleraInvite');
    const response = await func({ inviteUID });

    callback(response.data.message === 'success');
  } catch (e) {
    callback(false);
  }
};

export default crawleraInvite;
