import React from 'react';
import { Icon } from 'antd';
import { connect } from 'react-redux';
import { ReactComponent as PlayButtonSVG } from '../../../images/play-btn.svg';

class PostCardVideo extends React.Component {
  state = {
    playing: false
  };

  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
  }

  componentDidMount() {
    this.videoRef.current.addEventListener('ended', e => {
      e.target.currentTime = 0;
      this.setState({ playing: false });
    });
  }

  togglePlay = e => {
    e.preventDefault();

    const { playing } = this.state;
    if (!playing) {
      this.videoRef.current.play();
    } else {
      this.videoRef.current.pause();
    }
    this.setState({ playing: !playing });
  };

  render() {
    const { post } = this.props;
    const { type } = post;
    const { playing } = this.state;
    const video = post.highResVideo;
    return (
      <div className="post-card-video" onClick={this.togglePlay} role="presentation">
        <div className="post-type-wrapper">
          <div className={`post-type-label type-${type}`}>{type} Post</div>
        </div>
        <div className="post-card-video-actions">
          <div className="post-card-video-actions-button">
            {!playing && <Icon component={PlayButtonSVG} style={{ width: '100%' }} />}
          </div>
        </div>
        <video
          ref={this.videoRef}
          src={video}
          type="video/mp4"
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, { postUID }) => ({
  post: state.entities.posts.byId[postUID]
});

export default connect(mapStateToProps)(PostCardVideo);
