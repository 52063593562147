export const RECEIVE_INFLUENCER_SEARCHES = 'RECEIVE_INFLUENCER_SEARCHES';
const initialState = {
  byId: {},
  all: []
};

const addInfluencerSearches = (state, influecerSearches) => ({
  ...state,
  byId: {
    ...state.byId,
    ...influecerSearches
  },
  all: [...new Set(state.all.concat(Object.keys(influecerSearches)))]
});

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_INFLUENCER_SEARCHES:
      return addInfluencerSearches(state, action.influecerSearches);

    default:
      return state;
  }
};
