import React from 'react';
import { Row, Col, Skeleton } from 'antd';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withAuthUser } from 'lib/Session';
import { selectCampaignById } from 'selectors/campaigns';
import MinLayout from 'components/Common/MinLayout';
import { fetchCampaignPosts, resetCampaignPosts } from 'actions/ui/campaignPosts';
import CampaignSummary from './CampaignSummary';
import RightSideInfo from './RightSideInfo';
import RecentPost from './RecentPost';
import PostGuideLines from './PostGuideLines';
import CampaignImage from './CampaignImage';
import CampaignSettings from './CampaignSettings';
import StyleGuide from '../../Campaigns/StyleGuide';
import NoCampaignImage from '../../../images/no-campaign@2x.png';

const styles = {
  notFree: {
    margin: '30px 0'
  },
  isFreeTrial: {
    margin: '30px 0 80px'
  }
};

const statuses = {
  pending: {
    label: 'Pending',
    title: 'What happens Next?',
    message:
      "If your brand is selected to join the network, you'll receive an email letting you know you got in. Expect a response within 24 hours. "
  },
  approved: {
    label: 'Approved!',
    title: 'Start Your First Campaign to Collect Creator Applications',
    message:
      'Create a campaign in the navigation to the left to start generating posts and content for your brand. If you have questions along the way, you can always reach us through the chat bubble in the bottom right.'
  },
  rejected: {
    label: 'Pending',
    title: 'What happens Next?',
    message:
      "If your brand is selected to join the network, you'll receive an email letting you know you got in. Expect a response within 24 hours. "
  }
};

const BlankOverview = () => {
  return (
    <Row type="flex" justify="center" className="example-campaign">
      <Col span={16} style={{ textAlign: 'center' }}>
        <Row className="media">
          <Col style={{ textAlign: 'center' }}>
            <img src={NoCampaignImage} alt="no campaign" />
            <h1>Create a Campaign to Get Started</h1>
          </Col>
        </Row>

        <Row type="flex" justify="center">
          <Col style={{ width: '520px' }}>
            <p>
              Create a campaign in the navigation to the left to start generating posts and content
              for your brand. If you have questions along the way, you can always reach us through
              the chat bubble in the bottom right.
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const BlankOverviewOnboarding = ({ status }) => {
  if (!status) return <BlankOverview />;
  const { label, title, message } = statuses[status];
  let media = <img src={NoCampaignImage} alt="no campaign" />;
  if (status === 'approved') {
    media = (
      <iframe
        title="Onboarding"
        src="https://www.youtube.com/embed/JbcjY19WwkI"
        width="446"
        height="250"
        frameBorder="0"
        allowFullScreen
      />
    );
  }

  return (
    <Row type="flex" justify="center" className="example-campaign">
      <Col span={16} style={{ textAlign: 'center' }}>
        <Row className="media" type="flex" justify="center">
          <Col style={{ width: '446px', height: '250px', marginBottom: '22px' }}>{media}</Col>
        </Row>
        <div className="account-approval-status">
          Account Approval Status: <span className={`approval-status ${status}`}>{label}</span>
        </div>
        <h1>{title}</h1>

        <Row type="flex" justify="center">
          <Col style={{ width: '488px' }}>
            <p>{message}</p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const Overview = props => {
  const {
    campaign,
    authUser,
    brands,
    match: { params }
  } = props;

  const brandDetails = (brands[authUser.uid] || {}).details || {};
  const { status } = brandDetails;

  if (params.campaignId === 'example') {
    return status ? <BlankOverviewOnboarding status={status} /> : <BlankOverview />;
  }

  return (
    <div style={{ paddingBottom: '79px', background: '#ffffff' }} id="campaign-overview">
      <div
        className="ant-row-flex ant-row-flex-space-around"
        style={brandDetails.isFreeTrial ? styles.isFreeTrial : styles.notFree}
      >
        <MinLayout>
          <Row gutter={{ lg: 64, md: 32, sm: 12 }}>
            <Col md={16} style={{ background: '#ffffff' }}>
              <Skeleton title={false} loading={!campaign} paragraph={{ rows: 4 }} active>
                <CampaignImage
                  image={campaign.image}
                  campaignId={params.campaignId}
                  brandUID={campaign.brandUID}
                />
                <CampaignSummary campaignId={params.campaignId} brandUID={campaign.brandUID} />
                <PostGuideLines campaign={campaign} />
                <StyleGuide campaign={campaign} />
              </Skeleton>
            </Col>
            <Col md={8} className="pl-0">
              <RecentPost campaignId={params.campaignId} />
              <RightSideInfo
                website={campaign.url}
                isStoryRequired={campaign.isStoryRequired}
                postPerInfluencer={campaign.numberOfPosts}
                productValue={campaign.value}
                category={campaign.category}
                hashtags={campaign.hashtags}
                brandtags={campaign.brandtags}
                states={campaign.states}
                gender={campaign.gender}
                postTypes={campaign.postTypes}
              />
            </Col>
          </Row>
        </MinLayout>
      </div>
      <CampaignSettings
        campaignUID={params.campaignId}
        brandUID={campaign.brandUID}
        isListed={campaign.isListed}
        isCompleted={campaign.timestampCompleted}
      />
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  dispatchFetchCampaignPosts: campaignUID => dispatch(fetchCampaignPosts(campaignUID)),
  dispatchResetCampaignPosts: () => dispatch(resetCampaignPosts())
});

const mapStateToProps = (state, ownProps) => ({
  brands: state.entities.brands.byId,
  campaign: selectCampaignById(state, ownProps.match.params.campaignId) || {}
});

Overview.defaultProps = {
  campaign: {}
};

export default compose(
  withAuthUser,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Overview);
