import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Button, Table, Checkbox, Row, notification } from 'antd';
import { getInfluencerList } from 'actions/ui/influencerList';
import {
  checkLastReloadDate,
  approveInfluencers,
  rejectInfluencers,
  getInfluencerByCreditsType
} from 'actions/ui/applicationsAction';
import applicationsFilterAndSort from 'selectors/applications';

import { DATE_TIMEZONE_FORMAT } from 'constants/common';
import MinLayout from 'components/Common/MinLayout';
import { UpCaseFirstLetter } from 'lib/Common';
import EmptyApplications from 'components/Common/EmptyApplications';
import { InfluencerAvatar } from 'components/Influencers';
import InfoModal from 'components/Common/InfoModal';
import { withAuthUser } from 'lib/Session';

import { fetchBrand } from 'actions/brands';
import { REJECT_REASONS } from 'constants/influencers';
import FirstCampaignEmptyApplications from '../../Common/FirstCampaignEmptyApplications';
import FreeTrialModal from './modals/FreeTrialModal';
import ConfirmApproveModal from './modals/ConfirmApproveModal';
import NoCreditsModal from './modals/NoCreditsModal';
import ConfirmRejectModal from './modals/ConfirmRejectModal';
import ListItemActions from './ListItemActions';
import ListActions from './ListActions';
import { setInfluencers } from '../../../actions/influencers';
import { setApplicantList } from '../../../actions/ui/applicantList';
import {
  addElementEventListener,
  removeElementEventListener
} from '../../../helpers/autoScrollUpdate';
import { setMultipleSelect, setSortBy } from '../../../actions/ui/applicationsFilter';
import formatSocialData, { formatSocialDataMin } from '../../Helpers/numeral';
import InfluencerDrawer from '../../Influencers/Drawer';
import { removeCampaignApplicant } from '../../../actions/ui/campaignDashboard';

const MultipleSelect = ({ influencerUID, selectedRowKeys, onChangeSelect }) => (
  <Checkbox
    key={influencerUID}
    checked={selectedRowKeys.includes(influencerUID)}
    onChange={() => onChangeSelect(influencerUID)}
  />
);

const InfluencerImage = ({
  influencerUID,
  influencer,
  allInfluencersInBrand,
  showInfluencerSlider
}) => (
  <InfluencerAvatar
    onClick={showInfluencerSlider}
    influencer={influencer}
    showWorkBefore={allInfluencersInBrand.includes(influencerUID)}
    clickable={false}
  />
);

const columns = [
  {
    title: '',
    dataIndex: 'multipleSelect',
    key: 'multipleSelect',
    render: MultipleSelect,
    className: 'multi-select'
  },
  {
    title: 'INFLUENCER',
    dataIndex: 'influencer',
    key: 'influencer',
    render: InfluencerImage,
    className: 'pl-0 influencer-details'
  },
  {
    title: 'AGE',
    dataIndex: 'ageRange',
    className: 'age-range text-right',
    key: 'ageRange',
    rowClassName: 'age-range',
    sorter: true
  },
  {
    title: 'DATE APPLIED',
    dataIndex: 'dateApplied',
    className: 'date-applied text-right',
    key: 'dateApplied',
    rowClassName: 'date-applied',
    sorter: true
  },
  {
    title: 'FOLLOWERS',
    dataIndex: 'followedByCount',
    key: 'followedByCount',
    className: 'followers text-right',
    rowClassName: 'followers',
    sorter: true,
    render: formatSocialData
  },
  {
    title: 'LIKES/POST',
    dataIndex: 'avgLikeCount',
    key: 'avgLikeCount',
    className: 'like-count text-right',
    rowClassName: 'like-count',
    sorter: true,
    render: formatSocialData
  },
  {
    title: 'COMS/POST',
    dataIndex: 'avgCommentCount',
    key: 'avgCommentCount',
    className: 'comment-count text-right',
    rowClassName: 'comment-count',
    sorter: true,
    render: formatSocialData
  },
  {
    title: 'ENGAGEMENT RATE',
    dataIndex: 'engagementRate',
    key: 'engagementRate',
    className: 'engagement-rate text-right',
    rowClassName: 'engagement-rate',
    sorter: true,
    render: val => `${formatSocialDataMin(val)}%`
  },
  {
    title: '',
    dataIndex: 'viewProfile',
    key: 'viewProfile',
    className: 'actions',
    render: ListItemActions
  }
];

const defaultState = {
  rejectReason: REJECT_REASONS[0][1],
  rejectCustomReason: '',
  visible: false,
  selectedRowKeys: [],
  isValidReason: true,
  submitReject: false,
  submitApprove: false,
  selectedInfluencerUIDs: [],
  visibleNotEnoughCredits: false,
  visibleConfirmApprove: false,
  tallyCredits: [],
  massFinalReject: 'no',
  showInfoModal: false,
  infoModalMsg: '',
  width: null,
  showFreeTrialModal: false,
  influencerDetailsModal: {
    show: false,
    influencerUID: null
  },
  limit: 20
};

class ApplicationList extends Component {
  state = defaultState;

  constructor(props) {
    super(props);

    const {
      authUser: { uid: userUID }
    } = this.props;

    this.state = {
      ...defaultState,
      brandUID: userUID
    };
  }

  componentDidMount() {
    addElementEventListener('trend-content-layout', this.handleScroll);
  }

  componentWillUnmount() {
    removeElementEventListener('trend-content-layout', this.handleScroll);
  }

  handleScroll = () => {
    const { limit } = this.state;
    const { influencerIds } = this.props;
    if (limit < influencerIds.length) {
      this.setState(state => ({ ...state, limit: state.limit + 10 }));
    }
  };

  onChangeSelect = influencerUID => {
    let { selectedRowKeys } = this.state;

    if (selectedRowKeys.includes(influencerUID)) {
      selectedRowKeys = selectedRowKeys.filter(uid => uid !== influencerUID);
    } else {
      selectedRowKeys = [...selectedRowKeys, influencerUID];
    }

    this.setState(() => ({ selectedRowKeys }));
  };

  cancelMultipleSelect = () => {
    const { setMultipleSelect: hideMultipleSelect } = this.props;

    hideMultipleSelect();

    this.setState(() => ({
      selectedRowKeys: []
    }));
  };

  checkIsFreeTrial = () => {
    const { brandUID } = this.state;
    const { brands } = this.props;
    const { isFreeTrial } = brands[brandUID].details;

    this.setState(() => ({
      showFreeTrialModal: isFreeTrial
    }));

    return isFreeTrial;
  };

  closeModal = () => {
    this.setState({
      showInfoModal: false,
      visible: false,
      visibleConfirmApprove: false,
      visibleNotEnoughCredits: false
    });
  };

  hasSelectedRowKeys = (influencerUID, callback) => {
    const { selectedRowKeys } = this.state;

    if (influencerUID || selectedRowKeys.length > 0) {
      const selectedInfluencerUIDs = influencerUID ? [influencerUID] : selectedRowKeys;
      callback(selectedInfluencerUIDs);
    } else {
      this.setState(() => ({
        showInfoModal: true,
        infoModalMsg: 'No selected applicants!'
      }));
    }
  };

  multipleApprove = (influencerUID = null) => {
    if (this.checkIsFreeTrial()) {
      return;
    }

    this.hasSelectedRowKeys(influencerUID, selectedInfluencerUIDs => {
      this.setState(
        () => ({ selectedInfluencerUIDs }),
        () => {
          this.onClickApprove();
        }
      );
    });
  };

  multipleReject = (influencerUID = null) => {
    if (this.checkIsFreeTrial()) {
      return;
    }

    this.hasSelectedRowKeys(influencerUID, selectedInfluencerUIDs => {
      this.setState(() => ({
        selectedInfluencerUIDs,
        visible: true
      }));
    });
  };

  onClickApprove = (influencerUID = null) => {
    if (this.checkIsFreeTrial()) {
      return;
    }

    const { selectedRowKeys, brandUID } = this.state;

    if (influencerUID) {
      this.setState(() => ({
        selectedInfluencerUIDs: [influencerUID],
        selectedRowKeys: [new Set(selectedRowKeys.concat(influencerUID))]
      }));
    }

    const { checkLastReloadDate: reloadDate } = this.props;

    reloadDate(brandUID, this.isUpdatedLastReloadData);
  };

  isUpdatedLastReloadData = (brand, { type, message, description } = {}) => {
    const { influencers } = this.props;
    const { selectedInfluencerUIDs } = this.state;

    if (brand) {
      const brandDetails = brand.details;
      const { standard, vip } = getInfluencerByCreditsType(selectedInfluencerUIDs, influencers);
      const { addedStandardCredits = 0, standardCredits: sc = 0 } = brandDetails;
      const totalStandardCredits = addedStandardCredits + sc;
      const totalNeededCredit = standard + vip * 2;

      if (totalStandardCredits < totalNeededCredit) {
        const tallyCredits = [
          {
            key: '1',
            title: 'Credit(s)',
            standard: totalStandardCredits
          },
          {
            key: '2',
            title: 'Needed Credits',
            standard: totalNeededCredit
          }
        ];

        let nextPayment = null;
        if (brandDetails.standardCredits === 0) {
          nextPayment = moment(new Date(brandDetails.lastReloadDate * 1000), DATE_TIMEZONE_FORMAT)
            .add(30, 'days')
            .format('MMMM DD, YYYY');
        }

        this.setState(() => ({
          tallyCredits,
          nextPayment,
          visibleNotEnoughCredits: true
        }));
      } else {
        this.setState(() => ({
          visibleConfirmApprove: true
        }));
      }
    } else if (type === 'error') {
      notification.error({ message, description });
    } else {
      this.setState(() => ({
        showInfoModal: true,
        infoModalMsg: description,
        width: 400
      }));
    }
  };

  approveInfluencers = () => {
    const { selectedInfluencerUIDs: influencerUIDs, brandUID } = this.state;

    const { brands, match, influencers, campaigns, approveInfluencers: approve } = this.props;

    const campaign = campaigns[match.params.campaignId];

    const args = {
      influencerUIDs,
      influencers,
      campaignUID: match.params.campaignId,
      campaign,
      brandUID,
      brand: brands[brandUID]
    };

    this.setState(() => ({ submitApprove: true }));

    approve(args, (isSuccess, { status, error } = {}) => {
      if (isSuccess) {
        notification.success({
          message: 'Approved',
          description: 'Influencer(s) successfully approved!',
          duration: 2
        });
      } else {
        const notifType = status === 400 ? 'warning' : 'error';
        notification[notifType]({
          message: UpCaseFirstLetter(notifType),
          description: status === 400 ? error : 'Somethings wrong on accepting influencer',
          duration: 4
        });
      }

      this.removeInfluencers(influencerUIDs);
      this.setState(state => ({
        influencerDetailsModal: {
          ...state.influencerDetailsModal,
          show: false
        },
        submitApprove: false,
        visibleConfirmApprove: false,
        selectedRowKeys: []
      }));
    });
  };

  onChangeMassFinalReject = e => {
    this.setState(() => ({ massFinalReject: e.target.value }));
  };

  onChangeRejectReason = e => {
    this.setState(() => ({ rejectReason: e.target.value }));
  };

  onChange = e => {
    const { target } = e;
    this.setState(() => ({ [target.name]: target.value }));
  };

  onCancel = () => {
    const { selectedRowKeys } = this.state;

    this.setState(() => ({
      ...defaultState,
      selectedRowKeys
    }));
  };

  rejectInfluencers = () => {
    const {
      rejectReason: reason,
      rejectCustomReason: customReason,
      brandUID,
      selectedInfluencerUIDs: influencerUIDs,
      massFinalReject
    } = this.state;

    const { rejectInfluencers: reject, brands, match, influencers, campaigns } = this.props;

    if (!reason && !customReason.trim()) {
      this.setState(() => ({ isValidReason: false }));
    } else {
      this.setState(() => ({ submitReject: true }));

      const rejectReason = reason || customReason.trim();
      const args = {
        rejectReason,
        influencers,
        influencerUIDs,
        campaignUID: match.params.campaignId,
        campaign: campaigns[match.params.campaignId],
        brandUID,
        brandDetails: brands[brandUID].details,
        massFinalReject
      };

      reject(args, (isSuccess, { status, error } = {}) => {
        if (isSuccess) {
          notification.success({
            message: 'Rejected',
            description: 'Influencers successfully rejected!',
            duration: 2
          });
        } else {
          const notifType = status === 400 ? 'warning' : 'error';
          notification[notifType]({
            message: UpCaseFirstLetter(notifType),
            description: String(error) || 'Somethings wrong on rejecting influencer',
            duration: 4
          });
        }

        this.removeInfluencers(influencerUIDs);
        this.setState(state => ({
          influencerDetailsModal: {
            ...state.influencerDetailsModal,
            show: false
          },
          submitReject: false,
          visible: false,
          selectedRowKeys: []
        }));
      });
    }
  };

  handleTableChange = (pagination, filters, { field, order }) => {
    const { dispatch } = this.props;

    dispatch(setSortBy(field, order));
  };

  handleShowInfluencerDetails = influencerUID => event => {
    if (['APPROVE', 'PASS'].includes(event.target.innerText)) {
      return;
    }

    this.setState(state => {
      return {
        ...state,
        influencerDetailsModal: {
          show: true,
          influencerUID
        }
      };
    });
  };

  handleHideInfluencerDetails = () => {
    this.setState(state => {
      return {
        ...state,
        influencerDetailsModal: {
          ...state.influencerDetailsModal,
          show: false
        }
      };
    });
  };

  removeInfluencers(removeInfluencerUIDs = []) {
    const {
      dispatchRemoveApplicant,
      match: { params }
    } = this.props;

    dispatchRemoveApplicant(params.campaignId, removeInfluencerUIDs);
  }

  render() {
    const {
      influencerIds,
      influencers,
      allInfluencersInBrand,
      isFetching,
      isUpdating,
      multipleSelect,
      hasInfluencers,
      campaign,
      ownedCampaigns,
      archiveCampaigns,
      brands
    } = this.props;

    const {
      selectedRowKeys,
      visible,
      submitReject,
      rejectReason,
      rejectCustomReason,
      isValidReason,
      visibleNotEnoughCredits,
      tallyCredits,
      visibleConfirmApprove,
      submitApprove,
      selectedInfluencerUIDs,
      nextPayment,
      massFinalReject,
      showInfoModal,
      infoModalTitle,
      width,
      infoModalMsg,
      showFreeTrialModal,
      limit,
      influencerDetailsModal,
      brandUID
    } = this.state;

    const brand = brands[brandUID].details;
    const { status, lastPurchaseDate } = brand;

    const showMarqueNotif = status === 'approved' && !lastPurchaseDate;

    const mapInfluencers = influencerIds.slice(0, limit).map(influencerUID => {
      const { details: influencer, instagram = {} } = influencers[influencerUID];
      const {
        postCount,
        followedByCount,
        avgCommentCount,
        avgLikeCount,
        engagementRate
      } = instagram;
      let dateApplied = moment().format('MMM DD');

      if (
        campaign.appliedUID &&
        campaign.appliedUID[influencerUID] &&
        campaign.appliedUID[influencerUID].timestamp
      ) {
        dateApplied = moment(
          campaign.appliedUID[influencerUID].timestamp,
          moment.defaultFormat
        ).format('MMM DD');
      }

      return {
        key: influencerUID,
        multipleSelect: {
          influencerUID,
          selectedRowKeys,
          onChangeSelect: this.onChangeSelect
        },
        dateApplied,
        followedByCount,
        postCount,
        avgCommentCount,
        avgLikeCount,
        engagementRate,
        influencer: {
          influencerUID,
          influencer,
          allInfluencersInBrand,
          showInfluencerSlider: this.handleShowInfluencerDetails(influencerUID)
        },
        location: influencer.parsedState,
        ageRange: influencer.ageRange,
        gender: influencer.gender,
        categories: influencer.categories ? Object.values(influencer.categories) : [],
        viewProfile: {
          multipleApprove: this.multipleApprove,
          multipleReject: this.multipleReject,
          showInfluencerSlider: this.handleShowInfluencerDetails(influencerUID),
          influencerUID,
          influencer,
          status: influencers[influencerUID].appliedUIDStatus
        }
      };
    });

    const creditsTally = {
      vip: 0,
      standard: 0
    };

    selectedInfluencerUIDs.forEach(uid => {
      if (influencers[uid].details.isVIP) {
        creditsTally.vip += 1;
      } else {
        creditsTally.standard += 1;
      }
    });

    const multipleSelectColumn = columns.filter(
      column => multipleSelect || column.key !== 'multipleSelect'
    );

    const isFirstCampaign = ownedCampaigns.length === 1 && archiveCampaigns.length === 0;

    const addPadding = mapInfluencers.length > 5 && showMarqueNotif;

    return (
      <div>
        {hasInfluencers && multipleSelect && (
          <Row
            type="flex"
            justify="space-around"
            style={{
              padding: '20px 0',
              background: '#fcfcfc',
              borderBottom: '1px solid #e8e8e8'
            }}
          >
            <ListActions
              multipleApprove={this.multipleApprove}
              multipleReject={this.multipleReject}
              cancelMultipleSelect={this.cancelMultipleSelect}
            />
          </Row>
        )}
        <div
          className="application-container"
          id="applicant-lists"
          style={addPadding ? { marginBottom: '70px' } : {}}
        >
          <Row type="flex" justify="space-around">
            <MinLayout>
              {!hasInfluencers && (!isFirstCampaign || !status) && <EmptyApplications />}
              {!hasInfluencers && (isFirstCampaign && status) && <FirstCampaignEmptyApplications />}
              {hasInfluencers && (
                <Table
                  loading={isFetching || isUpdating}
                  columns={multipleSelectColumn}
                  dataSource={mapInfluencers}
                  className="trend-table"
                  cellpadding="0"
                  cellspacing="0"
                  sortDirections={['descend', 'ascend']}
                  onChange={this.handleTableChange}
                  onRow={record => ({
                    onClick: this.handleShowInfluencerDetails(record.key)
                  })}
                  pagination={false}
                />
              )}
            </MinLayout>
          </Row>
        </div>
        <FreeTrialModal show={showFreeTrialModal} hide={this.closeModal} />
        <NoCreditsModal
          show={visibleNotEnoughCredits}
          hide={this.closeModal}
          tallyCredits={tallyCredits}
          nextPayment={nextPayment}
        />
        <ConfirmApproveModal
          show={visibleConfirmApprove}
          hide={this.closeModal}
          selectedInfluencerUIDs={selectedInfluencerUIDs}
          creditsTally={creditsTally}
          submitApprove={submitApprove}
          approveInfluencers={this.approveInfluencers}
        />
        <ConfirmRejectModal
          show={visible}
          hide={this.closeModal}
          rejectInfluencer={this.rejectInfluencer}
          rejectInfluencers={this.rejectInfluencers}
          submitReject={submitReject}
          massFinalReject={massFinalReject}
          onChangeMassFinalReject={this.onChangeMassFinalReject}
          onChangeRejectReason={this.onChangeRejectReason}
          selectedInfluencerUIDs={selectedInfluencerUIDs}
          rejectReason={rejectReason}
          onChange={this.onChange}
          rejectCustomReason={rejectCustomReason}
          isValidReason={isValidReason}
        />
        <InfoModal
          visible={showInfoModal}
          title={infoModalTitle}
          width={width}
          onOkay={() => {
            this.setState(() => ({
              showInfoModal: false,
              infoModalTitle: '',
              infoModalMsg: ''
            }));
          }}
        >
          {infoModalMsg}
        </InfoModal>
        <InfluencerDrawer
          {...influencerDetailsModal}
          onHide={this.handleHideInfluencerDetails}
          actionButtons={influencerUID => [
            <Button
              onClick={() => this.multipleReject(influencerUID)}
              className="action-reject"
              type="default"
              block
            >
              PASS
            </Button>,
            <Button
              onClick={() => this.multipleApprove(influencerUID)}
              className="action-approve"
              type="primary"
              block
            >
              ACCEPT
            </Button>
          ]}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, { match: { params } }) => ({
  influencers: state.entities.influencers.byId,
  influencerIds: applicationsFilterAndSort(
    state.entities.influencers.byId,
    state.ui.campaignDashboard.byCampaignId[params.campaignId].applicantUIDs,
    state.ui.applicationsFilter,
    state.ui.campaignDashboard.currentCampaign
  ),
  campaign: state.entities.campaigns.byId[params.campaignId],
  ownedCampaigns: state.ui.sideNav.ownedCampaigns,
  archiveCampaigns: state.ui.sideNav.ownedArchivedCampaigns,
  hasInfluencers:
    state.ui.campaignDashboard.byCampaignId[params.campaignId] &&
    state.ui.campaignDashboard.byCampaignId[params.campaignId].applicantUIDs &&
    state.ui.campaignDashboard.byCampaignId[params.campaignId].applicantUIDs.length > 0,
  authUserData: state.entities.session.authUserData,
  brands: state.entities.brands.byId,
  campaigns: state.entities.campaigns.byId,
  isFetching: state.entities.influencers.isFetching,
  isUpdating: state.entities.campaigns.isFetching,
  allInfluencersInBrand: state.ui.applicationsAction.allInfluencersInBrand,
  multipleSelect: state.ui.applicationsFilter.multipleSelect
});

const mapDispatchToProps = dispatch => ({
  getInfluencerList: (args, onComplete) => dispatch(getInfluencerList(args, onComplete)),
  checkLastReloadDate: (brandUID, callback) => dispatch(checkLastReloadDate(brandUID, callback)),
  approveInfluencers: (args, callback) => dispatch(approveInfluencers(args, callback)),
  rejectInfluencers: (args, callback) => dispatch(rejectInfluencers(args, callback)),
  setMultipleSelect: () => dispatch(setMultipleSelect(false)),
  dispatchFetchBrand: (brandUID, callback) => dispatch(fetchBrand(brandUID, callback)),
  dispatchSetInfluencers: influencers => dispatch(setInfluencers(influencers)),
  dispatchRemoveApplicant: (campaignUID, influencerUIDs) =>
    dispatch(removeCampaignApplicant(campaignUID, influencerUIDs)),
  dispatchSetApplicantList: influencerUIDs => dispatch(setApplicantList(influencerUIDs)),
  dispatch
});

export default compose(
  withRouter,
  withAuthUser,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ApplicationList);
