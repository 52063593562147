import React, { useEffect, useState } from 'react';
import { Table, Button, Row, Input, Icon, Avatar } from 'antd';
import Promise from 'bluebird';
import moment from 'moment';
import { firebase } from '../../../lib/Firebase';
import './AdminCampaignModal.less';

const statusLabel = {
  'in-range': 'In Time Range',
  overdue: 'OVERDUE',
  completed: 'COMPLETED'
};

const columns = [
  {
    title: 'Influencer',
    key: 'username',
    className: 'influencer-details',
    dataIndex: 'username',
    render: (username, { image, status, key }) => {
      return (
        <Row align="middle" className={`influencer-data ${key}`}>
          <Avatar src={image} />
          <div className="wrapper">
            <div className="influencer-username">{username}</div>
            <div className={`post-state ${status}`}>{statusLabel[status]}</div>
          </div>
        </Row>
      );
    }
  },
  {
    title: 'Product Delivered',
    className: 'product-delivered',
    dataIndex: 'activeAt',
    key: 'activeAt',
    render: val => (val ? `${moment().diff(moment(val), 'days')} days ago` : '--')
  },
  {
    title: 'Due Date',
    dataIndex: 'dueDate',
    className: 'due-date',
    key: 'dueDate',
    render: val => (val ? moment(val).format('MM/DD/YY') : '--')
  },
  {
    dataIndex: 'actions',
    className: 'actions',
    render: ({ onClick }, val) => {
      return (
        <Button type={val.selected ? 'primary' : 'default'} onClick={onClick}>
          {val.selected ? 'Added' : 'Removed'}
        </Button>
      );
    }
  }
];

const searchByUserame = (search = '') => data => {
  return data.username.toLowerCase().includes(search.toLowerCase());
};

const byActive = (a, b) => {
  if (a.activeAt === b.activeAt) {
    return 0;
  }

  if (a.activeAt === undefined) {
    return 1;
  }

  if (b.activeAt === undefined) {
    return -1;
  }

  return a.activeAt > b.activeAt ? 1 : -1;
};

const SelectableInfluencerList = ({
  influencers = {},
  onSelectChange,
  value = [],
  numberOfPosts = 1
}) => {
  const [fetching, setFetching] = useState(false);
  const [source, setSource] = useState([]);
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState('');

  function handleClick(uid) {
    setSelected(prevState => {
      let newState = [...prevState];
      if (newState.includes(uid)) {
        newState = newState.filter(x => x !== uid);
      } else {
        newState = [...newState, uid];
      }
      onSelectChange(newState);
      return newState;
    });
  }

  async function fetchInfluencerDetails() {
    setFetching(true);
    const influencerUIDs = Object.keys(influencers);

    let overdues = [];
    let pendings = [];
    let completed = [];
    await Promise.map(influencerUIDs, async uid => {
      const partnership = influencers[uid];
      const infSnap = await firebase
        .influencer(uid)
        .child('details')
        .once('value');
      const { username, image } = infSnap.val();

      const { activeAt, dueDate, postSubmittedAt } = partnership;

      const { posts = {} } = partnership;

      let submittedPost = 0;
      Object.keys(posts).forEach(x => {
        const post = posts[x];
        if (post.status !== 'pending') {
          submittedPost += 1;
        }
      });

      const data = {
        key: uid,
        username,
        image,
        ...influencers[uid],
        actions: {
          onClick: () => handleClick(uid)
        },
        activeAt,
        dueDate
      };

      // if influencer status is completed
      if (dueDate < postSubmittedAt) {
        data.status = 'overdue';
        overdues = [...overdues, data];
      } else if (submittedPost >= numberOfPosts) {
        data.status = 'completed';
        completed = [...completed, data];
      } else {
        data.status = 'in-range';
        pendings = [...pendings, data];
      }
    });

    overdues.sort(byActive);
    pendings.sort(byActive);
    completed.sort(byActive);

    setSource([...overdues, ...pendings, ...completed]);
    setFetching(false);
  }

  useEffect(() => {
    fetchInfluencerDetails();
  }, [influencers]);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  function handleSearch(e) {
    setSearch(e.target.value);
  }

  if (!source) {
    return 'NO source';
  }

  const s = source
    .map(x => {
      const nw = x;
      if (selected.includes(x.key)) {
        nw.selected = true;
      } else {
        nw.selected = false;
      }

      return nw;
    })
    .filter(searchByUserame(search));

  return (
    <>
      <div className="search">
        <Icon type="search" />
        <Input onChange={handleSearch} placeholder="Search" />
      </div>
      <Table
        className="influencer-table"
        dataSource={s}
        columns={columns}
        loading={fetching}
        pagination={false}
      />
    </>
  );
};

export default SelectableInfluencerList;
