import React from 'react';
import { ReactComponent as IconProfile } from 'images/icon-profile.svg';
import { ReactComponent as IconApprove } from 'images/icon-approve.svg';
import { ReactComponent as IconReject } from 'images/icon-reject.svg';

import {} from 'antd';

const ListItemActions = props => {
  const { influencerUID, multipleApprove, multipleReject, showInfluencerSlider } = props;
  return (
    <div className="cell-action">
      <div
        role="presentation"
        style={{ cursor: 'pointer' }}
        className="link"
        onClick={e => showInfluencerSlider(e)}
      >
        <IconProfile
          width="14"
          height="15"
          style={{ position: 'relative', top: '3px', marginRight: '5px' }}
        />
        <span style={{ color: '#5b6572', letterSpacing: '0.5px', fontSize: '9px' }}>PROFILE</span>
      </div>
      <div>
        <div
          role="presentation"
          style={{ cursor: 'pointer' }}
          className="link"
          onClick={() => multipleApprove(influencerUID)}
        >
          <IconApprove
            width="14"
            height="15"
            style={{
              position: 'relative',
              top: '5px',
              marginRight: '5px'
            }}
          />
          <span style={{ color: '#5b6572', letterSpacing: '0.5px', fontSize: '9px' }}>APPROVE</span>
        </div>

        <div
          role="presentation"
          style={{ cursor: 'pointer' }}
          className="link"
          onClick={() => multipleReject(influencerUID)}
        >
          <IconReject
            width="14"
            height="15"
            style={{
              position: 'relative',
              top: '5px',
              marginRight: '5px'
            }}
          />
          <span style={{ color: '#5b6572', letterSpacing: '0.5px', fontSize: '9px' }}>PASS</span>
        </div>
      </div>
    </div>
  );
};
export default ListItemActions;
