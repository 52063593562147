import React from 'react';
import SilverBadge from '../../../images/badge-silver.png';
import GoldBadge from '../../../images/badge-gold.png';
import DiamondBadge from '../../../images/badge-diamond.png';

const Badge = ({ level }) => {
  let badge = SilverBadge;
  let style = {};
  if (level) {
    if (level === 2) {
      badge = GoldBadge;
    } else if (level === 3) {
      badge = DiamondBadge;
      style = { width: '41px', height: '41.5px' };
    }
  }

  return <img src={badge} alt="badge" style={style} className="influencer-badge" />;
};

export default Badge;
