import Promise from 'bluebird';
import { addPosts } from 'actions/posts';
import { setCampaignPosts } from 'actions/ui/campaignPosts';
import { setInfluencers } from 'actions/influencers';
import { firebase } from '../lib/Firebase';
import onFetchNewMessages from '../api/messages';
import { fetchCampaignActiveOrComplete } from '../api/campaigns';
import {
  fetchCampaignNavLinkStatusCounts,
  setNewMessagesCount
} from '../actions/ui/campaignDashboard';
import { addMessages } from '../actions/messages';

let oldAppliedUID = {
  active: null,
  completed: null
};

let oldCampaignPost = {};

const setDefaultOldValue = () => {
  oldCampaignPost = {};
  oldAppliedUID = {
    active: null,
    completed: null
  };
};

const isChangeAppliedUID = (key, dispatch, appliedUID, campaignUID) => {
  const _oldAppliedUID = oldAppliedUID[key];

  const isChangeLength = () =>
    Object.keys(appliedUID || {}).length !== Object.keys(_oldAppliedUID || {}).length;

  const isChangeStatus = () => {
    const mapStatus = influencers =>
      Object.keys(influencers)
        .map(influencerUID => influencers[influencerUID].status)
        .join();

    return mapStatus(_oldAppliedUID) !== mapStatus(appliedUID);
  };

  if (_oldAppliedUID && (isChangeLength() || isChangeStatus())) {
    dispatch(fetchCampaignNavLinkStatusCounts(campaignUID));
  }

  oldAppliedUID[key] = appliedUID;
};

const isChangeCampaignPostStatus = (key, dispatch, campaignPosts, campaignUID) => {
  const _oldCampaignPost = oldCampaignPost[key];

  const isChangeStatus = () => (_oldCampaignPost || {}).status !== campaignPosts.status;

  const isChangePostStatus = () => {
    const mapStatus = posts =>
      Object.keys(posts || {})
        .map(postUID => posts[postUID].details.status)
        .join();

    return mapStatus(_oldCampaignPost.posts) !== mapStatus(campaignPosts.posts);
  };

  if (_oldCampaignPost && (isChangeStatus() || isChangePostStatus())) {
    dispatch(fetchCampaignNavLinkStatusCounts(campaignUID));
  }

  oldCampaignPost[key] = campaignPosts;
};

export const listenNewMessages = (dispatch, campaignUID) => {
  onFetchNewMessages(campaignUID, (messageUIDs, messages) => {
    dispatch(setNewMessagesCount(campaignUID, messageUIDs.length));
    dispatch(addMessages(messages, campaignUID));
  });
};

export const listenAppliedStatusUpdate = (campaignId, callback) => {
  firebase.activeProduct(campaignId).on('value', snap => {
    if (callback && snap.exists()) {
      const campaign = snap.val();
      callback(campaign);
    }
  });

  firebase.completedProduct(campaignId).on('value', snap => {
    if (callback && snap.exists()) {
      const campaign = snap.val();
      callback(campaign);
    }
  });
};

export const listenUpdateStatuses = (dispatch, campaignUID) => {
  setDefaultOldValue();

  const getAppliedUID = snapshot => (snapshot.val() || {}).appliedUID || {};

  firebase.activeProduct(campaignUID).on('value', snapshot => {
    isChangeAppliedUID('active', dispatch, getAppliedUID(snapshot), campaignUID);
  });

  firebase.completedProduct(campaignUID).on('value', snapshot => {
    isChangeAppliedUID('completed', dispatch, getAppliedUID(snapshot), campaignUID);
  });

  fetchCampaignActiveOrComplete(campaignUID, (campaign, error) => {
    if (error) {
      return;
    }

    const { appliedUID = {} } = campaign;
    const influencerUIDs = Object.keys(appliedUID);

    Promise.map(influencerUIDs, influencerUID =>
      firebase
        .influencer(influencerUID)
        .child(`partnerships/products/${campaignUID}`)
        .on('value', snapshot => {
          isChangeCampaignPostStatus(influencerUID, dispatch, snapshot.val(), campaignUID);
        })
    );
  });
};

export const offListenerCampaignDashboard = campaignUID => {
  setDefaultOldValue();

  firebase.messages().off();
  firebase.activeProduct(campaignUID).off();
  firebase.completedProduct(campaignUID).off();

  fetchCampaignActiveOrComplete(campaignUID, campaign => {
    const { appliedUID = {} } = campaign;

    const influencerUIDs = Object.keys(appliedUID);

    influencerUIDs.forEach(influencerUID => {
      firebase
        .influencer(influencerUID)
        .child(`partnerships/products/${campaignUID}`)
        .off();
    });
  });
};

export const listenCampaignPosts = campaignUID => (dispatch, getState) => {
  const {
    entities: {
      influencers: { byId: influencerById }
    }
  } = getState();

  const postRef = firebase.firestore
    .collection('posts')
    .where('productUID', '==', campaignUID)
    .onSnapshot(snap => {
      const posts = {};

      if (!snap.empty) {
        Promise.map(snap.docs, async doc => {
          const post = doc.data();
          posts[doc.id] = post;

          if (!influencerById[post.influencerUID]) {
            await firebase
              .influencer(post.influencerUID)
              .once('value')
              .then(s => dispatch(setInfluencers({ [s.key]: s.val() })));
          }
        }).then(() => {
          dispatch(addPosts(posts));
          dispatch(setCampaignPosts(campaignUID, Object.keys(posts)));
        });
      }
    });

  return postRef;
};

export const resetCampaignInfluencersListener = (campaignId, influencerUIDs) => {
  influencerUIDs.forEach(id => {
    firebase
      .influencer(id)
      .child(`partnerships/products/${campaignId}`)
      .off();
  });
};

export const listenCampaignInfluencersPosts = (campaignId, influencerUIDs, callback) => {
  influencerUIDs.forEach(id => {
    firebase
      .influencer(id)
      .child(`partnerships/products/${campaignId}`)
      .on('value', snap => {
        if (callback && snap.exists()) {
          callback(id, snap.val());
        }
      });
  });
};

export const resetAllCampaignListeners = (campaignUID, listenedInfluencerUIDs) => {
  firebase
    .messages()
    .orderByChild('details/partnershipUID')
    .equalTo(campaignUID)
    .off();

  firebase
    .posts()
    .orderByChild('productUID')
    .equalTo(campaignUID)
    .off();

  firebase.activeProduct(campaignUID).off();
  firebase.completedProduct(campaignUID).off();

  listenedInfluencerUIDs.forEach(id => {
    firebase
      .influencer(id)
      .child(`partnerships/products/${campaignUID}`)
      .off();
  });
};
