import {
  SET_SORT_BY,
  SET_CATEGORY,
  SET_LOCATION,
  SET_MULTIPLE_SELECT_APPLICANT,
  SET_INFLUENCER_TYPE
} from 'reducers/ui/applicationsFilter';

export const setMultipleSelect = multipleSelect => ({
  type: SET_MULTIPLE_SELECT_APPLICANT,
  multipleSelect
});

export const setSortBy = (field, order) => ({
  type: SET_SORT_BY,
  field,
  order
});

export const setCategory = category => ({
  type: SET_CATEGORY,
  category
});

export const setLocation = location => ({
  type: SET_LOCATION,
  location
});

export const setInfluencerType = influencerType => ({
  type: SET_INFLUENCER_TYPE,
  influencerType
});
