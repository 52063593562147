/* eslint import/prefer-default-export: 0 */

import { firebase } from '../lib/Firebase';

export const listenActiveCampaigns = (
  filters = {},
  lastCampaignDoc = null,
  limit = 10,
  callback
) => {
  let query = firebase.firestore
    .collection('products')
    .where('brandActive', '==', true)
    .orderBy('createdAt', 'desc');

  if (filters.category && filters.health) {
    query = query.where(filters.category, '==', filters.health);
  }

  if (filters.brand) {
    query = query.where('brandUID', '==', filters.brand);
  }

  if (filters.search) {
    query = query.where('brandUID', '==', filters.search);
  }

  if (filters.gender) {
    query = query.where('gender', '==', filters.gender);
  }

  if (lastCampaignDoc) {
    query = query.startAt(lastCampaignDoc);
  }

  return query.limit(limit).onSnapshot(snap => {
    if (!snap.empty) {
      callback(snap.docs);
    }
  });
};
