/* eslint-disable no-console */
import axios from 'axios';
import { firebase, UserToken } from 'lib/Firebase';
import Routes from './routes';

export const paySubscription = async (token, plan, uid, onComplete = null) => {
  const authToken = await firebase.auth.currentUser.getIdToken(true);
  const data = { token, plan, uid };

  axios
    .post(Routes.paySubscription, data, {
      headers: { Authorization: `Bearer ${authToken}` }
    })
    .then(response => {
      onComplete(response);
    })
    .catch(error => {
      onComplete(null, error);
    });
};

export const fetchPlans = async (onComplete = null) => {
  // const { headers } = await UserToken();
  const headers = null;

  axios.get(Routes.plans, { headers }).then(response => {
    onComplete(response.data);
  });
};

export const fetchCurrentSubscription = async (uid, onComplete = null) => {
  const token = await firebase.auth.currentUser.getIdToken(true);

  axios
    .get(Routes.currentSubscription, {
      params: { uid },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(response => {
      onComplete(response.data);
    })
    .catch(err => {
      onComplete({}, err);
    });
};

export const updatePlan = async (uid, plan, onComplete = null) => {
  const token = await firebase.auth.currentUser.getIdToken(true);
  const data = { uid, plan };

  axios
    .post(Routes.updatePlan, data, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(response => {
      onComplete(response.data, null);
    })
    .catch(err => {
      onComplete(null, err);
    });
};

export const fetchCards = async () => {
  const { headers } = await UserToken();

  return axios
    .get(Routes.cardList, { headers })
    .catch(err => {
      console.log(err.response.data);
      return {};
    })
    .then(response => {
      const { data = null } = response;
      return data;
    });
};

export const createCard = async stripeToken => {
  const { headers } = await UserToken();
  const body = { token: stripeToken };

  return axios
    .post(Routes.createCard, body, { headers })
    .catch(err => {
      throw new Error(err.response.data.message);
    })
    .then(response => {
      if (response) {
        const { data = null } = response;

        return data;
      }

      return null;
    });
};

export const validateStripeCustomerId = async stripeCustomerId => {
  const { headers } = await UserToken();
  const body = { stripeCustomerId };

  return axios
    .post(Routes.validateStripeCustomerId, body, { headers })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      throw new Error(err.response.data.message);
    });
};

export const updateCardDetails = async token => {
  const { headers } = await UserToken();
  const body = { token };

  try {
    const response = await axios.post(Routes.updateCard, body, { headers });
    return response;
  } catch (err) {
    throw new Error(err.response.data.message);
  }
};
