import { firebase } from '../lib/Firebase';

const listenAllYotpoMetric = ({ onAdded }) => {
  return firebase.yotpoMetric().onSnapshot(snapshot => {
    snapshot.docChanges().forEach(change => {
      const { id: brandId } = change.doc;
      const yotpo = change.doc.data();

      if (change.type === 'added' && onAdded) {
        onAdded(brandId, yotpo);
      }
    });
  });
};

export { listenAllYotpoMetric as default, listenAllYotpoMetric };
