import React, { Component } from 'react';
import { Table, Row, Button, Col } from 'antd';
import { CSVLink } from 'react-csv';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { POST_STATUS } from 'constants/posts';
import { showShippingFormModal } from 'actions/ui/shippingFormModal';
import MinLayout from 'components/Common/MinLayout';
import CustomEmpty from 'components/Common/CustomEmpty';
import filterInfluencers from 'selectors/productShipments';
import ShippingFormModal from './ShippingFormModal';
import { InfluencerDrawer, InfluencerAvatar } from '../../Influencers';
import { ReactComponent as IconAlert } from '../../../images/alert.svg';

const Action = ({ showModal, influencerUID, campaignUID, status }) => {
  let shippedBtn = (
    <Button
      onClick={() => showModal(influencerUID, campaignUID)}
      className="trend-btn"
      style={{
        height: '33px',
        width: '97px',
        wordWrap: 'break-word',
        color: '#000000',
        fontSize: '10px',
        fontWeight: '900'
      }}
    >
      MARK AS
      <br />
      SHIPPED
    </Button>
  );

  if (status !== POST_STATUS.needsShipping) {
    shippedBtn = (
      <Button
        onClick={() => showModal(influencerUID, campaignUID)}
        className="trend-btn secondary type-firme-iblack bg-white"
        style={{ height: '32px' }}
      >
        Shipping Details
      </Button>
    );
  }

  return <div className="cell-action">{shippedBtn}</div>;
};

const Address = influencer => (
  <div
    style={{
      lineHeight: '1.64'
    }}
    className="size-11px text-black"
  >
    <div className="type-sbold">{influencer.name}</div>
    <div>
      {influencer.address} {influencer.secondAddress}
    </div>
    <div>
      {influencer.parsedCity}
      ,&nbsp;
      {influencer.parsedZip}
    </div>
  </div>
);

const columns = [
  {
    title: 'INFLUENCERS',
    dataIndex: 'influencer',
    key: 'influencer',
    width: '220px',
    render: influencer => <InfluencerAvatar influencer={influencer} />,
    className: 'influencer-width-shipment'
  },
  {
    title: 'ADDRESS',
    dataIndex: 'influencer',
    key: 'address',
    render: Address
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    align: 'right',
    className: 'col-action actions',
    render: Action
  }
];

class ProductShipments extends Component {
  state = {
    influencerDetailsModal: {
      show: false,
      influencerUID: null
    }
  };

  handleShowInfluencerDetails = influencerUID => event => {
    if (event.target.type === 'button') {
      return;
    }

    this.setState(state => {
      return {
        ...state,
        influencerDetailsModal: {
          show: true,
          influencerUID
        }
      };
    });
  };

  handleHideInfluencerDetails = () => {
    this.setState(state => {
      return {
        ...state,
        influencerDetailsModal: {
          ...state.influencerDetailsModal,
          show: false
        }
      };
    });
  };

  render() {
    const { influencerDetailsModal } = this.state;
    const {
      match,
      influencers,
      showShippingFormModal: showModal,
      isFetching,
      isUpdating,
      needsShippingInfluencerUIDs,
      shippedInfluencerUIDs
    } = this.props;

    const campaignUID = match.params.campaignId;

    const formatInfluencer = influencerUID => {
      const influencer = influencers[influencerUID];
      return {
        key: influencerUID,
        influencer: influencer.details,
        action: {
          showModal,
          influencerUID,
          campaignUID,
          status: influencer.partnerships.products[campaignUID].status
        }
      };
    };

    const mapNeedsShipping = needsShippingInfluencerUIDs.map(influencerUID =>
      formatInfluencer(influencerUID)
    );

    const mapShipped = shippedInfluencerUIDs.map(influencerUID => formatInfluencer(influencerUID));

    let csv = [['Full Name', 'Address']];
    needsShippingInfluencerUIDs.forEach(influencerUID => {
      const { details } = influencers[influencerUID];
      csv = [
        ...csv,
        [
          details.name,
          `${details.address} ${details.secondAddress}, ${details.city}, ${details.state} ${details.zip}`
        ]
      ];
    });

    return (
      <Row id="shipments-content" type="flex" justify="space-around">
        <MinLayout>
          {mapNeedsShipping.length > 0 && (
            <div style={{ marginTop: '28px', marginBottom: '8px' }}>
              <div
                className="type-ebold size-13px text-black mb-2 type-sfpro-heavy"
                style={{
                  marginBottom: '15px',
                  display: 'flex'
                }}
              >
                <IconAlert style={{ marginRight: '9px' }} />
                Ship & Add Tracking Codes - &nbsp;
                {mapNeedsShipping.length}
              </div>
              <Row>
                <Col span={18} className="type-sfpro-regular">
                  <div
                    style={{
                      fontSize: '13px',
                      lineHeight: '20px',
                      marginBottom: '39px',
                      color: '#000'
                    }}
                  >
                    It is crucial that you add tracking codes to the Trend platform after marking a
                    product as shipped. Adding
                    <br />
                    tracking codes allows us to assign due dates to the creators. This will keep
                    your campaign on track.
                  </div>
                </Col>
                <Col span={6} style={{ textAlign: 'right' }}>
                  <CSVLink
                    data={csv}
                    filename="NeedShipping.csv"
                    className="ant-btn trend-btn secondary type-firme-iblack bg-white"
                    style={{
                      marginRight: '46px',
                      width: '99px',
                      overflowWrap: 'break-word',
                      lineHeight: '1.499',
                      padding: '4px'
                    }}
                  >
                    <span>EXPORT</span>
                    <br />
                    <span>SHIPPING CSV</span>
                  </CSVLink>
                </Col>
              </Row>

              <Table
                locale={{
                  emptyText: <CustomEmpty description="No shipments to show" />
                }}
                className="trend-table product-shipment-table"
                loading={isFetching || isUpdating}
                columns={columns}
                dataSource={mapNeedsShipping}
                pagination={false}
                onRow={record => ({
                  onClick: this.handleShowInfluencerDetails(record.key)
                })}
                cellpadding="0"
                cellspacing="0"
              />
            </div>
          )}

          {mapShipped.length > 0 && (
            <div style={{ marginTop: '23px' }}>
              <div
                className="type-ebold size-13px text-black mb-2 type-sfpro-heavy"
                style={{ marginBottom: '15px' }}
              >
                Shipped -&nbsp;
                {mapShipped.length}
              </div>
              <Table
                locale={{
                  emptyText: <CustomEmpty description="No shipments to show" />
                }}
                className="trend-table"
                loading={isFetching || isUpdating}
                columns={columns}
                dataSource={mapShipped}
                pagination={false}
                cellpadding="0"
                cellspacing="0"
                onRow={record => ({
                  onClick: this.handleShowInfluencerDetails(record.key)
                })}
              />
            </div>
          )}

          <ShippingFormModal />

          <InfluencerDrawer {...influencerDetailsModal} onHide={this.handleHideInfluencerDetails} />
        </MinLayout>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  needsShippingInfluencerUIDs: filterInfluencers(
    state.entities.influencers.byId,
    state.ui.influencerList,
    ownProps.match.params.campaignId,
    POST_STATUS.needsShipping
  ),
  shippedInfluencerUIDs: filterInfluencers(
    state.entities.influencers.byId,
    state.ui.influencerList,
    ownProps.match.params.campaignId
  ),
  influencers: state.entities.influencers.byId,
  isFetching: state.entities.influencers.isFetching,
  isUpdating: state.entities.campaigns.isFetching
});

const mapDispatchToProps = {
  showShippingFormModal
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProductShipments);
