import React, { useState } from 'react';
import { Icon, Button, Dropdown, Menu, message, notification } from 'antd';
import { doSignInWithCustomToken, fetchAuthToken } from 'api/auth';
import { useHistory } from 'react-router-dom';
import { firebase } from '../../../lib/Firebase';

const Actions = ({ brandUID, productUID }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleClick({ key }) {
    setLoading(true);
    if (key === 'login') {
      fetchAuthToken(brandUID, (response, err) => {
        const token = response.data;
        if (!err) {
          doSignInWithCustomToken(token, () => {
            setLoading(false);
            history.push('/posts');
          });
        }
      });
    } else if (key === 'scrape') {
      try {
        await firebase.callService({
          serviceName: 'retrieveInstagramProductPostsStats',
          data: { uid: productUID, brandUID, skip: true },
          type: 'api'
        });

        message.success('Campaign posts successfully scraped');
      } catch (e) {
        message.error('Unauthorized access, please switch to an admin brand');
      }
      setLoading(false);
    } else if (key === 'move-listing-top') {
      const reverseEpochTimestamp = Math.floor(Date.now() / 1000) * -1;

      firebase.activeProduct(productUID).update({ reverseEpochTimestamp });
      firebase.firestore
        .collection('products')
        .doc(productUID)
        .update({ reverseEpochTimestamp });

      notification.success({
        message: 'Success',
        description: 'Campaign successfully bumped.',
        duration: 3
      });

      setLoading(false);
    }
  }

  const menu = (
    <Menu onClick={handleClick}>
      <Menu.Item key="login">Login to campaign</Menu.Item>
      <Menu.Item key="scrape">Scrape posts</Menu.Item>
      <Menu.Item key="move-listing-top">Move listing to the top</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight">
      <Button type="primary" loading={loading}>
        Actions <Icon type="down" />
      </Button>
    </Dropdown>
  );
};

export default Actions;
