import React from 'react';

import { ReactComponent as TrendLogoWeb } from 'images/trend-logo-web.svg';
import { ReactComponent as TrendLogoMobile } from 'images/trend-logo-mobile.svg';


const Logo = () => (
  <div className="logo-wrapper">
    <TrendLogoWeb className="logo d-iblock hidden-mobile" width="70px" />
    <TrendLogoMobile className="logo logo-mobile" width="30px" height="31px" />

  </div>
);

export default Logo;
