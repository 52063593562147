/* eslint-disable */

import React from 'react';
import { Row } from 'antd';

const TermsOfService = () => (
  <Row type="flex" justify="space-around">
    <div className="min-layout-content" style={{ textAlign: 'justify' }}>
      <h1
        className="white mb-2 text-white"
        style={{ textAlign: 'center', marginBottom: '40px !important' }}
      >
        Customer Service Agreement
      </h1>

      <p className="type-light" style={{ color: '#a6a6a6', lineHeight: '25px' }}>
        This a Service Agreement is between Yeuma Inc (dba “Trend”), a Delaware corporation with
        offices at 701 Brazos St., 16th Floor, Austin, TX 78701, on behalf of itself and its
        subsidiaries and the entity identified on the Order Page{' '}
        <b className="text-white">(“Customer”)</b>. The Agreement is effective as of the date of
        payment of the Invoice or Order Page <b className="text-white">("Effective Date")</b>. The
        following terms of service (these <b className="text-white">"Terms of Service"</b>), govern
        your access to and use of the Trend platform, including any content, functionality and
        services offered on or through www.trend.io (the "Site") by Yeuma Inc.
      </p>

      <h2 className="white mb-2 text-white" style={{ margin: '10px 0 10px' }}>
        1. DEFINITIONS
      </h2>

      <p className="type-light" style={{ color: '#a6a6a6', lineHeight: '25px' }}>
        Capitalized terms have the meanings described in this section or in the body of the
        Agreement. <br /> <br />
        <b className="text-white">"Agreement"</b> means this Service Agreement and Order Page
        (unless otherwise required by context). <br /> <br />
        <b className="text-white">"Customer Data"</b> means all electronic data or information that
        Customer submits to the Service. <br /> <br />
        <b className="text-white">"Community"</b> means all influencers and content creators in the
        Trend network. <br /> <br />
        <b className="text-white">"Content"</b> means images created by the Trend Influencer
        Community. <br /> <br />
        <b className="text-white">"Order Page"</b> means a Trend ordering page within the platform
        which references this Service Agreement. <br /> <br />
        <b className="text-white">"Service"</b> means Trend’s proprietary influencer marketing
        platform and community software-as-a-service, accessible through the Trend app, that is
        intended for fulfilling influencer marketing campaigns and providing analytics for the
        related content. <br /> <br />
        <b className="text-white">"Credits"</b> are the formal system with which a Buyer can engage
        a Creator after a purchase was made from the Order Page. <br /> <br />
        <b className="text-white">"Term"</b> means the period of Customer’s access to the Service as
        specified in an Order Form, unless terminated earlier under Section 7 (Term and
        Termination). <br /> <br />
        <b className="text-white">"Third Party Products"</b> means any products or services not
        developed by Trend. <br /> <br />
        <b className="text-white">"User"</b> means an employee or independent contractor of Customer
        that Customer authorizes to use the Service on Customer’s behalf.
      </p>

      <h2 className="white mb-2 text-white" style={{ margin: '10px 0 10px' }}>
        2. SERVICE AND SUPPORT
      </h2>

      <p className="type-light" style={{ color: '#a6a6a6', lineHeight: '25px' }}>
        <b className="text-white">2.1. Provision of Service.</b> Trend shall provide Customer with
        access to the Service for the Term specified on the Order Page. Each Credit has a lifetime
        of 12 months before it expires. <br /> <br />
        <b className="text-white">2.2. Use of Service.</b> Customer shall use the Service and the
        content generated by the Service only for its intended purpose. If Customer is a marketing
        or public relations agency, Customer may use the Service to provide services to Customer’s
        designated clients, subject to the restrictions in Section 3 (Restrictions). <br /> <br />
        <b className="text-white">2.3. Support.</b> Trend shall provide Customer with technical
        support <b className="text-white">("Customer Support")</b>. To request support, please
        contact your Account Manager or support@trend.io <br /> <br />
        <b className="text-white">2.4. System Requirements.</b> Customer shall meet the minimum
        system requirements for access to the Service. <br /> <br />
        <b className="text-white">2.5. Third Party Products.</b> Trend may offer Customer the
        ability to use Third Party Products with the Service, subject to Customer’s agreement with
        any applicable terms and conditions for those Third Party Products.
      </p>

      <h2 className="white mb-2 text-white" style={{ margin: '10px 0 10px' }}>
        3. RESPONSIBILITIES AND RESTRICTIONS
      </h2>

      <p className="type-light" style={{ color: '#a6a6a6', lineHeight: '25px' }}>
        <b className="text-white">3.1. Trend Responsibilities.</b> Trend shall: (i) provide Customer
        with access to the Service in accordance with this Agreement and all applicable laws; (ii)
        provide the Service with a minimum of 99.0% Uptime during any calendar month, except Trend
        shall have 2 days to restore availability after any downtime.{' '}
        <b className="text-white">"Uptime"</b> means the availability of the Service, excluding lack
        of availability due to Customer or third party causes, downtime for maintenance, or a force
        majeure event; and (iii) provide account support to ensure approved Influencers post within
        4 weeks of receiving product. In the event an Influencer does not post during that
        timeframe, Customer will receive additional Influencer credit . <br /> <br />
        <b className="text-white">3.2. Customer Responsibilities.</b> Customer shall: (i) comply
        with this Agreement; (ii) cooperate with Trend so that Trend can provide the Service,
        including using reasonable efforts to make people and information available; (iii) be
        responsible for the Customer Data, except for Trend’s obligations described in Section 5
        (Confidentiality); (iv) use reasonable efforts to prevent unauthorized access or use of the
        Service and promptly notify Trend if Customer discovers any unauthorized access or use; (v)
        use the Service in accordance with this Agreement and applicable laws, especially influencer
        marketing and endorsement guidelines as required by federal, state and local law; (vi) post
        product campaigns, approve or reject Influencer applications within 45 business days,
        provide product or service exactly as outlined in campaign in a timely manner, and approve
        or reject submitted posts within 72 hours of submission <br /> <br />
        <b className="text-white">3.3 Restrictions.</b> Only Users may use the Service and only with
        the account credentials issued to that User. Users may not share their account credentials.
        Customer shall not, and shall not permit any third party to: (i) use the Service except as
        expressly authorized under this Agreement; (ii) interfere with or disrupt the integrity or
        performance of the Service; (iii) rent, lease, lend, sell, sublicense, assign, distribute,
        publish, transfer or otherwise make the Service available; (iv) remove any title, trademark,
        copyright, or restricted rights notices or labels from the Service; (v) modify or create a
        derivative work of the Service or any portion of the Service; (vi) reverse engineer,
        disassemble, decompile, decode, adapt or otherwise attempt to derive or gain access to the
        source code, object code or underlying structure or algorithms of the Service; (vii) solicit
        members of the Trend Community to complete work outside of the Trend platform during the
        service Term or after the date of Termination. Engaging in this activity will result in a
        $1,000 per engagement done outside of the Trend platform with a member of the Trend
        Community. <br /> <br />
      </p>

      <h2 className="white mb-2 text-white" style={{ margin: '10px 0 10px' }}>
        4. FEES
      </h2>

      <p className="type-light" style={{ color: '#a6a6a6', lineHeight: '25px' }}>
        <b className="text-white">4.1. Fees.</b> Customer shall pay all fees specified in Order Page{' '}
        <b className="text-white">(“Fees”)</b>. Fees are quoted and payable in United States dollars
        and are non-refundable, except as described in Section 4.6 (Refund Policy) and Section 7
        (Term and Termination). <br /> <br />
        <b className="text-white">4.2. Taxes.</b> Fees are exclusive of all taxes, including any
        applicable sales, excise, or use taxes <b className="text-white">(“Taxes”)</b>. Trend shall
        include any Taxes on an Order Page or invoice. Customer shall pay any Taxes directly or to
        Trend, as required by law. If Customer is exempt from paying Taxes, Customer shall provide
        Trend with a valid tax exemption certificate. Customer is not responsible for taxes based on
        Trend’s net income, property, or employees. <br /> <br />
        <b className="text-white">4.3. Invoicing and Payment.</b> Trend shall invoice Customer
        according to the terms on the Order Page. Unless the Order Page states otherwise, Fees are
        due to begin service. Customer shall provide Trend with complete and accurate billing and
        contact information and promptly notify Trend of any changes throughout the Term. <br />{' '}
        <br />
        <b className="text-white">4.4. Payment Disputes.</b> If Customer disputes a billed amount in
        good faith, Customer shall give Trend written notice with detailed support of the dispute
        within 10 business days of Customer’s receipt of the invoice. If Customer fails to do so,
        Customer waives its right to dispute that invoice, and the invoice will be deemed accurate
        and valid. This section does not waive Customer’s obligation to pay all undisputed Fees.{' '}
        <br /> <br />
        <b className="text-white">4.5. Refund Policy.</b> If for any reason Customer would like to
        cancel, Customer may do so prior to approving any Influencer (“Community Member”)
        applications. Refund requests must be made prior to the first influencer application being
        accepted, whichever comes first. If you choose to cancel, please contact your Account
        Manager directly or <a href="support@trend.io">support@trend.io</a>.
      </p>

      <h2 className="white mb-2 text-white" style={{ margin: '10px 0 10px' }}>
        5. CONFIDENTIALITY
      </h2>

      <p className="type-light" style={{ color: '#a6a6a6', lineHeight: '25px' }}>
        <b className="text-white">5.1. Definition. “Confidential Information”</b> means oral,
        electronic or written information disclosed by a party that is designated confidential or
        that reasonably should be understood to be confidential given its nature and the
        circumstances of its disclosure. Customer Confidential Information includes, but is not
        limited to, Customer Data. Trend Confidential Information includes, but is not limited to,
        information pertaining to the features, functionality and performance of the Service,
        pricing, and this Agreement. Confidential Information does not include information that: (i)
        is now or becomes generally known or available to the public without breach of this
        Agreement by the receiving party (the <b className="text-white">“Recipient”</b>); (ii) was
        acquired by the Recipient without restriction on its use or disclosure before the
        information was received from the disclosing party (the{' '}
        <b className="text-white">“Discloser”</b>); (iii) is obtained by the Recipient without
        restriction on its use or disclosure from a third party authorized to make the disclosure;
        or (iv) is independently developed by the Recipient without using or referring to the
        Discloser's Confidential Information. <br /> <br />
        <b className="text-white">5.2. Protection of Confidential Information.</b>{' '}
        {
          'The Recipient may only use the Discloser’s Confidential Information in relation to this Agreement. The Recipient shall maintain the confidentiality of the Discloser’s Confidential Information with at least the same degree of care that it uses to protect its own confidential and proprietary information (including but not limited to maintaining reasonable administrative, physical, and technical safeguards) and no less than a reasonable degree of care. The Recipient shall not disclose any of the Discloser’s Confidential Information except to Recipient’s employees, contractors and agents who need to know the information to provide the Service or in relation to an event described in Section 5 (Assignment), in which case Recipient shall cause these recipients to agree to and abide by commercially reasonable confidentiality terms. Each party has the right to obtain an injunction (without having to post a bond) to prevent any breach or continued breach of this section.'
        }{' '}
        <br /> <br />
        <b className="text-white">5.3. Compelled Disclosure.</b>{' '}
        {
          'If the Recipient is required by law or a valid court or government order to disclose any of the Discloser’s Confidential Information, then (to the extent permitted under law) the Recipient shall promptly notify the Discloser in writing of the required disclosure so that the Discloser may seek to protect its Confidential Information. The Recipient shall cooperate with the Discloser in seeking such protection. If the Recipient is required to disclose the Discloser’s Confidential Information, it shall disclose only the portion of the Confidential Information legally required and shall use reasonable efforts to obtain reliable assurances that the Confidential Information will be treated confidentially to the maximum extent possible.'
        }{' '}
        <br /> <br />
      </p>

      <h2 className="white mb-2 text-white" style={{ margin: '10px 0 10px' }}>
        6. PROPRIETARY RIGHTS
      </h2>

      <p className="type-light" style={{ color: '#a6a6a6', lineHeight: '25px' }}>
        <b className="text-white">6.1. Customer Licenses to Content.</b> Trend owns all right, title
        and interest in and to each Content Submission, irrevocably, perpetually and without any
        limitation or restriction whatsoever. Customer is granted an irrevocable, perpetual,
        worldwide license to their approved content submitted through Trend. Customer will have the
        right to use, reproduce, display, electronically transmit, distribute, publish, broadcast,
        modify, edit, combine with the work of others, make derivative works from and otherwise
        exploit each of Customer’s Approved Content Submissions. Customer shall back up Customer
        Data and Content during the Term and may not have access to the Customer Data via the
        Service after the Term. <br /> <br />
        <b className="text-white">6.2. Blind Data.</b> Trend may collect, develop, create, extract,
        compile, synthesize, analyze and commercialize statistics, benchmarks, measures and other
        information based on Aggregated Data (collectively,{' '}
        <b className="text-white">“Blind Data”</b>). Blind Data will be owned solely by Trend and
        may be used for any lawful business purpose without a duty of accounting to Customer.{' '}
        <b className="text-white">“Aggregated Data”</b> means Customer Data that is: (i) anonymized
        and not identifiable to any person or entity; (ii) combined with the data of other customers
        or additional data sources; and (iii) presented in a way which does not reveal Customer’s
        identity. <br /> <br />
        <b className="text-white">6.3. Feedback.</b> If Customer provides Feedback, Customer grants
        to Trend a worldwide, perpetual, irrevocable, sub-licensable, royalty-free, transferable
        license to use the Feedback in the Service and any intellectual property Trend develops.{' '}
        <b className="text-white">“Feedback”</b> means recommendations, suggestions, enhancement
        requests or other feedback or any ideas, technology, developments, derivative works or other
        intellectual property related to the Service or any services or products provided by Trend.{' '}
        <br /> <br />
        <b className="text-white">6.4. Reservation of Rights.</b> Trend and its licensors reserve
        all right, title and interest in and to the Service, including all related intellectual
        property rights, subject to the limited rights expressly granted in this Agreement. No other
        rights are granted to Customer by this Agreement. <br /> <br />
      </p>

      <h2 className="white mb-2 text-white" style={{ margin: '10px 0 10px' }}>
        7. TERM AND TERMINATION
      </h2>

      <p className="type-light" style={{ color: '#a6a6a6', lineHeight: '25px' }}>
        <b className="text-white">7.1. Term.</b> The Term begins on the Effective Date and ends on
        the Termination Date. <b className="text-white">“Effective Date”</b> is after payment is
        received, unless otherwise noted on Order Form.{' '}
        <b className="text-white">“Termination Date”</b> means the earlier date of: (i) the
        expiration or termination of all Credits under this Agreement; or (ii) termination of this
        Agreement under this section. <br /> <br />
        <b className="text-white">7.2. Termination for Cause.</b> A party may terminate this
        Agreement or any applicable Order Page: (i) if the other party is in material breach of this
        Agreement and fails to cure the breach within 30 days of receiving written notice from the
        non-breaching party; or (ii) if the other party becomes the subject of a petition in
        bankruptcy or any proceeding relating to insolvency, receivership, liquidation or assignment
        for the benefit of creditors. Any notice of breach must contain specific information to
        substantiate the alleged breach. If Customer terminates due to Trend’s breach, Customer’s
        exclusive remedy is a pro-rata reimbursement of prepaid Fees covering the remainder of the
        Term after the Termination Date. If Trend terminates due to Customer’s breach, Customer will
        pay any unpaid Fees covering the remainder of the Term after the Termination Date.
        Termination under this section will not relieve Customer of its obligation to pay any Fees
        owed for the period prior to the Termination Date. <br /> <br />
        <b className="text-white">7.3. Effect of Termination.</b> Customer shall immediately cease
        all use of and access to the Service on the Termination Date. Section 3.3 (Restrictions),
        Section 5 (Confidentiality), Section 6 (Proprietary Rights), Section 7 (Term and
        Termination) and Section 9 (Limitation of Liability), and Section 11 (General Terms) will
        survive termination. All other rights and obligations will be of no further force or effect.{' '}
        <br /> <br />
      </p>

      <h2 className="white mb-2 text-white" style={{ margin: '10px 0 10px' }}>
        8. WARRANTIES AND DISCLAIMERS
      </h2>

      <p className="type-light" style={{ color: '#a6a6a6', lineHeight: '25px' }}>
        <b className="text-white">8.1. Mutual Warranties.</b> Each party represents that it: (i) has
        the legal power to enter into this Agreement; (ii) will comply with all applicable laws
        during the Term; and (iii) will use reasonable efforts to avoid transmitting to the other
        party any harmful or malicious code, files, scripts, agents or programs. <br /> <br />
        <b className="text-white">8.2. Warranty Disclaimer.</b> Trend does not make any
        representations that the functions performed by the Service will meet all of Customer’s
        requirements, that the operation of the Service will be uninterrupted or error free, that
        all defects in the Service will be corrected, or that the Service will be available in all
        languages or all countries. THE SERVICE IS PROVIDED “AS IS.” EXCEPT AS EXPRESSLY PROVIDED
        HEREIN, Trend MAKES NO WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR
        OTHERWISE, AND SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING BUT NOT LIMITED TO
        ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OR FITNESS FOR A PARTICULAR PURPOSE, TO
        THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. SPECIFICALLY, THIRD PARTY CONTENT AND TEST
        FEATURES ARE PROVIDED “AS IS,” EXCLUSIVE OF ANY WARRANTY. EACH PARTY DISCLAIMS ALL LIABILITY
        AND INDEMNIFICATION OBLIGATIONS FOR ANY HARM OR DAMAGES CAUSED BY ANY THIRD PARTY NETWORKING
        OR HOSTING PROVIDERS OR THIRD PARTY PRODUCTS. <br /> <br />
      </p>

      <h2 className="white mb-2 text-white" style={{ margin: '10px 0 10px' }}>
        9. LIMITATION OF LIABILITY
      </h2>

      <p className="type-light" style={{ color: '#a6a6a6', lineHeight: '25px' }}>
        <b className="text-white">9.1 Limitation of Liability.</b> IN NO EVENT WILL EITHER PARTY'S
        AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT, WHETHER IN CONTRACT, TORT
        OR UNDER ANY OTHER THEORY OF LIABILITY, EXCEED THE TOTAL AMOUNT PAID BY CUSTOMER HEREUNDER
        IN THE 12 MONTHS PRECEDING THE INCIDENT GIVING RISE TO THE LIABILITY. THE FOREGOING
        LIMITATIONS WILL NOT APPLY TO CUSTOMER’S PAYMENT OBLIGATIONS. <br /> <br />
        <b className="text-white">9.2. Exclusion of Consequential and Related Damages.</b> IN NO
        EVENT WILL EITHER PARTY HAVE ANY LIABILITY TO THE OTHER PARTY FOR ANY LOST PROFITS OR
        REVENUES OR FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL, COVER OR PUNITIVE DAMAGES,
        HOWEVER CAUSED, WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF LIABILITY, AND
        WHETHER THE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING WILL
        NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW. <br /> <br />
      </p>

      <h2 className="white mb-2 text-white" style={{ margin: '10px 0 10px' }}>
        10. US. GOVERNMENT MATTERS
      </h2>

      <p className="type-light" style={{ color: '#a6a6a6', lineHeight: '25px' }}>
        <b className="text-white">10.1.</b> Each party represents that it is not named on any United
        States government list of persons or entities restricted from doing business with any United
        States company. Customer shall not directly or indirectly access or use the Service in
        violation of any United States or international export embargo, prohibition or restriction.{' '}
        <br /> <br />
        <b className="text-white">10.2. Terms for U.S. Government Customers.</b> This section
        applies only to Customers that are U.S. government entities subject to the cited regulations{' '}
        <b className="text-white">(“U.S. Government Customers”)</b>. The Service is a “commercial
        item” (as defined in 48 C.F.R. 2.101) and involves the use of "commercial computer software"
        and "commercial computer software documentation" (as used in 48 C.F.R. 12.212). All U.S.
        Government Customers acquire subscriptions to the Service only as a “commercial item” and
        only with those rights that are granted to all other end-users pursuant to the terms and
        conditions of this Agreement, consistent with 48 C.F.R. 12.212 and 48 C.F.R. 227.72021
        through 227.72024. <br /> <br />
      </p>

      <h2 className="white mb-2 text-white" style={{ margin: '10px 0 10px' }}>
        11. GENERAL TERMS
      </h2>

      <p
        className="type-light"
        style={{ color: '#a6a6a6', lineHeight: '25px', marginBottom: '50px' }}
      >
        <b className="text-white">11.1. Dispute Resolution; Governing Law.</b> The parties shall use
        good faith, reasonable efforts to resolve any dispute before initiating legal action. The
        laws of the State of Texas, excluding choice of law principles, govern this Agreement.{' '}
        <br /> <br />
        <b className="text-white">11.2. Notices.</b> Notices under this Agreement must be in writing
        and will be considered given upon: (i) delivery by traceable courier or mail (delivery
        confirmation/ return receipt requested); or (ii) the second business day after sending by
        email. Notices to Trend should be sent to legal@trend.io or to Trend’s Legal Department at
        the address specified above. Billing notices and notices relating to this Agreement will be
        sent to the contacts designated by Customer on the Order Page. <br /> <br />
        <b className="text-white">11.3. Customer References.</b> During and after the Term, Trend
        may include Customer’s name, logo, Content and success stories in Trend’s website, press
        releases, promotional and sales literature, and lists of customers. <br /> <br />
        <b className="text-white">11.4. Force Majeure.</b> Neither party will be responsible for
        failure or delay of performance if caused by an event outside the reasonable control of the
        obligated party, including but not limited to an electrical, internet, or telecommunication
        change or outage not caused by the obligated party; government restrictions; or illegal acts
        of third parties. Each party will use reasonable efforts to mitigate the effect of a force
        majeure event. <br /> <br />
        <b className="text-white">11.5. Assignment.</b> Neither party may assign any of its rights
        or obligations under this Agreement without the other party’s prior written consent (not to
        be unreasonably withheld), except either party may assign this Agreement in its entirety
        without the other party’s consent to its affiliate or as part of a merger, acquisition,
        corporate reorganization, or sale of all or substantially all its assets. <br /> <br />
        <b className="text-white">11.6. Relationship of the Parties.</b> The parties are independent
        contractors. This Agreement does not create a partnership, franchise, joint venture, agency,
        fiduciary or employment relationship between the parties. <br /> <br />
        <b className="text-white">11.7. Waiver.</b> No failure or delay by either party to exercise
        any right under this Agreement will constitute a waiver of that right, unless expressly
        stated in this Agreement.
        <br /> <br />
        <b className="text-white">11.8. Severability.</b> If any provision of this Agreement is held
        by a court of competent jurisdiction to be invalid or unenforceable, the provision will be
        modified to the minimum extent necessary to make it enforceable. The provision will be
        disregarded only if such modification is not possible or is prohibited by law. The remaining
        provisions of this Agreement will remain in effect.
        <br /> <br />
        <b className="text-white">11.9.</b> Order of Precedence. If there is a conflict or
        inconsistency between any Order Form and this Service Agreement, the Order Form will
        control.
        <br /> <br />
        <b className="text-white">11.10. Entire Agreement.</b> This Agreement is the entire
        agreement between the parties and supersedes all prior and contemporaneous agreements,
        proposals or representations, written or oral, concerning its subject matter. No change to
        any provision of this Agreement or any Order Page will be effective unless in writing and
        signed by an authorized signatory of the party against whom the change is asserted. No terms
        in Customer’s purchase order or other order documents (excluding Order Forms) will be
        incorporated into this Agreement, regardless of any terms to the contrary, and Trend
        expressly rejects all such terms.
        <br /> <br />
        <b className="text-white">11.11. Headings.</b> Headings are for reference only and do not
        affect the meaning or interpretation of this Agreement.
        <br /> <br />
        <b className="text-white">11.12. Signature Authority.</b> The person signing this Agreement
        represents that they have authority to bind Customer to the terms of this Agreement.
        <br /> <br />
        <b className="text-white">11.13. Counterparts.</b> This Agreement may be executed in one or
        more counterparts. Each counterpart is an original. All counterparts together form one
        document.
      </p>
    </div>
  </Row>
);

export default TermsOfService;
