import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Table, Button, notification } from 'antd';
import { IMPRESSIONS } from 'constants/posts';
import CurrencyFormat from 'react-currency-format';
import { ReactComponent as IconDownload } from 'images/icon-download.svg';
import InfluencerAvatar from 'components/Common/InfluencerAvatar';
import PostAvatar from 'components/Common/PostAvatar';
import { postResourceDownloader } from '../../Post/PostDownloader';
import { usePostTypeFilter } from './PostTypeFilter';

const PostAction = ({ post, postUID } = {}, { influencer }) => (
  <div>
    <Button
      onClick={async () => {
        try {
          await postResourceDownloader(post, postUID, influencer.username);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
          notification.warning({
            message: 'Invalid Resource',
            description: alert,
            duration: 2
          });
        }
      }}
      shape="circle"
      style={{ margin: '0 5px 0 5px' }}
    >
      <IconDownload
        style={{
          position: 'relative',
          top: '2px',
          width: '15px',
          height: '15px'
        }}
      />
    </Button>
    <a href={post.link} target="_blank" rel="noopener noreferrer">
      <Button icon="link" shape="circle" />
    </a>
  </div>
);

const ThousandSeparator = num => (
  <CurrencyFormat value={num} displayType="text" thousandSeparator />
);

const columns = [
  {
    title: 'INFLUENCER',
    dataIndex: 'influencer',
    key: 'influencer',
    render: influencer => <InfluencerAvatar influencer={influencer} />
  },
  {
    title: 'POST',
    dataIndex: 'post',
    align: 'right',
    key: 'post',
    render: post => <PostAvatar postDetails={post} />,
    width: 50
  },
  {
    title: 'LIKES',
    dataIndex: 'likes',
    key: 'likes',
    align: 'right',
    render: ThousandSeparator
  },
  {
    title: 'COMMENTS',
    dataIndex: 'comments',
    key: 'comments',
    align: 'right',
    render: ThousandSeparator
  },
  {
    title: 'IMPRESSIONS',
    dataIndex: 'impressions',
    key: 'impressions',
    align: 'right',
    render: ThousandSeparator
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    align: 'right',
    render: PostAction
  }
];

const ListLayout = ({ postIds, posts, influencers, isFetching }) => {
  const types = usePostTypeFilter();

  const filteredPost = postIds.filter(postUID => types.includes(posts[postUID].type));
  const postDataSource = filteredPost.map((postUID, i) => {
    const { influencerUID, ...post } = posts[postUID];
    const influencer = influencers[influencerUID] && influencers[influencerUID].details;

    return {
      key: `${postUID}-${i}`,
      influencer,
      post,
      likes: (post.likes || 0).toLocaleString(),
      comments: (post.comments || 0).toLocaleString(),
      impressions: ((post.likes || 0) * IMPRESSIONS).toLocaleString(),
      action: {
        postUID,
        post
      }
    };
  });

  return (
    <div>
      <Table
        loading={isFetching}
        columns={columns}
        dataSource={postDataSource}
        pagination={false}
        className="trend-table"
      />
    </div>
  );
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { campaignId }
    }
  }
) => ({
  posts: state.entities.posts.byId,
  postIds: state.ui.campaignPosts[campaignId],
  influencers: state.entities.influencers.byId,
  isFetching: state.entities.posts.isFetching
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(ListLayout);
