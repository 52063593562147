import React from 'react';
import Container from '../../components/Common/Container';
import CampaignList from '../../components/CampaignList';

const RecentCampaignPage = () => {
  return (
    <div id="recent-campaign-page">
      <Container width={733}>
        <h1>Example Campaigns</h1>
        <CampaignList />
      </Container>
    </div>
  );
};

export default RecentCampaignPage;
