import React, { useState } from 'react';
import { Modal, Button, Row, Col, Icon, Input } from 'antd';
import { firebase } from '../../../lib/Firebase';
import './AdminCampaignModal.less';
import { ReactComponent as BackIcon } from '../../../images/back-arrow-white.svg';
import { ReactComponent as CloseIcon } from '../../../images/close-x.svg';

const { TextArea } = Input;

const EmailModal = ({ visible, influencerUIDs = [], onSuccess, onBack, onClose }) => {
  const [message, setMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);

  function handleChange(e) {
    setMessage(e.target.value);
  }

  async function handleClick() {
    setSubmitting(true);
    try {
      await firebase.sendTrendEmailMessage(influencerUIDs, message);
      setMessage('');
      setSubmitting(false);
      onSuccess();
    } catch (e) {
      console.log(e);
    }
  }

  function handleBack() {
    onBack();
  }

  function handleClose() {
    onClose();
  }

  return (
    <Modal className="admin-compose-email" visible={visible} closable={false} footer={false}>
      <div className="modal-header">
        <Button className="back" type="link" onClick={handleBack}>
          <Icon component={BackIcon} />
        </Button>
        <Button className="close" type="link" onClick={handleClose}>
          <Icon component={CloseIcon} />
        </Button>
        Sending to {influencerUIDs.length} Influencers
      </div>
      <div className="modal-body">
        <p>
          Enter your email below. This email will automatically add the influencers first name. Make
          sure to add a signature at the end.
        </p>
        <h1>Hello &quot;X&quot;,</h1>
        <TextArea placeholder="Enter your message here." onChange={handleChange} value={message} />
      </div>
      <div className="modal-footer">
        <Row justify="space-between">
          <Col span={12} className="text-left" onClick={handleBack}>
            <Button>Cancel</Button>
          </Col>
          <Col span={12} className="text-right">
            <Button type="primary" onClick={handleClick} loading={submitting}>
              Confirm Approval
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default EmailModal;
