import { firebase } from '..';

export default function(filters = {}) {
  let query = firebase.firestore.collection('influencers').where('isSuspended', '==', false);

  if (filters.state) {
    query = query.where('state', '==', filters.state);
  }

  if (filters.search) {
    query = query.where('usernameLow', '==', filters.search);
  }

  if (filters.star) {
    query = query.where('star', '==', filters.star);
  }

  if (filters.type) {
    query = query.where('isVIP', '==', filters.type === 'vip');
  }

  if (filters.gender) {
    query = query.where('gender', '==', filters.gender);
  }

  if (filters.lastLoginDate) {
    query = query

      .where('lastLoginDate', '>', filters.lastLoginDate)
      .orderBy('lastLoginDate', 'desc');
  } else if (filters.completedPartnershipCount) {
    console.log(filters.completedPartnershipCount);
    query = query
      .where('completedPartnershipCount', '<', filters.completedPartnershipCount)
      .orderBy('completedPartnershipCount', 'desc');
  } else {
    query = query.orderBy('lastAppliedDate', 'desc');
  }

  return query;
}
