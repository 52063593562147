import React from 'react';
import { Row, Col, Layout, Card, Avatar } from 'antd';
import CurrencyFormat from 'react-currency-format';
import { ReactComponent as IconDownload } from 'images/icon-download.svg';
import UpperCaseLabel from 'components/Common/UpperCaseLabel';
import { IMPRESSIONS } from 'constants/posts';

const { Content } = Layout;
const { Meta } = Card;

const PostCard = ({ postUID, post, influencer, onClickDownloadImage }) => {
  const iconActions = [<IconDownload onClick={() => onClickDownloadImage(postUID)} />];

  return (
    <Card
      cover={(
        <img
          key="image"
          src={post.fullResImage}
          alt=""
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
        />
)}
      actions={iconActions}
      className="post-card trend-post-card"
    >
      <Meta
        avatar={<Avatar src={influencer.avatar} style={{ width: '35px', height: '35px' }} />}
        title={`@${influencer.username}`}
        description={influencer.state}
      />
      <Row className="mt-3">
        <Col span={8}>
          <h2 className="m-0 size-13px" style={{ fontStyle: 'italic' }}>
            <CurrencyFormat value={post.likes} displayType="text" thousandSeparator />
          </h2>
          <UpperCaseLabel>Likes</UpperCaseLabel>
        </Col>
        <Col span={8}>
          <h2 className="m-0 size-13px" style={{ fontStyle: 'italic' }}>
            <CurrencyFormat value={post.comments} displayType="text" thousandSeparator />
          </h2>
          <UpperCaseLabel>Comments</UpperCaseLabel>
        </Col>
        <Col span={8}>
          <h2 className="m-0 size-13px" style={{ fontStyle: 'italic' }}>
            <CurrencyFormat value={post.likes * IMPRESSIONS} displayType="text" thousandSeparator />
          </h2>
          <UpperCaseLabel>Impressions</UpperCaseLabel>
        </Col>
      </Row>
    </Card>
  );
};

const PostsGridLayout = ({ posts, onClickDownloadImage }) => {
  const grids = Object.keys(posts).map(postUID => {
    const { post, influencerUID, influencer } = posts[postUID];

    return (
      <Col md={8} sm={12} xs={24} key={postUID} style={{ marginBottom: '10px' }}>
        <div>
          <PostCard
            onClickDownloadImage={onClickDownloadImage}
            influencerUID={influencerUID}
            postUID={postUID}
            post={post}
            influencer={influencer}
          />
        </div>
      </Col>
    );
  });

  return (
    <Content style={{ background: '#fff', minHeight: 500 }}>
      <Row type="flex" justify="space-around">
        <Col span={24}>
          <Row gutter={16}>{grids}</Row>
        </Col>
      </Row>
    </Content>
  );
};

export default PostsGridLayout;
