export const CURRENT_CAMPAIGN = 'CURRENT_CAMPAIGN';
export const SET_NEW_MESSAGES_COUNT = 'SET_NEW_MESSAGES_COUNT';
export const NEEDS_SHIPPING_COUNT = 'NEEDS_SHIPPING_COUNT';
export const POST_TO_APPROVE_COUNT = 'POST_TO_APPROVE_COUNT';
export const APPLIED_INFLUENCER_COUNT = 'APPLIED_INFLUENCER_COUNT';
export const SHIPPED_COUNT = 'SHIPPED_COUNT';
export const SET_CAMPAIGN_DASHBOARD_READY = 'SET_CAMPAIGN_DASHBOARD_READY';
export const SET_CAMPAIGN_INFLUENCER_LIST = 'SET_CAMPAIGN_INFLUENCER_LIST';
export const SET_CAMPAIGN_APPLICATION_LIST = 'SET_CAMPAIGN_APPLICANT_LIST';
export const REMOVE_CAMPAIGN_APPLICANT = 'REMOVE_CAMPAIGN_APPLICANT';
export const SET_CAMPAIGN_LISTENING = 'SET_CAMPAIGN_LISTENING';

const defaultState = {
  currentCampaign: {},
  byCampaignId: {},
  needsShippingCount: 0,
  postToApproveCount: 0,
  newMessagesCount: 0,
  appliedInfluencerCount: 0,
  shippedCount: 0,
  listenedInfluencerUIDs: []
};

const setCampaignInfo = (byCampaignState = {}, campaignUID, stat = {}) => ({
  ...byCampaignState,
  [campaignUID]: {
    ...byCampaignState[campaignUID],
    ...stat
  }
});

const removeCampaignApplicant = (byCampaignState = {}, campaignUID, influencerUIDs = []) => ({
  ...byCampaignState,
  [campaignUID]: {
    ...byCampaignState[campaignUID],
    applicantUIDs: [
      ...byCampaignState[campaignUID].applicantUIDs.filter(x => !influencerUIDs.includes(x))
    ]
  }
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case CURRENT_CAMPAIGN:
      return {
        ...state,
        currentCampaign: action.campaign
      };

    case SET_NEW_MESSAGES_COUNT:
      return {
        ...state,
        newMessagesCount: action.newMessageCount,
        byCampaignId: setCampaignInfo(state.byCampaignId, action.campaignUID, {
          newMessagesCount: action.newMessageCount
        })
      };

    case NEEDS_SHIPPING_COUNT:
      return {
        ...state,
        needsShippingCount: action.needsShippingCount,
        byCampaignId: setCampaignInfo(state.byCampaignId, action.campaignUID, {
          needsShippingCount: action.needsShippingCount
        })
      };

    case POST_TO_APPROVE_COUNT:
      return {
        ...state,
        postToApproveCount: action.postToApproveCount,
        byCampaignId: setCampaignInfo(state.byCampaignId, action.campaignUID, {
          postToApproveCount: action.postToApproveCount
        })
      };

    case APPLIED_INFLUENCER_COUNT:
      return {
        ...state,
        appliedInfluencerCount: action.appliedInfluencerCount,
        byCampaignId: setCampaignInfo(state.byCampaignId, action.campaignUID, {
          appliedInfluencerCount: action.appliedInfluencerCount
        })
      };

    case SHIPPED_COUNT:
      return {
        ...state,
        shippedCount: action.shippedCount,
        byCampaignId: setCampaignInfo(state.byCampaignId, action.campaignUID, {
          shippedCount: action.shippedCount
        })
      };

    case SET_CAMPAIGN_DASHBOARD_READY:
      return {
        ...state,
        byCampaignId: setCampaignInfo(state.byCampaignId, action.campaignUID, {
          ready: action.ready
        })
      };

    case SET_CAMPAIGN_INFLUENCER_LIST:
      return {
        ...state,
        byCampaignId: setCampaignInfo(state.byCampaignId, action.campaignUID, {
          influencerUIDs: action.influencerUIDs
        })
      };

    case SET_CAMPAIGN_APPLICATION_LIST:
      return {
        ...state,
        byCampaignId: setCampaignInfo(state.byCampaignId, action.campaignUID, {
          applicantUIDs: action.influencerUIDs
        })
      };

    case REMOVE_CAMPAIGN_APPLICANT:
      return {
        ...state,
        byCampaignId: removeCampaignApplicant(state.byCampaignId, action.campaignUID, [
          ...action.influencerUIDs
        ])
      };

    case SET_CAMPAIGN_LISTENING:
      return {
        ...state,
        byCampaignId: setCampaignInfo(state.byCampaignId, action.campaignUID, {
          listening: action.listening
        })
      };

    default:
      return state;
  }
};
