/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import moment from 'moment';
import { firebase } from '../../../lib/Firebase';
import { addReviews } from '../../../actions/reviews';
import { cancellablePromise } from '../../../lib/cancellablePromise';

const initialState = {
  reviews: {},
  loadmore: true,
  fetching: false,
  paginate: {
    count: 1,
    lastSnap: null
  }
};

class Reviews extends Component {
  state = initialState;

  componentDidMount() {
    this.retrieve();
  }

  componentDidUpdate(prevProps) {
    const { influencerUID } = this.props;
    if (prevProps.influencerUID !== influencerUID) {
      this.setState(initialState);
      this.promises = cancellablePromise(this.retrieve());
    }
  }

  loadMore = () => {
    this.setState(state => ({
      ...state,
      fetching: true,
      paginate: {
        ...state.paginate,
        count: state.paginate.count === 1 ? 4 : state.paginate.count + 2
      }
    }));

    setTimeout(async () => {
      await this.retrieve();

      this.setState(state => {
        const {
          reviews: { byInfluencerUID },
          influencerUID
        } = this.props;

        const reviewUIDs = byInfluencerUID[influencerUID] || [];

        return {
          ...state,
          fetching: false,
          loadmore: reviewUIDs.length >= state.paginate.count
        };
      });
    }, 1000);
  };

  async retrieve() {
    const {
      influencerUID,
      dispatch,
      reviews: { byInfluencerUID }
    } = this.props;
    const { paginate } = this.state;
    const reviewUIDs = byInfluencerUID[influencerUID] || [];

    if (reviewUIDs.length !== 0 && reviewUIDs.length >= paginate.count) {
      return;
    }

    let q = firebase
      .reviews()
      .where('influencerUID', '==', influencerUID)
      .orderBy('completedAt', 'desc');

    if (paginate.lastSnap) {
      q = q.startAfter(paginate.lastSnap);
    }

    const reviewsSnap = await q.limit(1).get();
    if (reviewsSnap.empty) {
      return;
    }

    const [reviewSnap] = reviewsSnap.docs;
    const review = reviewSnap.data();

    if (review.comments) {
      dispatch(addReviews({ [reviewSnap.id]: review }));

      this.setState(state => ({
        paginate: {
          ...state.paginate,
          lastSnap: reviewSnap
        }
      }));
    }
  }

  compomentWillUnmount() {
    this.promises.cancel();
  }

  render() {
    const { loadmore, fetching, paginate } = this.state;
    const {
      reviews: { byInfluencerUID, byId },
      influencerUID
    } = this.props;

    const reviewUIDs = byInfluencerUID[influencerUID] || [];

    if (reviewUIDs.length <= 0) {
      return <div />;
    }

    const count = paginate.count !== 1 ? paginate.count - 1 : paginate.count;

    const influencerReviews = reviewUIDs.slice(0, count).map(reviewUID => {
      const review = byId[reviewUID];
      return (
        <div key={reviewUID} className="influencer-review">
          <div className="review-content">
            <p className="review-comment">{review.comments}</p>
            <div className="review-reviewer">
              <span className="reviewer-name">{review.reviewer}</span> |{' '}
              <span className="reviewed-date">{moment(review.completedAt).format('MMMM DD')}</span>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className="influencer-reviews">
        <div className="influencer-info-label">ADDITIONAL REVIEWS</div>
        <div className="influencer-reviews-wrapper">{influencerReviews}</div>
        {loadmore && (
          <div className="load-more-reviews">
            <Button loading={fetching} block onClick={this.loadMore}>
              MORE REVIEWS
            </Button>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, { influencerUID }) => ({
  influencer: state.entities.influencers.byId[influencerUID],
  reviews: state.entities.reviews
});

export default connect(mapStateToProps)(Reviews);
