import React from 'react';
import moment from 'moment';
import { compose } from 'recompose';
import TextTruncate from 'react-text-truncate';
import { Row, Avatar, Table } from 'antd';
import { withRouter } from 'react-router-dom';
import MinLayout from 'components/Common/MinLayout';
import InfluencerImg1 from 'images/demo/influencer-1.png';
import InfluencerImg2 from 'images/demo/influencer-2.png';
import InfluencerImg3 from 'images/demo/influencer-3.png';
import MessagesBanner from './MessagesBanner';
import DemoHeaderNote from './DemoHeaderNote';

const columns = [
  {
    dataIndex: 'influencerAvatar',
    key: 'influencerAvatar',
    render: img => <Avatar src={img} size={50} />,
    className: 'avatar'
  },
  {
    dataIndex: 'influencer',
    key: 'influencer',
    className: 'author',
    render: (username, record) => (
      <div className="ml-2">
        <p className="type-bold">{`@${username}`}</p>
        <p>{record.influencerState}</p>
      </div>
    )
  },
  {
    dataIndex: 'message',
    key: 'message',
    className: 'message-col',
    align: 'center',
    render: message => (
      <div style={{ height: '30px' }} align="center">
        <div style={{ width: '260px', textAlign: 'left' }}>
          <TextTruncate text={message} line={2} />
        </div>
      </div>
    ),
    onCell: record => ({
      onClick: () => {
        record.history.push(`messages/${record.key}`);
      }
    })
  },
  {
    dataIndex: 'time',
    key: 'time',
    className: 'time'
  },
  {
    dataIndex: 'count',
    key: 'count',
    className: 'count',
    render: hasUnread =>
      hasUnread && (
        <Avatar
          style={{
            backgroundColor: '#ffb917',
            verticalAlign: 'middle',
            marginLeft: '17px'
          }}
        >
          1
        </Avatar>
      )
  }
];

const dataSource = [
  {
    key: 'ft-123456789',
    influencerAvatar: InfluencerImg1,
    influencer: 'demo-influencer1',
    influencerState: 'New York, NY',
    message:
      'Is there any way to check the shipping status? I’m traveling quite frequently the next couple of months and want to make sure I can bring the product for my shoot! Thanks!',
    time: moment().fromNow(),
    count: true
  },
  {
    key: 'ft-987654321',
    influencerAvatar: InfluencerImg2,
    influencer: 'demo-influencer2',
    influencerState: 'New York, NY',
    message: 'Sounds great! I’m looking forward to collaborating',
    time: moment().fromNow(),
    count: true
  },
  {
    key: 'ft-987654321123',
    influencerAvatar: InfluencerImg3,
    influencer: 'demo-influencer3',
    influencerState: 'New York, NY',
    message:
      "Congratulations! You have been chosen to receive Grape sleeves for western hats We're excited to work with you and can't wait to see what you create. Please remember to follow the campaign guidelines and reach out with any questions.",
    time: moment().fromNow(),
    count: false
  }
];

const Messages = props => {
  const { history } = props;

  dataSource.forEach((data, i) => {
    dataSource[i].history = history;
  });

  return (
    <Row
      gutter={64}
      style={{
        height: '100%',
        marginBottom: '40px',
        background: '#ffffff'
      }}
      type="flex"
      justify="space-around"
    >
      <DemoHeaderNote />
      <MinLayout>
        <Table
          showHeader={false}
          className="trend-table"
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
        <MessagesBanner />
      </MinLayout>
    </Row>
  );
};

export default compose(withRouter)(Messages);
