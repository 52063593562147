export const ADMIN_ADD_INFLUENCER = 'ADMIN_ADD_INFLUENCER';
export const ADMIN_LISTEN_INFLUENCERS = 'ADMIN_LISTEN_INFLUENCER';
export const ADMIN_UNLISTEN_INFLUENCERS = 'ADMIN_UNLISTEN_INFLUENCERS';
export const ADMIN_PUSH_INFLUENCER = 'ADMIN_PUSH_INFLUENCER';
export const ADMIN_UPDATE_LAST_INFLUENCER_DOC = 'ADMIN_UPDATE_LAST_INFLUENCER_DOC';
export const ADMIN_RESET_INFLUENCERS = 'ADMIN_RESET_INFLUENCERS';
export const ADMIN_REMOVE_INFLUENCER = 'ADMIN_REMOVE_INFLUENCER';

const initialState = {
  lastInfluencerDoc: null,
  influencers: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_ADD_INFLUENCER:
      return {
        ...state,
        influencers: [...new Set([...state.influencers, action.payload])]
      };

    case ADMIN_PUSH_INFLUENCER:
      return {
        ...state,
        influencers: [...new Set([...state.influencers, action.payload])]
      };
    case ADMIN_LISTEN_INFLUENCERS:
      return { ...state, listening: true };
    case ADMIN_UNLISTEN_INFLUENCERS:
      return { ...state, listening: false };
    case ADMIN_UPDATE_LAST_INFLUENCER_DOC:
      return { ...state, lastInfluencerDoc: action.payload };
    case ADMIN_REMOVE_INFLUENCER:
      return { ...state, influencers: [...state.influencers.filter(x => x !== action.payload)] };

    case ADMIN_RESET_INFLUENCERS:
      return { ...state, influencers: [] };

    default:
      return state;
  }
};
