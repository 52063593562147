import React, { Component } from 'react';
import { Avatar, Icon, Modal } from 'antd';
import { ReactComponent as PlayButtonSVG } from '../../images/play-btn.svg';
import Video from '../Post/Video';

class PostAvatar extends Component {
  state = {
    showModal: false,
    playVideo: false
  };

  constructor(props) {
    super(props);

    const { postDetails } = this.props;
    const { standardResolutionImage, fullResImage } = postDetails;
    let postImg = standardResolutionImage;

    if (!standardResolutionImage || standardResolutionImage === 'none') {
      postImg = fullResImage;
    }

    if (postDetails.type === 'story') {
      postImg = postDetails.thumbnail;
    }

    this.state = {
      postImg
    };
  }

  onAvatarClick = (link, type, event) => {
    if (!link) {
      return;
    }

    if (type === 'story') {
      this.toggleModal(event);
    } else {
      const win = window.open(link, '_blank');
      win.focus();
    }
  };

  toggleModal = e => {
    e.stopPropagation();

    const { showModal, playVideo } = this.state;
    this.setState({ showModal: !showModal, playVideo: !playVideo });
  };

  handleError = () => {
    const {
      postDetails: { fullResImage }
    } = this.props;
    const { postImg } = this.state;
    if (fullResImage !== postImg) {
      this.setState({ postImg: fullResImage });
    }
    // eslint-disable-next-line no-console
    console.log('unable to retrieve image');
  };

  render() {
    const { postDetails } = this.props;
    const { postImg, showModal, playVideo } = this.state;
    const type = postDetails.type || 'image';
    const link = type === 'story' ? postDetails.highResVideo : postDetails.link;

    return (
      <div>
        {/* eslint-disable-next-line */}
        <a
          rel="noopener noreferrer"
          className="post-avatar"
          onClick={e => this.onAvatarClick(link, type, e)}
        >
          {type !== 'image' && postImg && (
            <Icon component={PlayButtonSVG} className="play-button" />
          )}
          <Avatar src={postImg} size={50} shape="square" onError={this.handleError} />
          <div className={`story-type type-${type}`}>{type} post</div>
        </a>
        <Modal
          className="story-modal"
          visible={showModal}
          onCancel={this.toggleModal}
          footer={null}
          bodyStyle={{ padding: '0px' }}
          width={620}
          centered
          maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.65)' }}
        >
          <Video post={postDetails} playing={playVideo} videoStyle={{ width: '100%' }} />
        </Modal>
      </div>
    );
  }
}

export default PostAvatar;
