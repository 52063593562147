import React, { useState } from 'react';
import { Row, AutoComplete, Icon } from 'antd';
import { useSelector } from 'react-redux';
import debounce from '../../../components/Helpers/useDebounce';

const { Option } = AutoComplete;
const SearchBrands = ({ onChange }) => {
  const [currentSearch, setCurrentSearch] = useState('');
  const [options, setOptions] = useState([]);
  const [searching, setSearching] = useState(false);
  const brandSearches = useSelector(state => state.entities.brandSearch);

  function handleChange(value) {
    setSearching(true);
    if (onChange) {
      onChange(value);
      setCurrentSearch(value);
      setSearching(false);
    }
  }

  function handleSearch(value = '') {
    setSearching(true);
    const data = brandSearches.all.filter(x => {
      return (
        brandSearches.byId[x] &&
        brandSearches.byId[x].name &&
        brandSearches.byId[x].name.toLowerCase().includes(value.toLowerCase())
      );
    });

    setOptions(data);
    setSearching(false);
  }

  const list = options.map(x => (
    <Option key={x} value={x}>
      {brandSearches.byId[x].name}
    </Option>
  ));

  return (
    <Row className="search" type="flex" align="middle">
      {searching ? (
        <Icon type="loading" style={{ padding: '7px' }} />
      ) : (
        <Icon type="search" style={{ padding: '7px' }} />
      )}
      <AutoComplete
        allowClear
        onSearch={debounce(handleSearch, 500)}
        onSelect={debounce(handleChange, 500)}
        onChange={val => !val && currentSearch && handleChange(null)}
        placeholder="Search Brand"
      >
        {list}
      </AutoComplete>
    </Row>
  );
};

export default SearchBrands;
