export const ADD_CAMPAIGN_SUMMARY_INFLUENCERS = 'ADD_CAMPAIGN_SUMMARY_INFLUENCERS';

const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ADD_CAMPAIGN_SUMMARY_INFLUENCERS: {
      const { campaignUID, influencers } = action.payload;

      return {
        ...state,
        [campaignUID]: {
          summary: {
            ...state[campaignUID].summary,
            influencers: [...influencers],
          },
        },
      };
    }

    default:
      return state;
  }
};
