import React from 'react';
import { Row, Col, Radio } from 'antd';
import { ReactComponent as IconStarter } from 'images/starter-copy-5.svg';
import { ReactComponent as IconBusiness } from 'images/icon-subscription-business.svg';
import { ReactComponent as IconProfessional } from 'images/pro-copy-3.svg';
import CurrencyFormat from 'react-currency-format';

const planIconFor = planName => {
  switch (planName) {
    case 'Starter':
      return <IconStarter style={{ verticalAlign: 'middle' }} alt="Subscription Type Icon" />;
    case 'Business':
      return <IconBusiness style={{ verticalAlign: 'middle' }} alt="Subscription Type Icon" />;
    case 'Pro':
      return <IconProfessional style={{ verticalAlign: 'middle' }} alt="Subscription Type Icon" />;
    default:
      return null;
  }
};

const PlanRadio = ({ id, plan, existingPlan }) => {
  return (
    <Radio
      value={id}
      key={id}
      style={{ display: 'block' }}
      className={`plan-radio subscription-radio ${existingPlan === id &&
        !plan.legacy &&
        'current-plan'} ${plan.popular ? 'plan-radio-popular' : ''}`}
    >
      <Row type="flex" align="middle">
        <Col md={6} xs={24}>
          <div className="plan-icon">{planIconFor(plan.nickname)}</div>
          <h4
            className="text-white size-16px type-sbold plan-name"
            style={{ marginBottom: '4px', display: 'inline', verticalAlign: 'middle' }}
          >
            {`${plan.nickname}`}
            <span className="col-type text-gray-darkest size-11px">Plan Type</span>
          </h4>
        </Col>
        <Col md={6} xs={24} className="plan-duration">
          <strong className="text-white size-16px type-sbold d-block">
            {plan.duration} months
          </strong>
          <span className="col-type text-gray-darkest size-11px">Plan Duration</span>
        </Col>
        <Col md={3} xs={24} className="plan-initial-credits">
          <strong className="text-white size-16px type-sbold d-block">{plan.initialCredits}</strong>
          <span className="col-type text-gray-darkest size-11px">influencer sign up bonus</span>
        </Col>
        <Col
          md={existingPlan === id && !plan.legacy ? 8 : 6}
          xs={24}
          style={{
            lineHeight: '18px'
          }}
          className="plan-cost"
        >
          <strong className="text-white size-15px type-bold d-block">
            $
            <CurrencyFormat value={plan.amount} displayType="text" thousandSeparator />
          </strong>
          <span className="text-gray-darkest size-11px">Billed Monthly</span>
        </Col>
      </Row>
    </Radio>
  );
};

export default PlanRadio;
