import React, { Component } from 'react';
import { Select, Row } from 'antd';
import { connect } from 'react-redux';
import { setCategory, setLocation, setInfluencerType } from 'actions/ui/applicationsFilter';
import { getCategories } from 'actions/categories';
import STATES from 'constants/states';
import { UpCaseFirstLetter } from 'lib/Common';
import { INFLUENCER_CATEGORIES } from 'constants/categories';

const { Option } = Select;

class FilterApplications extends Component {
  componentWillMount() {
    const { getCategories: categories } = this.props;
    categories();
  }

  onChangeLocation = v => {
    const { dispatchOnChangeLocation } = this.props;
    const location = v === 'All' ? undefined : v;
    dispatchOnChangeLocation(location);
  };

  onChangeInfluencerType = influencerType => {
    const { dispatchOnChangeInfluencerType } = this.props;
    dispatchOnChangeInfluencerType(influencerType);
  };

  render() {
    const { onChangeCategory } = this.props;

    const categories = INFLUENCER_CATEGORIES.map(category => (
      <Option value={category} key={category}>
        {UpCaseFirstLetter(category)}
      </Option>
    ));

    const locations = Object.keys(STATES).map(stateCode => (
      <Option value={STATES[stateCode]} key={stateCode}>
        {STATES[stateCode]}
      </Option>
    ));

    return (
      <Row>
        <Select
          onChange={onChangeCategory}
          allowClear
          placeholder="Categories"
          style={{
            width: 98,
            marginRight: '12px'
          }}
          className="trend-select"
          showArrow={false}
        >
          {categories}
        </Select>
        <Select
          onChange={this.onChangeLocation}
          allowClear
          placeholder="Location"
          style={{
            width: 98,
            marginRight: '12px'
          }}
          className="trend-select"
          showArrow={false}
        >
          {locations}
        </Select>
        <Select
          onChange={this.onChangeInfluencerType}
          allowClear
          className="trend-select"
          placeholder="All Creators"
          showArrow={false}
          style={{
            width: 100,
            marginRight: '12px'
          }}
        >
          <Option value="all">All Creators</Option>
          <Option value="standard">Standard</Option>
          <Option value="vip">VIP</Option>
        </Select>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.entities.categories.categories
});

const mapDispatchToProps = dispatch => ({
  onChangeCategory: category => dispatch(setCategory(category)),
  dispatchOnChangeLocation: location => dispatch(setLocation(location)),
  dispatchOnChangeInfluencerType: influencerType => dispatch(setInfluencerType(influencerType)),
  getCategories: () => dispatch(getCategories())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterApplications);
