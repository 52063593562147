import React, { useState, useEffect } from 'react';
import { Row, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Container from '../Common/Container';
import Confetti from '../Confetti';
import { setOwnedCampaigns } from '../../actions/ui/sideNav';

const logo =
  'https://static.wixstatic.com/media/2ba213_71d75f77807444218e8b44e2e827c78e~mv2.png/v1/fill/w_218,h_40,al_c,q_80,usm_1.20_1.00_0.01/2ba213_71d75f77807444218e8b44e2e827c78e~mv2.webp';

const ManageCredits = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [particles, setParticles] = useState([1]);

  const session = useSelector(state => state.entities.session.authUserData);
  const { ownedCampaigns } = useSelector(state => state.ui.sideNav);

  useEffect(() => {
    if (session && session.uid && ownedCampaigns.length === 0) {
      dispatch(setOwnedCampaigns(session.uid));
    }
  }, [dispatch, session]);

  useEffect(() => {
    if (particles.length !== 0) {
      setTimeout(() => {
        setParticles([]);
      }, 10000);
    }
  }, [particles]);

  return (
    <div className="success-purchase">
      {particles.map(id => {
        return <Confetti key={`confetti-${id}`} count={Math.floor(window.innerWidth / 10)} />;
      })}

      <Container width={700}>
        <Row type="flex" justify="center">
          <img src={logo} alt="logo" className="logo" width="70px" />
        </Row>

        <Row type="flex" justify="center" className="header">
          Congratulations!
          <br />
          New credits have been added
          <br />
          to your account!
        </Row>

        <Row type="flex" justify="center" className="description">
          Now that you’ve added more credits to your account, you’ll be able to
          <br />
          work with more of our creators. Do you need to start a new campaign or
          <br />
          accept creators in an existing campaign?
        </Row>

        <Row type="flex" justify="center" className="btn-section">
          <Button
            className="start-new-campaign-btn"
            type="primary"
            block
            onClick={() => {
              history.replace('/campaigns/new');
            }}
          >
            Start A New Campaign
          </Button>

          <Button
            className="accept-creators-btn"
            type="primary"
            block
            onClick={() => {
              history.replace(`/campaigns/${ownedCampaigns[0]}/summary`);
            }}
            disabled={ownedCampaigns.length === 0}
          >
            Accept Creators For Existing Campaign
          </Button>
        </Row>
      </Container>
    </div>
  );
};

export default ManageCredits;
