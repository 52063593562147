import React from 'react';
import { withAuthUser } from 'lib/Session';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { fetchBrand } from 'actions/brands';
import { selectBrandById } from 'selectors/brands';

const WithBrand = (Component) => {
  class Brand extends React.Component {
    constructor(props) {
      super(props);

      const {
        dispatchFetchBrand,
        authUser,
      } = props;

      dispatchFetchBrand(authUser.uid);
    }

    render() {
      const { brand } = this.props;

      return (
        brand
          ? <Component brand={brand} {...this.props} />
          : null
      );
    }
  }

  const authUserUID = (ownProps) => {
    if (ownProps.authUser) {
      return ownProps.authUser.uid;
    }

    return null;
  };

  const mapStateToProps = (state, ownProps) => ({
    brand: selectBrandById(state, authUserUID(ownProps)),
  });

  const mapDispatchToProps = dispatch => ({
    dispatchFetchBrand: (brandUID, callback) => dispatch(fetchBrand(
      brandUID,
      callback,
    )),
  });

  return compose(
    withAuthUser,
    connect(mapStateToProps, mapDispatchToProps),
  )(props => <Brand {...props} />);
};

export default WithBrand;
