import {
  ADMIN_ADD_INFLUENCER,
  ADMIN_PUSH_INFLUENCER,
  ADMIN_UPDATE_LAST_INFLUENCER_DOC,
  ADMIN_REMOVE_INFLUENCER
} from '../../../../reducers/ui/admin/influencers';

export const adminAddInfluencer = influencerUID => ({
  payload: influencerUID,
  type: ADMIN_ADD_INFLUENCER
});

export const adminPushInfluencer = influencerUID => ({
  payload: influencerUID,
  type: ADMIN_PUSH_INFLUENCER
});

export const adminUpdateLastInfluencerDoc = influencerDoc => ({
  type: ADMIN_UPDATE_LAST_INFLUENCER_DOC,
  payload: influencerDoc
});

export const adminRemoveInfluencer = influencerUID => ({
  type: ADMIN_REMOVE_INFLUENCER,
  payload: influencerUID
});
