import React, { Component } from 'react';
import { Route, Switch, Link, Redirect, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Menu, Row } from 'antd';
import StoreEdit from 'components/BrandAccount/StoreEdit';
import TermsOfService from 'components/BrandAccount/TermsOfService';
import PrivacyPolicy from 'components/BrandAccount/PrivacyPolicy';
import Subscription from 'components/BrandAccount/Subscription';
import * as routes from 'constants/routes';
import { AuthUserContext } from 'lib/Session';
import SignOutButton from 'components/Signout';
import { ReactComponent as IconBack } from 'images/icon-back-arrow-white.svg';
import { ReactComponent as TrendLogoWeb } from 'images/trend-logo-web.svg';
import { connect } from 'react-redux';
import { selectBrandById } from 'selectors/brands';
import { fetchBrand } from 'actions/brands';
import BrandIntegration from './Integration';
import Notifications from './Notifications';

class BrandAccount extends Component {
  componentDidMount() {
    const { dispatchFetchBrand, authUser } = this.props;
    dispatchFetchBrand(authUser.uid);
  }

  goBack = () => {
    const { history, ownedCampaigns } = this.props;
    const noCampaigns = ownedCampaigns.length;

    if (noCampaigns > 0) {
      history.push(`/campaigns/${ownedCampaigns[noCampaigns - 1]}/summary`);
    } else {
      history.push('/campaigns/example/summary');
    }
  };

  render() {
    const { authUser: user } = this.props;

    const path = window.location.pathname.split('account/');
    const selectedKeys = path[1];

    return (
      <div>
        <div
          className="pt-7 mb-8"
          style={{
            background: '#0f0f0f',
            borderBottom: '1px solid #3c3c3c'
          }}
        >
          <div className="action-link clickable" align="center">
            <div className="back">
              <div onClick={this.goBack} role="presentation" style={{ color: '#ffb917' }}>
                <IconBack />
                <span className="white d-block type-sbold bsize-4">Back</span>
              </div>
            </div>
            <div className="sign-out">
              <AuthUserContext.Consumer>
                {authUser => authUser && <SignOutButton />}
              </AuthUserContext.Consumer>
            </div>
          </div>
          <div align="center">
            <TrendLogoWeb className="logo d-iblock" width="70px" />
            <h1 className="ht-6 pt-4 text-white">{user.email}</h1>
            <div className="pt-2">
              <Link to="/update_email" className="action-btn mr-2">
                Change Email
              </Link>
              <Link to="/change_password" className="action-btn">
                Change Password
              </Link>
            </div>
          </div>
          <div className="pt-7" align="center">
            <Row>
              <Menu
                mode="horizontal"
                className="account-menu"
                selectedKeys={[selectedKeys]}
                defaultOpenKeys={['store/edit']}
              >
                <Menu.Item key="store/edit">
                  <Link to={routes.BRAND_EDIT_PATH} replace>
                    {' '}
                    Company{' '}
                  </Link>
                </Menu.Item>
                <Menu.Item key="brand-integrations">
                  <Link to={routes.BRAND_INTEGRATIONS} replace>
                    {' Integrations '}
                  </Link>
                </Menu.Item>
                <Menu.Item key="notifications">
                  <Link to={routes.NOTIFICATIONS_PATH} replace>
                    {' Notifications '}
                  </Link>
                </Menu.Item>
                <Menu.Item key="privacy">
                  <Link to={routes.PRIVACY_PATH} replace>
                    {' '}
                    Privacy Policy{' '}
                  </Link>
                </Menu.Item>
                <Menu.Item key="tos">
                  <Link to={routes.TOS_PATH} replace>
                    {' '}
                    Terms of Service{' '}
                  </Link>
                </Menu.Item>
              </Menu>
            </Row>
          </div>
        </div>
        <Row>
          <Switch>
            <Route path={routes.BRAND_EDIT_PATH} component={StoreEdit} />
            <Route path={routes.BRAND_INTEGRATIONS} component={BrandIntegration} />
            <Route path={routes.NOTIFICATIONS_PATH} component={Notifications} />
            <Route
              path={routes.SUBSCRIPTION_SETTINGS_PATH}
              render={() => <Subscription authUser={user} />}
            />
            <Route path={routes.TOS_PATH} component={TermsOfService} />
            <Route path={routes.PRIVACY_PATH} component={PrivacyPolicy} />
            <Redirect to={routes.BRAND_EDIT_PATH} />
          </Switch>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentBrand: selectBrandById(state, ownProps.authUser.uid),
  ownedCampaigns: state.ui.sideNav.ownedCampaigns
});

const mapDispatchToProps = dispatch => ({
  dispatchFetchBrand: brandUID => {
    dispatch(fetchBrand(brandUID));
  }
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter
)(BrandAccount);
