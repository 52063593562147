import React from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import Image from './Image';
import Video from './Video';
import DownloadButton from './DownloadButton';

const Card = ({ post, influencers = {} }) => {
  if (!post) {
    return <div />;
  }

  const influencer = influencers[post.influencerUID].details;
  const { username } = influencer;
  const { likes, comments, type } = post;
  const displayResource = !type || type === 'image' ? <Image post={post} /> : <Video post={post} />;

  const stats = type !== 'story' && (
    <div className="post-card-stats">
      <div className="post-card-stat">
        <div className="post-card-stat-value">{likes}</div>
        <div className="post-card-stat-label">LIKES</div>
      </div>
      <div className="post-card-stat">
        <div className="post-card-stat-value">{comments}</div>
        <div className="post-card-stat-label">COMMENTS</div>
      </div>
    </div>
  );

  return (
    <Row className="post-card">
      <Col>
        <div className="post-card-resource">
          {displayResource}
          {stats}
        </div>
        <div className="post-card-actions">
          <DownloadButton post={post} filename={username} />
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state, { post, postUID }) => ({
  post: post || state.entities.posts.byId[postUID],
  influencers: state.entities.influencers.byId
});

export default connect(mapStateToProps)(Card);
