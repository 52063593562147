import React from 'react';
import { Col } from 'antd';

const ThinContainer = ({ children } = {}) => (
  <Col xs={22} sm={16} lg={10} md={14}>
    {children}
  </Col>
);

export default ThinContainer;
