import React from 'react';

import HeaderText from 'components/Common/HeaderText';

const oldGuide = guidelines => (
  <div className="guideline">
    <div className="text-black size-12px leading-23" style={{ whiteSpace: 'pre-line' }}>
      {guidelines}
    </div>
  </div>
);

const newGuide = postGuidelines => (
  <>
    <div className="guideline">
      <HeaderText
        bold
        className="size-13px text-black"
        style={{
          lineHeight: '0.92',
          marginBottom: '10px'
        }}
      >
        Which products will you send the influencers?
      </HeaderText>
      <div
        className="text-black size-12px leading-23"
        style={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}
      >
        {postGuidelines[0]}
      </div>
    </div>

    <div className="guideline">
      <HeaderText
        bold
        className="size-13px text-black mb-10"
        style={{
          lineHeight: '0.92',
          marginBottom: '10px'
        }}
      >
        What would you like the influencers to mention in their caption?
      </HeaderText>
      <div
        className="text-black size-12px leading-23"
        style={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}
      >
        {postGuidelines[1]}
      </div>
    </div>

    <div className="guideline">
      <HeaderText
        bold
        className="size-13px text-black mb-10"
        style={{
          lineHeight: '0.92',
          marginBottom: '10px'
        }}
      >
        What photo / video style are you looking for?
      </HeaderText>
      <div
        className="text-black size-12px leading-23"
        style={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}
      >
        {postGuidelines[2]}
      </div>
    </div>
  </>
);

const PostGuideLines = ({ campaign }) => {
  const { postGuidelines = [], guidelines } = campaign;

  return (
    <div style={{ marginTop: '31px' }} className="post-guidelines">
      <HeaderText bold className="size-18px text-black" style={{ marginBottom: '31px' }}>
        Post Guidelines
      </HeaderText>

      {postGuidelines.length > 0 ? newGuide(postGuidelines) : oldGuide(guidelines)}
    </div>
  );
};
export default PostGuideLines;
