import { POST_DETAIL_STATUS, IMPRESSIONS } from 'constants/posts';

const totalPostSummary = (posts, postIds = []) => {
  const totalSummary = {
    posts: 0,
    likes: 0,
    comments: 0,
    impressions: 0,
    totalPost: 0
  };

  postIds.forEach(postUID => {
    const post = posts[postUID] || {};

    if (post && post.status === POST_DETAIL_STATUS.approved) {
      totalSummary.posts += 1;
      totalSummary.likes += post.likes || 0;
      totalSummary.comments += post.comments || 0;
      totalSummary.impressions += (post.likes || 0) * IMPRESSIONS;
    }

    if (
      post &&
      [
        POST_DETAIL_STATUS.approved,
        POST_DETAIL_STATUS.pending,
        POST_DETAIL_STATUS.submitted
      ].includes(post.status)
    ) {
      totalSummary.totalPost += 1;
    }
  });

  return totalSummary;
};

export { totalPostSummary as default, totalPostSummary };
