export const RECEIVE_YOTPO_METRIC = 'RECEIVE_YOTPO_METRIC';

export const RECEIVE_YOTPO_METRICS = 'RECEIVE_YOTPO_METRICS';

export const UPDATE_YOTPO_METRIC = 'UPDATE_YOTPO_METRIC';

const initialState = {
  byId: {
    // 1: {
    //   ...atr
    // }
  },
  all: [
    // ids
    // 1,2,3
  ]
};

const addYotPoMetrics = (state, yotpos) => ({
  ...state,
  byId: {
    ...state.byId,
    ...yotpos
  },
  all: [...new Set(state.all.concat(Object.keys(yotpos)))]
});

const addYotPoMetric = (state, yotpo) => ({
  ...state,
  byId: {
    ...state.byId,
    ...yotpo
  },
  all: [...new Set(state.all.concat(Object.keys(yotpo)))]
});

const updateYotPoMetric = (state, yotpoId, yotpo) => ({
  ...state,
  byId: {
    ...state.byId,
    [yotpoId]: {
      ...state.byId[yotpoId],
      ...yotpo
    }
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_YOTPO_METRIC:
      return addYotPoMetric(state, action.yotpo);

    case RECEIVE_YOTPO_METRICS:
      return addYotPoMetrics(state, action.yotpos);

    case UPDATE_YOTPO_METRIC:
      return updateYotPoMetric(state, action.yotpoId, action.yotpo);

    default:
      return state;
  }
};
