/* eslint import/prefer-default-export: 0 */

import { influencersSearch } from '../lib/Firebase';

export const listenInfluencers = (filters = {}, lastInfluencerDoc = null, limit = 10, callback) => {
  let query = influencersSearch(filters);

  if (lastInfluencerDoc) {
    query = query.startAt(lastInfluencerDoc);
  }

  return query.limit(limit).onSnapshot(snap => {
    if (!snap.empty) {
      callback(snap.docs);
    }
  });
};
