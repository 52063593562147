import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'antd';
import EarnCreditsImg from '../../images/Earn_Credits.png';
import EarnCreditsImg2x from '../../images/Earn_Credits@2x.png';
import EarnCreditsImg3x from '../../images/Earn_Credits@3x.png';

import { listenTrendPendingReviews } from '../../listeners/brands';

const PendingTrendReviewModal = ({ brandUID, history, onClickCancel }) => {
  const [serving, setServing] = useState(false);

  useEffect(() => {
    listenTrendPendingReviews(brandUID, async status => {
      setServing(status);
    });
  }, []);

  const redirect = () => {
    history.push('/free-credits/refer');
    setServing(false);
  };

  return (
    <Modal
      wrapClassName="pending-trend-review-modal"
      footer={null}
      visible={serving}
      closable={false}
    >
      <div className="review-photo">
        <img
          src={EarnCreditsImg}
          srcSet={`${EarnCreditsImg2x} 2x, ${EarnCreditsImg3x} 3x`}
          className="Earn_Credits"
          alt="Earn Credits"
        />
      </div>
      <div className="review-message">
        <p className="message">Please leave Trend.io a review to earn two free credits</p>
      </div>
      <div className="review-footer">
        <Button
          type="primary"
          className="no-thank-you"
          onClick={() => {
            setServing(false);
            onClickCancel();
          }}
        >
          No, thank you
        </Button>
        <Button type="primary" className="earn-credits" onClick={() => redirect()}>
          Earn two free credits
        </Button>
      </div>
    </Modal>
  );
};

export default Form.create({ name: 'review-modal' })(PendingTrendReviewModal);
