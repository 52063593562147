export const handleScrollEvent = callback => {
  if (
    window.innerHeight + document.documentElement.scrollTop !==
    document.documentElement.offsetHeight
  )
    return;

  if (callback) {
    callback();
  }
};

const handler = callback => event => {
  const { scrollTop, clientHeight, scrollHeight } = event.target;
  if (scrollTop + clientHeight + 250 >= scrollHeight && callback) {
    callback();
  }
};

export const addElementEventListener = (elementID, callback) => {
  const layout = document.getElementById(elementID);
  layout.addEventListener('scroll', handler(callback));
};

export const removeElementEventListener = (elementId, func) => {
  const layout = document.getElementById(elementId);
  layout.removeEventListener('scroll', func);
};
