import React, { useEffect, useState } from 'react';
import { Row, Spin, Button, message } from 'antd';
import MinLayout from 'components/Common/MinLayout';
import { useDispatch } from 'react-redux';
import PostTypeFilter from './PostTypeFilters';
import GridLayout from './GridLayout';
import { firebase } from '../../../lib/Firebase';
import { getAllBrandSearches } from '../../../actions/brandSearch';

const AdminContentsPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllBrandSearches(null));
  }, [dispatch, getAllBrandSearches]);

  const [hasFetched, setHasFetched] = useState(false);
  const [postIds, setPostIds] = useState([]);
  const [influencers, setInfluencers] = useState(null);
  const [doc, setDoc] = useState(null);
  const [filters, setFilters] = useState(null);
  const [results, setResults] = useState(null);

  const getPosts = async (lastDoc = null) => {
    setHasFetched(false);
    const posts = [];
    const influencersTmp = [];
    let result = firebase.firestore
      .collection('posts')
      .orderBy('createdAt', 'desc')
      .limit(15);
    if (lastDoc) {
      result = result.startAfter(lastDoc);
    }

    if (filters) {
      if (filters.category) {
        result = result.where('category', '==', filters.category);
      }
      if (filters.brand) {
        result = result.where('brandUID', '==', filters.brand);
      }
      if (filters.creator) {
        result = result.where('influencerUID', '==', filters.creator);
      }
      if (filters.type) {
        result = result.where('type', '==', filters.type);
      }
    }

    result = await result.get();

    if (filters && result.empty) {
      message.info('No available posts');
    }

    if (result) {
      let last = null;
      result.docs.map(data => {
        posts.push({ uid: data.id, ...data.data() });
        last = data;
        return data;
      });

      setDoc(last);
      setPostIds(prevState => {
        return [...prevState, ...posts];
      });
    }
    if (!influencers) {
      const resultInfluencer = await firebase.firestore.collection('influencers').get();
      resultInfluencer.docs.map(data => {
        influencersTmp[data.id] = data.data();
        return data;
      });
      setInfluencers(influencersTmp);
    }

    setHasFetched(true);
    setResults(result.docs);
  };

  const loadMore = () => {
    getPosts(doc);
  };

  useEffect(() => {
    getPosts();
  }, []);

  useEffect(() => {
    if (filters) {
      getPosts();
      setPostIds([]);
    }
  }, [filters]);

  return (
    <div id="campaign-posts-page" style={{ marginTop: '20px' }}>
      <Row
        type="flex"
        justify="space-around"
        className="post-filter-types"
        style={{ paddingBottom: '20px' }}
      >
        <MinLayout>
          <PostTypeFilter
            setFilters={setFilters}
            filters={filters}
            postIds={postIds}
            setPostIds={setPostIds}
            setHasFetched={setHasFetched}
          />
        </MinLayout>
      </Row>
      <Spin spinning={!hasFetched}>
        <Row type="flex" justify="space-around" style={{ marginTop: '10px' }}>
          <MinLayout>
            <div style={{ marginTop: '10px' }}>
              <GridLayout postIds={postIds} influencers={influencers} />
            </div>
          </MinLayout>
        </Row>
      </Spin>
      <div className="text-center" style={{ paddingBottom: '20px', marginTop: '20px' }}>
        {results && results.length > 14 && results.length !== 0 && (
          <Button className="load-more" onClick={loadMore}>
            LOAD MORE
          </Button>
        )}
      </div>
    </div>
  );
};

export default AdminContentsPage;
