import React, { useState } from 'react';
import { Select, Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { CAMPAIGN_STATS } from '../../constants/campaigns';

const sortBy = brands => (a, b) => {
  const brandA = brands[a].name ? brands[a] : brands[a].details;
  const brandB = brands[b].name ? brands[b] : brands[b].details;

  if (brandA.name < brandB.name) {
    return -1;
  }
  if (brandA.name > brandB.name) {
    return 1;
  }
  return 0;
};

const Filter = ({ onChange, adminOrigin }) => {
  const [filters, setFilterState] = useState({});
  let brandById = {};
  let brandUIDs = [];

  if (adminOrigin) {
    [brandById, brandUIDs] = useSelector(state => [
      state.entities.brandSearch.byId,
      state.entities.brandSearch.all
    ]);
  } else {
    [brandById, brandUIDs] = useSelector(state => [
      state.entities.brands.byId,
      state.entities.brands.all
    ]);
  }

  const categoryOptions = Object.keys(CAMPAIGN_STATS).map(stat => (
    <Select.Option key={CAMPAIGN_STATS[stat]} value={CAMPAIGN_STATS[stat]}>
      {stat}
    </Select.Option>
  ));

  const validBrandUIDs = brandUIDs
    .filter(x => {
      if (adminOrigin) {
        return brandById[x].name;
      }

      return (
        brandById[x].details &&
        brandById[x].details.name &&
        !brandById[x].details.isDisabled &&
        brandById[x].details.lastLoginDate &&
        brandById[x].details.isActive
      );
    })
    .sort(sortBy(brandById));

  const brandOptions = validBrandUIDs.map(uid => {
    const brand = brandById[uid].name ? brandById[uid] : brandById[uid].details;

    return (
      <Select.Option key={uid} value={uid}>
        {brand.name}
      </Select.Option>
    );
  });

  const handleChange = type => val => {
    const newState = {
      ...filters,
      [type]: val
    };

    if (onChange) {
      onChange({
        ...newState
      });
    }
    setFilterState(newState);
  };

  return (
    <Row style={{ width: '100%' }} gutter={8}>
      <Col md={6}>
        <Select
          allowClear
          showArrow={false}
          className="trend-select"
          placeholder="Gender: Any"
          onChange={handleChange('gender')}
        >
          <Select.Option value="male">Male Only</Select.Option>
          <Select.Option value="female">Female Only</Select.Option>
        </Select>
      </Col>
      <Col md={6}>
        <Select
          allowClear
          showArrow={false}
          className="trend-select"
          placeholder="Category: All"
          onChange={handleChange('category')}
        >
          {categoryOptions}
        </Select>
      </Col>
      <Col md={6}>
        <Select
          allowClear
          showArrow={false}
          className="trend-select"
          placeholder="Status: All"
          onChange={handleChange('health')}
        >
          <Select.Option value={1}>Poor</Select.Option>
          <Select.Option value={2}>In Range</Select.Option>
          <Select.Option value={3}>Great</Select.Option>
        </Select>
      </Col>
      <Col md={6}>
        <Select
          allowClear
          showArrow={false}
          className="trend-select"
          placeholder="Brand: All"
          onChange={handleChange('brand')}
        >
          {brandOptions}
        </Select>
      </Col>
    </Row>
  );
};

export default Filter;
