import axios from "axios";
import { firebase } from "../../lib/Firebase";
import Routes from "api/routes";
import {
  RECEIVE_DELIVERY_DETAILS,
  REQUEST_DELIVERY_DETAILS,
  SHOW_SHIPPING_MODAL_FORM,
  HIDE_SHIPPING_MODAL_FORM,
  SET_SHIPPING_MODAL_FORM,
  SUBMIT_SHIPPING_FORM,
  SUBMITTED_SHIPPING_FORM,
  UPDATE_DELIVER_DETAILS,
  RESET_SHIPPING_DETAILS
} from "reducers/ui/shippingFormModal";
import { POST_STATUS } from "constants/posts";
import { setInfluencer } from "../influencers";

export const showShippingFormModal = (influencerId, productId) => ({
  type: SHOW_SHIPPING_MODAL_FORM,
  payload: {
    influencerId,
    productId
  }
});

export const hideShippingFormModal = () => ({
  type: HIDE_SHIPPING_MODAL_FORM
});

export const setShippingFormModal = (payload = {}) => ({
  type: SET_SHIPPING_MODAL_FORM,
  payload
});

export const submitShippingForm = () => ({
  type: SUBMIT_SHIPPING_FORM
});

export const submittedShippingForm = () => ({
  type: SUBMITTED_SHIPPING_FORM
});

export const updateShippingDetails = (
  influencerId,
  productId,
  parameters = {}
) => (dispatch, getState) => {
  dispatch(submitShippingForm());
  const {
    entities: { influencers }
  } = getState();

  const currentInfluencer = influencers.byId[influencerId];
  let product = currentInfluencer.partnerships.products[productId];
  let params = parameters;

  if (params.nonShippable) {
    params = {
      carrierCode: null,
      trackingNumber: null,
      status: POST_STATUS.active
    };
    product = {
      ...product,
      status: POST_STATUS.active,
      trackingNumber: null,
      carrierCode: null
    };
    currentInfluencer.partnerships.products[productId] = { ...product };
  } else {
    params = { ...params, status: POST_STATUS.shipped };
    currentInfluencer.partnerships.products[productId].status =
      POST_STATUS.shipped;
  }

  delete params.nonShippable;

  return firebase
    .influencerProduct(influencerId, productId)
    .update(params)
    .then(snap => {
      const updatedInfluencer = {
        ...currentInfluencer,
        partnerships: {
          ...currentInfluencer.partnerships,
          products: {
            ...currentInfluencer.partnerships.products,
            [productId]: {
              ...currentInfluencer.partnerships.products[productId],
              ...params
            }
          }
        }
      };

      dispatch(setInfluencer({ [influencerId]: updatedInfluencer }));
      dispatch(submittedShippingForm());
    })
    .catch(err => {
      console.log(err.message);
    });
};

export const updateDeliveryDetails = (payload = {}) => ({
  type: UPDATE_DELIVER_DETAILS,
  payload
});

export const resetShippingDetails = () => ({
  type: RESET_SHIPPING_DETAILS
});

export const receiveDeliveryDetails = () => ({
  type: RECEIVE_DELIVERY_DETAILS
});

export const requestDeliveryDetails = (
  carrierCode,
  trackingNumber
) => dispatch => {
  dispatch({ type: REQUEST_DELIVERY_DETAILS });

  return firebase.auth.currentUser
    .getIdToken(true)
    .then(idToken =>
      axios
        .get(Routes.shipengine, {
          params: { carrierCode, trackingNumber },
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        })
        .then(response => {
          const { data } = response;
          let actualDeliveryDate = data.actual_delivery_date;
          let shipDate = data.ship_date;

          if (data.status_code === "DE") {
            actualDeliveryDate = data.events[0].occurred_at;
            shipDate = data.events[data.events.length - 1].occurred_at;
          }

          dispatch(
            updateDeliveryDetails({
              actualDeliveryDate,
              shipDate,
              estimatedDeliveryDate: data.estimated_delivery_date,
              events: data.events
            })
          );
        })
        .catch(err => console.log(err))
    )
    .catch(err => console.log("Unauthorize!", err))
    .finally(() => dispatch(receiveDeliveryDetails()));
};
