const { firebase } = require('..');

const getYotPoProducts = async () => {
  try {
    const func = firebase.callable('getYotPoProducts');
    const response = await func();

    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export default getYotPoProducts;
