import React, { useState, useEffect } from 'react';
import { Avatar, Card, Spin, Button } from 'antd';
import PostCardVideo from './PostCardVideo';
import { postResourceDownloader } from '../../../components/Post/PostDownloader';
import { ReactComponent as IconDownload } from '../../../images/icon-download.svg';
import PostImage from './PostImage';
import { firebase } from '../../../lib/Firebase';

const { Meta } = Card;
const PostCard = props => {
  const { influencer, post } = props;
  const [downloading, setDownloading] = useState(false);
  const [imagesTmp, setImagesTmp] = useState([]);
  const [rdbPost, setRdbPost] = useState({});

  const getPostImages = () => {
    const { uid, images } = post;
    if (!images) {
      firebase
        .posts()
        .child(uid)
        .once('value', snapshot => {
          if (snapshot.exists()) {
            setImagesTmp(snapshot.val().images || []);
          }
          setRdbPost(snapshot.val());
        });
    } else {
      setImagesTmp(images);
    }
  };

  useEffect(() => {
    getPostImages();
  }, []);

  const handleDownloadImage = async () => {
    const { uid, images } = post;
    setDownloading(true);
    const tmpPost = images ? post : rdbPost.details;
    tmpPost.images = images || rdbPost.images;
    await postResourceDownloader(tmpPost, uid, influencer.username);
    setDownloading(false);
  };

  const iconActions = [
    <Spin spinning={downloading}>
      <div className="spin-btn">
        <Button className="download" type="link" onClick={handleDownloadImage} size="small">
          <span className="caps-heading">DOWNLOAD</span>
          <IconDownload />
        </Button>
      </div>
    </Spin>
  ];

  return (
    <Card
      cover={
        ['video', 'story'].includes(post.type) ? (
          <PostCardVideo post={post} influencer={influencer} />
        ) : (
          <PostImage withLabel post={post} influencer={influencer} postImages={imagesTmp} />
        )
      }
      actions={iconActions}
      className="post-card trend-post-card-content"
    >
      <a
        href={`https://www.instagram.com/${influencer.username}/`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Meta
          avatar={<Avatar src={influencer.image} style={{ width: '35px', height: '35px' }} />}
          title={<span title={`@${influencer.username}`}>@{influencer.username}</span>}
          description={influencer.state}
          style={{ height: 'auto' }}
        />
      </a>
    </Card>
  );
};

export default PostCard;
