import React from 'react';
import { StyleSheet, css } from 'aphrodite';

const styles = StyleSheet.create({
  container: {
    position: 'fixed',
    background: '#ffb917',
    padding: '14px 0px 15px 210px',
    width: '100%',
    bottom: 0,
    left: 0,
    '@media (min-width: 990px)': {
      padding: '14px 0px 15px 210px',
    },
    '@media (max-width: 990px)': {
      padding: '14px 0px 15px 80px',
    },
  },
  button: {
    marginRight: '3px',
  },
});

const FreeTrialBanner = ({ brand, history }) => (
  <div>
    {
      brand && brand.details.isFree && (
        <div className={css(styles.container)}>
          <div
            style={{
              fontSize: '18px',
              color: '#000000',
              fontFamily: 'TruenoBold',
              textAlign: 'center',
              padding: '4.5px',
              cursor: 'pointer',
            }}
            role="presentation"
            onClick={() => {
              history.push('/account/subscription');
            }}
          >
            FREE TRIAL MODE - Select a plan to accept applications!
          </div>
        </div>
      )
    }
  </div>
);

export default FreeTrialBanner;
