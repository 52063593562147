import React from 'react';
import {
  Modal,
  Row,
  Col,
  Button,
} from 'antd';

const WarningModal = (props) => {
  const {
    visible,
    onOkay,
    children,
    width = null,
    title,
  } = props;

  return (
    <Modal
      visible={visible}
      closable={false}
      class="trend-modal"
      width={width || 350}
      onCancel={() => onOkay()}
      footer={null}
    >
      {
        title && (
          <h2 className="text-black mb-2 type-ebold leading-24px">
            {title}
          </h2>
        )
      }
      <div
        style={{
          fontSize: '18px',
          color: '#000000',
          fontSeight: 'normal',
          fontStyle: 'normal',
          lineHeight: '1.33',
          letterSpacing: 'normal',
          fontFamily: 'truenoextrabold',
        }}
      >
        {children}
      </div>
      <Row
        style={{ marginTop: '20px' }}
        gutter={20}
        key="warning-modal"
      >
        <Col span={24}>
          <Button
            key="submit"
            onClick={() => onOkay()}
            className="trend-btn medium-btn"
            block
          >
            Okay
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default WarningModal;
