import React from 'react';
import numeral from 'numeral';
import { Row, Icon, Col } from 'antd';
import { ReactComponent as NegativeIcon } from '../../../images/negative.svg';
import { ReactComponent as PositiveIcon } from '../../../images/positive.svg';
import { ReactComponent as NoChangeIcon } from '../../../images/no-change.svg';
import formatSocialData, { formatSocialDataMin } from '../../Helpers/numeral';

const addIndicator = (val = 0, [a, b, c]) => {
  const num = parseFloat(val * 100).toFixed(2);
  if (num < 0) {
    return b;
  }
  if (num > 0) {
    return c;
  }

  return a;
};

const Stats = ({ followedByCount, avgCommentCount, avgLikeCount, stats = {}, engagementRate }) => {
  const {
    avgCommentCount: lastAvgCommentCount = avgCommentCount,
    avgLikeCount: lastAvgLikeCount = avgLikeCount
  } = stats;

  const commentPercentDiff = (avgCommentCount - lastAvgCommentCount) / avgCommentCount;
  const likesPercentDiff = (avgLikeCount - lastAvgLikeCount) / avgLikeCount;
  const formattedEngagementRate = formatSocialDataMin(engagementRate);

  return (
    <div className="influencer-stats">
      <div className="influencer-stats-followers">
        <label className="influencer-stats-label">FOLLOWERS</label>
        <div className="influencer-stats-value">{formatSocialData(followedByCount)}</div>
      </div>
      <Row className="influencer-stats-other-details">
        <Col span={12} className="influencer-stats-likes">
          <label className="influencer-stats-label">LIKES / POST</label>
          <div
            className={`influencer-stats-value ${addIndicator(likesPercentDiff, ['', '', 'up'])}`}
          >
            {addIndicator(likesPercentDiff, ['', 'down', 'up']) !== 'down' && (
              <Icon
                className="influencer-stats-value-indicator-icon"
                component={addIndicator(likesPercentDiff, [
                  NoChangeIcon,
                  NegativeIcon,
                  PositiveIcon
                ])}
              />
            )}
            {formatSocialData(avgLikeCount)}
            {addIndicator(likesPercentDiff, ['', 'down', 'up']) !== 'down' && (
              <span className="influencer-stats-value-indicator-percent">
                ({numeral(likesPercentDiff).format('0.00%')})
              </span>
            )}
          </div>
        </Col>
        <Col span={12} className="influencer-stats-comments">
          <label className="influencer-stats-label">COMMENTS / POST</label>
          <div
            className={`influencer-stats-value ${addIndicator(commentPercentDiff, ['', '', 'up'])}`}
          >
            {addIndicator(commentPercentDiff, ['', 'down', 'up']) !== 'down' && (
              <Icon
                className="influencer-stats-value-indicator-icon"
                component={addIndicator(commentPercentDiff, [
                  NoChangeIcon,
                  NegativeIcon,
                  PositiveIcon
                ])}
              />
            )}
            {formatSocialData(avgCommentCount)}
            {addIndicator(commentPercentDiff, ['', 'down', 'up']) !== 'down' && (
              <span className="influencer-stats-value-indicator-percent">
                ({numeral(commentPercentDiff).format('0.00%')})
              </span>
            )}
          </div>
        </Col>
      </Row>
      <Row className="influencer-stats-engagement">
        <Col>Engagement Rate: {formattedEngagementRate}%</Col>
      </Row>
    </div>
  );
};

export default Stats;
