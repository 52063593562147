import React, { Component } from 'react';
import { Row, Col, notification } from 'antd';
import MinLayout from 'components/Common/MinLayout';
import CurrencyFormat from 'react-currency-format';
import InfluencerImg1 from 'images/demo/influencer-1.png';
import InfluencerImg2 from 'images/demo/influencer-2.png';
import InfluencerImg3 from 'images/demo/influencer-3.png';
import PostImg1 from 'images/demo/post-1.png';
import PostImg2 from 'images/demo/post-2.png';
import PostImg3 from 'images/demo/post-3.png';
import { IMPRESSIONS } from 'constants/posts';
import PostImages from 'lib/Download';
import DemoPostsViewOption from './PostsViewOption';
import DemoPostsGridLayout from './PostsGridLayout';
import DemoPostsListLayout from './PostsListLayout';
import DemoPostsBanner from './PostsBanner';
import DemoHeaderNote from './DemoHeaderNote';

const postsData = {
  'p-123456789': {
    influencerUID: 'ft-123456789',
    influencer: {
      isVIP: true,
      state: 'New York, NY',
      username: 'demo-influencer1',
      avatar: InfluencerImg1
    },
    post: {
      fullResImage: PostImg1,
      likes: 1020,
      comments: 60,
      code: 'ft-123456789'
    }
  },
  'p-987654321': {
    influencerUID: 'ft-987654321',
    influencer: {
      isVIP: false,
      state: 'New York, NY',
      username: 'demo-influencer2',
      avatar: InfluencerImg2
    },
    post: {
      fullResImage: PostImg2,
      likes: 523,
      comments: 22,
      code: 'ft-987654321'
    }
  },
  'p-987654321123': {
    influencerUID: 'ft-987654321123',
    influencer: {
      isVIP: false,
      state: 'New York, NY',
      username: 'demo-influencer3',
      avatar: InfluencerImg3
    },
    post: {
      fullResImage: PostImg3,
      likes: 1140,
      comments: 32,
      code: 'ft-987654321123'
    }
  }
};

class Posts extends Component {
  state = {
    view: 'grid',
    posts: postsData
  };

  onChangeView = view => {
    this.setState(() => ({ view }));
  };

  onClickDownloadImage = postUID => {
    const { posts } = this.state;
    const { post } = posts[postUID];
    const newPost = {
      ...post,
      fullResImage: `${window.location.origin}${post.fullResImage}`
    };

    PostImages.downloadImage(newPost, alert => {
      notification.warning({
        message: 'Image',
        description: alert,
        duration: 2
      });
    });
  };

  render() {
    const { view, posts } = this.state;

    const postSummary = {
      livePost: 0,
      likes: 0,
      comments: 0
    };

    const selectedPosts = {};

    Object.keys(posts).forEach(postUID => {
      const { post } = posts[postUID];
      postSummary.livePost += 1;
      postSummary.likes += post.likes;
      postSummary.comments += post.comments;

      selectedPosts[postUID] = posts[postUID];
    });

    return (
      <div>
        <Row>
          <DemoHeaderNote />
        </Row>
        <Row type="flex" justify="space-around" align="middle" style={{ margin: '30px 0' }}>
          <MinLayout>
            <Row gutter={8}>
              <Col span={3}>
                <div
                  style={{
                    fontFamily: 'truenoextrabold',
                    fontSize: '17px',
                    fontStyle: 'italic',
                    color: '#000',
                    lineHeight: '1.4'
                  }}
                >
                  <CurrencyFormat
                    value={postSummary.livePost}
                    displayType="text"
                    thousandSeparator
                  />
                </div>
                <div style={{ fontSize: '8px', color: '#5b6572', letterSpacing: '0.5px' }}>
                  LIVE POSTS
                </div>
              </Col>
              <Col span={3}>
                <div
                  style={{
                    fontFamily: 'truenoextrabold',
                    fontSize: '17px',
                    fontStyle: 'italic',
                    color: '#000',
                    lineHeight: '1.4'
                  }}
                >
                  <CurrencyFormat value={postSummary.likes} displayType="text" thousandSeparator />
                </div>
                <div style={{ fontSize: '8px', color: '#5b6572', letterSpacing: '0.5px' }}>
                  LIKES
                </div>
              </Col>
              <Col span={3}>
                <div
                  style={{
                    fontFamily: 'truenoextrabold',
                    fontSize: '17px',
                    fontStyle: 'italic',
                    color: '#000',
                    lineHeight: '1.4'
                  }}
                >
                  <CurrencyFormat
                    value={postSummary.comments}
                    displayType="text"
                    thousandSeparator
                  />
                </div>
                <div style={{ fontSize: '8px', color: '#5b6572', letterSpacing: '0.5px' }}>
                  COMMENTS
                </div>
              </Col>
              <Col span={3}>
                <div
                  style={{
                    fontFamily: 'truenoextrabold',
                    fontSize: '17px',
                    fontStyle: 'italic',
                    color: '#000',
                    lineHeight: '1.4'
                  }}
                >
                  <CurrencyFormat
                    value={postSummary.likes * IMPRESSIONS}
                    displayType="text"
                    thousandSeparator
                  />
                </div>
                <div style={{ fontSize: '8px', color: '#5b6572', letterSpacing: '0.5px' }}>
                  IMPRESSIONS
                </div>
              </Col>
            </Row>
          </MinLayout>
        </Row>
        <Row type="flex" justify="space-around">
          <MinLayout>
            <div style={{ marginTop: '10px' }}>
              <DemoPostsViewOption onChangeView={this.onChangeView} view={view} posts={posts} />
              <div style={{ marginTop: '10px' }}>
                {view === 'grid' ? (
                  <DemoPostsGridLayout
                    posts={selectedPosts}
                    onClickDownloadImage={this.onClickDownloadImage}
                  />
                ) : (
                  <DemoPostsListLayout
                    posts={selectedPosts}
                    onClickDownloadImage={this.onClickDownloadImage}
                  />
                )}
              </div>
              <DemoPostsBanner />
            </div>
          </MinLayout>
        </Row>
      </div>
    );
  }
}

export default Posts;
