import React, { useState } from 'react';
import moment from 'moment';
import { Col, Row, Button, Modal, notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { addCredits } from 'actions/ui/brandAccount';
import { useHistory } from 'react-router-dom';
import { DATE_TIMEZONE_FORMAT } from '../../constants/common';
import { firebase } from '../../lib/Firebase';
import { formatCurrency } from '../Helpers/numeral';
import { purchasedTag } from '../../lib/Analytics';

const NewCreditPage = props => {
  const { authUser } = props;

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedStandardCredits, setSelectedStandCredits] = useState();
  const [totalStandardAmount, setTotalStandardAmount] = useState();
  const [saveAmount, setSaveAmount] = useState();
  const [totalAmount, setTotalAmount] = useState();

  const dispatch = useDispatch();
  const history = useHistory();
  const brandUID = firebase.auth.currentUser.uid;
  const accountDetails = useSelector(state => state.entities.session.authUserData.details);
  const withStripeAccount = accountDetails.stripeCustomerId;
  const ownedCampaigns = useSelector(state => state.ui.sideNav.ownedCampaigns);
  const archivedCampaigns = useSelector(state => state.ui.sideNav.ownedArchivedCampaigns);

  const addCreditsAction = () => {
    setSubmitting(true);
    dispatch(
      addCredits(authUser.uid, selectedStandardCredits, 0, async (response, error) => {
        setSubmitting(false);
        setShowConfirmModal(false);
        if (error) {
          notification.error({
            message: error.message,
            duration: 0
          });
        } else {
          const onboarding = ownedCampaigns.length === 1 && archivedCampaigns.length === 0;
          if (onboarding) {
            const campaignUID = ownedCampaigns[0];
            firebase.activeProduct(campaignUID).update({ isListed: true });
            // update first campaign
            let createdAt = moment();
            if (
              process.env.REACT_APP_ENV === 'staging' ||
              process.env.REACT_APP_ENV === 'development'
            ) {
              createdAt = moment()
                .subtract(1, 'day')
                .add(3, 'minutes');
            }
            firebase
              .brand(brandUID)
              .child('details')
              .update({
                firstCredit: true,
                createdAtFirstCredit: createdAt.format(DATE_TIMEZONE_FORMAT),
                status: 'approved'
              });
            const brandSnap = await firebase.firestore
              .collection('brands')
              .doc(authUser.uid)
              .get();
            if (brandSnap.exists) {
              const { businessId = undefined } = brandSnap.data();
              if (businessId) {
                await firebase.firestore
                  .collection('businesses')
                  .doc(businessId)
                  .update({
                    firstCredit: true,
                    status: 'approved'
                  });
              }
            }
          }
          await purchasedTag(authUser.uid);
          notification.success({
            message: 'Successfully purchased credits.',
            duration: 0
          });
          if (selectedStandardCredits >= 20) {
            history.push('/session');
          } else {
            history.push('/success-purchase');
          }
          setSelectedStandCredits();
          setTotalStandardAmount();
          setTotalAmount();
        }
      })
    );
  };

  const renderNoCreditCardInfo = () => {
    return (
      <div>
        <p>There is no payment method provided for your account. Please add one and try again</p>
        <Row style={{ marginTop: '20px' }} gutter={20} key="confirm-modal">
          <Col span={12}>
            <Button
              key="cancel"
              onClick={() => {
                setShowConfirmModal(false);
              }}
              className="trend-btn medium-btn secondary"
              block
            >
              Cancel
            </Button>
          </Col>
          <Col span={12}>
            <Button
              key="submit"
              onClick={() => history.push('/account/store/edit#payment-info')}
              className="trend-btn medium-btn"
              block
            >
              Add Payment Info
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  const renderPurchaseDetails = () => {
    return (
      <div>
        <Row>
          <Col span={12}>
            <p>{`${selectedStandardCredits} Credits`}</p>
          </Col>
          <Col span={12} align="right">
            <span>{formatCurrency(totalStandardAmount, '$')}</span>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <p>Savings</p>
          </Col>
          <Col span={12} align="right">
            <span>{formatCurrency(saveAmount, '$')}</span>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <p> TOTAL </p>
          </Col>
          <Col span={12} align="right">
            <strong>{formatCurrency(totalAmount, '$')}</strong>
          </Col>
        </Row>
        <Row style={{ marginTop: '20px' }} gutter={20} key="confirm-modal">
          <Col span={12}>
            <Button
              key="cancel"
              disabled={submitting}
              onClick={() => {
                setShowConfirmModal(false);
              }}
              className="trend-btn medium-btn secondary"
              block
            >
              Cancel
            </Button>
          </Col>
          <Col span={12}>
            <Button
              key="submit"
              loading={submitting}
              disabled={submitting}
              onClick={addCreditsAction}
              className="trend-btn medium-btn"
              block
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div style={{ color: '#fff', marginBottom: '100px' }}>
      <Col>
        <Row style={{ borderTop: '4px solid #282828', width: '940px', paddingBottom: '37px' }}>
          <Col span={6}>
            <div style={{ marginTop: '51px' }}>
              <span className="type-sfpro-regular size-28px font-weight-800">5 Credits</span>
            </div>
          </Col>
          <Col span={5} style={{ marginTop: '42px' }}>
            <span className="type-sfpro-regular size-11px color-yellow-font font-weight-700">
              COST PER CREDIT
            </span>
            <br />
            <span
              className="type-sfpro-regular text-white size-21px font-weight-800"
              style={{ marginTop: '5px' }}
            >
              $100
            </span>
          </Col>
          <Col span={5} style={{ marginTop: '42px' }}>
            <span className="type-sfpro-regular size-11px color-yellow-font font-weight-700">
              EXTRA SAVINGS
            </span>
            <br />
            <span
              className="type-sfpro-regular text-white size-21px font-weight-800"
              style={{ marginTop: '5px' }}
            >
              $0
            </span>
          </Col>
          <Col span={4} style={{ marginTop: '42px' }}>
            <span className="type-sfpro-regular size-11px color-yellow-font font-weight-700">
              TOTAL COST
            </span>
            <br />
            <span
              className="type-sfpro-regular text-white size-21px font-weight-800"
              style={{ marginTop: '5px' }}
            >
              $500
            </span>
          </Col>
          <Col span={3}>
            <Button
              key="approve"
              type="primary"
              className="type-sfpro-regular size-17px"
              loading={submitting}
              onClick={() => {
                setShowConfirmModal(true);
                setSelectedStandCredits(5);
                setTotalStandardAmount(500);
                setSaveAmount(0);
                setTotalAmount(500);
              }}
              block
              style={{
                background: '#FFB917',
                height: '48px',
                color: '#000',
                fontWeight: '800',
                fontStyle: 'italic',
                width: '123px',
                marginTop: '40px',
                borderRadius: '4px'
              }}
            >
              Buy Credits
            </Button>
          </Col>
        </Row>
        <Row style={{ borderTop: '4px solid #282828', width: '940px', paddingBottom: '37px' }}>
          <Col span={6}>
            <div style={{ marginTop: '51px' }}>
              <span className="type-sfpro-regular size-28px font-weight-800">10 Credits</span>
            </div>
          </Col>
          <Col span={5} style={{ marginTop: '42px' }}>
            <span className="type-sfpro-regular size-11px color-yellow-font font-weight-700">
              COST PER CREDIT
            </span>
            <br />
            <span
              className="type-sfpro-regular text-white size-21px font-weight-800"
              style={{ marginTop: '5px' }}
            >
              $95
            </span>
          </Col>
          <Col span={5} style={{ marginTop: '42px' }}>
            <span className="type-sfpro-regular size-11px color-yellow-font font-weight-700">
              EXTRA SAVINGS
            </span>
            <br />
            <span
              className="type-sfpro-regular text-white size-21px font-weight-800"
              style={{ marginTop: '5px' }}
            >
              $50
            </span>
          </Col>
          <Col span={4} style={{ marginTop: '42px' }}>
            <span className="type-sfpro-regular size-11px color-yellow-font font-weight-700">
              TOTAL COST
            </span>
            <br />
            <span
              className="type-sfpro-regular text-white size-21px font-weight-800"
              style={{ marginTop: '5px' }}
            >
              $950
            </span>
          </Col>
          <Col span={3}>
            <Button
              key="approve"
              type="primary"
              className="type-sfpro-regular size-17px"
              loading={submitting}
              onClick={() => {
                setShowConfirmModal(true);
                setSelectedStandCredits(10);
                setTotalStandardAmount(1000);
                setSaveAmount(50);
                setTotalAmount(950);
              }}
              block
              style={{
                background: '#FFB917',
                height: '48px',
                color: '#000',
                fontWeight: '800',
                fontStyle: 'italic',
                width: '123px',
                marginTop: '40px',
                borderRadius: '4px'
              }}
            >
              Buy Credits
            </Button>
          </Col>
        </Row>
        <Row style={{ borderTop: '4px solid #282828', width: '940px', paddingBottom: '37px' }}>
          <Col span={6}>
            <div style={{ marginTop: '44px' }}>
              <span className="type-sfpro-regular size-17px font-weight-700 most-popular">
                MOST POPULAR
              </span>{' '}
              <br />
              <span className="type-sfpro-regular size-28px font-weight-800">20 Credits</span>
            </div>
          </Col>
          <Col span={5} style={{ marginTop: '42px' }}>
            <span className="type-sfpro-regular size-11px color-yellow-font font-weight-700">
              COST PER CREDIT
            </span>
            <br />
            <span
              className="type-sfpro-regular text-white size-21px font-weight-800"
              style={{ marginTop: '5px' }}
            >
              $90
            </span>
          </Col>
          <Col span={5} style={{ marginTop: '42px' }}>
            <span className="type-sfpro-regular size-11px color-yellow-font font-weight-700">
              EXTRA SAVINGS
            </span>
            <br />
            <span
              className="type-sfpro-regular text-white size-21px font-weight-800"
              style={{ marginTop: '5px' }}
            >
              $200
            </span>
          </Col>
          <Col span={4} style={{ marginTop: '42px' }}>
            <span className="type-sfpro-regular size-11px color-yellow-font font-weight-700">
              TOTAL COST
            </span>
            <br />
            <span
              className="type-sfpro-regular text-white size-21px font-weight-800"
              style={{ marginTop: '5px' }}
            >
              $1,800
            </span>
          </Col>
          <Col span={3}>
            <Button
              key="approve"
              type="primary"
              className="type-sfpro-regular size-17px"
              loading={submitting}
              onClick={() => {
                setShowConfirmModal(true);
                setSelectedStandCredits(20);
                setSaveAmount(200);
                setTotalStandardAmount(2000);
                setTotalAmount(1800);
              }}
              block
              style={{
                background: '#FFB917',
                height: '48px',
                color: '#000',
                fontWeight: '800',
                fontStyle: 'italic',
                width: '123px',
                marginTop: '40px',
                borderRadius: '4px'
              }}
            >
              Buy Credits
            </Button>
          </Col>
        </Row>
        <Row
          style={{
            borderTop: '4px solid #282828',
            width: '940px',
            paddingBottom: '37px',
            borderBottom: '4px solid #282828'
          }}
        >
          <Col span={6}>
            <div style={{ marginTop: '51px' }}>
              <span className="type-sfpro-regular size-28px font-weight-800">40 Credits</span>
            </div>
          </Col>
          <Col span={5} style={{ marginTop: '42px' }}>
            <span className="type-sfpro-regular size-11px color-yellow-font font-weight-700">
              COST PER CREDIT
            </span>
            <br />
            <span
              className="type-sfpro-regular text-white size-21px font-weight-800"
              style={{ marginTop: '5px' }}
            >
              $88
            </span>
          </Col>
          <Col span={5} style={{ marginTop: '42px' }}>
            <span className="type-sfpro-regular size-11px color-yellow-font font-weight-700">
              EXTRA SAVINGS
            </span>
            <br />
            <span
              className="type-sfpro-regular text-white size-21px font-weight-800"
              style={{ marginTop: '5px' }}
            >
              $480
            </span>
          </Col>
          <Col span={4} style={{ marginTop: '42px' }}>
            <span className="type-sfpro-regular size-11px color-yellow-font font-weight-700">
              TOTAL COST
            </span>
            <br />
            <span
              className="type-sfpro-regular text-white size-21px font-weight-800"
              style={{ marginTop: '5px' }}
            >
              $3,520
            </span>
          </Col>
          <Col span={3}>
            <Button
              key="approve"
              type="primary"
              className="type-sfpro-regular size-17px"
              loading={submitting}
              onClick={() => {
                setShowConfirmModal(true);
                setSelectedStandCredits(40);
                setTotalStandardAmount(4000);
                setSaveAmount(480);
                setTotalAmount(3520);
              }}
              block
              style={{
                background: '#FFB917',
                height: '48px',
                color: '#000',
                fontWeight: '800',
                fontStyle: 'italic',
                width: '123px',
                marginTop: '40px',
                borderRadius: '4px'
              }}
            >
              Buy Credits
            </Button>
          </Col>
        </Row>
      </Col>
      <Modal
        title={withStripeAccount ? 'You will purchase' : 'No Payment Information'}
        visible={showConfirmModal}
        onOk={addCreditsAction}
        onCancel={() => {
          setShowConfirmModal(false);
        }}
        okText="Confirm"
        confirmLoading={submitting}
        className="trend-modal"
        footer={null}
      >
        {withStripeAccount ? renderPurchaseDetails() : renderNoCreditCardInfo()}
      </Modal>
    </div>
  );
};

export default NewCreditPage;
