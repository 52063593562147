import React from 'react';
import { Empty } from 'antd';
import NoPostsImg from 'images/DefaultEmpty/no-posts.png';

const EmptyPosts = () => (
  <Empty
    className="empty-no-applications"
    style={{
      marginTop: '66px',
      marginBottom: '109px'
    }}
    image={NoPostsImg}
    description={<span className="empty-desc-header type-sfpro-bold mb-2 mt-6">No Posts… Yet</span>}
  >
    <div className="mt-1">
      <div className="empty-desc-body">
        Once your campaign is underway you’ll be able able to view post
        <br className="hidden-mobile" />
        analytics, download the content from each post and share with your
        <br className="hidden-mobile" />
        team. You have 100% usage rights to repurpose content on your
        <br className="hidden-mobile" />
        own websites, ads, and social accounts.
      </div>
    </div>
  </Empty>
);

export default EmptyPosts;
