import React from 'react';
import { Icon, Button, Modal, Form } from 'antd';
import ContentSentImage from '../../images/content-sent@2x.png';
import { ReactComponent as CloseIcon } from '../../images/close-x.svg';

const SentModal = ({ show, handleShow }) => {
  function handleClose() {
    handleShow(false);
  }

  return (
    <Modal className="yotpo-modal sent" closable={false} visible={show} footer={false}>
      <Button type="link" className="close" onClick={handleClose}>
        <Icon component={CloseIcon} />
      </Button>
      <div className="text-center logos">
        <img className="content-sent-image" src={ContentSentImage} alt="content sent" />
      </div>
      <h1>Content Sent! It may take some time for it to show up in Yotpo, but it’s on its way.</h1>
      <div style={{ position: 'relative' }}>
        <Form.Item className="submit">
          <Button block onClick={handleClose}>
            Close and go back to Trend
          </Button>
        </Form.Item>
      </div>
    </Modal>
  );
};

export default SentModal;
