import React from 'react';
import PaymentForm from 'components/Common/PaymentForm';

const Subscription = ({ authUser }) => (
  <div style={{ padding: '21px' }}>
    <PaymentForm authUser={authUser} isNewSubscription={false} />
  </div>
);

export default Subscription;
