import {
  RECEIVE_YOTPO_METRIC,
  RECEIVE_YOTPO_METRICS,
  UPDATE_YOTPO_METRIC
} from 'reducers/entities/yotpoMetric';

export const receiveYotPoMetrics = yotpos => ({
  type: RECEIVE_YOTPO_METRICS,
  yotpos
});

export const receiveYotPoMetric = yotpo => ({
  type: RECEIVE_YOTPO_METRIC,
  yotpo
});

export const updateYotPoMetric = (yotpoId, yotpo) => ({
  type: UPDATE_YOTPO_METRIC,
  yotpoId,
  yotpo
});
