import React from 'react';
import { Row, Col, Button, Icon } from 'antd';
import { ReactComponent as ImageIcon } from '../../images/photographs.svg';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const sortByPosition = files => (a, b) => files[a].position - files[b].position;

const activeFilter = files => a => !files[a].removed;

class ImageUploadV2 extends React.Component {
  static getDerivedStateFromProps(nextProps) {
    if ('value' in nextProps) {
      return { files: nextProps.value || {} };
    }
    return null;
  }

  state = {
    files: {}
  };

  handleAdd = async e => {
    const { onChange } = this.props;
    if (e.target.files.length > 0) {
      const { files } = this.state;
      const activeFiles = Object.keys(files).filter(activeFilter(files));
      const totalFiles = activeFiles.length;
      const file = e.target.files[0];
      e.target.value = null;
      const newState = {
        files: {
          ...files,
          [`pending-${totalFiles}`]: {
            file,
            url: await getBase64(file),
            position: totalFiles
          }
        }
      };
      this.setState(newState);

      onChange(newState.files);
    }
  };

  handleRemove = fileUID => {
    const { onChange } = this.props;
    const { files } = this.state;

    const newState = {
      ...files,
      [fileUID]: {
        ...files[fileUID],
        removed: true
      }
    };

    const activeFiles = Object.keys(newState)
      .filter(activeFilter(newState))
      .sort(sortByPosition(newState));

    activeFiles.forEach((uid, index) => {
      newState[uid].position = index;
    });

    this.setState({ files: newState });
    onChange(newState);
  };

  render() {
    const { col } = this.props;
    const { files } = this.state;
    const sortedFile = Object.keys(files)
      .filter(activeFilter(files))
      .sort(sortByPosition(files));
    const fileList = sortedFile.map(fileUID => {
      const file = files[fileUID];
      return (
        <Col key={fileUID} span={col} className="image">
          <img src={file.url} alt="style guide" />
          <div className="actions">
            <Button className="remove" type="link" onClick={() => this.handleRemove(fileUID)} block>
              Remove
            </Button>
          </div>
        </Col>
      );
    });
    return (
      <Row className="image-uploader" gutter={16}>
        {fileList}
        <Col span={col}>
          <div className="add">
            <div className="button">
              <Icon component={ImageIcon} />
              <div className="label">Add Image+</div>
            </div>
            <input
              type="file"
              onChange={this.handleAdd}
              className="upload-files"
              accept="image/*"
            />
          </div>
        </Col>
      </Row>
    );
  }
}

export default ImageUploadV2;
