import moment from 'moment';
import { DATE_TIMEZONE_FORMAT } from 'constants/common';

const sortOnDetails = (influencers, field, order) => (a, b) => {
  const [first, second] = order === 'ascend' ? [b, a] : [a, b];
  const infA = influencers[first].details[field];
  const infB = influencers[second].details[field];

  if (infA > infB) {
    return -1;
  }
  if (infA < infB) {
    return 1;
  }
  return 0;
};

const sortOnInstagram = (influencers, field, order) => (a, b) => {
  const [first, second] = order === 'ascend' ? [b, a] : [a, b];
  if (!influencers[first].instagram || !influencers[second].instagram) {
    return !influencers[first].instagram ? 1 : -1;
  }

  const infA = influencers[first].instagram[field];
  const infB = influencers[second].instagram[field];

  if (infA > infB) {
    return -1;
  }
  if (infA < infB) {
    return 1;
  }
  return 0;
};

const sortedFilter = (influencers, filteredIds, sortBy) => {
  const { field, order } = sortBy;

  if (field === 'ageRange') {
    return filteredIds.sort(sortOnDetails(influencers, 'ageRange', order));
  }
  return filteredIds.sort(sortOnInstagram(influencers, field, order));
};

export default (influencers, influencerIds = [], filters, campaign = {}) => {
  const { category, location, sortBy, influencerType } = filters;
  const { order, field } = sortBy;

  let filteredIds = influencerIds.filter(influencerUID => {
    const influencer = influencers[influencerUID].details;

    const matchLocation = location
      ? influencer.state.toLowerCase() === location.toLowerCase()
      : true;

    let matchCategory = true;
    if (category) {
      const categories = Object.values(influencer.categories).map(cat => cat.toLowerCase());
      matchCategory = categories.includes(category.toLowerCase());
    }

    let matchInfluencerType = true;
    switch (influencerType) {
      case 'vip':
        matchInfluencerType = influencer.isVIP || false;
        break;
      case 'standard':
        matchInfluencerType = !influencer.isVIP;
        break;
      default:
        matchInfluencerType = true;
    }

    return matchCategory && matchLocation && matchInfluencerType;
  });

  if (!field || field === 'dateApplied') {
    filteredIds = filteredIds.sort((a, b) => {
      if (Object.keys(campaign) === 0) {
        return 0;
      }

      if (!campaign.appliedUID || !campaign.appliedUID[a] || !campaign.appliedUID[b]) {
        return 0;
      }

      const appliedTimestampA = moment(campaign.appliedUID[a].timestamp, DATE_TIMEZONE_FORMAT);
      const appliedTimestampB = moment(campaign.appliedUID[b].timestamp, DATE_TIMEZONE_FORMAT);

      return appliedTimestampB - appliedTimestampA;
    });

    return !field || order === 'descend' ? filteredIds : filteredIds.reverse();
  }

  return sortedFilter(influencers, filteredIds, sortBy);
};
