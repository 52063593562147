import axios from 'axios';
import Routes from 'api/routes';
import UserToken from 'lib/Firebase/userToken';
import { firebase } from 'lib/Firebase';
import moment from 'moment';
import Random from 'random-key';

export const fetchFreeTrials = (onComplete = null) => {
  firebase.trials().once('value').then((snap) => {
    let trials = [];
    if (snap.exists()) {
      trials = snap.val();
    }

    onComplete(trials);
  }).catch((err) => {
    onComplete(null, err);
  });
};

export const resendTrial = async(key, onComplete = null) => {
  const tokenData = await UserToken();
  return firebase.trial(key).once('value', (snap) => {
    const trial = {};
    trial[key] = snap.val()
    axios.post(Routes.sendFreeTrialInvitation, {
      email: snap.val().email,
      recipientName: snap.val().firstName,
      hash: snap.val().hash,
    }, { headers: tokenData.headers }).then((response) => {
      onComplete(trial, null);
    }).catch((error) => {
      onComplete(null, error);
    });
  }).catch((err) => {
    onComplete(null, err);
  });
};

export const sendTrial = async(params, onComplete = null) => {
  const key = firebase.trials().push().key;
  const { email, firstName, lastName } = params;
  const hash = Random.generate(100);
  const tokenData = await UserToken();

  firebase.trial(key).set({
    email,
    hash: hash,
    firstName,
    lastName,
    createdAt: moment().toString(),
  }).then(() => {
    return firebase.trial(key).once('value', (snap) => {
      const trial = {};
      trial[key] = snap.val()
      axios.post(Routes.sendFreeTrialInvitation, {
        email: email,
        recipientName: firstName,
        hash: hash,
      }, { headers: tokenData.headers }).then((response) => {
        onComplete(trial, null);
      }).catch((error) => {
        onComplete(null, error);
      });
    }).catch((err) => {
      onComplete(null, err);
    });
  }).catch((err) => {
    onComplete(null, err);
  });
}