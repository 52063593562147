/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import posed from 'react-pose';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { Row, Checkbox, Col, Radio, Button, Spin, notification } from 'antd';
import { fetchPlans, fetchCurrentSubscription, updatePlan } from 'api/subscriptions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { fetchBrand } from 'actions/brands';
import { SUPPORT_EMAIL } from 'constants/common';
import { ReactComponent as IconBack } from 'images/icon-back-arrow-white.svg';
import CustomPlanRadio from 'components/Common/CustomPlanRadio';
import config from 'app_config';
import CheckoutForm from './CheckoutForm';
import PlanRadio from './PlanRadio';

const stripePublishKey = config.STRIPE_PUB_KEY;
const RadioGroup = Radio.Group;
const StepContainer = posed.div({
  visible: {
    applyAtEnd: { display: 'block' },
    opacity: 1
  },
  hidden: {
    applyAtEnd: { display: 'none' },
    opacity: 0
  }
});
const ButtonsSlider = posed.div({
  hidden: {
    opacity: 0,
    clear: 'left',
    y: 0
  },
  visible: {
    opacity: 1,
    clear: 'left',
    y: 10
  }
});

class PaymentForm extends Component {
  state = {
    plans: {},
    selectedPlan: null,
    visibleStep: 'plan',
    existingPlan: null,
    updatingPlan: false,
    oldUser: false,
    agreeToTerms: false
  };

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    this.isFetching = false;

    this.setupPlans();
  }

  componentWillUnmount() {
    this.isFetching = false;
    this._isMounted = false;
  }

  setupPlans() {
    const { authUser: user, fetchBrand: getBrand } = this.props;

    if (this.isFetching) {
      return;
    }

    this.isFetching = true;

    getBrand(user.uid, brand => {
      if (brand.details.stripeCustomerId) {
        fetchCurrentSubscription(user.uid, (response = {}) => {
          this.getPlans(plans => {
            const { plan } = response;
            if (plan && !plans[plan.id].legacy) {
              this.setMountedState({
                selectedPlan: response.plan.id,
                existingPlan: response.plan.id,
                stripeCustomerId: brand.details
              });
            } else {
              this.setMountedState({
                selectedPlan: 'custom',
                existingPlan: 'custom',
                oldUser: true,
                stripeCustomerId: brand.details
              });
            }
          });
        });
      } else {
        this.getPlans(plans => {
          // If old brand set current plan to Custom
          if (!brand.details.isFree && brand.details.lastReloadDate && plans) {
            this.setMountedState({
              selectedPlan: 'custom',
              existingPlan: 'custom',
              oldUser: true
            });
          }
        });
      }
    });
  }

  getPlans(onComplete = null) {
    fetchPlans(response => {
      this.setMountedState({
        plans: response.body
      });
      if (onComplete) {
        onComplete(response.body);
      }
    });
  }

  setMountedState(stateArgs) {
    if (this._isMounted) {
      this.setState(() => ({ ...stateArgs }));
    }
  }

  onChange = e => {
    const { plans } = this.state;
    const { amount } = plans[e.target.value];

    this.setState(() => ({
      selectedPlan: e.target.value,
      planAmount: amount
    }));
  };

  onCustomChange = () => {
    this.setState(() => ({
      selectedPlan: 'custom'
    }));
  };

  goStep = step => {
    this.setState({ visibleStep: step });
  };

  cancelUpdate = () => {
    const { existingPlan } = this.state;
    this.setState({ selectedPlan: existingPlan });
  };

  onAgreeToTermsCheck = e => {
    this.setState({ agreeToTerms: e.target.checked });
  };

  onUpdatePlan = () => {
    const { authUser } = this.props;
    const { selectedPlan } = this.state;

    this.setState({ updatingPlan: true });
    updatePlan(authUser.uid, selectedPlan, (subscription, error) => {
      this.setState({ updatingPlan: false });
      if (error) {
        notification.error({ message: error.message });
      } else {
        this.setState({
          existingPlan: subscription.plan.id,
          selectedPlan: subscription.plan.id
        });
        notification.success({ message: 'Successfully updated your plan.' });
      }
    });
  };

  renderUpdateButton(existingPlan, selectedPlan) {
    const { updatingPlan, stripeCustomerId, agreeToTerms } = this.state;
    const postState = existingPlan !== selectedPlan ? 'visible' : 'hidden';
    let buttons = null;

    buttons = (
      <ButtonsSlider pose={postState} initialPose="hidden">
        <Row gutter={12}>
          <Col span={12}>
            <Button
              block
              size="large"
              onClick={this.cancelUpdate}
              className="trend-btn gray huge-btn"
            >
              CANCEL UPDATE
            </Button>
          </Col>
          <Col span={12}>
            {!!stripeCustomerId && (
              <Button
                block
                size="large"
                disabled={!agreeToTerms}
                onClick={this.onUpdatePlan}
                className="trend-btn huge-btn"
                loading={updatingPlan}
              >
                Update Plan
              </Button>
            )}
            {!stripeCustomerId && (
              <Button
                block
                size="large"
                disabled={!agreeToTerms}
                onClick={() => {
                  this.goStep('checkout');
                }}
                className="trend-btn huge-btn"
                loading={updatingPlan}
              >
                Update Plan
              </Button>
            )}
          </Col>
        </Row>
      </ButtonsSlider>
    );
    return buttons;
  }

  renderProceedPaymentButton() {
    const { selectedPlan, plans, agreeToTerms } = this.state;
    let button = null;
    if (Object.keys(plans).length !== 0) {
      button = (
        <Col span={24} style={{ textAlign: 'center' }}>
          <Button
            block
            className="trend-btn huge-btn mt-6"
            size="large"
            span={12}
            onClick={() => {
              this.goStep('checkout');
            }}
            disabled={!selectedPlan || !agreeToTerms}
          >
            Proceed to Payment
          </Button>
        </Col>
      );
    }
    return button;
  }

  render() {
    const { plans, selectedPlan, visibleStep, planAmount, existingPlan, oldUser } = this.state;
    const currentPlan = plans[existingPlan];
    const { isNewSubscription } = this.props;
    const isEmpty = Object.keys(plans).length === 0;

    let planIds = Object.keys(plans).filter(id => !plans[id].legacy);

    if (Object.keys(plans).length > 0 && currentPlan && !currentPlan.legacy) {
      planIds = [existingPlan];
    }

    const Plans = planIds.map(id => (
      <PlanRadio key={id} plan={plans[id]} id={id} existingPlan={existingPlan} />
    ));

    return (
      <div className="pt-3">
        <Row type="flex" justify="center">
          <Col lg={11} md={14} sm={20} xs={24}>
            <Col span={24} style={{ color: 'text-white', paddingBottom: '80px' }}>
              <StepContainer pose={visibleStep === 'plan' ? 'visible' : 'hidden'}>
                <Spin spinning={isEmpty} wrapperClassName="dark-theme">
                  <p
                    className="text-gray type-light size-15px"
                    style={{
                      marginBottom: '35px',
                      lineHeight: '1.85',
                      fontWeight: 800,
                      fontFamily: 'TruenoBold',
                      color: '#ffffff'
                    }}
                  >
                    You can upgrade your plan a level at any point and pay the difference between
                    your current plan and the new plan. Do you have any questions? Send us an email
                    to
                    <a href={`mailto:${SUPPORT_EMAIL}`} target="_top" className="text-white">
                      &nbsp;
                      {SUPPORT_EMAIL}
                      &nbsp;
                    </a>
                    to help us understand your needs.
                  </p>
                  {isNewSubscription && (
                    <h1 className="text-white" align="center">
                      Manage your plan & add credits
                    </h1>
                  )}
                  <Col className="plan-list" span={24}>
                    <Row className="plan-header">
                      <Col style={{ paddingLeft: '25px' }} md={6}>
                        PLAN TYPE
                      </Col>
                      <Col md={6}>PLAN DURATION</Col>
                      <Col md={10}>INFLUENCER SIGN UP BONUS</Col>
                    </Row>
                    <Row>
                      <RadioGroup
                        onChange={this.onChange}
                        value={selectedPlan}
                        className="ant-col-24"
                        block
                      >
                        {Plans}
                      </RadioGroup>
                      {oldUser && existingPlan === 'custom' && (
                        <RadioGroup
                          onChange={this.onCustomChange}
                          value={selectedPlan}
                          className="ant-col-24"
                          block
                        >
                          <CustomPlanRadio />
                        </RadioGroup>
                      )}
                    </Row>
                  </Col>
                  {(existingPlan === 'custom' || (currentPlan && currentPlan.legacy)) && (
                    <Col span={24} className="mt-5">
                      <label
                        htmlFor="multipleSelect"
                        className="d-block clickable leading-18px"
                        style={{
                          marginTop: 8,
                          marginBottom: 8
                        }}
                      >
                        <Checkbox
                          id="multipleSelect"
                          className="trend-terms-checkbox"
                          onChange={this.onAgreeToTermsCheck}
                          style={{ float: 'left', marginRight: '6px' }}
                        />
                        <span
                          className="size-13px spacing-1px text-white"
                          style={{
                            paddingBottom: 10,
                            paddingLeft: 16,
                            fontStyle: 'italic',
                            lineHeight: '26px'
                          }}
                        >
                          By selecting a plan you agree to our{' '}
                          <a href="/account/tos" target="tos">
                            Terms of service
                          </a>
                        </span>
                      </label>
                      {existingPlan
                        ? this.renderUpdateButton(existingPlan, selectedPlan)
                        : this.renderProceedPaymentButton()}
                    </Col>
                  )}
                </Spin>
              </StepContainer>

              <StepContainer pose={visibleStep === 'checkout' ? 'visible' : 'hidden'}>
                <a
                  href="#"
                  onClick={() => {
                    this.goStep('plan');
                  }}
                  className="d-block type-sbold bsize-4"
                >
                  <IconBack />
                  <span className="white d-block type-sbold bsize-4">Back</span>
                </a>

                <div style={{ textAlign: 'center', marginBottom: '80px' }} className="text-white">
                  <h1 className="text-white"> Payment </h1>
                  {selectedPlan && plans[selectedPlan] && (
                    <strong className="text-gray-darkest">
                      {`${plans[selectedPlan].nickname} - ${plans[selectedPlan].standardCredits} + ${plans[selectedPlan].credits} Posts/Month`}
                    </strong>
                  )}
                </div>

                <StripeProvider apiKey={stripePublishKey}>
                  <Elements>
                    <CheckoutForm plan={selectedPlan} amount={planAmount} />
                  </Elements>
                </StripeProvider>
              </StepContainer>
            </Col>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  fetchBrand: (brandUID, callback) => dispatch(fetchBrand(brandUID, callback))
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PaymentForm);
