import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BRAND_DETAILS_STATUS } from '../../constants/brands';

const RootPage = () => {
  const brandUID = useSelector(state => state.entities.session.authUserData.uid);
  const brand = useSelector(state => state.entities.brands.byId[brandUID].details);
  const { ownedCampaigns, ownedArchivedCampaigns } = useSelector(state => state.ui.sideNav);
  const { status, stripeCustomerId } = brand;
  const noCamapaigns = ownedCampaigns.length === 0 && ownedArchivedCampaigns.length === 0;
  const firstCampaignUID = ownedCampaigns[0];
  const onBoarding = status;
  const onBoardingApproved = onBoarding && status === BRAND_DETAILS_STATUS.approved;
  const onBoardingIncomplete = onBoardingApproved && !stripeCustomerId;

  let redirectUrl = '/posts';

  if (noCamapaigns || onBoardingIncomplete) {
    redirectUrl = '/campaigns/example/summary';
  } else if (firstCampaignUID) {
    redirectUrl = `/campaigns/${firstCampaignUID}/summary`;
  }
  return <Redirect to={redirectUrl} />;
};

export default RootPage;
