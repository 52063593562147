import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Row, Menu, Badge, Spin } from 'antd';
import { Route, Link, Switch, Redirect, withRouter } from 'react-router-dom';
import { withFirebase } from 'lib/Firebase';
import MinLayout from 'components/Common/MinLayout';
import { ReactComponent as IconInfluencerList } from 'images/icon-influencer-list.svg';
import { ReactComponent as IconShipmentsMade } from 'images/icon-shipments-made.svg';
import { ReactComponent as IconPostApproval } from 'images/icon-post-approval.svg';
import filterPosts from 'selectors/postApproval';
import { getInfluencerMessageUID } from 'actions/ui/messages';
import EmptyInfluencers from 'components/Common/EmptyInfluencers';
import { setInfluencerList, getInfluencerList } from 'actions/ui/influencerList';
import CampaignPolicy from 'policies/CampaignPolicy';
import Influencers from './Influencers';
import ProductShipments from './ProductShipments';
import PostApproval from './PostApproval';
import { appliedInfluencerByStatus } from '../../../selectors/campaignList';

class InfluencersSubMenu extends Component {
  state = {
    hasFetched: false
  };

  _isMounted = false;

  componentDidMount() {
    const { dispatch, campaign } = this.props;

    if (campaign) {
      const influencerUIDs = appliedInfluencerByStatus(campaign, 'approved');
      dispatch(setInfluencerList(influencerUIDs));
    }
    this.setState({ hasFetched: true });
  }

  componentWillUnmount() {
    this._isMounted = false;

    const { resetInfluencerList: resetInfluencers } = this.props;

    resetInfluencers();
  }

  onClickToMessageThread = influencerUID => {
    const {
      messages,
      history,
      match: { params }
    } = this.props;
    const messageUID = messages[`${params.campaignId}${influencerUID}`];
    history.push(`/campaigns/${params.campaignId}/messages/${messageUID}`);
  };

  defaultSelectedKeys = () => {
    const { location } = this.props;
    const defaultKeys = 'my_influencers';
    const arrPathname = location.pathname.split('/');

    return [arrPathname[4] || defaultKeys];
  };

  totalPostsSummary = () => {
    const { filterPosts: postFilter } = this.props;

    let { awaiting, approved, needsApproval } = postFilter;

    awaiting = Object.keys(awaiting).length;
    approved = Object.keys(approved).length;
    needsApproval = Object.keys(needsApproval).length;

    return `${approved}/${awaiting + approved + needsApproval}`;
  };

  render() {
    const { hasFetched } = this.state;
    const {
      influencerIds,
      match,
      needsShippingCount,
      postToApproveCount,
      shippedCount,
      isFetching
    } = this.props;

    return (
      <div style={{ background: '#ffffff' }}>
        {isFetching && (
          <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <Spin />
          </div>
        )}
        {hasFetched && !influencerIds.length && <EmptyInfluencers />}
        {hasFetched && !!influencerIds.length && (
          <div>
            <div
              style={{
                background: '#fcfcfc',
                borderBottom: '1px solid #e8e8e8'
              }}
            >
              <Row type="flex" justify="space-around">
                <MinLayout>
                  <Menu
                    mode="horizontal"
                    defaultSelectedKeys={this.defaultSelectedKeys()}
                    className="trend-menu-sub trend-menu-horizontal"
                  >
                    <Menu.Item key="my_influencers" style={{ width: '33.33%' }}>
                      <Link to={`/campaigns/${match.params.campaignId}/my_influencers`}>
                        <span className="ant-menu-icon">
                          <IconInfluencerList className="p-relative" style={{ left: '1px' }} />
                        </span>
                        <span
                          style={{
                            display: 'inline-block',
                            lineHeight: '1.2',
                            marginTop: '10px'
                          }}
                        >
                          <span className="tabs-value">{influencerIds.length}</span>
                          <span className="size-9px type-sbold spacing-05px">INFLUENCER LIST</span>
                        </span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="product_shipments" style={{ width: '33.33%' }}>
                      <Link
                        to={`/campaigns/${match.params.campaignId}/my_influencers/product_shipments`}
                      >
                        <span className="ant-menu-icon">
                          <IconShipmentsMade
                            className="p-relative"
                            style={{ top: '-1px', left: '1px' }}
                          />
                        </span>
                        <span
                          style={{
                            display: 'inline-block',
                            lineHeight: '1.2',
                            marginTop: '10px'
                          }}
                        >
                          <span className="tabs-value">
                            <Badge
                              count={needsShippingCount}
                              style={{ backgroundColor: '#ffb917' }}
                            />
                            {shippedCount}/{influencerIds.length}
                          </span>
                          <span className="size-9px type-sbold spacing-05px">SHIPMENTS MADE</span>
                        </span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="post_approval" style={{ width: '33.33%' }}>
                      <Link
                        to={`/campaigns/${match.params.campaignId}/my_influencers/post_approval`}
                      >
                        <span className="ant-menu-icon">
                          <IconPostApproval
                            className="p-relative"
                            style={{ top: '-1px', left: '-1px' }}
                          />
                        </span>
                        <span
                          style={{
                            display: 'inline-block',
                            lineHeight: '1.2',
                            marginTop: '10px'
                          }}
                        >
                          <span className="tabs-value">
                            <Badge
                              count={postToApproveCount}
                              style={{ backgroundColor: '#ffb917' }}
                            />
                            {this.totalPostsSummary()}
                          </span>
                          <span className="size-9px type-sbold spacing-05px">POST APPROVAL</span>
                        </span>
                      </Link>
                    </Menu.Item>
                  </Menu>
                </MinLayout>
              </Row>
            </div>
            <Row id="influencers-page">
              <Switch>
                <Route
                  exact
                  path="/campaigns/:campaignId/my_influencers"
                  render={() => (
                    <Influencers onClickToMessageThread={this.onClickToMessageThread} />
                  )}
                />
                <Route
                  path="/campaigns/:campaignId/my_influencers/product_shipments"
                  component={ProductShipments}
                />
                <Route
                  path="/campaigns/:campaignId/my_influencers/post_approval"
                  render={() => (
                    <PostApproval onClickToMessageThread={this.onClickToMessageThread} />
                  )}
                />
                <Redirect
                  from={match.url}
                  to={`/campaigns/${match.params.campaignId}/my_influencers`}
                />
              </Switch>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, { match: { params } }) => ({
  influencers: state.entities.influencers.byId,
  influencerIds: state.ui.campaignDashboard.byCampaignId[params.campaignId].influencerUIDs || [],
  campaign: state.entities.campaigns.byId[state.ui.campaignDashboard.currentCampaign.campaignUID],
  needsShippingCount: state.ui.campaignDashboard.byCampaignId[params.campaignId].needsShippingCount,
  shippedCount: state.ui.campaignDashboard.byCampaignId[params.campaignId].shippedCount,
  postToApproveCount: state.ui.campaignDashboard.byCampaignId[params.campaignId].postToApproveCount,
  filterPosts: filterPosts(
    params.campaignId,
    state.entities.influencers.byId,
    state.ui.influencerList
  ),
  messages: state.entities.messages.byCampaignInfluencer,
  isFetching: state.entities.influencers.isFetching
});

const mapDispatchToProps = dispatch => ({
  resetInfluencerList: () => dispatch(setInfluencerList([])),
  dispatch,
  getInfluencerList: (args, onComplete) => dispatch(getInfluencerList(args, onComplete)),
  getInfluencerMessageUID: (brandUID, influencerUID, callback) =>
    dispatch(getInfluencerMessageUID(brandUID, influencerUID, callback))
});

export default compose(
  CampaignPolicy,
  withRouter,
  withFirebase,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(InfluencersSubMenu);
