import React from 'react';
import UpperCaseLabel from 'components/Common/UpperCaseLabel';
import { Skeleton, Icon } from 'antd';
import { connect } from 'react-redux';
import CurrencyFormat from 'react-currency-format';
import { UpCaseFirstLetter } from 'lib/Common';
import STATES from 'constants/states';
import { ReactComponent as ImageIcon } from '../../../images/image-post-copy.svg';
import { ReactComponent as VideoIcon } from '../../../images/video-post.svg';
import { ReactComponent as StoryIcon } from '../../../images/ig-stories.svg';

const appendHttpProtocol = link => (/http/.test(link) ? link : `http://${link}`);

const requiredPost = campaign => {
  const { numberOfPosts, postTypes = [], isStoryRequired } = campaign;
  if (postTypes.length < 1) {
    return (
      <div className="required-posts" style={{ marginTop: '21px' }}>
        <UpperCaseLabel bold>Required Posts</UpperCaseLabel>
        <div style={{ marginTop: '21px' }}>
          <div className="label-value">
            <Icon className="post-icons" component={ImageIcon} />
            {numberOfPosts} Image Post
          </div>
        </div>
      </div>
    );
  }

  const videoCount = postTypes.filter(x => x === 'video').length;
  const imageCount = postTypes.filter(x => x === 'image').length;

  return (
    <div className="required-posts" style={{ marginTop: '21px' }}>
      <UpperCaseLabel bold>Required Posts</UpperCaseLabel>
      {imageCount > 0 && (
        <div style={{ marginTop: '21px' }}>
          <div className="label-value">
            <Icon className="post-icons" component={ImageIcon} />
            {imageCount} Image Post
          </div>
        </div>
      )}

      {videoCount > 0 && (
        <div style={{ marginTop: '21px' }}>
          <div className="label-value">
            <Icon className="post-icons" component={VideoIcon} />
            {videoCount} Video Post
          </div>
        </div>
      )}
      {isStoryRequired && (
        <div style={{ marginTop: '21px' }}>
          <div className="label-value" style={{ textTransform: 'capitalize' }}>
            <Icon className="post-icons" component={StoryIcon} />1 Instagram Story
          </div>
        </div>
      )}
    </div>
  );
};

const RightSideInfo = ({
  productValue,
  category,
  gender,
  states,
  hashtags,
  brandtags,
  currentCampaign
}) => (
  <div
    className="post-requirements"
    style={{
      marginTop: '23px',
      paddingTop: '23px',
      borderTop: '1px solid #e8e8e8'
    }}
  >
    <Skeleton loading={!currentCampaign} title={false} paragraph={{ rows: 10 }}>
      <h3 className="size-13px text-black">Campaign Details</h3>
      <div style={{ marginTop: '21px' }}>
        <UpperCaseLabel bold>website</UpperCaseLabel>
        <div className="label-value">
          <a
            href={appendHttpProtocol(currentCampaign.url)}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#000000' }}
          >
            {currentCampaign.url}
          </a>
        </div>
      </div>
      <div style={{ marginTop: '21px' }}>
        <UpperCaseLabel>product value</UpperCaseLabel>
        <div className="label-value">
          <CurrencyFormat value={productValue} displayType="text" thousandSeparator prefix="$" />
        </div>
      </div>
      <div style={{ marginTop: '30px' }}>
        <UpperCaseLabel>Product Category</UpperCaseLabel>
        <div className="label-value" style={{ textTransform: 'capitalize' }}>
          {category}
        </div>
      </div>
      <h3
        style={{
          borderTop: '1px solid #e8e8e8'
        }}
        className="size-13px mt-6 pt-6 text-black"
      >
        Post Requirements
      </h3>
      {requiredPost(currentCampaign)}
      <div style={{ marginTop: '21px', wordBreak: 'break-all' }}>
        <UpperCaseLabel>REQUIRED HASHTAGS</UpperCaseLabel>
        {Object.values(hashtags || {}).map(tag => (
          <span
            style={{
              fontFamily: 'truenoextrabold',
              color: '#000000',
              fontSize: '12px'
            }}
            key={tag}
          >
            <span className="mr-1">#{tag}</span>
          </span>
        ))}
      </div>
      <div style={{ marginTop: '30px' }}>
        <UpperCaseLabel>REQUIRED BRANDTAGS</UpperCaseLabel>
        {Object.values(brandtags || {}).map(tag => (
          <span
            style={{
              fontFamily: 'truenoextrabold',
              color: '#000000',
              fontSize: '12px'
            }}
            key={tag}
          >
            <span className="mr-1">@{tag}</span>
          </span>
        ))}
      </div>
      <h3
        style={{
          borderTop: '1px solid #e8e8e8'
        }}
        className="size-13px mt-6 pt-6 text-black"
      >
        Target States
      </h3>
      <div className="size-12px post-requirements-states" style={{ marginTop: '21px' }}>
        {!Object.values(states || {}).length && <span className="label-value"> All </span>}
        {Object.values(states || {}).map((state, index) => (
          <div className="post-requirements-states-state" key={state}>
            <span className="label-value">
              <span className="circle">{index + 1}</span>
              <span className="mr-1">{STATES[state] || state}</span>
            </span>
          </div>
        ))}
      </div>
      <h3
        style={{
          borderTop: '1px solid #e8e8e8'
        }}
        className="size-13px mt-6 pt-6 text-black"
      >
        Gender
      </h3>
      <div className="label-value" style={{ marginTop: '21px' }}>
        {UpCaseFirstLetter(gender || 'Any')}
      </div>
    </Skeleton>
  </div>
);

const mapStateToProps = state => ({
  currentCampaign: state.ui.campaignDashboard.currentCampaign
});

export default connect(mapStateToProps)(RightSideInfo);
