import React from 'react';
import { Empty } from 'antd';
import NoInfluencersImg from 'images/DefaultEmpty/no-influencers.png';

const EmptyInfluencers = () => (
  <Empty
    className="empty-no-applications"
    style={{
      marginTop: '66px',
      marginBottom: '109px'
    }}
    image={NoInfluencersImg}
    description={
      <span className="empty-desc-header type-sfpro-bold mb-2 mt-6">No Influencers</span>
    }
  >
    <div className="mt-1">
      <div className="empty-desc-body">
        Once you approve Influencers,
        <br className="hidden-mobile" />
        you’ll see their profile, shipping information and posts here.
        <br className="hidden-mobile" />
        This will be your HUB for managing the campaign with your approved influencers.
      </div>
    </div>
  </Empty>
);

export default EmptyInfluencers;
