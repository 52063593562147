import React from 'react';
import { List, Tag, Icon, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BrandLoginButton from './BrandLoginButton';
import RestartTrialButton from './RestartTrialButton';
import RemoveTrialButton from './RemoveTrialButton';

const BrandTable = ({ ids = [], loading, fetchBrands }) => {
  const brandById = useSelector(state => state.entities.brands.byId);
  if (loading) {
    return (
      <div style={{ textAlign: 'center', margin: '70px 0 70px' }}>
        <Spin indicator={<Icon type="loading" />} />
      </div>
    );
  }

  return (
    <List
      dataSource={ids}
      renderItem={brandId => {
        const brand = brandById[brandId];
        const details = brand.details || {};
        const { firstCampaign, firstCredit, status, lastPurchaseDate } = details;
        const approvedStatus = status === 'approved';

        const actions = [
          <Link className="ant-btn ant-btn-primary" to={`/admin/brands/${brandId}`}>
            Edit
          </Link>,
          <BrandLoginButton brandId={brandId} />
        ];

        if (firstCampaign && !firstCredit) {
          actions.unshift(<RestartTrialButton brandUID={brandId} />);
        }
        if (approvedStatus && firstCampaign && !lastPurchaseDate) {
          actions.unshift(<RemoveTrialButton brandUID={brandId} fetchBrands={fetchBrands} />);
        }

        return (
          <List.Item actions={actions}>
            <List.Item.Meta
              title={details.name || '[ NO NAME ]'}
              description={(
                <div>
                  {details.stripeCustomerId && (
                  <Tag color="green">Stripe Id: {details.stripeCustomerId}</Tag>
                  )}
                  {details.isFreeTrial && <Tag color="orange">Free Trial</Tag>}
                  {brand.products && Object.keys(brand.products).length && (
                  <Tag color="volcano">{Object.keys(brand.products).length} Campaign/s</Tag>
                  )}
                  {details.isContentAdmin && <Tag color="cyan">Content Admin</Tag>}
                </div>
)}
            />
          </List.Item>
        );
      }}
    />
  );
};

export default BrandTable;
