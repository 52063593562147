import {
  SET_INFLUENCER,
  SET_INFLUENCERS,
  UPDATE_INFLUENCER,
  UPDATE_INFLUENCER_DETAILS,
  UPDATE_INFLUENCER_POST,
  INFLUENCER_FETCH_REQUEST,
  INFLUENCER_RECEIVE_REQUEST
} from 'reducers/entities/influencers';
import { firebase } from '../lib/Firebase';
import { UPDATE_INFLUENCER_CAMPAIGN_POSTS } from '../reducers/entities/influencers';

import { RECEIVE_INFLUENCER_SEARCHES } from '../reducers/entities/influencerSearch';

export const fetchRequest = () => ({ type: INFLUENCER_FETCH_REQUEST });

export const receiveRequest = () => ({ type: INFLUENCER_RECEIVE_REQUEST });

export const setInfluencers = influencers => ({
  type: SET_INFLUENCERS,
  influencers
});

export const setInfluencer = influencer => ({
  type: SET_INFLUENCER,
  influencer
});

export const updateInfluencer = (influencerUID, influencer) => ({
  type: UPDATE_INFLUENCER,
  influencerUID,
  influencer
});

export const updateInfluencerDetails = (influencerUID, influencerDetails) => ({
  type: UPDATE_INFLUENCER_DETAILS,
  influencerUID,
  details: influencerDetails
});

export const updateInfluencerPost = (
  influencerUID,
  campaignUID,
  campaignStatus,
  postUID,
  postDetails
) => ({
  type: UPDATE_INFLUENCER_POST,
  influencerUID,
  campaignUID,
  campaignStatus,
  postUID,
  postDetails
});

export const updateInfluencerCampaignPosts = (influencerUID, campaignUID, campaignPosts) => ({
  type: UPDATE_INFLUENCER_CAMPAIGN_POSTS,
  influencerUID,
  campaignUID,
  campaignPosts
});

export const fetchInfluencer = influencerUID => dispatch => {
  firebase.influencer(influencerUID).once('value', snapshot => {
    const influencer = {};

    influencer[influencerUID] = {
      influencerUID,
      ...snapshot.val()
    };

    dispatch(setInfluencer(influencer));
  });
};

export const receiveInfluencerSearches = influecerSearches => ({
  type: RECEIVE_INFLUENCER_SEARCHES,
  influecerSearches
});

const influencerLimit = 100;

export const getAllInfluencers = lastDoc => async dispatch => {
  let influencerSnap = firebase
    .influencerSearch()
    .orderBy('createdAt', 'desc')
    .limit(influencerLimit);

  if (lastDoc) {
    influencerSnap = influencerSnap.startAfter(lastDoc);
  }

  const result = await influencerSnap.get();

  if (result.empty) {
    return;
  }

  const influencerData = result.docs;
  let influencerSearchData = {};
  let tmpLastDoc = null;
  influencerData.forEach(doc => {
    influencerSearchData = {
      ...influencerSearchData,
      [doc.id]: {
        ...doc.data()
      }
    };
    tmpLastDoc = doc;
  });

  dispatch(receiveInfluencerSearches(influencerSearchData));

  const resLength = influencerData.length;

  if (resLength > influencerLimit - 1) {
    // call getAllBrandSearch again
    await getAllInfluencers(tmpLastDoc)(dispatch);
  }
};
