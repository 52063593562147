import { ADD_BRAND_LISTENER } from '../../reducers/listeners/brands';
import { firebase } from '../../lib/Firebase';
import { updateBrand } from '../../actions/brands';

const addBrandListener = brandUID => ({
  type: ADD_BRAND_LISTENER,
  payload: brandUID
});

export const listenBrand = brandUID => async (dispatch, getState) => {
  const {
    listeners: { brands = [] }
  } = getState();

  if (!brands.includes(brandUID)) {
    dispatch(addBrandListener(brandUID));
    firebase.brand(brandUID).on('value', brandSnap => {
      dispatch(updateBrand(brandUID, brandSnap.val()));
    });
  }
};
