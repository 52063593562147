import { fetchRequest, receiveRequest } from './common/request';

export const CAMPAIGN_FETCH_REQUEST = 'CAMPAIGN_FETCH_REQUEST';
export const CAMPAIGN_RECEIVE_REQUEST = 'CAMPAIGN_RECEIVE_REQUEST';
export const RECEIVE_CAMPAIGNS = 'RECEIVE_CAMPAIGNS';
export const RECEIVE_CAMPAIGN = 'RECEIVE_CAMPAIGN';
export const FETCH_BRAND_CAMPAIGNS = 'FETCH_BRAND_CAMPAIGNS';
export const ADD_CAMPAIGN = 'ADD_CAMPAIGN';
export const EDIT_CAMPAIGN = 'EDIT_CAMPAIGN';
export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';
export const APPROVED_APPLIEDUIDS = 'APPROVED_APPLIEDUIDS';

const defaultState = {
  byId: {
    example: {
      appliedUID: {}
    }
  },
  all: [],
  owned: [],
  isFetching: false,
  approvedAppliedUIDs: []
};

const addCampaign = (state, campaign) => ({
  ...state,
  byId: {
    ...state.byId,
    ...campaign
  },
  all: [...new Set(state.all.concat(Object.keys(campaign)))]
});

const addCampaigns = (state, campaigns) => {
  let newState = state;

  Object.keys(campaigns).forEach(campaignUID => {
    newState = addCampaign(newState, { [campaignUID]: campaigns[campaignUID] });
  });

  return newState;
};

const addOwnedCampaigns = (state, campaignIds) => ({
  ...state,
  owned: [...new Set(state.owned.concat(campaignIds))]
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case RECEIVE_CAMPAIGNS:
      return { ...addCampaigns(state, action.payload), isFetching: false };

    case RECEIVE_CAMPAIGN:
      return { ...addCampaign(state, action.payload) };

    case ADD_CAMPAIGN: {
      let addNewState = addCampaign(state, action.payload);
      addNewState = addOwnedCampaigns(addNewState, Object.keys(action.payload));
      return { ...addNewState, newCampaign: action.payload };
    }

    case UPDATE_CAMPAIGN:
      return {
        ...state,
        byId: { ...state.byId, ...action.payload },
        newCampaign: action.payload
      };

    case APPROVED_APPLIEDUIDS:
      return {
        ...state,
        approvedAppliedUIDs: [
          ...new Set(state.approvedAppliedUIDs.concat(action.approvedAppliedUIDs))
        ]
      };

    case CAMPAIGN_FETCH_REQUEST:
      return fetchRequest(state);

    case CAMPAIGN_RECEIVE_REQUEST:
      return receiveRequest(state);

    default:
      return state;
  }
};
