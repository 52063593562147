import React from 'react';
import { connect } from 'react-redux';
import { setPostGraphFilter } from 'actions/ui/postGraph';
import { Button } from 'antd';

const ButtonGroup = Button.Group;

const Filter = ({ setFilter, filteredBy }) => (
  <div className="post-filter-action">
    <ButtonGroup>
      <Button className="post-filter-btn" onClick={() => setFilter('1week')} disabled={filteredBy === '1week'}>1W</Button>
      <Button className="post-filter-btn" onClick={() => setFilter('1month')} disabled={filteredBy === '1month'}>1M</Button>
      <Button className="post-filter-btn" onClick={() => setFilter('3months')} disabled={filteredBy === '3months'}>3M</Button>
      <Button className="post-filter-btn" onClick={() => setFilter('6months')} disabled={filteredBy === '6months'}>6M</Button>
      <Button className="post-filter-btn" onClick={() => setFilter('1year')} disabled={filteredBy === '1year'}>1Y</Button>
    </ButtonGroup>
  </div>
);

const mapStateToProps = state => ({
  filteredBy: state.ui.postGraph.filteredBy,
});

const mapDispatchToProps = dispatch => ({
  setFilter: filteredBy => dispatch(setPostGraphFilter(filteredBy)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
