import { firebase } from 'lib/Firebase';
import * as Promise from 'bluebird';
import { SET_STORE_ANALYTICS } from 'reducers/ui/storeAnalytics';
import { fetchRequest, receiveRequest, addPosts } from 'actions/posts';
import { setInfluencer } from 'actions/influencers';

export const setStoreAnalytics = (brandUID, onComplete = null) => (dispatch) => {
  dispatch(fetchRequest());
  firebase.brand(brandUID).child('products').orderByValue().equalTo('active').once('value', (data) => {
    if (data.exists()) {
      const productIds = Object.keys(data.val())
      let influencersProduct = [];
      let influencerIds = [];
      Promise.map(productIds, (productId) => {
        return firebase.activeProduct(productId).child('appliedUID').orderByChild('status').equalTo(`approved`).once('value', (pdata) => {
          if (pdata.exists()) {
            Object.keys(pdata.val()).forEach((influencerId) => {
              influencersProduct.push({ influencerId, productId });
              influencerIds.push(influencerId);
            });
          }
        });
      }, { concurrency: 5 } ).then(() => {
        Promise.map([...new Set(influencerIds)], (influencerUID) => {
          return firebase.influencer(influencerUID).once('value', (influencerData) => {
            if (influencerData.exists()) {
              dispatch(setInfluencer({ [influencerUID]: influencerData.val() }));
            }
          });
        });

        let postIds = [];
        Promise.map(influencersProduct, (item) => {
          return firebase.influencer(item.influencerId).child(`partnerships/products/${item.productId}/posts`).orderByChild('details/status').equalTo("approved").once("value", (data) => {
            if (data.exists()) {
              postIds = [...postIds, ...Object.keys(data.val())];
              Object.entries(data.val()).forEach(([id, post]) => {
                post.productUID = item.productId;
                post.influencerUID = item.influencerId;
                dispatch(addPosts({ [id]: post }));
              });
            }
          });
        }).then(() => {
          if (onComplete) { onComplete(postIds); }
          dispatch({ type: SET_STORE_ANALYTICS, payload: postIds })
          dispatch(receiveRequest());
        });
      });
    }
    if (onComplete) { onComplete([]); }
  });
};

export default { setStoreAnalytics };
