import React, { useEffect, useState } from 'react';
import { Table, Button, Spin } from 'antd';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import Container from '../../../components/Common/Container';
import { firebase } from '../../../lib/Firebase';
import { listenBrand } from '../../../stores/listeners/brands';

const columns = [
  {
    key: 'companyName',
    dataIndex: 'companyName',
    title: 'Company Name'
  },
  {
    key: 'contactName',
    dataIndex: 'contactName',
    title: 'Contact Name'
  },
  {
    key: 'submitedDate',
    title: 'Submitted Date',
    render: ({ submittedAt }) => moment(submittedAt * 1000).format('MMM DD, YYYY')
  },
  {
    key: 'brand',
    title: 'Referred By',
    render: ({ brand }) => {
      if (!brand) return <Spin />;
      return brand && brand.details && brand.details.name;
    }
  },
  {
    key: 'actions',
    name: 'actions',
    title: 'Action',
    render: ({ uid, brandUID, onAccept, onReject, loading, status }) => {
      if (status !== 'pending') {
        return status.toUpperCase();
      }
      return (
        <div style={{ minWidth: '193px' }}>
          <Spin spinning={loading}>
            <Button type="primary" onClick={() => onAccept(uid, brandUID)}>
              Accept
            </Button>

            <Button
              type="danger"
              onClick={() => onReject(uid, brandUID)}
              style={{ marginLeft: '10px' }}
            >
              Reject
            </Button>
          </Spin>
        </div>
      );
    }
  }
];
const Page = () => {
  const dispatch = useDispatch();
  const initialPagination = { page: 1, limit: 20 };
  const [pagination, setPagination] = useState(initialPagination);
  const [referrals, setReferrals] = useState([]);
  const [loading, setLoading] = useState([]);
  const brandById = useSelector(state => state.entities.brands.byId);
  const [lastDoc, setLastDoc] = useState({});

  const fetch = async (lastDocParam = null) => {
    let referralsSnap = firebase.firestore
      .collection('referrals')
      .orderBy('submittedAt', 'desc')
      .limit(20);

    if (lastDocParam) {
      referralsSnap = referralsSnap.startAfter(lastDocParam);
    }

    referralsSnap = await referralsSnap.get();

    if (!referralsSnap.empty) {
      const list = [];
      let data = null;
      referralsSnap.docs.forEach(reviewDoc => {
        const docData = reviewDoc.data();
        list.push({ uid: reviewDoc.id, ...docData });
        data = reviewDoc;
        dispatch(listenBrand(docData.brandUID));
      });

      setReferrals(prevState => {
        return [...prevState, ...list];
      });
      setLastDoc(data);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  async function handleAccept(referralUID, brandUID) {
    setLoading(state => [...state, referralUID]);
    await firebase.firestore
      .collection('referrals')
      .doc(referralUID)
      .update({
        status: 'approved',
        approvedAt: moment().unix()
      });

    const brandSnap = await firebase
      .brand(brandUID)
      .child('details')
      .once('value');
    const brand = brandSnap.val();
    const { standardCredits } = brand;

    await brandSnap.ref.update({
      standardCredits: standardCredits + 5
    });

    const [selectedReferral] = referrals.filter(x => x.uid === referralUID);
    const selectedIndex = referrals.indexOf(selectedReferral);
    referrals[selectedIndex].status = 'approved';
    setReferrals([...referrals]);

    setLoading(state => [...state.filter(x => x !== referralUID)]);
  }

  async function handleReject(referralUID) {
    setLoading(state => [...state, referralUID]);
    await firebase.firestore
      .collection('referrals')
      .doc(referralUID)
      .update({
        status: 'rejected',
        rejectedAt: moment().unix()
      });

    const [selectedReferral] = referrals.filter(x => x.uid === referralUID);
    const selectedIndex = referrals.indexOf(selectedReferral);
    referrals[selectedIndex].status = 'rejected';
    setReferrals([...referrals]);
    setLoading(state => [...state.filter(x => x !== referralUID)]);
  }

  const dataSource = referrals.map(review => {
    return {
      key: review.uid,
      ...review,
      brand: brandById[review.brandUID],
      loading: loading.includes(review.uid),
      onAccept: handleAccept,
      onReject: handleReject
    };
  });

  function loadMore() {
    setPagination({
      ...pagination,
      page: pagination.page + 1
    });

    fetch(lastDoc);
  }

  const showLimit = pagination.limit * pagination.page - pagination.page;
  return (
    <Container width={940} id="admin-business-approval">
      <Table dataSource={dataSource} columns={columns} pagination={false} showHeader />
      <div className="text-center loadmore">
        {dataSource.length > showLimit && (
          <Button className="load-more" onClick={loadMore}>
            LOAD MORE
          </Button>
        )}
      </div>
    </Container>
  );
};

export default Page;
