import React from 'react';
import { Form, Button, Select } from 'antd';
import TrendSelect from '../../Form/TrendSelect';

const { Option } = Select;
class Creators extends React.Component {
  state = {
    submitting: false
  };

  handleSubmit = () => {
    const {
      onSubmit,
      onError,
      form: { validateFieldsAndScroll }
    } = this.props;

    validateFieldsAndScroll(async (error, fields) => {
      if (!error) {
        if (onSubmit) {
          onError(false);
          onSubmit({ ...fields, states: { All: 'All' } });
        }
      } else {
        onError(true);
        this.setState({ submitting: false });
      }
    });
  };

  render() {
    const { submitting } = this.state;
    const { form, campaign } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form
        layout="vertical"
        className="product-form pb-8"
        hideRequiredMark
        onSubmit={this.handleSubmit}
      >
        <h1 className="page-header">Target Creators</h1>
        <div className="page-description">
          Put on your creative director hat and get specific about the kind of content you want the
          creators to produce.
        </div>
        <Form.Item label="Target Gender">
          {getFieldDecorator('gender', {
            initialValue: campaign.gender
          })(
            <TrendSelect type="dark" placeholder="Select Gender">
              <Option value={null}>Any</Option>
              <Option value="male">Male</Option>
              <Option value="female">Female</Option>
            </TrendSelect>
          )}
        </Form.Item>
        <div align="center">
          <Button
            size="large"
            type="submit"
            className="trend-btn product-btn"
            loading={submitting}
            onClick={this.handleSubmit}
          >
            Next Step - Social Media Details
          </Button>
        </div>
      </Form>
    );
  }
}

export default Form.create({ name: 'basic-pane' })(Creators);
