/* eslint-disable import/prefer-default-export */
import * as api from 'api/brands';
import { updateBrandDetails } from 'actions/brands';

export const addCredits = (
  uid,
  selectedStandardCredits,
  selectedVipCredits,
  onComplete = null
) => () => {
  api.addCredits(uid, selectedStandardCredits, selectedVipCredits, (response, error) => {
    if (error) {
      onComplete(response, error);
    } else {
      const brand = response.data;
      updateBrandDetails(uid, brand.details);
      onComplete(response.data, null);
    }
  });
};
