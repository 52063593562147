import React from 'react';
import { ReactComponent as TrendLogoWeb } from 'images/TrendLogoBlack.svg';
import { Row, Col, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

const PreOnboarding = () => {
  const history = useHistory();
  function goToCreateCampaign() {
    history.push(ROUTES.FIRST_CAMPAIGN_PATH);
  }

  return (
    <div style={{ background: '#fff', width: '100%', height: '100vh' }}>
      <Row type="flex" justify="center">
        <Col style={{ width: 740 }}>
          <div style={{ textAlign: 'center', marginBottom: 33, marginTop: 208 }}>
            <TrendLogoWeb width={120} />
          </div>
          <h1
            style={{
              lineHeight: '54px',
              fontSize: '40px',
              textAlign: 'center',
              marginBottom: '22px'
            }}
          >
            Our creators are ready to produce high quality content for your brand
          </h1>
          <Row type="flex" justify="center" style={{ marginBottom: 17 }}>
            <Col style={{ width: 480 }}>
              <p
                className="type-sfpro-regular"
                style={{
                  height: 75,
                  fontSize: '18px',
                  lineHeight: '26px',
                  textAlign: 'center',
                  color: '#000'
                }}
              >
                Start your first campaign for free and immediately collect real applications from
                creators eager to work for you.{' '}
              </p>
            </Col>
          </Row>

          <div style={{ textAlign: 'center', marginBottom: 54 }}>
            <Button
              type="primary"
              style={{
                width: 310,
                borderRadius: 32,
                height: 56,
                color: '#000',
                fontSize: 20,
                fontStyle: 'italic',
                fontFamily: 'SFProDisplay',
                fontWeight: 800
              }}
              onClick={() => {
                goToCreateCampaign();
              }}
            >
              Start your first campaign
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PreOnboarding;
