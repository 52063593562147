import React from 'react';
import { connect } from 'react-redux';
import CurrencyFormat from 'react-currency-format';
import { Row, Col } from 'antd';
import { IMPRESSIONS } from 'constants/posts';
import UpperCaseLabel from 'components/Common/UpperCaseLabel';

const PostStat = ({ post }) => (
  <Row className="mt-3 post-stats">
    <Col span={8}>
      <h2 className="m-0 size-13px" style={{ fontStyle: 'italic' }}>
        <CurrencyFormat
          value={(post.isVideo ? post.views : post.likes) || 0}
          displayType="text"
          thousandSeparator
        />
      </h2>
      <UpperCaseLabel>{post.isVideo ? 'Views' : 'Likes'}</UpperCaseLabel>
    </Col>
    <Col span={8}>
      <h2 className="m-0 size-13px" style={{ fontStyle: 'italic' }}>
        <CurrencyFormat value={post.comments || 0} displayType="text" thousandSeparator />
      </h2>
      <UpperCaseLabel>Comments</UpperCaseLabel>
    </Col>
    <Col span={8}>
      <h2 className="m-0 size-13px" style={{ fontStyle: 'italic' }}>
        <CurrencyFormat
          value={(post.likes || 0) * IMPRESSIONS}
          displayType="text"
          thousandSeparator
        />
      </h2>
      <UpperCaseLabel>Impressions</UpperCaseLabel>
    </Col>
  </Row>
);

const mapStateToProps = (state, { postUID, post }) => ({
  post: post || state.entities.posts.byId[postUID]
});

export default connect(mapStateToProps)(PostStat);
