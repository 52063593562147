export const ADD_POST_STATISTICS = 'ADD_POST_STATISTICS';
const initialState = {
  byId: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_POST_STATISTICS: {
      return {
        ...state.byId,
        byId: {
          ...action.payload,
        },
      };
    }

    default:
      return state;
  }
};
