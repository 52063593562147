import React, { useEffect, useState } from 'react';
import { Col, Select } from 'antd';
import SearchBrands from './SearchBrands';
import SearchInfluencers from './SearchInfluencers';
import { firebase } from '../../../lib/Firebase';
import { UpCaseFirstLetter } from '../../../lib/Common';

const PostTypeFilter = props => {
  const { setFilters, filters } = props;
  const [categories, setCategories] = useState(false);

  const handleChange = type => val => {
    const newState = {
      ...filters,
      [type]: val
    };

    setFilters(newState);
  };

  useEffect(() => {
    firebase.adminCategories().on('value', snap => {
      const categoriesSnap = snap.val();
      const categoryTmp = [];
      Object.entries(categoriesSnap).forEach(([id, category]) => {
        categoryTmp.push(
          <Select.Option key={id} value={category}>
            {UpCaseFirstLetter(category)}
          </Select.Option>
        );
      });

      setCategories(categoryTmp);
    });
  }, []);

  return (
    <>
      <Col span={6} md={6}>
        <SearchBrands onChange={handleChange('brand')} />
      </Col>
      <Col span={6} md={6}>
        <SearchInfluencers onChange={handleChange('creator')} />
      </Col>
      <Col span={6} md={6}>
        <Select
          allowClear
          showArrow={false}
          className="trend-select"
          placeholder="Category: All"
          style={{ width: '200px' }}
          onChange={handleChange('category')}
        >
          {categories}
        </Select>
      </Col>
      <Col span={6} md={6}>
        <Select
          allowClear
          showArrow={false}
          className="trend-select"
          placeholder="Type: All"
          style={{ width: '200px' }}
          onChange={handleChange('type')}
        >
          <Select.Option value="image">Image</Select.Option>
          <Select.Option value="video">Video</Select.Option>
          <Select.Option value="story">Story</Select.Option>
        </Select>
      </Col>
    </>
  );
};

export default PostTypeFilter;
