import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Layout,
  Row,
  Spin,
  Divider,
} from 'antd';
import { withFirebase } from 'lib/Firebase';
import { withAuthorization } from 'lib/Session'
import { compose } from 'recompose';
import { setStoreAnalytics } from 'actions/ui/storeAnalytics';
import Summary from './Summary';
import Posts from './Posts';

const { Content } = Layout;

class Analytics extends Component {
  componentDidMount() {
    this.props.setStoreAnalytics(this.props.authUser.uid)
  }

  render() {
    return (
      <Content style={{ background: '#fff', minHeight: 600 }} className="content-padding">
        <Row type="flex" justify="space-around" gutter={16}>
          <div className="min-layout-content">
            <h1>Store Analytics</h1>
            <Spin spinning={this.props.fetching}>
              <Summary />
              <Divider />
              <Posts />
            </Spin>
          </div>
        </Row>
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  fetching: state.entities.posts.isFetching
})

const mapDispatchToProps = (dispatch) => ({
  setStoreAnalytics: (brandUID) => dispatch(setStoreAnalytics(brandUID))
})

const condition = authUser => !!authUser;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase,
  withAuthorization(condition)
)(Analytics);
