/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { Drawer, Avatar, Row, Icon, Col } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { ReactComponent as VipIcon } from '../../../images/icon-vip-profile.svg';
import RecentPost from './RecentPost';
import Stats from './Stats';
import Biography from '../Biography';
import { ReactComponent as CloseIcon } from '../../../images/close-x.svg';
import PreviousWork from './PreviousWork';
import Reviews, { Stats as ReviewStats } from '../Reviews';

class InfluencerDrawer extends Component {
  handleHide = () => {
    const { onHide } = this.props;
    if (onHide) {
      onHide();
    }
  };

  displayActions = () => {
    const { actionButtons, influencerUID } = this.props;

    if (!actionButtons) {
      return <div />;
    }

    return actionButtons(influencerUID).map((action, index) => {
      return (
        <Col span={12} key={index}>
          {action}
        </Col>
      );
    });
  };

  render() {
    const { show, influencer, influencerUID } = this.props;
    if (!influencer) {
      return <div />;
    }

    const { details, instagram = {}, reviews } = influencer;
    const {
      avgCommentCount = 0,
      avgLikeCount = 0,
      images = [],
      biography,
      followedByCount,
      engagementRate
    } = instagram;

    const lastWeek = moment()
      .subtract(1, 'week')
      .format('YYYY-w');
    const { stats = {} } = instagram;

    return (
      <Drawer
        id="influencer-drawer"
        placement="right"
        width={328}
        visible={show}
        onClose={this.handleHide}
        closable={false}
        maskClosable
      >
        <div className="ant-drawer-header-no-title">
          <button
            onClick={this.handleHide}
            type="button"
            aria-label="Close"
            className="ant-drawer-close"
          >
            <Icon component={CloseIcon} />
          </button>
        </div>
        <div className="influencer-info">
          <div className="influencer-info-avatar">
            <a
              href={`https://instagram.com/${details.username}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Avatar size={90} src={details.image} />
            </a>
            {details.isVIP && <Icon className="influencer-info-vip" component={VipIcon} />}
          </div>
          <h1 className="influencer-info-name">@{details.username}</h1>
          <div className="influencer-info-address">
            {details.state} | Age: {details.ageRange}
          </div>
        </div>
        <Row className="influencer-info-actions" gutter={8}>
          {this.displayActions()}
        </Row>
        <Stats
          followedByCount={followedByCount}
          avgCommentCount={avgCommentCount}
          avgLikeCount={avgLikeCount}
          stats={stats[lastWeek]}
          engagementRate={engagementRate}
        />
        <ReviewStats reviews={reviews} />
        <Reviews influencerUID={influencerUID} />
        <RecentPost images={images} />
        <Biography text={biography} />
        <PreviousWork influencerUID={influencerUID} />
      </Drawer>
    );
  }
}

const mapStateToProps = (state, { influencerUID }) => ({
  influencer: state.entities.influencers.byId[influencerUID]
});

export default connect(mapStateToProps)(InfluencerDrawer);
