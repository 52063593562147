import { RECEIVE_BRAND_SEARCH, RECEIVE_BRAND_SEARCHES } from 'reducers/entities/brandSearch';
import { firebase } from '../lib/Firebase';

export const receiveBrandSearches = brandSearches => ({
  type: RECEIVE_BRAND_SEARCHES,
  brandSearches
});

export const receiveBrandSearch = brandSearch => ({
  type: RECEIVE_BRAND_SEARCH,
  brandSearch
});

const brandSearchLimit = 100;

export const getAllBrandSearches = brandId => async dispatch => {
  let brandSearchRef = firebase.brandSearch().limit(brandSearchLimit);

  if (brandId) {
    const brandSearch = await firebase
      .brandSearch()
      .doc(brandId)
      .get();
    brandSearchRef = brandSearchRef.startAfter(brandSearch);
  }

  const brandSnap = await brandSearchRef.get();

  if (brandSnap.empty) {
    return;
  }

  const brandData = brandSnap.docs;
  let brandSearchData = {};

  brandData.forEach(doc => {
    brandSearchData = {
      ...brandSearchData,
      [doc.id]: {
        ...doc.data()
      }
    };
  });

  dispatch(receiveBrandSearches(brandSearchData));

  const resLength = brandData.length;

  if (resLength > brandSearchLimit - 1) {
    const lastBrandId = brandData[resLength - 1].id;

    // call getAllBrandSearch again
    await getAllBrandSearches(lastBrandId)(dispatch);
  }
};
