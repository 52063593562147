import React, { createRef } from 'react';

import { Form, Button, Col, Row } from 'antd';
import { ReactComponent as TrendLogoWeb } from 'images/trend-logo-web.svg';
import { StripeProvider, Elements } from 'react-stripe-elements';
import config from 'app_config';
import { AuthUserContext } from 'lib/Session';
import SignOutButton from 'components/Signout';
import { ReactComponent as IconBack } from 'images/icon-back-arrow-white.svg';
import { firebase } from '../../lib/Firebase';
import CheckoutFormV3 from '../Common/CheckoutFormV3';
import { formatCurrency } from '../Helpers/numeral';

const stripePublishKey = config.STRIPE_PUB_KEY;

class BrandForm extends React.Component {
  state = {
    submitting: false
  };

  constructor() {
    super();
    this.checkoutRef = createRef();
  }

  handleSubmit = () => {
    const { history } = this.props;
    this.setState({ submitting: true });
    const authUser = firebase.auth.currentUser;
    this.checkoutRef.handleSubmit(
      authUser,
      () => {
        this.setState({ submitting: false });
        history.push('/posts');
      },
      () => {
        this.setState({ submitting: false });
      }
    );
  };

  render() {
    const { submitting } = this.state;
    const selectedStandardCredits = 0;
    const totalAmount = 0;
    const standardOffDiscount = 0;
    const costPerStandardCredit = 100;

    const goBack = () => {
      const { history } = this.props;
      if (history.location.key) {
        history.goBack();
      } else {
        history.push('/posts');
      }
    };

    return (
      <div style={{ background: '#000', marginTop: '38px', width: '100%' }} id="add-credits">
        <div className="action-link clickable" align="center">
          <div className="back">
            <div
              onClick={goBack}
              role="presentation"
              style={{ cursor: 'pointer', color: '#ffb917' }}
            >
              <IconBack />
              <span className="d-block type-sbold size-14px" style={{ color: '#ffb917' }}>
                Back
              </span>
            </div>
          </div>
          <div className="sign-out">
            <AuthUserContext.Consumer>
              {authUser => authUser && <SignOutButton />}
            </AuthUserContext.Consumer>
          </div>
        </div>
        <div align="center">
          <TrendLogoWeb className="logo d-iblock" width="70px" style={{ marginBottom: '38px' }} />
        </div>
        <Row type="flex" gutter={28} justify="center">
          <Col
            md={8}
            sm={24}
            className="text-white"
            style={{ paddingRight: '53px', width: '780px' }}
          >
            <div>
              <div style={{ marginTop: '69px' }}>
                <h1
                  style={{
                    color: '#fff',
                    marginTop: '10px',
                    fontSize: '40px',
                    marginBottom: '14px'
                  }}
                >
                  Add Payment Info
                </h1>
                <Row type="flex">
                  <Col style={{ maxWidth: '592px' }}>
                    <p
                      className="text-white sub-header  type-sfpro-medium"
                      style={{
                        height: '75px',
                        marginBottom: '52px',
                        color: '#999',
                        fontSize: '18px'
                      }}
                    >
                      You will NOT be charge anything until you choose to move forward and work with
                      a creator. Enter your payment info and launch your campaign to collect real
                      applications now.
                    </p>
                  </Col>
                </Row>
              </div>
              <div className="payment-form">
                <StripeProvider apiKey={stripePublishKey}>
                  <Elements>
                    <CheckoutFormV3
                      standardCredits={0}
                      amount={0}
                      onRef={ref => {
                        this.checkoutRef = ref;
                      }}
                    />
                  </Elements>
                </StripeProvider>
              </div>
            </div>
          </Col>
          <Col
            sm={24}
            lg={8}
            md={4}
            className="order-summary text-white"
            style={{ marginTop: '69px', width: '300px' }}
          >
            <h3 className="color-white">Order Summary</h3>
            <Row type="flex" justify="center">
              <Col style={{ width: '154px' }}>
                <div className="total-credits">
                  <div>
                    <div className="value">{selectedStandardCredits}</div>
                    <div className="label type-sfpro-light">Creator Credits</div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="add-credits-component text-white type-sfpro-regular">
              <Col className="credit-standard-section">
                <Row type="flex" align="top">
                  <Col span={12}>
                    <div className="text-white credit-label">Cost per credit</div>
                    <div className="text-white credit-label">Credit discount</div>
                  </Col>
                  <Col span={12} className="text-right">
                    <div className="credit-total-label">
                      {formatCurrency(costPerStandardCredit, '$', '')}
                    </div>
                    <div className="credit-total-label">{standardOffDiscount}% Off</div>
                  </Col>
                </Row>
                <Row type="flex" align="top">
                  <Col md={20} xs={16}>
                    <div className="text-white orange credit-label">
                      account manager + strategy session (20+ credits)
                    </div>
                  </Col>
                  <Col md={4} xs={8} className="text-right">
                    <div className="credit-total-label orange">
                      {selectedStandardCredits >= 20 ? 'YES!' : 'NO'}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="order-total">
              <Col span={12} className="text-left">
                Order total:
              </Col>
              <Col span={12} className="text-right">
                {formatCurrency(totalAmount, '$', '')}
              </Col>
            </Row>
            <Row className="confirmation">
              <Col>
                <Button
                  block
                  size="large"
                  className="trend-btn purchase-btn"
                  loading={submitting}
                  style={{ borderRadius: '40px' }}
                  onClick={() => {
                    this.handleSubmit();
                  }}
                >
                  Confirm Purchase
                </Button>
                <p className="text-center purchase-description">
                  Trend is designed to help brands with physical products and not brands with
                  digital goods or services.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Form.create({ name: 'brand-form' })(BrandForm);
