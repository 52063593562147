import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import {
  Layout,
  Button,
  Form,
  Input,
  Row,
  Col,
  Alert
  // Divider
} from 'antd';
import moment from 'moment';
import { firebase } from '../../lib/Firebase';
import trendLogoBlack from '../../images/trend-black@3x.png';
import loginBackground from '../../images/Session/bg-onboarding.png';
import { PasswordForgetLink } from '../../components/PasswordForget';
// import { FacebookLogin } from '../../components/Login';

const flStyle = {
  marginTop: '2em',
  fontSize: '9px',
  letterSpacing: '0.2em',
  textTransform: 'uppercase'
};

const backgroundStyle = {
  backgroundImage: `url(${loginBackground})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundColor: '#fff',
  height: '100vh',

  /* Center and scale the image nicely */
  backgroundPosition: 'center'
};

const { Content } = Layout;

class SignInPage extends Component {
  state = {
    loading: false,
    error: false,
    verify: undefined
  };

  componentDidMount() {
    this.listener = firebase.auth.onAuthStateChanged(auth => {
      if (auth) {
        this.defaultRedirectTo(auth.uid);
      }
    });
  }

  componentWillUnmount() {
    this.listener();
  }

  onSubmit = () => {
    const {
      form: { validateFieldsAndScroll }
    } = this.props;
    const { verify } = this.state;
    this.listener();

    this.setState({ loading: true });
    validateFieldsAndScroll(async (error, fields) => {
      if (!error) {
        try {
          const { email, password } = fields;
          let auth;
          if (verify) {
            auth = await firebase.auth.signInWithEmailAndPassword(verify.email, password);
            await auth.user.linkWithCredential(verify.credential);
          } else {
            auth = await firebase.doSignInWithEmailAndPassword(email, password);
            if (auth.user.uid) {
              // insert new record of login
              await firebase.firestore.collection('user_logs').add({
                email,
                loginAt: moment().unix()
              });
            }
          }

          this.setState({ loading: false });
          this.defaultRedirectTo(auth.user.uid);
        } catch (e) {
          this.setState({ loading: false, error: true });
        }
      } else {
        this.setState({ loading: false });
      }
    });
  };

  defaultRedirectTo = async userUID => {
    const { history } = this.props;
    const campaignUID = await this.firstCampaignUID(userUID);

    if (campaignUID) {
      history.push(`/campaigns/${campaignUID}/summary`);
    } else {
      history.push(`/campaigns/example/summary`);
    }
  };

  firstCampaignUID = async userUID => {
    let campaignUID = null;

    await firebase
      .brand(userUID)
      .child('products')
      .once('value', snapshot => {
        [campaignUID] = Object.keys(snapshot.val() || {});
      });

    return campaignUID;
  };

  onChange = e => {
    const { target } = e;
    this.setState({ [target.name]: target.value });
  };

  setStateMounted = args => {
    if (this._isMounted) {
      this.setState(() => ({ ...args }));
    }
  };

  handleUserExists = (email, credential) => {
    const {
      form: { setFieldsValue }
    } = this.props;
    setFieldsValue({ email });
    this.setState({
      verify: {
        email,
        credential
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator, getFieldValue }
    } = this.props;
    const { loading, error, verify } = this.state;

    const isInvalid = !getFieldValue('email') || !getFieldValue('password');

    return (
      <Row
        style={backgroundStyle}
        className="bg-black sign-in-layout"
        type="flex"
        justify="center"
        align="middle"
      >
        <div className="blur-bg" />
        <Content
          style={{ padding: 24, backgroundColor: '#fff', color: '#000' }}
          className="ant-col-lg-6 ant-col-md-8 ant-col-sm-22 ant-col-xs-22"
        >
          <Row>
            <div style={{ textAlign: 'center', padding: '2rem 0' }}>
              <img
                alt="Logo"
                src={trendLogoBlack}
                width="162"
                height="31"
                style={{ display: 'inline-block' }}
              />
            </div>
            <h1 style={{ textAlign: 'center', marginBottom: '30px' }}>Log In To Your Account</h1>
          </Row>
          <Row type="flex" justify="center" align="middle">
            <Col xs={24}>
              <Form layout="vertical" onSubmit={this.onSubmit}>
                <Form.Item>
                  <label className="trend-label">Email</label>
                  {getFieldDecorator('email', {
                    rules: [{ type: 'email', message: 'Invalid Format', required: true }]
                  })(
                    <Input
                      type="email"
                      size="large"
                      placeholder="email@email.com"
                      className="trend-input inverse"
                    />
                  )}
                </Form.Item>

                <Form.Item style={{ padding: 0, margin: '0 0 0.5em 0' }}>
                  <label className="trend-label">Password</label>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Password required' }]
                  })(
                    <Input
                      type="password"
                      size="large"
                      className="tc/krend-input"
                      placeholder="******"
                    />
                  )}
                </Form.Item>

                <PasswordForgetLink />
                {verify && (
                  <>
                    <Alert
                      type="warning"
                      message="Requires your Trend Account password to continue"
                      style={{ textAlign: 'center', marginBottom: '20px' }}
                    />
                    <Button
                      block
                      size="large"
                      type="submit"
                      className="trend-btn"
                      onClick={this.onSubmit}
                      loading={loading}
                      disabled={isInvalid}
                    >
                      Verify Account
                    </Button>
                  </>
                )}
                {!verify && (
                  <>
                    <Button
                      loading={loading}
                      block
                      size="large"
                      type="submit"
                      disabled={isInvalid}
                      className="trend-btn"
                      onClick={this.onSubmit}
                    >
                      Log In
                    </Button>
                  </>
                )}

                {error && (
                  <p style={{ color: '#f5222d', textAlign: 'center', marginTop: '10px' }}>
                    Invalid Email or Password
                  </p>
                )}

                <p style={flStyle} align="middle">
                  Don&apos;t have an account?&nbsp;
                  <a href="/signup?utm_medium=direct&utm_source=none&utm_campaign=none&utm_content=none&utm_term=none">
                    Sign Up
                  </a>
                </p>
              </Form>
            </Col>
          </Row>
        </Content>
      </Row>
    );
  }
}

// <Divider style={{ color: '#000' }}>OR</Divider>
// <FacebookLogin onUserExists={this.handleUserExists} />
export default compose(
  withRouter,
  Form.create({ name: 'signIn-page' })
)(SignInPage);
