import React, { useEffect, useState } from 'react';
import { Icon, Row, Col } from 'antd';
import moment from 'moment-timezone';
import { firebase } from '../../../lib/Firebase';
import { formatCurrency } from '../../Helpers/numeral';

const initalState = {
  today: 0,
  sevenDays: 0,
  thirtyDays: 0,
  overAll: 0
};

const useStat = (actionName, field) => {
  const [stats, setStats] = useState({ ...initalState });
  const [loading, setLoading] = useState({
    loadedData: false,
    loadedOverall: false
  });

  function retrieveStats() {
    setLoading({ loadedData: false, loadedOverall: false });
    const mainRef = firebase.firestore.collection('statistics').doc(actionName);
    const currentDay = moment()
      .tz('America/Los_Angeles')
      .startOf('day');
    const sevenDaysAgo = moment()
      .tz('America/Los_Angeles')
      .startOf('day')
      .subtract(7, 'days');
    const thirtyDays = moment()
      .tz('America/Los_Angeles')
      .startOf('day')
      .subtract(30, 'days');

    const currentData = mainRef
      .collection('daily')
      .where('date', '>=', thirtyDays.format('YYYY-MM-DD'))
      .orderBy('date', 'desc')
      .onSnapshot(docs => {
        let params = {
          today: 0,
          sevenDays: 0,
          thirtyDays: 0
        };

        docs.forEach(doc => {
          const dateId = moment.tz(doc.id, 'America/Los_Angeles');
          const { count, ...data } = doc.data();

          let value = count;
          if (field) {
            value = data[field];
          }

          if (currentDay.format('YYYY-MM-DD') === doc.id) {
            params = { ...params, today: params.today + value };
          }

          if (dateId > sevenDaysAgo) {
            params = { ...params, sevenDays: params.sevenDays + value };
          }

          if (dateId >= thirtyDays) {
            params = { ...params, thirtyDays: params.thirtyDays + value };
          }
        });
        setStats(s => ({ ...s, ...params }));
        setLoading(state => ({ ...state, loadedData: true }));
      });

    const overAllListener = mainRef.onSnapshot(doc => {
      if (doc.exists) {
        const { count = 0, ...data } = doc.data();
        let value = count;

        if (field) {
          value = data[field];
        }
        setStats(s => ({ ...s, overAll: value }));
      }
      setLoading(state => ({ ...state, loadedOverall: true }));
    });

    return [overAllListener, currentData];
  }

  useEffect(() => {
    const [listener, listener2] = retrieveStats();

    return () => {
      listener();
      listener2();
    };
  }, []);

  const { loadedData, loadedOverall } = loading;
  return [stats, loadedData && loadedOverall];
};

const DisplayStat = ({ title, stats, loaded }) => {
  const { today = 0, sevenDays = 0, thirtyDays = 0, overAll = 0 } = stats;
  return (
    <Col span={6}>
      <div className="stats">
        <div className="stat-title">
          {title} {!loaded && <Icon type="loading" />}
        </div>
        <Row>
          <Col span={12}>
            <div className="stat-count">{today}</div>
            <div className="stat-label">Last 24 hrs</div>
          </Col>
          <Col span={12}>
            <div className="stat-count">{sevenDays}</div>
            <div className="stat-label">Last 7 days</div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className="stat-count">{thirtyDays}</div>
            <div className="stat-label">Last 30 days</div>
          </Col>
          <Col span={12}>
            <div className="stat-count">{overAll}</div>
            <div className="stat-label">All Time</div>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

const SignUpStats = () => {
  const [stats, loaded] = useStat('newInfluencer');

  return <DisplayStat title="Influencers" stats={stats} loaded={loaded} />;
};

const CompletedPartnershipStats = () => {
  const [stats, loaded] = useStat('completedPartnership');

  return <DisplayStat title="Completed Campaigns" stats={stats} loaded={loaded} />;
};

const ActiveInfluencerStats = () => {
  const [stats, loaded] = useStat('activeInfluencer');

  return <DisplayStat title="Active Users" stats={stats} loaded={loaded} />;
};

const PayoutStats = () => {
  const [stats, loaded] = useStat('payoutInfluencer', 'payoutAmountTotal');

  let formatedStats = {};

  Object.keys(stats).forEach(stat => {
    const value = formatCurrency(stats[stat], '$');
    formatedStats = { ...formatedStats, [stat]: value };
  });

  return <DisplayStat title="Payouts" stats={formatedStats} loaded={loaded} />;
};

const Statistic = () => {
  return (
    <Row type="flex" className="influencers-stats" gutter={12}>
      <SignUpStats />
      <ActiveInfluencerStats />
      <CompletedPartnershipStats />
      <PayoutStats />
    </Row>
  );
};

export default Statistic;
