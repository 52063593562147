import React, { useState } from 'react';
import { Button, Icon, Select, Row, Col, AutoComplete } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import STATES from '../../../constants/states';
import { firebase } from '../../../lib/Firebase';
import debounce from '../../Helpers/useDebounce';
import { ReactComponent as DownloadIcon } from '../../../images/download.svg';

const states = { ...STATES };
delete states.All;

const { Option } = AutoComplete;
const Filters = ({ onChange, onDownload }) => {
  const [filters, setFilters] = useState({});
  const [downloading, setDownloading] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const [searching, setSearching] = useState(false);

  function handleSelectChange(type) {
    return val => {
      setFilters(state => {
        const newState = { ...state, [type]: val };
        onChange(newState);
        return newState;
      });
    };
  }

  async function handleSearchChange(val) {
    if (val) {
      const searchUsername = (val || '').toLowerCase();

      setSearching(true);
      const influencerSnap = await firebase.firestore
        .collection('influencers')
        .where('usernameLow', '>=', searchUsername)
        .orderBy('usernameLow', 'asc')
        .limit(5)
        .get();
      let usernames = [];
      influencerSnap.docs.forEach(x => {
        const { usernameLow, username } = x.data();

        if (usernameLow.includes(searchUsername)) {
          usernames = [...usernames, username];
        }
      });
      setSearchList(usernames);
      setSearching(false);
    } else {
      setSearchList([]);
      setSearching(false);
    }
  }

  function handleSearchSelect(val) {
    setFilters(state => {
      let newState = {};
      const searchUsername = (val || '').toLowerCase();
      const mapSearchList = _.map(searchList, t => t.toLowerCase());

      if (mapSearchList.includes(searchUsername)) {
        newState = { ...state, search: searchUsername };
      } else {
        newState = { ...state, search: '' };
      }
      onChange(newState);
      return newState;
    });
  }

  async function downloadCSV() {
    setDownloading(true);
    await onDownload();
    setDownloading(false);
  }

  const weekAgo = moment()
    .subtract(1, 'week')
    .unix();
  const monthAgo = moment()
    .subtract(1, 'month')
    .unix();
  const threeMonthsAgo = moment()
    .subtract(3, 'months')
    .unix();

  const stateOptions = Object.keys(states).map(key => (
    <Select.Option key={key} value={key}>
      {states[key]}
    </Select.Option>
  ));

  const searchListOptions = searchList.map(x => (
    <Option key={x} value={x}>
      {x}
    </Option>
  ));

  return (
    <>
      <Col md={7} xs={24}>
        <Row className="search" type="flex" align="middle">
          {searching ? <Icon type="loading" /> : <Icon type="search" />}
          <AutoComplete
            onChange={debounce(handleSearchChange, 500)}
            placeholder="Search"
            onSelect={handleSearchSelect}
            allowClear
            onBlur={handleSearchSelect}
          >
            {searchListOptions}
          </AutoComplete>
        </Row>
      </Col>
      <Col md={17} xs={24}>
        <Row type="flex" justify="end" gutter={8} align="middle">
          <Col md={5} className="text-right">
            <Button block className="download-csv" loading={downloading} onClick={downloadCSV}>
              <Icon component={DownloadIcon} />
              <span>
                Download
                <br /> Contact CSV
              </span>
            </Button>
          </Col>
          <Col md={5}>
            <Select
              allowClear
              showArrow={false}
              className="trend-select"
              placeholder="Last Login"
              onChange={handleSelectChange('lastLoginDate')}
            >
              <Select.Option value={weekAgo}>1 Week</Select.Option>
              <Select.Option value={monthAgo}>1 Month</Select.Option>
              <Select.Option value={threeMonthsAgo}>3 Month</Select.Option>
            </Select>
          </Col>
          <Col md={5}>
            <Select
              allowClear
              showArrow={false}
              className="trend-select"
              placeholder="Completed"
              value={filters.completedPartnershipCount}
              onChange={handleSelectChange('completedPartnershipCount')}
            >
              <Select.Option value={1}>{'Completed: < 1'}</Select.Option>
              <Select.Option value={3}>{'Completed: < 3'}</Select.Option>
              <Select.Option value={10}>{'Completed: < 10'}</Select.Option>
              <Select.Option value={30}>{'Completed: < 30'}</Select.Option>
            </Select>
          </Col>
          <Col md={4}>
            <Select
              allowClear
              showArrow={false}
              className="trend-select"
              placeholder="Level"
              onChange={handleSelectChange('star')}
            >
              <Select.Option value={1}>1</Select.Option>
              <Select.Option value={2}>2</Select.Option>
              <Select.Option value={3}>3</Select.Option>
            </Select>
          </Col>
          <Col md={4}>
            <Select
              allowClear
              showArrow={false}
              className="trend-select"
              placeholder="Location: All"
              onChange={handleSelectChange('state')}
            >
              {stateOptions}
            </Select>
          </Col>
        </Row>
        <Row gutter={8} type="flex" justify="end">
          <Col span={4}>
            <Select
              allowClear
              showArrow={false}
              className="trend-select"
              placeholder="Gender"
              onChange={handleSelectChange('gender')}
            >
              <Select.Option value="male">Male</Select.Option>
              <Select.Option value="female">Female</Select.Option>
            </Select>
          </Col>
          <Col span={4}>
            <Select
              allowClear
              showArrow={false}
              className="trend-select"
              placeholder="Type"
              onChange={handleSelectChange('type')}
            >
              <Select.Option value="standard">Standard</Select.Option>
              <Select.Option value="vip">VIP</Select.Option>
            </Select>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Filters;
