import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Promise from 'bluebird';
import { Spin, Checkbox, Row, Select } from 'antd';
import Container from '../../components/Common/Container';
import { firebase, postSearch } from '../../lib/Firebase';
import { PostCardV2 } from '../../components/Post';
import { setInfluencers } from '../../actions/influencers';
import { UpCaseFirstLetter } from '../../lib/Common';

const { Option } = Select;
const Filters = ({ onChange }) => {
  const [filters, setFilters] = useState({
    category: '',
    owned: false
  });
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function retrieveCategories() {
      const categoriesSnap = await firebase.adminCategories().once('value');
      setCategories(Object.values(categoriesSnap.val()));
    }

    retrieveCategories();
  }, []);

  const categoryOptions = categories.map(category => (
    <Option key={category} value={category}>
      {UpCaseFirstLetter(category)}
    </Option>
  ));

  const handleChange = type => val => {
    if (onChange) {
      setFilters({ ...filters, [type]: val });
    }

    onChange({ ...filters, [type]: val });
  };

  const { category } = filters;
  return (
    <>
      <Select
        style={{ width: '98px', height: '30px', display: 'inline-block' }}
        className="trend-select"
        placeholder="Categories"
        showArrow={false}
        value={category}
        onSelect={handleChange('category')}
      >
        <Option value="">All</Option>
        {categoryOptions}
      </Select>
      <Checkbox
        id="myCampaignOnly"
        onChange={e => handleChange('owned')(e.target.checked)}
        className="custom-checkbox"
        style={{
          marginLeft: '6px'
        }}
      >
        <span
          className="size-10px type-firme-iblack spacing-1px text-pigeon-dark"
          style={{ fontSize: '0.8em', verticalAlign: 'middle' }}
        >
          MY POSTS ONLY
        </span>
      </Checkbox>
    </>
  );
};

const AllPostPage = () => {
  // state
  const [brandUID, setBrandUID] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);

  // selectors
  const userId = useSelector(
    state => state.entities.session.authUserData && state.entities.session.authUserData.uid
  );

  useEffect(() => {
    if (userId && !brandUID) {
      setBrandUID(userId);
    }
  }, [userId, setBrandUID, brandUID]);

  const dispatch = useDispatch();

  async function retrievePosts(filters = {}) {
    setLoading(true);
    const postDocs = await postSearch(filters)
      .limit(30)
      .get();

    const influencerUIDs = [];
    postDocs.forEach(doc => {
      const post = doc.data();

      influencerUIDs.push(post.influencerUID);
    });

    let influencers = {};

    await Promise.map(
      influencerUIDs,
      async influencerUID => {
        const doc = await firebase.firestore
          .collection('influencers')
          .doc(influencerUID)
          .get();

        influencers = { ...influencers, [influencerUID]: { details: doc.data() } };
      },
      { concurrency: 4 }
    );

    dispatch(setInfluencers(influencers));
    setPosts(postDocs.docs);
    setLoading(false);
  }

  useEffect(() => {
    retrievePosts();
  }, []);

  async function handleFilterChange(filters = {}) {
    let newFilters = filters;
    if (filters.owned) {
      newFilters = { ...filters, brandUID };
    }

    await retrievePosts(newFilters);
  }

  const allPosts = posts.map(doc => {
    const post = doc.data();
    const { influencerUID } = post;
    return <PostCardV2 key={doc.id} post={post} influencerUID={influencerUID} />;
  });

  return (
    <div id="all-post-page">
      <Container width={733}>
        <h1>Latest Post</h1>
        <div className="post-filters">
          <Filters onChange={handleFilterChange} />
        </div>
        <Spin spinning={loading}>
          <Row type="flex" justify="space-around">
            {allPosts}
          </Row>
        </Spin>
      </Container>
    </div>
  );
};

export default AllPostPage;
