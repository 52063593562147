import {
  ADMIN_ADD_ACTIVE_CAMPAIGN,
  ADMIN_PUSH_ACTIVE_CAMPAIGN,
  ADMIN_UPDATE_LAST_ACTIVE_CAMPAIGN_DOC
} from '../../../../reducers/ui/admin/activeCampaigns';

export const adminAddActiveCampaign = productUID => ({
  payload: productUID,
  type: ADMIN_ADD_ACTIVE_CAMPAIGN
});

export const adminPushActiveCampaign = productUID => ({
  payload: productUID,
  type: ADMIN_PUSH_ACTIVE_CAMPAIGN
});

export const adminUpdateLastCampaignDoc = campaignDoc => ({
  type: ADMIN_UPDATE_LAST_ACTIVE_CAMPAIGN_DOC,
  payload: campaignDoc
});
