import React from 'react';
import { Form, Button, Input, Row, Col, Radio } from 'antd';
import TrendSelect from '../../Form/TrendSelect';
import ImageUploaderV2 from '../../Form/ImageUploaderV2';
import { firebase } from '../../../lib/Firebase';
import { UpCaseFirstLetter } from '../../../lib/Common';

const cappedAmount = 175;
const { TextArea } = Input;

class Action extends React.Component {
  state = {
    categories: [],
    submitting: false
  };

  componentDidMount() {
    firebase.adminCategories().on('value', snap => {
      const categories = snap.val();

      this.setState({
        categories: Object.keys(categories).map(x => ({
          key: categories[x],
          value: UpCaseFirstLetter(categories[x])
        }))
      });
    });
  }

  handleSubmit = () => {
    const {
      onSubmit,
      onError,
      form: { validateFieldsAndScroll }
    } = this.props;
    this.setState({ submitting: true });

    validateFieldsAndScroll(async (error, fields) => {
      if (!error) {
        if (onSubmit) {
          onError(false);
          await onSubmit(fields);
        }
      } else {
        onError(true);
        this.setState({ submitting: false });
      }
    });
  };

  validateMaximumPost = productValue => (rule, val, callback) => {
    if (productValue < 175 && val > 1) {
      callback(false);
    } else {
      callback();
    }
  };

  render() {
    const { submitting, categories = [] } = this.state;
    const { form, campaign } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const productValue = campaign.value;
    const numberOfPosts = getFieldValue('numberOfPosts') || 0;
    const { postGuidelines = [], postTypes = [] } = campaign;

    return (
      <Form
        layout="vertical"
        className="product-form pb-8"
        hideRequiredMark
        onSubmit={this.handleSubmit}
      >
        <h1 className="page-header">Lights, Camera, Action!</h1>
        <div className="page-description">
          Put on your creative director hat and get specific about the kind of content you want the
          creators to produce.
        </div>
        <Row gutter={{ sm: 30 }}>
          <Col sm={12} xs={24}>
            <Form.Item
              label={
                <div className="with-sub-label">
                  <span>Product Category</span>
                  <div className="sub-label">Select One</div>
                </div>
              }
            >
              {getFieldDecorator('category', {
                initialValue: campaign.category,
                rules: [{ required: true, message: 'Please choose a category.' }]
              })(<TrendSelect type="dark" options={categories} placeholder="Select category" />)}
            </Form.Item>
          </Col>
          <Col sm={12} xs={24}>
            <Form.Item
              label={
                <div className="with-sub-label">
                  <span>Post Per Creator</span>
                  <div className="sub-label">Brands must provide over $175 to warrant 2 posts.</div>
                </div>
              }
            >
              <div>
                {getFieldDecorator('numberOfPosts', {
                  initialValue: campaign.numberOfPosts,
                  rules: [
                    {
                      required: true,
                      message: 'Please choose number of posts.'
                    },
                    {
                      validator: this.validateMaximumPost(productValue),
                      message: 'Only $175 and above can warrant 2 posts'
                    }
                  ]
                })(
                  <Radio.Group
                    buttonStyle="solid"
                    className="trend-radio-buttons"
                    style={{ width: '123px' }}
                  >
                    <Radio.Button value={1}>1</Radio.Button>
                    {productValue >= cappedAmount ? <Radio.Button value={2}>2</Radio.Button> : ''}
                  </Radio.Group>
                )}
              </div>
            </Form.Item>
          </Col>
        </Row>
        {numberOfPosts > 0 && (
          <Form.Item label="What are you looking for in your first post?">
            {getFieldDecorator('postTypes[0]', {
              initialValue: postTypes[0] || 'image',
              rules: [{ required: true, message: 'Please select a post type' }]
            })(
              <Radio.Group buttonStyle="solid" className="trend-radio-buttons">
                <Radio.Button value="image">Image Post</Radio.Button>
                <Radio.Button value="video">Video Post</Radio.Button>
              </Radio.Group>
            )}
          </Form.Item>
        )}
        {numberOfPosts > 1 && (
          <Form.Item label="What are you looking for in your second post?">
            {getFieldDecorator('postTypes[1]', {
              initialValue: postTypes[1] || 'image',
              rules: [{ required: true, message: 'Please select a post type' }]
            })(
              <Radio.Group buttonStyle="solid" className="trend-radio-buttons">
                <Radio.Button value="image">Image Post</Radio.Button>
                <Radio.Button value="video">Video Post</Radio.Button>
              </Radio.Group>
            )}
          </Form.Item>
        )}
        <Form.Item label="Instagram Story Required?">
          {getFieldDecorator('isStoryRequired', {
            initialValue: campaign.isStoryRequired,
            rules: [{ required: true, message: 'Please select from the options' }]
          })(
            <Radio.Group buttonStyle="solid" className="trend-radio-buttons">
              <Radio.Button id="campaign-is_story_required_no" value={false}>
                No
              </Radio.Button>
              <Radio.Button id="campaign-is_story_required_yes" value>
                Yes
              </Radio.Button>
            </Radio.Group>
          )}
        </Form.Item>

        <Form.Item label="Which products will you send the influencers?">
          {getFieldDecorator('postGuidelines[0]', {
            initialValue: postGuidelines[0],
            rules: [
              {
                required: true,
                message: 'Please provide guidelines.'
              }
            ]
          })(
            <Input
              className="trend-textarea dark"
              placeholder="Start Typing..."
              style={{ height: '51px' }}
            />
          )}
        </Form.Item>
        <Form.Item label="What would you like the influencers to mention in their caption?">
          {getFieldDecorator('postGuidelines[1]', {
            initialValue: postGuidelines[1],
            rules: [
              {
                required: true,
                message: 'Please provide guidelines.'
              }
            ]
          })(<TextArea rows="6" className="trend-textarea dark" placeholder="Start Typing..." />)}
        </Form.Item>
        <Form.Item label="What photo / video style are you looking for?">
          {getFieldDecorator('postGuidelines[2]', {
            initialValue: postGuidelines[2],
            rules: [
              {
                required: true,
                message: 'Please provide guidelines.'
              }
            ]
          })(<TextArea rows="6" className="trend-textarea dark" placeholder="Start Typing..." />)}
        </Form.Item>
        <Form.Item
          label={
            <div className="with-sub-label">
              Style Guide
              <div className="sub-label">
                Add images so the creators know what kind of content to create.
              </div>
            </div>
          }
        >
          {getFieldDecorator('styleGuide', {
            initialValue: campaign.styleGuide
          })(<ImageUploaderV2 col={6} />)}
        </Form.Item>
        <div align="center">
          <Button
            size="large"
            type="submit"
            className="trend-btn product-btn"
            loading={submitting}
            onClick={this.handleSubmit}
          >
            All Done - Launch Your Campaign!
          </Button>
        </div>
      </Form>
    );
  }
}

export default Form.create({ name: 'brief-pane' })(Action);
