import React, { Component, useEffect, useState } from 'react';
import { Checkbox, Form, Input, Row, Col, Button, message } from 'antd';
import 'styles/base/antd-custom.less';
import { withRouter, useHistory } from 'react-router-dom';
import { compose } from 'recompose';
// import { StripeProvider, Elements } from 'react-stripe-elements';
// import config from 'app_config';
import queryString from 'query-string';
import moment from 'moment';
import trendLogo from '../../images/trend@2x.png';
import { firebase } from '../../lib/Firebase';
// import { calculateCreditsTotalCost } from '../../helpers/credits';
import { AC_FIELD_IDS } from '../../constants/ac';
import * as ROUTES from '../../constants/routes';
import { DATE_TIMEZONE_FORMAT } from '../../constants/common';
import { UTMFieldNames } from '../../constants/utmFieldNames';

// const stripePublishKey = config.STRIPE_PUB_KEY;

const SignUpPage = () => {
  const [AcInfo, setAcInfo] = useState(null);
  // const [contactUserId, setContactId] = useState(null);
  const history = useHistory();

  const getBusinessInfo = async () => {
    const params = queryString.parse(window.location.search);
    if (params.contactId) {
      const { contactId } = params;
      // setContactId(contactId);
      setAcInfo(await firebase.getContactInfoById(contactId));
    }
  };

  useEffect(() => {
    getBusinessInfo();

    if (firebase.auth.currentUser) {
      message.info('You are already logged in.');
      history.push('/');
    }
  }, []);

  const handleSubmit = () => {
    // let emailTmp = null;
    // if (AcInfo) {
    //   const {
    //     contact: { email }
    //   } = AcInfo.data;
    //   emailTmp = email;
    // }
    // const newEmail = formInputs.email !== emailTmp;
    // await firebase.newSignupProcess(formInputs.email, contactUserId, newEmail);

    history.push(ROUTES.PRE_ONBOARDING_PATH);
  };

  return (
    <div id="sign-up-page" className="bg-black">
      <SignUpForm handleSubmit={handleSubmit} AcInfo={AcInfo} />
    </div>
  );
};

class SignUpFormBase extends Component {
  state = {
    withAuth: false,
    submitting: false,
    // standardCredits: 0,
    contactId: ''
  };

  componentDidMount() {
    const { history } = this.props;

    const query = new URLSearchParams(history.location.search);
    const contactId = query.get('contactId');
    // const standardCredits = parseInt(query.get('standardCredits'), 10) || 0;

    this.setState({ contactId });
  }

  UTMParams = async () => {
    const params = queryString.parse(window.location.search);

    const value = [];
    if (Object.keys(params).length) {
      Object.keys(UTMFieldNames).forEach(key => {
        value.push({
          fieldName: UTMFieldNames[key],
          value: params[key]
        });
      });
    }

    return value;
  };

  onSubmit = event => {
    event.preventDefault();

    const {
      form: { validateFieldsAndScroll },
      AcInfo,
      history
    } = this.props;

    const { contactId } = this.state;

    this.setState({ submitting: true });
    validateFieldsAndScroll(async (error, values) => {
      if (!error) {
        const { email, password, fullName } = values;

        let emailTmp = null;
        let newEmail = true;
        if (contactId) {
          if (AcInfo) {
            const {
              contact: { email: acEmail }
            } = AcInfo.data;
            emailTmp = acEmail;
          }

          newEmail = email !== emailTmp;
        }

        let brandUID;
        try {
          let authUser;

          if (firebase.auth.currentUser) {
            await firebase.auth.currentUser.updatePassword(password);
            await firebase.auth.currentUser.updateEmail(email);
            authUser = firebase.auth.currentUser;
          } else {
            const auth = await firebase.doCreateUserWithEmailAndPassword(email, password);
            authUser = auth.user;
          }

          brandUID = authUser.uid;
          let acInfoData = {};

          await firebase.brandInfo(brandUID).set({
            credits: 0,
            standardCredits: 0,
            email,
            fullName,
            firstCampaign: false,
            status: 'pending',
            isFreeTrial: false,
            createdAt: moment().format(DATE_TIMEZONE_FORMAT),
            createdAtUnix: moment().unix()
          });

          // set utm
          const utmParams = await this.UTMParams();
          firebase.setUtmTags({ email, utmParams, fullName, contactId, newEmail });

          if (process.env.NODE_ENV === 'production') {
            window.$FPROM.trackSignup({ email }, () => {
              // eslint-disable-next-line no-console
              console.log('Message Sent');
            });
          }

          if (AcInfo) {
            const {
              contact: { firstName, lastName, orgname, adate },
              fieldValues = []
            } = AcInfo.data;

            const [fieldValue] = fieldValues.filter(
              fv => parseInt(fv.field, 10) === AC_FIELD_IDS.Website
            );

            acInfoData = {
              ownerName: `${firstName} ${lastName}`,
              name: orgname,
              status: 'pending',
              appliedAt: adate ? moment(adate).unix() : null
            };

            if (fieldValue) {
              acInfoData.website = fieldValue.value;
            }
          }

          const businessSnap = await firebase.firestore
            .collection('businesses')
            .where('contactId', '==', String(contactId))
            .get();

          if (!AcInfo && businessSnap.empty) {
            await firebase.firestore.collection('businesses').add({
              fullName,
              status: 'pending',
              isRegistered: true,
              email,
              website: '',
              createdAtUnix: moment().unix()
            });
          }

          const brandAddonData = {};

          if (!businessSnap.empty) {
            const businessId = businessSnap.docs[0].id;
            const business = businessSnap.docs[0].data();

            brandAddonData.businessId = businessId;

            if (business.appliedAt) {
              brandAddonData.appliedAt = business.appliedAt;
            }

            if (business.approvedAt) {
              brandAddonData.approvedAt = business.approvedAt;
            }

            firebase.firestore
              .collection('businesses')
              .doc(businessId)
              .update({ isRegistered: true, registeredAt: moment().unix() });
          }

          const brandData = {
            ...acInfoData,
            ...brandAddonData
          };

          firebase.brandInfo(brandUID).update(brandData);

          history.push(`${ROUTES.PRE_ONBOARDING_PATH}?first=true`);
        } catch (e) {
          if (firebase.auth.currentUser) {
            await firebase.brand(brandUID).remove();
            await firebase.auth.currentUser.delete();
          }
          console.log(e);
          message.error(e.message);
          this.setState({ submitting: false });
        }
      } else {
        this.setState({ submitting: false });
      }
    });
  };

  comparePassword = (rules, value, callback) => {
    const { form } = this.props;
    const password = form.getFieldValue('password');
    if ((value && value.length !== password.length) || value !== password) {
      callback('Confirm Password did not match');
    } else {
      callback();
    }
  };

  logout = () => {
    firebase.auth.signOut();
  };

  sliderMarks = () => {
    const marks = {};
    for (let i = 0; i <= 50; i += 5) {
      marks[i] = i.toString();
    }
    return marks;
  };

  render() {
    const { submitting, withAuth } = this.state;

    const {
      form: { getFieldDecorator }
    } = this.props;

    // const plan = getFieldValue('plan') || {};

    // const { totalAmount } = calculateCreditsTotalCost(standardCredits);

    return (
      <div>
        <Form layout="vertical" onSubmit={this.onSubmit}>
          <Row type="flex" justify="end">
            <Col className="registration-message" style={{ width: 'calc(100% - 512px)' }}>
              <Row type="flex" justify="center">
                <Col style={{ width: '430px' }}>
                  <img src={trendLogo} alt="trend" className="trend" />
                  <p className="type-sfpro-regular size-31px">
                    Connect With Creators, Generate Posts & Content At A Set Price. It’s That
                    Simple.
                  </p>
                  <span
                    className="type-sfpro-regular size-22px"
                    style={{ fontWeight: 400, lineHeight: '32px' }}
                  >
                    “Trend allows us to always have a fresh stack of creative content to use across
                    our website, social accounts, and ads. The Influencers are a pleasure to work
                    with and always find ways to surprise us with what they can create.”
                  </span>
                </Col>
              </Row>
              <Row type="flex" justify="center">
                <Col style={{ width: '430px', marginTop: '24px' }}>
                  <span className="type-sfpro-regular size-13px" style={{ fontWeight: '700' }}>
                    Daina Slekys Trout | CEO Health-Ade Kombucha
                  </span>
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                width: '512px',
                background: '#fff',

                minHeight: '100vh'
              }}
            >
              <Row type="flex" justify="center">
                <Col
                  style={{
                    width: '421px',
                    paddingTop: '124px'
                  }}
                >
                  <div>
                    <span
                      className="type-sfpro-regular size-25px"
                      style={{ lineHeight: '18px', fontWeight: 800, color: '#000' }}
                    >
                      Create A Free Campaign
                    </span>
                    <div
                      className="type-sfpro-regular size-18px"
                      style={{
                        width: '437px',
                        marginTop: '14px',
                        fontWeight: 400,
                        lineHeight: '26px',
                        color: '#000'
                      }}
                    >
                      With Trend there are no contracts, or subscriptions. Create a campaign right
                      now and immediately collect qualified applications from creators eager to work
                      for your brand.
                    </div>
                  </div>
                  <Form.Item style={{ marginTop: '37px' }}>
                    <label className="trend-label">Full Name</label>
                    {getFieldDecorator('fullName', {
                      rules: [{ required: true, message: 'Full name Required' }]
                    })(
                      <Input
                        type="text"
                        size="large"
                        className="trend-input"
                        disabled={withAuth}
                        placeholder="Helen Jane"
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    <label className="trend-label">Work Email*</label>
                    {getFieldDecorator('email', {
                      rules: [
                        { required: true, message: 'Email Required' },
                        { type: 'email', message: 'Email Invalid' }
                      ]
                    })(
                      <Input
                        type="text"
                        size="large"
                        className="trend-input"
                        disabled={withAuth}
                        placeholder="yourname@email.com"
                      />
                    )}
                  </Form.Item>

                  <Row gutter={24}>
                    <Col lg={12}>
                      <Form.Item>
                        <label className="trend-label">Password</label>
                        {getFieldDecorator('password', {
                          rules: [{ required: true, message: 'Password Required' }]
                        })(
                          <Input
                            type="password"
                            size="large"
                            className="trend-input"
                            placeholder="********"
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col lg={12}>
                      <Form.Item>
                        <label className="trend-label">Confirm Password</label>
                        {getFieldDecorator('confirmPassword', {
                          rules: [
                            {
                              required: true,
                              message: 'Confirm Password Required'
                            },
                            {
                              validator: this.comparePassword,
                              message: 'Confirm Password did not match'
                            }
                          ]
                        })(
                          <Input
                            type="password"
                            size="large"
                            className="trend-input"
                            placeholder="********"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row type="flex" justify="center">
                    <Col>
                      <Form.Item className="terms-conditions">
                        <label
                          htmlFor="multipleSelect"
                          className="d-block clickable leading-18px"
                          style={{
                            paddingBottom: 0,
                            paddingTop: 3
                          }}
                        >
                          {getFieldDecorator('terms', {
                            valuePropName: 'checked',
                            initialValue: false,
                            rules: [
                              {
                                type: 'boolean',
                                transform: value => value || undefined,
                                required: true,
                                message: 'Must Accept Terms of Service'
                              }
                            ]
                          })(
                            <Checkbox
                              id="multipleSelect"
                              className="trend-terms-checkbox"
                              style={{ float: 'left', marginRight: '6px', width: '30px' }}
                            />
                          )}
                          <span
                            className="type-sfpro-regular size-14px spacing-1px text-white"
                            style={{
                              lineHeight: '30px',
                              width: '182px',
                              fontWeight: '500'
                            }}
                          >
                            I accept all statements in the{' '}
                            <a href="https://trend.io/terms" target="tos">
                              Terms of service
                            </a>
                          </span>
                        </label>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row className="confirmation">
                    <Col className="text-center">
                      <Button
                        size="large"
                        type="submit"
                        className="trend-btn"
                        loading={submitting}
                        onClick={this.onSubmit}
                      >
                        Get Started
                      </Button>
                    </Col>
                  </Row>
                  <Row className="confirmation-text" type="flex" justify="center">
                    <Col>
                      <p className="text-center">
                        Trend is designed to help brands with physical products and not brands with
                        digital goods or services.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

const SignUpForm = compose(
  withRouter,
  Form.create('sign-up')
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm };
