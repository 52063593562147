import React, { useState } from 'react';
import { Button } from 'antd';
import LoginModal from './LoginModal';

const UpdateAccountButton = ({ children, ...rest }) => {
  const [modal, setModal] = useState({
    show: false
  });

  function handleClick() {
    setModal({ ...modal, show: true });
  }

  return (
    <>
      <Button className="yotpo-btn" size="large" onClick={handleClick} {...rest}>
        {children}
      </Button>
      <LoginModal show={modal.show} handleShow={val => setModal({ ...modal, show: val })} />
    </>
  );
};

export default UpdateAccountButton;
