import React, { useState, useEffect } from 'react';
import { Modal, Table, Button } from 'antd';

const NoCreditsModal = props => {
  const { show, tallyCredits, hide } = props;
  const [visibleNotEnoughCredits, setVisibleNotEnoughCredits] = useState(show);

  const creditsColumn = [
    {
      title: '',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Creator Credits',
      dataIndex: 'standard',
      key: 'standard'
    }
  ];

  useEffect(() => {
    setVisibleNotEnoughCredits(show);
  }, [props]);

  return (
    <Modal
      title="Not Enough Credits"
      visible={visibleNotEnoughCredits}
      closable={false}
      footer={[
        <Button key="okay" onClick={() => hide()}>
          Okay
        </Button>
      ]}
    >
      Not enough credits. See table below.
      <Table
        columns={creditsColumn}
        pagination={false}
        dataSource={tallyCredits}
        style={{ margin: '15px 0 15px 0' }}
      />
    </Modal>
  );
};
export default NoCreditsModal;
