import React, { useEffect, useState, createRef } from 'react';
import { Icon } from 'antd';
import { ReactComponent as PlayButtonSVG } from '../../../images/play-btn.svg';

const PostCardVideo = props => {
  const { post } = props;
  const { type } = post;

  const [playing, setPlaying] = useState(false);
  const videoRef = createRef();
  useEffect(() => {
    videoRef.current.addEventListener('ended', e => {
      e.target.currentTime = 0;
      setPlaying(false);
    });
  }, []);

  const togglePlay = e => {
    e.preventDefault();
    if (!playing) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
    setPlaying(!playing);
  };

  const video = post.highResVideo;
  return (
    <div className="post-card-video" onClick={togglePlay} role="presentation">
      <div className="post-type-wrapper">
        <div className={`post-type-label type-${type}`}>{type} Post</div>
      </div>
      <div className="post-card-video-actions">
        <div className="post-card-video-actions-button">
          {!playing && <Icon component={PlayButtonSVG} style={{ width: '100%' }} />}
        </div>
      </div>
      <video
        ref={videoRef}
        src={video}
        type="video/mp4"
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
    </div>
  );
};

export default PostCardVideo;
