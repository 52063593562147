import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form, Input, Radio, message, Select } from 'antd';
import moment from 'moment';
import Promise from 'bluebird';
import { firebase } from '../../../lib/Firebase';
import Container from '../../../components/Common/Container';
import ImageUpload from './ImageUploader';

const { TextArea } = Input;
const { Option } = Select;
let id = 0;

const OnboardingGuides = props => {
  const { form } = props;
  const { setFieldsValue, getFieldDecorator, validateFieldsAndScroll } = form;
  const [submitting, setSubmitting] = useState(false);
  const [selectedOption, setSelectedOption] = useState('brand');
  const [formType, setFormType] = useState('content');
  const [keyFormType, setKeyFormType] = useState([]);
  const [tmpKeys, setTmpKeys] = useState([]);
  const [defaultImages, setDefaultImages] = useState({});
  const [defaultData, setDefaultData] = useState([]);

  const getData = async option => {
    const data = await firebase.firestore
      .collection('onboardingGuides')
      .where('selectedOption', '==', option)
      .orderBy('key', 'asc')
      .get();

    if (!data.empty) {
      const tmpData = [];
      const tmpForKeys = [];
      let tmpForFormType = {};
      data.docs.map(guide => {
        const guideData = guide.data();
        const title = [];
        title[guideData.key] = guideData.title;
        if (guideData.formType === 'title') {
          getFieldDecorator(`title[${guideData.key}]`, {
            initialValue: guideData.title
          });
        }
        if (guideData.formType === 'content') {
          getFieldDecorator(`content[${guideData.key}]`, {
            initialValue: guideData.content
          });
        }
        if (guideData.formType === 'image') {
          getFieldDecorator(`storeImage[${guideData.key}]`, {
            initialValue: guideData.storeImage
          });
        }
        if (guideData.formType === 'youtube') {
          getFieldDecorator(`youtube[${guideData.key}]`, {
            initialValue: guideData.youtube
          });
        }
        if (guideData.formType === 'image') {
          setDefaultImages(prevState => {
            return { ...prevState, [guideData.key]: guideData.storeImage };
          });
        }

        id = Number(guideData.key);
        tmpForKeys.push(id);

        tmpForFormType = {
          ...tmpForFormType,
          [id]: guideData.formType
        };
        tmpData[guideData.key] = { id: guide.id, ...guideData };

        return guideData;
      });
      setFieldsValue({
        keys: tmpForKeys
      });
      setTmpKeys(tmpForKeys);
      setKeyFormType(tmpForFormType);
      setDefaultData(tmpData);
    }
  };

  useEffect(() => {
    getFieldDecorator('keys', { initialValue: [] });
    getData(selectedOption);
  }, []);

  const handleChange = e => {
    setSelectedOption(e.target.value);
    setDefaultData([]);
    setDefaultImages({});
    setTmpKeys([]);
    setKeyFormType([]);
    getData(e.target.value);
    id = 0;
  };

  const onChangeSelect = value => {
    setFormType(value);
  };

  const uploadProcess = process =>
    new Promise((resolve, reject) => {
      process.on(
        'state_changed',
        () => {},
        error => {
          reject(error);
        },
        async () => {
          const url = process.snapshot.ref.getDownloadURL();
          resolve(url);
        }
      );
    });

  const uploadOnboardingImages = async images => {
    const tmpImages = [];
    await Promise.map(images, async image => {
      if (image.id) {
        const storageRef = firebase.storage.ref('onboardingGuide').child(`images/${image.id}`);
        storageRef.delete();
      } else {
        // create and upload
        const { file } = image;

        const storageRef = firebase.storage.ref('onboardingGuide').child('images');

        const metadata = {
          contentType: file.type
        };
        const filename = Math.random()
          .toString(36)
          .substring(7);
        const uploadTask = storageRef.child(filename).put(file, metadata);
        const url = await uploadProcess(uploadTask);
        tmpImages.push(url);
      }
    });

    return tmpImages;
  };

  const onClickAdd = () => {
    const nextId = id + 1;
    id = Number(nextId);
    const nextKeys = tmpKeys.concat(nextId);
    setFieldsValue({
      keys: nextKeys
    });
    setTmpKeys(nextKeys);
    setKeyFormType(prevState => {
      return { ...prevState, [nextId]: formType };
    });
  };

  const onFinish = () => {
    setSubmitting(true);

    validateFieldsAndScroll(async (error, fields) => {
      if (!error) {
        const { storeImage, title, content, youtube } = fields;
        await Promise.map(Object.keys(keyFormType), async key => {
          const query = firebase.firestore.collection('onboardingGuides');
          if (defaultData && defaultData[key]) {
            const newStoreImage = storeImage[key]
              ? await uploadOnboardingImages(storeImage[key])
              : [];
            query.doc(defaultData[key].id).update({
              title: title && title[key] ? title[key] : '',
              content: content && content[key] ? content[key] : '',
              storeImage: newStoreImage,
              youtube: youtube && youtube[key] ? youtube[key] : ''
            });
          } else {
            const newStoreImage = storeImage[key]
              ? await uploadOnboardingImages(storeImage[key])
              : [];
            query.add({
              createdAt: moment().unix(),
              selectedOption,
              title: title && title[key] ? title[key] : '',
              content: content && content[key] ? content[key] : '',
              storeImage: newStoreImage,
              youtube: youtube && youtube[key] ? youtube[key] : '',
              key: Number(key),
              formType: keyFormType[key]
            });
          }
        }).then(() => {
          setSubmitting(false);
          message.success('Updated successfully');
        });
      } else {
        console.log(error);
        setSubmitting(false);
      }
    });
  };

  const onChangeUpload = images => {
    setFieldsValue(prevState => ({
      ...prevState,
      storeImage: images
    }));
  };

  return (
    <Container id="admin-right-bar" style={{ marginBottom: '50px', width: '940px' }}>
      <div className="partnership-header" style={{ marginTop: '20px' }}>
        <Radio.Group value={selectedOption} onChange={handleChange}>
          <Radio.Button value="brand">Brand</Radio.Button>
          <Radio.Button value="product">Product</Radio.Button>
          <Radio.Button value="creators">Creators</Radio.Button>
          <Radio.Button value="social">Social</Radio.Button>
          <Radio.Button value="action">Action</Radio.Button>
        </Radio.Group>
      </div>
      <Row type="flex" justify="space-between" className="search-filters">
        <Col md={24} xs={24}>
          <Row type="flex" justify="end" gutter={8}>
            <Select
              onChange={onChangeSelect}
              allowClear
              placeholder="Select: Content Type"
              showArrow
              style={{
                width: 250,
                marginRight: '12px'
              }}
            >
              <Option value="title">Title</Option>
              <Option value="content">Textbox</Option>
              <Option value="image">Image</Option>
              <Option value="youtube">Youtube</Option>
            </Select>
            <Button onClick={onClickAdd}>Add +</Button>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col md={24} xs={24}>
          <Form name="vertical" onFinish={onFinish} autoComplete="off">
            {tmpKeys.map(k => (
              <>
                <Row type="flex" justify="end" gutter={8}>
                  {/* <p style={{cursor: 'pointer'}} onClick={e => removeContent(k)}>X</p> */}
                </Row>
                <Row type="flex" style={keyFormType[k] !== 'title' ? { display: 'none' } : null}>
                  <Form.Item style={{ width: '500px' }} key={k} label="Title">
                    {getFieldDecorator(`title[${k}]`, {
                      rules: [{ required: false }]
                    })(
                      <Input
                        type="text"
                        size="large"
                        name={`title[${k}]`}
                        style={{ marginTop: '5px' }}
                        className="trend-input"
                        placeHolder="Title"
                      />
                    )}
                  </Form.Item>
                </Row>
                <Row type="flex" style={keyFormType[k] !== 'content' ? { display: 'none' } : null}>
                  <Form.Item label="Textbox" style={{ width: '500px' }}>
                    {getFieldDecorator(`content[${k}]`, {
                      rules: [{ required: false }]
                    })(
                      <TextArea
                        type="text"
                        size="large"
                        name={`content[${k}]`}
                        style={{ marginTop: '5px', height: '100px' }}
                        className="trend-input"
                        placeHolder="Please enter text here..."
                      />
                    )}
                  </Form.Item>
                </Row>
                <Row type="flex" style={keyFormType[k] !== 'image' ? { display: 'none' } : null}>
                  <Form.Item label="Images">
                    {getFieldDecorator(`storeImage[${k}]`, {
                      rules: [{ required: false }]
                    })(
                      <ImageUpload onChange={onChangeUpload} defaultImages={defaultImages} k={k} />
                    )}
                  </Form.Item>
                </Row>
                <Row type="flex" style={keyFormType[k] !== 'youtube' ? { display: 'none' } : null}>
                  <Form.Item style={{ width: '500px' }} key={k} label="Youtube link">
                    {getFieldDecorator(`youtube[${k}]`, {
                      rules: [{ required: false }]
                    })(
                      <Input
                        type="text"
                        size="large"
                        name={`youtube[${k}]`}
                        style={{ marginTop: '5px' }}
                        className="trend-input"
                        placeHolder="Enter youtube link here.."
                      />
                    )}
                  </Form.Item>
                </Row>
              </>
            ))}
            {tmpKeys && tmpKeys.length > 0 ? (
              <Col md={18} xs={24}>
                <Button type="submit" onClick={onFinish} loading={submitting}>
                  Save Changes
                </Button>
              </Col>
            ) : null}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Form.create()(OnboardingGuides);
