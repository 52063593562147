import React from 'react';
import { Row } from 'antd';
import { Link } from 'react-router-dom';
import * as routes from 'constants/routes';

const FreeTrialBadge = () => (
  <Row style={{ padding: '15px 15px 10px 15px' }}>
    <h2 className="text-white" style={{ lineHeight: '22px' }}>
      <small>FREE</small>
      <br />
      TRIAL
    </h2>
    <p className="text-white type-light size-13px">
      This is a free trial mode. Your campaign is currently accepting REAL influencer
      applications. to accept these influencers and move forward with your campaign,
      select a plan below.
    </p>
    <Link className="ant-btn ant-btn-lg trend-btn" size="large" to={routes.SUBSCRIPTION_SETTINGS_PATH}>
      Select Plan
    </Link>
  </Row>
);

export default FreeTrialBadge;
