export const SET_CAMPAIGN_POSTS = 'SET_CAMPAIGN_POSTS';

export const RESET_CAMPAIGN_POSTS = 'RESET_CAMPAIGN_POSTS';

const initialState = {
  // campaignUID: []
};

const campaignPosts = (state = initialState, action) => {
  switch (action.type) {
    case SET_CAMPAIGN_POSTS: {
      const { campaignUID, postUIDs } = action.payload;

      return {
        ...state,
        [campaignUID]: [
          ...new Set([...postUIDs.concat(state[campaignUID] || [])]),
        ],
      };
    }
    case RESET_CAMPAIGN_POSTS:
      return {};

    default:
      return state;
  }
};

export default campaignPosts;
