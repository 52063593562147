/* eslint no-underscore-dangle: ["error", { "allow": ["_chart"] }] */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchPostStatistics } from 'actions/ui/postGraph';
import { Line as LineChart } from 'react-chartjs-2';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

const moment = extendMoment(Moment);

class PostGraph extends Component {
  state = {
    data: {},
    formatDate: 'day'
  };

  componentDidMount() {
    const { brandUID, fetchPostStats, productUID, filteredBy } = this.props;

    fetchPostStats(brandUID, productUID).then(() => {
      this.setData(filteredBy);

      return true;
    });
  }

  componentWillReceiveProps(nextProps) {
    const { filteredBy } = this.props;

    if (nextProps.filteredBy !== filteredBy) {
      this.setData(nextProps.filteredBy);
    }
  }

  setData(filter) {
    const { brandUID, productUID } = this.props;

    let range;
    let formatDate = 'day';
    const yesterday = moment().subtract(1, 'day');
    const today = moment();

    if (filter === '1week') {
      range = moment.range([moment().subtract(7, 'days'), yesterday]);
    } else if (filter === '3months') {
      range = moment.range([moment().subtract(3, 'months'), yesterday]);
      formatDate = 'week';
    } else if (filter === '6months') {
      range = moment.range([moment().subtract(6, 'months'), yesterday]);
      formatDate = 'month';
    } else if (filter === '1year') {
      range = moment.range([moment().subtract(1, 'year'), yesterday]);
      formatDate = 'quarter';
    } else {
      range = moment.range([moment().subtract(30, 'days'), yesterday]);
    }

    let dates = Array.from(range.by('day'));
    const { postStats, posts, postUIDs } = this.props;
    const brandStats = postStats[brandUID];
    if (!brandStats || !brandStats[productUID]) {
      return '';
    }

    const productStats = brandStats[productUID];

    let likeStats = [];

    let lastDataPostWithValue = {};
    dates = dates.map(date => {
      const formattedDate = date.format('YYYY-MM-DD');
      let total = { likes: 0 };

      if (productStats[formattedDate]) {
        Object.keys(productStats[formattedDate]).forEach(postUID => {
          const value = productStats[formattedDate][postUID];

          if (value.likeCount) {
            lastDataPostWithValue = {
              ...lastDataPostWithValue,
              [postUID]: value.likeCount
            };
          }
        });
      }

      Object.keys(lastDataPostWithValue).forEach(postUID => {
        const lastValue = lastDataPostWithValue[postUID];

        total = {
          ...total,
          likes: total.likes + lastValue
        };
      });

      likeStats = [...likeStats, total.likes];

      return formattedDate;
    });

    dates = [...dates, today.format('YYYY-MM-DD')];

    let currentCount = 0;
    postUIDs.forEach(postUID => {
      const post = posts[postUID];
      if (post.type !== 'story' && post.likes) {
        currentCount += post.likes;
      }
    });

    likeStats = [...likeStats, currentCount];

    const ctx = document.getElementById('postGraph').getContext('2d');
    const gradientFill = ctx.createLinearGradient(0, 0, 0, 400);
    gradientFill.addColorStop(0, 'rgba(255, 205, 86, 0.6)');
    gradientFill.addColorStop(1, 'rgba(255, 255, 255, 0.0)');

    const datasets = [
      {
        label: 'LIKES',
        borderColor: '#ffcd57',
        backgroundColor: gradientFill,
        fill: 'origin',
        borderWidth: 1,
        pointRadius: 1,
        pointHitRadius: 5,
        lineTension: 0,
        data: likeStats
      }
    ];

    const data = {
      labels: dates,
      datasets
    };

    this.setState({ data, formatDate });

    return true;
  }

  render() {
    const { data, formatDate } = this.state;
    const options = {
      legend: {
        display: false
      },
      tooltips: {
        mode: 'x',
        intersect: false,
        enabled: false,
        custom: function custom(tooltipModel) {
          let tooltipEl = document.getElementById('chartjs-tooltip');
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            document.body.appendChild(tooltipEl);
          }

          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const titleEl = `<span class='tooltip-title'>${titleLines[0]}</span>`;

            const bodyLines = tooltipModel.body.map(item => item.lines);
            const tooltipBody = [bodyLines[0]]
              .map((body, i) => {
                const colors = tooltipModel.labelColors[i];
                let style = `background: ${colors.borderColor}`;
                style += `; border-color: ${colors.borderColor}`;
                const span = `<span class='tooltip-item-icon' style="${style}"></span>`;
                const [label, value] = body[0].split(': ');

                const labelEl = `<span class='tooltip-item-label'>${label}</span>`;
                const valueEl = `<span class='tooltip-item-value'>${parseInt(
                  value,
                  10
                ).toLocaleString()}</span>`;
                return `<div class='tooltip-item'>${span}${labelEl}${valueEl}</div>`;
              })
              .join('');

            tooltipEl.innerHTML = `<div class='tooltip-container'>${titleEl + tooltipBody}</div>`;
          }

          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          const position = this._chart.canvas.getBoundingClientRect();
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = `${position.left -
            tooltipEl.offsetWidth +
            window.pageXOffset +
            tooltipModel.caretX}px`;
          tooltipEl.style.top = `${position.top -
            tooltipEl.offsetHeight +
            window.pageYOffset +
            tooltipModel.caretY}px`;
        },
        callbacks: {
          title: ([{ xLabel: val }]) => moment(val).format('MMM D YYYY')
        }
      },
      scales: {
        yAxes: [
          {
            position: 'right',
            gridLines: {
              display: false
            },
            ticks: {
              fontColor: '#5b6572',
              fontSize: 8,
              fontFamily: 'TruenoBold',
              textTransform: 'uppercase',
              callback: label => label.toLocaleString()
            }
          }
        ],
        xAxes: [
          {
            type: 'time',
            time: {
              unit: formatDate,
              displayFormats: {
                week: 'MMM DD'
              }
            },
            ticks: {
              fontColor: '#5b6572',
              fontSize: 8,
              fontFamily: 'TruenoBold',
              textTransform: 'uppercase'
            },
            gridLines: {
              offsetGridLines: true
            }
          }
        ]
      }
    };

    return (
      <div id="chartContainer">
        <canvas id="chart-cursor" height={100} />
        <LineChart id="postGraph" data={data} options={options} height={51} />
      </div>
    );
  }
}

const mapStateToProps = (
  state,
  {
    match: {
      params: { campaignId }
    }
  }
) => ({
  postStats: state.entities.postStatistics.byId,
  brandUID: state.entities.session.authUserData.uid,
  posts: state.entities.posts.byId,
  filteredBy: state.ui.postGraph.filteredBy,
  postUIDs: state.ui.campaignPosts[campaignId]
});

const mapDispatchToProps = dispatch => ({
  fetchPostStats: (brandUID, productUID) => dispatch(fetchPostStatistics(brandUID, productUID))
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PostGraph);
