export const sortCampaigns = (ids, campaigns) =>
  ids.sort((a, b) => {
    const campaignA = new Date(campaigns[a].createdAt).getTime();
    const campaignB = new Date(campaigns[b].createdAt).getTime();
    if (campaignA < campaignB) {
      return 1;
    }
    if (campaignA > campaignB) {
      return -1;
    }
    return 0;
  });

export const filterCampaignList = (campaigns, campaignUIDs, { brandUID, category }) =>
  campaignUIDs.filter(campaignUID => {
    const campaign = campaigns[campaignUID];

    const categoryFlag = category ? campaign.category === category : true;
    const myCampaignFlag = brandUID ? campaign.brandUID === brandUID : true;

    return campaign.isListed === true && categoryFlag && myCampaignFlag;
  });

export const lastActiveCampaignId = (campaignIds = [], campaigns) => {
  const filteredIds = campaignIds.filter(
    id => campaigns[id].filters && campaigns[id].filters.createdTimeUnix.includes('ACTIVE')
  );
  return filteredIds[filteredIds.length - 1];
};

export const appliedInfluencers = campaign => {
  if (!campaign.appliedUID) {
    return [];
  }
  return Object.keys(campaign.appliedUID).filter(influencerUID => {
    const appliedInfluencer = campaign.appliedUID[influencerUID];

    return appliedInfluencer.status === 'submitted';
  });
};

export const appliedInfluencerByStatus = (campaign, status) => {
  if (campaign && !campaign.appliedUID) {
    return [];
  }

  return Object.keys(campaign.appliedUID).filter(influencerUID => {
    const appliedInfluencer = campaign.appliedUID[influencerUID];

    if (Array.isArray(status)) {
      return status.includes(appliedInfluencer.status);
    }

    return appliedInfluencer.status === status;
  });
};
