/* eslint no-console: ["error", { allow: ["log"] }] */
import * as Promise from 'bluebird';
import moment from 'moment';
import {
  RECEIVE_CAMPAIGNS,
  RECEIVE_CAMPAIGN,
  ADD_CAMPAIGN,
  UPDATE_CAMPAIGN,
  CAMPAIGN_FETCH_REQUEST,
  CAMPAIGN_RECEIVE_REQUEST,
  APPROVED_APPLIEDUIDS
} from 'reducers/entities/campaigns';
import { BRAND_PRODUCT_STATUS } from 'constants/brands';
import { CAMPAIGN_APPLIED_UID_STATUS } from 'constants/campaigns';
import { setCurrentCampaign } from 'actions/ui/campaignDashboard';
import { moveSnapApi } from 'api/common';
import { DATE_TIMEZONE_FORMAT } from 'constants/common';
import { fetchCampaignActiveOrComplete } from 'api/campaigns';
import { firebase } from '../lib/Firebase';
import { receiveBrand, receiveBrands } from './brands';

export const fetchRequest = () => ({ type: CAMPAIGN_FETCH_REQUEST });

export const receiveRequest = () => ({ type: CAMPAIGN_RECEIVE_REQUEST });

// FETCHING ALL CAMPAIGNS REGARDLESS OF WHAT BRAND
export const receiveCampaigns = campaigns => ({
  type: RECEIVE_CAMPAIGNS,
  payload: campaigns
});

// FETCHING A SIGNLE CAMPAIGN
export const receivedCampaign = campaign => ({
  type: RECEIVE_CAMPAIGN,
  payload: campaign
});

export const addCampaign = campaign => ({
  type: ADD_CAMPAIGN,
  payload: campaign
});

export const updateCampaign = campaign => ({
  type: UPDATE_CAMPAIGN,
  payload: campaign
});

export const addApprovedAppliedUIDs = approvedAppliedUIDs => ({
  type: APPROVED_APPLIEDUIDS,
  approvedAppliedUIDs
});

export const fetchApprovedInfluencerInCampaign = campaignUID => dispatch => {
  fetchCampaignActiveOrComplete(campaignUID, (campaign, error) => {
    if (error) {
      console.log('Error on fetchApprovedInfluencerInCampaign: ', error);
    } else {
      const { appliedUID = {} } = campaign;

      Object.keys(appliedUID).forEach(influencerUID => {
        if (appliedUID[influencerUID].status === CAMPAIGN_APPLIED_UID_STATUS.approved) {
          dispatch(addApprovedAppliedUIDs(influencerUID));
        }
      });
    }
  });
};

export const fetchCampaigns = () => dispatch => {
  dispatch(fetchRequest());

  firebase.activeProducts().once('value', snapshot => {
    const campaigns = {};
    if (!snapshot.exists()) {
      return;
    }

    Object.entries(snapshot.val()).forEach(([key, val]) => {
      if (val.isListed === true) {
        campaigns[key] = val;
      }
    });

    const brandIds = Object.values(campaigns).map(obj => obj.brandUID);

    const brands = {};
    Promise.map(
      brandIds,
      id =>
        firebase.brand(id).once('value', brand => {
          brands[id] = brand.val();
        }),
      {
        concurrency: 5
      }
    )
      .then(() => {
        dispatch(receiveBrands(brands));
        dispatch(receiveCampaigns(snapshot.val()));
      })
      .catch(e => {
        console.log('Error on Getting brands: ', e);
      });
  });
};

export const fetchCampaignById = campaignUID => dispatch =>
  firebase.activeProduct(campaignUID).once('value', snapshot => {
    const campaign = snapshot.val();

    if (!campaign) {
      return;
    }

    dispatch(receivedCampaign({ [campaignUID]: campaign }));

    firebase.brand(campaign.brandUID).once('value', brandSnapshot => {
      const brand = brandSnapshot.val();

      if (!brand) {
        return;
      }

      dispatch(receiveBrand({ [campaign.brandUID]: brand }));
    });
  });

// FETCHING CAMPAIGNS OF A SPECIFIC BRAND
export const fetchBrandCampaigns = brandId => dispatch =>
  firebase
    .activeProducts()
    .orderByChild('brandUID')
    .equalTo(brandId)
    .on('value', snapshot => {
      const campaigns = snapshot.val();
      if (campaigns) {
        dispatch(receiveCampaigns(campaigns));
      }
    });

const uploadProcess = process =>
  new Promise((resolve, reject) => {
    process.on(
      'state_changed',
      () => {},
      error => {
        reject(error);
      },
      async () => {
        const url = process.snapshot.ref.getDownloadURL();
        resolve(url);
      }
    );
  });

const uploadStyleGuideImages = async (productRef, brandUID, guidelines = {}) => {
  let styleGuide = {};
  await Promise.map(Object.keys(guidelines), async uid => {
    const guide = guidelines[uid];

    if (uid.includes('pending')) {
      const { file, position, removed } = guide;
      if (removed) return;
      const metadata = {
        contentType: file.type
      };

      const storageRef = firebase.storage
        .ref('brands')
        .child(`${brandUID}/products/${productRef.key}/styleGuide`);

      const filename = productRef.push().key;
      const uploadTask = storageRef.child(filename).put(file, metadata);

      const url = await uploadProcess(uploadTask);

      styleGuide = {
        ...styleGuide,
        [filename]: {
          url,
          position
        }
      };
    } else if (guide.removed) {
      const storageRef = firebase.storage
        .ref('brands')
        .child(`${brandUID}/products/${productRef.key}/styleGuide/${uid}`);
      storageRef.delete();
    } else {
      styleGuide = {
        ...styleGuide,
        [uid]: guide
      };
    }
  });

  return styleGuide;
};

export const createCampaign = (campaign, campaignUID, brandUID) => async dispatch => {
  const productRef = firebase.activeProduct(campaignUID);
  try {
    // put it try catch so if errors it doesn't create products record on brand
    const styleGuide = await uploadStyleGuideImages(productRef, brandUID, campaign.styleGuide);

    const newCampaign = {
      ...campaign,
      styleGuide,
      createdAt: moment().format(DATE_TIMEZONE_FORMAT),
      reverseEpochTimestamp: Math.floor(Date.now() / 1000) * -1,
      isVIP: true
    };

    await productRef.set(newCampaign);
    dispatch(addCampaign({ [campaignUID]: campaign }));
    await firebase
      .brand(brandUID)
      .child('products')
      .update({
        [campaignUID]: BRAND_PRODUCT_STATUS.active
      });

    return campaignUID;
  } catch (e) {
    console.log('Error on creating Campaign: ', e);
    return null;
  }
};

export const editCampaign = (campaign, id, brandUID) => async dispatch => {
  const productSnap = await firebase.activeProduct(id).once('value');
  let productRef;

  if (productSnap.exists()) {
    productRef = productSnap.ref;
  } else {
    productRef = firebase.completedProduct(id);
  }

  const styleGuide = await uploadStyleGuideImages(productRef, brandUID, campaign.styleGuide);

  const params = {
    ...campaign,
    styleGuide,
    reverseEpochTimestamp: Math.floor(Date.now() / 1000) * -1
  };

  return productRef.update(params, error => {
    if (error) {
      console.log('Error on updating Campaign: ', error);
    } else {
      dispatch(updateCampaign({ [id]: campaign }));
      firebase
        .brand(campaign.brandUID)
        .child('products')
        .update({ [id]: BRAND_PRODUCT_STATUS.active });
      firebase.firestore
        .collection('products')
        .doc(id)
        .update({ ...params });
    }
  });
};

export const removeCampaignFromStore = (campaignUID, onComplete = null) => dispatch => {
  firebase
    .activeProduct(campaignUID)
    .update({ isListed: false })
    .then(error => {
      if (!error) {
        firebase.activeProduct(campaignUID).once('value', snapshot => {
          dispatch(updateCampaign({ [campaignUID]: snapshot.val() }));
          dispatch(setCurrentCampaign({ campaignUID, ...snapshot.val() }));

          if (onComplete) {
            onComplete();
          }
        });
      }
    });
};
export const addCampaignBackToStore = (campaignUID, onComplete = null) => dispatch => {
  firebase
    .activeProduct(campaignUID)
    .update({ isListed: true })
    .then(error => {
      if (!error) {
        firebase.activeProduct(campaignUID).once('value', snapshot => {
          dispatch(updateCampaign({ [campaignUID]: snapshot.val() }));
          dispatch(setCurrentCampaign({ campaignUID, ...snapshot.val() }));

          if (onComplete) {
            onComplete();
          }
        });
      }
    });
};

export const archiveCampaign = (campaignUID, brandUID, onComplete = null) => () => {
  const activeRef = firebase.activeProduct(campaignUID);
  const completedRef = firebase.completedProduct(campaignUID);
  const completeCallback = onComplete;

  moveSnapApi({ fromRef: activeRef, toRef: completedRef }, () => {
    firebase
      .brand(brandUID)
      .child('products')
      .child(campaignUID)
      .set(BRAND_PRODUCT_STATUS.completed);
    firebase
      .completedProduct(campaignUID)
      .update({ timestampCompleted: moment().format(DATE_TIMEZONE_FORMAT) });
    if (completeCallback) {
      completeCallback();
    }
  });
};

export const revertArchiveCampaign = (campaignUID, brandUID, onComplete = null) => () => {
  const activeRef = firebase.activeProduct(campaignUID);
  const completedRef = firebase.completedProduct(campaignUID);
  const completeCallback = onComplete;

  moveSnapApi({ fromRef: completedRef, toRef: activeRef }, () => {
    firebase
      .activeProduct(campaignUID)
      .child('timestampCompleted')
      .remove();
    firebase
      .brand(brandUID)
      .child('products')
      .child(campaignUID)
      .set(BRAND_PRODUCT_STATUS.active);
    if (completeCallback) {
      completeCallback();
    }
  });
};
