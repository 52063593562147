/* eslint no-console: ["error", { allow: ["log"] }] */
import { firebase } from "lib/Firebase";
import * as Promise from "bluebird";
import { MESSAGE_TYPE } from "constants/common";
import {
  ADD_MESSAGES,
  MESSAGE_FETCH_REQUEST,
  MESSAGE_RECEIVE_REQUEST
} from "reducers/entities/messages";
import { congratulationMsg } from "./ui/applicationsAction";
import { fetchInfluencer, setInfluencers } from "./influencers";

export const fetchRequest = () => ({ type: MESSAGE_FETCH_REQUEST });

export const receiveRequest = () => ({ type: MESSAGE_RECEIVE_REQUEST });

export const addMessages = (messages, campaignUID) => ({
  type: ADD_MESSAGES,
  messages,
  campaignUID
});

export const fetchMessage = messageUID => dispatch => {
  dispatch(fetchRequest());

  firebase.message(messageUID).on(
    "value",
    snapshot => {
      const message = snapshot.val();

      if (!message) {
        dispatch(receiveRequest());
        return;
      }

      const { users = {} } = message;

      Object.keys(users).forEach(userUID => {
        const user = users[userUID];
        if (user.type === MESSAGE_TYPE.influencer) {
          dispatch(fetchInfluencer(userUID));
          dispatch(
            addMessages({
              [messageUID]: {
                messageUID,
                ...message
              }
            })
          );
        }
      });
    },
    error => {
      console.log("Error on fetching a thread: ", error);
      dispatch(receiveRequest());
    }
  );
};

export const markThreadAsRead = messageID => dispatch => {
  dispatch(fetchRequest());
  firebase
    .message(messageID)
    .child("users")
    .orderByChild("type")
    .equalTo("brand")
    .once(
      "value",
      snapshot => {
        if (!snapshot.val()) {
          dispatch(receiveRequest());
          return;
        }

        firebase
          .message(messageID)
          .child(`users/${Object.keys(snapshot.val())[0]}`)
          .update({ hasUnread: false })
          .then(() => {
            dispatch(receiveRequest());
          });
      },
      error => {
        console.log("Error on fetching a thread: ", error);
        dispatch(receiveRequest());
      }
    );
};

export const fetchMessages = (
  campaignUID,
  type,
  onComplete = null
) => dispatch => {
  dispatch(fetchRequest());
  const userList = {};
  firebase
    .messages()
    .orderByChild("details/partnershipUID")
    .equalTo(campaignUID)

    .on("value", async snapshot => {
      const messages = snapshot.val();

      if (!messages) {
        dispatch(receiveRequest());
        if (onComplete) {
          onComplete(messages);
        }
        return;
      }

      // const influencerSnap = await firebase
      //   .activeProduct(campaignUID)
      //   .child("appliedUID")
      //   .once("value");

      // const influencers = influencerSnap.val();
      // const approvedInfluencerUIDs = Object.keys(
      //   pickBy(
      //     influencers,
      //     influencer =>
      //       influencer.status === CAMPAIGN_APPLIED_UID_STATUS.approved
      //   )
      // );

      let newMessages = {};

      await Promise.map(Object.keys(messages), messageUID => {
        const message = messages[messageUID];
        const { details, users = {}, messages: messageThreads } = message;

        let approved = false;
        Object.keys(users).forEach(userMessageUID => {
          const user = users[userMessageUID];

          if (user.type === MESSAGE_TYPE.influencer) {
            userList[userMessageUID] = user;
            approved = true;
          }
        });

        if (approved && users && details.partnershipType === type) {
          let isCongratsMsg = true;
          if (Object.keys(messageThreads).length === 1) {
            const [messageDetails] = Object.values(messageThreads);
            isCongratsMsg =
              messageDetails.text.slice(0, 16) ===
              congratulationMsg().slice(0, 16);
          }

          if (Object.keys(messageThreads).length > 1 || isCongratsMsg) {
            newMessages = {
              ...newMessages,
              [messageUID]: {
                messageUID,
                ...message
              }
            };
          }
        }
      });
      const influencerList = {};
      Promise.map(Object.keys(userList), async userID => {
        await firebase.influencer(userID).once("value", snapshot => {
          if (snapshot.val()) {
            influencerList[userID] = snapshot.val();
          }
        });
      }).then(() => {
        dispatch(setInfluencers(influencerList));
      });

      dispatch(addMessages(newMessages));
      dispatch(receiveRequest());
      if (onComplete) {
        onComplete(messages);
      }
    });
};
