import React, { useEffect, useState } from 'react';
import { Row, Col, Icon, Button, Modal } from 'antd';
import { ReactComponent as TrendLogoWeb } from 'images/trend-logo-web.svg';
import { useHistory, Prompt } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import * as routes from 'constants/routes';
import { useBeforeunload } from 'react-beforeunload';
import { CampaignFormV2 } from '../../components/Campaigns';
import { firebase } from '../../lib/Firebase';
import { createCampaign } from '../../actions/campaigns';
import useSession from '../../lib/Session/useSession';
import { ReactComponent as BackIcon } from '../../images/back-arrow-white.svg';

const NewPage = () => {
  const dispatch = useDispatch();
  const [campaignUID, setCampaignUID] = useState();
  const [loading, setLoading] = useState(true);
  const [showBackModal, setShowBackModal] = useState(false);
  const [prompt, setPrompt] = useState(true);
  const [campaign, setCampaign] = useState({});
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [cookies] = useCookies(['isAdmin']);
  const authUser = useSession();
  const history = useHistory();

  useBeforeunload(() => "You'll lose your data!");

  async function setData() {
    // const data = localStorage.getItem('campaign');
    let dataUID = localStorage.getItem('campaignUID');
    if (!dataUID) {
      dataUID = await firebase.activeProducts().push().key;
      localStorage.setItem('campaignUID', dataUID);
    }

    setCampaignUID(dataUID);
    // setCampaign(JSON.parse(data || '{}'));
    setLoading(false);
  }

  useEffect(() => {
    const { uid } = firebase.auth.currentUser;

    if (!cookies.isAdmin) {
      firebase
        .brand(uid)
        .child('details')
        .update({ lastLoginDate: new Date().getTime() / 1000 });
    }

    setData();
  }, []);

  async function handleSubmit(values = {}, hasError = false) {
    const brandUID = authUser.uid;

    setPrompt(false);

    let payload = {
      ...campaign,
      ...values,
      isListed: false,
      isVIP: true,
      brandUID
    };

    Object.keys(payload).forEach(keys => {
      if (payload[keys] === undefined) delete payload[keys];
    });

    setCampaign(payload);

    // localStorage.setItem('campaign', JSON.stringify(payload));

    if (values.category && !hasError) {
      payload = {
        ...payload,
        isListed: true,
        states: { All: 'All' }
      };

      await dispatch(createCampaign(payload, campaignUID, brandUID));
      // localStorage.removeItem('campaign');
      localStorage.removeItem('campaignUID');
      history.push(routes.toCampaignSummaryPath(campaignUID));
    }
  }

  if (loading) {
    return <div />;
  }

  const handleBlockedNavigation = () => {
    if (!confirmedNavigation) {
      setShowBackModal(true);
      return false;
    }

    return true;
  };

  const handleConfirmNavigationClick = e => {
    e.preventDefault();
    history.goBack();
    setConfirmedNavigation(true);
    setShowBackModal(false);
  };

  return (
    <div style={{ background: '#000' }} id="new-campaign-page">
      <Prompt when={prompt} message={handleBlockedNavigation} />
      <Row
        type="flex"
        justify="space-around"
        style={{ borderBottom: '1px solid #3C3C3C', padding: '15px 15px 15px 0px' }}
      >
        <Col sm={12} style={{ lineHeight: '13px' }}>
          <Button
            type="link"
            style={{
              position: 'absolute',
              fontSize: '18px',
              lineHeight: '13px',
              display: 'inline-block',
              top: '-9px'
            }}
            onClick={() => {
              setShowBackModal(true);
            }}
          >
            <Icon component={BackIcon} />
          </Button>
          <TrendLogoWeb className="logo d-iblock" width="70px" style={{ marginLeft: '53px' }} />
        </Col>
        <Col sm={12} style={{ textAlign: 'right', lineHeight: '13px' }}>
          <span
            style={{
              color: '#fff',
              fontFamily: 'SFProDisplay',
              fontSize: '14px',
              fontWeight: 'bold'
            }}
          >
            Need Help Setting Up Your Campaign?{' '}
            <a
              href="https://calendly.com/jamie-catanach/onboarding"
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontStyle: 'italic' }}
            >
              Schedule A Call
            </a>
          </span>
        </Col>
      </Row>
      <Row>
        <Col>
          <CampaignFormV2 campaignUID={campaignUID} campaign={campaign} onSubmit={handleSubmit} />
        </Col>
      </Row>
      <Modal
        visible={showBackModal}
        closable={false}
        className="trend-modal"
        footer={null}
        width={735}
        style={{ height: '384px' }}
      >
        <div style={{ textAlign: 'center', marginTop: '87px' }}>
          <h2
            className="type-sfpro-regular black mb-2 ht-6 lh-2 size-40px"
            style={{ fontWeight: 800 }}
          >
            Are you sure you want to leave?
          </h2>
          <div
            className="type-sfpro-regular text-black size-18px lh-20px"
            style={{ marginTop: '12px', fontWeight: 400 }}
          >
            {"None of your progress will be saved. You'll have to start from scratch."}
          </div>
        </div>
        <Row
          gutter={20}
          key="row-multi-action"
          style={{
            textAlign: 'center',
            width: '550px',
            margin: '0 auto',
            marginTop: '58px',
            marginBottom: '91px'
          }}
        >
          <Col span={12}>
            <Button
              key="cancel"
              className="size-20px trend-btn type-sfpro-regular"
              onClick={() => {
                setShowBackModal(false);
              }}
              block
              style={{
                width: '236px',
                height: '56px',
                fontFamily: 'SFProDisplay',
                borderRadius: '32px',
                fontStyle: 'italic',
                fontWeight: 800,
                fontSize: '20px !important',
                textTransform: 'none'
              }}
            >
              {"No, I'll Stay"}
            </Button>
          </Col>
          <Col span={12}>
            <Button
              key="approve"
              type="primary"
              className="type-sfpro-regular size-20px trend-btn secondary"
              onClick={handleConfirmNavigationClick}
              block
              style={{
                width: '236px',
                height: '56px',
                fontFamily: 'SFProDisplay',
                borderRadius: '32px',
                fontStyle: 'italic',
                fontWeight: 800,
                fontSize: '20px !important',
                textTransform: 'none',
                color: '#000',
                background: '#FFFFFF',
                border: '3px solid #000000'
              }}
            >
              Yes, Leave
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default NewPage;
