import React from 'react';
import { Row, Col } from 'antd';

const FirstCampaignEmptyApplications = () => (
  <Row type="flex" justify="center" className="empty-application">
    <Col span={16} style={{ textAlign: 'center' }}>
      <Row type="flex" justify="center">
        <Col style={{ width: '446px', height: '250px', marginBottom: '22px' }}>
          <iframe
            title="Onboarding"
            src="https://www.youtube.com/embed/OozxyK3wccQ"
            width="446"
            height="250"
            frameBorder="0"
            allowFullScreen
          />
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Col style={{ width: '600px' }}>
          <p style={{ fontSize: '20px', lineHeight: '28px' }}>
            Your campaign is live and has been sent to our network. Creator <br />
            applications that will show up here as soon as 5 minutes from <br />
            now. Check in throughout the day to review your applications <br />
            and add Creator Credits to start working with them.
          </p>
        </Col>
      </Row>
    </Col>
  </Row>
);

export default FirstCampaignEmptyApplications;
