import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import {
  Table,
  Button,
  Row,
  Col,
  Radio,
  Icon,
  Modal,
  Input,
  Dropdown,
  Menu,
  Alert,
  Divider,
  notification
} from 'antd';
import { withFirebase } from 'lib/Firebase';
import rejectInfluencer from 'actions/ui/influencersAction';
import { REJECT_REASONS } from 'constants/influencers';
import sortInfluencers from 'selectors/sortInfluencers';
import MinLayout from 'components/Common/MinLayout';
import { ReactComponent as IconMessages } from 'images/icon-messages.svg';
import { UpCaseFirstLetter } from 'lib/Common';
import { InfluencerDrawer, InfluencerAvatar } from '../../Influencers';

const { Group: RadioGroup } = Radio;
const { TextArea } = Input;
const { Item: MenuItem } = Menu;

const ActionButton = ({
  influencerUID,
  isFetchingMessageUID,
  clickMsgInfluencerUID,
  showConfirmModal,
  onClickToMessageThread
}) => {
  const moreOptions = (
    <Menu className="remove-dropdown">
      <MenuItem>
        <span onClick={() => showConfirmModal(influencerUID)} role="presentation">
          REMOVE FROM CAMPAIGN
        </span>
      </MenuItem>
    </Menu>
  );
  const loadingMsg = isFetchingMessageUID && clickMsgInfluencerUID === influencerUID;
  return (
    <div className="cell-action">
      <Button
        onClick={() => onClickToMessageThread(influencerUID)}
        shape="circle"
        style={{
          background: '#ffb917',
          border: '0',
          marginRight: '5px',
          width: '35px',
          height: '35px'
        }}
      >
        {loadingMsg && (
          <Icon
            type="loading"
            style={{
              color: '#ffffff',
              width: '15px',
              height: '15px',
              position: 'relative'
            }}
            spin
          />
        )}
        {!loadingMsg && (
          <IconMessages
            style={{
              position: 'relative',
              top: '2px',
              width: '15px',
              height: '14px'
            }}
          />
        )}
      </Button>

      <Dropdown
        overlay={moreOptions}
        placement="bottomRight"
        trigger={['click']}
        className="more-options-btn"
      >
        <Button shape="circle">
          <Icon type="ellipsis" />
        </Button>
      </Dropdown>
    </div>
  );
};

const Categories = categories =>
  Object.keys(categories).map(category => <div key={category}>{UpCaseFirstLetter(category)}</div>);

const columns = [
  {
    title: 'INFLUENCER',
    dataIndex: 'influencer',
    key: 'influencer',
    render: influencer => <InfluencerAvatar influencer={influencer} />,
    className: 'pl-0 influencer-width influencer-details'
  },
  {
    title: 'LOCATION',
    dataIndex: 'location',
    key: 'location',
    className: 'text-right location'
  },
  {
    title: 'AGE RANGE',
    dataIndex: 'ageRange',
    key: 'ageRange',
    className: 'text-right'
  },
  {
    title: 'GENDER',
    dataIndex: 'gender',
    key: 'gender',
    className: 'text-right'
  },
  {
    title: 'CATEGORY',
    dataIndex: 'category',
    key: 'category',
    className: 'text-right',
    render: Categories
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    align: 'right',
    className: 'actions',
    render: ActionButton
  }
];

const defaultState = {
  rejectReason: REJECT_REASONS[0][1],
  rejectCustomReason: '',
  confirmModal: false,
  rejectModal: false,
  isValidReason: true,
  submitReject: false,
  influencerUID: undefined,
  clickMsgInfluencerUID: undefined,
  confirmRejectModal: false,
  influencerDetailsModal: {
    show: false,
    influencerUID: null
  }
};

class Influencers extends Component {
  state = defaultState;

  rejectInfluencer = () => {
    const { rejectReason: reason, rejectCustomReason: customReason, influencerUID } = this.state;

    const { rejectInfluencer: reject, influencers, campaign, match } = this.props;

    if (!reason && !customReason.trim()) {
      this.setState(() => ({ isValidReason: false }));
    } else {
      this.setState(() => ({ submitReject: true }));

      const rejectReason = reason || customReason.trim();
      const args = {
        rejectReason,
        influencerUID,
        influencer: influencers[influencerUID].details,
        campaignUID: match.params.campaignId,
        campaign,
        brandUID: campaign.brandUID
      };

      reject(args, (isSuccess, { status, error } = {}) => {
        if (isSuccess) {
          notification.success({
            message: 'Rejected',
            description: 'Influencers successfully rejected!',
            duration: 2
          });
        } else {
          const notifType = status === 400 ? 'warning' : 'error';
          notification[notifType]({
            message: UpCaseFirstLetter(notifType),
            description: String(error) || 'Somethings wrong on rejecting influencer',
            duration: 4
          });
        }
        this.setState(() => ({ ...defaultState }));
      });
    }
  };

  onChangeRejectReason = e => {
    this.setState(() => ({
      isValidReason: true,
      rejectReason: e.target.value
    }));
  };

  onChange = e => {
    const { target } = e;
    this.setState(() => ({ [target.name]: target.value }));
  };

  showConfirmModal = influencerUID => {
    this.setState(() => ({
      influencerUID,
      confirmRejectModal: true
    }));
  };

  onClickToMessageThread = influencerUID => {
    this.setState(() => ({
      clickMsgInfluencerUID: influencerUID
    }));

    const { onClickToMessageThread: toMessageThread } = this.props;

    toMessageThread(influencerUID);
  };

  handleShowInfluencerDetails = influencerUID => event => {
    if (
      (event.target.tagName !== 'TD' &&
        event.target.closest('td') &&
        event.target.closest('td').className.includes('actions')) ||
      (event.target.closest('li') &&
        event.target.closest('li').className.includes('ant-dropdown-menu-item'))
    ) {
      return;
    }

    this.setState(state => {
      return {
        ...state,
        influencerDetailsModal: {
          show: true,
          influencerUID
        }
      };
    });
  };

  handleHideInfluencerDetails = () => {
    this.setState(state => {
      return {
        ...state,
        influencerDetailsModal: {
          ...state.influencerDetailsModal,
          show: false
        }
      };
    });
  };

  render() {
    const { influencers, influencerIds, isFetchingMessageUID, isFetching, isUpdating } = this.props;

    const {
      clickMsgInfluencerUID,
      influencerUID: stateInfluencerUID,
      submitReject,
      rejectModal,
      rejectReason,
      rejectCustomReason,
      isValidReason,
      confirmRejectModal,
      influencerDetailsModal
    } = this.state;

    const mapInfluencers = influencerIds.map(influencerUID => {
      const influencer = influencers[influencerUID];
      const influencerDetails = influencer.details;

      return {
        key: influencerUID,
        influencer: influencerDetails,
        location: influencerDetails.completeAddress,
        gender: UpCaseFirstLetter(influencerDetails.gender),
        ageRange: influencerDetails.ageRange,
        category: influencerDetails.categories,

        action: {
          influencerUID,
          isFetchingMessageUID,
          clickMsgInfluencerUID,
          showConfirmModal: this.showConfirmModal,
          onClickToMessageThread: this.onClickToMessageThread
        }
      };
    });

    const reasons = REJECT_REASONS.map(([reasonShow, reasonMessage]) => (
      <Radio
        key={reasonShow}
        style={{ display: 'block', height: '30px', lineHeight: '30px' }}
        value={reasonMessage}
      >
        {reasonShow}
      </Radio>
    ));

    const influencer = influencers[stateInfluencerUID];
    const isVIP = influencer && influencer.details.isVIP;

    return (
      <Row type="flex" justify="space-around">
        <MinLayout>
          <Table
            loading={isFetching || isUpdating}
            columns={columns}
            dataSource={mapInfluencers}
            pagination={false}
            className="trend-table"
            onRow={record => ({
              onClick: this.handleShowInfluencerDetails(record.key)
            })}
          />
          <Modal
            closable={false}
            visible={confirmRejectModal}
            onCancel={() => this.setState(() => ({ confirmRejectModal: false }))}
            className="trend-modal"
            footer={null}
          >
            <span className="label">
              Are you sure you want to&nbsp;
              <span className="highlighted">remove this Influencer?</span>
              Influencers should only be removed in an emergency situation.
            </span>

            <Row style={{ marginTop: '20px' }} gutter={20} key="reject-modal">
              <Col span={12}>
                <Button
                  key="cancel"
                  onClick={() => this.setState(() => ({ confirmRejectModal: false }))}
                  className="trend-btn medium-btn secondary"
                  block
                >
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  key="submit"
                  loading={submitReject}
                  onClick={() => {
                    this.setState(() => ({
                      rejectModal: true,
                      confirmRejectModal: false
                    }));
                  }}
                  className="trend-btn medium-btn"
                  block
                >
                  Confirm
                </Button>
              </Col>
            </Row>
          </Modal>
          <Modal
            visible={rejectModal}
            onOk={this.rejectInfluencer}
            onCancel={() => this.setState(() => ({ rejectModal: false }))}
            className="trend-modal"
            footer={[
              <Row gutter={20} key="reject-modal">
                <Col span={12}>
                  <Button
                    key="cancel"
                    disabled={submitReject}
                    onClick={() => this.setState(() => ({ rejectModal: false }))}
                    className="trend-btn medium-btn secondary"
                    block
                  >
                    Cancel
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    key="submit"
                    loading={submitReject}
                    onClick={this.rejectInfluencer}
                    className="trend-btn medium-btn reject"
                    block
                  >
                    Confirm Reject
                  </Button>
                </Col>
              </Row>
            ]}
          >
            <h2 className="text-black mb-2 type-ebold leading-24px">
              {isVIP ? 'Reject VIP Influencer' : 'Reject Standard Influencer'}
            </h2>
            <Divider />
            {isVIP && (
              <div>
                You&apos;re about to reject a VIP influencer. You will get back 1 VIP token.
              </div>
            )}
            {!isVIP && (
              <div>
                You&apos;re about to reject a standard influencer. You will get back 1 standard
                token.
              </div>
            )}
            <RadioGroup
              onChange={this.onChangeRejectReason}
              value={rejectReason}
              style={{ marginTop: '10px' }}
            >
              {reasons}
            </RadioGroup>
            {!rejectReason && (
              <TextArea
                rows={4}
                onChange={this.onChange}
                name="rejectCustomReason"
                value={rejectCustomReason}
              />
            )}
            {!rejectReason && !isValidReason && (
              <Alert
                message="Please enter a reason to explain to the influencer why they are being rejected."
                type="warning"
                style={{ marginTop: '5px' }}
              />
            )}
          </Modal>
          <InfluencerDrawer {...influencerDetailsModal} onHide={this.handleHideInfluencerDetails} />
        </MinLayout>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  influencers: state.entities.influencers.byId,
  influencerIds: sortInfluencers(
    state.ui.campaignDashboard.byCampaignId[ownProps.match.params.campaignId].influencerUIDs,
    state.entities.campaigns.byId[ownProps.match.params.campaignId]
  ),
  campaign: state.entities.campaigns.byId[ownProps.match.params.campaignId],
  isFetching: state.entities.influencers.isFetching,
  isUpdating: state.entities.campaigns.isFetching,
  isFetchingMessageUID: state.entities.messages.isFetching
});

const mapDispatchToProps = dispatch => ({
  rejectInfluencer: (args, callback) => dispatch(rejectInfluencer(args, callback))
});

export default compose(
  withRouter,
  withFirebase,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Influencers);
