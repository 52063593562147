// INSTRUCTIONS
//
// ROUTE USAGE FORMAT
// format: toPathName
// ex
//   this.props.history.push(toCampaignSummaryPath(campaignId))
//
// ROUTE DECLERATION FORMAT
// format: NAME_OF_PATH
// ex.
//   <Route to={CAMPAIGN_SUMMARY_PATH} />
//
// NOTE: Always add a 'PATH' suffix to the path.
// ex.
//   - toPostListPath
//   - POST_LIST_PATH
//

export const toCampaignSummaryPath = campaignId => `/campaigns/${campaignId}/summary`;
export const toCampaignApplicationsPath = campaignId => `/campaigns/${campaignId}/applications`;

export const SUBSCRIPTION_PATH = '/subscribe';
export const SETUP_STORE_PATH = '/setup/store';
export const PRE_ONBOARDING_PATH = '/onboarding';
export const FIRST_CAMPAIGN_PATH = '/campaigns/firstCampaign';

export const CAMPAIGN_SUMMARY_PATH = '/campaigns/:campaignId/summary';
export const CAMPAIGN_HISTORY_PATH = '/campaigns/history/';

export const ACCOUNT_SETTINGS_PATH = '/account';
export const BRAND_EDIT_PATH = '/account/store/edit';
export const TOS_PATH = '/account/tos';
export const PRIVACY_PATH = '/account/privacy';
export const NOTIFICATIONS_PATH = '/account/notifications';
export const SUBSCRIPTION_SETTINGS_PATH = '/account/subscription';
export const ADD_CREDITS_PATH = '/account/addCredits';
export const SUCCESS_PURCHASE_PATH = '/success-purchase';
export const BRAND_INTEGRATIONS = '/account/brand-integrations';

export const MANAGE_CREDITS_PATH = '/credits';
export const MANAGE_PLAN_PATH = '/credits/managePlan';
export const MANAGE_ADD_CREDITS_PATH = '/credits/addCredits';

export const ADMIN_OPTIONS_PATH = '/admin';
export const ADMIN_BRANDS_PATH = '/admin/brands';
export const ADMIN_BRAND_PATH = '/admin/brands/:id';
export const ADMIN_TRIALS_PATH = '/admin/trials';
export const ADMIN_CONTENTS = '/admin/contents';

// demo path
export const DEMO_MESSAGES_PATH = '/campaigns/:campaignId/demo/messages';
export const DEMO_MESSAGE_CHATBOX_PATH = '/campaigns/:campaignId/demo/messages/:messageId';
export const DEMO_MY_INFLUENCERS_PATH = '/campaigns/:campaignId/demo/my_influencers';
export const DEMO_MY_INFLUENCERS_PRODUCT_SHIPMENTS_PATH =
  '/campaigns/:campaignId/demo/my_influencers/product_shipments';
export const DEMO_MY_INFLUENCERS_POST_APPROVAL_PATH =
  '/campaigns/:campaignId/demo/my_influencers/post_approval';
export const DEMO_POSTS_PATH = '/campaigns/:campaignId/demo/posts';

export const toDemoMyInfluencersPath = campaignUID =>
  `/campaigns/${campaignUID}/demo/my_influencers`;

export const toDemoMyInfluencersProductShipmentsPath = campaignUID =>
  `/campaigns/${campaignUID}/demo/my_influencers/product_shipments`;

export const toDemoMyInfluencersPostApprovalPath = campaignUID =>
  `/campaigns/${campaignUID}/demo/my_influencers/post_approval`;

export const ADMINV2_PATH = '/adminv2';

export const CAMPAIGN_EXAMPLE_DASHBOARD = '/exampleCampaigns/:campaignId';
export const toCampaignExample = campaignUID => `/exampleCampaigns/${campaignUID}/summary`;
