import React from 'react';
import { Avatar } from 'antd';
import { ReactComponent as IconVip } from 'images/icon-vip-profile.svg';

const InfluencerAvatar = ({ influencer, showWorkBefore }) => (
  <div>
    <a
      href={`https://www.instagram.com/${influencer.username}/`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="p-relative">
        <Avatar src={influencer.image} size={50} style={{ float: 'left' }} />
        {influencer.isVIP && (
          <IconVip style={{ position: 'absolute', top: '35px', left: '35px' }} />
        )}
        {showWorkBefore && (
          <div className="status-tag type-firme-iblack spacing-1px" style={{ color: '#000000' }}>
            <span>WORKED TOGETHER BEFORE</span>
          </div>
        )}
      </div>
      <div
        className="text-left"
        style={{ position: 'relative', float: 'left', marginLeft: '10px' }}
      >
        <div
          className="type-sbold size-12px text-black"
          style={{ paddingLeft: '3px', paddingTop: '8px' }}
        >
          @{influencer.username}
        </div>
        <div style={{ fontSize: '11px', color: '#5b6572', paddingLeft: '3px' }}>
          {influencer.parsedState}
        </div>
      </div>
    </a>
  </div>
);

export default InfluencerAvatar;
