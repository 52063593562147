import Particles from './Particles';

export const COLORS = ['#2ecc71', '#3498db', '#e67e22', '#e67e22', '#e74c3c'];

export const TOP_OFFSET = window.innerHeight;

export const LEFT_OFFSET = 250;

export const generateWholeNumber = (min, max) => min + Math.floor(Math.random() * (max - min));

export const generateRandomColor = () => COLORS[generateWholeNumber(0, COLORS.length)];

export default Particles;
