import { firebase, UserToken } from 'lib/Firebase';
import axios from 'axios';
import Routes from 'api/routes';

export const doSignInWithCustomToken = (token, onComplete = null) => {
  firebase.auth.signInWithCustomToken(token).then((data) => {
    onComplete(data);
  }).catch((e) => {
    onComplete(null, e);
  });
};

export const fetchAuthToken = async (uid, onComplete = null) => {
  const token = await UserToken();

  axios.get(Routes.authToken, {
    params: { uid },
    headers: token.headers,
  }).then((response) => {
    onComplete(response);
  });
};
