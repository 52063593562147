import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Popconfirm, Modal, Table, Button, Avatar, message } from 'antd';
import { inviteSubs, listenInvitations } from '../../../listeners/invites';
import { addInvite, removeInvite } from '../../../actions/invites';
import { firebase } from '../../../lib/Firebase';
import { crawleraInvite } from '../../../lib/Firebase/callables';
import './style.less';
import Container from '../../../components/Common/Container';

const columns = [
  {
    title: 'Handle',
    dataIndex: '',
    key: 'handle',
    className: 'influencer-details',
    render: val => {
      return (
        <>
          <a
            href={`https://instagram.com/${val.handle.slice(1, val.handle.length)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Avatar src={val.photo} />
            {val.handle}
          </a>
        </>
      );
    }
  },
  {
    title: 'Action',
    dataIndex: 'actions',
    align: 'right',
    key: 'actions',
    className: 'actions',
    render: ({ inviteUID, getPhoto, isPhoto, onApprove, onReject, handleCrawleraInvite }) => {
      return (
        <>
          {!isPhoto && (
            <Button type="primary" onClick={handleCrawleraInvite} loading={getPhoto[inviteUID]}>
              Get Photo
            </Button>
          )}
          <Button type="primary" onClick={onApprove}>
            Approve
          </Button>
          <Popconfirm
            placement="topRight"
            title="Are you sure you want to Reject this Influencer"
            onConfirm={onReject}
            okText="Confirm"
          >
            <Button>Reject</Button>
          </Popconfirm>
        </>
      );
    }
  }
];

const initialPagination = { page: 1, limit: 20 };
const Page = () => {
  const invites = useSelector(state => state.entities.invites);
  const [pagination, setPagination] = useState(initialPagination);
  const [getPhoto, setGetPhoto] = useState({});
  const [modal, setModal] = useState({ show: false, inviteUID: null, sending: false });
  const dispatch = useDispatch();

  const [lastInviteDoc, setLastInviteDoc] = useState(null);

  function retrieveInvitations(lastDoc) {
    const listener = listenInvitations(lastDoc, pagination.limit, invitations => {
      let invs = {};
      invitations.forEach(invitation => {
        invs = { ...invs, [invitation.id]: invitation.data() };
      });

      dispatch(addInvite(invs));

      setLastInviteDoc(invitations[invitations.length - 1]);
    });

    inviteSubs.addListener(listener);
  }

  useEffect(() => {
    retrieveInvitations(null);
    return () => inviteSubs.unsubscribe();
  }, []);

  async function handleApprove(type) {
    setModal({ ...modal, show: false });
    try {
      await firebase.firestore
        .collection('invites')
        .doc(modal.inviteUID)
        .update({
          type,
          status: 'approved',
          approvedAt: new Date().getTime() / 1000
        });

      await firebase.sendTrendApprovalEmail(modal.inviteUID);

      dispatch(removeInvite(modal.inviteUID));
      message.success('Approve Message Sent!');
    } catch (e) {
      message.error('Something went wrong');
    }
  }

  function handleReject(inviteUID) {
    firebase.firestore
      .collection('invites')
      .doc(inviteUID)
      .update({
        status: 'rejected',
        rejectedAt: new Date().getTime() / 1000
      });

    dispatch(removeInvite(inviteUID));
  }

  async function handleCrawleraInvite(inviteUID) {
    setGetPhoto({ ...getPhoto, [inviteUID]: true });

    await crawleraInvite({ inviteUID }, success => {
      if (!success) {
        message.warning('Failed on getting photo from Instagram, please try again!');
      }

      setGetPhoto({ ...getPhoto, [inviteUID]: false });
    });
  }

  function loadMore() {
    setPagination({
      ...pagination,
      page: pagination.page + 1
    });

    retrieveInvitations(lastInviteDoc);
  }

  function showConfirmationPopUp(id) {
    setModal({ ...modal, show: true, inviteUID: id });
  }

  const showLimit = pagination.limit * pagination.page - pagination.page;
  const source = invites.all
    .map(id => {
      const invite = invites.byId[id];
      let handle = invite.instagramHandle;
      if (handle.charAt(0) !== '@') {
        handle = `@${handle}`;
      }
      return {
        key: id,
        email: invite.email,
        handle,
        photo: invite.photo,
        actions: {
          inviteUID: id,
          getPhoto,
          isPhoto: !!invite.photo,
          handleCrawleraInvite: () => handleCrawleraInvite(id),
          onApprove: () => showConfirmationPopUp(id),
          onReject: () => handleReject(id)
        }
      };
    })
    .slice(0, showLimit);

  return (
    <Container width={940} id="admin-influencer-approval">
      <Table dataSource={source} columns={columns} pagination={false} showHeader={false} />
      <Modal visible={modal.show} closable={false} footer={false} className="admin-approve-modal">
        <p className="text-center">Are you sure you want to approve this influencer?</p>
        <Row type="flex" justify="center">
          <Col className="button-options">
            <Button type="primary" block onClick={() => handleApprove('standard')}>
              Confirm Standard Approval
            </Button>
            <Button type="primary" block onClick={() => handleApprove('vip')}>
              Confirm VIP Approval
            </Button>
            <Button block onClick={() => setModal({ ...modal, show: false })}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal>
      <div className="text-center loadmore">
        {invites.all.length > showLimit && (
          <Button className="load-more" onClick={loadMore}>
            LOAD MORE
          </Button>
        )}
      </div>
    </Container>
  );
};

export default Page;
