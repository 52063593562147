import React from 'react';
import { Row, Col } from 'antd';

const instagramLink = code => `https://instagram.com/p/${code}`;

const RecentPost = ({ images = {} }) => {
  if (Object.keys(images).length < 4) {
    return <div />;
  }

  const img = Object.keys(images).map(id => ({ id, path: images[id] }));

  return (
    <div className="influencer-posts">
      <label className="influencer-info-label">RECENT POSTS</label>
      <div className="influencer-posts-images">
        <Row gutter={4}>
          <Col span={12}>
            <a href={instagramLink(img[1].id)} target="_blank" rel="noopener noreferrer">
              <img src={img[1].path} alt="recent-post-7" />
            </a>
          </Col>
          <Col span={12}>
            <a href={instagramLink(img[2].id)} target="_blank" rel="noopener noreferrer">
              <img src={img[2].path} alt="recent-post-8" />
            </a>
          </Col>
        </Row>
        <Row gutter={4}>
          <Col span={12}>
            <a href={instagramLink(img[3].id)} target="_blank" rel="noopener noreferrer">
              <img src={img[3].path} alt="recent-post-9" />
            </a>
          </Col>
          <Col span={12}>
            <a href={instagramLink(img[4].id)} target="_blank" rel="noopener noreferrer">
              <img src={img[4].path} alt="recent-post-10" />
            </a>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default RecentPost;
