import { combineReducers } from 'redux';
import activeCampaigns from './activeCampaigns';
import influencers from './influencers';
import yotpoMetrics from './yotpoMetrics';

export default combineReducers({
  activeCampaigns,
  influencers,
  yotpoMetrics
});
