import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import PostCard from 'components/CampaignDashboard/Posts/PostCard';

class Posts extends Component {
  list() {
    const { posts, postIds, influencers } = this.props;

    return postIds.map(postUID => {
      const { influencerUID, details: post } = posts[postUID];
      const influencer = influencers[influencerUID] && influencers[influencerUID].details;
      return (
        <Col key={postUID} span={8} styles={{ marginBottom: '10px' }}>
          <div>
            <PostCard
              influencerUID={influencerUID}
              postUID={postUID}
              post={post}
              influencer={influencer || {}}
            />
          </div>
        </Col>
      );
    });
  }

  render() {
    return <Row>{this.list()}</Row>;
  }
}

Posts.defaultProps = {};

Posts.propTypes = {};

const mapStateToProps = state => ({
  posts: state.entities.posts.byId,
  postIds: state.ui.storeAnalytics,
  influencers: state.entities.influencers.byId
});
export default connect(mapStateToProps)(Posts);
