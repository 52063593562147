import { firebase } from '../lib/Firebase';

export const listenOwnCampaigns = (brandUID, callback) => {
  firebase
    .activeProducts()
    .orderByChild('brandUID')
    .equalTo(brandUID)
    .on('value', snapshot => {
      if (snapshot.exists()) {
        callback('active', snapshot.val());
      }
    });

  firebase
    .completedProducts()
    .orderByChild('brandUID')
    .equalTo(brandUID)
    .on('value', snapshot => {
      if (snapshot.exists()) {
        callback('completed', snapshot.val());
      }
    });
};

export const removeListenerOwnCampaign = brandUID => {
  firebase
    .activeProducts()
    .orderByChild('brandUID')
    .equalTo(brandUID)
    .off();
  firebase
    .completedProduct()
    .orderByChild('brandUID')
    .equalTo(brandUID)
    .off();
};

export const listenBrandPosts = (brandUID, callback) => {
  return firebase.firestore
    .collection('posts')
    .where('brandUID', '==', brandUID)
    .onSnapshot(snap => {
      let posts = {};

      snap.docs.forEach(doc => {
        posts = { ...posts, [doc.id]: doc.data() };
      });

      callback(posts);
    });
};

export const listenPendingReviews = (brandUID, callback) => {
  return firebase
    .reviews()
    .where('brandUID', '==', brandUID)
    .where('status', '==', 'pending')
    .onSnapshot(snapshot => {
      snapshot.docChanges().forEach(change => {
        callback(change.type, { [change.doc.id]: change.doc.data() });
      });
    });
};

export const listenTrendPendingReviews = (brandUID, callback) => {
  return firebase
    .trendReviews()
    .where('brandUID', '==', brandUID)
    .onSnapshot(snapshot => {
      let status = true;
      snapshot.docChanges().forEach(change => {
        const statuses = ['pending', 'approved'];
        if (statuses.includes(change.doc.data().status)) {
          status = false;
        }
      });
      callback(status);
    });
};

export const listenAllBrands = ({ onUpdate }) => {
  firebase.brands().on('child_changed', brandSnap => {
    if (onUpdate) {
      onUpdate(brandSnap.key, brandSnap.val());
    }
  });

  return firebase.brands();
};
