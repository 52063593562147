import React from 'react';
import { connect } from 'react-redux';
import { PostCard } from '../../Post';

const PreviousWork = ({ campaignIds, postsByInfluencer = [] }) => {
  if (!campaignIds) {
    return <div />;
  }

  if (postsByInfluencer.length <= 0) {
    return <div />;
  }

  const postCard = postsByInfluencer.map(postUID => {
    return <PostCard key={postUID} postUID={postUID} />;
  });

  return (
    <div className="influencer-info-work">
      <label className="influencer-info-label">PREVIOUS WORK TOGETHER</label>
      <div className="influencer-info-posts">{postCard}</div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  campaignIds: state.ui.applicationsAction.campaignInfluencerIds[ownProps.influencerUID],
  postsByInfluencer: state.entities.posts.byInfluencerId[ownProps.influencerUID]
});

export default connect(mapStateToProps)(PreviousWork);
