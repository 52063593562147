/* eslint no-console: ["error", { allow: ["log"] }] */
import React, { Component } from 'react';
import { Button, Modal, Form, Input, notification, message } from 'antd';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { UserToken } from 'lib/Firebase';
import Routes from 'api/routes';
import { connect } from 'react-redux';
import { EmailAnalytics } from 'lib/Email';
import { ReactComponent as IconEmail } from 'images/icon-email.svg';
import { ReactComponent as IconDownload } from 'images/icon-download.svg';
import { ReactComponent as IconBell } from 'images/bell.svg';
import { ReactComponent as IconNotificationBell } from 'images/notification-bell.svg';
import WithBrand from 'components/Helpers/WithBrand';
import { firebase } from '../../../lib/Firebase';

const hasErrors = fieldsError => Object.keys(fieldsError).some(field => fieldsError[field]);

const defaultState = {
  visible: false,
  submittedForm: false,
  downloadStatus: false,
  error: {}
};

class AnalyticsButton extends Component {
  _isMounted = false;

  state = {
    ...defaultState,
    tokenId: null
  };

  componentDidMount() {
    this._isMounted = true;

    UserToken().then(({ tokenId }) => {
      if (this._isMounted) {
        this.setState(() => ({ tokenId }));
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showModal = () => {
    const {
      form: { resetFields },
      brand
    } = this.props;

    if (brand && brand.details.isFreeTrial) {
      return;
    }

    resetFields();

    this.setState(() => ({
      visible: true
    }));
  };

  onNotif = () => {
    const { brand } = this.props;

    if (brand && brand.brandUID) {
      firebase
        .notifications()
        .doc(brand.brandUID)
        .set(
          {
            newApplication: true,
            newMessage: true,
            newPost: true
          },
          { merge: true }
        );
    }

    if ('Notification' in window && Notification.permission === 'granted') {
      notification.success({
        message: 'Notifications are on!',
        description: "We'll alert you at key points during your campaign",
        duration: 3,
        className: 'ant-notification trend-notifcation notif-on',
        icon: <IconNotificationBell />
      });
    }
  };

  submitEmailAnalytics = event => {
    event.preventDefault();

    const { form, match } = this.props;
    const { email } = form.getFieldsValue();

    this.setState(() => ({
      submittedForm: true
    }));

    try {
      this.reProcessGettingPostData().then(() => {
        EmailAnalytics(match.params.campaignId, email, isSuccess => {
          this.setState(() => defaultState);

          if (isSuccess) {
            notification.success({
              message: 'Analytics',
              description: 'Email Analyitics was successfully sent.',
              duration: 2
            });
          } else {
            notification.error({
              message: 'Analytics',
              description: 'Somethings wrong on sending email analytics.',
              duration: 2
            });
          }
        });
      });
    } catch (e) {
      console.log(e);
    }
  };

  async reProcessGettingPostData() {
    const { campaigns, match } = this.props;
    const campaignUID = match.params.campaignId;
    const { brandUID } = campaigns[campaignUID];

    return firebase.branchUserJobPostStatsUpdate({ uid: campaignUID, brandUID });
  }

  redirectToReports() {
    try {
      this.setState(() => ({
        downloadStatus: true
      }));
      this.reProcessGettingPostData()
        .then(() => {
          const { match } = this.props;
          const { tokenId } = this.state;
          this.setState(() => defaultState);

          window.open(
            `${Routes.postReportCsv}?campaignUID=${match.params.campaignId}&tokenId=${tokenId}`,
            '_blank'
          );
        })
        .catch(e => {
          console.log(e);
          this.setState(() => defaultState);
          message.error('Something went wrong!.');
        });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { form, brand, notification: notif } = this.props;

    const { newApplication, newMessage, newPost } = notif(brand && brand.brandUID);
    const isAllNotifOn = newApplication && newMessage && newPost;

    const { visible, submittedForm, downloadStatus } = this.state;

    const { getFieldDecorator, getFieldsError } = form;

    const { email = '' } = form.getFieldsValue();

    const IconHandler = isAllNotifOn ? IconEmail : IconBell;

    return (
      <div>
        <div style={{ display: 'flex' }}>
          <Button
            className="trend-btn ant-btn secondary type-firme-iblack mt-2 mr-1"
            onClick={isAllNotifOn ? this.showModal : this.onNotif}
            style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}
          >
            <IconHandler className="mr-2" />
            <span className="hidden-mobile leading-1">
              <span className="d-block">{isAllNotifOn ? 'EMAIL' : 'TURN ON BROWSER'}</span>
              {isAllNotifOn ? 'ANALYTICS' : 'NOTIFICATIONS'}
            </span>
          </Button>

          <Button
            className="trend-btn ant-btn secondary type-firme-iblack mt-2"
            onClick={() => {
              if (brand && !brand.details.isFreeTrial) {
                this.redirectToReports();
              }
            }}
            disabled={downloadStatus}
          >
            <IconDownload className="mr-2 f-left" />
            <span className="hidden-mobile leading-1">
              <span className="d-block">DOWNLOAD</span>
              ANALYTICS
            </span>
          </Button>
          {downloadStatus && <Button loading style={{ border: 'none' }} />}
        </div>
        <Modal
          visible={visible}
          closable={false}
          onCancel={() => {
            if (!submittedForm) {
              this.setState(() => defaultState);
            }
          }}
          className="trend-modal"
          width={300}
          footer={null}
        >
          <Form layout="vertical" onSubmit={this.submitEmailAnalytics}>
            <Form.Item>
              {getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    message: 'Email is required!'
                  },
                  {
                    type: 'email',
                    message: 'Invalid Email!'
                  }
                ]
              })(
                <Input
                  type="text"
                  size="large"
                  name="email"
                  className="trend-input"
                  placeholder="Email Address"
                />
              )}
            </Form.Item>
            <Button
              block
              loading={submittedForm}
              size="large"
              type="submit"
              className="trend-btn huge-btn"
              htmlType="submit"
              disabled={submittedForm || email === '' || hasErrors(getFieldsError())}
            >
              {submittedForm ? 'Sending Analytics' : 'Send Analytics'}
            </Button>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  campaigns: state.entities.campaigns.byId,
  notification: brandUID => state.entities.notifications.byId[brandUID] || {}
});

export default compose(
  WithBrand,
  Form.create({ name: 'email_analytics' }),
  withRouter,
  connect(mapStateToProps)
)(AnalyticsButton);
