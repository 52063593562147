import React from 'react';
import { Menu, Badge } from 'antd';
import { Link } from 'react-router-dom';

const { Item: MenuItem } = Menu;

const TabLinks = ({
  brandDetails,
  defaultSelectedKeys,
  matchUrl,
  postToApproveCount,
  needsShippingCount,
  appliedInfluencerCount,
  newMessagesCount,
}) => (
  <Menu
    mode="horizontal"
    defaultSelectedKeys={defaultSelectedKeys}
    selectedKeys={defaultSelectedKeys}
    className="trend-menu-horizontal"
  >
    <MenuItem key="summary">
      <Link to={`${matchUrl}/summary`}>
        Summary
      </Link>
    </MenuItem>
    <MenuItem key="applications">
      <Link to={`${matchUrl}/applications`}>
        <Badge count={appliedInfluencerCount} offset={[10, 0]} style={{ backgroundColor: '#ffb917' }} />
        Applications
      </Link>
    </MenuItem>
    <MenuItem key="my_influencers">
      {
        brandDetails.isFreeTrial ? (
          <Link to={`${matchUrl}/demo/my_influencers`}>
            <Badge count={3} offset={[10, 0]} style={{ backgroundColor: '#ffb917' }} />
            Influencers
          </Link>
        ) : (
          <Link to={`${matchUrl}/my_influencers`}>
            <Badge count={(postToApproveCount + needsShippingCount)} offset={[10, 0]} style={{ backgroundColor: '#ffb917' }} />
            Influencers
          </Link>
        )
      }
    </MenuItem>
    <MenuItem key="messages">
      {
        brandDetails.isFreeTrial ? (
          <Link to={`${matchUrl}/demo/messages`}>
            <Badge count={2} offset={[10, 0]} style={{ backgroundColor: '#ffb917' }} />
            Messages
          </Link>
        ) : (
          <Link to={`${matchUrl}/messages`}>
            <Badge count={newMessagesCount} offset={[10, 0]} style={{ backgroundColor: '#ffb917' }} />
            Messages
          </Link>
        )
      }
    </MenuItem>
    <MenuItem key="posts">
      {
        brandDetails.isFreeTrial ? (
          <Link to={`${matchUrl}/demo/posts`}>
            Posts & Analytics
          </Link>
        ) : (
          <Link to={`${matchUrl}/posts`}>
            Posts & Analytics
          </Link>
        )
      }
    </MenuItem>
  </Menu>
);

export default TabLinks;
