import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'antd';
import { totalPostSummary } from 'selectors/postsAnalytics';

function Summary({ totalPostSummary }) {
  return (
    <Row>
      <Col span={4}>
        <div style={{ fontFamily: 'truenoextrabold', fontSize: '17px', fontStyle: 'italic', color: '#000', lineHeight: '1.4'}}>
          {totalPostSummary.posts.toLocaleString()}
        </div>
        <div style={{ fontSize: '8px', color: '#5b6572', letterSpacing: '0.5px' }}>LIVE POSTS</div>
      </Col>
      <Col span={4}>
        <div style={{ fontFamily: 'truenoextrabold', fontSize: '17px', fontStyle: 'italic', color: '#000', lineHeight: '1.4'}}>
          {totalPostSummary.likes.toLocaleString()}
        </div>
        <div style={{ fontSize: '8px', color: '#5b6572', letterSpacing: '0.5px' }}>LIKES</div>
      </Col>
      <Col span={4}>
        <div style={{ fontFamily: 'truenoextrabold', fontSize: '17px', fontStyle: 'italic', color: '#000', lineHeight: '1.4'}}>
          {totalPostSummary.comments.toLocaleString()}
        </div>
        <div style={{ fontSize: '8px', color: '#5b6572', letterSpacing: '0.5px' }}>COMMENTS</div>
      </Col>
      <Col span={4}>
        <div style={{ fontFamily: 'truenoextrabold', fontSize: '17px', fontStyle: 'italic', color: '#000', lineHeight: '1.4'}}>
          {totalPostSummary.impressions.toLocaleString()}
        </div>
        <div style={{ fontSize: '8px', color: '#5b6572', letterSpacing: '0.5px' }}>IMPRESSIONS</div>
      </Col>
    </Row>
  );
}

Summary.defaultProps = {};

Summary.propTypes = {};

const mapStateToProps = (state) => ({
  totalPostSummary: totalPostSummary(state.entities.posts.byId, state.ui.storeAnalytics)
})

export default connect(mapStateToProps)(Summary);
