import {
  RECEIVE_CHAT_MESSAGES,
  CHAT_MESSAGE_FETCH_REQUEST,
  CHAT_MESSAGE_RECEIVE_REQUEST
} from 'reducers/entities/chat_messages';
import { firebase } from '../lib/Firebase';

export const fetchRequest = () => ({ type: CHAT_MESSAGE_FETCH_REQUEST });

export const receiveRequest = () => ({ type: CHAT_MESSAGE_RECEIVE_REQUEST });

/* eslint-disable */
export const receiveChatMessages = chat_messages => ({
  type: RECEIVE_CHAT_MESSAGES,
  chat_messages
});
/* eslint-enable */

export const fetchChatMessages = (messageID, brandID) => dispatch => {
  dispatch(fetchRequest());
  firebase.message(messageID).on(
    'value',
    snapshot => {
      if (!snapshot.val()) {
        dispatch(receiveRequest());
        return;
      }
      snapshot.ref.child(`users/${brandID}`).update({ hasUnread: false });

      dispatch(receiveChatMessages(snapshot.val()));
    },
    e => {
      console.log('Error on fetching messages: ', e);
      dispatch(receiveRequest());
    }
  );
};

export const sendChatMessage = (messageId, influencerUID, data) => async (dispatch, getState) => {
  dispatch(fetchRequest());
  const { text, sender } = data;
  const state = getState();
  const brand = state.entities.brands.byId[sender].details;
  await firebase.sendPushNotification(influencerUID, `${brand.name}: ${text}`);
  firebase.chatMessages(messageId).push(data);
  await firebase
    .message(messageId)
    .child(`users/${influencerUID}`)
    .update({ hasUnread: true });
  dispatch(receiveRequest());
};
