import React, { Component } from 'react';
import { Layout, Button } from 'antd';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import 'styles/base/antd-custom.less';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import trendLogo from 'images/trend@2x.png';
import { withFirebase } from 'lib/Firebase';
import { DATE_TIMEZONE_FORMAT } from 'constants/common';
import moment from 'moment'

const { Content } = Layout;
const INITIAL_STATE = {
  email: '',
  passwordOne: '',
  passwordTwo: '',
  type: 'brand',
  plan: '',
  error: null,
  formErrors: { passwordOne: '', passwordTwo: '', email: '' },
};

const flStyle = {
  marginTop: '2em',
  fontSize: '9px',
  letterSpacing: '0.2em',
  textTransform: 'uppercase',
  color: '#fff',
};

const TrialSignUpPage = () => (
  <div className="bg-black">
    <div style={{ textAlign: 'center', padding: '4em 0' }}>
      <img
        alt="Trend"
        src={trendLogo}
        width="162"
        height="31"
        style={{ display: 'inline-block' }}
      />
    </div>
    <h1 style={{ textAlign: 'center', color: '#fff' }}>Sign Up</h1>
    <TrialSignUpForm />
  </div>
);

class TrialSignUpFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    const {
      firebase,
      history,
      match,
    } = this.props;

    const { hash } = match.params;

    firebase.trials().orderByChild('hash').equalTo(hash).once('value', (snapshot) => {
      if (!snapshot.val()) {
        history.push('/login');
        return;
      }

      const [trialId, item] = Object.entries(snapshot.val())[0];
      this.setState({
        ...item,
        trialId,
      });
    });
  }

  onSubmit = (event) => {
    const {
      email, passwordOne, passwordTwo, trialId,
    } = this.state;

    const initBrandData = { credits: 0,
      standardCredits: 0,
      isFreeTrial: true,
      isAdmin: false,
    };

    let { error } = this.state;

    if (passwordOne !== passwordTwo) {
      error = { message: 'Confirm password did not match.' };
      this.setState({ error });
      return;
    }

    let userId;
    const { firebase, history } = this.props;
    let user = {};
    firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then((authUser) => {
        user = authUser.user;
        userId = authUser.user.uid;
        initBrandData.uid = userId;
        initBrandData.email = authUser.user.email;
        firebase.brandInfo(userId).set(initBrandData);
      })
      .then(() => {
        return firebase.trial(trialId).update({
          email: user.email,
          brandUID: userId,
          registeredAt: moment().format(DATE_TIMEZONE_FORMAT),
        })
      })
      .then(() => {
        history.push(`/trial/${this.props.match.params.hash}/company`);
      })
      .catch((err) => {
        this.setState({ error: err });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    const { target } = event;
    this.setState({ [target.name]: target.value });
  };

  render() {
    const {
      email, passwordOne, passwordTwo, error,
    } = this.state;

    return (
      <div>
        <Layout>
          <Content style={{ padding: 24, background: '#000' }}>
            <Row type="flex" justify="space-around" align="middle">
              <Col lg={6} md={10} sm={20} xs={24}>
                <Form layout="vertical" onSubmit={this.onSubmit}>
                  <Form.Item>
                    <label className="trend-label">Email</label>
                    <Input
                      type="text"
                      size="large"
                      onPressEnter={this.onSubmit}
                      name="email"
                      disabled={true}
                      value={email}
                      onChange={this.onChange}
                      className="trend-input"
                    />
                  </Form.Item>
                  <Form.Item>
                    <label className="trend-label">Password</label>
                    <Input
                      type="password"
                      size="large"
                      onPressEnter={this.onSubmit}
                      name="passwordOne"
                      value={passwordOne}
                      onChange={this.onChange}
                      className="trend-input"
                    />
                  </Form.Item>
                  <Form.Item>
                    <label className="trend-label">Confirm Password</label>
                    <Input
                      type="password"
                      size="large"
                      onPressEnter={this.onSubmit}
                      name="passwordTwo"
                      value={passwordTwo}
                      onChange={this.onChange}
                      className="trend-input"
                    />
                  </Form.Item>
                  <Button
                    block
                    size="large"
                    type="submit"
                    className="trend-btn"
                    onClick={this.onSubmit}
                  >
                    Register
                  </Button>
                  {error && <p className="text-white mt-3" align="middle">{error.message}</p>}
                  <p style={flStyle} align="middle">
                    Have an account? &nbsp;
                    <Link to="/login">Login</Link>
                  </p>
                </Form>
              </Col>
            </Row>
          </Content>
        </Layout>
      </div>
    );
  }
}

const TrialSignUpForm = compose(
  withRouter,
  withFirebase,
)(TrialSignUpFormBase);

export default TrialSignUpPage;

export { TrialSignUpForm };
