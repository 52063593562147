import React from 'react';
import IconCustomPlan from 'images/Subscription/star.png';
import { Row, Radio, Col } from 'antd';

const CustomPlanRadio = () => (
  <Radio
    value="custom"
    key="custom"
    style={{ display: 'block' }}
    className="plan-radio subscription-radio custom-radio"
  >
    <Row type="flex" align="middle">
      <Col span={3} style={{ textAlign: 'center' }}>
        <img src={IconCustomPlan} alt="custom plan" style={{ width: '40px' }} />
      </Col>
      <Col span={18}>
        <h4 className="text-white size-15px type-bold mt-1" style={{ marginBottom: '4px' }}>
          Custom Plan
        </h4>
        <p className="type-regular text-gray size-11px pt-1">
          This plan is modified for your specific use case
        </p>
      </Col>
      <Col span={3} className="pr-1 text-right" style={{ paddingTop: '18px', lineHeight: '1' }} />
    </Row>
  </Radio>
);

export default CustomPlanRadio;
