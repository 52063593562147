import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Table, Row, Col, Button, Icon, notification, Modal } from 'antd';
import validUrl from 'valid-url';
import CustomEmpty from 'components/Common/CustomEmpty';
import { withFirebase } from 'lib/Firebase';
import { POST_DETAIL_STATUS } from 'constants/posts';
import filterPosts from 'selectors/postApproval';
import postDetailStatusChange from 'actions/ui/postApproval';
import MinLayout from 'components/Common/MinLayout';
import { ReactComponent as IconMessages } from 'images/icon-messages.svg';
import CurrencyFormat from 'react-currency-format';
import { ReactComponent as IconDownload } from 'images/icon-download.svg';
import PostAvatar from 'components/Common/PostAvatar';
import TextArea from 'antd/lib/input/TextArea';
import { DATE_TIMEZONE_FORMAT } from 'constants/common';
import { postResourceDownloader } from '../../Post/PostDownloader';
import { InfluencerDrawer, InfluencerAvatar } from '../../Influencers';
import { sendChatMessage } from '../../../actions/chat_messages';

import debounce from '../../Helpers/useDebounce';

const postAction = (
  { post, isFetchingMessageUID, clickMsgPostUID, showModal, onClickToMessageThread },
  { influencer, post: postMain, postUID }
) => {
  const loadingMsg = isFetchingMessageUID && clickMsgPostUID === post.postUID;
  const messageAction = (
    <Button
      onClick={() => onClickToMessageThread(post.influencerUID, post.postUID)}
      shape="circle"
      style={{
        background: '#ffb917',
        border: '0',
        marginRight: '5px',
        width: '35px',
        height: '35px'
      }}
    >
      {loadingMsg && (
        <Icon
          type="loading"
          style={{
            color: '#ffffff',
            width: '15px',
            height: '15px',
            position: 'relative'
          }}
          spin
        />
      )}
      {!loadingMsg && (
        <IconMessages
          style={{
            position: 'relative',
            top: '2px',
            width: '15px',
            height: '14px'
          }}
        />
      )}
    </Button>
  );

  const approvedAction = (
    <div>
      {messageAction}
      <Button
        onClick={async () => {
          try {
            console.log(postMain, postUID, influencer.username);
            await postResourceDownloader(postMain, postUID, influencer.username);
          } catch (e) {
            console.log(e);
            notification.warning({
              message: 'Invalid Resource',
              description: alert,
              duration: 2
            });
          }
        }}
        style={{
          width: '35px',
          height: '35px',
          backgroundColor: '#fcfcfc',
          border: 'solid 1px #e9e9e9',
          objectFit: 'contain'
        }}
        shape="circle"
      >
        <IconDownload
          style={{
            position: 'relative',
            top: '2px',
            width: '15px',
            height: '15px'
          }}
        />
      </Button>
    </div>
  );

  const pendingAction = (
    <div>
      <Button
        onClick={() =>
          showModal(
            POST_DETAIL_STATUS.approved,
            post.influencerUID,
            post.postUID,
            post.code,
            post.link,
            post.type
          )
        }
        style={{ height: '33px', marginRight: '9px' }}
        className="trend-btn ant-btn type-firme-italic size-10px"
      >
        APPROVE
      </Button>
      <Button
        onClick={() =>
          showModal(POST_DETAIL_STATUS.rejected, post.influencerUID, post.postUID, post.link)
        }
        className="trend-btn ant-btn secondary type-firme-italic bg-white size-10px"
        style={{ backgroundColor: '#fcfcfc', height: '33px' }}
      >
        REJECT
      </Button>
    </div>
  );

  const isAwaiting = ![POST_DETAIL_STATUS.approved, POST_DETAIL_STATUS.submitted].includes(
    post.status
  );

  return (
    <div className="cell-action">
      {post.status === POST_DETAIL_STATUS.approved && approvedAction}
      {post.status === POST_DETAIL_STATUS.submitted && pendingAction}
      {isAwaiting && messageAction}
    </div>
  );
};

const ThousandSeparator = (num, { post }) => {
  if (post.type !== 'story' && (num || num === 0)) {
    return <CurrencyFormat value={num} displayType="text" thousandSeparator />;
  }

  return '--';
};

const columns = [
  {
    title: 'INFLUENCER',
    dataIndex: 'influencer',
    key: 'influencer',
    className: 'influencer-width',
    render: influencer => <InfluencerAvatar influencer={influencer} />
  },
  {
    title: 'POST',
    dataIndex: 'post',
    key: 'post',
    className: 'post-width pl-0 post',
    render: post => {
      let { details } = post;

      if (!details) {
        details = post;
      }
      return <PostAvatar postDetails={details} />;
    }
  },
  {
    title: 'COMMENTS',
    dataIndex: 'comments',
    className: 'comments pl-0',
    key: 'comments',
    render: ThousandSeparator
  },
  {
    title: 'LIKES',
    dataIndex: 'likes',
    key: 'likes',
    className: 'likes pl-0',
    render: ThousandSeparator
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    align: 'right',
    className: 'pl-0 actions',
    render: postAction
  }
];

const defaultState = {
  status: undefined,
  influencerUID: undefined,
  postUID: undefined,
  visibleModal: false,
  submitModal: false,
  code: undefined
};

class PostApproval extends Component {
  constructor(props) {
    super(props);

    const { match } = this.props;

    this.state = {
      campaignUID: match.params.campaignId,
      influencerDetailsModal: {
        show: false,
        influencerUID: null
      },
      reasonForRejection: null,
      ...defaultState
    };
  }

  showModal = (status, influencerUID, postUID, code, link, type) => {
    if (status === POST_DETAIL_STATUS.approved && !validUrl.isUri(link) && type !== 'story') {
      notification.warning({
        message: 'Invalid Link',
        description: 'There seems to be an issue with this link. Try again later.',
        duration: 2
      });
    } else {
      this.setState(() => ({
        status,
        influencerUID,
        postUID,
        code,
        visibleModal: true
      }));
    }
  };

  changeStatus = () => {
    const { campaignUID, influencerUID, postUID, status, code, reasonForRejection } = this.state;

    const {
      postDetailStatusChange: statusChange,
      campaign,
      firebase,
      sendChatMessage: sendMessage
    } = this.props;

    const args = {
      campaignUID,
      campaign,
      influencerUID,
      postUID,
      postCode: code,
      status
    };

    // if reason for rejection is empty
    if (!reasonForRejection && status === 'rejected') {
      notification.error({ message: 'Please enter reason for rejection.' });
      return;
    }

    this.setState(() => ({ submitModal: true }));

    statusChange(args, async (isSuccess, batch, error = null) => {
      this.setState(() => ({ ...defaultState }));

      if (isSuccess) {
        notification.success({
          message: `${args.status.charAt(0).toUpperCase()}${args.status.slice(1)}`,
          description: `Post successfully ${args.status}!`,
          duration: 2
        });
        if (status === 'rejected') {
          const influencerMessages = (await firebase
            .influencer(influencerUID)
            .child('messages')
            .once('value')).val();

          const { brandUID } = campaign;
          if (Object.entries(influencerMessages).length) {
            Object.entries(influencerMessages).forEach(async ([id, val]) => {
              if (val) {
                const messagesLog = (await firebase
                  .message(id)
                  .child(`details`)
                  .once('value')).val();
                if (messagesLog.partnershipUID === campaignUID) {
                  const params = {
                    sender: brandUID,
                    text: `Your work for ${campaign.name} has been rejected. You may resubmit a new post at any time. \n\rReason: ${reasonForRejection}`,
                    timestamp: moment().format(DATE_TIMEZONE_FORMAT)
                  };
                  sendMessage(id, influencerUID, params);
                }
              }
            });
          }
          this.setState({ reasonForRejection: null });
        }
      } else {
        const defaultErrMsg = `Somethings wrong on ${args.status} post. Please contact us.`;

        notification.error({
          message: 'Error',
          description: String(error) || defaultErrMsg,
          duration: 4
        });
      }
    });
  };

  onClickToMessageThread = (influencerUID, postUID) => {
    this.setState(() => ({
      clickMsgPostUID: postUID
    }));

    const { onClickToMessageThread: toMessageThread } = this.props;

    toMessageThread(influencerUID);
  };

  handleShowInfluencerDetails = influencerUID => event => {
    const parentClass = event.target.closest('td').className;
    const tagname = event.target.tagName.toLowerCase();

    if (
      (tagname !== 'td' && event.target.closest('td').className.includes('actions')) ||
      parentClass.includes('post')
    ) {
      return;
    }

    this.setState(state => {
      return {
        ...state,
        influencerDetailsModal: {
          show: true,
          influencerUID
        }
      };
    });
  };

  handleHideInfluencerDetails = () => {
    this.setState(state => {
      return {
        ...state,
        influencerDetailsModal: {
          ...state.influencerDetailsModal,
          show: false
        }
      };
    });
  };

  handleInputReason = value => {
    this.setState({ reasonForRejection: value });
  };

  render() {
    const filterCampaignPosts = {
      awaiting: [],
      needsApproval: [],
      approved: []
    };

    const {
      filterPosts: postsFilter,
      influencers,
      isFetchingMessageUID,
      isFetching,
      isUpdating,
      campaignApprovePosts = [],
      posts: p
    } = this.props;

    const {
      clickMsgPostUID,
      visibleModal,
      submitModal,
      status,
      influencerDetailsModal
    } = this.state;

    Object.keys(postsFilter).forEach(postType => {
      const posts = postsFilter[postType];

      Object.keys(posts).forEach(postUID => {
        let post = posts[postUID];
        const influencer = influencers[post.influencerUID].details;

        // override reject, don't show images
        // and number of likes, posts.
        // treat as like pending
        if (post.status === POST_DETAIL_STATUS.rejected) {
          post = {
            ...post,
            standardResolutionImage: '',
            fullResImage: '',
            thumbnail: '',
            postUID,
            influencerUID: post.influencerUID,
            status: post.status
          };
        }

        filterCampaignPosts[postType] = [
          ...filterCampaignPosts[postType],
          {
            key: `${postUID}-${postType}`,
            influencer,
            influencerUID: post.influencerUID,
            post: { details: post },
            likes: post.likes,
            comments: post.comments,
            action: {
              post,
              clickMsgPostUID,
              isFetchingMessageUID,
              showModal: this.showModal,
              onClickToMessageThread: this.onClickToMessageThread
            }
          }
        ];
      });
    });

    const approvedPosts = campaignApprovePosts.map(postUID => {
      const post = p[postUID];
      const postDetails = {
        ...post,
        status: 'approved'
      };

      const influencer = influencers[post.influencerUID].details;

      return {
        key: postUID,
        influencer,
        postUID,
        influencerUID: post.influencerUID,
        post,
        likes: postDetails.likes,
        comments: postDetails.comments,
        action: {
          post: postDetails,
          clickMsgPostUID,
          isFetchingMessageUID,
          showModal: this.showModal,
          onClickToMessageThread: this.onClickToMessageThread
        }
      };
    });

    return (
      <div id="post-approval-content">
        <Row type="flex" justify="space-around">
          <MinLayout>
            {!filterCampaignPosts.needsApproval.length &&
              !filterCampaignPosts.awaiting.length &&
              !filterCampaignPosts.approved.length && (
                <CustomEmpty description="No Post Yet!" style={{ marginTop: '40px' }} />
              )}
            {filterCampaignPosts.needsApproval.length > 0 && (
              <div style={{ marginTop: '23px' }}>
                <div style={{ marginBottom: '15px' }}>
                  <div className="type-ebold size-13px text-black mb-2">
                    Needs Approval -&nbsp;
                    {filterCampaignPosts.needsApproval.length}
                  </div>
                  <div
                    style={{
                      fontSize: '10px',
                      color: '#000',
                      lineHeight: '1.8'
                    }}
                  >
                    Influencers work hard to create unique work for your brand. Make sure to
                    <br />
                    only reject a post if they&apos;ve missed something from the post requirements.
                  </div>
                </div>
                <Table
                  loading={isFetching || isUpdating}
                  columns={columns}
                  dataSource={filterCampaignPosts.needsApproval}
                  className="trend-table"
                  pagination={false}
                  onRow={record => ({
                    onClick: this.handleShowInfluencerDetails(record.influencerUID)
                  })}
                />
              </div>
            )}
            {filterCampaignPosts.awaiting.length > 0 && (
              <div style={{ marginTop: '40px' }}>
                <div style={{ marginBottom: '15px' }}>
                  <div className="type-ebold size-13px text-black mb-2">
                    Awaiting Post -&nbsp;
                    {filterCampaignPosts.awaiting.length}
                  </div>
                  <div
                    style={{
                      fontSize: '10px',
                      color: '#000',
                      lineHeight: '1.8'
                    }}
                  >
                    These influencers have yet to post. If you are looking for status on when
                    <br />
                    they will post, feel free to message them at any point.
                  </div>
                </div>
                <Table
                  loading={isFetching || isUpdating}
                  columns={columns}
                  dataSource={filterCampaignPosts.awaiting}
                  className="trend-table table-awaiting"
                  pagination={false}
                  onRow={record => ({
                    onClick: this.handleShowInfluencerDetails(record.influencerUID)
                  })}
                />
              </div>
            )}
            {approvedPosts.length > 0 && (
              <div style={{ marginTop: '40px', marginBottom: '44px' }}>
                <div className="type-ebold size-13px text-black mb-2">
                  Approved Posts -&nbsp;
                  {approvedPosts.length}
                </div>
                <Table
                  loading={isFetching || isUpdating}
                  columns={columns}
                  dataSource={approvedPosts}
                  className="trend-table table-approved"
                  pagination={false}
                  onRow={record => ({
                    onClick: this.handleShowInfluencerDetails(record.influencerUID)
                  })}
                />
              </div>
            )}
          </MinLayout>
        </Row>
        <Modal
          visible={visibleModal}
          width={423}
          maskCloseable
          closable={false}
          footer={null}
          className="trend-modal"
        >
          {status === POST_DETAIL_STATUS.approved && (
            <div className="label">
              Are you sure you want to
              <div style={{ color: '#FFB917' }}>approve this post?</div>
            </div>
          )}

          {status !== POST_DETAIL_STATUS.approved && (
            <div className="label" style={{ width: '280px', fontSize: '18px', lineHeight: '24px' }}>
              Are you sure you want to
              <i className="highlighted" style={{ color: '#cf453c' }}>
                {' '}
                Reject{' '}
              </i>{' '}
              this post?
            </div>
          )}

          <Row style={{ marginTop: '20px', padding: '0 10px' }} gutter={20} key="confirm-modal">
            {status !== POST_DETAIL_STATUS.approved && (
              <>
                <hr style={{ border: '1px solid #D6D6D6' }} />
                <TextArea
                  onChange={e => debounce(this.handleInputReason(e.target.value), 500)}
                  placeholder="Let the influencer know which piece of the post guidelines they failed to accomplish"
                  style={{
                    marginTop: '20px',
                    height: '130px',
                    background: '#FFFFFF',
                    border: '1px solid #D6D6D6 !important',
                    boxSizing: 'border-box',
                    borderRadius: '2px'
                  }}
                />
              </>
            )}
            <Col
              span={12}
              style={{ paddingLeft: '0px', paddingRight: '10px', marginTop: '22.5px' }}
            >
              <Button
                key="cancel"
                disabled={submitModal}
                onClick={() => this.setState(() => ({ visibleModal: false }))}
                className="trend-btn medium-btn secondary"
                block
                style={{ borderRadius: '4px' }}
              >
                Cancel
              </Button>
            </Col>
            <Col
              span={12}
              style={{ paddingRight: '0px', paddingLeft: '10px', marginTop: '22.5px' }}
            >
              <Button
                key="submit"
                loading={submitModal}
                onClick={this.changeStatus}
                className="trend-btn medium-btn"
                block
                style={{ borderRadius: '4px' }}
              >
                {status === POST_DETAIL_STATUS.approved ? 'Approve' : 'Confirm Rejection'}
              </Button>
            </Col>
          </Row>
        </Modal>
        <InfluencerDrawer {...influencerDetailsModal} onHide={this.handleHideInfluencerDetails} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  campaign: state.ui.campaignDashboard.currentCampaign,
  filterPosts: filterPosts(
    ownProps.match.params.campaignId,
    state.entities.influencers.byId,
    state.ui.influencerList
  ),
  influencers: state.entities.influencers.byId,
  influencerIds: state.ui.influencerList,
  isFetching: state.entities.influencers.isFetching,
  isFetchingMessageUID: state.entities.messages.isFetching,
  isPostRequest: state.entities.posts.isFetching,
  campaignApprovePosts: state.ui.campaignPosts[ownProps.match.params.campaignId],
  posts: state.entities.posts.byId,
  brands: state.entities.brands.byId
});

const mapDispatchToProps = dispatch => ({
  postDetailStatusChange: (args, callback) => dispatch(postDetailStatusChange(args, callback)),
  sendChatMessage: (messageId, influencerUID, data) =>
    dispatch(sendChatMessage(messageId, influencerUID, data)),
  dispatch
});

export default compose(
  withRouter,
  withFirebase,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PostApproval);
