import React, { useState, useEffect } from 'react';
import { Modal, Rate, Input, Button, Icon, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as StarIcon } from '../../images/star-grey.svg';
import { listenPendingReviews } from '../../listeners/brands';
import { addReviews } from '../../actions/reviews';
import { firebase } from '../../lib/Firebase';

const PendingReviewModal = ({
  brandUID,
  form: { getFieldDecorator, validateFieldsAndScroll, resetFields }
}) => {
  const dispatch = useDispatch();
  const [serving, setServingState] = useState(null);
  const [pendingUIDs, setPendingUIDsState] = useState([]);
  const [submitting, setSubmittingState] = useState(false);
  const review = useSelector(state => state.entities.reviews.byId[serving]) || {};

  useEffect(() => {
    const listener = listenPendingReviews(brandUID, async (action, reviews) => {
      if (action === 'added') {
        dispatch(addReviews(reviews));
        setPendingUIDsState(prevState => [...prevState, ...Object.keys(reviews)]);
        if (!serving) {
          setServingState(Object.keys(reviews)[0]);
        }
      }
    });

    return () => {
      listener();
    };
  }, []);

  function handleSubmit() {
    validateFieldsAndScroll(async (err, fields) => {
      setSubmittingState(true);
      if (!err) {
        const { attitudeProfessionalism, contentQuality, comments } = fields;
        let params = {
          attitudeProfessionalism,
          contentQuality
        };

        if (comments) {
          params = {
            ...params,
            comments
          };
        }

        await firebase.review(serving).update({ ...params, status: 'completed' });
        const newPendingUIDs = [...pendingUIDs.filter(x => x !== serving)];
        resetFields();
        setSubmittingState(false);
        setServingState(null);

        setTimeout(() => {
          setPendingUIDsState(newPendingUIDs);
          setServingState(newPendingUIDs[0]);
        }, 2000);
      } else {
        setSubmittingState(false);
      }
    });
  }

  return (
    <Modal wrapClassName="pending-review-modal" footer={null} visible={!!serving} closable={false}>
      <div className="review-photo">
        <img src={review.image} alt="nice" />
        <span className="instagram-logo" />
        <span className="emoji-heart" />
        <Button
          className="link"
          type="default"
          href={`https://instagram.com/p/${review.code}`}
          target="_blank"
        >
          VIEW POST
        </Button>
      </div>
      <div className="review-message">
        <p>How was your experience working with @{review.username}?</p>
      </div>

      <div className="review-form">
        <Form.Item className="review-input">
          <label className="review-input-label">Content Quality</label>
          <div className="review-input-control">
            {getFieldDecorator('contentQuality', {
              initialValue: 0,
              rules: [
                {
                  required: true,
                  message: 'Required'
                },
                {
                  type: 'number',
                  min: 1,
                  message: 'Required'
                }
              ]
            })(<Rate character={<Icon component={StarIcon} />} />)}
          </div>
        </Form.Item>
        <Form.Item className="review-input">
          <label className="review-input-label">Attitude & Professionalism</label>
          <div className="review-input-control">
            {getFieldDecorator('attitudeProfessionalism', {
              initialValue: 0,
              rules: [
                { required: true, message: 'Required' },
                {
                  type: 'number',
                  min: 1,
                  message: 'Required'
                }
              ]
            })(<Rate character={<Icon component={StarIcon} />} />)}
          </div>
        </Form.Item>
      </div>

      <div className="review-additional-comments">
        <Form.Item>
          {getFieldDecorator('comments')(
            <Input.TextArea
              autosize={false}
              placeholder="Any additional comments? (optional) This review will be made public."
            />
          )}
        </Form.Item>
      </div>
      <div className="review-footer">
        <Button loading={submitting} onClick={handleSubmit} type="primary">
          SUBMIT REVIEW
        </Button>
      </div>
    </Modal>
  );
};

export default Form.create({ name: 'review-modal' })(PendingReviewModal);
