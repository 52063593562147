import moment from 'moment';

const sortByTimestamp = messages => (mA, mB) => {
  const messageA = messages[mA];
  const messageB = messages[mB];

  return (
    moment(messageB.lastMessageTimestamp, moment.defaultFormat) -
    moment(messageA.lastMessageTimestamp, moment.defaultFormat)
  );
};

export default (messageUIDs = [], messages, userUID) => {
  let unread = [];
  let read = [];
  messageUIDs.forEach(uid => {
    const message = messages[uid];
    if (message.users[userUID].hasUnread) {
      unread = [...unread, uid];
    } else {
      read = [...read, uid];
    }
  });

  unread = unread.sort(sortByTimestamp(messages));
  read = read.sort(sortByTimestamp(messages));

  return [...unread, ...read];
};
