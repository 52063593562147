/* eslint no-console: ["error", { allow: ["log"] }] */
/* eslint newline-per-chained-call: ["error", { "ignoreChainWithDepth": 6 }] */
import { firebase } from 'lib/Firebase';
import { fetchRequest, receiveRequest } from 'actions/posts';
import { POST_STATUS, POST_DETAIL_STATUS } from 'constants/posts';
import { updateInfluencerPost } from 'actions/influencers';
import payPal from 'lib/Paypal';

export default (args, callback) => {
  const { influencerUID, campaignUID, campaign, postUID, status } = args;

  return dispatch => {
    dispatch(fetchRequest());

    const handleCallback = (isSuccess, response, error = null) => {
      dispatch(receiveRequest());
      callback(isSuccess, response, error);
    };

    const influencerCampaignRef = firebase.influencerProduct(influencerUID, campaignUID);
    const postDetailsRef = influencerCampaignRef.child(`posts/${args.postUID}/details`);

    postDetailsRef
      .update({
        status,
        isUnread: true
      })
      .then(() => {
        dispatch(updateInfluencerPost(influencerUID, campaignUID, null, postUID, { status }));

        // terminate right away if status not approved
        // don't need to proceed
        if (status !== POST_DETAIL_STATUS.approved) {
          handleCallback(true);
          return;
        }

        influencerCampaignRef.once('value', snapshot => {
          const campaignPosts = snapshot.val();
          let isCompleted = true;

          Object.keys(campaignPosts.posts).forEach(uid => {
            const post = campaignPosts.posts[uid];

            if (post.details.status !== POST_DETAIL_STATUS.approved) {
              isCompleted = false;
            }
          });

          if (!isCompleted) {
            handleCallback(true);
            return;
          }

          // don't update if status is already completed,
          // to also avoid double charge
          if (!campaign.isFree && campaignPosts.status !== POST_STATUS.completed) {
            const paypalCallback = ({ payoutBatchId }, error) => {
              if (error) {
                handleCallback(false, {}, error);
              } else {
                dispatch(updateInfluencerPost(influencerUID, campaignUID, POST_STATUS.completed));

                firebase.updateProductsTable({
                  influencerUID,
                  campaignUID,
                  status: POST_STATUS.completed
                });
                handleCallback(true, { payoutBatchId });
              }
            };

            payPal(influencerUID, campaignUID, paypalCallback);
          } else {
            handleCallback(true);
          }
        });
      })
      .catch(e => {
        handleCallback(false);
        console.log('Error on updating status postDetailStatusChange: ', e);
      });
  };
};
