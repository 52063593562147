import React, { useState } from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { firebase } from '../../lib/Firebase';

const RemoveTrialButton = ({ brandUID, fetchBrands }) => {
  const [submitting, setSubmittingState] = useState(false);
  async function handleClick() {
    setSubmittingState(true);
    const today = moment().unix();
    const products = await firebase
      .activeProducts()
      .orderByChild('brandUID')
      .equalTo(brandUID)
      .once('value');

    await firebase
      .brand(brandUID)
      .child('details')
      .update({
        status: 'approved',
        lastPurchaseDate: today
      });

    await firebase.firestore
      .collection('brands')
      .doc(brandUID)
      .update({
        status: 'approved',
        lastPurchaseDate: today
      });

    if (products.exists() && Object.keys(products.val()).length === 1) {
      const [firstProductUID] = Object.keys(products.val());

      await firebase.activeProduct(firstProductUID).update({
        isListed: true
      });
    }
    setTimeout(() => {
      if (fetchBrands) {
        fetchBrands();
      }
      setSubmittingState(false);
    }, 500);
  }

  return (
    <Button onClick={handleClick} loading={submitting}>
      Remove Trial
    </Button>
  );
};

export default RemoveTrialButton;
