/* eslint import/prefer-default-export: 0 */
import { firebase } from '../lib/Firebase';

class InviteListener {
  constructor() {
    this.subscriptions = [];
  }

  addListener(subs) {
    this.subscriptions.push(subs);
  }

  unsubscribe() {
    this.subscriptions.forEach(sub => {
      sub();
    });
  }
}

export const listenInvitations = (lastCampaignDoc = null, limit = 20, callback) => {
  let query = firebase.firestore
    .collection('invites')
    .where('status', '==', 'submitted')
    .orderBy('requestDate', 'desc');

  if (lastCampaignDoc) {
    query = query.startAt(lastCampaignDoc);
  }

  return query.limit(limit).onSnapshot(snap => {
    if (!snap.empty) {
      callback(snap.docs);
    }
  });
};

export const inviteSubs = new InviteListener();
