import React from 'react';
import moment from 'moment';
import { Row, Col, Avatar } from 'antd';
import { DATE_TIMEZONE_FORMAT } from 'constants/common';

function ChatItem({ item } = {}) {
  return item.own ? (
    <Row
      style={{ backgroundColor: '#ffffff', marginTop: 40, marginBottom: 40 }}
      type="flex"
      justify="space-around"
    >
      <Col align="center" span={4} />
      <Col
        span={14}
        offset={3}
        style={{
          border: '1px solid #eee',
          padding: 25,
          borderRadius: 10,
          backgroundColor: '#f8f9fb'
        }}
      >
        <p
          style={{
            marginBottom: '0',
            fontSize: '12px',
            color: '#000',
            lineHeight: '2.08',
            whiteSpace: 'pre-wrap'
          }}
        >
          {item.text}
        </p>
      </Col>
      <Col align="center" span={2}>
        <Avatar src={item.avatar} size={50} className="mt-1">
          User
        </Avatar>
        <p
          style={{
            color: '#5B6572',
            textAlign: 'center',
            lineHeight: '11px'
          }}
          className="mt-2 type-regular size-9px"
        >
          {moment(item.timestamp, DATE_TIMEZONE_FORMAT).fromNow()}
        </p>
      </Col>
    </Row>
  ) : (
    <Row
      style={{ backgroundColor: '#ffffff', marginTop: 40, marginBottom: 40 }}
      type="flex"
      justify="space-around"
    >
      <Col align="center" span={2}>
        <Avatar src={item.avatar} size={50}>
          Me
        </Avatar>
        <p
          style={{
            marginTop: '10',
            fontFamily: 'TruenoRegular',
            fontSize: '9px',
            color: '#5B6572',
            textAlign: 'center',
            letterSpacing: 0,
            lineHeight: '11px'
          }}
          className="mt-2"
        >
          {moment(item.timestamp, DATE_TIMEZONE_FORMAT).fromNow()}
        </p>
      </Col>
      <Col span={14} style={{ border: '1px solid #eee', padding: 25, borderRadius: 10 }}>
        <p
          style={{
            marginBottom: '0',
            fontSize: '12px',
            color: '#000',
            lineHeight: '2.08',
            whiteSpace: 'pre-wrap'
          }}
        >
          {item.text}
        </p>
      </Col>
      <Col align="center" span={4} />
    </Row>
  );
}

export default ChatItem;
