import React from 'react';
import { Row, Col } from 'antd';
import MacImage from '../../images/macimage.png';

const MobileView = () => {
  return (
    <div id="mobile-view-session-page">
      <Row type="flex" justify="center">
        <Col xs={20}>
          <div className="text-center image">
            <img src={MacImage} alt="mobile-view" style={{ marginTop: '43px' }} />
          </div>
          <h1>
            Log in at app.trend.io/login from your computer to start your campaign. Our platform is
            designed for desktop use only.
          </h1>
        </Col>
      </Row>
    </div>
  );
};

export default MobileView;
