import React from 'react';
import { Radio } from 'antd';
import { useHistory } from 'react-router-dom';

const Headers = () => {
  const history = useHistory();
  const path = history.location.pathname;

  function handleChange(event) {
    history.push(event.target.value);
  }

  return (
    <div className="partnership-header">
      <Radio.Group value={path} onChange={handleChange}>
        <Radio.Button value="/adminV2/payouts/processing">Processing</Radio.Button>
        <Radio.Button value="/adminV2/payouts/denied">Denied</Radio.Button>
        <Radio.Button value="/adminV2/payouts/paid">Paid</Radio.Button>
      </Radio.Group>
    </div>
  );
};

export default Headers;
