import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Intercom from 'react-intercom';
import { withAuthentication } from 'lib/Session';
import PasswordForget from '../components/PasswordForget';
import Subscription from '../components/OpenStore/Subscription';
import SessionLayout from '../components/Layouts/SessionLayout';
import MobileViewSignUp from '../components/Layouts/MobileViewSignUp';
import SiderLayout from '../components/Layouts/SiderLayout';
import CampaignDashboard from '../components/CampaignDashboard';
import ExampleDashboard from '../components/ExampleDashboard';
import StoreAnalytics from '../components/StoreAnalytics';
import BrandAccount from '../components/BrandAccount/BrandAccount';
import ChangeEmail from '../components/ChangeEmail';
import ChangePassword from '../components/PasswordForget/ChangePassword';
import * as routes from '../constants/routes';
import ManageCredits from '../components/ManageCredits/ManageCredits';
import AdminOptions from '../components/Admin';
import AdminFreeTrials from '../components/Admin/FreeTrials';
import AdminBrands from '../pages/Admin/Brands/main';
import AppRoute from './AppRoute';
import PrivateRoute from './PrivateRoute';
import OnboardingRoute from './OnboardingRoute';
import AdminRoute from './AdminRoute';
import TrialSignup from '../components/Signup/TrialSignup';
import TrialCompanyProfile from '../components/BrandAccount/TrialCompanyProfile';
import TrialCampaignSetup from '../components/BrandAccount/TrialCampaignSetup';
import NewFirstCampaign from '../pages/Campaigns/NewFirstCampaignPage';
import CampaignNewPage from '../pages/Campaigns/NewPage';
import CampaignEditPage from '../pages/Campaigns/EditPage';
import SignUp from '../pages/Account/SignUpPage';
import CampaignHistory from '../components/CampaignDashboard/CampaignHistory/CampaignHistory';
import SignInPage from '../pages/Account/SignInPage';
import AdminLayout from '../components/Layouts/AdminLayout';
import AdminMain from '../pages/Admin/main';
import Onboarding from '../pages/Account/Onboarding';
import AllPostPage from '../pages/Posts/AllPostPage';
import FreeCredits from '../pages/FreeCredits/Main';
import SignUpV2Page from '../pages/Account/SignUpV2Page';
import RecentCampaignPage from '../pages/Campaigns/RecentCampaignPage';
import Calendly from '../components/Calendly';
import RootPage from '../pages/RootPage';

import BrandForm from '../components/Brands/BrandForm';
import PaymentForm from '../components/Payment/Form';

import AdminContents from '../pages/Admin/Contents';
import PreOnboarding from '../pages/Account/PreOnboarding';
import Notifications from '../components/Notifications';
import SuccessPurchase from '../components/ManageCredits/SuccessPurchase';

const TrendRouters = ({ authUser }) => {
  let user = {};

  if (authUser) {
    user = {
      email: authUser.email
    };
  }

  return (
    <Router>
      <div>
        <Switch>
          {isMobile && (
            <AppRoute path="/signup" component={MobileViewSignUp} exact layout={SessionLayout} />
          )}
          {!isMobile && <AppRoute path="/signup" component={SignUp} exact layout={SessionLayout} />}
          <AppRoute path="/sign_up" component={SignUpV2Page} exact layout={SessionLayout} />
          <AppRoute path="/login" component={SignInPage} exact layout={SessionLayout} />
          <AppRoute
            path="/forgot_password"
            component={PasswordForget}
            exact
            layout={SessionLayout}
          />
          <AppRoute path="/trial/:hash" component={TrialSignup} exact layout={SessionLayout} />
          <AppRoute
            path="/trial/:hash/company"
            component={TrialCompanyProfile}
            exact
            layout={SessionLayout}
          />
          <AppRoute
            path="/trial/:hash/campaign"
            component={TrialCampaignSetup}
            exact
            layout={SessionLayout}
          />
          <AppRoute path="/strategy_session" component={Onboarding} exact layout={SessionLayout} />
          <PrivateRoute path="/update_email" component={ChangeEmail} exact />
          <PrivateRoute path="/change_password" component={ChangePassword} exact />
          <OnboardingRoute
            path={routes.SUBSCRIPTION_PATH}
            component={Subscription}
            exact
            layout={SessionLayout}
          />
          <OnboardingRoute
            path={routes.PRE_ONBOARDING_PATH}
            component={PreOnboarding}
            exact
            layout={SessionLayout}
          />

          {/* demo routes */}

          <PrivateRoute path="/account" component={BrandAccount} />
          <PrivateRoute path="/session" component={Calendly} />
          <PrivateRoute path="/credits" component={ManageCredits} />
          <PrivateRoute path={routes.SUCCESS_PURCHASE_PATH} component={SuccessPurchase} />
          <PrivateRoute
            path="/store/analytics"
            component={StoreAnalytics}
            exact
            layout={SiderLayout}
          />
          <PrivateRoute
            path="/campaigns/new"
            component={CampaignNewPage}
            onLeave={() => alert('a')}
          />
          <PrivateRoute path="/campaigns/brandForm" component={BrandForm} />
          <PrivateRoute path="/campaigns/paymentForm" component={PaymentForm} />
          <OnboardingRoute path={routes.FIRST_CAMPAIGN_PATH} component={NewFirstCampaign} />
          <PrivateRoute path="/campaigns/:campaignId/edit" component={CampaignEditPage} exact />
          <PrivateRoute
            path="/campaigns/history"
            component={CampaignHistory}
            layout={SiderLayout}
          />
          <PrivateRoute
            path="/campaigns/:campaignId"
            component={CampaignDashboard}
            layout={SiderLayout}
          />

          <PrivateRoute path="/free-credits" component={FreeCredits} layout={SiderLayout} />

          <PrivateRoute path="/posts" component={AllPostPage} exact layout={SiderLayout} />
          <PrivateRoute
            path="/exampleCampaigns"
            component={RecentCampaignPage}
            exact
            layout={SiderLayout}
          />
          <PrivateRoute
            path="/exampleCampaigns/:campaignId"
            component={ExampleDashboard}
            layout={SiderLayout}
          />

          <AdminRoute
            path={routes.ADMIN_TRIALS_PATH}
            component={AdminFreeTrials}
            layout={SiderLayout}
          />

          <AdminRoute
            path={routes.ADMIN_BRANDS_PATH}
            component={AdminBrands}
            layout={SiderLayout}
          />

          <AdminRoute
            exact
            path={routes.ADMIN_OPTIONS_PATH}
            layout={SiderLayout}
            component={AdminOptions}
          />
          <AdminRoute path={routes.ADMINV2_PATH} component={AdminMain} layout={AdminLayout} />
          <AdminRoute
            exact
            path={routes.ADMIN_CONTENTS}
            layout={AdminLayout}
            component={AdminContents}
          />
          <PrivateRoute exact path="/" component={RootPage} layout={SiderLayout} />
        </Switch>

        {authUser && authUser.uid && <Notifications />}

        {process.env.REACT_APP_ENV === 'production' && authUser && (
          <Intercom appID="auh3f5wk" {...user} />
        )}
      </div>
    </Router>
  );
};

const mapStateToProps = state => ({
  authUser: state.entities.session.authUserData
});

export default compose(
  withAuthentication,
  connect(mapStateToProps)
)(TrendRouters);
