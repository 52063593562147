import React from 'react';
import { Avatar, Card } from 'antd';
import { connect } from 'react-redux';
import PostCardVideo from './Video';
import PostStat from './PostStat';
import PostImage from './Image';

const { Meta } = Card;

const CardV2 = ({ post, influencer }) => {
  const {
    details: { image, username, state }
  } = influencer;

  return (
    <Card
      cover={
        ['video', 'story'].includes(post.type) ? (
          <PostCardVideo post={post} withLabel />
        ) : (
          <PostImage post={post} withLabel />
        )
      }
      className="post-card trend-post-card"
      bordered={false}
    >
      <a href={`https://www.instagram.com/${username}/`} target="_blank" rel="noopener noreferrer">
        <Meta
          avatar={<Avatar src={image} style={{ width: '35px', height: '35px' }} />}
          title={<span title={`@${username}`}>@{username}</span>}
          description={state}
        />
      </a>
      {post.type !== 'story' && <PostStat post={post} />}
    </Card>
  );
};

const mapStateToProps = (state, { influencer, influencerUID }) => ({
  postById: state.entities.posts.byId,
  influencer: influencer || state.entities.influencers.byId[influencerUID]
});

export default connect(mapStateToProps)(CardV2);
