import { firebase } from "lib/Firebase";
import { MESSAGE_TYPE } from "constants/common";
import { CAMPAIGN_APPLIED_UID_STATUS } from "constants/campaigns";
import { pickBy } from "lodash";

export default (campaignUID, onComplete = null) => {
  firebase
    .messages()
    .orderByChild("details/partnershipUID")
    .equalTo(campaignUID)
    .on("value", async snapshot => {
      const messages = snapshot.val();
      const messageUIDs = [];
      let approvedMessages = {};

      if (!snapshot.exists()) {
        onComplete(messageUIDs, {});
        return;
      }

      const influencerSnap = await firebase
        .activeProduct(campaignUID)
        .child("appliedUID")
        .once("value");

      const influencers = influencerSnap.val();
      const approvedInfluencerUIDs = Object.keys(
        pickBy(
          influencers,
          influencer =>
            influencer.status === CAMPAIGN_APPLIED_UID_STATUS.approved
        )
      );

      Object.keys(messages).forEach(messageUID => {
        const { users = {} } = messages[messageUID];
        let notRead = false;
        let approved = false;

        Object.keys(users).forEach(userMessageUID => {
          const user = users[userMessageUID];

          if (user.type === MESSAGE_TYPE.brand && user.hasUnread) {
            notRead = true;
          }

          if (
            approvedInfluencerUIDs.includes(userMessageUID) &&
            user.type === MESSAGE_TYPE.influencer
          ) {
            approved = true;
          }
        });

        if (approved) {
          approvedMessages = {
            ...approvedMessages,
            [messageUID]: messages[messageUID]
          };
          if (notRead) {
            messageUIDs.push(messageUID);
          }
        }
      });

      onComplete(messageUIDs, approvedMessages);
    });
};
