import React from 'react';
import { StyleSheet, css } from 'aphrodite';

const styles = StyleSheet.create({
  normal: {
    fontWeight: 'normal'
  },
  bold: {
    fontWeight: '600px'
  }
});

const HeaderText = ({ bold = false, children, className, ...rest } = {}) => (
  <h3 {...rest} className={`${className} ${bold ? css(styles.bold) : css(styles.normal)}`}>
    {children}
  </h3>
);

export default HeaderText;
