import React, { useEffect, useState } from 'react';
import { Row, Col, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Container from '../Common/Container';
import { firebase } from '../../lib/Firebase';
import { listenNotification } from '../../listeners/notifications';

const Notifications = () => {
  const dispatch = useDispatch();
  const session = useSelector(state => state.entities.session.authUserData);

  const [brandUID, setBrandUID] = useState(undefined);

  useEffect(() => {
    if (session && session.uid) {
      setBrandUID(session.uid);
    }
  }, [session, setBrandUID]);

  const notification = useSelector(state => state.entities.notifications.byId[brandUID] || {});

  useEffect(() => {
    if (brandUID) {
      return listenNotification({ brandUID, dispatch });
    }

    return undefined;
  }, [session, dispatch]);

  const onChange = field => checked => {
    if (brandUID) {
      firebase
        .notifications()
        .doc(brandUID)
        .set({ [field]: checked }, { merge: true });
    }
  };

  return (
    <div className="brand-notifications">
      <Container width={500}>
        <Row className="note">
          Adjust your browser notifications to stay on top of your campaigns
        </Row>
        <Row type="flex" justify="center">
          <Row className="notif-container">
            <Row justify="space-between" className="notif-row">
              <Col span={18}>Application Notifications</Col>
              <Col span={6} className="notif-action">
                <Switch
                  checked={notification.newApplication}
                  className="notif-switch-action"
                  onChange={onChange('newApplication')}
                />
              </Col>
            </Row>
            <Row justify="space-between" className="new-message-row notif-row">
              <Col span={18}>New Message Notifications</Col>
              <Col span={6} className="notif-action">
                <Switch
                  checked={notification.newMessage}
                  className="notif-switch-action"
                  onChange={onChange('newMessage')}
                />
              </Col>
            </Row>
            <Row justify="space-between" className="new-post-row notif-row">
              <Col span={18}>New Post Notifications</Col>
              <Col span={6} className="notif-action">
                <Switch
                  checked={notification.newPost}
                  className="notif-switch-action"
                  onChange={onChange('newPost')}
                />
              </Col>
            </Row>
          </Row>
        </Row>
      </Container>
    </div>
  );
};

export default Notifications;
