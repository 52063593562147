import React, { useState } from 'react';
import { Button } from 'antd';
import { withRouter } from 'react-router-dom';
import { doSignInWithCustomToken, fetchAuthToken } from 'api/auth';

const BrandLoginButton = ({ brandId, history, children, type }) => {
  const [logging, setLoggingState] = useState(false);

  function handleLogin() {
    setLoggingState(true);
    fetchAuthToken(brandId, (response, err) => {
      const token = response.data;
      if (!err) {
        doSignInWithCustomToken(token, () => {
          setLoggingState(false);
          history.push('/posts');
        });
      }
    });
  }

  return (
    <Button loading={logging} onClick={handleLogin} type={type}>
      {children || 'Login'}
    </Button>
  );
};

export default withRouter(BrandLoginButton);
