import React from 'react';
import { Modal, Row, Col, Button, Alert, Divider, Radio, Input } from 'antd';
import { REJECT_REASONS } from 'constants/influencers';

const { Group: RadioGroup, Button: RadioButton } = Radio;
const { TextArea } = Input;

const ConfrmRejectModal = props => {
  const {
    show,
    hide,
    rejectInfluencer,
    rejectInfluencers,
    submitReject,
    massFinalReject,
    onChangeMassFinalReject,
    onChangeRejectReason,
    selectedInfluencerUIDs,
    rejectReason,
    onChange,
    rejectCustomReason,
    isValidReason
  } = props;

  const Reasons = REJECT_REASONS.map(([reasonShow, reasonMessage]) => (
    <Radio
      key={reasonShow}
      style={{ display: 'block', height: '30px', lineHeight: '30px' }}
      value={reasonMessage}
    >
      {reasonShow}
    </Radio>
  ));

  return (
    <Modal
      visible={show}
      onOk={rejectInfluencer}
      closable={false}
      className="trend-modal"
      footer={[
        <Row gutter={20} key="reject-modal">
          <Col span={12}>
            <Button
              key="cancel"
              disabled={submitReject}
              className="trend-btn medium-btn secondary"
              onClick={() => {
                hide('visible');
              }}
              block
            >
              Cancel
            </Button>
          </Col>
          <Col span={12}>
            <Button
              key="submit"
              className="trend-btn medium-btn reject"
              loading={submitReject}
              onClick={rejectInfluencers}
              block
            >
              Confirm Application Pass
            </Button>
          </Col>
        </Row>
      ]}
    >
      <div>
        <Row>
          <Col span={18}>
            <h2 className="text-black mb-2 type-ebold leading-24px">
              Are you sure you want to&nbsp;
              <span style={{ color: '#af4c4c', fontStyle: 'italic' }}>Pass</span>
              &nbsp;on this influencer?
            </h2>
          </Col>
        </Row>
        <Row>
          <Col span={18}>
            <span className="bsize-4 text-black leading-18px">
              Reject&nbsp;
              {selectedInfluencerUIDs.length > 1 ? 'these influencers' : 'this influencer'}
              &nbsp;from all of
              <br />
              your current and future campaigns?
            </span>
          </Col>
          <Col span={6}>
            <RadioGroup
              onChange={onChangeMassFinalReject}
              value={massFinalReject}
              defaultValue="no"
              buttonStyle="solid"
              className="plain-radio-group"
            >
              <RadioButton value="no">NO</RadioButton>
              <RadioButton value="yes">YES</RadioButton>
            </RadioGroup>
          </Col>
        </Row>
      </div>
      <Divider />
      <RadioGroup onChange={onChangeRejectReason} value={rejectReason}>
        {Reasons}
      </RadioGroup>
      {!rejectReason && (
        <TextArea
          rows={4}
          onChange={onChange}
          name="rejectCustomReason"
          value={rejectCustomReason}
          placeholder="Explain why this influencer is being rejected..."
        />
      )}
      {!rejectReason && !isValidReason && (
        <Alert
          message="Please enter a reason to explain to the influencer why they are being rejected."
          type="warning"
          style={{ marginTop: '5px' }}
        />
      )}
    </Modal>
  );
};
export default ConfrmRejectModal;
