/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from 'lib/Firebase';
import { connect } from 'react-redux';
import { Layout, Col, Row, Skeleton } from 'antd';
import { withAuthUser } from 'lib/Session';
import 'styles/base/antd-custom.less';
import MinLayout from 'components/Common/MinLayout';
import { withCookies } from 'react-cookie';
import CampaignContent from './TabContent/CampaignContent';
import {
  listenNewMessages,
  listenCampaignPosts,
  listenAppliedStatusUpdate
} from '../../listeners/campaignDashboard';
import {
  addCurrentCampaign,
  setCampaignDashboardReady,
  loadCampaignDashboard
} from '../../actions/ui/campaignDashboard';

const { Content } = Layout;

class Dashboard extends React.Component {
  componentDidMount() {
    const { firebase, cookies } = this.props;
    const { uid } = firebase.auth.currentUser;

    if (!cookies.get('isAdmin')) {
      firebase
        .brand(uid)
        .child('details')
        .update({ lastLoginDate: new Date().getTime() / 1000 });
    }

    this.reloadResources();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params.campaignId !== match.params.campaignId) {
      this.reloadResources();
    }
  }

  async reloadResources() {
    const {
      dispatchAddCurrentCampaign,
      dispatchListenCampaignPosts,
      match: { params },
      campaignDashboard,
      authUser: { uid: userUID },
      dispatch
    } = this.props;
    const { campaignId } = params;
    const dashboard = campaignDashboard.byCampaignId[campaignId] || {};
    let currentCampaign;
    await dispatchAddCurrentCampaign(campaignId, c => {
      currentCampaign = c;
    });

    if (!dashboard || !dashboard.ready) {
      dispatchListenCampaignPosts(campaignId);
      listenNewMessages(dispatch, params.campaignId);
      dispatch(setCampaignDashboardReady(campaignId, false));
      if (userUID === currentCampaign.brandUID) {
        listenAppliedStatusUpdate(campaignId, async campaign => {
          dispatch(loadCampaignDashboard(campaignId, campaign));
        });
      } else {
        dispatch(setCampaignDashboardReady(campaignId, true));
      }
    }
  }

  render() {
    const { match, currentCampaign, campaignDashboard } = this.props;
    const dashboard = campaignDashboard.byCampaignId[match.params.campaignId];

    const campaign = currentCampaign || {};

    if (Object.keys(campaign).length < 1 || !dashboard || !dashboard.ready) {
      return (
        <div>
          <div
            style={{
              minHeight: '175px',
              paddingTop: '38px'
            }}
          >
            <div
              style={{
                borderBottom: '1px solid #dbdfe8',
                paddingBottom: '60px'
              }}
            >
              <Row type="flex" justify="space-around">
                <MinLayout>
                  <Row>
                    <Col xs={6}>
                      <Skeleton active paragraph={false} />
                    </Col>
                  </Row>
                </MinLayout>
              </Row>
            </div>
          </div>
          <Row type="flex" justify="space-around">
            <MinLayout>
              <Skeleton active />
            </MinLayout>
          </Row>
        </div>
      );
    }

    return (
      <div id="example-campaign">
        <Content>
          <Switch>
            <Route
              path="/exampleCampaigns/:campaignId"
              render={() => (
                <CampaignContent key={match.params.campaignId || 'example'} {...this.props} />
              )}
            />
          </Switch>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentCampaign: state.ui.campaignDashboard.currentCampaign,
  campaignDashboard: state.ui.campaignDashboard,
  campaigns: state.entities.campaigns.byId
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  dispatchAddCurrentCampaign: (campaignUID, callback) =>
    dispatch(addCurrentCampaign(campaignUID, callback)),
  dispatchListenCampaignPosts: campaignUID => dispatch(listenCampaignPosts(campaignUID))
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withAuthUser,
  withFirebase,
  withRouter,
  withCookies
)(Dashboard);
