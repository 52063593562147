/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import moment from 'moment';
import { Row, Layout, Col, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import { withFirebase } from 'lib/Firebase';
import HeaderText from 'components/Common/HeaderText';
import { withAuthorization } from 'lib/Session';
import { DATE_TIMEZONE_FORMAT } from 'constants/common';
import { ReactComponent as IconBackMessage } from 'images/icon-back-arrow.svg';
import MinLayout from 'components/Common/MinLayout';
import { fetchMessage, markThreadAsRead } from 'actions/messages';
import { fetchChatMessages, sendChatMessage } from '../../../actions/chat_messages';
import ChatItem from './ChatItem';
import { InfluencerDrawer } from '../../Influencers';

const { Content, Header, Footer } = Layout;

class Chat extends Component {
  state = {
    influencerUID: null,
    showDetails: false
  };

  constructor(props) {
    super(props);
    this.inputField = React.createRef();
  }

  componentDidMount() {
    const {
      messages,
      match: { params },
      markThreadAsRead: read
    } = this.props;
    const { messageId } = params;
    const thread = messages[messageId];
    if (thread) {
      const influencerUID = Object.keys(thread.users).filter(userUID => {
        return thread.users[userUID].type === 'influencer';
      })[0];

      this.setState({ influencerUID });
      this.inputField.current.focus();
      this.scrollToBottom();
      read(messageId);
    }
  }

  onJustSend = () => {
    const chatValue = this.inputField.current.value || '';

    if (chatValue.trim() === '') {
      this.inputField.current.value = '';
      return;
    }

    this.onSend(chatValue);
    this.inputField.current.value = '';
  };

  onPressSend = event => {
    event.persist();
    if (event.keyCode === 13) {
      this.onJustSend();
    }
  };

  onSend = message => {
    const { sendChatMessage: sendMessage, authUser, match } = this.props;
    const { influencerUID } = this.state;
    const params = {
      sender: authUser.uid,
      text: message,
      timestamp: moment().format(DATE_TIMEZONE_FORMAT)
    };

    sendMessage(match.params.messageId, influencerUID, params);

    setTimeout(() => {
      this.scrollToBottom();
    }, 100);
  };

  scrollToBottom = () => {
    const self = this;
    setTimeout(() => {
      self.messagesEnd.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  handleShowInfluencerDetails = e => {
    e.preventDefault();
    this.setState({ showDetails: true });
  };

  handleHideInfluencerDetails = () => {
    this.setState({ showDetails: false });
  };

  render() {
    let dataSource = [];
    const {
      authUser,
      influencers,
      brands,
      match: { params },
      messages,
      history
    } = this.props;
    const { influencerUID, showDetails } = this.state;

    const thread = messages[params.messageId];

    const influencerDetails = influencers.byId[influencerUID]
      ? influencers.byId[influencerUID].details
      : {};

    if (thread && thread.messages && influencers.all.length && brands.all.length) {
      const brand = brands.byId[authUser.uid];

      dataSource = Object.entries(thread.messages).map(([id, chatMessage]) => ({
        key: id,
        sender: thread.users[authUser.uid],
        own: chatMessage.sender === authUser.uid,
        text: chatMessage.text,
        timestamp: chatMessage.timestamp,
        avatar:
          chatMessage.sender === authUser.uid ? brand.details.storeImage : influencerDetails.image
      }));
    }

    return (
      <>
        <Header
          style={{
            backgroundColor: '#fff',
            borderBottom: '1px solid #d6d6d6',
            padding: '30px 0',
            height: '90px',
            position: 'fixed',
            top: '0',
            width: 'calc(100% - 210px)',
            zIndex: 1
          }}
        >
          <Row type="flex" justify="space-around">
            <MinLayout>
              <HeaderText bold style={{ marginBottom: 48, paddingBottom: 48 }}>
                <Button
                  style={{
                    float: 'left',
                    marginRight: 24,
                    paddingRight: 22,
                    color: '#000',
                    boxShadow: 'none',
                    border: '0',
                    borderRight: '1px solid #d6d6d6',
                    borderRadius: '0',
                    fontSize: '12px',
                    textAlign: 'center'
                  }}
                  onClick={history.goBack}
                >
                  <IconBackMessage style={{ display: 'inlineBlock' }} />
                  <span style={{ display: 'block' }}>Back</span>
                </Button>
                <div style={{ float: 'left', marginTop: '6px', lineHeight: '0' }}>
                  <div style={{ lineHeight: '0' }}>{influencerDetails.name}</div>
                  {influencerDetails ? (
                    <Button
                      type="link"
                      style={{
                        border: 'none',
                        lineHeight: '3.5',
                        cursor: 'pointer',
                        color: '#ffb917',
                        fontStyle: 'italic',
                        fontFamily: 'truenosemibold',
                        fontSize: '12px',
                        textAlign: 'left',
                        padding: 0
                      }}
                      onClick={this.handleShowInfluencerDetails}
                    >
                      See Profile
                    </Button>
                  ) : null}
                </div>
              </HeaderText>
            </MinLayout>
          </Row>
        </Header>
        <Content style={{ marginTop: 48, paddingTop: 24 }}>
          <Row type="flex" justify="space-around">
            <MinLayout style={{ marginBottom: '64px' }}>
              {dataSource.map((row, idx) => (
                <ChatItem key={idx} item={row} />
              ))}
              <div
                style={{ float: 'left', clear: 'both' }}
                ref={el => {
                  this.messagesEnd = el;
                }}
              />
            </MinLayout>
          </Row>
        </Content>
        <Footer
          style={{
            backgroundColor: '#fff',
            position: 'fixed',
            bottom: '0',
            width: 'calc(100% - 210px)',
            borderTop: '1px solid #e8e8e8',
            margin: 0,
            paddingTop: 24,
            paddingLeft: 24
          }}
        >
          <Row
            style={{ backgroundColor: '#fff' }}
            align="middle"
            gutter={4}
            type="flex"
            justify="space-around"
          >
            <MinLayout>
              <Col span={22} className="trend-message-input">
                <input
                  className="ant-input ant-input-lg"
                  onKeyDown={this.onPressSend}
                  type="text"
                  ref={this.inputField}
                  size="large"
                  placeholder="Type a Message..."
                  style={{ border: 0, color: '#000' }}
                />
              </Col>
              <Col span={2}>
                <Button
                  onClick={this.onJustSend}
                  className="trend-btn"
                  size="large"
                  style={{ color: '#000', paddingLeft: 24, paddingRight: 24 }}
                >
                  Send
                </Button>
              </Col>
            </MinLayout>
          </Row>
          <InfluencerDrawer
            influencerUID={influencerUID}
            show={showDetails}
            onHide={this.handleHideInfluencerDetails}
          />
        </Footer>
      </>
    );
  }
}
const mapStateToProps = state => ({
  brands: state.entities.brands,
  messages: state.entities.messages.byId,
  influencers: state.entities.influencers,
  isFetching: state.entities.chat_messages.isFetching
});

const mapDispatchToProps = dispatch => ({
  fetchChatMessages: (messageId, brandID) => dispatch(fetchChatMessages(messageId, brandID)),
  fetchMessage: (messageId, brandID) => dispatch(fetchMessage(messageId, brandID)),
  markThreadAsRead: messageId => dispatch(markThreadAsRead(messageId)),
  sendChatMessage: (messageId, influencerUID, data) =>
    dispatch(sendChatMessage(messageId, influencerUID, data))
});

const condition = authUser => !!authUser;

export default compose(
  withRouter,
  withFirebase,
  withAuthorization(condition),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Chat);
