import { useState, useEffect, useContext } from 'react';
import Promise from 'bluebird';
import { firebase } from '../../../lib/Firebase';
import { InfluencerCampaignContext } from './context';

const initialState = {
  active: [],
  needsShipping: [],
  shipped: [],
  completed: []
};

const useInfluencerCampaigns = influencerUID => {
  const [loading, setLoading] = useState(false);
  const { campaigns, setCampaigns } = useContext(InfluencerCampaignContext);

  async function retrieveActiveCampaigns() {
    setLoading(true);
    const partnershipsRef = await firebase
      .influencer(influencerUID)
      .child('partnerships/products')
      .orderByChild('status');

    let newState = initialState;

    const statuses = Object.keys(newState);
    await Promise.map(statuses, async status => {
      const querySnap = await partnershipsRef.equalTo(status).once('value');
      if (querySnap.exists()) {
        const campaignUIDs = Object.keys(querySnap.val());
        await Promise.map(campaignUIDs, async campaignUID => {
          const partnershipsSnap = await firebase.firestore
            .collection('influencersPartnerships')
            .where('influencerUID', '==', influencerUID)
            .where('productUID', '==', campaignUID)
            .get();

          const [partnershipDoc] = partnershipsSnap.docs;
          const snap = await firebase.firestore
            .collection('products')
            .doc(campaignUID)
            .get();

          newState = {
            ...newState,
            [status]: [
              ...newState[status],
              {
                key: campaignUID,
                campaign: { uid: snap.id, ...snap.data() },
                partnership: partnershipDoc.data()
              }
            ]
          };
        });
      }
    });
    setCampaigns({ influencerUID, campaigns: newState });
    setLoading(false);
  }

  useEffect(() => {
    if (!campaigns[influencerUID]) {
      retrieveActiveCampaigns();
    }
  }, [influencerUID]);

  function setPartnership(productUID, status, partnership) {
    const data = campaigns[influencerUID][status].map(d => {
      const newData = { ...d };
      if (newData.key === productUID) {
        newData.partnership = partnership;
      }

      return newData;
    });

    setCampaigns({
      influencerUID,
      campaigns: {
        ...campaigns[influencerUID],
        [status]: [...data]
      }
    });
  }

  return [campaigns[influencerUID] || initialState, loading, setPartnership];
};

export default useInfluencerCampaigns;
