import config from 'app_config';

const API_URL = config.REACT_APP_FUNCTION_API_URL;
const baseUrl = path => `${API_URL}/api/${path}`;

const Routes = {
  spendCredits: baseUrl('spendCredits'),
  shipengine: baseUrl('shipengine'),
  paySubscription: baseUrl('paySubscription'),
  plans: baseUrl('plans'),
  currentSubscription: baseUrl('subscription'),
  updatePlan: baseUrl('updatePlan'),
  postReportCsv: baseUrl('download/postReportCsv'),
  addCredits: baseUrl('addCredits'),
  returnCredits: baseUrl('returnCredits'),
  sendAnalyticsEmail: baseUrl('sendAnalyticsEmail'),
  paypalPayout: baseUrl('paypal/payout'),
  authToken: baseUrl('token'),
  sendFreeTrialInvitation: baseUrl('freeTrialInvitation'),
  testEmail: baseUrl('testEmail'),
  cardList: baseUrl('cardList'),
  createCard: baseUrl('createCard'),
  validateStripeCustomerId: baseUrl('validateStripeCustomerId'),
  updateCard: baseUrl('updateCard')
};

export default Routes;
