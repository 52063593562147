import { fetchCampaignActiveOrComplete } from "api/campaigns";
import { CAMPAIGN_APPLIED_UID_STATUS } from "constants/campaigns";
import { firebase } from "../lib/Firebase";
import * as Promise from "bluebird";

export const fetchInfluencer = (influencerUID, onComplete) => {
  firebase.influencer(influencerUID).once("value", snapshot => {
    const influencer = {};
    if (snapshot.exists()) {
      influencer[influencerUID] = snapshot.val();
    }

    onComplete(influencer);
  });
};

export const fetchCampaignAppliedInfluencerUIDs = (
  campaignUID,
  onComplete = null
) => {
  fetchCampaignActiveOrComplete(campaignUID, (campaign, error) => {
    const influencerUIDs = [];
    if (error) {
      onComplete(error);
    }

    Object.keys(campaign.appliedUID || {}).forEach(influencerUID => {
      const influencer = campaign.appliedUID[influencerUID];
      if (influencer.status === CAMPAIGN_APPLIED_UID_STATUS.submitted) {
        influencerUIDs.push(influencerUID);
      }
    });
    onComplete(influencerUIDs);
  });
};

export const fetchCampaignApprovedInfuencerUIDs = (
  campaignUID,
  onComplete = null
) => {
  fetchCampaignActiveOrComplete(campaignUID, (campaign, error) => {
    const influencerUIDs = [];
    if (error) {
      onComplete(error);
    }

    Object.keys(campaign.appliedUID || {}).forEach(influencerUID => {
      const approvedInfluencer = campaign.appliedUID[influencerUID];
      if (approvedInfluencer.status === CAMPAIGN_APPLIED_UID_STATUS.approved) {
        influencerUIDs.push(influencerUID);
      }
    });

    onComplete(influencerUIDs);
  });
};

export const fetchInfluencerByUIDs = influencerUIDs => {
  let influencers = {};
  return Promise.map(influencerUIDs, influencerUID => {
    return firebase
      .influencer(influencerUID)
      .once("value")
      .then(snap => {
        if (snap.exists()) {
          influencers = {
            ...influencers,
            [snap.key]: snap.val()
          };
        }
        return null;
      });
  }).then(val => {
    return influencers;
  });
};
