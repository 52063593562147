export const SET_CATEGORIES = 'SET_CATEGORIES';

const defaultState = {
  categories: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
      };
    default:
      return state;
  }
};
