import React, { Component } from 'react';
import {
  Layout, List, Button, Form, Input, message,
} from 'antd';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  getFreeTrials,
  sendFreeTrial,
  resendFreeTrial,
} from 'actions/ui/adminOptions';
import MinLayout from 'components/Common/MinLayout';
import { DATE_TIMEZONE_FORMAT } from 'constants/common';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import config from 'app_config';

const { Content } = Layout;

class FreeTrials extends Component {
  static defaultProps = {
    email: '',
    firstName: '',
    lastName: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      firstName: '',
      lastName: '',
    };
  }

  componentDidMount() {
    const { fetchTrials } = this.props;
    fetchTrials();
  }

  onResendFreeTrial = (id) => {
    const { resendFreeTrial } = this.props;
    resendFreeTrial(id);
    message.success('Resent Free trial invitation');
  };

  onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { form, sendFreeTrial, trials } = this.props;

    form
      .validateFields()
      .then((data) => {
        if (
          !Object.values(trials)
            .map(trial => trial.email)
            .includes(data.email)
        ) {
          sendFreeTrial(data);
          message.success('Free trial invitation sent');
        } else {
          message.info(
            'This email has been added already. Resend Invitation instead',
          );
        }
        form.resetFields();
      })
      .catch((errors) => {
        this.setState({ errors });
      });
  };

  onCopyToClipboard = (hash) => {
    message.success(`Copied ${hash} to CLIPBOARD`, 30);
  };

  onChange = (e) => {
    const { target } = e;
    this.setState({ [target.name]: target.value });
  };

  render() {
    const { trials, form } = this.props;
    const { getFieldDecorator } = form;
    const { email, firstName, lastName } = this.state;

    return (
      <MinLayout className="bg-white">
        <h1>Free Trials</h1>
        <Content>
          <Form
            layout="horizontal"
            onSubmit={this.onSubmit}
            className="product-form pb-8"
          >
            <Form.Item label="Email Address">
              {getFieldDecorator('email', {
                initialValue: email,
                rules: [
                  {
                    required: true,
                    message: 'Add the email address of the user.',
                  },
                ],
              })(
                <Input
                  type="email"
                  name="email"
                  size="large"
                  className="trend-input light type-light"
                  placeholder="Email Address"
                />,
              )}
            </Form.Item>
            <Form.Item label="First Name">
              {getFieldDecorator('firstName', {
                initialValue: firstName,
                rules: [
                  {
                    required: true,
                    message: 'Add the first name of the user.',
                  },
                ],
              })(
                <Input
                  type="text"
                  name="firstName"
                  size="large"
                  className="trend-input light type-light"
                  placeholder="First Name"
                />,
              )}
            </Form.Item>
            <Form.Item label="Last Name">
              {getFieldDecorator('lastName', {
                initialValue: lastName,
                rules: [
                  {
                    required: true,
                    message: 'Add the last name of the user.',
                  },
                ],
              })(
                <Input
                  type="text"
                  name="lastName"
                  size="large"
                  className="trend-input light type-light"
                  placeholder="Last Name"
                />,
              )}
            </Form.Item>
            <Button
              size="large"
              type="submit"
              className="trend-btn"
              onClick={this.onSubmit}
            >
              Send
            </Button>
          </Form>
        </Content>

        <List
          dataSource={Object.keys(trials).reverse()}
          renderItem={id => (
            <List.Item
              actions={[
                <div style={{ textAlign: 'right' }}>
                  {
                    `Invited on ${ moment(trials[id].createdAt).utc().tz('America/Chicago').format(
                      DATE_TIMEZONE_FORMAT,
                    )}`
                  }
                  <br />
                  {
                    trials[id].registeredAt && trials[id].registeredAt.length ? (
                      `Registered On ${moment(trials[id].registeredAt).utc().tz('America/Chicago').format(
                        DATE_TIMEZONE_FORMAT,
                      )}`
                    ) : (
                      <Button onClick={() => this.onResendFreeTrial(id)}>
                        Resend Invitation
                      </Button>
                    )
                  }
                </div>
                ,
              ]}
            >
              {trials[id] && (
                <span>
                  <div>
                    {trials[id].firstName}
                    {' '}
                    {trials[id].lastName}
                    {' '}
-
                    {' '}
                    {trials[id].email}
                  </div>
                  <div className="reminder-stats">
                    <small>
                      { trials[id].remindedToRegister && 'Register reminder: Sent' }
                    </small>
                    <small>
                      { trials[id].remindedToSetupCampaign && 'Setup reminder: Sent' }
                    </small>
                    <small>
                      { trials[id].remindedToUpgrade && 'Upgrade reminder: Sent' }
                    </small>
                  </div>
                  <div>
                    <CopyToClipboard
                      text={`${config.REACT_APP_URL}/trial/${trials[id].hash}`}
                      onCopy={() => this.onCopyToClipboard(
                        `${config.REACT_APP_URL}/trial/${trials[id].hash}`,
                      )
                      }
                    >
                      <Button>Copy Free Trial User Registration Link</Button>
                    </CopyToClipboard>
                  </div>
                </span>
              )}
            </List.Item>
          )}
        />
      </MinLayout>
    );
  }
}

export const mapStateToProps = state => ({
  trials: state.entities.trials.byId,
});

export const mapDispatchToProps = dispatch => ({
  fetchTrials: () => dispatch(getFreeTrials()),
  sendFreeTrial: params => dispatch(sendFreeTrial(params)),
  resendFreeTrial: trialUID => dispatch(resendFreeTrial(trialUID)),
});

export default compose(
  withRouter,
  Form.create({ email: '' }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(FreeTrials);
