import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/firebase-messaging';
import * as app from 'firebase/app';
import 'firebase/performance';
import config from 'app_config';

const appConfig = {
  apiKey: config.REACT_APP_API_KEY,
  authDomain: config.REACT_APP_AUTH_DOMAIN,
  databaseURL: config.REACT_APP_DATABASE_URL,
  projectId: config.REACT_APP_PROJECT_ID,
  storageBucket: config.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: config.REACT_APP_MESSAGING_SENDER_ID,
  appId: config.REACT_APP_ID
};

class Firebase {
  constructor() {
    app.initializeApp(appConfig);
    this.auth = app.auth();
    this.db = app.database();
    this.storage = app.storage();
    this.firestore = app.firestore();
    this.functions = app.functions;
    if (process.env.NODE_ENV === 'development') {
      // If you want to use the emulator database
      // this.firestore.settings({ host: 'localhost:8080', ssl: false });
      // this.functions().useFunctionsEmulator('http://localhost:5000');
    }
    this.app = app;

    try {
      this.messaging = app.messaging();
      this.messaging.usePublicVapidKey(config.REACT_VAPID_KEY);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("This browser doesn't support the API's required to use the firebase SDK. ", e);
    }
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = (event, callback = null) => {
    event.preventDefault();
    if (callback) {
      callback();
    }
    this.auth.signOut();
  };

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  brand = uid => this.db.ref(`brands/${uid}`);

  brands = () => this.db.ref('brands');

  brandInfo = uid => this.db.ref(`brands/${uid}/details`);

  posts = () => this.db.ref('posts');

  influencer = uid => this.db.ref(`influencers/${uid}`);

  influencers = () => this.db.ref('influencers');

  influencerProduct = (influencerUID, campaignUID) =>
    this.db.ref(`influencers/${influencerUID}/partnerships/products/${campaignUID}`);

  message = messageId => this.db.ref(`messages/${messageId}`);

  messages = () => this.db.ref('messages');

  chatMessages = messageId => this.db.ref(`messages/${messageId}/messages`);

  brandMessages = brandID => this.db.ref(`brands/${brandID}/messages`);

  influencers = () => this.db.ref('influencers');

  adminCategories = () => this.db.ref('admin/categories');

  activeProducts = () => this.db.ref('products/active');

  activeProduct = uid => this.db.ref(`products/active/${uid}`);

  completedProduct = campaignUID => this.db.ref(`products/completed/${campaignUID}`);

  completedProducts = () => this.db.ref('products/completed');

  campaignPosts = uid =>
    this.db.ref('influencers').orderByChild(`partnerships/products/${uid}/posts`);

  campaignShipments = uid =>
    this.db.ref('influencers').orderByChild(`partnerships/products/${uid}`);

  paypalRef = () => this.db.ref('admin/payPal');

  payoutsRef = () => this.db.ref('admin/payouts');

  postStatistics = brandUID => this.db.ref('postStatistics').child(brandUID);

  posts = () => this.db.ref('posts');

  trials = () => this.db.ref('trials');

  trial = trialUID => this.db.ref(`trials/${trialUID}`);

  additionalMedia = campaignUID => this.db.ref('additionalMedia').child(campaignUID);

  reviews = () => this.firestore.collection('reviews');

  trendReviews = () => this.firestore.collection('trendReviews');

  review = reviewUID => this.firestore.collection('reviews').doc(reviewUID);

  purchasedHistory = () => this.firestore.collection('purchasedHistory');

  brandSearch = () => this.firestore.collection('brandSearch');

  influencerSearch = () => this.firestore.collection('influencers');

  notifications = () => this.firestore.collection('notifications');

  notifAlerts = brandUID =>
    this.firestore
      .collection('notifications')
      .doc(brandUID)
      .collection('notifAlerts');

  trackDownloadPost = async postUID => {
    const postSnap = await this.db
      .ref('posts')
      .child(postUID)
      .once('value');

    if (postSnap.exists()) {
      const { productUID, influencerUID } = postSnap.val();

      await this.firestore
        .collection('products')
        .doc(productUID)
        .update({
          [`appliedUID.${influencerUID}.posts.${postUID}.downloaded`]: true
        });
    }
  };

  yotpoMetric = () => this.firestore.collection('yotpoMetric');

  callable = name => {
    return this.functions().httpsCallable(`callable-${name}`);
  };

  callService = ({ serviceName, data, type }) => {
    const func = this.callable('requestService');
    return func({ serviceName, data, type });
  };

  setACTag = async (email, tag) => {
    const func = this.callable('setAcTag');

    return func({ email, tag });
  };

  checkAndSetAcTag = async (email, tag) => {
    const func = this.callable('checkAndSetAcTag');

    return func({ email, tag });
  };

  branchUserJobPostStatsUpdate = async data => {
    const func = this.callable('branchUserJobPostStatsUpdate');

    return func(data);
  };

  sendTrendApprovalEmail = async inviteUID => {
    const func = this.callable('sendTrendApproveEmail');

    return func({ inviteUID });
  };

  createActiveCampaignContact = ({ email, name, businessName, website, utmParams }) => {
    const func = this.callable('createActiveCampaignContact');
    return func({ email, name, businessName, website, utmParams });
  };

  sendTrendEmailMessage = async (influencerUIDs = [], message = '') => {
    const func = this.callable('sendTrendEmailMessage');
    return func({ influencerUIDs, message });
  };

  brandUserScrapePostDataCampaign = async (influencerUIDs = [], message = '') => {
    const func = this.callable('sendTrendEmailMessage');
    return func({ influencerUIDs, message });
  };

  newSignupProcess = async (email, contactId, newEmail) => {
    const func = this.callable('newSignupProcess');

    return func({ email, contactId, newEmail });
  };

  newOnboardingApproveBusiness = async (email, alreadyExists) => {
    const func = this.callable('newOnboardingApproveBusiness');

    return func({ email, alreadyExists });
  };

  getContactInfoById = async contactId => {
    const func = this.callable('getContactInfoById');

    return func({ contactId });
  };

  sendPushNotification = async (influencerUID, message) => {
    const func = this.callable('sendPushNotification');
    return func({ influencerUID, message });
  };

  setUtmTags = ({ email, utmParams, fullName, contactId, newEmail }) => {
    const func = this.callable('setUtmTags');
    return func({ email, utmParams, fullName, contactId, newEmail });
  };

  updateProductsTable = ({ influencerUID, campaignUID, status }) => {
    const func = this.callable('updateProductsTable');
    return func({ influencerUID, campaignUID, status });
  };

  updateProductActiveByDeliveryDate = ({ influencerUID, productUID, deliveryDate }) => {
    const func = this.callable('updateProductActiveByDeliveryDate');
    return func({ influencerUID, productUID, deliveryDate });
  };
}

export default Firebase;
