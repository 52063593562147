import React from 'react';

const Biography = ({ text = '' }) =>
  text.length > 0 ? (
    <div className="influencer-info-bio">
      <label className="influencer-info-label">BIOGRAPHY</label>
      <p>{text}</p>
    </div>
  ) : (
    <div />
  );

export default Biography;
