export const SUPPORT_EMAIL = 'support@trend.io';

// for purchaing VIP credits contact email
export const PURCHASE_VIP_EMAIL = 'ramon@trend.io';

export const DATE_TIMEZONE_FORMAT = 'YYYY-MM-DD HH:mm:ss ZZ';

// use in send new message using dynamic type
export const MESSAGE_TYPE = {
  brand: 'brand',
  influencer: 'influencer',
  product: 'product',
};

export const PARTNERSHIP_TYPE = {
  product: 'product',
};

export const MASS_FINAL_REJECT = {
  no: 'no',
  yes: 'yes',
};

export const FREE_INFLUENCERS_DISPLAY = 3;
