import React from 'react';
import { Row, Col } from 'antd';

const EmptyList = () => {
  const list = [];
  for (let i = 0; i < 3; i += 1) {
    list.push(
      <Row className="mb-3" key={i}>
        <Col span={13}>
          <Col span={8}>
            <div
              className="empty-box"
              style={{
                border: '1px dashed #888',
                height: '40px',
                width: '40px'
              }}
            />
          </Col>
          <Col span={16}>
            <span
              className="type-sfpro-bold size-10px text-pigeon-dark"
              style={{ lineHeight: '42px' }}
            >
              Awaiting Posts
            </span>
          </Col>
        </Col>
        <Col span={5}>
          <h2 className="m-0 size-10px text-right text-pigeon-dark" style={{ lineHeight: '42px' }}>
            —
          </h2>
        </Col>
        <Col span={6}>
          <h2 className="m-0 size-10px text-right text-pigeon-dark" style={{ lineHeight: '42px' }}>
            —
          </h2>
        </Col>
      </Row>
    );
  }
  return list;
};

const EmptyRecentPosts = () => <div>{EmptyList()}</div>;

export default EmptyRecentPosts;
