import React from 'react';
import { Card, Col } from 'antd';

const CampaignListItem = ({ campaign, brand } = {}) =>
  !brand ? (
    ''
  ) : (
    <Col
      xs={{ span: 24 }}
      sm={{ span: 24 }}
      md={{ span: 12 }}
      lg={{ span: 12 }}
      xl={{ span: 12 }}
      className="item-campaign"
    >
      <Card
        hoverable
        style={{
          borderColor: '#f4f4f4',
          borderTop: '0',
          margin: '0 6.5px 13px 6.5px'
        }}
        cover={(
          <div
            style={{
              height: '198px',
              position: 'relative',
              overflow: 'hidden'
            }}
          >
            <img
              alt="example"
              src={campaign.image}
              style={{
                position: 'absolute',
                left: '0',
                right: '0',
                top: '0',
                bottom: '0',
                margin: 'auto',
                width: '100%'
              }}
            />
          </div>
)}
      >
        <div style={{ zIndex: -1 }}>
          <h3 className="campaign-user">{brand.details.name}</h3>
          <h5 className="campaign-name" alt={campaign.name}>
            {campaign.name.length > 110 ? `${campaign.name.substring(0, 110)}...` : campaign.name}
          </h5>
          <div className="campaign-value" style={{ zIndex: 1 }}>{`$ ${campaign.value} Value`}</div>
        </div>
      </Card>
    </Col>
  );

export default CampaignListItem;
