import React, { useState } from 'react';
import { Row, AutoComplete, Icon } from 'antd';
import debounce from '../../../components/Helpers/useDebounce';
import { firebase } from '../../../lib/Firebase';

const { Option } = AutoComplete;
const SearchInfuencers = ({ onChange }) => {
  const [searchList, setSearchList] = useState([]);
  const [searching, setSearching] = useState(false);
  const [influencers, setInfluencers] = useState(false);

  async function handleSearchChange(val) {
    if (val) {
      const searchUsername = (val || '').toLowerCase();

      setSearching(true);
      const influencersTmp = {};
      const influencerSnap = await firebase.firestore
        .collection('influencers')
        .where('usernameLow', '>=', searchUsername)
        .orderBy('usernameLow', 'asc')
        .limit(5)
        .get();
      let usernames = [];
      influencerSnap.docs.forEach(x => {
        const { usernameLow, username } = x.data();

        if (usernameLow.includes(searchUsername)) {
          usernames = [...usernames, username];
          influencersTmp[usernameLow] = x.id;
        }
      });
      setInfluencers(influencersTmp);
      setSearchList(usernames);
      setSearching(false);
    } else {
      setSearchList([]);
      setSearching(false);
    }
  }

  function handleSearchSelect(val) {
    const searchUsername = (val || '').toLowerCase();
    onChange(influencers[searchUsername] || '');
  }

  const searchListOptions = searchList.map(x => (
    <Option key={x} value={x}>
      {x}
    </Option>
  ));

  return (
    <Row className="search" type="flex" align="middle">
      {searching ? (
        <Icon type="loading" style={{ padding: '7px' }} />
      ) : (
        <Icon type="search" style={{ padding: '7px' }} />
      )}
      <AutoComplete
        onChange={debounce(handleSearchChange, 500)}
        placeholder="Search Creator"
        onSelect={debounce(handleSearchSelect, 500)}
        allowClear
        onSearch={debounce(handleSearchSelect, 500)}
      >
        {searchListOptions}
      </AutoComplete>
    </Row>
  );
};

export default SearchInfuencers;
