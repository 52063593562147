const selectBrandById = (state, brandUID) => {
  let brand = null;

  if (brandUID && state.entities.brands) {
    brand = state.entities.brands.byId[brandUID];
  }

  return brand;
};

export {
  selectBrandById as default,
  selectBrandById,
};
