import React, { Component } from 'react';
import { Avatar, Card, Spin, Button } from 'antd';
import { connect } from 'react-redux';
import PostCardVideo from './PostCardVideo';
import { postResourceDownloader } from '../../Post/PostDownloader';
import { ReactComponent as IconDownload } from '../../../images/icon-download.svg';
import PostStat from '../../Post/PostStat';
import { PostImage } from '../../Post';
import { YotPoBtn } from '../../YotPo';
import YotPoImage from '../../../images/yotpo-circle@2x.png';

const { Meta } = Card;

class PostCard extends Component {
  state = {
    downloading: false
  };

  handleDownloadImage = async () => {
    const { postUID, postById, influencer } = this.props;
    const post = postById[postUID];
    this.setState({ downloading: true });
    await postResourceDownloader(post, postUID, influencer.username);
    this.setState({ downloading: false });
  };

  render() {
    const { post, influencer, postUID, session } = this.props;
    const { downloading } = this.state;

    const iconActions = [
      <Spin spinning={downloading}>
        <div className="spin-btn">
          <Button className="download" type="link" onClick={this.handleDownloadImage} size="small">
            <span className="caps-heading">DOWNLOAD</span>
            <IconDownload />
          </Button>
        </div>
      </Spin>
    ];

    if (session.integrations.yotpo) {
      iconActions.push(
        <YotPoBtn type="link" postUIDs={[postUID]} size="small">
          <span className="caps-heading" style={{ marginRight: '8px' }}>
            SEND TO
          </span>
          <img src={YotPoImage} alt="yotpo" />
        </YotPoBtn>
      );
    }

    return (
      <Card
        cover={
          ['video', 'story'].includes(post.type) ? (
            <PostCardVideo postUID={postUID} />
          ) : (
            <PostImage postUID={postUID} withLabel />
          )
        }
        actions={iconActions}
        className="post-card trend-post-card"
      >
        <a
          href={`https://www.instagram.com/${influencer.username}/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Meta
            avatar={<Avatar src={influencer.image} style={{ width: '35px', height: '35px' }} />}
            title={<span title={`@${influencer.username}`}>@{influencer.username}</span>}
            description={influencer.state}
          />
        </a>
        {post.type !== 'story' && <PostStat postUID={postUID} />}
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  postById: state.entities.posts.byId,
  session: state.entities.session.authUserData
});

export default connect(mapStateToProps)(PostCard);
