export const SET_INFLUENCER_LIST = "SET_INFLUENCER_LIST";

const defaultState = [];

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_INFLUENCER_LIST:
      return [...new Set(action.influencers)];

    default:
      return state;
  }
};
