import React from 'react';
import { Table, Button, Avatar } from 'antd';
import { IMPRESSIONS } from 'constants/posts';
import CurrencyFormat from 'react-currency-format';
import { ReactComponent as IconDownload } from 'images/icon-download.svg';
import DemoInfluencerAvatar from './InfluencerAvatar';

const PostAction = ({ postUID, onClickDownloadImage } = {}) => (
  <div>
    <Button
      onClick={() => onClickDownloadImage(postUID)}
      shape="circle"
      style={{ margin: '0 5px 0 5px' }}
    >
      <IconDownload
        style={{
          position: 'relative',
          top: '2px',
          width: '15px',
          height: '15px'
        }}
      />
    </Button>
    <Button icon="link" shape="circle" />
  </div>
);

const ThousandSeparator = num => (
  <CurrencyFormat value={num} displayType="text" thousandSeparator />
);

const PostAvatar = ({ fullResImage }) => <Avatar src={fullResImage} size={50} shape="square" />;

const columns = [
  {
    title: 'INFLUENCER',
    dataIndex: 'influencer',
    key: 'influencer',
    render: influencer => <DemoInfluencerAvatar influencer={influencer} />
  },
  {
    title: 'POST',
    dataIndex: 'post',
    align: 'right',
    key: 'post',
    render: PostAvatar,
    width: 50
  },
  {
    title: 'LIKES',
    dataIndex: 'likes',
    key: 'likes',
    align: 'right',
    render: ThousandSeparator
  },
  {
    title: 'COMMENTS',
    dataIndex: 'comments',
    key: 'comments',
    align: 'right',
    render: ThousandSeparator
  },
  {
    title: 'IMPRESSIONS',
    dataIndex: 'impressions',
    key: 'impressions',
    align: 'right',
    render: ThousandSeparator
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    align: 'right',
    render: PostAction
  }
];

const PostsListLayout = ({ posts, onClickDownloadImage }) => {
  const postDataSource = [];

  Object.keys(posts).forEach(postUID => {
    const { post, influencer } = posts[postUID];

    postDataSource.push({
      key: postUID,
      influencer,
      post,
      likes: post.likes,
      comments: post.comments,
      impressions: post.likes * IMPRESSIONS,
      action: {
        postUID,
        onClickDownloadImage
      }
    });
  });

  return (
    <div>
      <Table
        columns={columns}
        dataSource={postDataSource}
        pagination={false}
        className="trend-table"
      />
    </div>
  );
};

export default PostsListLayout;
