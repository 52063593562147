import React from 'react';
import { Row, Col } from 'antd';
import HeaderText from '../Common/HeaderText';

const StyleGuide = ({ campaign }) => {
  const { styleGuide = {} } = campaign;
  const list = Object.keys(styleGuide).map(uid => {
    const guide = styleGuide[uid];
    return (
      <Col key={uid} className="image" span={6}>
        <img src={guide.url} alt={`style guide ${uid}`} />
      </Col>
    );
  });

  if (list.length < 1) return <div />;

  return (
    <div className="styleguide">
      <HeaderText bold className="size-18px text-black" style={{ marginBottom: '21px' }}>
        Style Guide
      </HeaderText>
      <Row gutter={16}>{list}</Row>
    </div>
  );
};

export default StyleGuide;
