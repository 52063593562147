import moment from 'moment';
import axios from 'axios';
import {
  firebase,
  UserToken,
} from 'lib/Firebase';
import { updateBrandDetails } from 'actions/brands';
import { POST_STATUS } from 'constants/posts';
import { CAMPAIGN_APPLIED_UID_STATUS } from 'constants/campaigns';
import {
  DATE_TIMEZONE_FORMAT,
  MESSAGE_TYPE,
} from 'constants/common';
import Routes from 'api/routes';
import { sendNewMessage } from './messages';

export default ({
  rejectReason,
  influencerUID,
  influencer,
  campaignUID,
  campaign,
  brandUID,
}, callback = null) => async (dispatch) => {
  const { headers } = await UserToken();
  const data = {
    influencerUID,
    campaignUID,
    brandUID,
  };

  axios.post(Routes.returnCredits, data, { headers }).then((response) => {
    const { data: { brandDetails } } = response.data;

    dispatch(updateBrandDetails(brandUID, { ...brandDetails }));

    const influencerCampaignRef = firebase.influencer(influencerUID).child(`partnerships/products/${campaignUID}`);

    influencerCampaignRef.once('value', (campaignSnapshot) => {
      if (!campaignSnapshot) {
        callback(false);
        return;
      }

      influencerCampaignRef.update({ status: POST_STATUS.rejected });

      // remove all posts, base on mobile code
      influencerCampaignRef.update({ posts: null });

      const appliedUID = {
        status: CAMPAIGN_APPLIED_UID_STATUS.rejected,
        isUnread: true,
        timestamp: moment().format(DATE_TIMEZONE_FORMAT),
      };

      firebase.activeProduct(campaignUID).child(`appliedUID/${influencerUID}`).update(appliedUID);

      const text = `Your approval for ${brandDetails.name} has been rescinded. Message from the brand: ${rejectReason}`;

      const sender = {
        uid: brandUID,
        name: brandDetails.name,
        type: MESSAGE_TYPE.brand,
      };

      const recipient = [{
        uid: influencerUID,
        name: influencer.username,
        type: MESSAGE_TYPE.influencer,
      }];

      const partnership = {
        uid: campaignUID,
        name: campaign.name,
        type: MESSAGE_TYPE.product,
      };

      dispatch(sendNewMessage(
        text,
        sender,
        recipient,
        partnership,
        true,
        true,
      ));

      // TODO:
      //  do push notification here

      callback(true);
    });
  }).catch((error) => {
    if (error.response && error.response.data) {
      const {
        status,
        data: message,
      } = error.response;

      callback(false, { status, error: message });
    } else {
      callback(false, { error });
    }
  });
};
