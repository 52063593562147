/* eslint no-console: ["error", { allow: ["log"] }] */
import { firebase } from 'lib/Firebase';
import {
  POST_FETCH_REQUEST,
  POST_RECEIVE_REQUEST,
  ADD_POST,
  ADD_POSTS,
  UPDATE_POST,
  UPDATE_POSTS
} from 'reducers/entities/posts';

export const updatePost = (postUID, post) => ({
  type: UPDATE_POST,
  postUID,
  post
});

export const updatePosts = posts => ({
  type: UPDATE_POSTS,
  posts
});

export const fetchRequest = () => ({ type: POST_FETCH_REQUEST });

export const receiveRequest = () => ({ type: POST_RECEIVE_REQUEST });

export const updatePostDetails = (influencerUID, campaignUID, postUID, post) => {
  const postDetailsRef = firebase
    .influencer(influencerUID)
    .child(`partnerships/products/${campaignUID}/posts/${postUID}/details`);
  return dispatch => {
    postDetailsRef
      .update({ ...post })
      .then(() => {
        dispatch(
          updatePost(postUID, {
            influencerUID,
            postUID,
            ...post
          })
        );
      })
      .catch(e => {
        console.log('Error on updatePostDetails: ', e);
      });
  };
};

export const addPosts = posts => dispatch => {
  dispatch({ type: ADD_POSTS, posts });
};

export const addPost = post => dispatch => {
  dispatch({ type: ADD_POST, post });
};
