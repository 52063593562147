import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Row, Col, Form, Input, message, Button, Modal, PageHeader, Checkbox } from 'antd';
import { firebase } from 'lib/Firebase';
import { receiveBrand, updateBrandDetails, fetchBrand } from 'actions/brands';
import { validateStripeCustomerId } from 'api/subscriptions';

const { confirm } = Modal;

class Edit extends React.Component {
  state = {
    submitting: false,
    isContentAdmin: true
  };

  componentDidMount() {
    const {
      brand,
      dispatch,
      match: {
        params: { id }
      },
      form: { setFieldsValue }
    } = this.props;
    let fetching = false;

    if (!brand) {
      fetching = true;
      firebase.brand(id).once('value', snap => {
        const brandInfo = snap.val();
        const details = brandInfo.details || {};
        const { isAdmin, isContentAdmin } = details;
        this.setState({ isContentAdmin: isContentAdmin || false });
        if (isAdmin) {
          this.setState({ isContentAdmin: false });
          this.setState({ isAdmin });
        }

        dispatch(receiveBrand({ [snap.key]: brandInfo }));

        setFieldsValue({ stripeCustomerId: details.stripeCustomerId });
      });
    } else {
      const details = brand.details || {};
      const { isAdmin, isContentAdmin } = details;
      this.setState({ isContentAdmin: isContentAdmin || false });
      if (isAdmin) {
        this.setState({ isContentAdmin: false });
        this.setState({ isAdmin });
      }
    }

    if (!fetching) {
      const details = brand.details || {};
      setFieldsValue({ stripeCustomerId: details.stripeCustomerId });
    }
  }

  validateStripe = async (rule, value, callback) => {
    const { form } = this.props;
    const { isFieldTouched } = form;
    if (value && isFieldTouched('stripeCustomerId')) {
      try {
        await validateStripeCustomerId(value);
        callback();
      } catch (err) {
        callback(false);
      }
    }
  };

  handleSubmit = () => {
    const {
      form: { validateFieldsAndScroll },
      dispatch,
      match: {
        params: { id }
      },
      history,
      brand
    } = this.props;
    const { isContentAdmin } = this.state;

    this.setState({ submitting: true });

    validateFieldsAndScroll((err, values) => {
      if (!err) {
        firebase
          .brand(id)
          .child('details')
          .update({ ...values, isContentAdmin }, error => {
            if (!error) {
              const details = brand.details || {};
              dispatch(updateBrandDetails(id, { ...values }));
              this.setState({ submitting: false });
              message.success(`Brand - ${details.name} successfully updated`);
              history.push('/admin/brands');

              dispatch(fetchBrand(id));
            }
          });
      }
      if (err) {
        this.setState({ submitting: false });
      }
    });
  };

  showConfirmation = () => {
    confirm({
      title: 'Are you sure you want to update this Brand?',
      onOk: this.handleSubmit
    });
  };

  canAccessAdminContents = () => {
    const { isContentAdmin, isAdmin } = this.state;
    if (!isContentAdmin === true && isAdmin) {
      message.info('Account already has full admin privileges.');
    }
    this.setState({ isContentAdmin: !isContentAdmin });
  };

  render() {
    const { isContentAdmin, isAdmin } = this.state;
    const { brand, history } = this.props;
    if (!brand) {
      return <div />;
    }

    const {
      form: { getFieldDecorator }
    } = this.props;
    const { submitting } = this.state;
    const details = brand.details || {};
    return (
      <>
        <PageHeader title={details.name} onBack={() => history.push('/admin/brands')} />
        <Row>
          <Col>
            <Form>
              <Form.Item label="Stripe Customer Id">
                {getFieldDecorator('stripeCustomerId', {
                  rules: [
                    { required: true, message: 'Required' },
                    {
                      validator: this.validateStripe,
                      message: 'Stripe customer does not exists'
                    }
                  ]
                })(<Input />)}
              </Form.Item>
              <Checkbox onChange={this.canAccessAdminContents} checked={isContentAdmin}>
                Allow access to Admin Content page
              </Checkbox>
              <Form.Item>
                {isAdmin && (
                  <small style={{ color: 'red' }}>
                    Account already has full admin privileges.
                    <br />
                  </small>
                )}
                <Button
                  loading={submitting}
                  onClick={this.showConfirmation}
                  type="primary"
                  disabled={isAdmin}
                >
                  Update
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (
  state,
  {
    match: {
      params: { id }
    }
  }
) => ({
  brand: state.entities.brands.byId[id]
});

export default compose(
  withRouter,
  Form.create({ name: 'admin-brand-edit' }),
  connect(mapStateToProps)
)(Edit);
