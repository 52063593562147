import React, { Component } from "react";
import StackTrace from "stacktrace-js";

const MY_SLACK_WEBHOOK_URL =
  "https://hooks.slack.com/services/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";
const slack = require("slack-notify")(MY_SLACK_WEBHOOK_URL);

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error) {
    const {
      location: { pathname }
    } = window;

    StackTrace.fromError(error).then(err => {
      const env = process.env.REACT_APP_ENV;
      const errDesc = error.stack.split("\n")[0];
      const shortStack = `Error: ${err[0].fileName}:${err[0].lineNumber}`;
      const errorMsg = [errDesc, shortStack].join("\n");

      if (env !== "development") {
        slack.send({
          channel: "#random",
          text: `${env} - ${errorMsg}\n location: ${pathname}`
        });
      } else {
        console.log(errorMsg, pathname);
      }
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return children;
  }
}

export default ErrorBoundary;
