export const LIST_YOTPO_METRICS = 'LIST_YOTPO_METRICS';

export const ADD_LIST_YOTPO_METRICS = 'ADD_LIST_YOTPO_METRICS';

export const YOTPO_BRANDS = 'YOTPO_BRANDS';

export const ADD_YOTPO_API_DATA = 'ADD_YOTPO_API_DATA';

const initialState = {
  byBrandId: {},
  yotpoData: {},
  listYotPos: []
};

const addYotPoApiData = (state, action) => {
  const { yotpoData, yotpoId } = action;
  const data = state.yotpoData[yotpoId] || [];

  return {
    ...state,
    yotpoData: {
      ...state.yotpoData,
      [yotpoId]: [...data, ...yotpoData]
    }
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LIST_YOTPO_METRICS:
      return {
        ...state,
        listYotPos: Object.keys(action.yotpos)
      };

    case YOTPO_BRANDS:
      return {
        ...state,
        byBrandId: {
          ...state.byBrandId,
          ...action.brands
        }
      };

    case ADD_LIST_YOTPO_METRICS:
      return {
        ...state,
        listYotPos: [...new Set(state.listYotPos.concat(Object.keys(action.yotpo)))]
      };

    case ADD_YOTPO_API_DATA:
      return addYotPoApiData(state, action);

    default:
      return state;
  }
};
