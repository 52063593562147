import React, { Component } from 'react';
import { connect } from 'react-redux';
import PaymentForm from 'components/Common/PaymentForm';
import SignOutButton from 'components/Signout';
import { AuthUserContext } from 'lib/Session';

class Subscription extends Component {
  componentWillUpdate(nextProps) {
    const { authUser } = this.props;
    if (!authUser && nextProps.authUser) {
      if (nextProps.authUser.details.stripeActive) {
        nextProps.history.push('/');
      }
    }
  }

  render() {
    const { authUser } = this.props;
    return (
      <div className="mt-8">
        <div className="action-link clickable" align="center">
          <div className="sign-out">
            <AuthUserContext.Consumer>
              {authUser => (authUser && <SignOutButton />)}
            </AuthUserContext.Consumer>
          </div>
        </div>
        { !!authUser && <PaymentForm authUser={authUser} isNewSubscription /> }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.entities.session.authUserData,
});

export default connect(mapStateToProps, null)(Subscription);
