export const SET_ALL_INFLUENCERS_IN_BRAND = 'SET_ALL_INFLUENCERS_IN_BRAND';

export const SET_TOTAL_APPLIED_UIDS = 'SET_TOTAL_APPLIED_UIDS';

const defaultState = {
  allInfluencersInBrand: [],
  campaignInfluencerIds: {},
  totalAppliedUIDs: 0
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_ALL_INFLUENCERS_IN_BRAND:
      return {
        ...state,
        allInfluencersInBrand: action.influencerUIDs,
        campaignInfluencerIds: action.campaignInfluencerUIDs
      };

    case SET_TOTAL_APPLIED_UIDS:
      return {
        ...state,
        totalAppliedUIDs: action.totalAppliedUIDs
      };

    default:
      return state;
  }
};
