import React, { Component } from 'react';
import { Row, Col, Radio, Button } from 'antd';
import { ReactComponent as IconGrid } from 'images/icon-grid.svg';
import IconList from 'images/icon-list@3x.png';
import PostImages from 'lib/Download';

const { Button: RadioButton, Group: RadioGroup } = Radio;

class PostsViewOption extends Component {
  state = {
    downloadAllHD: false
  };

  downloadHDImages = () => {
    const { posts } = this.props;
    const newPosts = {};

    Object.keys(posts).forEach(postUID => {
      const { post } = posts[postUID];
      post.fullResImage = `${window.location.origin}${post.fullResImage}`;

      newPosts[postUID] = {
        details: post
      };
    });

    this.setState(() => ({
      downloadAllHD: true
    }));

    PostImages.downloadAllHD('campaignX', newPosts, () => {
      this.setState(() => ({
        downloadAllHD: false
      }));
    });
  };

  render() {
    const { downloadAllHD } = this.state;
    const { view, onChangeView } = this.props;

    return (
      <Row type="flex" align="middle" style={{ marginTop: '20px' }}>
        <Col span={12} style={{ paddingTop: 10 }}>
          <RadioGroup
            defaultValue={view}
            onChange={e => {
              onChangeView(e.target.value);
            }}
            className="plain-radio"
          >
            <RadioButton value="grid">
              <span style={{ display: 'block', lineHeight: '11px' }}>
                <IconGrid
                  style={{
                    width: '15px',
                    height: '15px',
                    marginRight: '10px',
                    verticalAlign: 'middle'
                  }}
                />
                <span className="size-11px">Grid</span>
              </span>
            </RadioButton>
            <RadioButton value="list">
              <span style={{ display: 'block', lineHeight: '11px' }}>
                <img
                  src={IconList}
                  style={{
                    width: '19px',
                    height: '15px',
                    marginRight: '10px',
                    verticalAlign: 'middle'
                  }}
                  alt="Icon List"
                />
                <span className="size-11px">List</span>
              </span>
            </RadioButton>
          </RadioGroup>
        </Col>
        <Col span={12} className="text-right">
          <Button
            loading={downloadAllHD}
            onClick={this.downloadHDImages}
            size="large"
            className="trend-btn type-firme-iblack size-10px text-black spacing-05px"
          >
            Download HD Creative
          </Button>
        </Col>
      </Row>
    );
  }
}

export default PostsViewOption;
