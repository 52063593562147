/* eslint-disable no-console */
import React from 'react';
import ReactDOM from 'react-dom';
import 'styles/base/antd-custom.less';
import { Provider } from 'react-redux';
import TrendRouters from 'routers/TrendRouters';
import configureStore from 'stores/configureStore';
import { FirebaseContext, firebase } from 'lib/Firebase';
import ErrorBoundary from 'lib/Error/ErrorBoundary';
import { CookiesProvider } from 'react-cookie';
import TagManager from 'react-gtm-module';
import * as serviceWorker from './serviceWorker';

const tagManagerArgs = {
  gtmId: 'GTM-WLTLTWK'
};

TagManager.initialize(tagManagerArgs);

const store = configureStore();

const jsx = (
  <CookiesProvider>
    <Provider store={store}>
      <FirebaseContext.Provider value={firebase}>
        <ErrorBoundary>
          <TrendRouters />
        </ErrorBoundary>
      </FirebaseContext.Provider>
    </Provider>
  </CookiesProvider>
);

ReactDOM.render(jsx, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
