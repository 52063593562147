import React from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'recompose';
import { Row, Col } from 'antd';
import AnalyticsButton from 'components/CampaignDashboard/Banner/AnalyticsButton';
import _ from 'lodash';
import { withAuthUser } from 'lib/Session';

const styles = {
  campaignTitle: {
    fontSize: '26',
    lineHeight: '30px'
  },
  userAvatar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  userInfo: {
    marginLeft: '20px'
  },
  notifIcon: {
    marginLeft: '40px'
  }
};

const CampaignBanner = ({ campaignName, currentCampaign, authUser }) => {
  const brandById = useSelector(state => state.entities.brands.byId);
  // const { brands } = this.props;
  const userBrand = brandById[currentCampaign.brandUID];
  const brand = userBrand && userBrand.details ? userBrand.details : {};
  return (
    <Row styles={styles.bannerWrapper} type="flex" justify="space-between">
      <Col md={12} xs={20} style={{ paddingBottom: '19px' }}>
        <h1 style={styles.campaignTitle} className="mb-0">
          {campaignName}
          {!_.isEmpty(currentCampaign) &&
            !currentCampaign.isListed &&
            brand.status &&
            brand.status !== 'pending' && (
              <span
                className="type-ebold size-8px uppercase ml-2"
                style={{
                  position: 'relative',
                  top: '-8px',
                  color: '#ff5a5a'
                }}
              >
                (Not accepting applications)
              </span>
            )}
        </h1>
      </Col>
      {authUser.uid === currentCampaign.brandUID && (
        <Col md={12} xs={4} style={styles.userAvatar}>
          <div style={styles.notifIcon}>
            <AnalyticsButton />
          </div>
        </Col>
      )}
    </Row>
  );
};

export default compose(withAuthUser)(CampaignBanner);
