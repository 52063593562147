export const SET_SORT_BY = 'SET_SORT_BY';

export const SET_CATEGORY = 'SET_CATEGORY';

export const SET_LOCATION = 'SET_LOCATION';

export const SET_INFLUENCER_TYPE = 'SET_INFLUENCER_TYPE';

export const SET_MULTIPLE_SELECT_APPLICANT = 'SET_MULTIPLE_SELECT_APPLICANT';

const defaultState = {
  sortBy: {
    field: null,
    order: 'ascending'
  }
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_SORT_BY:
      return {
        ...state,
        sortBy: {
          field: action.field,
          order: action.order
        }
      };
    case SET_CATEGORY:
      return {
        ...state,
        category: action.category
      };
    case SET_LOCATION:
      return {
        ...state,
        location: action.location
      };
    case SET_MULTIPLE_SELECT_APPLICANT:
      return {
        ...state,
        multipleSelect: action.multipleSelect
      };
    case SET_INFLUENCER_TYPE:
      return {
        ...state,
        influencerType: action.influencerType
      };
    default:
      return state;
  }
};
