export const ADMIN_ADD_ACTIVE_CAMPAIGN = 'ADMIN_ADD_ACTIVE_CAMPAIGN';
export const ADMIN_LISTEN_ACTIVE_CAMPAIGNS = 'ADMIN_LISTEN_ACTIVE_CAMPAIGNS';
export const ADMIN_UNLISTEN_ACTIVE_CAMPAIGNS = 'ADMIN_UNLISTEN_ACTIVE_CAMPAIGNS';
export const ADMIN_PUSH_ACTIVE_CAMPAIGN = 'ADMIN_PUSH_ACTIVE_CAMPAIGN';
export const ADMIN_UPDATE_LAST_ACTIVE_CAMPAIGN_DOC = 'ADMIN_UPDATE_LAST_ACTIVE_CAMPAIGN_DOC';
export const ADMIN_RESET_CAMPAIGNS = 'ADMIN_RESET_CAMPAIGNS';

let initialState = {
  lastCampaignDoc: null,
  campaigns: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_ADD_ACTIVE_CAMPAIGN:
      return {
        ...state,
        campaigns: [...new Set([...state.campaigns, action.payload])]
      };

    case ADMIN_PUSH_ACTIVE_CAMPAIGN:
      return {
        ...state,
        campaigns: [...new Set([...state.campaigns, action.payload])]
      };
    case ADMIN_LISTEN_ACTIVE_CAMPAIGNS:
      return { ...state, listening: true };
    case ADMIN_UNLISTEN_ACTIVE_CAMPAIGNS:
      return { ...state, listening: false };
    case ADMIN_UPDATE_LAST_ACTIVE_CAMPAIGN_DOC:
      return { ...state, lastCampaignDoc: action.payload };

    case ADMIN_RESET_CAMPAIGNS:
      return { ...state, campaigns: [] };

    default:
      return state;
  }
};
