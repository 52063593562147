import React, { useState } from 'react';
import { Spin, Row, Col } from 'antd';

const useLoading = () => {
  const [loading, setLoading] = useState(false);

  const LoadingScreen = ({ children }) =>
    loading ? (
      <Row type="flex" justify="center" align="middle" style={{ height: '300px' }}>
        <Col span={8} className="text-center">
          <Spin />
        </Col>
      </Row>
    ) : (
      children
    );
  return [LoadingScreen, setLoading];
};

export default useLoading;
