import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

class TrendSelect extends React.Component {
  static getDerivedStateFromProps(nextProps) {
    if ('value' in nextProps) {
      if (nextProps.mode === 'multiple') {
        return { value: nextProps.value || [] };
      }

      return { value: nextProps.value };
    }
    return null;
  }

  constructor(props) {
    super(props);

    let otherProps = {};
    let value;

    const { mode, id } = props;
    if (mode === 'multiple') {
      value = [...(props.value || [])];
    } else {
      value = props.value || '';
    }

    if (id) {
      otherProps = { id };
    }

    this.state = {
      value,
      otherProps
    };
  }

  handleChange = val => {
    const { onChange, clearSelectOption, mode } = this.props;

    let newVal = val;
    if (onChange) {
      if (mode === 'multiple') {
        if (val.includes(clearSelectOption) && val[val.length - 1] === clearSelectOption) {
          newVal = [clearSelectOption];
        } else {
          newVal = val.filter(x => x !== clearSelectOption);
        }
      }
      this.setState({ value: newVal });
      onChange(newVal);
    }
  };

  selectOptions = () => {
    const { options = [] } = this.props;

    return options.map(opt => {
      if (typeof opt === 'object') {
        return (
          <Option key={opt.key} value={opt.key}>
            {opt.value}
          </Option>
        );
      }
      return (
        <Option key={opt} value={opt}>
          {opt}
        </Option>
      );
    });
  };

  render() {
    const { placeholder, children, mode, type, optionFilterProp } = this.props;
    const { value, otherProps } = this.state;

    return (
      <Select
        showArrow={false}
        {...otherProps}
        mode={mode}
        className={type === 'dark' ? 'trend-select-dark' : 'trend-select'}
        dropdownClassName="trend-selected-tags-dropdown"
        onChange={this.handleChange}
        placeholder={placeholder}
        optionFilterProp={optionFilterProp}
        value={value}
      >
        {children || this.selectOptions()}
      </Select>
    );
  }
}

export default TrendSelect;
