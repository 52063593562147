import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { Checkbox, Form, Input, Row, Col, Button, message } from 'antd';
import { firebase } from '../../lib/Firebase';
import * as ROUTES from '../../constants/routes';
import { AC_FIELD_IDS } from '../../constants/ac';
import { DATE_TIMEZONE_FORMAT } from '../../constants/common';
import { UTMFieldNames } from '../../constants/utmFieldNames';

const MobileView = props => {
  const [submitting, setSubmitting] = useState(false);
  const [AcInfo, setAcInfo] = useState(null);
  const [userContactId, setContactId] = useState(null);

  const { form } = props;
  const { getFieldDecorator, validateFieldsAndScroll } = form;
  const history = useHistory();

  const getBusinessInfo = async () => {
    const params = queryString.parse(window.location.search);
    if (params.contactId) {
      const { contactId } = params;
      setContactId(contactId);
      setAcInfo(await firebase.getContactInfoById(contactId));
    }
  };

  useEffect(() => {
    getBusinessInfo();
  }, []);

  const comparePassword = (rules, value, callback) => {
    const password = form.getFieldValue('password');
    if ((value && value.length !== password.length) || value !== password) {
      callback('Confirm Password did not match');
    } else {
      callback();
    }
  };

  const UTMParams = async () => {
    const params = queryString.parse(window.location.search);

    const value = [];
    if (Object.keys(params).length) {
      Object.keys(UTMFieldNames).forEach(key => {
        value.push({
          fieldName: UTMFieldNames[key],
          value: params[key]
        });
      });
    }

    return value;
  };

  const onSubmit = event => {
    event.preventDefault();

    setSubmitting(true);
    validateFieldsAndScroll(async (error, values) => {
      if (!error) {
        const { email, password, fullName } = values;

        let emailTmp = null;
        let newEmail = true;
        if (userContactId) {
          if (AcInfo) {
            const {
              contact: { email: acEmail }
            } = AcInfo.data;
            emailTmp = acEmail;
          }

          newEmail = email !== emailTmp;
        }

        let brandUID;
        try {
          let authUser;

          if (firebase.auth.currentUser) {
            await firebase.auth.currentUser.updatePassword(password);
            await firebase.auth.currentUser.updateEmail(email);
            authUser = firebase.auth.currentUser;
          } else {
            const auth = await firebase.doCreateUserWithEmailAndPassword(email, password);
            authUser = auth.user;
          }

          brandUID = authUser.uid;
          let acInfoData = {};

          await firebase.brandInfo(brandUID).set({
            credits: 0,
            standardCredits: 0,
            email,
            fullName,
            firstCampaign: false,
            status: 'pending',
            isFreeTrial: false,
            createdAt: moment().format(DATE_TIMEZONE_FORMAT),
            createdAtUnix: moment().unix()
          });

          // set utm
          const utmParams = await UTMParams();
          firebase.setUtmTags({ email, utmParams, fullName, userContactId, newEmail });

          if (process.env.NODE_ENV === 'production') {
            window.$FPROM.trackSignup({ email }, () => {
              // eslint-disable-next-line no-console
              console.log('Message Sent');
            });
          }

          if (AcInfo) {
            const {
              contact: { firstName, lastName, orgname, adate },
              fieldValues = []
            } = AcInfo.data;

            const [fieldValue] = fieldValues.filter(
              fv => parseInt(fv.field, 10) === AC_FIELD_IDS.Website
            );

            acInfoData = {
              ownerName: `${firstName} ${lastName}`,
              name: orgname,
              status: 'pending',
              appliedAt: adate ? moment(adate).unix() : null
            };

            if (fieldValue) {
              acInfoData.website = fieldValue.value;
            }
          }

          const businessSnap = await firebase.firestore
            .collection('businesses')
            .where('contactId', '==', String(userContactId))
            .get();

          if (!AcInfo && businessSnap.empty) {
            await firebase.firestore.collection('businesses').add({
              fullName,
              status: 'pending',
              isRegistered: true,
              email,
              website: '',
              createdAtUnix: moment().unix()
            });
          }

          const brandAddonData = {};

          if (!businessSnap.empty) {
            const businessId = businessSnap.docs[0].id;
            const business = businessSnap.docs[0].data();

            brandAddonData.businessId = businessId;

            if (business.appliedAt) {
              brandAddonData.appliedAt = business.appliedAt;
            }

            if (business.approvedAt) {
              brandAddonData.approvedAt = business.approvedAt;
            }

            firebase.firestore
              .collection('businesses')
              .doc(businessId)
              .update({ isRegistered: true, registeredAt: moment().unix() });
          }

          const brandData = {
            ...acInfoData,
            ...brandAddonData
          };

          firebase.brandInfo(brandUID).update(brandData);

          history.push(`${ROUTES.PRE_ONBOARDING_PATH}?first=true`);
        } catch (e) {
          if (firebase.auth.currentUser) {
            await firebase.brand(brandUID).remove();
            await firebase.auth.currentUser.delete();
          }
          console.log(e);
          message.error(e.message);
          setSubmitting(false);
        }
      } else {
        setSubmitting(false);
      }
    });
  };

  return (
    <div style={{ background: 'none', backgroundColor: '#fff', color: '#000 !important' }}>
      <Form layout="vertical" onSubmit={onSubmit}>
        <Row type="flex" justify="center">
          <Col xs={20}>
            <h1 style={{ fontSize: '22px', marginTop: '51px' }}>Create A Free Campaign</h1>
          </Col>
          <Col xs={20}>
            <p className="type-sfpro-regular size-16px text-black" style={{ lineHeight: '24px' }}>
              With Trend there are no contracts, or subscriptions. Create a campaign right now and
              immediately collect qualified applications from creators eager to work for your brand.
            </p>
          </Col>

          <Col xs={20}>
            <Form.Item>
              <label
                className="type-sfpro-regular size-14px"
                style={{ color: '#000', fontWeight: 700 }}
              >
                Full Name
              </label>
              {getFieldDecorator('fullName', {
                rules: [{ required: true, message: 'Full name Required' }]
              })(
                <Input type="text" size="large" className="trend-input" placeholder="Helen Jane" />
              )}
            </Form.Item>
            <Form.Item>
              <label
                className="type-sfpro-regular size-14px"
                style={{ color: '#000', fontWeight: 700 }}
              >
                Work Email*
              </label>
              {getFieldDecorator('email', {
                rules: [
                  { required: true, message: 'Email Required' },
                  { type: 'email', message: 'Email Invalid' }
                ]
              })(
                <Input
                  type="text"
                  size="large"
                  className="trend-input"
                  // disabled={withAuth}
                  placeholder="yourname@email.com"
                />
              )}
            </Form.Item>
            <Form.Item>
              <label
                className="type-sfpro-regular size-14px"
                style={{ color: '#000', fontWeight: 700 }}
              >
                Password
              </label>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Password Required' }]
              })(
                <Input
                  type="password"
                  size="large"
                  className="trend-input"
                  placeholder="********"
                />
              )}
            </Form.Item>

            <Form.Item>
              <label
                className="type-sfpro-regular size-14px"
                style={{ color: '#000', fontWeight: 700 }}
              >
                Confirm Password
              </label>
              {getFieldDecorator('confirmPassword', {
                rules: [
                  {
                    required: true,
                    message: 'Confirm Password Required'
                  },
                  {
                    validator: comparePassword,
                    message: 'Confirm Password did not match'
                  }
                ]
              })(
                <Input
                  type="password"
                  size="large"
                  className="trend-input"
                  placeholder="********"
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={20}>
            <Form.Item className="terms-conditions" style={{ marginBottom: '0px' }}>
              <label
                htmlFor="multipleSelect"
                className="d-block clickable leading-18px"
                style={{
                  paddingBottom: 0,
                  paddingTop: 30,
                  textAlign: 'center'
                }}
              >
                {getFieldDecorator('terms', {
                  valuePropName: 'checked',
                  initialValue: false,
                  rules: [
                    {
                      type: 'boolean',
                      transform: value => value || undefined,
                      required: true,
                      message: 'Must Accept Terms of Service'
                    }
                  ]
                })(
                  <Checkbox
                    id="multipleSelect"
                    className="trend-terms-checkbox-mobile"
                    style={{
                      marginRight: '6px',
                      width: '30px',
                      borderRadius: '4px',
                      backgroundColor: '#fff'
                    }}
                  />
                )}
              </label>
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item className="terms-conditions" style={{ textAlign: 'center' }}>
              <span
                className="type-sfpro-regular size-14px text-white"
                style={{
                  width: '182px',
                  fontWeight: '700',
                  color: '#000',
                  marginBottom: '24px'
                }}
              >
                I accept all statements in the{' '}
                <a href="https://trend.io/terms/" target="tos">
                  Terms of service
                </a>
              </span>
            </Form.Item>
          </Col>
          <Col xs={20}>
            <Col className="text-center">
              <Button
                size="large"
                type="submit"
                className="type-sfpro-regular trend-btn size-20px"
                style={{
                  width: '232px',
                  height: '56px',
                  borderRadius: '32px',
                  textTransform: 'none'
                }}
                loading={submitting}
                onClick={onSubmit}
              >
                Get Started
              </Button>
            </Col>
          </Col>

          <Col xs={20}>
            <p
              className="type-sfpro-regular size-12px"
              style={{
                lineHeight: '17px',
                textAlign: 'center',
                marginTop: '36px',
                marginBottom: '64px'
              }}
            >
              Trend is designed to help brands with physical products and not brands with digital
              goods or services.
            </p>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Form.create()(MobileView);
