import React from 'react';
import { compose } from 'recompose';
import MinLayout from 'components/Common/MinLayout';
import {
  Row,
  Menu,
  Badge,
} from 'antd';
import {
  Route,
  Link,
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom';
import { ReactComponent as IconInfluencerList } from 'images/icon-influencer-list.svg';
import { ReactComponent as IconShipmentsMade } from 'images/icon-shipments-made.svg';
import { ReactComponent as IconPostApproval } from 'images/icon-post-approval.svg';
import * as routes from 'constants/routes';
import DemoInfluencers from './Influencers';
import DemoProductShipments from './ProductShipments';
import DemoPostApproval from './PostApproval';
import DemoHeaderNote from './DemoHeaderNote';

const InfluencersTabLink = ({
  match,
  location,
}) => {
  const defaultSelectedKeys = () => {
    const defaultKeys = 'my_influencers';
    const arrPathname = location.pathname.split('/');

    return [arrPathname[5] || defaultKeys];
  };

  return (
    <div>
      <div style={{ background: '#fcfcfc', borderBottom: '1px solid #e8e8e8' }}>
        <Row type="flex" justify="space-around">
          <MinLayout>
            <Menu mode="horizontal" defaultSelectedKeys={defaultSelectedKeys()} className="trend-menu-sub trend-menu-horizontal">
              <Menu.Item key="my_influencers" style={{ width: '33.33%' }}>
                <Link to={routes.toDemoMyInfluencersPath(match.params.campaignId)}>
                  <span className="ant-menu-icon">
                    <IconInfluencerList className="p-relative" style={{ left: '1px' }} />
                  </span>
                  <span style={{ display: 'inline-block', lineHeight: '1.2', marginTop: '10px' }}>
                    <span className="tabs-value">3</span>
                    <span className="size-9px type-sbold spacing-05px">INFLUENCER LIST</span>
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="product_shipments" style={{ width: '33.33%' }}>
                <Link to={routes.toDemoMyInfluencersProductShipmentsPath(match.params.campaignId)}>
                  <span className="ant-menu-icon">
                    <IconShipmentsMade className="p-relative" style={{ top: '-1px', left: '1px' }} />
                  </span>
                  <span style={{ display: 'inline-block', lineHeight: '1.2', marginTop: '10px' }}>
                    <span className="tabs-value">
                      <Badge count={2} style={{ backgroundColor: '#ffb917' }} />
                      {2}
                      /
                      {3}
                    </span>
                    <span className="size-9px type-sbold spacing-05px">SHIPMENTS MADE</span>
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="post_approval" style={{ width: '33.33%' }}>
                <Link to={routes.toDemoMyInfluencersPostApprovalPath(match.params.campaignId)}>
                  <span className="ant-menu-icon">
                    <IconPostApproval className="p-relative" style={{ top: '-1px', left: '-1px' }} />
                  </span>
                  <span style={{ display: 'inline-block', lineHeight: '1.2', marginTop: '10px' }}>
                    <span className="tabs-value">
                      <Badge count={1} style={{ backgroundColor: '#ffb917' }} />
                      3/6
                    </span>
                    <span className="size-9px type-sbold spacing-05px">POST APPROVAL</span>
                  </span>
                </Link>
              </Menu.Item>
            </Menu>
          </MinLayout>
        </Row>
      </div>
      <Row>
        <DemoHeaderNote />
        <Switch>
          <Route exact path={routes.DEMO_MY_INFLUENCERS_PATH} component={DemoInfluencers} />
          <Route
            exact
            path={routes.DEMO_MY_INFLUENCERS_PRODUCT_SHIPMENTS_PATH}
            component={DemoProductShipments}
          />
          <Route
            path={routes.DEMO_MY_INFLUENCERS_POST_APPROVAL_PATH}
            component={DemoPostApproval}
          />
          <Redirect from={match.url} to={routes.toDemoMyInfluencersPath(match.params.campaignId)} />
        </Switch>
      </Row>
    </div>
  );
};

export default compose(withRouter)(InfluencersTabLink);
