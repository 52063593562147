import * as Promise from 'bluebird';
import { firebase } from 'lib/Firebase';
import {
  RESET_CAMPAIGN_POSTS,
  SET_CAMPAIGN_POSTS,
} from 'reducers/ui/campaignPosts';
import { addPosts } from 'actions/posts';
import { setInfluencers } from 'actions/influencers';

export const resetCampaignPosts = () => ({
  type: RESET_CAMPAIGN_POSTS,
});

export const setCampaignPosts = (campaignUID, postUIDs) => ({
  type: SET_CAMPAIGN_POSTS,
  payload: {
    campaignUID,
    postUIDs,
  },
});

export const fetchCampaignPosts = campaignUID => (dispatch) => {
  const campaignRef = firebase.activeProduct(campaignUID).child('appliedUID').orderByChild('status').equalTo('approved');

  campaignRef.once('value')
    .then((snapshot) => {
      let influencerUIDs = [];

      if (snapshot.exists()) {
        influencerUIDs = Object.keys(snapshot.val());
      }

      return influencerUIDs;
    })
    .then((influencerUIDs) => {
      let influencers = {};

      return Promise.map(influencerUIDs, influencerUID => (
        firebase.influencer(influencerUID)
          .once('value')
          .then((snapshot) => {
            if (snapshot.exists()) {
              const influencer = snapshot.val();
              influencers = { ...influencers, [influencerUID]: influencer };
            }
          })
      ), { concurrency: 10 }).then(() => influencers);
    })
    .then((influencers) => {
      dispatch(setInfluencers(influencers));

      let posts = {};

      Object.keys(influencers).forEach((influencerUID) => {
        const influencerPosts = influencers[influencerUID].partnerships.products[campaignUID].posts;

        Object.keys(influencerPosts).forEach((postUID) => {
          const post = influencerPosts[postUID];
          posts = {
            ...posts,
            [postUID]: {
              ...post,
              influencerUID,
              productUID: campaignUID,
            },
          };
        });
      });

      dispatch(addPosts(posts));
      dispatch(setCampaignPosts(campaignUID, Object.keys(posts)));
    });
};
