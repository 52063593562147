const INFLUENCER_CATEGORIES = [
  'Food',
  'Fashion',
  'Fitness',
  'Technology',
  'Sports',
  'Adventure',
  'Photography',
  'Pet',
  'Other'
];

// eslint-disable-next-line import/prefer-default-export
export { INFLUENCER_CATEGORIES };
