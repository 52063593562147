import React from 'react';
import { ReactComponent as IconBack } from 'images/icon-back-arrow-white.svg';
import { Form, Button, Input, Col, Row } from 'antd';
import ThinContainer from 'components/Common/ThinContainer';
import { ReactComponent as TrendLogoWeb } from 'images/trend-logo-web.svg';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { AuthUserContext } from 'lib/Session';
import SignOutButton from 'components/Signout';

import { fetchLoggedUserData } from 'actions/session';
import { firebase } from '../../lib/Firebase';
import ImageUpload from '../Form/ImageUploader';

class BrandForm extends React.Component {
  state = {
    brandUID: '',
    submitting: false
  };

  componentDidMount() {
    const {
      authUser,
      form: { setFieldsValue },
      dispatch
    } = this.props;

    dispatch(
      fetchLoggedUserData(authUser, res => {
        const { name, storeImage, description } = res.details;
        setFieldsValue({
          name,
          storeImage,
          description
        });
      })
    );

    this.setState({ brandUID: firebase.auth.currentUser.uid });
  }

  handleSubmit = () => {
    this.setState({ submitting: true });
    const {
      form: { validateFieldsAndScroll },
      history
    } = this.props;
    const { brandUID } = this.state;

    validateFieldsAndScroll(async (error, fields) => {
      if (!error) {
        const { storeImage, description, name } = fields;
        await firebase
          .brand(brandUID)
          .child('details')
          .update({
            storeImage,
            name,
            description
          });

        this.setState({ submitting: false });
        history.push('/posts');
      } else {
        this.setState({ submitting: false });
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator }
    } = this.props;
    const { brandUID, submitting } = this.state;

    const goBack = () => {
      const { history } = this.props;
      if (history.location.key) {
        history.goBack();
      } else {
        history.push('/posts');
      }
    };

    return (
      <div style={{ background: '#000' }} id="new-campaign-page" className="first-campaign">
        <div className="action-link clickable" align="center">
          <div className="back">
            <div
              onClick={goBack}
              role="presentation"
              style={{ cursor: 'pointer', color: '#ffb917' }}
            >
              <IconBack />
              <span className="text-white d-block type-sbold size-12px type-sfpro-ibold">Back</span>
            </div>
          </div>
          <div className="sign-out">
            <AuthUserContext.Consumer>
              {authUser => authUser && <SignOutButton />}
            </AuthUserContext.Consumer>
          </div>
        </div>
        <Row type="flex" justify="space-around" style={{ paddingTop: '30px' }}>
          <ThinContainer>
            <div align="center">
              <TrendLogoWeb
                className="logo d-iblock"
                width="70px"
                style={{ marginBottom: '38px' }}
              />
            </div>
            <div style={{ marginTop: '60px' }}>
              <div style={{ marginTop: '59px' }}>
                <h1 style={{ color: '#fff' }} align="center">
                  Show Off Your Brand
                </h1>
                <Row type="flex" justify="center">
                  <Col style={{ maxWidth: '422px' }}>
                    <p
                      className="text-white text-center sub-header"
                      style={{ height: '75px', marginBottom: '10px' }}
                    >
                      {
                        'We’ll need an image for your brand and a quick description of your company.'
                      }
                    </p>
                  </Col>
                </Row>
              </div>
              <Form hideRequiredMark colon={false}>
                <Form.Item style={{ marginBottom: '40px' }}>
                  {getFieldDecorator('storeImage', {
                    rules: [{ required: true, message: 'Required' }]
                  })(
                    <ImageUpload filename={brandUID}>
                      <div className="label">Add any image that represents your brand</div>
                    </ImageUpload>
                  )}
                </Form.Item>
                <Form.Item label="Company Name" style={{ marginBottom: '40px' }}>
                  {getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Required' }]
                  })(
                    <Input
                      type="text"
                      size="large"
                      name="name"
                      className="trend-textarea dark"
                      placeholder="2-3 sentences that decribe your company"
                      value="asdasd"
                    />
                  )}
                </Form.Item>
                <Form.Item label="Company Description" style={{ marginBottom: '64px' }}>
                  {getFieldDecorator('description', {
                    rules: [{ required: true, message: 'Required' }]
                  })(
                    <Input.TextArea
                      className="trend-textarea dark"
                      placeholder="2-3 sentences that decribe your company"
                      style={{ minHeight: '155px' }}
                    />
                  )}
                </Form.Item>
                <div className="text-center">
                  <Button loading={submitting} type="primary" onClick={this.handleSubmit}>
                    Save Brand Info
                  </Button>
                </div>
              </Form>
            </div>
          </ThinContainer>
        </Row>
      </div>
    );
  }
}

export const mapDispatchToProps = dispatch => ({
  dispatch
});

export default compose(
  withRouter,
  withCookies,
  connect(mapDispatchToProps)
)(Form.create({ name: 'brand-form' })(BrandForm));
