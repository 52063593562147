import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Col, Icon, message as m } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import './AdminCampaignModal.less';
import { ReactComponent as CloseIcon } from '../../../images/close-x.svg';
import EmailModal from './EmailModal';
import SelectableInfluencerList from './SelectableInfluencerList';
import Actions from './Actions';

const initialState = {
  campaign: null,
  brand: null,
  influencers: {},
  selectedInfluencerUIDs: []
};

const initialEmailModal = {
  show: false,
  submitting: false
};

const AdminCampaignModal = ({ campaignUID, visible, onClose }) => {
  const [state, setState] = useState(initialState);
  const [emailModal, setEmailModal] = useState(initialEmailModal);
  const { campaign, brand, influencers, selectedInfluencerUIDs } = state;

  const campaigns = useSelector(s => s.entities.campaigns);
  const brands = useSelector(s => s.entities.brands);

  useEffect(() => {
    if (campaignUID) {
      const newCampaign = campaigns.byId[campaignUID];
      const newBrand = brands.byId[newCampaign.brandUID].details;
      let approvedInfluencers = {};
      Object.keys(newCampaign.appliedUID || {}).forEach(infUID => {
        const application = newCampaign.appliedUID[infUID];
        if (['approved', 'completed', 'shipped', 'active'].includes(application.status)) {
          approvedInfluencers = { ...approvedInfluencers, [infUID]: application };
        }
      });

      setState(prevState => ({
        ...prevState,
        brand: newBrand,
        influencers: approvedInfluencers,
        campaign: newCampaign
      }));
    }
  }, [campaignUID]);

  function handleSelectChange(influencerUIDs) {
    setState(s => ({ ...s, selectedInfluencerUIDs: influencerUIDs }));
  }

  function handleEmailClick() {
    setEmailModal(s => ({ ...s, show: true }));
  }

  function handleMessageSent() {
    setState(s => ({ ...s, selectedInfluencerUIDs: [] }));
    setEmailModal(s => ({ ...s, show: false }));
    m.success('Message successfully sent!');
  }

  function handleEmailBack() {
    setEmailModal(s => ({ ...s, show: false }));
  }

  function handleClose() {
    setState(s => ({ ...s, selectedInfluencerUIDs: [] }));
    onClose();
  }

  function handleEmailClose() {
    setEmailModal(s => ({ ...s, show: false }));
    setState(s => ({ ...s, selectedInfluencerUIDs: [] }));
    onClose();
  }

  if (!state.campaign) {
    return <div />;
  }

  const formatedLastPurchaseDate = moment(brand.lastPurchaseDate * 1000).format('MM/DD/YY');
  const formatedLastLoginDate = moment(brand.lastLoginDate * 1000).format('MM/DD/YY');

  const { postOverdueCount, postCount, numberOfPosts } = state.campaign;
  const { credits = 0, standardCredits = 0, addedStandardCredits = 0, addedVipCredits = 0 } = brand;
  const totalCredits = credits + standardCredits + addedVipCredits + addedStandardCredits;

  return (
    <>
      <Modal
        className="admin-campaign-email-modal"
        visible={!emailModal.show && visible}
        closable={false}
        footer={false}
      >
        <div className="modal-header">
          <h1>{campaign.name} </h1>
          <div className="close">
            <Button onClick={handleClose} type="link">
              <Icon component={CloseIcon} />
            </Button>
          </div>
          <Row className="campaign-details">
            <Col span={18}>
              <Row>
                <Col span={6} className="brand-name">
                  {brand.name}
                </Col>
                <Col span={6} className="brand-credits">
                  Credits: {totalCredits}
                </Col>
                <Col span={12} className="brand-lastpurchasedate">
                  Last Purchase Date: {formatedLastPurchaseDate}
                </Col>
              </Row>
              <Row>
                <Col span={10} className="brand-email">
                  {brand.authEmail}
                </Col>
                <Col span={14} className="brand-lastlogindate">
                  Last Login Date: {formatedLastLoginDate}
                </Col>
              </Row>
            </Col>
            <Col span={6} className="action text-right">
              <Actions productUID={campaignUID} brandUID={campaign.brandUID} />
            </Col>
          </Row>
        </div>
        <div className="modal-body">
          <div className="influencer-list">
            <Row className="title">
              <Col className="name" span={12}>
                List of Influencers
              </Col>
              <Col span={12} className="overdue-count">
                Overdue Posts: {postOverdueCount} / {postCount}
              </Col>
            </Row>
            <SelectableInfluencerList
              influencers={influencers}
              onSelectChange={handleSelectChange}
              value={selectedInfluencerUIDs}
              numberOfPosts={numberOfPosts}
            />
          </div>
        </div>
        <div className="modal-footer">
          <Button type="primary" block onClick={handleEmailClick}>
            Compose Email
            <br />
            <span>Email List: {selectedInfluencerUIDs.length}</span>
          </Button>
        </div>
      </Modal>
      <EmailModal
        visible={emailModal.show}
        influencerUIDs={selectedInfluencerUIDs}
        onSuccess={handleMessageSent}
        onBack={handleEmailBack}
        onClose={handleEmailClose}
      />
    </>
  );
};

export default AdminCampaignModal;
