const development = {
  SHIP_ENGINE_API_KEY: "4WM9mXZ2NJvfyX7EWnAX14E2brOo3JJl4faCB+/WmLY",
  STRIPE_PUB_KEY: "pk_test_UmEuw6bdx2d9LbuPlM3Lydty",
  REACT_APP_API_KEY: "AIzaSyDGQMahcz2yEqegZ181T9tc1JuJnuOOVLs",
  REACT_APP_AUTH_DOMAIN: "localhost",
  REACT_APP_DATABASE_URL: "https://development-verbv2.firebaseio.com",
  REACT_APP_PROJECT_ID: "development-verbv2",
  REACT_APP_STORAGE_BUCKET: "development-verbv2.appspot.com",
  REACT_APP_MESSAGING_SENDER_ID: "861962464857",
  REACT_APP_FUNCTION_API_URL: "http://localhost:5000/development-verbv2/us-central1",
  REACT_APP_URL: "http://localhost:3000"
};

const staging = {
  SHIP_ENGINE_API_KEY: '4WM9mXZ2NJvfyX7EWnAX14E2brOo3JJl4faCB+/WmLY',
  STRIPE_PUB_KEY: 'pk_test_UmEuw6bdx2d9LbuPlM3Lydty',
  REACT_APP_API_KEY: 'AIzaSyDGQMahcz2yEqegZ181T9tc1JuJnuOOVLs',
  REACT_APP_AUTH_DOMAIN: 'development-verbv2.firebaseio.com',
  REACT_APP_DATABASE_URL: 'https://development-verbv2.firebaseio.com',
  REACT_APP_PROJECT_ID: 'development-verbv2',
  REACT_APP_STORAGE_BUCKET: 'development-verbv2.appspot.com',
  REACT_APP_MESSAGING_SENDER_ID: '861962464857',
  REACT_APP_FUNCTION_API_URL: 'https://us-central1-development-verbv2.cloudfunctions.net',
  REACT_APP_URL: 'https://development-verbv2.firebaseapp.com',
  REACT_VAPID_KEY: 'BN7ePArKle6c1OqazPGHQ_rkobeW4btnyRdQjzl6ZSYLdrMRRPijYrLBsdzesOCjTqsRMcnmI6GqXFgyu0dAhZQ'
};

const production = {
  STRIPE_PUB_KEY: "pk_live_6dfXnbjK52e0xlgygI0oA1vi",
  SHIP_ENGINE_API_KEY: "4WM9mXZ2NJvfyX7EWnAX14E2brOo3JJl4faCB+/WmLY",
  REACT_APP_API_KEY: "AIzaSyBaldUwxGD5K1Aj6lko9TmdcyasmRgbYDM",
  REACT_APP_AUTH_DOMAIN: "verbv2.firebaseapp.com",
  REACT_APP_DATABASE_URL: "https://verbv2.firebaseio.com",
  REACT_APP_PROJECT_ID: "verbv2",
  REACT_APP_STORAGE_BUCKET: "verbv2.appspot.com",
  REACT_APP_MESSAGING_SENDER_ID: "480689852664",
  REACT_APP_FUNCTION_API_URL: "https://us-central1-verbv2.cloudfunctions.net",
  REACT_VAPID_KEY: 'BOuyPIKbuhBbGsixU-Rt_JRPyZDjTDW3mY3QUPNKQSpNvgbxq8rBDW1FDA_U03-S4qSS070vEVkdgtoNe4w_G5s'
};

const demo = {
  SHIP_ENGINE_API_KEY: '4WM9mXZ2NJvfyX7EWnAX14E2brOo3JJl4faCB+/WmLY',
  STRIPE_PUB_KEY: 'pk_test_UmEuw6bdx2d9LbuPlM3Lydty',
  REACT_APP_API_KEY: 'AIzaSyDvMRNB8SOcNMnfireE2I1CFxypdc4PpBs',
  REACT_APP_AUTH_DOMAIN: 'trend-demo.firebaseapp.com',
  REACT_APP_DATABASE_URL: 'https://trend-demo.firebaseio.com',
  REACT_APP_PROJECT_ID: 'trend-demo',
  REACT_APP_STORAGE_BUCKET: 'trend-demo.appspot.com',
  REACT_APP_MESSAGING_SENDER_ID: '167714022300',
  REACT_APP_FUNCTION_API_URL: 'https://us-central1-trend-demo.cloudfunctions.net',
  REACT_APP_URL: 'http://trend-demo.firebaseapp.com'
};

let config = {};

switch (process.env.REACT_APP_ENV) {
  case "development":
    config = development;
    break;
  case "staging":
    config = staging;
    break;
  case "production":
    config = production;
    break;

  case "demo":
      config = demo;
      break;
  default:
    Error("Not a valid environment");
}

export default {
  NODE_PATH: "src",
  REACT_APP_FUNCTION_API_PATH: "/api",
  ...config
};
