import {
  SET_ADMIN_BRAND_IDS,
  SET_ADMIN_BRAND_SEARCHING
} from "reducers/ui/adminBrandsPage";

export const setAdminBrandIds = (brandIds = [], lastSnapshot) => ({
  type: SET_ADMIN_BRAND_IDS,
  payload: {
    brandIds,
    lastSnapshot
  }
});

export const setAdminBrandSearching = state => ({
  type: SET_ADMIN_BRAND_SEARCHING,
  payload: state
});
