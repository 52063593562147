import React from 'react';
import { Row, Col } from 'antd';
import MacImage from '../../images/macimage.png';

const MobileView = () => {
  return (
    <Row type="flex" justify="center" id="mobile-view-page">
      <Col xs={20}>
        <div className="text-center image">
          <img src={MacImage} alt="mobile-view" />
        </div>
        <h1>
          We received your application. Our platform is designed for desktop use only.
          <div className="sub-header">
            If your brand is selected to join the network, you’ll recieve an email letting you know
            you got in. Expect a response within 24 hours.
          </div>
        </h1>
      </Col>
    </Row>
  );
};

export default MobileView;
