import React from 'react';
import moment from 'moment';
import Countdown from 'react-countdown';
import { useHistory } from 'react-router-dom';

const AddCreditNotification = ({ unixDate, expired }) => {
  const hoursLater = moment(unixDate * 1000).add(24, 'hours');
  const history = useHistory();
  const Notification = () => {
    if (!expired) {
      return (
        <p>
          Add creator credits before your listing is removed{' '}
          <span className="countdown">
            <Countdown date={hoursLater} daysInHours />
          </span>
        </p>
      );
    }
    return <p>Add creator credits to accept creators and set your campaign live</p>;
  };

  return (
    <div
      className={`add-credit-message ${expired && 'expired'}`}
      role="presentation"
      onClick={() => history.push('/credits/addCredits')}
    >
      {[1, 2, 3, 4, 6, 7, 8].map(id => (
        <Notification key={id} />
      ))}
    </div>
  );
};

export default AddCreditNotification;
