import Promise from 'bluebird';
import { CAMPAIGN_APPLIED_UID_STATUS } from '../constants/campaigns';
import { firebase } from '../lib/Firebase';
import { PARTNERSHIP_STATUS } from '../constants/partnerships';

export const fetchBrandCampaignsApi = brandId => {
  const campaignBrandRef = firebase
    .activeProducts()
    .orderByChild('brandUID')
    .equalTo(brandId);
  return campaignBrandRef.once('value').then(snapshot => {
    let campaigns = {};

    if (snapshot.exists()) {
      campaigns = snapshot.val();
    }

    return campaigns;
  });
};

export const fetchBrandCompletedCampaignsApi = (brandUID, onComplete) => {
  const campaignBrandRef = firebase
    .completedProducts()
    .orderByChild('brandUID')
    .equalTo(brandUID);
  return campaignBrandRef.once('value').then(snapshot => {
    let campaigns = {};

    if (snapshot.exists()) {
      campaigns = snapshot.val();
    }

    onComplete(campaigns);
  });
};

export const fetchInfluencerAppliedCampaignApi = (influencerUID, campaignUID) => {
  const influencerCampaignRef = firebase
    .influencer(influencerUID)
    .child('partnerships')
    .child(`products/${campaignUID}`);
  return influencerCampaignRef.once('value').then(snapshot => {
    let appliedCampaign = {};

    if (snapshot.exists()) {
      appliedCampaign = snapshot.val();
    }

    return appliedCampaign;
  });
};

export const checkIfAllApprovedAreCompleted = async campaignUID => {
  const productSnap = await firebase.activeProduct(campaignUID).once('value');

  const product = productSnap.val();
  const { appliedUID = {} } = product;

  const pendingUIDs = Object.keys(appliedUID).filter(
    uid => appliedUID[uid].status === CAMPAIGN_APPLIED_UID_STATUS.submitted
  );

  if (pendingUIDs.length > 0) {
    throw new Error('Campaign has still have a pending partnership');
  }

  const approvedUIDs = Object.keys(appliedUID).filter(
    x => appliedUID[x].status === CAMPAIGN_APPLIED_UID_STATUS.approved
  );

  if (approvedUIDs.length > 0) {
    try {
      await Promise.map(approvedUIDs, async influencerUID => {
        const partnershipSnap = await firebase
          .influencer(influencerUID)
          .child('partnerships/products')
          .child(campaignUID)
          .once('value');
        const partnership = partnershipSnap.val();

        if (partnership.status !== PARTNERSHIP_STATUS.COMPLETED) {
          throw new Error('Campaign has still have a pending partnership');
        }
      });
    } catch (e) {
      throw new Error(e);
    }
  }

  return true;
};

export const fetchCampaignActiveOrComplete = (campaignUID, callback) => {
  firebase
    .activeProduct(campaignUID)
    .once('value', activeSnapshot => {
      const activeCampaign = activeSnapshot.val();
      if (activeCampaign) {
        callback(activeCampaign);
      } else {
        firebase
          .completedProduct(campaignUID)
          .once('value', completeSnapshot => {
            callback(completeSnapshot.val());
          })
          .catch(error => {
            callback(null, { error });
          });
      }
    })
    .catch(error => {
      callback(null, { error });
    });
};

export const onFetchCampaignActiveOrComplete = (campaignUID, callback) => {
  firebase.activeProduct(campaignUID).on('value', snapshot => {
    if (snapshot.exists()) {
      callback(snapshot.val());
    } else {
      firebase.completedProduct(campaignUID).on('value', comSnapshot => {
        callback(comSnapshot.val());
      });
    }
  });
};
