import React, { Component } from 'react';
import { Row } from 'antd';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Overview from 'components/CampaignDashboard/Overviews';
import Applications from 'components/CampaignDashboard/Applications';
import Influencers from 'components/CampaignDashboard/Influencers';
import Messages from 'components/CampaignDashboard/Messages';
import DemoMessages from 'components/Demo/Messages';
import DemoInfluencersTabLink from 'components/Demo/InfluencersTabLink';
import DemoPosts from 'components/Demo/Posts';
import Posts from 'components/CampaignDashboard/Posts';
import TabLinks from 'components/CampaignDashboard/TabContent/TabLinks';
import CampaignBanner from 'components/CampaignDashboard/Banner/CampaignBanner';
import { withFirebase } from 'lib/Firebase';
import { withAuthUser } from 'lib/Session';
import * as routes from 'constants/routes';
import MinLayout from 'components/Common/MinLayout';
import WithBrand from 'components/Helpers/WithBrand';

class CampaignContent extends Component {
  defaultSelectedKeys = () => {
    const { location } = this.props;
    const defaultKey = 'summary';
    const arrPathname = location.pathname.split('/');
    const pathIndex = location.pathname.includes('demo') ? 4 : 3;

    return [arrPathname[pathIndex] || defaultKey];
  };

  render() {
    const {
      match,
      campaigns,
      campaignDashboard: {
        postToApproveCount = 0,
        needsShippingCount = 0,
        newMessagesCount = 0,
        appliedInfluencerCount = 0
      },
      currentCampaign,
      authUser: { uid: userUID },
      brand: { details: brandDetails },
      match: { params }
    } = this.props;
    const { campaignId } = params;

    let campaign = campaigns[campaignId] || currentCampaign || {};
    if (match.params.campaignId === 'example') {
      campaign = { name: 'Welcome to Trend', isListed: true };
    }
    return (
      <div className="campaign-content">
        <div className="nav-header">
          <Row type="flex" justify="space-around">
            <MinLayout>
              <CampaignBanner campaignName={campaign.name} currentCampaign={campaign} />
            </MinLayout>
          </Row>
          <div style={{ borderBottom: '1px solid #dbdfe8' }}>
            <Row type="flex" justify="space-around">
              <MinLayout>
                {(userUID === campaign.brandUID || match.params.campaignId === 'example') && (
                  <TabLinks
                    matchUrl={match.url}
                    defaultSelectedKeys={this.defaultSelectedKeys()}
                    postToApproveCount={postToApproveCount}
                    needsShippingCount={needsShippingCount}
                    appliedInfluencerCount={appliedInfluencerCount}
                    newMessagesCount={newMessagesCount}
                    brandDetails={brandDetails}
                  />
                )}
              </MinLayout>
            </Row>
          </div>
        </div>
        <Row>
          {/* demo path, should be priority */}
          <Route path={routes.DEMO_MY_INFLUENCERS_PATH} component={DemoInfluencersTabLink} />
          <Route path={routes.DEMO_POSTS_PATH} component={DemoPosts} />
          <Route path={routes.DEMO_MESSAGES_PATH} component={DemoMessages} />

          <Route path={routes.CAMPAIGN_SUMMARY_PATH} component={Overview} />
          <Route path="/campaigns/:campaignId/applications" component={Applications} />
          <Route path="/campaigns/:campaignId/my_influencers" component={Influencers} />
          <Route path="/campaigns/:campaignId/posts" component={Posts} />
          <Route path="/campaigns/:campaignId/messages" component={Messages} />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, { match: { params } }) => ({
  currentCampaign: state.ui.campaignDashboard.currentCampaign,
  campaignDashboard: state.ui.campaignDashboard.byCampaignId[params.campaignId],
  influencers: state.entities.influencers.byId,
  campaigns: state.entities.campaigns.byId
});

export default compose(
  withRouter,
  WithBrand,
  withFirebase,
  withAuthUser,
  connect(mapStateToProps)
)(CampaignContent);
