import React from 'react';
import { Modal, Button, Form, Input, message as Message, Icon, Popover } from 'antd';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Promise from 'bluebird';
import { firebase } from 'lib/Firebase';
import moment from 'moment';
import { ReactComponent as CancelIcon } from '../../../images/complete-campaign-icon-dark.svg';
import GroupIcon from '../../../images/shoutout.png';
import PencilIcon from '../../../images/pencil.png';

const { TextArea } = Input;

const DynamicVariablesContent = (
  <>
    <ul>
      <li>[name]</li>
      <li>[username]</li>
    </ul>
    <h4>How to use:</h4>
    <p>
      Hi [name], <br />
      thank you for applying.
      <br />
      ======================== <br />
      Hi James, <br />
      thank you for applying. <br />
    </p>
  </>
);

class MultipleMessageModal extends React.Component {
  state = {
    showModal: false,
    submitting: false,
    enableSelection: false
  };

  handleOk = () => {
    const {
      form: { validateFields, resetFields },
      influencerById,
      messageById,
      brandUID,
      messageUIDs
    } = this.props;

    this.setState({ submitting: true });

    validateFields((err, fields) => {
      if (!err) {
        setTimeout(() => {
          Promise.map(messageUIDs, id => {
            const message = messageById[id];
            const [influencerUserUID] = Object.keys(message.users).filter(
              userUID => message.users[userUID].type === 'influencer'
            );
            const influencer = influencerById[influencerUserUID];
            const text = fields.message
              .replace('[username]', `@${influencer.details.username}`)
              .replace('[name]', influencer.details.name);
            const m = firebase
              .message(id)
              .child('messages')
              .push();
            return m.update({
              text,
              sender: brandUID,
              timestamp: moment().format('YYYY-MM-DD HH:mm:ss ZZ')
            });
          }).then(() => {
            resetFields(['message']);
            Message.success('Messages successfully sent!', 3);
            this.setState({ submitting: false, showModal: false });
          });
        }, 1000);
      } else {
        this.setState({ submitting: false });
      }
    });
  };

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  handleShowModal = () => {
    this.setState({ showModal: true });
  };

  handleToggleSelection = () => {
    const { enableSelection } = this.state;
    const { onSelection } = this.props;
    this.setState({ enableSelection: !enableSelection });
    onSelection(!enableSelection);
  };

  render() {
    const { showModal, submitting, enableSelection } = this.state;
    const {
      form: { getFieldDecorator },
      messageUIDs = []
    } = this.props;

    return (
      <>
        <div className="actions">
          <Button
            className={`message ${enableSelection ? 'hide' : ''}`}
            type="default"
            onClick={this.handleToggleSelection}
          >
            <img src={GroupIcon} alt="icon" />
            Group Message
          </Button>
          <Button
            className={`cancel ${!enableSelection ? 'hide' : ''}`}
            onClick={this.handleToggleSelection}
          >
            <Icon component={CancelIcon} />
            Cancel
          </Button>
          <Button
            className={`send ${!enableSelection ? 'hide' : ''}`}
            onClick={this.handleShowModal}
            disabled={messageUIDs.length < 1}
          >
            <img src={PencilIcon} alt="icon" />
            Write Message
          </Button>
          <div className={`selected-count ${!enableSelection ? 'hide' : ''}`}>
            {messageUIDs.length} INFLUENCERS SELECTED
          </div>
        </div>

        <Modal
          title="Send Messages"
          visible={showModal}
          confirmLoading={submitting}
          onOk={this.handleOk}
          okText="Send"
          onCancel={this.handleCancel}
        >
          <Form>
            <Form.Item
              label="Message"
              help={(
                <Popover content={DynamicVariablesContent} title="Variables">
                  <Button size="small">
                    Variables
                    <Icon type="question-circle" />
                  </Button>
                </Popover>
)}
            >
              {getFieldDecorator('message', {
                rules: [{ required: true, message: 'Required' }]
              })(<TextArea style={{ height: '300px' }} />)}
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  influencerById: state.entities.influencers.byId,
  messageById: state.entities.messages.byId,
  brandUID: state.entities.session.authUserData.uid
});

export default compose(
  connect(mapStateToProps),
  Form.create({ name: 'message' })
)(MultipleMessageModal);
