import React from 'react';
import { Icon, Button, Row, Col } from 'antd';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { ReactComponent as StoryIcon } from '../../../images/ig-stories-filter.svg';
import { ReactComponent as VideoIcon } from '../../../images/video-post-filter.svg';
import { ReactComponent as ImageIcon } from '../../../images/image-post-filter.svg';

export const usePostTypeFilter = () => {
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { type: filter = 'all' } = query;

  let types = [undefined, 'image', 'video', 'story'];
  if (filter === 'images') {
    types = [undefined, 'image'];
  } else if (filter === 'videos') {
    types = ['video'];
  } else if (filter === 'stories') {
    types = ['story'];
  }

  return types;
};

const PostTypeFilter = () => {
  const { campaignId } = useParams();
  const history = useHistory();
  const [postUIDs = [], posts = {}] = useSelector(state => [
    state.ui.campaignPosts[campaignId],
    state.entities.posts.byId
  ]);

  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { type } = query;

  const filters = {
    all: 0,
    images: 0,
    videos: 0,
    stories: 0
  };

  postUIDs.forEach(postUID => {
    const { type: postType = 'image' } = posts[postUID];

    switch (postType) {
      case 'image':
        filters.images += 1;
        break;
      case 'video':
        filters.videos += 1;
        break;
      case 'story':
        filters.stories += 1;
        break;
      default:
        break;
    }

    filters.all += 1;
  });

  function goTo(postType) {
    const q = new URLSearchParams(history.location.search);
    q.set('type', postType);
    history.replace({ ...history.location, search: q.toString() });
  }

  return (
    <>
      <Col
        span={6}
        className={`post-filter post-filter-all ${[undefined, 'all'].includes(type) &&
          'post-filter-selected'}`}
      >
        <Button type="link" onClick={() => goTo('all')} block>
          <Row type="flex" align="middle">
            <span className="post-filter-icon">ALL</span>
            <div className="post-filter-details">
              <div className="post-filter-count">{filters.all}</div>
              <div className="post-filter-label">ALL POSTS</div>
            </div>
          </Row>
        </Button>
      </Col>
      <Col
        span={6}
        className={`post-filter post-filter-images ${type === 'images' && 'post-filter-selected'}`}
      >
        <Button type="link" onClick={() => goTo('images')} block>
          <Row type="flex" align="middle">
            <span className="post-filter-icon">
              <Icon component={ImageIcon} />
            </span>
            <div className="post-filter-details">
              <div className="post-filter-count">{filters.images}</div>
              <div className="post-filter-label">IMAGE POSTS</div>
            </div>
          </Row>
        </Button>
      </Col>
      <Col
        span={6}
        className={`post-filter post-filter-videos ${type === 'videos' && 'post-filter-selected'}`}
      >
        <Button type="link" onClick={() => goTo('videos')} block>
          <Row type="flex" align="middle">
            <span className="post-filter-icon">
              <Icon component={VideoIcon} />
            </span>
            <div className="post-filter-details">
              <div className="post-filter-count">{filters.videos}</div>
              <div className="post-filter-label">VIDEO POSTS</div>
            </div>
          </Row>
        </Button>
      </Col>
      <Col
        span={6}
        className={`post-filter post-filter-stories ${type === 'stories' &&
          'post-filter-selected'}`}
      >
        <Button type="link" onClick={() => goTo('stories')} block>
          <Row type="flex" align="middle">
            <span className="post-filter-icon">
              <Icon component={StoryIcon} />
            </span>
            <div className="post-filter-details">
              <div className="post-filter-count">{filters.stories}</div>
              <div className="post-filter-label">STORY POSTS</div>
            </div>
          </Row>
        </Button>
      </Col>
    </>
  );
};

export default PostTypeFilter;
