import React from 'react';
import { connect } from 'react-redux';
import { Progress } from 'antd';
import numeral from 'numeral';

const Stats = ({ reviews = {} }) => {
  const influencerReview = reviews;
  const { avgContentQuality = 5, avgAttitudeProfessionalism = 5 } = influencerReview;
  return (
    <div className="influencer-review-stats">
      <div className="review-stat">
        <label className="review-stat-label">Content Quality</label>
        <div className="review-stat-value">{numeral(avgContentQuality).format('0.0')}</div>
        <div className="review-stat-rate">
          <Progress
            strokeColor={{
              to: '#15f590',
              from: '#5f2b90'
            }}
            percent={(avgContentQuality / 5) * 100}
            showInfo={false}
            strokeWidth={4}
          />
        </div>
      </div>
      <div className="review-stat">
        <label className="review-stat-label">Attitude and professionalism</label>
        <div className="review-stat-value">{numeral(avgAttitudeProfessionalism).format('0.0')}</div>
        <div className="review-stat-rate">
          <Progress
            strokeColor={{
              to: '#15f590',
              from: '#5f2b90'
            }}
            showInfo={false}
            percent={(avgAttitudeProfessionalism / 5) * 100}
            strokeWidth={4}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, { influencerUID }) => ({
  influencer: state.entities.influencers.byId[influencerUID]
});

export default connect(mapStateToProps)(Stats);
