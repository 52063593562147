import _ from 'lodash';

export const CAMPAIGN_LIST_CATEGORY = 'CAMPAIGN_LIST_CATEGORY';
export const CAMPAIGN_LIST_MY_CAMPAIGN = 'CAMPAIGN_LIST_MY_CAMPAIGN';
export const CAMPAIGN_LIST_BRAND_UID = 'CAMPAIGN_LIST_BRAND_UID';
export const CLEAR_CAMPAIGN_LIST = 'CLEAR_CAMPAIGN_LIST';
export const SET_CAMPAIGN_LIST = 'SET_CAMPAIGN_LIST';

const defaultState = {
  list: [],
  params: {
    category: '',
    brandUID: null,
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_CAMPAIGN_LIST:
      return {
        ...state,
        list: _.uniq([
          ...state.list,
          ...action.payload,
        ]),
      };

    case CAMPAIGN_LIST_CATEGORY:
      return {
        ...state,
        params: {
          ...state.params,
          category: action.category,
        },
      };

    case CAMPAIGN_LIST_MY_CAMPAIGN:
      return {
        ...state,
        myCampaign: action.myCampaign,
      };

    case CAMPAIGN_LIST_BRAND_UID:
      return {
        ...state,
        params: {
          ...state.params,
          brandUID: action.brandUID,
        },
      };

    case CLEAR_CAMPAIGN_LIST:
      return {
        ...state,
        list: [],
      };

    default:
      return state;
  }
};
