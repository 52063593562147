import React from 'react';
import { Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CampaignMenu from './CampaignMenu';

const CampaignSubMenu = ({ children, showCampaigns }) => {
  const history = useHistory();
  const campaigns = useSelector(state => state.entities.campaigns.byId);
  const ownedCampaigns = useSelector(state => state.ui.sideNav.ownedCampaigns);

  const path = history.location.pathname.split('/');
  const id = `${path[1]}/${path[2] || ''}`;

  let campaignMenus = [];
  if (showCampaigns) {
    campaignMenus = ownedCampaigns.map(campaignId => (
      <CampaignMenu
        key={`campaigns/${campaignId}`}
        image={campaigns[campaignId].image}
        title={campaigns[campaignId].name}
        campaignId={campaignId}
      />
    ));
  }

  return (
    <div>
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[id]}
        defaultOpenKeys={['campaigns']}
        className={['trend-menu-inline campaign-menu']}
      >
        {campaignMenus}
        {children}
      </Menu>
    </div>
  );
};

export default CampaignSubMenu;
