import React, { useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBrand } from '../../../actions/brands';

const ActiveCampaign = ({ influencerUID, campaign }) => {
  const dispatch = useDispatch();
  const brand = useSelector(state => state.entities.brands.byId[campaign.brandUID] || {});

  const partnership = campaign.appliedUID[influencerUID];
  const postCount = partnership.posts ? Object.keys(partnership.posts).length : 0;
  let { dueDate, activeAt, shippedAt } = partnership;
  dueDate = dueDate && moment(dueDate);
  activeAt = activeAt && moment(activeAt);
  shippedAt = shippedAt && moment(shippedAt);
  const currentDate = moment();
  const twoDaysAgo = moment().add(2, 'days');
  const approvedCount = partnership.posts
    ? Object.keys(partnership.posts).filter(x => partnership.posts[x].status === 'approved').length
    : 0;

  useEffect(() => {
    if (Object.keys(brand).length === 0 && campaign.brandUID) {
      fetchBrand(campaign.brandUID)(dispatch);
    }
  }, [dispatch, brand, campaign]);

  let shipmentStatus = 'danger';
  if (activeAt) {
    shipmentStatus = 'success';
  } else if (shippedAt) {
    shipmentStatus = 'warning';
  }

  let postStatus = 'success';
  if (dueDate && dueDate < currentDate) {
    postStatus = 'danger';
  } else if (dueDate && dueDate > currentDate && dueDate < twoDaysAgo) {
    postStatus = 'warning';
  }

  return (
    <div className="campaign-details" key={campaign.key}>
      {brand.details && brand.details.name && (
        <div className="campaign-details-brand-name">{brand.details.name}</div>
      )}
      <div className="campaign-details-name">{campaign.name}</div>
      <div className="campaigns-details-status">
        <div className="details-status">
          <span className={`icon ${shipmentStatus}`} />
          <div className="status">
            <div className="status-value">
              Delivered - {activeAt && moment(activeAt).format('M/D/YY')}
            </div>
            <div className="status-label">Shipment</div>
          </div>
        </div>
        <div className="details-status">
          <span className={`icon ${postStatus}`} />
          <div className="status">
            <div className="status-value">
              {approvedCount}/{postCount} - Due {dueDate && moment(dueDate).format('M/D/YY')}
            </div>
            <div className="status-label">Posts</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveCampaign;
