/* eslint no-console: ["error", { allow: ["log"] }] */
import { firebase } from "lib/Firebase";
import * as Promise from "bluebird";
import { SET_INFLUENCER_LIST } from "reducers/ui/influencerList";
import {
  fetchRequest,
  receiveRequest,
  setInfluencers
} from "actions/influencers";
import { onFetchCampaignActiveOrComplete } from "api/campaigns";
import { FREE_INFLUENCERS_DISPLAY } from "constants/common";
import { setCurrentCampaign } from "actions/ui/campaignDashboard";
import { setTotalAppliedUIDs } from "actions/ui/applicationsAction";

export const setInfluencerList = influencers => ({
  type: SET_INFLUENCER_LIST,
  influencers
});

export const getInfluencerList = (args = {}, onComplete = null) => dispatch => {
  dispatch(fetchRequest());
  dispatch(setTotalAppliedUIDs(0));
  dispatch(setInfluencerList([]));

  const { campaignId: campaignUID, isFree = false } = args;

  onFetchCampaignActiveOrComplete(campaignUID, campaign => {
    if (!campaign || !campaign.appliedUID) {
      dispatch(receiveRequest());
      if (onComplete) {
        onComplete({});
      }
      return;
    }

    dispatch(
      setCurrentCampaign({
        campaignUID,
        ...campaign
      })
    );

    const influencerUIDs = [];

    Object.keys(campaign.appliedUID).forEach(influencerUID => {
      const influencer = campaign.appliedUID[influencerUID];
      if (args.statuses.includes(influencer.status)) {
        influencerUIDs.push(influencerUID);
      }
    });

    const influencers = {};

    Promise.map(
      influencerUIDs,
      influencerUID =>
        firebase.influencer(influencerUID).once("value", snapshot => {
          const influencer = snapshot.val();

          const appInfluencer = {
            influencerUID,
            appliedUIDStatus: campaign.appliedUID[influencerUID].status,
            ...influencer
          };

          influencers[influencerUID] = appInfluencer;
        }),
      {
        concurrency: 5
      }
    )
      .then(() => {
        dispatch(setInfluencers(influencers));
        if (isFree) {
          const freeInfluencers = {};

          Object.keys(influencers)
            .slice(0, FREE_INFLUENCERS_DISPLAY)
            .forEach(influencerUID => {
              freeInfluencers[influencerUID] = influencers[influencerUID];
            });

          dispatch(setTotalAppliedUIDs(Object.keys(influencers).length));
          dispatch(setInfluencerList(Object.keys(freeInfluencers)));
        } else {
          dispatch(setInfluencerList(Object.keys(influencers)));
        }
      })
      .catch(e => {
        console.log("Error on getting influencer: ", e);
      })
      .finally(() => {
        if (onComplete) {
          onComplete(influencers);
        }
        dispatch(receiveRequest());
      });
  });
};
