import React, { useEffect, useState } from 'react';
import { Input, Form, Col, Row, Icon, Spin, Button } from 'antd';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement
} from 'react-stripe-elements';
import { useSelector } from 'react-redux';
import { fetchCards } from '../../api/subscriptions';
import { ReactComponent as Visa } from '../../images/CreditCard/Visa.svg';
import { ReactComponent as Mastercard } from '../../images/CreditCard/Mastercard.svg';
import { ReactComponent as Discover } from '../../images/CreditCard/Discover.svg';
import { ReactComponent as Diners } from '../../images/CreditCard/Diners.svg';
import { ReactComponent as JCB } from '../../images/CreditCard/JCB.svg';

const inputStyles = {
  fontSize: '18px',
  color: '#FFB917',
  fontFamily: 'TruenoRegular,Arial,sans-serif',
  paddingBottom: '5px',
  '::placeholder': {
    color: '#3A3A3A'
  }
};

const CardForm = ({ onCancel }) => (
  <>
    <Form.Item>
      <label className="text-gray-darkest">
        Card Number
        <CardNumberElement
          placeholder="Enter your credit card number"
          style={{
            base: inputStyles
          }}
        />
      </label>
      <Row gutter={28}>
        <Col span={12}>
          <label className="text-gray-darkest">
            Expiration Date
            <CardExpiryElement style={{ base: inputStyles }} />
          </label>
        </Col>
        <Col span={12}>
          <label className="text-gray-darkest">
            CVC
            <CardCVCElement style={{ base: inputStyles }} />
          </label>
        </Col>
      </Row>
      <label className="text-gray-darkest">
        Postal Code
        <PostalCodeElement
          style={{
            base: inputStyles
          }}
        />
      </label>
    </Form.Item>
    <Form.Item>
      <Button type="primary" onClick={onCancel} block>
        Cancel
      </Button>
    </Form.Item>
  </>
);

const CardDetails = ({ card: { brand, last4, expMonth, expYear }, onUpdate }) => {
  function cardType() {
    let component;
    switch (brand) {
      case 'Visa':
        component = Visa;
        break;
      case 'Mastercard':
        component = Mastercard;
        break;
      case 'Discover':
        component = Discover;
        break;
      case 'JCB':
        component = JCB;
        break;
      case 'Diners':
        component = Diners;
        break;
      default:
        return '';
    }
    return <Icon component={component} style={{ fontSize: '20px', marginLeft: '5px' }} />;
  }

  return (
    <Form.Item
      style={{
        border: '1px solid #2a2a2a',
        padding: '10px 15px 15px',
        borderRadius: '5px'
      }}
    >
      <Row gutter={28}>
        <Col span={16}>
          <label className="text-gray-darkest">
            Card Number
            {cardType()}
            <Input
              type="text"
              size="large"
              name="name"
              value={`**** **** **** ${last4}`}
              className="trend-input"
              disabled
            />
          </label>
        </Col>
        <Col span={8}>
          <label className="text-gray-darkest">
            Expiration Date
            <Input
              type="text"
              size="large"
              name="name"
              value={`${expMonth} / ${expYear % 100}`}
              className="trend-input"
              disabled
            />
          </label>
        </Col>
      </Row>
      <Row style={{ marginTop: '7px' }}>
        <Col>
          <Button type="primary" block onClick={onUpdate}>
            Update
          </Button>
        </Col>
      </Row>
    </Form.Item>
  );
};

const PaymentForm = ({ onUpdate, onCancel, showForm }) => {
  const [fetching, setFetching] = useState(false);
  const [card, setCard] = useState({
    last4: '****',
    expMonth: 0,
    expYear: 0
  });
  const { details: brandDetails = undefined } = useSelector(
    state => state.entities.session.authUserData
  );

  async function retrievePaymentInfo() {
    setFetching(true);
    try {
      const cards = await fetchCards();

      const defaultCard = cards.data[0];
      const { last4, brand } = defaultCard;

      setCard({
        last4,
        brand,
        expMonth: defaultCard.exp_month,
        expYear: defaultCard.exp_year
      });
    } catch (e) {
      console.log('unable to retrieve card list', e);
    }

    setFetching(false);
  }

  function handleUpdate() {
    onUpdate();
  }

  function handleCancel() {
    onCancel();
  }

  useEffect(() => {
    retrievePaymentInfo();
  }, [showForm]);

  return (
    <div id="payment-info">
      <h3 className="text-gray-darkest">
        <Icon type="credit-card" /> Payment Information
      </h3>
      <Spin spinning={fetching}>
        {brandDetails.stripeCustomerId && !showForm ? (
          <CardDetails card={card} onUpdate={handleUpdate} />
        ) : (
          <CardForm onCancel={handleCancel} />
        )}
      </Spin>
    </div>
  );
};

export default PaymentForm;
