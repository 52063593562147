import React from 'react';
import { Empty } from 'antd';
import NoInfluencersImg from 'images/DefaultEmpty/no-influencers.png';

const InfluencersBanner = () => (
  <Empty
    className="empty-no-applications"
    style={{
      marginTop: '66px',
      marginBottom: '109px',
    }}
    image={
      <img src={NoInfluencersImg} alt="free trial" style={{ width: '450px', height: 'auto' }} />
    }
    description={(
      <span className="empty-desc-header type-bold mb-2 mt-6">
        Get a feel for the tool
      </span>
    )}
  >
    <div className="mt-1">
      <div className="empty-desc-body">
        Above is an example of what the tool will look when you start approving
        <br className="hidden-mobile" />
        &nbsp;influencers of your own. In this section you can view and message all of
        <br className="hidden-mobile" />
        &nbsp;your influencers, manage product shipments, and approve posts as they
        <br className="hidden-mobile" />
        &nbsp;roll in and immediately acquire rights to the content.
      </div>
    </div>
  </Empty>
);

export default InfluencersBanner;
