import React from 'react';
import AuthUserContext from './context';

const withAuthUser = Component => (
  // eslint-disable-next-line react/prefer-stateless-function
  class extends React.Component {
    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser => <Component authUser={authUser} {...this.props} />}
        </AuthUserContext.Consumer>
      );
    }
  }
);

export default withAuthUser;
