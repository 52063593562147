import React from 'react';
import { Empty } from 'antd';
import NoPostsImg from 'images/DefaultEmpty/no-posts.png';

const PostsBanner = () => (
  <Empty
    className="empty-no-messages"
    style={{
      marginTop: '60px',
      marginBottom: '109px'
    }}
    image={(
      <img
        src={NoPostsImg}
        alt="no messages"
        style={{
          width: '504px',
          height: 'auto'
        }}
      />
)}
    description={(
      <span className="empty-desc-header type-sfpro-bold mb-2" style={{ marginTop: '70px' }}>
        Watch your campaign
      </span>
)}
  >
    <div className="mt-1">
      <div className="empty-desc-body">
        The Trend app allows you to iron out any details you need to with
        <br className="hidden-mobile" />
        &nbsp;the influencers you work with. Communication is key to ensuring
        <br className="hidden-mobile" />
        &nbsp;you’re campaigns go off without a hitch.
      </div>
    </div>
  </Empty>
);

export default PostsBanner;
