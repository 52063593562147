import {
  RECEIVE_TRIAL,
  RECEIVE_TRIALS,
} from 'reducers/entities/trials';

export const receiveTrials = trials => ({
  type: RECEIVE_TRIALS,
  trials,
});

export const receiveTrial = trial => ({
  type: RECEIVE_TRIAL,
  trial,
});