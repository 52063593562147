export const RECEIVE_BRAND_SEARCHES = 'RECEIVE_BRAND_SEARCHES';

export const RECEIVE_BRAND_SEARCH = 'RECEIVE_BRAND_SEARCH';

const initialState = {
  byId: {
    // 1: {
    //   ...atr
    // }
  },
  all: [
    // ids
    // 1,2,3
  ]
};

const addBrandSearches = (state, brandSearches) => ({
  ...state,
  byId: {
    ...state.byId,
    ...brandSearches
  },
  all: [...new Set(state.all.concat(Object.keys(brandSearches)))]
});

const addBrandSearch = (state, brandSearch) => ({
  ...state,
  byId: {
    ...state.byId,
    ...brandSearch
  },
  all: [...new Set(state.all.concat(Object.keys(brandSearch)))]
});

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_BRAND_SEARCHES:
      return addBrandSearches(state, action.brandSearches);

    case RECEIVE_BRAND_SEARCH:
      return addBrandSearch(state, action.brandSearch);

    default:
      return state;
  }
};
