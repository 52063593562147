export const RECEIVE_TRIAL = 'RECEIVE_TRIAL';

export const RECEIVE_TRIALS = 'RECEIVE_TRIALS';

const initialState = {
  byId: {
    // 1: {
    //   ...atr
    // }
  },
  all: [
    // ids
    // 1,2,3
  ],
};

const addTrials = (state, trials) => ({
  ...state,
  byId: {
    ...state.byId,
    ...trials,
  },
  all: [
    ...new Set(state.all.concat(Object.keys(trials))),
  ],
});

const addTrial = (state, trial) => ({
  ...state,
  byId: {
    ...state.byId,
    ...trial,
  },
  all: [
    ...new Set(state.all.concat(Object.keys(trial))),
  ],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_TRIAL:
      return addTrial(state, action.trial);

    case RECEIVE_TRIALS:
      return addTrials(state, action.trials);

    default:
      return state;
  }
};
