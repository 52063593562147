import { POST_DETAIL_STATUS } from 'constants/posts';

export default (campaignUID, influencers, influencerUIDs) => {
  const posts = {
    awaiting: {},
    needsApproval: {},
    approved: {}
  };

  influencerUIDs.forEach(influencerUID => {
    const influencer = influencers[influencerUID];

    if (!influencer || !influencer.partnerships) {
      return;
    }

    const influencerCampaign = influencer.partnerships.products[campaignUID];

    if (!influencerCampaign || !influencerCampaign.posts) {
      return;
    }

    Object.keys(influencerCampaign.posts).forEach(postUID => {
      const { details: postDetails } = influencerCampaign.posts[postUID];
      const post = {
        postUID,
        influencerUID,
        ...postDetails
      };

      if (post.status === POST_DETAIL_STATUS.approved) {
        posts.approved[postUID] = post;
      } else if (post.status === POST_DETAIL_STATUS.submitted) {
        posts.needsApproval[postUID] = post;
      } else {
        posts.awaiting[postUID] = post;
      }
    });
  });

  return posts;
};
