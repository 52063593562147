/* eslint import/prefer-default-export: 0 */
import { firebase } from '../lib/Firebase';

class BusinessListener {
  constructor() {
    this.subscriptions = [];
  }

  addListener(subs) {
    this.subscriptions.push(subs);
  }
}

export const businessListener = async (
  lastBusinessDoc = null,
  filters = {},
  limit = 50,
  callback
) => {
  let business = [];
  let lastDocBusiness = null;
  let newFilters = {};

  const refQuery = ({ status, hasRecord }) => {
    let query = firebase.firestore.collection('businesses').orderBy('createdAtUnix', 'desc');

    if (status && status !== 'all') {
      if (Array.isArray(status)) {
        query = query.where('status', 'in', status);
      } else {
        query = query.where('status', '==', status);
      }
    } else if (!hasRecord) {
      query = query.where('status', '==', 'pending');
    }

    if (
      !status &&
      ['has_not_registered', 'has_no_campaign', 'has_no_credits'].includes(hasRecord)
    ) {
      query = query.where('status', '==', 'pending');
    }

    if (!status && ['has_campaign', 'has_credits', 'has_registered'].includes(hasRecord)) {
      query = query.where('status', '==', 'approved');
    }

    if (['has_campaign'].includes(hasRecord)) {
      query = query.where('firstCampaign', '==', true);
    }

    if (['has_credits'].includes(hasRecord)) {
      query = query.where('firstCredit', '==', true);
    }

    if (['has_registered'].includes(hasRecord)) {
      query = query.where('isRegistered', '==', true);
    }

    return query;
  };

  const iterateBusiness = businessesDocs => {
    businessesDocs.forEach(businessSnap => {
      const businessData = businessSnap.data();

      const appendData = () => {
        business = [...business, { uid: businessSnap.id, ...businessData }];
        lastDocBusiness = businessSnap;
      };

      const checkData = key => {
        if (!businessData[key]) {
          appendData();
        } else {
          // leave blank to avoid pre-hiding
          // LOAD MORE on the BusinessPage
          business = [...business, {}];
        }
      };

      if (filters.hasRecord === 'has_no_credits') {
        checkData('firstCredit');
      } else if (filters.hasRecord === 'has_not_registered') {
        checkData('isRegistered');
      } else if (filters.hasRecord === 'has_no_campaign') {
        checkData('firstCampaign');
      } else {
        appendData();
      }
    });
  };

  let firstQuery = refQuery(filters);

  if (lastBusinessDoc) {
    firstQuery = firstQuery.startAfter(lastBusinessDoc);
  }

  const businessesSnap = await firstQuery.limit(limit).get();
  const businessLength = businessesSnap.docs.length;

  iterateBusiness(businessesSnap.docs);

  // in case the pending is finish loading all data
  if (
    !filters.status &&
    !['has_campaign', 'has_credits', 'has_registered'].includes(filters.hasRecord) &&
    lastDocBusiness &&
    businessLength < limit
  ) {
    newFilters = { status: ['approved', 'rejected'] };

    if (filters.hasRecord) {
      newFilters = { ...newFilters, hasRecord: filters.hasRecord };
    }

    const remainingLength = limit - businessLength;
    const extraQuery = refQuery(newFilters);

    const secondBusinessesSnap = await extraQuery.limit(remainingLength).get();

    iterateBusiness(secondBusinessesSnap);
  }

  callback(business, lastDocBusiness, newFilters);
};

export const businessSubs = new BusinessListener();
