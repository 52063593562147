import { REMOVE_INVITE, ADD_INVITE } from '../reducers/entities/invites';

export const addInvite = (invites = {}) => ({
  type: ADD_INVITE,
  payload: invites
});

export const removeInvite = inviteUID => ({
  type: REMOVE_INVITE,
  payload: inviteUID
});
