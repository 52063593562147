import React from 'react';
import { Button, PageHeader, Input } from 'antd';
import { BrandTable } from 'components/Brands';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { setAdminBrandIds } from 'actions/ui/adminBrandsPage';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Promise from 'bluebird';
import { firebase } from '../../../lib/Firebase';

const List = props => {
  const { fetchBrands } = props;
  const history = useHistory();
  const { list, searching } = useSelector(state => state.ui.adminBrandsPage);
  const { all, byId: brandById } = useSelector(state => state.entities.brands);

  const dispatch = useDispatch();

  function handleSearchChange(e) {
    const val = e.target.value;
    const newList = _.filter(all, id => {
      const brand = brandById[id];

      return (
        brand.details &&
        brand.details.name &&
        brand.details.name.toLowerCase().includes(val.toLowerCase())
      );
    });
    dispatch(setAdminBrandIds(newList));
  }
  const sortedList = _.sortBy(list, [
    id => (brandById[id].details.name ? brandById[id].details.name.toLowerCase() : '')
  ]);

  const cardDateFormat = dateUnix => {
    if (dateUnix) return moment(dateUnix * 1000).format('MM/DD/YY');
    return 'N/A';
  };

  const handleExport = async () => {
    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent +=
      'Name, Standard Credits, Vip Credits, Last login date, Total number of logins, Total number of logins this month, Total credits purchased\r\n';
    let csvBody = '';
    await Promise.map(sortedList, async uid => {
      const brand = brandById[uid].details;
      const {
        name,
        standardCredits = 0,
        credits = 0,
        addedStandardCredits = 0,
        addedVipCredits = 0,
        authEmail,
        email,
        lifetimePurchasedCredits,
        lastLoginDate
      } = brand;
      const lastLoginDateTmp = lastLoginDate || null;
      let totalNumberOfLogins = 0;
      let totalNumberOfLoginsThisMonth = 0;
      const totalCreditsPurchased = lifetimePurchasedCredits || 0;
      if (authEmail) {
        // get logs
        const logs = await firebase.firestore
          .collection('user_logs')
          .where('email', '==', authEmail)
          .orderBy('loginAt', 'desc')
          .get();

        if (!logs.empty) {
          totalNumberOfLogins = logs.docs.length;
          logs.docs.map(log => {
            const logTmp = log.data();
            if (
              logTmp.loginAt >=
                moment()
                  .startOf('month')
                  .unix() &&
              logTmp.loginAt <=
                moment()
                  .endOf('month')
                  .unix()
            ) {
              totalNumberOfLoginsThisMonth += 1;
            }
            return log;
          });
        }
      } else if (email) {
        // get logs
        const logs = await firebase.firestore
          .collection('user_logs')
          .where('email', '==', email)
          .orderBy('loginAt', 'desc')
          .get();
        if (!logs.empty) {
          totalNumberOfLogins = logs.docs.length;
          logs.docs.map(log => {
            const logTmp = log.data();
            if (
              logTmp.loginAt >=
                moment()
                  .startOf('month')
                  .unix() &&
              logTmp.loginAt <=
                moment()
                  .endOf('month')
                  .unix()
            ) {
              totalNumberOfLoginsThisMonth += 1;
            }
            return log;
          });
        }
      }
      const row = [
        `"${name}"`,
        standardCredits + addedStandardCredits,
        credits + addedVipCredits,
        lastLoginDateTmp ? cardDateFormat(lastLoginDateTmp) : null,
        totalNumberOfLogins,
        totalNumberOfLoginsThisMonth,
        totalCreditsPurchased
      ].join(',');
      csvBody += `${row}\r\n`;
    });

    const encodedUri = encodeURIComponent(csvBody);
    const link = document.createElement('a');
    link.setAttribute('href', csvContent + encodedUri);
    link.setAttribute('download', 'brands.csv');
    document.body.appendChild(link);

    link.click();
    link.remove();
  };

  return (
    <>
      <PageHeader
        title="Brands"
        extra={[
          <Button key={0} onClick={handleExport}>
            Export CSV
          </Button>,
          <Input
            placeholder="Search Brand"
            key={1}
            style={{ width: '375px' }}
            onChange={handleSearchChange}
          />
        ]}
        onBack={() => history.push('/admin')}
      />

      <BrandTable ids={sortedList} loading={searching} fetchBrands={fetchBrands} />
    </>
  );
};

export default List;
