import React, { useRef, useState, useEffect } from 'react';
import { generateRandomColor, generateWholeNumber, TOP_OFFSET, LEFT_OFFSET } from './index';

const CircularParticle = () => {
  const SIZE_RANGE = [5, 10];
  const ROTATION_RANGE = [0, 45];

  const size = generateWholeNumber(...SIZE_RANGE);

  const divEl = useRef(null);
  const [style] = useState({
    backgroundColor: generateRandomColor(),
    width: size,
    height: size,
    borderRadius: size,
    transform: `rotateZ(${generateWholeNumber(...ROTATION_RANGE)}deg)`,
    left: generateWholeNumber(0, window.innerWidth),
    top: generateWholeNumber(-TOP_OFFSET, 0)
  });

  useEffect(() => {
    const { left } = style;

    if (divEl) {
      setTimeout(() => {
        try {
          divEl.current.style.top = `${window.innerHeight + generateWholeNumber(0, TOP_OFFSET)}px`;
          divEl.current.style.left = `${left + generateWholeNumber(-LEFT_OFFSET, LEFT_OFFSET)}px`;
        } catch {
          // do nothing
        }
      }, 0);
    }
  }, [style, divEl]);

  return <div ref={divEl} className="particle" style={style} />;
};

export default CircularParticle;
