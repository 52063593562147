import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

const FreeEmptyRecentPosts = ({ history }) => (
  <div
    style={{
      width: '100%',
      height: '187px',
      border: 'dashed 1px #888888',
      backgroundColor: '#ffffff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    }}
    onClick={() => {
      history.push('/account/subscription');
    }}
    role="presentation"
  >
    <div
      style={{
        fontFamily: 'TruenoBold',
        fontSize: '16px',
        lineHeight: '1.31',
        fontStyle: 'italic',
      }}
    >
      Select your plan to
      <br />
      start creating posts!
    </div>
  </div>
);

export default compose(withRouter)(FreeEmptyRecentPosts);
