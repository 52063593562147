/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Tabs, Drawer, Avatar, Row, Icon, Col } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ReactComponent as VipIcon } from '../../../images/icon-vip-profile.svg';
import RecentPost from './RecentPost';
import Stats from './Stats';
import Biography from '../Biography';
import { ReactComponent as CloseIcon } from '../../../images/close-x.svg';
import Reviews, { Stats as ReviewStats } from '../Reviews';
import Badge from '../Badge';
import InfluencerCampaigns from '../Campaigns';
import ActiveCampaign from './ActiveCampaign';
import CompletedCampaign from './CompletedCampaign';

const { TabPane } = Tabs;

const InfluencerDrawer = ({ influencerUID, actionButtons, onHide, show }) => {
  const influencer = useSelector(state => state.entities.influencers.byId[influencerUID]);
  if (!influencer) return <div />;

  function handleHide() {
    if (onHide) {
      onHide();
    }
  }

  if (!actionButtons) {
    return <div />;
  }

  let buttons = actionButtons(influencerUID);
  const colspan = 24 / buttons.length;

  buttons = buttons.map((action, index) => {
    return (
      <Col span={colspan} key={index}>
        {action}
      </Col>
    );
  });

  const { details, instagram = {} } = influencer;
  const {
    avgCommentCount = 0,
    avgLikeCount = 0,
    images = [],
    biography,
    followedByCount,
    engagementRate,
    authEmail,
    paypalEmail,
    state,
    username,
    isVIP,
    ageRange,
    star
  } = details;

  const { avgAttitudeProfessionalism = 5, avgContentQuality = 5 } = details;

  const lastWeek = moment()
    .subtract(1, 'week')
    .format('YYYY-w');
  const { stats = {} } = instagram;

  const reviews = {
    avgAttitudeProfessionalism,
    avgContentQuality
  };

  return (
    <Drawer
      id="influencer-drawer-admin"
      placement="right"
      width={328}
      visible={show}
      onClose={handleHide}
      closable={false}
      maskClosable
    >
      <div className="ant-drawer-header-no-title">
        <button onClick={handleHide} type="button" aria-label="Close" className="ant-drawer-close">
          <Icon component={CloseIcon} />
        </button>
      </div>
      <div className="influencer-info">
        <div className="influencer-info-avatar">
          <a
            href={`https://instagram.com/${details.username}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Avatar size={90} src={details.image} />
          </a>
          {isVIP && <Icon className="influencer-info-vip" component={VipIcon} />}
          <Badge level={star} />
        </div>
        <h1 className="influencer-info-name">@{username}</h1>
        <div className="influencer-info-address">
          {state} | Age: {ageRange}
          <br />
          Paypal: {paypalEmail}
          <br />
          Email: {authEmail}
        </div>
      </div>
      <Row className="influencer-info-actions" gutter={8}>
        {buttons}
      </Row>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Profile" key="1">
          <Stats
            followedByCount={followedByCount}
            avgCommentCount={avgCommentCount}
            avgLikeCount={avgLikeCount}
            stats={stats[lastWeek]}
            engagementRate={engagementRate}
          />
          <ReviewStats reviews={reviews} />
          <Reviews influencerUID={influencerUID} />
          <RecentPost images={images} />
          <Biography text={biography} />
        </TabPane>
        <TabPane tab="Active" key="2">
          <InfluencerCampaigns
            influencerUID={show && influencerUID}
            statuses={['active', 'shipped', 'needsShipping']}
            render={data => (
              <ActiveCampaign
                key={data.key}
                influencerUID={influencerUID}
                campaign={data.campaign}
                partnership={data.partnership}
              />
            )}
          />
        </TabPane>
        <TabPane tab="History" key="3">
          <InfluencerCampaigns
            influencerUID={show && influencerUID}
            statuses={['completed']}
            sortBy="completedAt"
            render={data => (
              <CompletedCampaign
                key={data.key}
                influencerUID={influencerUID}
                campaign={data.campaign}
                partnership={data.partnership}
              />
            )}
          />
        </TabPane>
      </Tabs>
    </Drawer>
  );
};

export default InfluencerDrawer;
