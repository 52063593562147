/* eslint-disable camelcase */
import { combineReducers } from 'redux';
import brands from './brands';
import campaigns from './campaigns';
import posts from './posts';
import categories from './categories';
import influencers from './influencers';
import session from './session';
import messages from './messages';
import chat_messages from './chat_messages';
import postStatistics from './postStatistics';
import trials from './trials';
import reviews from './reviews';
import invites from './invites';
import yotpoMetric from './yotpoMetric';
import brandSearch from './brandSearch';
import influencerSearch from './influencerSearch';
import notifications from './notifications';

export default combineReducers({
  brands,
  campaigns,
  posts,
  categories,
  messages,
  influencers,
  chat_messages,
  session,
  postStatistics,
  trials,
  reviews,
  invites,
  yotpoMetric,
  brandSearch,
  influencerSearch,
  notifications
});
