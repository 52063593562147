import React from 'react';
import { Spin } from 'antd';
import useInfluencerCampaigns from './useInfluencerCampaigns';

const campaignSortBy = (influencerUID, type) => (a, b) => {
  return b.campaign.appliedUID[influencerUID][type] - a.campaign.appliedUID[influencerUID][type];
};

const Campaigns = ({ render, influencerUID, statuses = ['completed'], sortBy = 'activeAt' }) => {
  const [campaigns, loading] = useInfluencerCampaigns(influencerUID);
  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '40px' }}>
        <Spin />
      </div>
    );
  }

  let campaignsByStatus = [];
  statuses.forEach(status => {
    campaignsByStatus = [...campaignsByStatus, ...campaigns[status]];
  });

  campaignsByStatus.sort(campaignSortBy(influencerUID, sortBy));

  return <div className="influencer-campaigns">{campaignsByStatus.map(render)}</div>;
};

export default Campaigns;
