import React, { useEffect } from 'react';
import { Row } from 'antd';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import MinLayout from 'components/Common/MinLayout';
import { receiveBrands } from 'actions/brands';
import { setAdminBrandIds, setAdminBrandSearching } from 'actions/ui/adminBrandsPage';
import * as ROUTES from '../../../constants/routes';
import ListPage from './list';
import EditPage from './edit';
import { firebase } from '../../../lib/Firebase';

const main = () => {
  const dispatch = useDispatch();

  const fetchBrands = () => {
    firebase
      .brands()
      .orderByChild('details/name')
      .once('value', snap => {
        if (snap.exists()) {
          let brands = {};

          snap.forEach(s => {
            if (s.val().details.name && s.val().details.name !== '') {
              brands = {
                ...brands,
                [s.key]: s.val()
              };
            }
          });
          dispatch(receiveBrands(brands));
          dispatch(setAdminBrandIds(Object.keys(brands)));
          dispatch(setAdminBrandSearching(false));
        }
      });
  };

  useEffect(() => {
    dispatch(setAdminBrandSearching(true));
    fetchBrands();
  }, []);

  return (
    <Row type="flex" justify="center">
      <MinLayout className="content">
        <Switch>
          <Route
            exact
            path={ROUTES.ADMIN_BRANDS_PATH}
            component={ListPage}
            fetchBrands={fetchBrands}
          />
          <Route exact path={ROUTES.ADMIN_BRAND_PATH} component={EditPage} />
        </Switch>
      </MinLayout>
    </Row>
  );
};

export default main;
