import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Icon, Button, Modal, Form, Select } from 'antd';
import { useParams } from 'react-router-dom';
import TransferImage from '../../images/transfer-photo@2x.png';
import { ReactComponent as CloseIcon } from '../../images/close-x.svg';
import { getYotPoProducts } from '../../lib/Firebase/callables';

const AttachProductModal = ({ show, handleShow, onSubmit }) => {
  const { campaignId } = useParams();
  const tProduct = useSelector(state => state.entities.campaigns.byId[campaignId]);
  const [product, setProduct] = useState(undefined);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  function handleClose() {
    setProduct(undefined);
    handleShow(false);
  }

  function handleSubmit() {
    const [selected] = products.filter(x => x.domain_key === product);
    onSubmit(selected.domain_key);
  }

  async function retrieveAlbums() {
    setLoading(true);
    const res = await getYotPoProducts();
    if (res) {
      setProducts(res);
      setProduct(tProduct.yotPoProductID);
    }
    setLoading(false);
  }

  function productOptions() {
    return products.map(p => {
      return (
        <Select.Option key={p.id} value={p.domain_key}>
          {p.name}
        </Select.Option>
      );
    });
  }

  useEffect(() => {
    if (show) {
      retrieveAlbums();
    }
  }, [show]);

  return (
    <Modal className="yotpo-modal transfer" closable={false} visible={show} footer={false}>
      <Button type="link" className="close" onClick={handleClose}>
        <Icon component={CloseIcon} />
      </Button>
      <div className="text-center logos">
        <img className="content-sent-image" src={TransferImage} alt="content sent" />
      </div>
      <h1>
        Select a Yotpo product tag to
        <br />
        attach this content to
      </h1>
      <Form.Item label="Product Folder">
        <Select
          showSearch
          placeholder="Select Product Tags"
          dropdownClassName="yotpo-product-select"
          showArrow={false}
          notFoundContent="You don’t have any Product Albums"
          value={product}
          onChange={val => setProduct(val)}
          loading={loading}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {productOptions()}
        </Select>
      </Form.Item>
      <div style={{ position: 'relative' }}>
        <Form.Item className="submit">
          <Button block onClick={handleSubmit}>
            Send Content to Yotpo
          </Button>
        </Form.Item>
      </div>
    </Modal>
  );
};

export default AttachProductModal;
