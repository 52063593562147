import React from 'react';
import { Route } from 'react-router-dom';
import { withAuthorization } from 'lib/Session';
import { isMobile } from 'react-device-detect';
import MobileSignUpConfirmation from '../components/Layouts/MobileSignUpConfirmation';

const OnboardingRoute = ({ component: Component, layout: Layout, authUser, brand, ...rest }) => {
  if (isMobile) {
    return <MobileSignUpConfirmation />;
  }
  const renderCompoent = props => {
    if (Layout) {
      return (
        <Layout authUser={authUser}>
          <Component {...props} brand={brand} />
        </Layout>
      );
    }

    return <Component authUser={authUser} brand={brand} {...props} />;
  };

  return <Route {...rest} render={renderCompoent} />;
};

const condition = (authUser, brand) => !!authUser && !!brand;

export default withAuthorization(condition)(OnboardingRoute);
