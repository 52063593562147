import React from 'react';
import { Empty } from 'antd';
import NoApplicationsImg from 'images/DefaultEmpty/no-applications.png';

const EmptyApplications = () => (
  <Empty
    className="empty-no-applications"
    style={{
      marginTop: '66px',
      marginBottom: '109px'
    }}
    image={NoApplicationsImg}
    description={
      <span className="empty-desc-header type-sfpro-bold mb-2 mt-6">No Applications</span>
    }
  >
    <div className="mt-1">
      <div className="empty-desc-body type-sfpro-regular">
        Don’t worry, influencers will apply soon! If you have any questions
        <br className="hidden-mobile" />
        about your campaign or how you can attract more influencers to your
        <br className="hidden-mobile" />
        campaign, contact natalie@trend.io.
      </div>
    </div>
  </Empty>
);

export default EmptyApplications;
