import React from 'react';

const DemoHeaderNote = () => (
  <div
    style={{
      textAlign: 'center',
      fontSize: '11px',
      lineHeight: '28px',
      fontStyle: 'italic',
      fontFamily: 'truenosemibold',
      height: '29px',
      background: '#fff6d7',
      width: '100%',
    }}
  >
    Demo Data - This will give you an understanding of how the tool will work for you.
  </div>
);

export default DemoHeaderNote;
