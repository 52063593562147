import React, { useEffect, useState } from 'react';
import Promise from 'bluebird';
import { Spin } from 'antd';
import { firebase } from '../../../lib/Firebase';

const Plain = ({ influencerUID }) => {
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  async function retrieveActiveCampaigns() {
    setLoading(true);
    const partnershipsRef = await firebase
      .influencer(influencerUID)
      .child('partnerships/products')
      .orderByChild('status');

    let campaignUIDs = [];
    await Promise.map(['active', 'needsShipping', 'shipped'], async status => {
      const querySnap = await partnershipsRef.equalTo(status).once('value');
      if (querySnap.exists()) {
        campaignUIDs = [...campaignUIDs, ...Object.keys(querySnap.val())];
      }
    });

    await Promise.map(campaignUIDs, async campaignUID => {
      const snap = await firebase.activeProduct(campaignUID).once('value');
      setCampaigns(state => [...state, { key: campaignUID, ...snap.val() }]);
    });
    setLoading(false);
  }

  useEffect(() => {
    retrieveActiveCampaigns();
  }, []);

  if (loading) return <Spin className="admin-influencer-active-campaigns" />;

  const list = campaigns.map(campaign => {
    return (
      <p key={campaign.key} className={campaign.key}>
        {campaign.name}
      </p>
    );
  });

  return <div className="admin-influencer-active-campaigns">{list}</div>;
};

export default Plain;
