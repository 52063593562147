import React from 'react';
import { Row, Col, Icon } from 'antd';
import { useSelector } from 'react-redux';
import Container from '../Common/Container';
import { UpdateAccount } from '../YotPo';
import YotPoImg from '../../images/yotpo-circle@2x.png';
import { ReactComponent as EditIcon } from '../../images/edit-pencil.svg';

const BrandIntegration = () => {
  const session = useSelector(state => state.entities.session.authUserData);
  const { integrations = {} } = session;
  const { yotpo } = integrations;

  return (
    <div className="brand-integrations">
      <Container width={1024}>
        <h1 className="page-title text-center">Get the most out of your content</h1>
        <Row>
          <Col span={8}>
            <UpdateAccount type="link">
              <div className={`card yotpo ${yotpo && 'active'}`}>
                <div className="card-logo">
                  <img src={YotPoImg} alt="YotPo" />
                </div>
                <div className="card-description">
                  Integrate with your Yotpo account to put your fresh UGC to work. The integration
                  is seamless and will allow you to grow your ROI even more.
                </div>
                <div className="card-actions">
                  {yotpo ? (
                    <>
                      <Icon component={EditIcon} /> Edit
                    </>
                  ) : (
                    <>
                      <span className="circle">+</span> Add
                    </>
                  )}
                </div>
              </div>
            </UpdateAccount>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BrandIntegration;
