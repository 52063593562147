import moment from 'moment';
import { fetchRequest, receiveRequest } from './common/request';
import { DATE_TIMEZONE_FORMAT } from '../../constants/common';

export const ADD_MESSAGES = 'ADD_MESSAGES';

export const MESSAGE_FETCH_REQUEST = 'MESSAGE_FETCH_REQUEST';

export const MESSAGE_RECEIVE_REQUEST = 'MESSAGE_RECEIVE_REQUEST';

const defaultState = {
  byId: {},
  byCampaign: {},
  byCampaignInfluencer: {},
  all: [],
  isFetching: false
};

const addLastMessageDetails = messages => {
  let newMessages = messages;

  Object.keys(messages).forEach(messageUID => {
    const message = messages[messageUID];
    const { messages: msgs, users } = message;
    let details = {
      timestamp: moment().format(DATE_TIMEZONE_FORMAT),
      text: ''
    };

    // When a brand Approves an Influencer. it will do an auto update
    // and there are chances that the message.messages does not yet exist
    // which will cause an error
    if (msgs) {
      [details] = Object.values(msgs).sort((a, b) => {
        return (
          moment(b.timestamp, moment.defaultFormat) - moment(a.timestamp, moment.defaultFormat)
        );
      });
    }

    const [influencerUID] = Object.keys(users).filter(x => users[x].type === 'influencer');

    newMessages = {
      ...newMessages,
      [messageUID]: {
        ...newMessages[messageUID],
        lastMessageTimestamp: details.timestamp,
        lastMessageText: details.text,
        influencerUID
      }
    };
  });

  return newMessages;
};

const INFLUENCER = 'influencer';
const byCampaignInfluencer = (campaignInfluencer = {}, messages) => {
  let newState = { ...campaignInfluencer };

  Object.keys(messages).forEach(messageUID => {
    const message = messages[messageUID];
    const { details } = message;

    const [infUID] = Object.keys(message.users).filter(x => message.users[x].type === INFLUENCER);

    newState = {
      ...newState,
      [`${details.partnershipUID}${infUID}`]: messageUID
    };
  });

  return newState;
};

const addMessages = (state, messages = {}, campaignUID) => {
  const newMessages = addLastMessageDetails(messages);
  const campaignMessages = state.byCampaign[campaignUID] || [];

  return {
    ...state,
    byId: {
      ...state.byId,
      ...newMessages
    },
    byCampaignInfluencer: byCampaignInfluencer(state.byCampaignInfluencer, newMessages),
    byCampaign: {
      ...state.byCampaign,
      [campaignUID]: [...new Set([...campaignMessages, ...Object.keys(newMessages)])]
    },
    all: [...new Set(state.all.concat(Object.keys(newMessages)))]
  };
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ADD_MESSAGES:
      return addMessages(state, action.messages, action.campaignUID);
    case MESSAGE_FETCH_REQUEST:
      return fetchRequest(state);
    case MESSAGE_RECEIVE_REQUEST:
      return receiveRequest(state);

    default:
      return state;
  }
};
