export const ADD_INVITE = 'ADD_INVITE';
export const REMOVE_INVITE = 'REMOVE_INVITE';

const initialState = {
  byId: {},
  all: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_INVITE:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...action.payload
        },
        all: [...new Set([...state.all, ...Object.keys(action.payload)])]
      };

    case REMOVE_INVITE:
      return {
        ...state,
        all: [...state.all.filter(x => x !== action.payload)]
      };
    default:
      return state;
  }
};
