import React from 'react';
import { withFirebase } from 'lib/Firebase';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { fetchLoggedUserData, clearSession } from 'actions/session';
import { withCookies } from 'react-cookie';
import AuthUserContext from './context';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    _isMounted = false;

    state = {
      authUser: null,
      ready: false
    };

    componentDidMount() {
      this._isMounted = true;
      const { dispatchFetchLoggedUserData, cookies, clearSession: clear, firebase } = this.props;

      this.listener = firebase.auth.onAuthStateChanged(authUser => {
        if (authUser) {
          if (this._isMounted) {
            this.setState(() => ({ authUser }));
          }

          dispatchFetchLoggedUserData(authUser, brand => {
            if (brand && brand.details && brand.details.isAdmin) {
              cookies.set('isAdmin', brand.details.uid, { path: '/' });
            }

            if (brand && brand.details && brand.details.isContentAdmin) {
              cookies.set('isContentAdmin', true);
            } else {
              cookies.set('isContentAdmin', false);
            }

            if (!cookies.get('isAdmin')) {
              firebase
                .brand(authUser.uid)
                .child('details')
                .update({
                  lastLoginDate: new Date().getTime() / 1000
                });
            }

            this.setState({ ready: true });
          });
        } else {
          if (this._isMounted) {
            this.setState({ authUser: null });
          }

          this.setState({ ready: true });
          clear();
        }
        console.log('READY');
      });
    }

    componentWillUnmount() {
      this._isMounted = false;
      this.listener();
    }

    render() {
      const { authUser, ready } = this.state;

      if (!ready) {
        return <div />;
      }
      return (
        <AuthUserContext.Provider value={authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  const mapDispatchToProps = dispatch => ({
    dispatchFetchLoggedUserData: (authUser, onComplete) => {
      dispatch(fetchLoggedUserData(authUser, onComplete));
    },
    clearSession: () => dispatch(clearSession())
  });

  const mapStateToProps = () => ({});

  return compose(
    withFirebase,
    withCookies,
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
  )(props => <WithAuthentication {...props} />);
};

export default withAuthentication;
