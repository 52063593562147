import React from 'react';
import moment from 'moment';
import { Menu, Icon, Row, Col } from 'antd';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { compose } from 'recompose';
import CampaignSubMenu from 'components/Layouts/SideNav/CampaignSubMenu';
import { ReactComponent as IconPosts } from 'images/icon-all-posts.svg';
import { ReactComponent as IconSettings } from 'images/icon-settings.svg';
import { ReactComponent as IcontStar } from 'images/star.svg';
import * as routes from 'constants/routes';
import { withCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { ReactComponent as AddCampaignIcon } from '../../../images/add-campaign.svg';
import ExampleItem from './ExampleItem';

const CampaignMenus = props => {
  const history = useHistory();
  const path = history.location.pathname.split('/');
  const { ownedCampaigns, ownedArchivedCampaigns: archivedCampaigns } = useSelector(
    state => state.ui.sideNav
  );
  const campaignById = useSelector(state => state.entities.campaigns.byId);
  const noCampaigns = ownedCampaigns.length === 0 && archivedCampaigns.length === 0;
  const { userBrand, squishedNav, cookies, buttonText } = props;
  const selectedKeys = path[1] || '';
  const isAdmin = cookies.get('isAdmin');
  const isContentAdmin = cookies.get('isContentAdmin');
  const { status, stripeCustomerId, lastPurchaseDate, approvedAt } = userBrand.details;
  const incomplete = !stripeCustomerId;
  const firstCampaign = campaignById[ownedCampaigns[0]];
  const firstCampaignCompleted = firstCampaign && !incomplete;
  const onBoarding = status && !lastPurchaseDate;
  const onBoardingApproved = onBoarding && status === 'approved';
  const onBoardingApprovedComplete = onBoardingApproved && firstCampaignCompleted;
  const showMarqueNotif = status === 'approved' && !lastPurchaseDate;
  const dontShowCreateButton = ownedCampaigns.length === 1 && !lastPurchaseDate;

  const onBoardingActive =
    onBoardingApprovedComplete &&
    moment(approvedAt, 'YYYY-MM-DD HH:mm:SS ZZ').add(24, 'hours') > moment();

  const expiredOnBoardingCampaign =
    onBoardingApprovedComplete &&
    moment(approvedAt, 'YYYY-MM-DD HH:mm:SS ZZ').add(24, 'hours') < moment();

  return (
    <div className="side-menu">
      <div>
        <Row>
          <Col lg={14} xs={24} className="pl-3">
            <div className="menu-title">My Campaigns</div>
          </Col>
          {!!archivedCampaigns.length && (
            <Col lg={10} xs={24} className={`${squishedNav ? 'pr-4' : 'pr-3'}`}>
              <Link to="/campaigns/history" className="sub-menu-btn archive mb-1">
                <span>ARCHIVE</span>
              </Link>
            </Col>
          )}
        </Row>

        {/* {(hideCreation || !incomplete) && <CampaignSubMenu />} */}
        <CampaignSubMenu showCampaigns={!noCampaigns}>
          {noCampaigns && <ExampleItem key="campaigns/example" />}
          {!dontShowCreateButton && (
            <Menu.Item key="campaigns/new">
              <Link
                to="/campaigns/new"
                className={`campaign-link ${onBoarding && 'first-campaign'}`}
                style={{ paddingTop: 1 }}
              >
                <Icon className="add-campaign" component={AddCampaignIcon} />
                <span
                  style={{ fontSize: '10px', verticalAlign: 'middle' }}
                  className="hidden-mobile"
                >
                  {buttonText}
                </span>
              </Link>
            </Menu.Item>
          )}
        </CampaignSubMenu>
      </div>
      <Row style={{ marginTop: '45px' }}>
        <Col xs={24} className="pl-3">
          <div className="menu-title">More From Trend</div>
        </Col>
      </Row>

      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[selectedKeys]}
        defaultOpenKeys={['campaigns']}
        className="trend-menu-inline secondary mb-6"
      >
        <Menu.Item key="posts" className="sub-menu-item">
          <Link to="/posts">
            <span className="box-border icon-posts">
              <IconPosts />
            </span>

            <span className="hidden-mobile">Latest Posts</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="recentCampaigns" className="sub-menu-item">
          <Link to="/exampleCampaigns">
            <span className="box-border">
              <IcontStar />
            </span>

            <span className="hidden-mobile">Example Campaigns</span>
          </Link>
        </Menu.Item>
        {(isAdmin || isContentAdmin === 'true') && (
          <Menu.Item key="admin/">
            <Link to="/admin">
              <Icon
                type="user"
                style={{ width: '25px', height: '25px', fontSize: '18px' }}
                className="ml-1"
              />
              <span className="hidden-mobile"> Access Employee Functions </span>
            </Link>
          </Menu.Item>
        )}
        {lastPurchaseDate && (
          <Menu.Item key="free-credits" className="sub-menu-item">
            <Link to="/free-credits/refer">
              <span className="box-border">$</span>
              <span className="hidden-mobile"> Earn Free Credits </span>
            </Link>
          </Menu.Item>
        )}

        <Menu.Item key="help-center" className="sub-menu-item">
          <a target="_blank" href="https://intercom.help/trendapp/en/" rel="noopener noreferrer">
            <span
              className="box-border"
              style={{ fontSize: '22px', fontWeight: '600', lineHeight: '34px' }}
            >
              ?
            </span>
            <span className="hidden-mobile"> Help Center </span>
          </a>
        </Menu.Item>
      </Menu>

      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[selectedKeys]}
        defaultOpenKeys={['campaigns']}
        className={`trend-menu-inline settings ${(expiredOnBoardingCampaign || onBoardingActive) &&
          showMarqueNotif &&
          'with-notification'}`}
      >
        <Menu.Item key="store/edit">
          <Link
            to={routes.ACCOUNT_SETTINGS_PATH}
            style={{ width: squishedNav ? 'inherit' : '210px' }}
          >
            <span className="box-border">
              <IconSettings style={{ width: '19px', height: '19px' }} />
            </span>
            {!squishedNav && <span>Account Settings</span>}
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default compose(
  withRouter,
  withCookies
)(CampaignMenus);
