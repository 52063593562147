import React from 'react';
import { Spin, Icon } from 'antd';
import FileUploader from 'react-firebase-file-uploader';
import { firebase } from '../../lib/Firebase';

class ImageUpload extends React.Component {
  static getDerivedStateFromProps(nextProps) {
    if ('value' in nextProps) {
      return { image: nextProps.value };
    }
    return null;
  }

  state = {
    image: '',
    loading: false,
    progress: 0
  };

  handleUploadStart = () =>
    this.setState({
      loading: true,
      progress: 0
    });

  handleProgress = progress => this.setState({ progress });

  handleUploadError = () => {
    this.setState({ loading: false });
  };

  handleUploadSuccess = filename => {
    const { onChange } = this.props;
    this.setState({ loading: false });

    firebase.storage
      .ref('images')
      .child(filename)
      .getDownloadURL()
      .then(url => {
        if (onChange) {
          console.log(url);
          onChange(url);
        }
      });
  };

  render() {
    const { image, loading, progress } = this.state;
    const { filename, children } = this.props;
    const uploadButton = (
      <FileUploader
        hidden
        required
        accept="image/*"
        filename={filename}
        storageRef={firebase.storage.ref('images')}
        onUploadStart={this.handleUploadStart}
        onUploadError={this.handleUploadError}
        onUploadSuccess={this.handleUploadSuccess}
        onProgress={this.handleProgress}
      />
    );

    return (
      <div align="center" className="product-uploader">
        {loading && <p className="white">{loading}</p>}
        <Spin tip={`${progress}%`} spinning={loading} style={{ marginTop: '140px' }} />
        {image && !loading && (
          <div>
            <img src={image} className="product-image" alt={image} />

            <label className="ant-btn trend-btn edit-btn">
              <span>Change Photo</span>
              {uploadButton}
            </label>
          </div>
        )}
        {!image && !loading && (
          <label align="center" className="product-label">
            {!image && <Icon type="plus" className="icon" />}
            {children}
            {uploadButton}
          </label>
        )}
      </div>
    );
  }
}

export default ImageUpload;
