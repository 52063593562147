import Promise from 'bluebird';
import { retrieveAndCompressFiles, retrieveFile } from '../../lib/Download/resourceDownloader';
import { firebase } from '../../lib/Firebase';

const generateResource = async (post, filename) => {
  const images = [...(post.images || [])];
  const { type, fullResImage, standardResolutionImage, highResVideo } = post;
  let resources = [];

  if (!type || type === 'image') {
    resources = [
      {
        filename,
        url: fullResImage,
        fallbackUrl: standardResolutionImage
      }
    ];

    if (images && images.length > 1) {
      images.shift();
      images.forEach((image, index) => {
        resources = [
          ...resources,
          {
            filename: `${filename}-${index + 1}`,
            url: image.standardResFilePath || image.fullResFilePath
          }
        ];
      });
    }

    if (['video', 'story'].includes(type)) {
      resources = [
        ...resources,
        {
          filename: `${filename}-vid`,
          url: highResVideo
        }
      ];
    }
  }

  if (['video', 'story'].includes(type)) {
    resources = [
      ...resources,
      {
        filename: `${filename}-vid`,
        url: highResVideo
      }
    ];
  }

  await firebase.trackDownloadPost(filename);

  return resources;
};

export const postResourceDownloader = async (post = {}, postUID = '', filename = '') => {
  const resources = await generateResource(post, postUID);

  if (resources.length > 1) {
    return retrieveAndCompressFiles(resources, filename);
  }

  return retrieveFile(resources[0].url, filename, resources[0].fallbackUrl);
};

export const downloadAllPostResources = async (posts = {}, additionalMedia = {}, filename) => {
  let resources = [];
  Object.keys(additionalMedia).forEach(influencerUID => {
    const am = additionalMedia[influencerUID];

    if (am.images) {
      Object.keys(am.images).forEach(id => {
        const amImage = am.images[id];
        resources = [...resources, { url: amImage, filename: id }];
      });
    }

    if (am.videos) {
      Object.keys(am.videos).forEach(id => {
        const amVideo = am.videos[id];
        resources = [...resources, { url: amVideo.url, filename: id }];
      });
    }
  });

  await Promise.map(Object.keys(posts), async postUID => {
    const post = posts[postUID];
    if (!['video', 'story'].includes(post.type)) {
      const res = await generateResource(posts[postUID], postUID);
      resources = [...resources, ...res];
    }
  });

  return retrieveAndCompressFiles(resources, filename);
};
