import React from 'react';
import { Menu } from 'antd';
import { Switch, Redirect, Route, useLocation, Link } from 'react-router-dom';
import Container from '../../components/Common/Container';
import PageHeader from '../../components/Common/PageHeader';
import EarnCreditsImg from '../../images/Earn_Credits@2x.png';
import Refers from './ReferPage';

const Main = () => {
  const { pathname } = useLocation();

  return (
    <div id="free-credits-page">
      <PageHeader>
        <Container width={733}>
          <h1>
            Earn Free Credits <img src={EarnCreditsImg} alt="Earn Credits" />
          </h1>
          <Menu mode="horizontal" selectedKeys={[pathname]}>
            <Menu.Item key="/free-credits/refer">
              <Link to="/free-credits/refer">Refer a Brand</Link>
            </Menu.Item>
          </Menu>
        </Container>
      </PageHeader>

      <div className="page-body">
        <div className="page-content">
          <Switch>
            <Route path="/free-credits/refer" exact component={Refers} />
            <Redirect from="/free-credits" to="/free-credits/refer" />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Main;
