import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import {
  Layout,
  Row,
  Col,
  Form,
  Button,
  Input,
  Modal,
} from 'antd';
import { ReactComponent as IconBack } from 'images/icon-back-arrow-white.svg';
import trendLogo from 'images/trend@2x.png';
import doUpdateEmail from 'actions/users';

const { Content } = Layout;

const hasErrors = fieldsError => (
  Object.keys(fieldsError).some(field => fieldsError[field])
);

const defaultState = {
  error: {},
  visible: false,
  submitted: false,
};

class ChangeEmail extends Component {
  state = defaultState;

  componentDidMount() {
    const { form } = this.props;
    form.validateFields();
  }

  onSubmit = (e) => {
    e.preventDefault();

    const { form } = this.props;

    const {
      email,
      currentPassword,
    } = form.getFieldsValue();

    this.setState(() => ({ submitted: true }));
    doUpdateEmail(email, currentPassword, (isSuccess, { error } = {}) => {
      if (isSuccess) {
        this.setState(() => ({ visible: true }));
      } else {
        this.setState(() => ({ error }));
      }

      this.setState(() => ({ submitted: false }));
    });
  }

  goBack = () => {
    const { history } = this.props;

    if (history.location.key) {
      history.goBack();
    } else {
      history.replace('/account/store/edit');
    }
  };

  render() {
    const {
      error,
      visible,
      submitted,
    } = this.state;

    const {
      form,
      history,
      authUser,
    } = this.props;

    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched,
    } = form;

    const emailError = isFieldTouched('email') && getFieldError('email');
    const currentPasswordError = isFieldTouched('currentPassword') && getFieldError('currentPassword');

    return (
      <div className="bg-black">
        <div className="action-link clickable" align="center">
          <div className="back">
            <div onClick={this.goBack} role="presentation" style={{ color: '#ffb917' }}>
              <IconBack />
              <span className="white d-block type-sbold bsize-4">
                Back
              </span>
            </div>
          </div>
        </div>
        <div style={{ textAlign: 'center', padding: '4rem 0' }}>
          <img
            alt="Trend"
            src={trendLogo}
            width="162"
            height="31"
            style={{ display: 'inline-block' }}
          />
        </div>
        <h1 style={{ textAlign: 'center', color: '#fff' }}>Change Email</h1>
        <h3 style={{ textAlign: 'center', color: '#fff' }}>{authUser.email}</h3>
        <div>
          <Layout>
            <Content style={{ padding: 24, background: '#000' }}>
              <Row type="flex" justify="space-around" align="middle">
                <Col span={6}>
                  <Form layout="vertical" onSubmit={this.onSubmit}>
                    <Form.Item validateStatus={emailError ? 'error' : ''}>
                      <label className="trend-label">New Email</label>
                      {
                        getFieldDecorator('email', {
                          rules: [{
                            required: true,
                            message: 'Email is required!',
                          }, {
                            type: 'email',
                            message: 'Invalid Email!',
                          }],
                        })((
                          <Input
                            type="text"
                            size="large"
                            name="email"
                            className="trend-input"
                          />
                        ))
                      }
                    </Form.Item>
                    <Form.Item validateStatus={currentPasswordError ? 'error' : ''}>
                      <label className="trend-label">Input Password</label>
                      {
                        getFieldDecorator('currentPassword', {
                          rules: [{
                            required: true,
                            message: 'Input Password is required!',
                          }],
                        })((
                          <Input
                            type="password"
                            size="large"
                            className="trend-input"
                          />
                        ))
                      }
                    </Form.Item>
                    <Button
                      block
                      loading={submitted}
                      size="large"
                      type="submit"
                      className="trend-btn"
                      htmlType="submit"
                      disabled={submitted || hasErrors(getFieldsError())}
                    >
                      Change Email
                    </Button>
                    {
                      Object.keys(error).length > 0 && (
                        <p style={{ color: '#f5222d' }}>{error.message}</p>
                      )
                    }
                  </Form>
                </Col>
              </Row>
            </Content>
          </Layout>
          <Modal
            visible={visible}
            closable={false}
            className="trend-modal"
            width={300}
            footer={[
              <Button
                key="okay"
                onClick={() => {
                  this.setState(() => defaultState);
                  history.push('/account/store/edit');
                }}
                className="trend-btn huge-btn"
                block
              >
                Okay
              </Button>,
            ]}
          >
            <span className="bsize-4 text-black leading-18px">
              Email address was successfully updated!
            </span>
          </Modal>
        </div>
      </div>
    );
  }
}

const WrappedChangeEmailForm = Form.create({ name: 'change_email' })(ChangeEmail);

export default compose(withRouter)(WrappedChangeEmailForm);
