import React from 'react';
import { Avatar } from 'antd';
import { ReactComponent as IconVip } from 'images/icon-vip-profile.svg';

const InfluencerAvatar = ({ influencer }) => (
  <div>
    <div className="p-relative">
      <Avatar src={influencer.avatar} size={50} style={{ float: 'left' }} />
      {
        influencer.isVIP && (
          <IconVip
            style={{ position: 'absolute', top: '35px', left: '35px' }}
          />
        )
      }
    </div>
    <div style={{ position: 'relative', float: 'left', marginLeft: '10px' }}>
      <div className="type-sbold size-12px text-black" style={{ paddingLeft: '3px', paddingTop: '8px' }}>
        @
        {influencer.username}
      </div>
      <div style={{ fontSize: '11px', color: '#5b6572', paddingLeft: '3px' }}>
        {influencer.state}
      </div>
    </div>
  </div>
);

export default InfluencerAvatar;
