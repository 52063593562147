import React, { Component } from 'react';
import { Row, Button, Form, message, Input, notification } from 'antd';
import { connect } from 'react-redux';
import { goToAnchor, configureAnchors } from 'react-scrollable-anchor';
import { compose } from 'recompose';
import { createCard, updateCardDetails } from 'api/subscriptions';
import { withRouter } from 'react-router-dom';
import { withFirebase } from 'lib/Firebase';
import { AuthUserContext, withAuthorization } from 'lib/Session';
import { StripeProvider, Elements, injectStripe } from 'react-stripe-elements';
import { updateSession } from 'actions/session';
import config from 'app_config';
import withBrand from '../Helpers/WithBrand';
import PaymentForm from './PaymentForm';
import ImageUpload from '../Form/ImageUploader';

configureAnchors({ offset: -60, scrollDuration: 200 });
const stripePublishKey = config.STRIPE_PUB_KEY;

const defaultState = {
  name: '',
  storeImage: '',
  description: '',
  stripeCustomerId: null,
  isUploading: false,
  updateCard: false,
  showPaymentForm: false,
  progress: 0,
  error: null,
  submitting: false
};

class StoreEditFormBase extends Component {
  state = defaultState;

  _isMounted = false;

  componentDidMount() {
    const {
      history,
      brandDetails: { name, storeImage, description, stripeCustomerId },
      form: { setFieldsValue }
    } = this.props;

    setFieldsValue({
      name,
      storeImage,
      description
    });

    this.setState({
      stripeCustomerId,
      showPaymentForm: !stripeCustomerId
    });

    const {
      location: { hash = '' }
    } = history;
    goToAnchor(hash.replace('#', ''));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onSuccess = () => {
    notification.success({
      message: 'Company',
      description: 'Settings Saved!',
      duration: 2
    });
  };

  onSubmit = async e => {
    e.preventDefault();
    this.setState({ submitting: true });
    let { stripeCustomerId = '' } = this.state;
    const { updateCard, showPaymentForm } = this.state;
    const {
      form: { validateFieldsAndScroll },
      firebase,
      authUser,
      stripe,
      dispatchUpdateSession
    } = this.props;

    validateFieldsAndScroll(async (error, fields) => {
      if (!error) {
        if (showPaymentForm) {
          const stripeResponse = await stripe.createToken({ name: authUser.email });
          if (!stripeCustomerId) {
            if (!stripeResponse.error) {
              const { token } = stripeResponse;

              try {
                const { stripeCustomerId: cusUID } = await createCard(token);
                stripeCustomerId = cusUID;
              } catch (err) {
                message.error(err.message);
                this.setState({ submitting: false });
                return;
              }
            } else {
              message.error(stripeResponse.error.message);
              this.setState({ submitting: false });
              return;
            }
          } else if (updateCard) {
            if (!stripeResponse.error) {
              const { token } = stripeResponse;

              try {
                await updateCardDetails(token);
              } catch (err) {
                message.error(err.message);
                this.setState({ submitting: false });
                return;
              }
            } else {
              message.error(stripeResponse.error.message);
              this.setState({ submitting: false });
              return;
            }
          }
        }

        await firebase.brandInfo(authUser.uid).update({
          ...fields,
          logo: fields.storeImage,
          stripeCustomerId
        });
        this.onSuccess();
        dispatchUpdateSession({ details: { ...fields, stripeCustomerId } });

        let newState = {
          stripeCustomerId,
          submitting: false,
          showPaymentForm: false
        };
        if (updateCard) {
          newState = { ...newState, updateCard: false };
        }
        this.setState(newState);
        this.setState({ error });
        this.setState({ submitting: false });
      } else {
        this.setState({ submitting: false });
      }
    });
  };

  onChange = e => {
    const { target } = e;
    this.setState({ [target.name]: target.value });
  };

  handleCardUpdate = () => {
    this.setState({ updateCard: true, showPaymentForm: true });
  };

  handleCardCancel = () => {
    this.setState({ updateCard: false, showPaymentForm: false });
  };

  render() {
    const {
      firebase,
      form: { getFieldDecorator }
    } = this.props;
    const brandUID = firebase.auth.currentUser.uid;
    const { error, submitting, showPaymentForm } = this.state;

    return (
      <Form
        layout="vertical"
        onSubmit={this.onSubmit}
        className="product-form pb-8"
        hideRequiredMark
      >
        <Form.Item>
          {getFieldDecorator('storeImage', { rules: [{ required: true, message: 'Required' }] })(
            <ImageUpload filename={brandUID}>
              <div className="label">Add any image that represents your brand</div>
            </ImageUpload>
          )}
        </Form.Item>
        <Form.Item label="Company Name">
          {getFieldDecorator('name', { rules: [{ required: true, message: 'Required' }] })(
            <Input
              type="text"
              size="large"
              name="name"
              style={{ marginTop: '5px' }}
              className="trend-input"
            />
          )}
        </Form.Item>
        <Form.Item label="Company Description">
          {getFieldDecorator('description', { rules: [{ required: true, message: 'Required' }] })(
            <Input.TextArea
              className="trend-textarea dark"
              placeholder="2-3 sentences that decribe your company"
              style={{ minHeight: '155px' }}
            />
          )}
        </Form.Item>
        <PaymentForm
          onUpdate={this.handleCardUpdate}
          showForm={showPaymentForm}
          onCancel={this.handleCardCancel}
        />
        <div align="center">
          <Button
            right="true"
            size="large"
            type="submit"
            className="trend-btn product-btn"
            onClick={this.onSubmit}
            loading={submitting}
          >
            Save Updates
          </Button>
        </div>
        {error && <p>{error.message}</p>}
      </Form>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchUpdateSession: details => dispatch(updateSession(details))
});

const mapStateToProps = state => ({
  brandDetails: state.entities.session.authUserData.details
});

const StoreEditPage = compose(
  Form.create({ name: 'store-edit' }),
  withRouter,
  withFirebase,
  withBrand,
  injectStripe,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(StoreEditFormBase);

const EditStore = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser && (
        <div style={{ background: '#000 !important' }}>
          <Row type="flex" justify="space-around">
            <div className="min-layout-content small">
              <StripeProvider apiKey={stripePublishKey}>
                <Elements>
                  <StoreEditPage authUser={authUser} />
                </Elements>
              </StripeProvider>
            </div>
          </Row>
        </div>
      )
    }
  </AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(EditStore);
