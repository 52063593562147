import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Tag, Button, message, Popconfirm, Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { firebase } from '../../../lib/Firebase';
import { PARTNERSHIP_PAYOUT_STATUS } from '../../../constants/partnerships';
import { useInfluencerCampaigns } from '../Campaigns';
import { fetchBrand } from '../../../actions/brands';

const statusColor = {
  processing: 'orange',
  paid: 'green',
  denied: 'red'
};

const PayoutStatus = ({ status }) => {
  if (!status) return <div />;

  return (
    <Tag color={statusColor[status]} style={{ textTransform: 'uppercase' }}>
      {status}
    </Tag>
  );
};

const CompletedCampaign = ({ influencerUID, campaign, partnership }) => {
  const dispatch = useDispatch();
  const brand = useSelector(state => state.entities.brands.byId[campaign.brandUID] || {});

  const update = useInfluencerCampaigns(influencerUID)[2];
  const [loading, setLoading] = useState(false);
  const { uid } = campaign;
  const applications = campaign.appliedUID[influencerUID];
  const { payoutStatus, payoutStatusMessage } = partnership;

  let { dueDate, completedAt } = applications;
  const { payoutAmount } = applications;
  dueDate = dueDate && moment(dueDate);
  completedAt = completedAt && moment(completedAt);
  let diff;
  if (dueDate && completedAt) {
    diff = completedAt.diff(dueDate, 'days');
  }

  useEffect(() => {
    if (Object.keys(brand).length === 0 && campaign.brandUID) {
      fetchBrand(campaign.brandUID)(dispatch);
    }
  }, [dispatch, brand, campaign]);

  async function handleResend() {
    setLoading(true);
    try {
      await firebase.callService({
        serviceName: 'payoutInfluencer',
        data: { influencerUID, productUID: uid, skipStatus: true }
      });
      message.success('Processing a new batch payment');
      setLoading(false);
      update(uid, 'completed', {
        ...partnership,
        payoutStatusMessage: '',
        payoutStatus: 'processing'
      });
    } catch (e) {
      message.error('Unauthorized access, please switch to an admin brand');
    }
  }

  return (
    <div className="campaign-details" key={campaign.key}>
      {brand.details && brand.details.name && (
        <div className="campaign-details-brand-name">{brand.details.name}</div>
      )}
      <div className="campaign-details-name">{campaign.name}</div>
      <div className="campaigns-details-status">
        <div className="details-status">
          <div className="status">
            <div className="status-value">{completedAt ? completedAt.format('M/D/YY') : '--'}</div>
            <div className="status-label">Date Completed</div>
          </div>
        </div>
        <div className="details-status">
          <div className="status">
            <div className="status-value">{diff ? `${diff} days` : `--`}</div>
            <div className="status-label">Days Completed after due date</div>
          </div>
        </div>
        <div className="details-status">
          <div className="status">
            <div className="status-value">
              <PayoutStatus status={payoutStatus} /> {payoutAmount || `--`}
            </div>
            {payoutStatus === PARTNERSHIP_PAYOUT_STATUS.DENIED && (
              <>
                <div className="status-value" />
                <div className="status-value">
                  <Alert
                    type="error"
                    description={(
                      <>
                        <p>{payoutStatusMessage}</p>
                        <Popconfirm
                          title="Are you sure you want to resend a new payment?"
                          onConfirm={handleResend}
                        >
                          <Button loading={loading} type="primary">
                            Resend Payout
                          </Button>
                        </Popconfirm>
                      </>
)}
                  />
                </div>
              </>
            )}
            <div className="status-label">payment</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletedCampaign;
