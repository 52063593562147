import { combineReducers } from 'redux';
import entities from './entities';
import ui from './ui';
import listeners from './listeners';

export default combineReducers({
  entities,
  ui,
  listeners
});
