import React from 'react';
import { Row, Col } from 'antd';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';
import { DATE_TIMEZONE_FORMAT } from 'constants/common';
import PostCardImage from 'components/Common/PostCardImage';

const PostItem = ({ post, postUID, username }) => (
  <Row className="post-item" style={{ marginBottom: '15px' }}>
    <Col
      span={13}
      style={{
        cursor: 'pointer'
      }}
      onClick={() => {
        window.open(post.link, '_blank');
      }}
    >
      <div
        className="post-item-avatar"
        style={{
          display: 'inline-block',
          width: '40px',
          height: '40px',
          overflow: 'hidden'
        }}
      >
        <PostCardImage postUID={postUID} withLabel={false} />
      </div>
      <div className="post-item-details">
        <strong
          className="size-10px text-black type-sbold d-block post-item-details-name"
          style={{ lineHeight: '0.8' }}
          title={`@${username}`}
        >
          @{username}
        </strong>
        {post.type !== 'story' && (
          <small className="size-9px text-black type-regular post-item-details-date">
            {moment(post.createdTime, DATE_TIMEZONE_FORMAT).format('M/D/YYYY')}
          </small>
        )}
      </div>
    </Col>
    {post.type !== 'story' && (
      <>
        <Col span={5} className="text-right text-black size-12px type-regular">
          <CurrencyFormat value={post.comments} displayType="text" thousandSeparator />
        </Col>
        <Col span={6} className="text-right text-black size-12px type-regular">
          <CurrencyFormat value={post.likes} displayType="text" thousandSeparator />
        </Col>
      </>
    )}
  </Row>
);

export default PostItem;
