import React, { Component } from 'react';
import {
  Row,
  Button,
  Form,
  Input,
  notification,
} from 'antd';
import { compose } from 'recompose';
import FileUploader from 'react-firebase-file-uploader';
import { withRouter } from 'react-router-dom';
import { withFirebase } from 'lib/Firebase';
import Random from 'random-key';
import { ReactComponent as TrendLogoWeb } from 'images/trend-logo-web.svg';
import moment from 'moment';
import { DATE_TIMEZONE_FORMAT } from 'constants/common';
import { AuthUserContext, withAuthorization } from 'lib/Session';
import SignOutButton from 'components/Signout';


const { TextArea } = Input;

const defaultState = {
  email: '',
  company_name: '',
  storeImage: '',
};

class TrialCompanyProfile extends Component {
  state = defaultState;

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;

    const {
      firebase,
      history,
    } = this.props;

    firebase.trials().orderByChild('hash').equalTo(this.props.match.params.hash).once('child_added', (snapshot) => {
      if (this._isMounted) {
        if (!snapshot.val()) {
          history.push("/login") //Need to change this to 404 perhaps?
        } else {
          const { email, firstName, lastName } = snapshot.val();
          this.setState({ email, firstName, lastName, trialId: snapshot.key, hash: this.props.match.params.hash });          
        }
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

  handleProgress = progress => this.setState({ progress });

  handleUploadError = () => {
    this.setState({ isUploading: false });
  }

  handleUploadSuccess = (filename) => {
    this.setState({ progress: 100, isUploading: false });

    const { firebase } = this.props;

    firebase.storage.ref('brands').child(filename).getDownloadURL().then((url) => {
      this.setState({ storeImage: url });
    });
  };

  onSuccess = () => {
    notification.success({
      message: 'Company',
      description: 'Profile Saved',
      duration: 2,
    });
  };

  onChange = (e) => {
    const { target } = e;
    this.setState({ [target.name]: target.value });
  }

  onSubmit = (event) => {
    const {
      company_name,
      description,
      storeImage,
      email,
      trialId,
      hash,
    } = this.state;

    const {
      firebase,
      authUser,
      history,
    } = this.props;
    
    const initBrandData = {
      name: company_name,
      description,
      storeImage,
      logo: storeImage,
      isPushNotificationsEnabled: true,
      isActive: true,
      isAdmin: false,
      isDisabled: false,
      isFree: true,
      createdAt: moment().format(DATE_TIMEZONE_FORMAT),
      credits: 0,
      standardCredits: 0,
      email,
      registeredAt: moment().format(DATE_TIMEZONE_FORMAT),
    };

    
    firebase.brandInfo(authUser.uid).update(initBrandData).then(() => {
      return firebase.trial(trialId).update({ registeredAt: moment().format(DATE_TIMEZONE_FORMAT) })
    }).then(() => {
      history.push(`/trial/${hash}/campaign`);
    }).catch((err) => {
        this.setState({ error: err });
    });

    event.preventDefault();
  };

  render() {
    const { firebase } = this.props;
    const {
      company_name,
      description,
      storeImage,
      error,
      isUploading,
      progress,
    } = this.state;

    return (
      <div style={{ background: '#000 !important', paddingTop: 30, }}>
        <div className="action-link clickable" align="center">
          <div className="sign-out">
            <AuthUserContext.Consumer>
              {authUser => (authUser && <SignOutButton />)}
            </AuthUserContext.Consumer>
          </div>
        </div>
        <div align="center">
          <TrendLogoWeb className="logo d-iblock" width="70px" style={{marginBottom: 38}} />
          <div
            style={{
              height: 57,
              width: 353,
              backgroundColor: '#0B0B0B',
              borderRadius: 57,
              padding: '13px 0 13px 0',
              fontSize: 12,
              textAlign: 'left',
            }}
          >
            <ul
              style={{
                color: '#DFDFDF',
                listStyle: 'none',
                margin: 0,
                padding: 0,
              }}
            >
              <li style={{width: '50%', float: 'left'}}>
                <div style={{position: 'relative'}}>
                  <span
                    style={{
                      position: 'absolute',
                      left: 16,
                      margin: 0,
                      padding: '0',
                      height: 28,
                      backgroundColor: '#FDAB14',
                      width: 28,
                      borderRadius: 28,
                      border: '1px solid #ffffff',
                    }}
                  >
                    <span style=
                      {{
                        position: 'absolute',
                        top: 3,
                        left: 11,
                      }}
                    >
                      1
                    </span>
                  </span>
                  <span style={{ position: 'relative', top: 4, left: 54 }}>Company Profile</span>
                </div>
              </li>
              <li style={{width: '50%', float: 'left'}}>
                <div style={{position: 'relative'}}>
                  <span
                    style={{
                      position: 'absolute',
                      left: 16,
                      margin: 0,
                      padding: '0',
                      height: 28,
                      backgroundColor: '#111111',
                      width: 28,
                      color: '#333333',
                      borderRadius: 28,
                      border: '1px solid #111111',
                    }}
                  >
                    <span style=
                      {{
                        position: 'absolute',
                        top: 4,
                        left: 9,
                      }}
                    >
                      2
                    </span>
                  </span>
                  <span style={{ position: 'relative', top: 4, left: 54, color: '#333333' }}>Campaign Set up</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="pt-7" align="center" style={{ color: '#DBDBDB' }}>
          <div className="min-layout-content small"
            style={{
              textAlign: 'center',
              maxWidth: 580,
            }}
          >
            <h4 style={{ color: '#DBDBDB', fontSize: 18 }}>Welcome to Trend!</h4>
            <p style={{ fontSize: 14, fontWeight: 'normal', lineHeight: '24px' }}>
              Tell us about your company so we can present you in the best possible light to our influencers. The information you enter here is what
              the influencers will see when they're browsing for potential partnerships.
            </p>
          </div>
        </div>
        <div className="pt-7" align="center">
          <Row
            type="flex"
            justify="space-around"
          >
            <div className="min-layout-content small">
              <Form layout="vertical" onSubmit={this.onSubmit} className="product-form pb-8">
                <Form.Item>
                  <div className="p-relative product-uploader" align="center">
                    {storeImage && <img src={storeImage} alt="" className="product-image" />}
                    <label className="ant-btn trend-btn edit-btn">
                      {!isUploading && storeImage && <span>Change Photo</span>}
                      {!isUploading && !storeImage && <span>Add Company Store Image</span>}
                      {isUploading && <span style={{ color: '#fff' }}>Uploading {progress}%... </span>}
                      <FileUploader
                        hidden
                        accept="image/*"
                        filename={() => Random.generate(30)}
                        storageRef={firebase.storage.ref('brands')}
                        onUploadStart={this.handleUploadStart}
                        onUploadError={this.handleUploadError}
                        onUploadSuccess={this.handleUploadSuccess}
                        onProgress={this.handleProgress}
                      />
                    </label>
                  </div>
                </Form.Item>
                <Form.Item>
                  <div className="ant-form-item-label">
                    <label>Company Name</label>
                  </div>
                  <Input
                    type="text"
                    size="large"
                    name="company_name"
                    value={company_name}
                    onChange={this.onChange}
                    style={{ marginTop: '5px' }}
                    className="trend-input"
                  />
                </Form.Item>
                <Form.Item>
                  <div className="ant-form-item-label">
                    <label>Company Description</label>
                  </div>
                  <TextArea
                    rows={8}
                    size="large"
                    name="description"
                    value={description}
                    style={{ marginTop: '5px' }}
                    onChange={this.onChange}
                    className="trend-textarea"
                  />
                </Form.Item>
                <div align="center">
                  <Button right="true" size="large" type="submit" className="trend-btn product-btn" onClick={this.onSubmit}>Next</Button>
                </div>
                {error && <p>{error.message}</p>}
              </Form>
            </div>
          </Row>
        </div>
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withRouter,
  withFirebase,
  withAuthorization(condition),
)(TrialCompanyProfile);