import React, { useState } from 'react';
import { Row, Icon, AutoComplete, Col } from 'antd';
import { useSelector } from 'react-redux';

const { Option } = AutoComplete;
const Search = ({ onChange }) => {
  const [currentSearch, setCurrentSearch] = useState('');
  const [options, setOptions] = useState([]);
  const brandSearches = useSelector(state => state.entities.brandSearch);

  function handleChange(value) {
    if (onChange) {
      onChange({ search: value });
      setCurrentSearch(value);
    }
  }

  function handleSearch(value = '') {
    const data = brandSearches.all.filter(x => {
      const { name = '' } = brandSearches.byId[x] || {};
      return name.toLowerCase().includes(value.toLowerCase());
    });

    setOptions(data);
  }

  const list = options.map(x => (
    <Option key={x} value={x}>
      {brandSearches.byId[x].name}
    </Option>
  ));

  return (
    <Row className="search" type="flex" align="middle">
      <Col span={1}>
        <Icon type="search" />
      </Col>
      <Col span={23}>
        <AutoComplete
          allowClear
          onSearch={handleSearch}
          onSelect={handleChange}
          onChange={val => !val && currentSearch && handleChange(null)}
          placeholder="Search"
        >
          {list}
        </AutoComplete>
      </Col>
    </Row>
  );
};

export default Search;
