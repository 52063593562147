import { firebase } from '.';

class JobScheduler {
  constructor(serviceName, data) {
    this.serviceName = serviceName;
    this.data = data;
  }

  setData(attr = {}) {
    this.data = attr;
  }

  setService(serviceName = '') {
    this.serviceName = serviceName;
  }

  async performAt(timestamp) {
    await firebase.firestore.collection('jobs').add({
      serviceName: this.serviceName,
      data: this.data,
      status: 'scheduled',
      performAt: timestamp
    });
  }
}

export default JobScheduler;
