import React, { useState, useEffect } from 'react';
import { Icon, Button, Spin } from 'antd';
import { ReactComponent as RightIcon } from '../../../images/arrow-right.svg';
import { ReactComponent as LeftIcon } from '../../../images/arrow-left.svg';

const PostImage = props => {
  const { post, withLabel, postImages } = props;
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { standardResolutionImage, fullResImage } = post;
    let defaultImg = standardResolutionImage;

    if (!standardResolutionImage || standardResolutionImage === 'none') {
      defaultImg = fullResImage;
    }

    const imagesTmp = postImages.slice(1).map(image => image.standardResFilePath);

    setImages([defaultImg, ...imagesTmp]);
  }, [postImages]);

  const handleNextPage = () => {
    setPage(page + 1);
    setLoading(true);
  };

  const handlePrevPage = () => {
    setPage(page - 1);
    setLoading(true);
  };

  const handleErrorImage = () => {
    const { fullResImage } = post;

    if (fullResImage) {
      const updateImages = [fullResImage, ...images.slice(1)];
      setImages(updateImages);
    }
  };

  const handleImageLoaded = () => {
    setLoading(false);
  };

  const actions = () => {
    if (images.length < 2) {
      return <div />;
    }
    return (
      <div className="post-gallery-actions">
        {page > 0 && (
          <Button className="action-previous" type="link" onClick={handlePrevPage}>
            <Icon component={LeftIcon} />
          </Button>
        )}

        {page < images.length - 1 && (
          <Button className="action-next" type="link" onClick={handleNextPage}>
            <Icon component={RightIcon} />
          </Button>
        )}
      </div>
    );
  };

  return (
    <div className="post-card-image">
      <Spin spinning={loading}>
        <a href={`https://instagram.com/p/${post.code}`} target="_blank" rel="noopener noreferrer">
          <img
            src={images[page]}
            alt="im-1"
            onError={handleErrorImage}
            onLoad={handleImageLoaded}
          />
        </a>
        {actions()}
        {withLabel && (
          <div className="post-type-wrapper">
            <div className={`post-type-label type-${post.type || 'image'}`}>
              {post.type || 'Image'} Post
            </div>
          </div>
        )}
      </Spin>
    </div>
  );
};

export default PostImage;
