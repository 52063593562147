import React from 'react';
import { Form, Input, Row, Col, Button, InputNumber } from 'antd';
import ImageUpload from '../../Form/ImageUploader';

class Basic extends React.Component {
  state = {
    submitting: false
  };

  handleSubmit = () => {
    const {
      onSubmit,
      onError,
      form: { validateFieldsAndScroll }
    } = this.props;

    validateFieldsAndScroll(async (error, fields) => {
      if (!error) {
        onError(false);
        onSubmit(fields);
      } else {
        onError(true);
        this.setState({ submitting: false });
      }
    });
  };

  handleError = () => {
    const { onError } = this.props;
    console.log('has Error');
    onError(true);
  };

  render() {
    const { submitting } = this.state;
    const { form, campaignUID, campaign } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form
        layout="vertical"
        className="product-form pb-8"
        hideRequiredMark
        onSubmit={this.handleSubmit}
        onErrorCapture={this.handleError}
      >
        <h1 className="page-header">Your Product Info</h1>
        <div className="page-description">
          Add an attractive image of your product to catch the creators attention, and set the stage
          for the results you’re looking for.
        </div>

        <Form.Item label="Name your campaign" style={{ marginBottom: '39px' }}>
          {getFieldDecorator('name', {
            initialValue: campaign.name,
            rules: [{ required: true, message: 'Add a campaign name' }]
          })(
            <Input
              type="text"
              placeholder="Add a campaign name"
              size="large"
              className="trend-input dark large type-light"
            />
          )}
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          {getFieldDecorator('image', {
            initialValue: campaign.image,
            rules: [{ required: true, message: 'Campaign Image is required.' }]
          })(
            <ImageUpload
              filename={campaignUID}
              ref={ref => {
                this.uploaderRef = ref;
              }}
            >
              <div className="label">Add any image of your product</div>
            </ImageUpload>
          )}
        </Form.Item>
        <Row gutter={{ sm: 30 }}>
          <Col sm={12} xs={24}>
            <Form.Item label="Product Website">
              {getFieldDecorator('url', {
                initialValue: campaign.url,
                rules: [
                  {
                    transform: urlLink => {
                      if (!/^https?:\/\//i.test(urlLink)) {
                        return `http://${urlLink}`;
                      }

                      return urlLink;
                    }
                  },
                  {
                    required: true,
                    message: 'Please enter website'
                  },
                  {
                    type: 'url',
                    message: 'Please provide a valid website URL.'
                  }
                ]
              })(
                <Input
                  type="text"
                  size="large"
                  className="trend-input dark"
                  placeholder="http://www.website.com"
                />
              )}
            </Form.Item>
          </Col>
          <Col sm={12} xs={24}>
            <Form.Item label="Product value in dollars">
              <div>
                {getFieldDecorator('value', {
                  initialValue: campaign.value,
                  rules: [
                    {
                      required: true,
                      message: 'Please provide a numeric value.'
                    }
                  ]
                })(
                  <InputNumber
                    className="trend-input dark"
                    placeholder="$50.00"
                    formatter={v => v && `$${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={v => v.replace(/\$\s?|(,*)/g, '')}
                  />
                )}
              </div>
            </Form.Item>
          </Col>
        </Row>
        <div align="center">
          <Button
            size="large"
            type="submit"
            className="trend-btn product-btn"
            loading={submitting}
            onClick={this.handleSubmit}
          >
            Next Step - Creators
          </Button>
        </div>
      </Form>
    );
  }
}

export default Form.create({ name: 'basic-pane' })(Basic);
