export const SET_POST_GRAPH_FILTER_TYPE = 'SET_POST_GRAPH_FILTER_TYPE';

const initialState = {
  filteredBy: '1month', // 1week, 1month, 3months, 6months, 1year
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_POST_GRAPH_FILTER_TYPE:
      return {
        ...state,
        filteredBy: action.payload,
      };

    default:
      return state;
  }
};
