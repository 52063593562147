/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component, createRef } from 'react';
import { Icon, Spin } from 'antd';
import { ReactComponent as PlayButtonSVG } from '../../images/play-btn.svg';

class Video extends Component {
  state = {
    playing: false,
    loading: true
  };

  static defaultProps = {
    videoStyle: {},
    video: null,
    playing: false
  };

  constructor(props) {
    super(props);

    this.vidRef = createRef();
  }

  componentDidMount() {
    const { playing } = this.props;

    this.vidRef.current.addEventListener('ended', event => {
      // eslint-disable-next-line no-param-reassign
      event.target.currentTime = 0;
      this.setState({ playing: false });
    });

    if (playing) {
      this.togglePlay();
    }
  }

  togglePlay = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const { playing } = this.state;
    if (!playing) {
      this.vidRef.current.play();
    } else {
      this.vidRef.current.pause();
    }
    this.setState({ playing: !playing });
  };

  handleLoad = () => {
    this.setState({ loading: false });
  };

  render() {
    const { post, videoStyle, withLabel } = this.props;
    const { highResVideo: video, type } = post;
    const { loading, playing } = this.state;
    return (
      <div className="post-card-video" onClick={this.togglePlay}>
        {withLabel && (
          <div className="post-type-wrapper">
            <div className={`post-type-label type-${type}`}>{type} Post</div>
          </div>
        )}
        <div className="post-card-video-actions">
          <div className="post-card-video-actions-button">
            {!playing && <Icon component={PlayButtonSVG} style={{ width: '100%' }} />}
          </div>
        </div>
        <Spin spinning={loading}>
          <video
            ref={this.vidRef}
            src={video}
            preload="auto"
            type="video/mp4"
            style={{ objectFit: 'cover', ...videoStyle }}
            onLoadedData={this.handleLoad}
          />
        </Spin>
      </div>
    );
  }
}

export default Video;
