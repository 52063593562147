import {
  receiveBrands,
} from 'actions/brands';
import { fetchBrands } from 'api/brands';

import {
  receiveTrial,
  receiveTrials,
} from 'actions/trials';
import { sendTrial, fetchFreeTrials, resendTrial } from 'api/trials';

export const getBrands = () => (dispatch) => {
  fetchBrands((brands, err) => {
    if (!err) {
      dispatch(receiveBrands(brands));
    }
  });
};

export const getFreeTrials = () => (dispatch) => {
  fetchFreeTrials((trials, err) => {
    if (!err) {
      dispatch(receiveTrials(trials));
    }
  })
}

export const sendFreeTrial = params => (dispatch) => {
  sendTrial(params, (trial, err) => {
    if (!err) {
      dispatch(receiveTrial(trial))
    }
  })
}

export const resendFreeTrial = id => (dispatch) => {
  resendTrial(id, (trial, err) => {
    if (!err) {
      dispatch(receiveTrial(trial))
    }
  })
}