// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as IconNotificationBell } from 'images/notification-bell.svg';
import { firebase } from '../../lib/Firebase';
import { listenNotification } from '../../listeners/notifications';

const Notifications = () => {
  const dispatch = useDispatch();

  const [brandUID, setBrandUID] = useState(undefined);
  const [requestPermission, setRequestPermission] = useState(false);
  const [tokensList, setTokensList] = useState([]);

  const session = useSelector(state => state.entities.session.authUserData);
  const notificationData = useSelector(state => state.entities.notifications.byId[brandUID] || {});
  const { newApplication, newPost, newMessage, tokens = [] } = notificationData;

  useEffect(() => {
    if (session && session.uid) {
      setBrandUID(session.uid);
    }
  }, [session, setBrandUID]);

  useEffect(() => {
    if (tokensList.length > 0 && brandUID) {
      const saveTokens = async () => {
        await firebase
          .notifications()
          .doc(brandUID)
          .set({ tokens: tokensList }, { merge: true });
      };

      saveTokens();
      setTokensList([]);
    }
  }, [setTokensList, tokensList, brandUID]);

  const notifWarning = () => {
    notification.warning({
      message: 'Notification',
      description: 'This browser does not support notifications.',
      duration: 5
    });
  };

  const grantedPermission = async () => {
    if (requestPermission) {
      return;
    }

    setRequestPermission(true);

    let token;

    // bug:
    // https://www.gitmemory.com/issue/firebase/firebase-js-sdk/2364/554778269
    try {
      try {
        token = await firebase.messaging.getToken();
      } catch {
        token = await firebase.messaging.getToken();
      }

      // eslint-disable-next-line no-console
      console.log('token: ', token);

      if (!localStorage.getItem('notifToken') && (newApplication || newPost || newMessage)) {
        localStorage.setItem('notifToken', token);

        notification.success({
          message: 'Notifications are on!',
          description: "We'll alert you at key points during your campaign",
          duration: 3,
          className: 'ant-notification trend-notifcation notif-on',
          icon: <IconNotificationBell />
        });
      }

      setTokensList([...new Set([...tokens, token])]);
    } catch {
      notifWarning();
    }
  };

  const handlPermission = async permission => {
    if (!('permission' in Notification)) {
      Notification.permission = permission;
    }

    if (permission === 'granted') {
      await grantedPermission();
    }

    if (permission !== 'granted') {
      localStorage.removeItem('notifToken');
    }
  };

  const checkPermission = () => {
    if ('Notification' in window) {
      try {
        Notification.requestPermission().then(handlPermission);
      } catch {
        Notification.requestPermission(handlPermission);
      }
    } else {
      notifWarning();
    }
  };

  useEffect(() => {
    if (newApplication || newMessage || newPost) {
      checkPermission();
    }
  }, [newApplication, newMessage, newPost]);

  useEffect(() => {
    if (brandUID) {
      return listenNotification({ brandUID, dispatch });
    }

    return undefined;
  }, [dispatch, brandUID]);

  return null;
};

export default Notifications;
